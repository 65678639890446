<script setup>
import { computed, readonly } from 'vue';
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';
import { BackButton } from '@/components';
import { object, string } from 'yup';
import { OWNER_ROLES, REGEXES } from '@clearbanc/data-common-types';
import { JobTitlesByCorpCountry } from '@/data/owner';
import { getParsedMessage } from '@/data/error-messages';
import CheckboxGroup from '@clearbanc/clear-components/checkboxgroup';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import DSButton from '@clearbanc/clear-components/button';

// -----
// Setup
// -----

const store = useStore();

const emits = defineEmits(['onBack', 'onSuccess', 'onError']);

// ---------
// Constants
// ---------

const requestStatuses = readonly({
  createNonPrimaryUser: computed(() =>
    store.getters.requestStatus('CREATE_NON_PRIMARY_USER'),
  ),
  createUserInvite: computed(() =>
    store.getters.requestStatus('CREATE_USER_INVITE'),
  ),
});
const jobTitles = computed(() =>
  JobTitlesByCorpCountry(store.getters.business.corpCountry),
);

const validationSchema = object({
  firstName: string()
    .required('Required')
    .matches(
      REGEXES.NAME,
      'Only letters, valid punctuation, and no consecutive or trailing spaces',
    ),
  lastName: string()
    .required('Required')
    .matches(
      REGEXES.NAME,
      'Only letters, valid punctuation, and no consecutive or trailing spaces',
    ),
  email: string().nullable().required('Required').email(),
  jobTitle: object().nullable().required('Required'),
});

// ---------
// Variables
// ---------

const { values, handleSubmit, isSubmitting, errors } = useForm({
  validationSchema,
});

const isFormStateValid = computed(() => {
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return true;
  } catch {
    return false;
  }
});

const hasErrors = computed(
  () => !isFormStateValid.value || !!Object.keys(errors.value).length,
);

const onSubmit = handleSubmit(async () => {
  const payload = {
    ...values,
    jobTitle: values.jobTitle.value,
    jobRoles: values.jobRoles.filter((role) => !!role).flat(),
  };
  const newOwner = await store.dispatchApiAction(
    'CREATE_NON_PRIMARY_USER',
    payload,
  );
  if (requestStatuses.createNonPrimaryUser.isError) {
    emits(
      'onError',
      getParsedMessage(requestStatuses.createNonPrimaryUser.error, true),
    );
    return;
  }
  await store.dispatchApiAction('CREATE_USER_INVITE', {
    id: newOwner.id,
  });
  if (requestStatuses.createUserInvite.isError) {
    emits(
      'onError',
      getParsedMessage(requestStatuses.createUserInvite.error, true),
    );
  }
  emits('onSuccess');
});
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <BackButton :class="$style['back-button']" @click="emits('onBack')" />
      <h3 :class="$style.title">New Business Owner/Director</h3>
    </div>
    <form
      ref="form"
      class="c-form"
      :class="$style.form"
      :style="{ pointerEvents: isSubmitting ? 'none' : 'all' }"
      @submit="onSubmit"
    >
      <div class="c-group">
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-12">
            <InputTextGroup name="firstName" label="First Name" />
          </div>
        </div>
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-12">
            <InputTextGroup name="lastName" label="Last Name" />
          </div>
          <div class="col-span-12">
            <InputTextGroup name="email" label="Email Address" />
          </div>
          <div class="col-span-12">
            <DropdownGroup
              name="jobTitle"
              :label="$t('common.jobTitle')"
              option-label="label"
              :options="jobTitles"
              :placeholder="$t('common.selectOption')"
              append-to="self"
            />
          </div>
          <div class="col-span-12">
            <CheckboxGroup
              name="jobRoles[0]"
              :options="[
                {
                  value: OWNER_ROLES.DIRECTOR,
                  label: 'This individual is a Director / Board member',
                },
              ]"
            />
            <CheckboxGroup
              name="jobRoles[1]"
              :options="[
                {
                  value: OWNER_ROLES.OWNER,
                  label: 'This individual has 25%+ ownership',
                },
              ]"
            />
          </div>
        </div>
        <div :class="$style.footer">
          An email will be sent to this email address and this individual will
          be required to verify their identity.
        </div>
        <div :class="$style.actions">
          <DSButton
            label="Save and send email link to ID verification"
            :class="$style.actions__button"
            :loading="isSubmitting"
            :disabled="hasErrors"
            type="submit"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped module lang="less">
.container {
  padding: 20px;
  margin-top: 40px;
  border: 1px solid #e8e8ea;
  box-shadow: 0 2px 8px 0 #0000000f;
  background: #fff;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.back-button {
  margin-right: 10px;
}

.title {
  color: var(--neutral-black, #000);
  font-family: @gerstner-font;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.footer {
  margin-top: 40px;
}

.actions {
  margin-top: 40px;
}

.actions__button {
  width: 100%;
}

@media (min-width: 800px) {
  .container {
    padding: 40px;
  }
}
</style>
