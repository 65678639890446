// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_rfEYj{font-family:Montserrat;padding:0 20px 30px;background:#fff;border:1px solid #dadada;box-sizing:border-box;border-radius:4px;min-width:700px;flex:1 0 0;margin:24px auto auto}@media only screen and (max-width:768px){.container_rfEYj{margin:0 auto auto;width:unset;min-width:unset}}.container_rfEYj .title_N99n4{font-family:Montserrat;font-weight:700;font-size:21px;margin:30px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_rfEYj",
	"title": "title_N99n4"
};
export default ___CSS_LOADER_EXPORT___;
