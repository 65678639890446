// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_wahYr{width:420px}@media screen and (max-width:500px){.form_wahYr{width:unset}}.form_wahYr>*{margin-bottom:20px;margin:auto auto 20px auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form_wahYr"
};
export default ___CSS_LOADER_EXPORT___;
