// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input-container_rP7T2{max-width:400px;margin:2rem auto;text-align:left}.input-container_rP7T2 .button_RJtO4{display:block;margin-top:2rem;margin-left:auto}.help-section_rnM3T{margin-top:1.5rem}.help-section_rnM3T p{margin:0}@media only screen and (max-width:500px){.shopify-tip-content_tQCuQ{text-align:left}}.shopify-tip-content_tQCuQ p{margin:0}.tooltip_F0zcr{color:#000;padding:4px;cursor:help}.shopify-modal-tooltip-content_LgvbB{max-width:400px!important}@media only screen and (max-width:500px){.shopify-modal-tooltip-content_LgvbB{max-width:calc(100% - 40px)!important;left:20px!important}}.shopify-modal-tooltip-content_LgvbB img,.shopify-modal-tooltip-content_LgvbB span,.shopify-modal-tooltip-content_LgvbB strong{display:block}.shopify-modal-tooltip-content_LgvbB img{max-width:100%;margin-top:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-container": "input-container_rP7T2",
	"button": "button_RJtO4",
	"help-section": "help-section_rnM3T",
	"shopify-tip-content": "shopify-tip-content_tQCuQ",
	"tooltip": "tooltip_F0zcr",
	"shopify-modal-tooltip-content": "shopify-modal-tooltip-content_LgvbB"
};
export default ___CSS_LOADER_EXPORT___;
