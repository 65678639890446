// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkbox-container_b6e_a{position:relative;font-family:Montserrat;font-style:normal;font-weight:400;font-size:16px;line-height:20px;color:#666;min-height:20px;-webkit-user-select:none;-moz-user-select:none;user-select:none;padding:2px 0 0 40px;text-align:left;clear:both;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.checkbox-container_b6e_a input[type=checkbox]{display:none}.checkbox-container_b6e_a.error_uEAfO{min-height:40px}.checkbox-container_b6e_a.disabled_dZSaN{color:#a7a6a6;pointer-events:none}.checkbox-container_b6e_a .text_D36eD{display:inline-block}.checkbox-container_b6e_a .checkbox_WzQ6N{position:absolute;top:0;left:0;height:24px;width:24px;box-shadow:inset 0 0 0 1px #c4c4c4;background:#fff;border-radius:4px;text-align:center;vertical-align:middle;cursor:pointer;color:#000;line-height:16px;padding-left:1px}.transparent_UUy62.checkbox-container_b6e_a .checkbox_WzQ6N{background:none}.checkbox-container_b6e_a .checkbox_WzQ6N:focus{box-shadow:0 0 0 2px #005fcc}.error_uEAfO.checkbox-container_b6e_a .checkbox_WzQ6N{box-shadow:inset 0 0 0 1px #f05758}.checkbox-container_b6e_a .checkbox_WzQ6N .checkmark_x_JCp{height:15px;width:15px;margin-top:4px}.active_TeP8Q.checkbox-container_b6e_a .checkbox_WzQ6N{background:#f2f2f2}.disabled_dZSaN.checkbox-container_b6e_a .checkbox_WzQ6N{color:#a7a6a6;box-shadow:inset 0 0 0 1px #a7a6a6}.checkbox-container_b6e_a .error-msg_XPWjT{position:absolute;margin-top:2px;font-size:12px;line-height:12px;text-align:left;color:#f05758}@media only screen and (max-width:670px){.checkbox-container_b6e_a{margin-bottom:10px;padding-top:3px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-container": "checkbox-container_b6e_a",
	"error": "error_uEAfO",
	"disabled": "disabled_dZSaN",
	"text": "text_D36eD",
	"checkbox": "checkbox_WzQ6N",
	"transparent": "transparent_UUy62",
	"checkmark": "checkmark_x_JCp",
	"active": "active_TeP8Q",
	"error-msg": "error-msg_XPWjT"
};
export default ___CSS_LOADER_EXPORT___;
