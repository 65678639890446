<template>
  <Modal
    v-model:visible="showModal"
    :header="title"
    :closable="true"
    append-to="self"
    @hide="close"
  >
    <form-row
      v-if="apiErrorCode !== 'unauthorized'"
      type="container"
      no-inputs="no-inputs"
    >
      <p class="bold">
        {{ $t('components.popupApiError.serverIsUnreachable') }}
      </p>
      <div>
        {{ $t('components.popupApiError.email')
        }}<a href="mailto:support@clear.co">&nbsp;support@clear.co</a>
      </div>
      <div>
        {{ $t('components.popupApiError.phone')
        }}<a :href="`tel:${contactDetails.number}`"
          >&nbsp;{{ contactDetails.number }}</a
        >
      </div>
    </form-row>
    <div
      v-else
      :class="$style['body-container']"
      type="container"
      no-inputs="no-inputs"
    >
      <p :class="$style['body-paragraph']">
        {{ $t('components.popupApiError.toKeepYourAccountSecure') }}
      </p>
      <p :class="$style['body-paragraph']">
        {{ $t('components.popupApiError.pleaseLoginToContinue') }}
      </p>
      <Onboarding2Cta
        :class="$style['login-cta']"
        :text="$t('common.buttons.logIn')"
        @click="close"
      />
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import analytics from '@/utils/analytics';
import { Onboarding2Cta } from '@/components';
import Modal from '@clearbanc/clear-components/dialog';

export default {
  components: {
    Modal,
    Onboarding2Cta,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(['apiErrorCode', 'contactDetails']),
    title() {
      const unauthorized = this.$t('components.popupApiError.sessionExpired');
      const timeout = this.$t('components.popupApiError.requestTimeout');
      const internalServerError = this.$t(
        'components.popupApiError.internalServerError',
      );
      const gatewayTimeout = this.$t('components.popupApiError.gatewayTimeout');
      return (
        {
          unauthorized,
          timeout,
          500: internalServerError,
          504: gatewayTimeout,
        }[this.apiErrorCode] || internalServerError
      );
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  mounted() {
    const sessionExpiredSegmentEvent = 'viewed_session_expired';
    if (this.apiErrorCode === 'unauthorized') {
      analytics.track(sessionExpiredSegmentEvent, {
        stage: this.currentRoute,
        timestamp: new Date(),
      });
    }
  },
  watch: {
    apiErrorCode() {
      this.showModal = !!this.apiErrorCode;
    },
  },
  methods: {
    close() {
      if (this.apiErrorCode) {
        if (
          this.apiErrorCode === 'unauthorized' ||
          this.apiErrorCode === 'timeout'
        ) {
          this.$router.push({
            name: 'logout',
          });
        }
        this.$store.commit('HIDE_API_ERROR_POPUP');
      }
    },
  },
};
</script>

<style lang="less" module>
.body-container {
  margin: auto;
  padding-bottom: 30px;
  @media only screen and (min-width: 501px) {
    width: 90%;
  }
  .body-paragraph {
    text-align: left;
    font-weight: 400;
    color: black;
    font-family: Montserrat;
    margin: 0 0 2em;
    top: 0;
  }
  .login-cta {
    margin-top: 50px;
  }
}
</style>
