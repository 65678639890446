// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uploadZone_OA2Z3{height:100%!important;width:100%!important;margin:20px}.uploadZone_OA2Z3 .upload-list_XqOuZ{display:none!important}.uploadZone_OA2Z3 .dropzone_rMThP{height:90%!important;width:90%!important;vertical-align:bottom!important;margin:5%;border:1px dashed #69c1bc!important}.uploadZone_OA2Z3 .dropzone_rMThP .dz-message_zc6Dz{font-size:20px!important;font-family:Montserrat,sans-serif;font-style:normal!important;color:#000;white-space:pre-wrap!important;margin:auto}.uploadZone_OA2Z3 .dropzone_rMThP.dz-clickable_A979Y{display:flex;align-items:center;justify-content:center}.trashButton_UYs06{width:.5em}.trashButton_UYs06>*{text-align:center}.uploadSuccess_reqG7{border:solid;border-color:#69c1bc;border-radius:5px;border-width:2px;padding:10px;margin-bottom:auto 20px;width:100%}.document-column_KA3ue{color:#000;width:260px;font-weight:400}@media only screen and (max-width:960px){.document-column_KA3ue{width:140px}}@media only screen and (max-width:670px){.document-column_KA3ue{display:inline-block;width:-webkit-max-content;width:-moz-max-content;width:max-content}}.trash-column_WQNJd{width:30px}@media only screen and (max-width:960px){.trash-column_WQNJd{width:25px}}@media only screen and (max-width:670px){.trash-column_WQNJd{display:inline-block;width:-webkit-max-content;width:-moz-max-content;width:max-content}}.subtitle_ydRx7{color:gray;font-size:12px;text-align:left;width:60%}@media screen and (max-width:600px){.subtitle_ydRx7{width:100%;text-align:center}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadZone": "uploadZone_OA2Z3",
	"upload-list": "upload-list_XqOuZ",
	"dropzone": "dropzone_rMThP",
	"dz-message": "dz-message_zc6Dz",
	"dz-clickable": "dz-clickable_A979Y",
	"trashButton": "trashButton_UYs06",
	"uploadSuccess": "uploadSuccess_reqG7",
	"document-column": "document-column_KA3ue",
	"trash-column": "trash-column_WQNJd",
	"subtitle": "subtitle_ydRx7"
};
export default ___CSS_LOADER_EXPORT___;
