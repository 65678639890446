// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pdf-list_RjoW3{text-align:left}.pdf-list_RjoW3 .pdf-title_tz4j8{font-size:18px;font-weight:500;margin:0}.pdf-list_RjoW3 .processing_LJJUo{margin-left:10px;text-align:center}.file_SseR6{font-size:18px}.file-text_DaZ3O{cursor:pointer;text-decoration:underline}.file-text_DaZ3O.blue-link_qfcKY{color:#0d3685}.file-text_DaZ3O.closable-list_BNb0L{text-align:left}.file-button_b0bBz{cursor:pointer;width:18px;height:18px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pdf-list": "pdf-list_RjoW3",
	"pdf-title": "pdf-title_tz4j8",
	"processing": "processing_LJJUo",
	"file": "file_SseR6",
	"file-text": "file-text_DaZ3O",
	"blue-link": "blue-link_qfcKY",
	"closable-list": "closable-list_BNb0L",
	"file-button": "file-button_b0bBz"
};
export default ___CSS_LOADER_EXPORT___;
