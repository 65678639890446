// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboard_OTaWm{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif}.dashboard_OTaWm .header_LUusW{text-align:left}@media only screen and (min-width:960px){.dashboard_OTaWm .header_LUusW{text-align:center}}.dashboard_OTaWm .title_dZf_v{font-weight:500;font-size:20px;line-height:1.4;margin:1.125rem 0 1.5rem;color:#000}@media only screen and (min-width:960px){.dashboard_OTaWm .title_dZf_v{margin:1.875rem 0 1.5rem;font-size:26px;line-height:1.23}}.dashboard_OTaWm .description_xBTza{font-weight:300;margin-bottom:1.5rem}.dashboard_OTaWm .description_xBTza,.dashboard_OTaWm .steps_HrcPp{font-size:16px;line-height:1.5;color:#393939}.dashboard_OTaWm .steps_HrcPp{font-weight:700;margin-bottom:3rem}@media only screen and (min-width:960px){.dashboard_OTaWm .steps_HrcPp{margin-bottom:5rem}}.dashboard_OTaWm .body_uEPMH{display:grid;row-gap:3rem;grid-template-columns:repeat(1,minmax(0,1fr))}.dashboard_OTaWm .body_uEPMH .cta_BLzG_{width:100%}@media only screen and (min-width:960px){.dashboard_OTaWm .body_uEPMH{grid-template-columns:repeat(2,minmax(0,1fr));-moz-column-gap:1.5625rem;column-gap:1.5625rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard": "dashboard_OTaWm",
	"header": "header_LUusW",
	"title": "title_dZf_v",
	"description": "description_xBTza",
	"steps": "steps_HrcPp",
	"body": "body_uEPMH",
	"cta": "cta_BLzG_"
};
export default ___CSS_LOADER_EXPORT___;
