<template>
  <div v-if="files.length">
    <DataTable :value="files">
      <Column
        field="name"
        :header="`${capitalizeAndReplaceUnderscores(fileType)}:`"
      >
        <template #body="slotProps">
          <div
            :class="textClasses"
            :data-cy="kebabCase(computedName(slotProps.data) + ' file')"
            tabindex="0"
            @click="handleDownloadFile(slotProps.data.id)"
            @keyup.enter="handleDownloadFile(slotProps.data.id)"
          >
            {{ computedName(slotProps.data) }}
          </div>
        </template>
      </Column>
      <Column field="startDate" header="Start Date:">
        <template #body="slotProps">
          <Calendar
            v-model="dateRanges[slotProps.data.id].startDate"
            :max-date="new Date()"
            date-format="mm/dd/yy"
            :class="$style['calendar']"
            @date-select="
              (value) => {
                dateSelected(slotProps.data.id, 'startDate', value);
              }
            "
          />
        </template>
      </Column>
      <Column field="endDate" header="End Date:">
        <template #body="slotProps">
          <Calendar
            v-model="dateRanges[slotProps.data.id].endDate"
            :max-date="new Date()"
            :min-date="
              getMinEndDate(dateRanges[slotProps.data.id]['startDate'])
            "
            date-format="mm/dd/yy"
            :class="$style['calendar']"
            @date-select="
              (value) => {
                dateSelected(slotProps.data.id, 'endDate', value);
              }
            "
          />
        </template>
      </Column>
      <Column field="close">
        <template #body="slotProps">
          <svg
            v-if="isClosable"
            :class="$style['file-button']"
            tabindex="0"
            @click="$emit('fileRemove', slotProps.data.id)"
            @keyup.enter="$emit('fileRemove', slotProps.data.id)"
            v-html="require('@/assets/icons/close-icon.svg?raw')"
          ></svg>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import kebabCase from '@/utils/kebabcase';
import { downloadFileMixin } from '@/utils/vue-mixins';
import Calendar from '@clearbanc/clear-components/calendar';
import DataTable from '@clearbanc/clear-components/datatable';
import Column from '@clearbanc/clear-components/column';

export default {
  components: {
    Calendar,
    DataTable,
    Column,
  },
  mixins: [downloadFileMixin],
  props: {
    files: { type: Array },
    isClosable: { type: Boolean, default: true },
    shortenFilenames: { type: Boolean, default: false },
  },
  data() {
    return {
      dateRanges: {},
    };
  },
  computed: {
    fileClasses() {
      return {
        [this.$style.file]: this.isClosable,
      };
    },
    textClasses() {
      return {
        [this.$style['file-text']]: true,
        [this.$style['closable-list']]: this.isClosable,
        [this.$style['list-only']]: !this.isClosable,
        [this.$style['blue-link']]: true,
      };
    },
    fileType() {
      return this.files[0].type;
    },
  },
  beforeMount() {
    this.updateDateRanges();
  },
  methods: {
    kebabCase,
    dateSelected(fileId, dateType, date) {
      this.dateRanges[fileId][dateType] = date;
      if (
        this.dateRanges[fileId].startDate &&
        this.dateRanges[fileId].endDate
      ) {
        this.$emit('updateFile', {
          id: fileId,
          startDate: this.dateRanges[fileId].startDate,
          endDate: this.dateRanges[fileId].endDate,
        });
      }
    },
    computedName(file) {
      if (this.shortenFilenames) {
        const onlyFileName = file.name.replace(`.${file.extension}`, '');
        if (onlyFileName.length > 10) {
          return onlyFileName.slice(0, 10).concat(`...${file.extension}`);
        }
      }
      return file.name;
    },
    capitalizeAndReplaceUnderscores(string) {
      const words = string
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

      return words.join(' ');
    },
    async handleDownloadFile(id) {
      this.$emit('trackUploadedDocClicked');
      await this.downloadFile(id);
    },
    formatDate(date) {
      // this is to fix an issue where the date shows up as a day behind
      return new Date(date.split('T')[0].replace(/-/g, '/'));
    },
    getMinEndDate(startDate) {
      if (!startDate) return null;
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 1);
      return endDate;
    },
    updateDateRanges() {
      this.files.forEach(
        (file) =>
          (this.dateRanges[file.id] =
            file.startDate && file.endDate
              ? {
                  startDate: this.formatDate(file.startDate),
                  endDate: this.formatDate(file.endDate),
                }
              : { startDate: '', endDate: '' }),
      );
    },
  },
  watch: {
    files: {
      handler() {
        this.updateDateRanges();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" module>
.file {
  font-size: 18px;
}

.file-text {
  cursor: pointer;
  text-decoration: underline;
  &.blue-link {
    color: @link-blue;
  }
  &.closable-list {
    text-align: left;
  }
  &.list-only {
    position: relative;
    z-index: 1;
    margin: 0 0 10px;
    text-align: right;
    max-width: 290px;
    @media screen and (max-width: 500px) {
      text-align: left;
    }
  }
}

.file-button {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.calendar {
  width: 245px;
}
</style>
