// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_dasMq{padding:20px;margin-top:40px;border:1px solid #e8e8ea;box-shadow:0 2px 8px 0 #0000000f;background:#fff}.controls-error_zjJQh{text-align:right;width:100%;margin-top:20px}@media (min-width:800px){.container_dasMq{padding:40px}}.unauthenticaed-upload_gUjJ9 [class^=p-badge]{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_dasMq",
	"controls-error": "controls-error_zjJQh",
	"unauthenticaed-upload": "unauthenticaed-upload_gUjJ9"
};
export default ___CSS_LOADER_EXPORT___;
