// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".requested_vwHUU{border-bottom:1px dashed #ceced0;margin-bottom:30px;padding-bottom:40px}.requested_vwHUU button[class^=p-button]{margin:0}.requested_vwHUU .message_zShQJ{margin:0 0 20px}.requested_vwHUU td:first-child,.requested_vwHUU td:nth-last-child(2){white-space:nowrap}.icon_xYxPn{color:#e8a524;margin-right:10px}.capitalize_QVwoW{text-transform:capitalize}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requested": "requested_vwHUU",
	"message": "message_zShQJ",
	"icon": "icon_xYxPn",
	"capitalize": "capitalize_QVwoW"
};
export default ___CSS_LOADER_EXPORT___;
