// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_G0fjG{position:relative;display:inline-block;font-family:Montserrat Next;font-weight:600;font-size:14px;line-height:51px;background:#1d444b;color:#fff;text-transform:uppercase;text-align:center;white-space:nowrap;transition:all .2s ease;cursor:pointer;padding:0 25px;border:0;height:50px}.button_G0fjG.small_qWtS5{font-size:12px;line-height:37px;padding:0 15px;height:36px}.button_G0fjG.tiny_fvBL3{font-size:10px;font-weight:500;line-height:31px;padding:0 15px;height:30px}.button_G0fjG.grey_Gmn6d{background:#eaeaea;color:#000}.button_G0fjG.disabled_Bkl83{background:#dadada;color:#a7a6a6;pointer-events:none;cursor:default}.button_G0fjG:hover{background:#000;color:#fff}.button_G0fjG:focus{outline:none;background:#000}.button_G0fjG .tooltip_aReAL{position:absolute;bottom:110%;left:50%;transform:translateX(-50%);color:#333;background:#f2f2f2;border:1px solid #fff;line-height:16px;text-transform:none;padding:10px 15px;border-radius:3px;box-shadow:0 0 10px rgba(0,0,0,.5)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button_G0fjG",
	"small": "small_qWtS5",
	"tiny": "tiny_fvBL3",
	"grey": "grey_Gmn6d",
	"disabled": "disabled_Bkl83",
	"tooltip": "tooltip_aReAL"
};
export default ___CSS_LOADER_EXPORT___;
