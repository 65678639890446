<template>
  <div class="data-security-page">
    <h4>
      {{
        $t('account.pages.dataAndPrivacy.pageAccessData.accessMyPersonalData')
      }}
    </h4>
    <p>
      {{
        $t('account.pages.dataAndPrivacy.pageAccessData.yourAccountYourData')
      }}
      <br />
      {{
        $t(
          'account.pages.dataAndPrivacy.pageAccessData.mayTakeTimeToCreateAndEmail',
        )
      }}
    </p>
    <template v-if="readyArchives.length">
      <h4 class="small">
        {{ $t('account.pages.dataAndPrivacy.pageAccessData.readyArchives') }}
      </h4>
      <div class="table-component__table-wrapper">
        <DataTable
          :value="readyArchives"
          class="table-component__table"
          responsive-layout="scroll"
        >
          <Column
            field="createdAt"
            :header="
              $t('account.pages.dataAndPrivacy.pageAccessData.createdOn')
            "
          >
            <template #body="row">
              {{ formatDt(row.data?.createdAt) }}
            </template>
          </Column>
          <Column
            field="size"
            :header="$t('account.pages.dataAndPrivacy.pageAccessData.size')"
          >
            <template #body="row">
              {{ bytesToSize(row.data?.size) }}
            </template>
          </Column>
          <Column
            :header="
              $t('account.pages.dataAndPrivacy.pageAccessData.detailsLowerCase')
            "
          >
            <template #body="row">
              <a
                class="data-security-page__download-link"
                :style="{ cursor: 'pointer' }"
                :class="{ 'download-link__loading': loading[row.data?.id] }"
                @click="downloadData(row.data?.id)"
                @keyup.enter="downloadData(row.data?.id)"
                >{{
                  $t('account.pages.dataAndPrivacy.pageAccessData.download')
                }}</a
              >
            </template>
          </Column>
        </DataTable>
      </div>
    </template>
    <template v-if="pendingArchives.length">
      <h4 class="small">
        {{ $t('account.pages.dataAndPrivacy.pageAccessData.pendingArchives') }}
      </h4>
      <div class="table-component__table-wrapper">
        <DataTable
          :value="pendingArchives"
          class="table-component__table"
          responsive-layout="scroll"
        >
          <Column
            field="createdAt"
            :header="
              $t('account.pages.dataAndPrivacy.pageAccessData.createdOn')
            "
          >
            <template #body="row">
              {{ formatDt(row.data?.createdAt) }}
            </template>
          </Column>
          <Column
            field="size"
            :header="$t('account.pages.dataAndPrivacy.pageAccessData.size')"
          >
            <template #body>
              {{ $t('account.pages.dataAndPrivacy.pageAccessData.pending') }}
            </template>
          </Column>
          <Column
            :header="
              $t('account.pages.dataAndPrivacy.pageAccessData.detailsLowerCase')
            "
          >
            <template #body>
              {{ $t('account.pages.dataAndPrivacy.pageAccessData.pending') }}
            </template>
          </Column>
        </DataTable>
      </div>
    </template>
    <a ref="downloadLinkRef" aria-label="download link" />
    <v-button :loading="exportDataRequest.isPending" @click="sendRequest">
      {{ $t('account.pages.dataAndPrivacy.pageAccessData.dataAccessButton') }}
    </v-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Column from '@clearbanc/clear-components/column';
import DataTable from '@clearbanc/clear-components/datatable';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { validateChildren } from '@/composables/validation';
import analytics from '@/utils/analytics';
import { useMeta } from 'vue-meta';
import { formatDate } from '@/utils/dates';

export default {
  components: { DataTable, Column },
  setup() {
    useMeta({ title: 'Export data | Clearco' });
    const { hasError } = validateChildren();

    return { hasError };
  },
  data() {
    return {
      password: '',
      loading: {},
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'currentUpload',
      'productSegmentLabel',
      'isDiligenceServiceReadDataEnabled',
    ]),
    ...mapRequestStatuses({
      exportDataRequest: 'EXPORT_PERSONAL_DATA',
      getUserUploadDetailsRequest: 'GET_USER_UPLOAD_DETAILS',
    }),
    archives() {
      const filter = this.isDiligenceServiceReadDataEnabled
        ? {
            externalIdType: 'user',
            documentType: 'export_data',
            externalId: `${this.user.id}`,
          }
        : {
            entity: 'user',
            type: 'export_data',
            metaId: `${this.user.id}`,
          };
      return this.$store.getters.uploadsMatching(filter);
    },
    readyArchives() {
      const filtered = this.archives.filter((arch) => !!arch.size);
      return filtered;
    },
    pendingArchives() {
      return this.archives.filter((arch) => !arch.size);
    },
    getTargetArea() {
      return {
        targetArea: this.$route.name,
      };
    },
    segmentProps() {
      return {
        productSegment: this.productSegmentLabel,
        ...this.getTargetArea,
      };
    },
  },
  mounted() {
    this.$store.dispatchApiAction('FETCH_USER_UPLOADS');
  },
  methods: {
    openValidatePasswordPopup() {
      this.$refs.verifyPasswordPopup.open();
    },
    async sendRequest() {
      await this.$store.dispatchApiAction('EXPORT_PERSONAL_DATA');
      if (this.exportDataRequest.isSuccess) {
        analytics.track('fe_new_archive_confirm_clicked', this.segmentProps);
        this.$store.dispatchApiAction('FETCH_USER_UPLOADS');
        this.$refs.verifyPasswordPopup.close();
      }
    },
    async downloadData(id) {
      this.$set(this.loading, id, true);
      await this.$store.dispatchApiAction('GET_USER_UPLOAD_DETAILS', {
        id,
        download: true,
      });
      if (this.getUserUploadDetailsRequest.isSuccess) {
        this.$refs.downloadLinkRef.setAttribute(
          'href',
          this.currentUpload.downloadUrl,
        );
        this.$refs.downloadLinkRef.click();
        window.setTimeout(() => this.$set(this.loading, id, false), 2000);
      }
    },
    formatDt(dt) {
      try {
        return formatDate(new Date(dt));
      } catch (e) {
        return '';
      }
    },
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'kB', 'MB', 'GB'];
      if (!bytes) return '0 Bytes';
      const sizeIdx = Math.floor(Math.log(bytes) / Math.log(1024));
      const convertedFileSize =
        Math.round(((bytes * 10) / 1000) ** sizeIdx) / 10;
      return `${convertedFileSize} ${sizes[sizeIdx]}`;
    },
  },
};
</script>

<style lang="less">
.data-security-page {
  padding-top: 10px;
  .data-security-page__download-link {
    font-size: 0.875rem;
    line-height: inherit;

    &.download-link__loading {
      opacity: 0.7;
      cursor: default;
      pointer-events: none;
    }
  }

  .table-component__table-wrapper,
  p {
    margin-top: 5px;
  }
}
</style>
