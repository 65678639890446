<template>
  <div :class="$style.calculator">
    <div :class="$style.calculator__amount">
      <p :class="$style.calculator__amount__title">
        Submitted Amount & Extension
      </p>
      <div :class="$style.calculator__amount__price">
        <span>$</span>{{ formattedSliderValue }}
      </div>
      <input
        v-model="sliderValue"
        type="range"
        min="1000"
        max="250000"
        step="1000"
        :class="$style.slider"
        aria-label="Invoice Amount"
      />
    </div>

    <div :class="$style.calculator__extension">
      <button
        v-for="extension in rates"
        :class="[
          $style['extension-button'],
          selectedRate === extension ? $style['extension-button--active'] : '',
        ]"
        @click="
          () => {
            analytics.track(`product_education_calculator_viewed`, {
              months: `${extension.num_months} months`,
            });
            selectedRate = extension;
          }
        "
      >
        <span :class="$style['extension-button__duration']"
          >{{ extension.num_months }} Months</span
        >
        <span :class="$style['extension-button__percent']"
          >{{ parseFloat((extension.rate * 100).toFixed(2)) }}%</span
        >
      </button>
    </div>

    <div :class="[$style.filler, $style['outer-filler']]"></div>

    <div :class="$style.calculator__results">
      <div :class="$style.calculator__results__left">
        <div :class="$style.calculator__results__left__duration">
          <div :class="$style.time">
            <p :class="$style.time__value">
              {{ numberOfWeeks }}
            </p>
            <p :class="$style.time__description">Weekly<br />Repayments</p>
          </div>
        </div>
        <div :class="$style.calculator__results__left__price">
          <div :class="$style.time">
            <p :class="$style.time__value">
              <span>$</span>{{ Number(weeklyRepayments).toLocaleString() }}
            </p>
            <p :class="$style.time__description">Per<br />Week</p>
          </div>
        </div>
      </div>
      <div :class="$style.calculator__results__right">
        <div :class="$style.calculator__results__right__cost">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 230.1 120.1"
            style="enable-background: new 0 0 230.1 120.1"
            xml:space="preserve"
            :class="$style.chart"
          >
            <path
              :class="[$style.chart__line, $style['chart__line--green']]"
              :style="{
                strokeDashoffset: Math.ceil(345 * (selectedRate.rate * 2)),
              }"
              d="M5,115.1C5,54.3,54.3,5,115.1,5s110.1,49.3,110.1,110.1"
            />
            <path
              :class="[$style.chart__line, $style['chart__line--orange']]"
              :style="{
                strokeDashoffset:
                  Math.ceil(345 * (selectedRate.rate * 2)) + 330,
              }"
              d="M5,115.1C5,54.3,54.3,5,115.1,5s110.1,49.3,110.1,110.1"
            />
          </svg>

          <div :class="$style['chart-details']">
            <div :class="$style['chart-details__description']">Total Cost</div>
            <div :class="$style['chart-details__amount']">
              <span>$</span>{{ totalCost.toLocaleString() }}
            </div>
          </div>
        </div>
        <div :class="$style.calculator__results__right__breakdown">
          <div :class="$style.calculator__results__right__breakdown__price">
            <span>$</span>{{ formattedSliderValue }}
          </div>
          <div
            :class="$style.calculator__results__right__breakdown__description"
          >
            Invoice Amount
          </div>
        </div>
        <div
          :class="[
            $style.calculator__results__right__breakdown,
            $style['calculator__results__right__breakdown--fees'],
          ]"
        >
          <div :class="$style.calculator__results__right__breakdown__price">
            <span>$</span>{{ fee.toLocaleString() }}
          </div>
          <div
            :class="$style.calculator__results__right__breakdown__description"
          >
            Fees
          </div>
        </div>
      </div>
    </div>

    <div v-if="showBillSubmitCta" :class="$style.calculator__cta">
      <div :class="$style.filler"></div>
      <div :class="$style.calculator__cta__content">
        <div :class="$style.calculator__cta__content__left">
          <span
            :class="$style.calculator__cta__content__left__icon"
            class="material-symbols-outlined"
            >receipt_long</span
          >
          Ready to submit your first invoice?
        </div>
        <div :class="$style.calculator__cta__content__right">
          <DSButton
            label="Finish Application"
            @click="
              () => {
                analytics.track(
                  'product_education_calculator_clicked_finish_application',
                );
                $router.push({
                  name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
                });
              }
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DSButton from '@clearbanc/clear-components/button';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import analytics from '@/utils/analytics';

export default {
  components: { DSButton },
  props: {
    showBillSubmitCta: { type: Boolean, required: false },
  },
  data() {
    return {
      PAYMENTS_ROUTE_NAMES,
      selectedRate: { num_months: 0, rate: 0 },
      sliderValue: 50000,
      weeksInAMonth: 4.34524,
      analytics,
      rates: [],
    };
  },
  async mounted() {
    const numMonthsList = this.$route.query?.num_months?.split(',');
    const ratesList = this.$route.query?.rates?.split(',');
    if (numMonthsList?.length && numMonthsList.length === ratesList?.length) {
      this.rates = numMonthsList.map((numMonth, index) => ({
        num_months: numMonth,
        rate: ratesList[index],
      }));
    } else {
      await this.$store.dispatchApiAction('GET_ADVERTISED_FEES');
      this.rates = this.advertisedRates.rates;
    }
    this.selectedRate = this.rates[0];
  },
  computed: {
    ...mapGetters(['advertisedRates']),
    formattedSliderValue() {
      return Number(this.sliderValue).toLocaleString();
    },
    fee() {
      return this.sliderValue * this.selectedRate.rate;
    },
    numberOfWeeks() {
      return Math.floor(this.selectedRate.num_months * this.weeksInAMonth);
    },
    totalCost() {
      return Number(this.sliderValue) + this.fee;
    },
    weeklyRepayments() {
      if (this.numberOfWeeks) {
        return (this.totalCost / this.numberOfWeeks).toFixed(2);
      }

      return 0;
    },
  },
};
</script>

<style lang="less" module>
.calculator {
  display: grid;
  row-gap: 20px;
  column-gap: 20px;
  margin-bottom: 25px;
  grid-template-columns: 1fr;
}

@media (min-width: 600px) {
  .calculator {
    grid-template-columns: min-content 1fr;
  }
}

.calculator__amount {
  background: #fff;
  border-radius: 4px;
  grid-column: 1/2;
  grid-row: 1/2;
  padding: 24px 30px;
}

.calculator__amount__title {
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  text-decoration-line: underline;
  margin: 0 0 24px 0;
}

.calculator__amount__price {
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 28px 0;
  display: flex;
  align-items: flex-start;
}

.calculator__amount__price span {
  color: #a5a5a5;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0 3px 0 0;
  display: inline-block;
}

.calculator__extension {
  background: #fff;
  border-radius: 4px;
  display: flex;
  grid-column: 1/2;
}

.extension-button {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  white-space: nowrap;
  width: 100%;
  transition: background 0.1s, color 0.1s;
}

.extension-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.extension-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.extension-button--active {
  background: #7f30ff;
  cursor: default;
  pointer-events: none;
}

.extension-button + .extension-button {
  border-left: 1px solid rgba(237, 237, 237, 0.5);
}

.extension-button__duration {
  padding: 6px 0;
  border-bottom: 1px solid rgba(237, 237, 237, 0.5);
  color: #111;
  text-align: center;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}

.extension-button--active .extension-button__duration {
  color: #fff;
  border-color: rgba(0, 0, 0, 0.2);
}

.extension-button__percent {
  padding: 6px 0;
  color: #111;
  text-align: center;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
}

@media (min-width: 480px) {
  .extension-button__duration,
  .extension-button__percent {
    padding: 6px 24px;
  }
}

.extension-button--active .extension-button__percent {
  color: #fff;
}

.calculator__results {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  grid-column: 1/2;
  grid-row: 3/4;
}

@media (min-width: 600px) {
  .calculator__results {
    grid-column: 1/3;
  }
}

@media (min-width: 800px) {
  .calculator__results {
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  .calculator__results {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}

.calculator__results__left {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.calculator__results__left__duration {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calculator__results__left__price {
  border-top: 1px solid #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time {
  display: flex;
  align-items: center;
}

.time__value {
  color: #111;
  text-align: right;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  display: flex;
  align-items: flex-start;
  padding-right: 8px;
}

.time__value span {
  color: #a5a5a5;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0 3px 0 0;
  display: inline-block;
}

.time__description {
  border-left: 1px solid #ededed;
  padding-left: 8px;
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.calculator__results__right {
  border-top: 1px solid #f6f6f6;
  display: grid;
}

@media (min-width: 600px) {
  .calculator__results__right {
    border-top: 0;
    border-left: 1px solid #f6f6f6;
  }
}

.calculator__results__right__cost {
  border-bottom: 1px solid #f6f6f6;
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 30px 0;
  position: relative;
}

@media (min-width: 480px) {
  .calculator__results__right__cost {
    padding: 30px 50px;
  }
}

@media (min-width: 1500px) {
  .calculator__results__right__cost {
    padding: 30px 70px;
  }
}

.chart {
  width: 240px;
}

.chart__line {
  fill: none;
  stroke-width: 10;
  stroke-linecap: round;
  transition: all 0.2s;
}

.chart__line--green {
  stroke: #29ca43;
  stroke-dasharray: 345;
}

.chart__line--orange {
  stroke: #f8a62c;
  stroke-dasharray: 345;
}

.chart-details {
  text-align: center;
  position: absolute;
  top: calc(50% + 25px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.chart-details__description {
  border-bottom: 1px solid #ededed;
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  padding: 0 0 2px 0;
  margin: 0 0 10px 0;
  display: inline-block;
}

.chart-details__amount {
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  display: flex;
  align-items: flex-start;
}

.chart-details__amount span {
  color: #a5a5a5;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0 3px 0 0;
  display: inline-block;
}

.calculator__results__right__breakdown {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.calculator__results__right__breakdown__price {
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: flex;
  align-items: flex-start;
}

.calculator__results__right__breakdown__price span {
  color: #a5a5a5;
  font-size: 12px;
  display: inline-block;
  margin: 0 2px 0 0;
}

.calculator__results__right__breakdown__description {
  border-top: 1px solid #ededed;
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  padding-top: 6px;
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.calculator__results__right__breakdown__description:before {
  content: '';
  width: 18px;
  height: 4px;
  border-radius: 4px;
  background: #29ca43;
  display: inline-block;
  margin-right: 10px;
}

.calculator__results__right__breakdown--fees {
  border-left: 1px solid #f6f6f6;
}

.calculator__results__right__breakdown--fees
  .calculator__results__right__breakdown__description:before {
  background: #f8a62c;
}

.filler {
  background: #ebebeb;
  border-radius: 4px;
  display: none;
}

.outer-filler {
  grid-column: 2/3;
  grid-row: 1/3;
}

@media (min-width: 600px) {
  .filler {
    display: block;
  }
}

@media (min-width: 1200px) {
  .outer-filler {
    display: none;
  }
}

.calculator__cta {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  grid-column: 1/2;
  grid-row: 4/5;
}

@media (min-width: 600px) {
  .calculator__cta {
    grid-column: 1/3;
    grid-template-columns: 1fr max-content;
  }
}

@media (min-width: 1200px) {
  .calculator__cta {
    grid-row: 3/4;
  }
}

.calculator__cta__content {
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (min-width: 480px) {
  .calculator__cta__content {
    flex-direction: row;
  }
}

@media (min-width: 600px) {
  .calculator__cta__content {
    max-width: 448px;
  }
}

.calculator__cta__content:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #f7f7f9;
}

.calculator__cta__content__left {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  justify-content: center;
}

.calculator__cta__content__left__icon {
  margin-right: 11px;
}

.calculator__cta__content__right {
  display: flex;
  align-items: center;
  border-top: 1px solid #f6f6f6;
  padding: 20px;
  white-space: nowrap;
  width: 100%;
}

.calculator__cta__content__right button {
  width: 100%;
}

@media (min-width: 480px) {
  .calculator__cta__content__right {
    width: auto;
    border-left: 1px solid #f6f6f6;
    border-top: 0;
  }
}

.slider {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: #e2e2e2;
  outline: none;
  margin: 0;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #7f30ff;
  transition: background 0.2s;
  cursor: pointer;
}

.slider::-webkit-slider-thumb:hover {
  background: #5b00f0;
}

.slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #7f30ff;
  transition: background 0.2s;
  cursor: pointer;
}

.slider::-moz-range-thumb:hover {
  background: #5b00f0;
}
</style>
