<template>
  <div :class="$style.requested">
    <Message
      :class="$style.message"
      severity="warn"
      icon="pi-exclamation-triangle"
      :closable="false"
    >
      <h3>Upload Missing Documents</h3>
      <p>
        Please upload the following requested document(s) to avoid funding
        delays
      </p>
    </Message>
    <DataTable
      filter-display="menu"
      :value="activeDocumentRequests"
      :class="$style.table"
      :always-show-paginator="false"
      :rows="20"
      :sort-order="-1"
      responsive-layout="scroll"
      edit-mode="row"
      :loading="isLoading"
      show-gridlines
    >
      <template #empty>
        {{ isLoading ? 'Loading files...' : 'No files found.' }}
      </template>

      <template>
        <Column field="type" header="File Type" :show-filter-operator="false">
          <template #body="slotProps">
            <icon :class="$style.icon" name="exclamation-triangle" />
            <span :class="$style.capitalize">{{
              formatType(slotProps.data.type)
            }}</span>
          </template>
        </Column>
        <Column field="message" header="Description">
          <template #body="slotProps">
            {{ slotProps.data.message }}
          </template>
        </Column>
        <Column field="createdAt" header="Requested On">
          <template #body="slotProps">
            {{
              formatDate(new Date(slotProps.data.createdAt), dateDisplayFormat)
            }}
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <DSButton
              :style="{ width: '100%' }"
              label="Upload"
              class="p-button-sm"
              @click="openUploadModal(slotProps.data.type)"
            />
          </template>
        </Column>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from '@clearbanc/clear-components/datatable';
import Column from '@clearbanc/clear-components/column';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import formatDate from 'date-fns/format';

export default {
  props: ['formatType', 'isLoading'],
  components: {
    DataTable,
    Column,
    DSButton,
    Message,
  },
  data() {
    return {
      dateDisplayFormat: 'MMMM do, yyyy',
    };
  },
  methods: {
    formatDate,
    openUploadModal(fileType) {
      this.$emit('openUploadModal', fileType);
    },
  },
  computed: {
    ...mapGetters(['activeDocumentRequests']),
  },
};
</script>

<style>
.p-datatable .p-datatable-header {
  padding: 0;
}
</style>

<style module>
.requested {
  border-bottom: 1px dashed #ceced0;
  margin-bottom: 30px;
  padding-bottom: 40px;
}

.requested button[class^='p-button'] {
  margin: 0;
}

.requested .message {
  margin: 0 0 20px;
}

.requested td:first-child,
.requested td:nth-last-child(2) {
  white-space: nowrap;
}

.icon {
  color: #e8a524;
  margin-right: 10px;
}

.capitalize {
  text-transform: capitalize;
}
</style>
