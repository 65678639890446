import { GETTERS } from '@/data/state-modules';
import { reactive } from 'vue';

// source: https://github.com/vuejs/vuex/blob/96a265a345c76ec7d0f81a115aef74b7eda89452/dist/vuex.common.js#L1091
const normalizeMap = (map) => {
  const isObject = (obj) => obj !== null && typeof obj === 'object';
  const isValidMap = (obj) => Array.isArray(obj) || isObject(obj);

  if (!isValidMap(map)) {
    return [];
  }
  return Array.isArray(map)
    ? map.map(function (key) {
        return { key, val: key };
      })
    : Object.keys(map).map(function (key) {
        return { key, val: map[key] };
      });
};

// inspired by Vuex mapGetters
export const mapStateModuleGetters = (getters, context) => {
  const result = {};
  normalizeMap(getters).forEach(({ key, val }) => {
    result[key] = function mappedStateModuleGetter() {
      const ctx = this ?? context;
      const currentStateModule = ctx.$store.getters.currentStateModule;

      if (!currentStateModule || !(currentStateModule in GETTERS)) {
        return undefined;
      }

      const newVal = GETTERS[currentStateModule][val];

      if (!newVal) {
        return undefined;
      }

      return ctx.$store.getters[newVal];
    };
  });

  return result;
};

/**
 * A function to be utilized in setup hook / composition-api.
 *
 * Specifically can be used in composable functions or setup hooks of a vue file to create
 * computed properties for state module gettesr by returning an object of
 * key: state module getter Name
 * value: reactive computed property value
 *
 * This should provide the same result in a composable function by destructuring the return function return value as
 * is accomplished by using `mapStateModuleGetters` in computed hook of a vue file.
 *
 *
 * @param {String[]} getters
 * @param {Object} context
 * @returns Object
 */
export const mapStateModuleGettersForComposable = (getters, context) => {
  const result = {};
  function mappedStateModuleGetter(val) {
    const ctx = this ?? context;
    const currentStateModule = ctx.$store.getters.currentStateModule;

    if (!currentStateModule || !(currentStateModule in GETTERS)) {
      return undefined;
    }

    const newVal = GETTERS[currentStateModule][val];

    if (!newVal) {
      return undefined;
    }

    return ctx.$store.getters[newVal];
  }
  normalizeMap(getters).forEach(({ key, val }) => {
    const newRef = reactive({});
    newRef.value = mappedStateModuleGetter(val);
    result[key] = newRef;
  });

  return result;
};
