<template>
  <div data-cy="connect-sales-account-modal">
    <p :class="$style.description">
      Connect at least one sales platform to verify your account. The more
      connections, the better we can understand your business and may help you
      finance more of your invoices.
    </p>
    <EncryptionMessage />
    <LayoutConnectAccounts
      :show-error="showError"
      :show-encryption-message="false"
      :event-tracking-fields="commonTrackingFields"
    >
      <TileGroupConnectSales
        v-model:show-error="showError"
        :event-tracking-fields="commonTrackingFields"
      />
    </LayoutConnectAccounts>
    <SkipForNow
      :button-text="buttonText"
      :allow-next="allowNext"
      @click="buttonAction"
    />
  </div>
</template>

<script>
import TileGroupConnectSales from '@/components/TileGroupConnectSales';
import { mapStateModuleGetters } from '@/utils/state-modules';
import { mapGetters } from 'vuex';
import EncryptionMessage from '@/components/EncryptionMessage';
import SkipForNow from '@/components/payments/SkipForNow';
import { LayoutConnectAccounts } from '@/components';

export default {
  components: {
    LayoutConnectAccounts,
    SkipForNow,
    EncryptionMessage,
    TileGroupConnectSales,
  },
  props: {
    buttonText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      skipStep: false,
      showError: false,
    };
  },
  computed: {
    ...mapGetters(['productSegmentLabel', 'hasActiveSalesAccounts']),
    ...mapStateModuleGetters(['experimentName']),
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        targetArea: this.$route.name,
        experiment: this.experimentName,
      };
    },
    allowNext() {
      return this.hasActiveSalesAccounts;
    },
  },
  methods: {
    buttonAction() {
      this.$emit('button-action');
    },
  },
};
</script>

<style lang="less" module>
.description {
  max-width: 500px;
  margin: 0 auto;
}
</style>
