<template>
  <span>
    <ext-account
      title="googleads"
      :logo="googleads"
      :success="success"
      :error="error"
      :logo-alt="$t('components.connectGoogleAds.googleAdsLogo')"
      @click="openModal"
    />

    <!-- Google Ads Modal -->
    <ModalGoogleads
      ref="googleadsModal"
      @submit="continueGoogleAdsIntegration"
      @error="$emit('error', MARKETING_PLATFORMS.GOOGLEADS)"
      @modalAction="
        (platformName, action) => $emit('modalAction', platformName, action)
      "
    />
  </span>
</template>

<script>
import { MARKETING_PLATFORMS } from '@/data/platforms';
import ModalGoogleads from '@/components/modals/ModalGoogleads';
import ExtAccount from '@/components/modals/ModalExternalAccountOnboarding2';

export default {
  components: {
    ExtAccount,
    ModalGoogleads,
  },
  props: {
    authorizeUri: {
      type: String,
      required: true,
    },
    success: Boolean,
    error: Boolean,
  },
  computed: {
    googleads: () => require('@/assets/logos/google.png'),
    MARKETING_PLATFORMS: () => MARKETING_PLATFORMS,
  },
  methods: {
    openModal() {
      this.$refs.googleadsModal.open();
    },
    async continueGoogleAdsIntegration(customerId) {
      this.$emit('submit', {
        authorizeUri: this.authorizeUri,
        extras: customerId,
      });
    },
  },
};
</script>
