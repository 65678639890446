/* eslint-disable no-param-reassign */

import accounting from 'accounting';
import {
  SUPPORTED_CURRENCY_SYMBOLS,
  CURRENCY_MAPPING_BY_SUPPORTED_COUNTRYCODE,
} from '@/data/currency-code-with-names';
import { CurrencyCode } from '@clearbanc/data-common-types';
import { dollarsToCents as dollarsToCentsBigint } from '@clearbanc/money';

// inspired by https://github.com/mazipan/vue-currency-filter/blob/master/VueCurrencyFilter.js

// future projects:
// - more options - simpler symbol in front/back
// - different currency configuration

const defaultFormattingOptions = {
  format: '%s%v',
  symbol: '$',
  precision: 2,
  thousand: ',',
  decimal: '.',
};

export const CURRENCY_THAT_USES_DOT_IN_THOUSAND = [CurrencyCode.EUR];

const keys = Object.keys(CURRENCY_MAPPING_BY_SUPPORTED_COUNTRYCODE);
const SUPPORTED_COUNTRIES_BY_CODE = {};
keys.forEach((key) => (SUPPORTED_COUNTRIES_BY_CODE[key] = key));

export const SUPPORTED_EUROPEAN_UNION_COUNTRY_CODES = [
  SUPPORTED_COUNTRIES_BY_CODE.NL,
  SUPPORTED_COUNTRIES_BY_CODE.DE,
  SUPPORTED_COUNTRIES_BY_CODE.IE,
  SUPPORTED_COUNTRIES_BY_CODE.BE,
  SUPPORTED_COUNTRIES_BY_CODE.AT,
  SUPPORTED_COUNTRIES_BY_CODE.FI,
];

export const currencyCodeByCorpCountry = (corpCountry) => {
  return CURRENCY_MAPPING_BY_SUPPORTED_COUNTRYCODE[corpCountry] ?? 'USD';
};

export const signUpCurrency = (businessCountry) => {
  if (SUPPORTED_EUROPEAN_UNION_COUNTRY_CODES.includes(businessCountry)) {
    businessCountry = 'EU';
  }

  switch (businessCountry) {
    case 'US':
      return { symbol: '$', label: 'US Dollars' };
    case 'GB':
      return { symbol: '£', label: 'GB Pounds' };
    case 'AU':
      return { symbol: '$', label: 'Australian Dollars' };
    case 'EU':
      return { symbol: '€', label: 'Euros' };
    case 'SG':
      return { symbol: '$', label: 'Singapore Dollars' };
    default:
      return { symbol: '$', label: 'Canadian Dollars' };
  }
};

export const determineCurrencyMultiplier = (currencyCode) => {
  // determines the multiplier required to move between a currency's lowest denomination (ex. cents)
  // and its base unit (ex. dollars)
  if (currencyCode) {
    const decimalDigits =
      SUPPORTED_CURRENCY_SYMBOLS[currencyCode].decimal_digits;
    return 10 ** decimalDigits;
  }
  // default to usd if no currencyCode is submitted.
  return 100;
};

export function dollarsToCents(dollars, currencyCode) {
  return Number(dollarsToCentsBigint(Number(dollars), currencyCode));
}

export function centsToDollars(cents, currencyCode) {
  return cents / determineCurrencyMultiplier(currencyCode);
}

export function formatMoney(
  value,
  currencyCode,
  specificFormattingOverrides,
  noDecimal = false,
) {
  // specificFormattingOverrides takes options that are passed through to accounting
  // alternatively you can pass true or false to force showing / hiding cents
  // if you pass nothing, it will show cents if they are present and not otherwise
  let formattingOptions = defaultFormattingOptions;
  if (currencyCode) {
    formattingOptions = {
      format: '%s%v',
      symbol: SUPPORTED_CURRENCY_SYMBOLS[currencyCode].symbol_native,
      precision: SUPPORTED_CURRENCY_SYMBOLS[currencyCode].decimal_digits,
      thousand: CURRENCY_THAT_USES_DOT_IN_THOUSAND.includes(currencyCode)
        ? '.'
        : ',',
      decimal: CURRENCY_THAT_USES_DOT_IN_THOUSAND.includes(currencyCode)
        ? ',' // if the currency uses a dot in thousand, then it uses a comma in the decimal part
        : '.',
    };
  }
  if (
    typeof specificFormattingOverrides === 'object' &&
    specificFormattingOverrides !== null
  ) {
    Object.assign(formattingOptions, specificFormattingOverrides);
  }

  let amount = parseFloat(value) / determineCurrencyMultiplier(currencyCode);

  if (Number.isNaN(amount)) amount = 0.0;
  let formattedAmount = accounting.formatMoney(amount, {
    ...formattingOptions,
  });

  // TODO: validate what this is doing
  if (specificFormattingOverrides === false) {
    formattedAmount = formattedAmount.replace(/\.00$/, '');
  }

  if (noDecimal)
    formattedAmount = formattedAmount
      .split(
        // spliting according to the currency type (if is dot or comma in the decimal part)
        // if the currency uses a dot in thousand, then it uses a comma in the decimal part
        CURRENCY_THAT_USES_DOT_IN_THOUSAND.includes(currencyCode) ? ',' : '.',
      )[0]
      .substring(1);

  return formattedAmount;
}

// Requirements based on GoCardless for UK and Australia can be found at https://developer.gocardless.com/api-reference/#appendix-local-bank-details
// Requirements based on Stripe for US and Canada can be found at https://stripe.com/docs/connect/payouts

const ACCOUNT_NUMBER_REQUIREMENTS_BY_CURRENCY = {
  AUD: {
    regex: '^[0-9]{5,9}$',
    errorMessage: 'Must be a valid 5 to 9 digit account number',
  },
  GBP: {
    regex: '^[0-9]{6,8}$',
    errorMessage: 'Must be a valid 6 to 8 digit account number',
  },
  USD: {
    regex: '^[0-9]{5,17}$',
    errorMessage: 'Must be a valid 5 to 17 digit account number',
  },
  CAD: {
    regex: '^[0-9]{7,12}$',
    errorMessage: 'Must be a valid 7 to 12 digit account number',
  },
};

const BRANCH_CODE_REQUIREMENTS_BY_CURRENCY = {
  AUD: {
    // BSB Code
    regex: '^[0-9]{3}-?[0-9]{3}$',
    errorMessage: 'Must be a valid 6 digit BSB code',
  },
  GBP: {
    // Sort Code
    regex: '^[0-9]{6}$',
    errorMessage: 'Must be a valid 6 digit sort code',
  },
  USD: {
    // Routing Number
    regex: '^[0-9]{9}$',
    errorMessage: 'Must be a valid 9 digit account number',
  },
  CAD: {
    // Routing Number
    regex: '^[0-9]{7,8}$',
    errorMessage: 'Must be a valid 7 to 8 digit account number',
  },
};

export function getAccountNumberRequirements(account) {
  return (
    ACCOUNT_NUMBER_REQUIREMENTS_BY_CURRENCY[account.currencyCode] ||
    ACCOUNT_NUMBER_REQUIREMENTS_BY_CURRENCY.USD
  );
}

export function getBranchCodeRequirements(account) {
  return (
    BRANCH_CODE_REQUIREMENTS_BY_CURRENCY[account.currencyCode] ||
    BRANCH_CODE_REQUIREMENTS_BY_CURRENCY.USD
  );
}

export const IBAN_REQUIREMENTS_BY_COUNTRY = {
  AT: {
    // https://bank.codes/iban/structure/austria/
    regex: '^(AT)([0-9]{18})$',
    errorMessage: 'Must be a valid Austria IBAN',
  },
  BE: {
    // https://bank.codes/iban/structure/belgium/
    regex: '^(BE)([0-9]{14})$',
    errorMessage: 'Must be a valid Belgium IBAN',
  },
  CH: {
    // https://bank.codes/iban/structure/switzerland/
    regex: '^(CH)([0-9]{7})([A-Za-z0-9]{12})$',
    errorMessage: 'Must be a valid Switzerland IBAN',
  },
  CY: {
    // https://bank.codes/iban/structure/cyprus/
    regex: '^(CY)([0-9]{10})([A-Za-z0-9]{16})$',
    errorMessage: 'Must be a valid Cyprus IBAN',
  },
  CZ: {
    // https://bank.codes/iban/structure/czech-republic/
    regex: '^(CZ)([0-9]{22})$',
    errorMessage: 'Must be a valid Czech Republic IBAN',
  },
  DE: {
    // https://bank.codes/iban/structure/germany/
    regex: '^(DE)([0-9]{20})$',
    errorMessage: 'Must be a valid Germany IBAN',
  },
  DK: {
    // https://bank.codes/iban/structure/denmark/
    regex: '^(DK)([0-9]{16})$',
    errorMessage: 'Must be a valid Denmark IBAN',
  },
  EE: {
    // https://bank.codes/iban/structure/estonia/
    regex: '^(EE)([0-9]{18})$',
    errorMessage: 'Must be a valid Estonia IBAN',
  },
  ES: {
    // https://bank.codes/iban/structure/spain/
    regex: '^(ES)([0-9]{22})$',
    errorMessage: 'Must be a valid Spain IBAN',
  },
  FI: {
    // https://bank.codes/iban/structure/finland/
    regex: '^(FI)([0-9]{16})$',
    errorMessage: 'Must be a valid Finland IBAN',
  },
  FR: {
    // https://bank.codes/iban/structure/france/
    regex: '^(FR)([0-9]{12})([A-Za-z0-9]{11})([0-9]{2})$',
    errorMessage: 'Must be a valid France IBAN',
  },
  GB: {
    // https://bank.codes/iban/structure/united-kingdom/
    regex: '^(GB)([0-9]{2})([A-Za-z]{4})([0-9]{14})$',
    errorMessage: 'Must be a valid United Kingdom IBAN',
  },
  GR: {
    // https://bank.codes/iban/structure/greece/
    regex: '^(GR)([0-9]{9})([A-Za-z0-9]{16})$',
    errorMessage: 'Must be a valid Greece IBAN',
  },
  HR: {
    // https://bank.codes/iban/structure/croatia/
    regex: '^(HR)([0-9]{19})$',
    errorMessage: 'Must be a valid Croatia IBAN',
  },
  HU: {
    // https://bank.codes/iban/structure/hungary/
    regex: '^(HU)([0-9]{26})$',
    errorMessage: 'Must be a valid Hungary IBAN',
  },
  IE: {
    // https://bank.codes/iban/structure/ireland/
    regex: '^(IE)([0-9]{2})([A-Za-z]{4})([0-9]{14})$',
    errorMessage: 'Must be a valid Ireland IBAN',
  },
  IS: {
    // https://bank.codes/iban/structure/iceland/
    regex: '^(IS)([0-9]{24})$',
    errorMessage: 'Must be a valid Iceland IBAN',
  },
  IT: {
    // https://bank.codes/iban/structure/italy/
    regex: '^(IT)([0-9]{2})([A-Za-z]{1})([0-9]{10})([A-Za-z0-9]{12})$',
    errorMessage: 'Must be a valid Italy IBAN',
  },
  LI: {
    // https://bank.codes/iban/structure/liechtenstein/
    regex: '^(LI)([0-9]{7})([A-Za-z0-9]{12})$',
    errorMessage: 'Must be a valid Liechtenstein IBAN',
  },
  LT: {
    // https://bank.codes/iban/structure/lithuania/
    regex: '^(LT)([0-9]{18})$',
    errorMessage: 'Must be a valid Lithuania IBAN',
  },
  LU: {
    // https://bank.codes/iban/structure/luxembourg/
    regex: '^(LU)([0-9]{5})([A-Za-z0-9]{13})$',
    errorMessage: 'Must be a valid Lithuania IBAN',
  },
  LV: {
    // https://bank.codes/iban/structure/latvia/
    regex: '^(LV)([0-9]{2})([A-Za-z]{4})([A-Za-z0-9]{13})$',
    errorMessage: 'Must be a valid Latvia IBAN',
  },
  MC: {
    // https://bank.codes/iban/structure/monaco/
    regex: '^(MC)([0-9]{12})([A-Za-z0-9]{11})([0-9]{2})$',
    errorMessage: 'Must be a valid Monaco IBAN',
  },
  MT: {
    // https://bank.codes/iban/structure/malta/
    regex: '^(MT)([0-9]{2})([A-Za-z]{4})([0-9]{5})([A-Za-z0-9]{18})$',
    errorMessage: 'Must be a valid Malta IBAN',
  },
  NL: {
    // https://bank.codes/iban/structure/netherlands/
    regex: '^(NL)([0-9]{2})([A-Za-z]{4})([0-9]{10})$',
    errorMessage: 'Must be a valid Netherlands IBAN',
  },
  PL: {
    // https://bank.codes/iban/structure/poland/
    regex: '^(PL)([0-9]{26})$',
    errorMessage: 'Must be a valid Poland IBAN',
  },
  PT: {
    // https://bank.codes/iban/structure/portugal/
    regex: '^(PT)([0-9]{23})$',
    errorMessage: 'Must be a valid Portugal IBAN',
  },
  RO: {
    // https://bank.codes/iban/structure/romania/
    regex: '^(RO)([0-9]{2})([A-Za-z]{4})([A-Za-z0-9]{16})',
    errorMessage: 'Must be a valid Romania IBAN',
  },
  SI: {
    // https://bank.codes/iban/structure/slovenia/
    regex: '^(SI)[0-9]{17}$',
    errorMessage: 'Must be a valid Slovenia IBAN',
  },
  SK: {
    // https://bank.codes/iban/structure/slovakia/
    regex: '^(SK)([0-9]{22})$',
    errorMessage: 'Must be a valid Slovak IBAN',
  },
  SM: {
    // https://bank.codes/iban/structure/san-marino/
    regex: '^(SM)([0-9]{2})([A-Za-z]{1})([0-9]{10})([A-Za-z0-9]{12})$',
    errorMessage: 'Must be a valid San Marino IBAN',
  },
  SE: {
    // https://bank.codes/iban/structure/sweden/
    regex: '^(SE)([0-9]{22})',
    errorMessage: 'Must be a valid Sweden IBAN',
  },
  OTHER: {
    // Norway and non-SEPA
    regex: '[A-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}',
    errorMessage: 'Must be a valid IBAN',
  },
};
