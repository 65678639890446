// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".back-button1-content_fLYWd .back-button_dSuQE{cursor:pointer;width:18px;height:18px;margin:auto auto auto 0}.back-button2-content_rk7hG{display:flex;justify-content:flex-start}.back-button2-content_rk7hG svg{cursor:pointer;width:18px;height:18px;margin:auto 0 auto 0}.back-button2-content_rk7hG p{cursor:pointer;color:#000;text-decoration:underline;font-weight:700}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back-button1-content": "back-button1-content_fLYWd",
	"back-button": "back-button_dSuQE",
	"back-button2-content": "back-button2-content_rk7hG"
};
export default ___CSS_LOADER_EXPORT___;
