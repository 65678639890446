// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_rKl75[data-v-11881dcc]{display:flex;flex-direction:column}.footer__item_ftMPF+.footer__item_ftMPF[data-v-11881dcc]{margin:24px 0 0 0}@media (min-width:768px){.footer_rKl75[data-v-11881dcc]{flex-direction:row;justify-content:space-between}.footer__item_ftMPF+.footer__item_ftMPF[data-v-11881dcc]{margin:0 0 0 24px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer_rKl75",
	"footer__item": "footer__item_ftMPF"
};
export default ___CSS_LOADER_EXPORT___;
