<template>
  <div :class="$style.wrapper" :style="wrapperCustomStyles">
    <OnboardingUiMsg v-if="showErrorMessage" type="error">
      {{ CONTACT_SUPPORT_MSG }}
    </OnboardingUiMsg>
    <VeeForm
      class="c-form"
      :validation-schema="schema"
      :initial-values="initialValues"
      data-cy="form"
      @submit="onSubmit"
    >
      <div class="c-form__row">
        <InputTextGroup name="firstName" label="First Name" />
      </div>
      <div class="c-form__row">
        <InputTextGroup name="lastName" label="Last Name" />
      </div>
      <div class="c-form__row">
        <FormPhoneInput
          :dropdown-attrs="{
            name: 'countryCallingCode',
            options: countryCallingCodes,
            optionLabel: 'nameString',
            placeholder: 'Select',
            filter: true,
          }"
          :input-attrs="{
            name: 'phoneNumber',
            label: 'Phone',
          }"
        />
      </div>
      <div class="c-form__controls">
        <DSButton
          label="Next"
          class="m-auto p-button-secondary"
          :class="$style.button"
          type="submit"
          :loading="isLoading"
          data-cy="next-button"
        />
      </div>
    </VeeForm>
  </div>
</template>

<script>
import { CONTACT_SUPPORT_MSG } from '@/data/error-messages';
import OnboardingUiMsg from '@/components/UiMsg';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import analytics from '@/utils/analytics';
import { CLEARPAY_SEGMENT_EVENTS } from '@/data/payments';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import { REGEXES } from '@clearbanc/validation';
import { Form as VeeForm } from 'vee-validate';
import { object, string } from 'yup';
import { getAllCallingCodes } from '@/utils/phone-numbers';
import DSButton from '@clearbanc/clear-components/button';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import FormPhoneInput from '@/components/forms/FormPhoneInput';

export default {
  components: {
    OnboardingUiMsg,
    VeeForm,
    DSButton,
    InputTextGroup,
    FormPhoneInput,
  },
  data() {
    return {
      showErrorMessage: false,
      countryCallingCodes: getAllCallingCodes(),
      initialValues: {},
      isLoading: false,
      schema: object({
        firstName: string()
          .required(this.$t('common.required'))
          .matches(
            REGEXES.NAME,
            'Only letters, valid punctuation, and no consecutive or trailing spaces',
          ),
        lastName: string()
          .required(this.$t('common.required'))
          .matches(
            REGEXES.NAME,
            'Only letters, valid punctuation, and no consecutive or trailing spaces',
          ),
        phoneNumber: string()
          .nullable()
          .when('countryCallingCode', (countryCallingCode, schema) => {
            // https://www.npmjs.com/package/libphonenumber-js
            if (countryCallingCode) {
              return schema
                .test(
                  'is-valid-phone-number',
                  this.$t('common.phoneNumberNotValid'),
                  (value) => {
                    return (
                      isPossiblePhoneNumber(
                        value || '',
                        countryCallingCode?.value,
                      ) &&
                      isValidPhoneNumber(value || '', countryCallingCode?.value)
                    );
                  },
                )
                .required(this.$t('common.required'));
            }

            return schema.required(this.$t && this.$t('common.required'));
          }),
      }),
    };
  },
  computed: {
    CONTACT_SUPPORT_MSG() {
      return CONTACT_SUPPORT_MSG(this.contactDetails.number);
    },
    ...mapGetters([
      'user',
      'paymentsOnboardingSteps',
      'contactDetails',
      'isEmailVerified',
    ]),
    ...mapRequestStatuses({
      updateUserRequest: 'UPDATE_USER',
    }),
    currentRoute() {
      return this.$route.name;
    },
    COUNTRY_CODES: () => COUNTRY_CODES,
    wrapperCustomStyles() {
      const marginTop = this.isEmailVerified ? '35px' : undefined;
      return { marginTop };
    },
  },
  beforeMount() {
    this.updateInitialValues();
  },
  methods: {
    parsePhoneNumber,
    updateInitialValues() {
      if (this.user?.firstName) {
        this.initialValues.firstName = this.user.firstName;
      }

      if (this.user?.lastName) {
        this.initialValues.lastName = this.user.lastName;
      }

      if (this.user?.phoneNumber) {
        const parsedPhoneNumber = this.parsePhoneNumber(
          this.user.phoneNumber,
          this.user.addressCountryCode || COUNTRY_CODES.US,
        );
        const callingCode = this.countryCallingCodes.find((item) => {
          return item.value === parsedPhoneNumber.country;
        });

        this.initialValues.countryCallingCode = callingCode;
        this.initialValues.phoneNumber = parsedPhoneNumber.nationalNumber;
      } else {
        this.initialValues.countryCallingCode = this.countryCallingCodes.find(
          (item) => {
            return item.value === 'US';
          },
        );
      }
    },
    async onSubmit(values) {
      this.isLoading = true;

      const formValues = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: `+${values.countryCallingCode?.callingCode}${values.phoneNumber}`,
      };

      await this.$store.dispatchApiAction('UPDATE_USER', formValues);

      if (this.updateUserRequest.isError) {
        this.showErrorMessage = true;
        const errorMessage = this.updateUserRequest.errorMessage;
        analytics.track('clear_pay_error', {
          errorMessage,
        });
      } else {
        analytics.track(CLEARPAY_SEGMENT_EVENTS.SUBMITTED_BASIC_PROFILE);
        this.$store.dispatch(
          'UPDATE_PAYMENTS_ONBOARDING_STEP',
          this.currentRoute,
        );
        this.$router.push({
          name: this.paymentsOnboardingSteps[this.currentRoute].nextRoute.name,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="less" module>
.wrapper {
  max-width: 300px;
  margin: 0 auto;
}

.button {
  min-width: 186px;
}
</style>
