// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_IuEcg[data-v-95e60a92]{background:var(--neutral-grey-50,#f8f8fa);position:absolute;top:0;left:0;padding:20px;align-items:center;justify-content:center;overflow:scroll}.container_G2ySm[data-v-95e60a92],.wrapper_IuEcg[data-v-95e60a92]{width:100%;height:100%;display:flex}.container_G2ySm[data-v-95e60a92]{max-width:1128px;max-height:830px;flex-direction:column;justify-content:stretch}.box_gAiZh[data-v-95e60a92]{border-radius:4px;border:1px solid var(--neutral-grey-200,#ceced0);background:var(--neutral-white,#fff);box-shadow:0 2px 8px 0 rgba(0,0,0,.06);padding:20px;flex-grow:1}.footer_c5WnA[data-v-95e60a92]{padding:24px 0}@media (min-width:768px){.box_gAiZh[data-v-95e60a92]{padding:80px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_IuEcg",
	"container": "container_G2ySm",
	"box": "box_gAiZh",
	"footer": "footer_c5WnA"
};
export default ___CSS_LOADER_EXPORT___;
