import { COLOR_THEMES } from '@/data/color-themes';
import {
  BillLabel,
  BillPaymentLabels,
  BillPaymentMethods,
  BillStatus,
  messageToFounderTitles,
  TransactionStatus,
  TransactionToBillStatus,
} from '@/data/payments';
import * as dateFns from 'date-fns';

export const getBillStatusLabel = (status, type = 'bill') => {
  const normalizedStatus =
    type === 'bill' ? status : TransactionToBillStatus[status];
  return BillLabel[normalizedStatus];
};

export const getBillStatusTheme = (status) => {
  const { SUCCESS, PENDING, IN_TRANSIT, CANCELED, FAILED, SETTLED, SUBMITTED } =
    TransactionStatus;
  const {
    UNREAD,
    PROCESSING,
    APPROVING_VENDOR,
    WAITING_ON_FOUNDER,
    WAITING_ON_CLEARCO,
    COMPLETED,
    ERROR,
    CANCELLED,
    SCHEDULED,
    PAYING_VENDOR,
    WAITING_FOR_PAYMENT,
    BNPL_APPROVED,
    BNPL_DECLINED,
    AUTO_DEBITING_ACTIVE,
  } = BillStatus;
  if ([SUCCESS, COMPLETED, BNPL_APPROVED, SETTLED].includes(status)) {
    return COLOR_THEMES.SUCCESS;
  } else if (
    [
      IN_TRANSIT,
      PENDING,
      SUBMITTED,
      PAYING_VENDOR,
      SCHEDULED,
      UNREAD,
      PROCESSING,
      APPROVING_VENDOR,
      WAITING_ON_FOUNDER,
      WAITING_ON_CLEARCO,
      WAITING_FOR_PAYMENT,
      AUTO_DEBITING_ACTIVE,
    ].includes(status)
  ) {
    return COLOR_THEMES.INFO;
  } else if (
    [CANCELED, FAILED, ERROR, CANCELLED, BNPL_DECLINED].includes(status)
  ) {
    return COLOR_THEMES.DANGER;
  }

  return COLOR_THEMES.WARNING;
};

export const getBillPaymentMethod = (paymentMethod) => {
  return BillPaymentLabels[paymentMethod];
};

export const founderBillMessage = (status) => {
  let messageToFounder = {};
  if (status === BillStatus.ERROR) {
    messageToFounder = {
      title: messageToFounderTitles[status],
      bannerType: 'error',
    };
  } else if (status === BillStatus.COMPLETED) {
    messageToFounder = {
      title: messageToFounderTitles[status],
      bannerType: 'success',
    };
  } else if (status === BillStatus.WAITING_ON_FOUNDER) {
    messageToFounder = {
      title: messageToFounderTitles[status],
      bannerType: 'warn',
    };
  }

  return messageToFounder;
};

export const getShortBillId = (id) => (id ? id.slice(-5).toUpperCase() : '---');

export const getBillFees = (paymentMethod) => {
  if (paymentMethod === BillPaymentMethods.CREDIT_CARD) {
    return '2.9%';
  }
  return 'Free';
};

export const dueDateToEasternDateTime = (dueDate) => {
  // being a no timezone date if it gets parsed to date will be parsed to UTC
  // just cropping the string displays the right date

  return dueDate ? dueDate.substring(0, 10) : '';
};

export const getFormattedDateString = (date) => {
  if (!date) {
    return '';
  }
  const dateUtc = new Date(date)
    .toISOString()
    .replace('T', ' ')
    .substring(0, 19);
  return dateFns.format(dateFns.parseISO(dateUtc), 'MMM dd, yyyy');
};

export const limitFileName = (str) => {
  const max = 25;
  return str.length > max ? `${str.substring(0, max)}...` : str;
};

export const userChangedTheForm = (initialValues, formOutput) => {
  return JSON.stringify(initialValues) !== JSON.stringify(formOutput);
};

export const validEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
