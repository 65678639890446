<template>
  <div :class="$style.wrapper">
    <router-view />
  </div>
</template>

<style lang="less" module>
.wrapper {
  min-width: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
