<template>
  <div v-if="showTable" :class="$style['pdf-list']">
    <DataTable :value="files">
      <Column field="name" header="File">
        <template #body="slotProps"
          ><span
            :class="[$style['file-text'], $style['blue-link']]"
            tabindex="0"
            @click="handleDownloadFile(slotProps.data.id)"
            @keyup.enter="handleDownloadFile(slotProps.data.id)"
          >
            {{ computedName(slotProps.data) }}</span
          ></template
        >
      </Column>
      <Column field="bank_name" header="Bank"></Column>
      <Column field="account_number" header="Account Number"></Column>
      <Column field="statement_start_date" header="Start Date">
        <template #body="slotProps">
          {{ formatDate(slotProps.data.statement_start_date) }}
        </template>
      </Column>
      <Column field="statement_end_date" header="End Date">
        <template #body="slotProps">
          {{ formatDate(slotProps.data.statement_end_date) }}
        </template>
      </Column>
      <Column field="status" header="Status" style="text-align: center">
        <template #body="slotProps">
          <button
            v-tooltip.focus.bottom="{
              value: getStatus(slotProps.data).message,
              escape: true,
            }"
            class="status-icon"
          >
            <icon
              :name="getStatus(slotProps.data).icon"
              :class="getStatus(slotProps.data).status"
            />
          </button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import kebabCase from '@/utils/kebabcase';
import { downloadFileMixin } from '@/utils/vue-mixins';
import { sub, isBefore } from 'date-fns';
import DataTable from '@clearbanc/clear-components/datatable';
import Column from '@clearbanc/clear-components/column';

export default {
  components: {
    DataTable,
    Column,
  },
  mixins: [downloadFileMixin],
  props: {
    files: { type: Array },
    canDeleteFile: { type: Boolean, default: true },
    shortenFilenames: { type: Boolean, default: false },
    showEmptyTable: { type: Boolean, default: false },
    accountNumber: { type: String },
  },
  data() {
    return {
      expandedRowGroups: null,
    };
  },
  computed: {
    fileType() {
      return this.files[0].type;
    },
    showTable() {
      return this.files.length || (!this.files.length && this.showEmptyTable);
    },
  },
  methods: {
    kebabCase,
    computedName(file) {
      if (this.shortenFilenames) {
        const onlyFileName = file.name.replace(`.${file.extension}`, '');
        if (onlyFileName.length > 10) {
          return onlyFileName.slice(0, 10).concat(`...${file.extension}`);
        }
      }
      return file.name;
    },
    capitalizeAndReplaceUnderscores(string) {
      const words = string
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

      return words.join(' ');
    },
    async handleDownloadFile(id) {
      this.$emit('trackUploadedDocClicked');
      await this.downloadFile(id);
    },
    getStatus(file) {
      const oneHourAgo = sub(new Date(), { hours: 1 });
      if (
        !file.statement_start_date &&
        isBefore(new Date(file.created_at ?? ''), oneHourAgo)
      ) {
        return {
          status: 'processing',
          message:
            'Processing is taking longer than usual, please check back later.',
          icon: 'info-circle',
        };
      } else if (!file.statement_start_date) {
        return {
          status: 'processing',
          message: 'Processing - This could take a few minutes',
          icon: 'spinner',
        };
      } else if (!this.isPrimaryAccountMatch(file)) {
        return {
          status: 'error',
          message: `Account number does not match the selected primary account ${this.accountNumber} and will be excluded.`,
          icon: 'exclamation-circle',
        };
      }
      return {
        status: 'success',
        message: 'Processing has completed',
        icon: 'check-circle',
      };
    },
    isPrimaryAccountMatch(file) {
      const fileAccountNumber = file.account_number
        ?.replace(/[^a-zA-Z0-9]/g, '')
        ?.slice(-4);
      return fileAccountNumber === this.accountNumber;
    },
    formatDate(date) {
      if (!date) return '';
      return date.split('T')[0].replace(/-/g, '/');
    },
  },
};
</script>

<style lang="less">
.status-icon {
  .error {
    color: #fa2d40;
  }
  .success {
    color: #446e40;
  }
  .processing {
    color: #569eb8;
  }
}
</style>

<style lang="less" module>
.pdf-list {
  text-align: left;
  .pdf-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
  .processing {
    margin-left: 10px;
    text-align: center;
  }
}
.file {
  font-size: 18px;
}

.file-text {
  cursor: pointer;
  text-decoration: underline;
  &.blue-link {
    color: @link-blue;
  }
  &.closable-list {
    text-align: left;
  }
}

.file-button {
  cursor: pointer;
  width: 18px;
  height: 18px;
}
</style>
