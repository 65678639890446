<template>
  <div>
    <FormOwners
      v-if="isImprovedKyFlowEnabled"
      :selected-owner="selectedOwner"
      :business-corp-country="business.corpCountry"
    />
    <FormOwnersStepOne
      v-else
      ref="form"
      :event-tracking-fields="commonTrackingFields"
      @validationSuccess="handleValidationSuccess"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import analytics from '@/utils/analytics';
import { mapStateModuleGetters } from '@/utils/state-modules';
import stateModuleAware from '@/mixins/state-module-aware';
import { FormOwnersStepOne } from '@/components';
import FormOwners from '@/components/forms/FormOwners';

export default {
  components: {
    FormOwnersStepOne,
    FormOwners,
  },
  mixins: [stateModuleAware],
  props: {
    theme: { type: String, default: 'black' },
  },
  computed: {
    ...mapGetters([
      'business',
      'user',
      'productSegmentLabel',
      'isImprovedKyFlowEnabled',
      'selectedOwner',
    ]),
    ...mapStateModuleGetters(['steps', 'experimentName']),
    buttonClasses() {
      return {
        [this.$style[`theme--${this.theme}`]]: !!this.theme,
      };
    },
    currentRoute() {
      return this.$route.name;
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        targetArea: this.currentRoute,
        experiment: this.experimentName,
      };
    },
  },
  methods: {
    trackEvent(name, props) {
      analytics.track(`fe_${name}`, { ...this.commonTrackingFields, ...props });
    },
    async handleNextStepClick() {
      this.trackEvent('onboarding_next_step_click');
      this.$refs.form.validateForm();
    },
    async handleValidationSuccess() {
      this.trackEvent('self_serve_stage_complete', {
        businessId: this.business.id,
        userId: this.user.id,
        stage: this.currentRoute,
      });
      await this.dispatchToStateModule('UPDATE_STEP', {
        step: this.currentRoute,
      });
      this.$router
        .push({ name: this.steps[this.currentRoute].nextRoute.name })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" module>
.theme--purple {
  button {
    background-color: @color-cc-purple-200;
    &:hover {
      background-color: @color-cc-purple-200 !important;
    }
  }
}
</style>
