export const PRODUCT_SEGMENT = {
  CORE: 'core',
  VC: 'vc',
  EXTEND_RUNWAY: 'extend_runway',
  MRR_FACTORING: 'mrr_factoring',
  CHARGE_CARD: 'charge_card',
  INSIGHTS: 'insights',
  INDIEGOGO: 'indiegogo',
  BETA: 'beta',
  BNPL: 'bnpl',
};
