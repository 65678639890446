<template>
  <div :class="$style['clearco-card-layout']">
    <a href="#" @click.prevent="this.$router.back()">
      <icon style="color: black; width: 16px" name="chevron-left" />
      <h3>Clearco Card</h3>
    </a>
    <message severity="warn" :closable="false">
      <h3>NOTICE EFFECTIVE NOVEMBER 15, 2023</h3>
      <p>
        The Visa virtual card used to access your advance funds has been
        sunsetted on November 15, 2023. To access your remaining advance funds,
        submit your invoice(s) via
        <router-link :to="PAYMENTS_ROUTE_NAMES.VENDOR_LIST"
          >"Pay a Vendor"</router-link
        >
        on your dashboard. Have questions? Reach out to our Support Team at
        support@clear.co or call +1 (415) 610 5166.
      </p>
    </message>

    <WayfindingContentLayout
      :title="$t('account.pages.subpageClearbancCard.clearcoCardDetails')"
      class="clearbanc-card-subpage"
    >
      <div v-if="fetchCardsRequest.isPendingOrEmpty">
        {{ $t('common.buttons.loadingPending') }}
      </div>
      <div v-else-if="!card">
        {{ $t('account.pages.subpageClearbancCard.youDoNotHaveAClearcoCard') }}
      </div>
      <template v-else>
        <warning-banner
          v-if="!isActiveCard"
          :title="
            $t('account.pages.subpageClearbancCard.youDoNotHaveAClearcoCard')
          "
        >
        </warning-banner>
        <clearbanc-card-widget />
        <form-row no-inputs="no-inputs">
          <progress-bar
            is-card
            :success="cardSpendStats.totalSpentCents"
            :total="cardSpendStats.totalLimitCents"
            :currency-code="advanceCurrencyCode"
          />
        </form-row>
        <form-row v-if="getUserAdvancesRequest.isSuccess">
          <form-input
            type="container"
            :label="$t('account.pages.subpageClearbancCard.totalSpent')"
          >
            {{
              $filters.currency(
                cardSpendStats.totalSpentCents,
                advanceCurrencyCode,
              )
            }}
            <router-link
              :to="{ name: 'spend-transactions' }"
              :title="
                $t('account.pages.subpageClearbancCard.viewSpendTransactions')
              "
              style="white-space: nowrap"
              >{{
                $t('account.pages.subpageClearbancCard.viewTransactions')
              }}</router-link
            >
          </form-input>
          <form-input
            type="container"
            :label="$t('account.pages.subpageClearbancCard.availableBalance')"
          >
            {{
              $filters.currency(
                cardSpendStats.totalRemainingCents,
                advanceCurrencyCode,
              )
            }}
          </form-input>
          <form-input
            type="container"
            :label="$t('account.pages.subpageClearbancCard.totalLimit')"
          >
            {{
              $filters.currency(
                cardSpendStats.totalLimitCents,
                advanceCurrencyCode,
                false,
              )
            }}
          </form-input>
        </form-row>

        <message severity="error" :closable="false">
          <h3>NOTICE EFFECTIVE NOVEMBER 15, 2023</h3>
          <p>
            The Visa virtual card used to access your advance funds will be
            sunsetted on November 15, 2023. To access your remaining advance
            funds, submit your invoice(s) via
            <router-link :to="PAYMENTS_ROUTE_NAMES.VENDOR_LIST"
              >"Pay a Vendor"</router-link
            >
            on your dashboard. Have questions? Reach out to our Support Team at
            support@clear.co or call +1 (415) 610 5166.
          </p>
        </message>
      </template>
    </WayfindingContentLayout>
  </div>
</template>

<script>
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import Message from '@clearbanc/clear-components/message';
import { useMeta } from 'vue-meta';
import Button from '@clearbanc/clear-components/button';
import UiIconButton from '@clearbanc/design-components/src/components/ui-icon-button';

const components = {
  'progress-bar': require('@/components/progress/ProgressBar').default,
  'clearbanc-card-widget': require('@/components/WidgetClearbancCard').default,
  pagination: require('@/components/Pagination').default,
  WayfindingContentLayout: require('@/layout/LayoutContentWayfinding').default,
  'warning-banner': require('@/components/banners/BannerWarning').default,
  Message,
  Button,
  UiIconButton,
};

export default {
  components,
  setup() {
    useMeta({
      title: 'Clearco card details | Clearco',
    });
  },
  data() {
    return {};
  },
  computed: {
    PAYMENTS_ROUTE_NAMES: () => PAYMENTS_ROUTE_NAMES,
    ...mapGetters({
      card: 'issuedClearbancCard',
      totalCreditLimitCents: 'totalCreditLimitCents',
      totalCreditSpentCents: 'totalCreditSpentCents',
      totalCreditRemainingCents: 'totalCreditRemainingCents',
      totalVenturesCreditLimitCents: 'totalVenturesCreditLimitCents',
      totalVenturesCreditSpentCents: 'totalVenturesCreditSpentCents',
      totalVenturesCreditRemainingCents: 'totalVenturesCreditRemainingCents',
      usersAdvances: 'activeAdvances',
    }),
    ...mapRequestStatuses({
      fetchCardsRequest: 'FETCH_USER_CARDS',
      getUserAdvancesRequest: ['GET_USER_ADVANCES'],
    }),
    isActiveCard() {
      return this.card.isActive;
    },
    advanceCurrencyCode() {
      const { currencyCode } = this.usersAdvances[0] || {};
      return currencyCode;
    },
    cardSpendStats() {
      return {
        totalSpentCents:
          Number(this.totalCreditSpentCents) +
          Number(this.totalVenturesCreditSpentCents),
        totalRemainingCents:
          Number(this.totalCreditRemainingCents) +
          Number(this.totalVenturesCreditRemainingCents),
        totalLimitCents:
          Number(this.totalCreditLimitCents) +
          Number(this.totalVenturesCreditLimitCents),
      };
    },
  },
  mounted() {
    this.$store.dispatchApiAction('FETCH_USER_CARDS', { canceled: false });
  },
};
</script>

<style lang="less" module>
.clearco-card-layout {
  margin: 20px auto;
  max-width: 85%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Gerstner-Programm';
  font-weight: 300;

  a {
    display: flex;
    gap: 16px;
    align-items: center;

    h3 {
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 21px;
    }
  }
}
</style>
