<template>
  <div>
    <div v-if="isMenuOpen" :class="$style['mobile-menu']">
      <template v-if="!userIsLoggedIn">
        <a :class="$style.link" :href="addLocaleParam(clearbancURL + '/faq')">
          <ui-header size="small" :class="$style['mobile-header']">{{
            $t('common.faq')
          }}</ui-header>
        </a>
        <a
          :class="$style.link"
          :href="addLocaleParam(clearbancURL + '/partners')"
        >
          <ui-header size="small" :class="$style['mobile-header']">{{
            $t('components.navBar.partners')
          }}</ui-header>
        </a>
        <a :class="$style.link" href="/">
          <ui-header size="small" :class="$style['mobile-header']">{{
            $t('common.buttons.logIn')
          }}</ui-header>
        </a>
        <!-- Anchor tag used intentionally inplace of router-link -->
        <!-- Page must reload to trigger cloudflare worker in order to obtain ip country -->
        <a :href="'payments/onboarding/signup'" aria-label="sign up link"
          ><ui-a
            secondary
            :text="$t('components.navBar.signMeUp')"
            :class="$style['signup-link']"
        /></a>
        <br />
      </template>
    </div>
    <div
      data-cy="mobile-menu-button"
      :class="$style['mobile-menu-button']"
      tabindex="0"
      @click="isMenuOpen = !isMenuOpen"
      @keyup.enter="isMenuOpen = !isMenuOpen"
    >
      <div v-if="!isMenuOpen">
        <div :class="$style.line" />
        <div :class="$style.line" />
        <div :class="$style.line" />
      </div>
      <div v-else>
        <div :class="$style['close-icon']" />
      </div>
    </div>
  </div>
</template>

<script>
import { UiHeader, UiA } from 'ui-components';
import { mapGetters } from 'vuex';
import addLocaleParam from '@/utils/add-locale-param';

export default {
  components: {
    UiHeader,
    UiA,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters(['userIsLoggedIn']),
    clearbancURL() {
      return process.env.CLEARCOM_URL;
    },
  },
  methods: {
    addLocaleParam,
  },
};
</script>

<style lang="less" module>
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 1;
  color: white;
  padding-top: 80px;

  .mobile-header {
    color: white;
    font-family: 'Montserrat';
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: @gutter-60;
    width: 100%;
    position: relative;

    &:first-child {
      border-top: 1px solid @color-lightgray-400;
    }

    div {
      margin: 0;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 70%;
      height: 1px;
      background: @color-lightgray-400;
    }
  }

  .button {
    margin: 50px auto 0;
    display: block;
    font-family: 'Montserrat';
  }

  .signup-link {
    position: absolute;
    bottom: @gutter-80;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    color: #333;
    text-decoration: none !important;
    &:hover,
    &:focus,
    & {
      color: #333;
    }
  }
}

.mobile-menu-button {
  display: none;
  position: absolute;
  top: 36px;
  right: 20px;
  cursor: pointer;
  z-index: 2;

  .line {
    height: 3px;
    width: 24px;
    background: @color-black;

    &:nth-child(2) {
      margin: 5px 0;
    }
  }

  .close-icon {
    height: 30px;
    width: 30px;

    &::after {
      content: '\00d7';
      position: absolute;
      top: -2px;
      left: 0;
      color: white;
      font-size: 60px;
      font-weight: 100;
    }
  }

  @media only screen and (max-width: 1350px) {
    display: block;
  }
}
</style>
