// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_D6iEV{max-width:320px;margin:0 auto}.form_OrlwB{width:320px}.button_xl1ZB{min-width:186px}button.button-back_qKxMz{padding-left:0}@media (max-width:500px){.form_OrlwB{width:auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_D6iEV",
	"form": "form_OrlwB",
	"button": "button_xl1ZB",
	"button-back": "button-back_qKxMz"
};
export default ___CSS_LOADER_EXPORT___;
