// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_TAg4N{background:linear-gradient(0deg,rgba(97,47,255,.08) 25%,#fff);border:1px solid #caa8ff;border-bottom-right-radius:4px;border-bottom-left-radius:4px}.list_QyGNu{counter-reset:count}.list__item_MaoLg{padding:2rem;counter-increment:count}.list__item_MaoLg+.list__item_MaoLg{border-top:1px dashed #caa8ff}.list__item__content__H_J4{position:relative;padding-left:40px}.list__item__content__index_roNGZ{position:absolute;top:0;left:0;content:counter(count,decimal-leading-zero);font-weight:700;color:#732cf8}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_TAg4N",
	"list": "list_QyGNu",
	"list__item": "list__item_MaoLg",
	"list__item__content": "list__item__content__H_J4",
	"list__item__content__index": "list__item__content__index_roNGZ"
};
export default ___CSS_LOADER_EXPORT___;
