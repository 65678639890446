<template>
  <a
    :class="$style.link"
    :href="addLocaleParam(helpCenterURL)"
    target="_blank"
    >{{ $t('common.helpCenter') }}</a
  >
</template>
<script>
import { helpCenterURL } from '@/data/shared-faqs';
import addLocaleParam from '@/utils/add-locale-param';

export default {
  data() {
    return {
      helpCenterURL,
    };
  },
  methods: {
    addLocaleParam,
  },
};
</script>

<style lang="less" module>
.link {
  color: @cc-light-brown;
  text-decoration: underline;
}
</style>
