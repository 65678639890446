import store from '@/store';

// Google requires a global function to handle some of its errors, it is defined here
// because this requires custom logic depending on the page structure
window.gm_authFailure = () => {
  store.dispatch('globalAutocomplete', { loadError: true });
};

if (process.env.GOOGLE_PLACES_API_KEY) {
  (function () {
    const scriptTags = Array.from(document.getElementsByTagName('script'));
    const elementAlreadyExists = scriptTags.some(
      (tag) =>
        tag.src && tag.src.startsWith('https://maps.googleapis.com/maps'),
    );
    if (!elementAlreadyExists) {
      const firstScriptTag = scriptTags[0];
      const gs = document.createElement('script');
      gs.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLACES_API_KEY}&libraries=places&language=${store.getters.selectedLanguage}`;
      gs.async = 'true';
      gs.defer = true;
      gs.id = 'google-places-script';
      if (firstScriptTag) {
        firstScriptTag.parentNode.insertBefore(gs, firstScriptTag);
      }
    }
  })();
}
