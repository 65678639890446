<template>
  <use-funds-panel-lvl-1
    v-if="!showAdvanceType"
    :visible-advances-with-balance="visibleAdvancesWithBalance"
    @cta-clicked="ctaCallback"
  />
  <use-funds-panel-lvl-2
    v-else
    :advance-type="showAdvanceType"
    :funds-remaining="getFundsRemainingForAdvanceType(showAdvanceType)"
    :show-back-button="visibleAdvances.length !== 1"
    @back="backBtnCallback"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ADVANCE_SUBTYPES,
  ADVANCE_SUBTYPES_OPTIONS,
} from '@/data/advance-subtypes';
import UseFundsPanelLvl1 from './UseFundsPanelLvl1Financing';
import UseFundsPanelLvl2 from './UseFundsPanelLvl2Financing';

export default {
  components: {
    UseFundsPanelLvl1,
    UseFundsPanelLvl2,
  },
  data() {
    return {
      showAdvanceType: null,
      ADVANCE_SUBTYPES,
      ADVANCE_SUBTYPES_OPTIONS,
    };
  },
  computed: {
    ...mapGetters([
      'totalCreditLimitCents', // Unified (Marketing)
      'totalCreditRemainingCents', // Unified (Marketing)
      'totalVenturesCreditLimitCents',
      'totalVenturesCreditRemainingCents',
      'totalInventoryCreditLimitCents',
      'totalInventoryCreditRemainingCents',
    ]),
    visibleAdvances() {
      return this.ADVANCE_SUBTYPES_OPTIONS.filter((advanceType) =>
        this.isAdvanceTypeVisible(advanceType),
      );
    },
    visibleAdvancesWithBalance() {
      return this.visibleAdvances.map((advanceType) => {
        return {
          subtype: advanceType,
          balance: this.getFundsRemainingForAdvanceType(advanceType),
        };
      });
    },
  },
  // default to lvl-2 view if only one advance type ever taken
  watch: {
    visibleAdvances() {
      this.setAdvanceTypeIfOnlyOneVisible();
    },
  },
  mounted() {
    this.setAdvanceTypeIfOnlyOneVisible();
  },
  methods: {
    ctaCallback(data) {
      this.showAdvanceType = data;
    },
    backBtnCallback() {
      this.showAdvanceType = null;
    },
    // we display an advance type in lvl-1 view even if no funds remain
    // for now only unified, ventures, inventory supported on this page
    isAdvanceTypeVisible(advanceType) {
      const { UNIFIED, VENTURES, INVENTORY, BNPL, MARKETING_FACEBOOK } =
        this.ADVANCE_SUBTYPES;

      switch (advanceType) {
        case UNIFIED:
          return this.totalCreditLimitCents > 0;
        case VENTURES:
          return this.totalVenturesCreditLimitCents > 0;
        case INVENTORY:
          return this.totalInventoryCreditLimitCents > 0;
        case BNPL:
          return false;
        case MARKETING_FACEBOOK:
          return false;
        default:
          return false;
      }
    },
    getFundsRemainingForAdvanceType(advanceType) {
      const { UNIFIED, VENTURES, INVENTORY, BNPL, MARKETING_FACEBOOK } =
        this.ADVANCE_SUBTYPES;

      switch (advanceType) {
        case UNIFIED:
          return this.totalCreditRemainingCents;
        case VENTURES:
          return this.totalVenturesCreditRemainingCents;
        case INVENTORY:
          return this.totalInventoryCreditRemainingCents;
        case BNPL:
          return 0;
        case MARKETING_FACEBOOK:
          return 0;
        default:
          return 0;
      }
    },
    setAdvanceTypeIfOnlyOneVisible() {
      if (this.visibleAdvances.length === 1) {
        this.showAdvanceType = this.visibleAdvances[0];
      }
    },
  },
};
</script>
