<template>
  <span>
    <ext-account
      title="shopify_fivetran"
      :logo="shopify"
      :success="success"
      :disconnected="disconnected"
      :warning="warning"
      :processing="processing"
      :error="error"
      :logo-alt="$t('components.tileGroupConnectSales.shopifyLogo')"
      @click="openModal"
    />
    <!-- Shopify Fivetran Modal -->
    <ModalShopifyFivetran
      ref="shopifyFivetranModal"
      :redirect-uri="redirectRoute"
      @submit="continueShopifyOauth"
      @error="handleError"
      @modalAction="
        (platformName, action) => $emit('modalAction', platformName, action)
      "
    />
  </span>
</template>

<script>
import { isMobileMixin, modalsRedirectMixin } from '@/utils/vue-mixins';
import { SALES_PLATFORMS } from '@/data/platforms';
import ExtAccount from '@/components/modals/ModalExternalAccountOnboarding2';
import ModalShopifyFivetran from '@/components/modals/ModalShopifyFivetran';

export default {
  components: {
    ExtAccount,
    ModalShopifyFivetran,
  },
  mixins: [isMobileMixin, modalsRedirectMixin],
  props: {
    authorizeUri: {
      type: String,
      required: true,
    },
    success: Boolean,
    disconnected: Boolean,
    error: Boolean,
    warning: Boolean,
    processing: Boolean,
  },
  data() {
    return {
      storeName: '',
    };
  },
  computed: {
    shopify: () => require('@/assets/logos/shopify.png'),
    SALES_PLATFORMS: () => SALES_PLATFORMS,
  },
  methods: {
    openModal() {
      this.clearOauthParams();
      this.$emit('tileClick', SALES_PLATFORMS.SHOPIFY_FIVETRAN);
      this.$refs.shopifyFivetranModal.open();
    },
    continueShopifyOauth(storeName, authorizeUri) {
      this.storeName = storeName;
      const options = { authorizeUri };
      if (this.isMobile()) {
        options.noPopup = true;
        options.redirectRoute = this.redirectRoute;
        localStorage.setItem(
          'oauth-provider',
          SALES_PLATFORMS.SHOPIFY_FIVETRAN,
        );
        localStorage.setItem('oauth-redirect-route', this.redirectRoute);
      }
      this.$emit('submit', options);
    },
    handleError(errorMsg) {
      this.$emit('error', errorMsg);
    },
    clearOauthParams() {
      localStorage.removeItem('oauth-provider');
      localStorage.removeItem('oauth-redirect-route');
    },
  },
};
</script>
