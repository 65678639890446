<template>
  <div :class="$style['language-switcher-container']">
    <DropdownWithEyebrow
      :placeholder="$t('common.language')"
      name="lang"
      :options="SUPPORTED_LANGUAGE_LIST"
      :model-value="selectedLanguage"
      :class="$style['dropdown']"
      @input="switchLanguage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SUPPORTED_LANGUAGE_LIST,
  updateLanguage,
} from '@/configs/i18n-constants';
import DropdownWithEyebrow from '@/components/DropdownWithEyebrow';

export default {
  name: 'LanguageSwitcher',
  components: {
    DropdownWithEyebrow,
  },
  computed: {
    ...mapGetters(['selectedLanguage']),
    SUPPORTED_LANGUAGE_LIST: () => SUPPORTED_LANGUAGE_LIST,
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    async switchLanguage(language) {
      const previousLanguage = this.selectedLanguage;
      const nextLanguage = language;
      const target = this.currentRoute;
      updateLanguage(this.$store, { target, previousLanguage, nextLanguage });
    },
  },
};
</script>
<style lang="less" module>
.dropdown {
  width: 127px;
}
.language-switcher-container {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 70px auto 0;
  @media only screen and (max-width: 960px) {
    max-width: 340px;
  }
  @media only screen and (max-width: 500px) {
    margin: 0 auto;
  }
  @media only screen and (max-width: 460px) {
    position: absolute;
    top: 14px;
    right: 20px;
  }
}
</style>
