// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".clearco-card-layout_m0Dyv{margin:20px auto;max-width:85%;width:100%;display:flex;flex-direction:column;flex:1;font-family:Gerstner-Programm;font-weight:300}.clearco-card-layout_m0Dyv a{display:flex;gap:16px;align-items:center}.clearco-card-layout_m0Dyv a h3{font-family:Montserrat;font-weight:700;font-size:21px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clearco-card-layout": "clearco-card-layout_m0Dyv"
};
export default ___CSS_LOADER_EXPORT___;
