// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_gOfsN{min-height:100%;min-width:100%;position:absolute;background:#fff}.container_gOfsN .content__ZzkH{margin:0 auto;position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_gOfsN",
	"content": "content__ZzkH"
};
export default ___CSS_LOADER_EXPORT___;
