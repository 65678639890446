// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-wrapper_UqXaZ{display:flex;flex-flow:column;align-items:center}.header-wrapper_UqXaZ h2{margin:30px 0}@media only screen and (max-width:600px){.header-wrapper_UqXaZ{margin:30px 0;flex-flow:row;align-items:flex-end}.header-wrapper_UqXaZ h2{margin:0}.header-wrapper_UqXaZ img{margin-left:15px}}.bill-pay-popup-container_WJmk8{padding:0 40px}@media only screen and (max-width:600px){.bill-pay-popup-container_WJmk8{padding:0 5px}}.bnpl-card_Lx6LJ{max-width:526px;max-height:204px;margin:30px auto;display:flex;flex-direction:column;align-items:flex-start}.bnpl-card-title_NTYD8{padding-bottom:15px}.icon-text-group_GGSDW{display:flex;align-items:center;margin:2px 0}.icon_OM8cC{height:24px;width:24px;margin-right:5px}.paybill-bottom-action_IOhP2{display:flex;justify-content:space-between;align-items:center;margin:30px auto 0}button.button-add-invoice_GksWh{color:#7f30ff!important;margin-top:30px}button.button-done_wrBVX{padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-wrapper": "header-wrapper_UqXaZ",
	"bill-pay-popup-container": "bill-pay-popup-container_WJmk8",
	"bnpl-card": "bnpl-card_Lx6LJ",
	"bnpl-card-title": "bnpl-card-title_NTYD8",
	"icon-text-group": "icon-text-group_GGSDW",
	"icon": "icon_OM8cC",
	"paybill-bottom-action": "paybill-bottom-action_IOhP2",
	"button-add-invoice": "button-add-invoice_GksWh",
	"button-done": "button-done_wrBVX"
};
export default ___CSS_LOADER_EXPORT___;
