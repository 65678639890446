// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_NXb7g[data-v-3685af39]{padding:0;display:flex;justify-content:stretch;gap:16px}body button.footer__button_oJ5mb[data-v-3685af39]{margin:0;flex-grow:1;justify-content:center;width:50%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer_NXb7g",
	"footer__button": "footer__button_oJ5mb"
};
export default ___CSS_LOADER_EXPORT___;
