// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".payments-layout_jPV8q{margin:20px auto;max-width:85%;width:100%;display:flex;flex-direction:column;flex:1;font-family:Gerstner-Programm;font-weight:300}@media screen and (max-width:500px){.payments-layout_jPV8q{max-width:90%}}.payments-layout_jPV8q a{display:flex;gap:16px;align-items:center}.payments-layout_jPV8q h1{font-weight:500;line-height:1em;font-size:26px}.payments-layout_jPV8q h1,.payments-layout_jPV8q h2{font-family:Gerstner-Programm;font-style:normal}.payments-layout_jPV8q h2{font-weight:300;font-display:block;line-height:22px;font-size:16px}.payments-layout_jPV8q h3{font-family:Montserrat;font-weight:700;font-size:21px}.spinner_ge8Eu>div{margin:50px auto!important}.vendor-container_tghv2{width:98%;margin:12px;padding:20px 10px 10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-layout": "payments-layout_jPV8q",
	"spinner": "spinner_ge8Eu",
	"vendor-container": "vendor-container_tghv2"
};
export default ___CSS_LOADER_EXPORT___;
