// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".summary-container_sRLei{width:100%;margin-top:10px}.summary-container_sRLei .pending_uK15_,.summary-container_sRLei .settled_O5faA,.summary-container_sRLei .sub-heading_CohK0{margin:0}.summary-container_sRLei .status-bar-track_rBD2P{width:100%;border-radius:5px;height:8px;margin:10px 0;background:#e8e8ea;overflow:hidden}.summary-container_sRLei .status-bar-track_rBD2P .status-bar-percent_ZCMs9{background:linear-gradient(90deg,#b563ec 2.68%,#f6c8a4 90.69%);border-radius:5px;height:8px}.summary-container_sRLei .tooltip_Et900{color:#000;padding:4px;cursor:help}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary-container": "summary-container_sRLei",
	"pending": "pending_uK15_",
	"settled": "settled_O5faA",
	"sub-heading": "sub-heading_CohK0",
	"status-bar-track": "status-bar-track_rBD2P",
	"status-bar-percent": "status-bar-percent_ZCMs9",
	"tooltip": "tooltip_Et900"
};
export default ___CSS_LOADER_EXPORT___;
