// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_gp97w{border:none;background:#000;color:#fff;cursor:pointer;display:inline-block;font-family:Montserrat,sans-serif;font-size:18px;font-weight:500;text-align:center;height:50px;padding:0 30px;transition:all .2s ease}.button_gp97w .button-text_BjNzW{display:inline-block;line-height:.8;margin:auto 0;position:relative;top:1px}.button_gp97w .icon_lVUxZ{margin-right:10px}.button_gp97w.disabled_csksY{background-color:#aeaeae;cursor:default}.button_gp97w:hover:not(.disabled_csksY){background:#8169c5}.button_gp97w:focus{box-shadow:0 0 0 2px #005fcc}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button_gp97w",
	"button-text": "button-text_BjNzW",
	"icon": "icon_lVUxZ",
	"disabled": "disabled_csksY"
};
export default ___CSS_LOADER_EXPORT___;
