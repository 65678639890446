// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_hMH7x{min-width:186px;justify-content:center}button.back-button_pg3Pt{padding-left:0;transition:color .2s}button.back-button_pg3Pt:disabled{border:0;background:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button_hMH7x",
	"back-button": "back-button_pg3Pt"
};
export default ___CSS_LOADER_EXPORT___;
