<template>
  <div :class="$style.container">
    <quick-link
      v-for="link in links"
      :key="link.smallTitle()"
      :link="link"
      :data-cy="'mobile-quick-link-' + kebabCase(link.smallTitle())"
      mobile
    />
  </div>
</template>

<script>
import kebabCase from '@/utils/kebabcase';
import QuickLink from './QuickLinkWayfinding';

export default {
  components: {
    QuickLink,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  methods: {
    kebabCase,
  },
};
</script>

<style lang="less" module>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>
