import enUS from './en-us.json'; // English - United States
import deDE from './de-de.json'; // German - German
import nlBE from './nl-be.json'; // Dutch - Belgium
import fiFI from './fi-fi.json'; // Finnish - Finland
import nlNL from './nl-nl.json'; // Dutch - Netherlands
import daDK from './da-dk.json'; // Danish - Denmark
import svSE from './sv-se.json'; // Swedish - Sweden

export default {
  'en-us': enUS,
  'de-de': deDE,
  'nl-be': nlBE,
  'fi-fi': fiFI,
  'nl-nl': nlNL,
  'da-dk': daDK,
  'sv-se': svSE,
};
