<script setup>
import { ref, defineExpose, readonly } from 'vue';
import { useStore } from 'vuex';
import Modal from '@clearbanc/clear-components/dialog';
import ModalControls from '@/components/modals/shared/ModalControls';

// -----
// Setup
// -----

const store = useStore();

// ---------
// Variables
// ---------

const isLoading = ref(false);
const isModalVisible = ref(false);
const selectedSignatory = ref(null);

// ---------
// Constants
// ---------

const allAuthorizedSignatories = readonly(
  store.getters.allAuthorizedSignatories,
);

// --------------
// Modal Controls
// --------------

function toggleModal() {
  const openingModal = !isModalVisible.value;

  if (openingModal) {
    const dismissedSignatoryModal = sessionStorage.getItem(
      'dismissedSignatoryModal',
    );

    if (dismissedSignatoryModal || allAuthorizedSignatories.length < 2) {
      return;
    }
  }

  isModalVisible.value = !isModalVisible.value;
}

async function submitModal(signatoryToUpdate) {
  isLoading.value = true;

  if (signatoryToUpdate.isPrimary) {
    await store.dispatchApiAction('UPDATE_USER', {
      overrideSignatories: true,
      jobRoles: signatoryToUpdate.jobRoles,
    });
  } else {
    await store.dispatchApiAction('UPDATE_NON_PRIMARY_USER', {
      id: signatoryToUpdate.id,
      overrideSignatories: true,
      jobRoles: signatoryToUpdate.jobRoles,
    });
  }

  isLoading.value = false;
  toggleModal();
}

function dismissModal() {
  sessionStorage.setItem('dismissedSignatoryModal', true);
  toggleModal();
}

// -----------------
// Component Options
// -----------------

defineExpose({ toggleModal });
</script>

<template>
  <Modal
    v-model:visible="isModalVisible"
    header="Please update the signatory"
    :modal="true"
    :draggable="false"
    append-to="self"
  >
    <p :class="$style.description">
      We updated our policy and now every business account can have only one
      signatory. Please reselect your signatory, otherwise we can’t generate
      contract.
    </p>

    <div :class="$style.signatories">
      <button
        v-for="(signatory, index) in allAuthorizedSignatories"
        :key="`signatory-${index}`"
        :class="[
          $style.signatories__item,
          selectedSignatory?.id === signatory.id &&
            $style['signatories__item--active'],
        ]"
        @click="selectedSignatory = signatory"
      >
        {{ signatory.firstName }} {{ signatory.lastName }}
      </button>
    </div>

    <template #footer>
      <ModalControls
        :buttons="[
          {
            text: 'Skip for now',
            action: dismissModal,
            classes: ['p-button-outlined'],
            attributes: {},
          },
          {
            text: 'Confirm',
            action: () => submitModal(selectedSignatory),
            classes: [],
            attributes: { loading: isLoading },
          },
        ]"
      />
    </template>
  </Modal>
</template>

<style lang="less" scoped module>
.description {
  width: 100%;
  max-width: 665px;
  text-align: center;
  margin: 0 0 40px 0;
}

.signatories {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto 20px;
}

.signatories__item {
  border: 1px solid var(--neutral-grey-100, #e8e8ea);
  padding: 16px;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 2px;
}

.signatories__item + .signatories__item {
  margin-top: 24px;
}

.signatories__item:before {
  content: '';
  width: 20px;
  height: 20px;
  border: 1px solid var(--neutral-black, #000);
  display: inline-block;
  border-radius: 50%;
  margin-right: 16px;
}

.signatories__item--active {
  border-color: var(--primary-brand-primary-400, #7f30ff);
  cursor: default;
}

.signatories__item--active:before {
  border: 4px solid var(--primary-brand-primary-400, #7f30ff);
}
</style>
