// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".explainer-text_Bh8mk{max-width:615px}.footer-container_kiO7R{width:100%;max-width:1000px;padding:0 50px;margin:auto;display:flex;align-items:baseline;justify-content:space-between}@media screen and (min-width:961px){.footer-container_kiO7R{transform:translateY(-25px)}}@media screen and (max-width:500px){.footer-container_kiO7R{padding:0 10px}}@media screen and (max-width:550px){.footer-container_kiO7R{flex-flow:column;align-items:center}}.footer-container_kiO7R .encryption-container_WGyXk{color:#666;display:flex;align-items:baseline;justify-content:center}.footer-container_kiO7R .encryption-container_WGyXk svg{width:20px;height:20px;fill:#666;transform:translateY(2px);margin-right:5px}.footer-container_kiO7R .encryption-container_WGyXk a{margin-left:5px}.connect-account-btn_h5RRN button{background-color:#721cff}.connect-account-btn_h5RRN button:hover{background-color:#721cff!important}h2.title_aD81r{font-size:19px}h2.heading__EFFF{font-size:21px;line-height:30px;font-weight:500;margin:0 0 24px}.sub-heading_nF66B{margin:0 auto 2rem;max-width:550px}.modal-content-container_KqNZH{max-width:700px;text-align:left}@media screen and (max-width:500px){.sub-heading_nF66B,h2.heading__EFFF{text-align:left}}.modal_zHUGk .icon_y9qIY{width:100%;max-width:77px;margin:47px auto}.modal_zHUGk [class=p-dialog-footer] button{margin:0}.modal_zHUGk [class=p-dialog-content]{padding-bottom:2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"explainer-text": "explainer-text_Bh8mk",
	"footer-container": "footer-container_kiO7R",
	"encryption-container": "encryption-container_WGyXk",
	"connect-account-btn": "connect-account-btn_h5RRN",
	"title": "title_aD81r",
	"heading": "heading__EFFF",
	"sub-heading": "sub-heading_nF66B",
	"modal-content-container": "modal-content-container_KqNZH",
	"modal": "modal_zHUGk",
	"icon": "icon_y9qIY"
};
export default ___CSS_LOADER_EXPORT___;
