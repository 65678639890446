<template>
  <div :class="$style.loader">
    <icon name="spinner" scale="2" />
  </div>
</template>

<style lang="less" module>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 75%;
}
</style>
