// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".parent_LvRqe{color:#666;display:flex;align-items:flex-start;margin-left:30px;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:16px}.parent_LvRqe .parent-icon_ew64O{margin-right:8px}.warning-icon_Zf9pt{position:absolute;margin:0 0 0 8px;color:#e8a524}.legacy-label_WMH8d{margin-left:4px}.box_GuMla{position:relative;align-items:center;background:none;border:none;color:#666;cursor:pointer;display:flex;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:16px;height:60px;width:100%;padding:unset}.box_GuMla:hover{background:#fff}@media only screen and (max-width:1439px){.box_GuMla:hover{background:#f2f2f2}}.box_GuMla:hover.darken_TBu2q{background:#f2f2f2}.box_GuMla.reduced_Ljp6d{padding:0 20px;height:40px;font-size:14px}.box_GuMla.with-subtitle_QprB1{padding-bottom:10px}.box_GuMla .subtitle_UVKoP{position:absolute;bottom:7px;left:30px;font-size:10px;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;color:#721cff}.box_GuMla .subtitle_UVKoP,.selected_PrVHz{font-weight:700}.selected_PrVHz.highlighted_klaqr{background:#fff}@media only screen and (max-width:1439px){.selected_PrVHz.highlighted_klaqr{background:#f2f2f2}}.child_z1ROS{color:#666;cursor:pointer;display:flex;margin-left:55px}.child_z1ROS.shift-left_Za95p{margin-left:unset}.child_z1ROS .child-icon_ICWk3{align-self:center;margin-left:-20px;margin-right:4px}.child_z1ROS .warning_oQD9G{color:#e8a524}.selected-text_yrGih{color:#000}.accordion-container_nw40v{cursor:pointer;margin-left:5px;padding:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": "parent_LvRqe",
	"parent-icon": "parent-icon_ew64O",
	"warning-icon": "warning-icon_Zf9pt",
	"legacy-label": "legacy-label_WMH8d",
	"box": "box_GuMla",
	"darken": "darken_TBu2q",
	"reduced": "reduced_Ljp6d",
	"with-subtitle": "with-subtitle_QprB1",
	"subtitle": "subtitle_UVKoP",
	"selected": "selected_PrVHz",
	"highlighted": "highlighted_klaqr",
	"child": "child_z1ROS",
	"shift-left": "shift-left_Za95p",
	"child-icon": "child-icon_ICWk3",
	"warning": "warning_oQD9G",
	"selected-text": "selected-text_yrGih",
	"accordion-container": "accordion-container_nw40v"
};
export default ___CSS_LOADER_EXPORT___;
