<template>
  <span>
    <ext-account
      title="appstore"
      :logo="appstore"
      :success="success"
      :disconnected="disconnected"
      :error="errors.appstore"
      logo-alt="App Store logo"
      @click="openModal"
    />

    <!-- Apple Appstore Modal -->
    <ModalAppstore
      ref="appstoreModal"
      @success="$emit('success', SALES_PLATFORMS.APPSTORE)"
      @error="$emit('error', SALES_PLATFORMS.APPSTORE)"
      @oauthError="(payload) => $emit('oauthError', payload)"
      @modalAction="
        (platformName, action) => $emit('modalAction', platformName, action)
      "
    />
  </span>
</template>

<script>
import { SALES_PLATFORMS } from '@/data/platforms';
import ExtAccount from '@/components/modals/ModalExternalAccountOnboarding2';
import ModalAppstore from '@/components/modals/ModalAppstore';

export default {
  components: {
    ExtAccount,
    ModalAppstore,
  },
  props: {
    success: Boolean,
    disconnected: Boolean,
    errors: Object,
  },
  computed: {
    appstore: () => require('@/assets/logos/applepay.png'),
    SALES_PLATFORMS: () => SALES_PLATFORMS,
  },
  methods: {
    openModal() {
      this.$emit('tileClick', SALES_PLATFORMS.APPSTORE);
      this.$refs.appstoreModal.open();
    },
  },
};
</script>
