export default {
  // keys reference the name of a todo-section to be toggled
  state: {
    coreSelectOfferSection: false,
    coreSignContractSection: false,
    connectBankingSection: false,
  },
  mutations: {
    TOGGLE_TODO_SECTION: (state, { name, isOpen }) => {
      state[name] = isOpen;
    },
  },
};
