import i18n from '@/plugins/i18n';

export const PURPOSE_CODES = {
  AE: {
    codes: {
      FIS: i18n.t('data.paymentPurpose.fis'),
      GDI: i18n.t('data.paymentPurpose.gdi'),
      IFS: i18n.t('data.paymentPurpose.ifs'),
      ITS: i18n.t('data.paymentPurpose.its'),
      PMS: i18n.t('data.paymentPurpose.pms'),
      RNT: i18n.t('data.paymentPurpose.rnt'),
      RDS: i18n.t('data.paymentPurpose.rds'),
      SAL: i18n.t('data.paymentPurpose.sal'),
      STS: i18n.t('data.paymentPurpose.sts'),
      TCS: i18n.t('data.paymentPurpose.tcs'),
      TTS: i18n.t('data.paymentPurpose.tts'),
    },
  },
};
