// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".white-background_m4YLO{background:#fff;min-height:100vh;position:absolute;min-width:100vw}.white-background_m4YLO .content_n985F{padding:120px 0 230px}.white-background_m4YLO .content_n985F .icon_WfopC{display:block;margin:0 auto;height:40px;width:19px}@media only screen and (max-width:500px){.white-background_m4YLO .content_n985F .icon_WfopC{margin-left:20px}}.white-background_m4YLO .content_n985F .text_A5ETy{height:33px;font-weight:500;font-size:21px;line-height:30px;color:#000;margin-top:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white-background": "white-background_m4YLO",
	"content": "content_n985F",
	"icon": "icon_WfopC",
	"text": "text_A5ETy"
};
export default ___CSS_LOADER_EXPORT___;
