// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".white-background_LbJ6s{min-height:100vh;min-width:100vw;background-color:#fff;position:absolute;top:0;left:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white-background": "white-background_LbJ6s"
};
export default ___CSS_LOADER_EXPORT___;
