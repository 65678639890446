import { vcServiceRequest } from '@/utils/vc-service-request';
import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  state: {
    partner: {},
    partnerInfo: {},
    partners: {},
    partnerCompanies: {},
    partnerCompaniesCount: null,
    partnerMatches: {},
    partnersFromCategory: {},
    partnersCount: 0,
    bnplUploadedInvoices: {},
  },
  getters: {
    partner: (state) => state.partner,
    partnerInfo: (state) => state.partnerInfo,
    partnerId: (state) => state.partner.id,
    partners: (state) => Object.values(state.partners),
    partnerCompanies: (state) => Object.values(state.partnerCompanies),
    partnerCompaniesCount: (state) => state.partnerCompaniesCount,
    partnerMatches: (state) => Object.values(state.partnerMatches),
    partnersFromCategory: (state) => Object.values(state.partnersFromCategory),
    partnersCount: (state) => state.partnersCount,
    bnplUploadedInvoices: (state) => Object.values(state.bnplUploadedInvoices),
  },
  ...buildApiActions({
    GET_PARTNERS: {
      action: (ctx, payload) => ({
        method: 'get',
        interceptor: vcServiceRequest,
        url: '/partners',
        params: payload,
      }),
      mutation: (state, { response, responseTotalCount }) => {
        state.partners = response.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {});
        state.partnersCount = responseTotalCount || '0';
      },
    },
    GET_PARTNER: {
      action: (ctx, payload) => ({
        method: 'get',
        interceptor: vcServiceRequest,
        url: `/partners/${payload.partnerId}`,
      }),
      mutation: (state, { response }) => {
        state.partner = response;
      },
    },
    GET_PARTNER_INFO: {
      action: (ctx, payload) => ({
        method: 'get',
        interceptor: vcServiceRequest,
        url: `/merchants/${payload.partnerId}/partner-info`,
      }),
      mutation: (state, { response }) => {
        if (response) {
          state.partnerInfo = response;
        }
      },
    },
    GET_PARTNER_COMPANIES: {
      action: (ctx, params) => ({
        method: 'get',
        interceptor: vcServiceRequest,
        url: `/partners/${ctx.state.partner.id}/companies`,
        params,
      }),
      mutation: (state, { response, responseTotalCount }) => {
        const companies = response.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {});

        state.partnerCompanies = companies;
        state.partnerCompaniesCount = responseTotalCount;
      },
    },
    GET_PARTNERS_FROM_CATEGORY: {
      action: (ctx, params) => ({
        method: 'get',
        interceptor: vcServiceRequest,
        url: `/merchants/${ctx.rootGetters.userId}/partners`,
        params,
      }),
      mutation: (state, { response }) => {
        state.partnersFromCategory = response;
      },
    },
    GET_PARTNER_MATCHES: {
      action: (ctx, params) => ({
        method: 'get',
        interceptor: vcServiceRequest,
        url: `/partners/${ctx.state.partner.id}/matches`,
        params,
      }),
      mutation: (state, { response }) => {
        const matches = response.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {});

        state.partnerMatches = matches;
      },
    },
    UPDATE_PARTNER: {
      action: (ctx, params) => ({
        method: 'patch',
        interceptor: vcServiceRequest,
        url: `/partners/${ctx.state.partner.id}`,
        params,
      }),
      mutation: (state, { response }) => {
        state.partner = response;
        state.partners[response.id] = response;
      },
    },
    UPDATE_PARTNER_INFO: {
      action: (ctx, params) => ({
        method: 'post',
        interceptor: vcServiceRequest,
        url: `/merchants/${ctx.state.partner.id}/partner-info`,
        params,
      }),
      mutation: (state, { response }) => {
        state.partnerInfo = response;
      },
    },
    UPDATE_PARTNER_MATCH: {
      action: (ctx, params) => ({
        method: 'patch',
        interceptor: vcServiceRequest,
        url: `/partners/${ctx.state.partner.id}/matches/${params.matchId}`,
        params,
      }),
      mutation: (state, { response, payload }) => {
        delete state.partnerCompanies[payload.matchId];
        delete state.partnerMatches[payload.matchId];
      },
    },
    ADD_PARTNER: {
      action: (ctx, params) => ({
        method: 'post',
        interceptor: vcServiceRequest,
        url: '/partners',
        params,
      }),
      mutation: (state, { response }) => {
        state.partner = response;
        state.partners[response.id] = response;
      },
    },
    ADD_PARTNER_MATCHES: {
      action: (ctx, params) => ({
        method: 'post',
        interceptor: vcServiceRequest,
        url: `/partners/${ctx.state.partner.id}/matches`,
        params,
      }),
      mutation: (state, { response }) => {
        response.forEach((match) => {
          const matchOrCompany =
            match.introducedAt || match.bypassedByAdminAt
              ? 'partnerCompanies'
              : 'partnerMatches';
          state[matchOrCompany][match.id] = match;
        });
      },
    },
    ADD_MERCHANT_MATCH: {
      action: (ctx, params) => ({
        method: 'post',
        interceptor: vcServiceRequest,
        url: '/merchants/add-match',
        params,
      }),
      mutation: (state, { response }) => {
        state.partnerMatches[response.id] = response;
      },
    },
    UPLOAD_PARTNER_MEDIA: {
      action: (ctx, params) => ({
        method: 'post',
        interceptor: vcServiceRequest,
        url: `/merchants/upload-file`,
        params,
        returnResponse: true,
      }),
    },
  }),
};
