// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mobile-menu_XeYsq{position:fixed;top:0;left:0;height:100%;width:100%;background:#000;z-index:1;color:#fff;padding-top:80px}.mobile-menu_XeYsq .mobile-header_Eu7Jx{color:#fff;font-family:Montserrat}.mobile-menu_XeYsq .link_XU7bA{display:flex;align-items:center;justify-content:center;height:60px;width:100%;position:relative}.mobile-menu_XeYsq .link_XU7bA:first-child{border-top:1px solid #cacaca}.mobile-menu_XeYsq .link_XU7bA div{margin:0}.mobile-menu_XeYsq .link_XU7bA:after{content:\"\";position:absolute;bottom:0;left:50%;transform:translateX(-50%);width:70%;height:1px;background:#cacaca}.mobile-menu_XeYsq .button_n2WWU{margin:50px auto 0;display:block;font-family:Montserrat}.mobile-menu_XeYsq .signup-link_Ce3my{position:absolute;bottom:80px;left:50%;transform:translateX(-50%);white-space:nowrap;color:#333;text-decoration:none!important}.mobile-menu_XeYsq .signup-link_Ce3my,.mobile-menu_XeYsq .signup-link_Ce3my:focus,.mobile-menu_XeYsq .signup-link_Ce3my:hover{color:#333}.mobile-menu-button_TTr8H{display:none;position:absolute;top:36px;right:20px;cursor:pointer;z-index:2}.mobile-menu-button_TTr8H .line_dphTE{height:3px;width:24px;background:#000}.mobile-menu-button_TTr8H .line_dphTE:nth-child(2){margin:5px 0}.mobile-menu-button_TTr8H .close-icon_ZThMF{height:30px;width:30px}.mobile-menu-button_TTr8H .close-icon_ZThMF:after{content:\"\\00d7\";position:absolute;top:-2px;left:0;color:#fff;font-size:60px;font-weight:100}@media only screen and (max-width:1350px){.mobile-menu-button_TTr8H{display:block}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile-menu": "mobile-menu_XeYsq",
	"mobile-header": "mobile-header_Eu7Jx",
	"link": "link_XU7bA",
	"button": "button_n2WWU",
	"signup-link": "signup-link_Ce3my",
	"mobile-menu-button": "mobile-menu-button_TTr8H",
	"line": "line_dphTE",
	"close-icon": "close-icon_ZThMF"
};
export default ___CSS_LOADER_EXPORT___;
