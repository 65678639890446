<template>
  <div :class="$style['banner-message']">
    <div v-if="title" :class="$style.title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="less" module>
.banner-message {
  font-family: @gerstner-font;
  font-size: @font-size-14;
  line-height: @spacing-20;
  background-color: @color-warning-100;
  padding-left: @spacing-8;
  color: @black;
  padding: @spacing-20;
}
</style>
