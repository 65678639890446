<template>
  <div class="input-selection--container">
    <label v-if="label" :for="`input-selection-${uniqueComponentId}`">
      {{ label }}
    </label>
    <div>
      <select
        :id="`input-selection-${uniqueComponentId}`"
        @change="handleOnChange"
      >
        <option v-if="placeholder">{{ placeholder }}</option>
        <option
          v-for="item in list"
          :key="item.key || item.value"
          :value="item.value"
          :disabled="item.disabled"
          :selected="selectedItemValue === item.value"
        >
          {{ item.label }}
        </option>
      </select>
      <span v-html="require('@/assets/icons/select-arrow.svg')" />
    </div>
  </div>
</template>

<script>
import uniqueComponentId from '@/mixins/unique-component-id';

export default {
  mixins: [uniqueComponentId],
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    list: {
      type: Array,
      default: () => [],
    },
    selectedItemValue: {
      type: [String, Number],
      default: () => null,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    handleOnChange(e) {
      const offset = this.placeholder
        ? e.target.selectedIndex - 1
        : e.target.selectedIndex;
      this.onChange(this.list[offset]);
      this.$emit('change');
    },
  },
};
</script>

<style lang="less" scoped>
.input-selection--container {
  width: 100%;
  position: relative;

  > label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #666;
    display: block;
    margin-bottom: 5px;
  }

  > div {
    position: relative;
    width: 100%;

    > span {
      position: absolute;
      height: 13px;
      width: 13px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    > select {
      background: transparent;
      font-family: Montserrat;
      display: inline-block;
      -webkit-appearance: none;
      -moz-appearance: window;
      border: none;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.1em;
      color: #333;
      padding: 12px 20px 12px 0;
      border-bottom: 1px solid #333;

      option {
        background: white;
      }
    }

    > select:focus {
      border-color: gray;
      outline: 0;
    }

    > select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }
}
</style>
