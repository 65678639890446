import _ from 'lodash';

import { buildApiActions } from '@/utils/vuex-api-utils';

function emptyVendor() {
  return {
    beneficiaryAddress: {
      country: '',
      line1: '',
      line2: '',
      state: '',
      city: '',
      postalCode: '',
    },
    bankAddress: {
      line1: '',
      line2: '',
      country: '',
      state: '',
      postalCode: '',
      city: '',
    },
    details: {
      vendorEmail: '',
      vendorPhone: '',
      vendorName: '',
      vendorWebsite: '',
      vendorReason: '',
    },
  };
}

export default {
  state: {
    vendors: {},
    selectedVendor: emptyVendor(),
    requiredVendorFieldsWithValidation: {},
    receiptVendorOptions: [],
  },
  getters: {
    vendors: (state) => _.values(state.vendors),
    selectedVendor: (state) => state.selectedVendor,
    requiredVendorFieldsWithValidation: (state) =>
      state.requiredVendorFieldsWithValidation,
    vendorsSearchResults: (state) => state.vendorsSearchResults,
    vendorsSearchCount: (state) => state.vendorsSearchCount,
    receiptVendorOptions: (state) => state.receiptVendorOptions,
  },
  ...buildApiActions(
    {
      SEARCH_VENDORS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: '/vendors',
          params: payload,
        }),
        mutation: (state, { response, responseTotalCount }) => {
          const vendors = response.map((vendor) => {
            return {
              ...vendor,
              ...(!vendor.vendorCategory && {
                vendorCategory: 'other',
              }),
            };
          });
          state.vendorsSearchResults = vendors;
          state.vendorsSearchCount = responseTotalCount;

          const vendorsObj = vendors.reduce((acc, vendor) => {
            Object.assign(acc, { [vendor.id]: vendor });
            return acc;
          }, {});

          state.vendors = vendorsObj;
        },
      },
      CREATE_VENDOR_ACCOUNT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: '/vendors',
          params: {
            ...payload,
            ...(payload.vendorCategory === 'other' && {
              vendorCategory: null,
            }),
          },
        }),
        mutation: (state, { response }) => {
          state.vendors[response.id] = response;
          state.selectedVendor = response;
        },
      },
      GET_RECEIPT_VENDOR_OPTIONS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/receipt-vendors-list`,
        }),
        mutation: (state, { response }) => {
          state.receiptVendorOptions = response;
        },
      },
      APPROVE_VENDOR_ACCOUNT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/vendors/${payload.id}/approve`,
          params: _.omit(payload, 'id'),
        }),
        mutation: (state, { response }) => {
          state.vendors[response.id] = response;
        },
      },
      REJECT_VENDOR_ACCOUNT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/vendors/${payload.id}/reject`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.vendors[response.id] = response;
        },
      },
      UPDATE_VENDOR_ACCOUNT: {
        action: (ctx, payload) => ({
          method: 'patch',
          url: `/vendors/${payload.id}`,
          params: {
            ...payload,
            ...(payload.vendorCategory === 'other' && {
              vendorCategory: null,
            }),
          },
        }),
        mutation: (state, { response }) => {
          state.vendors[response.id] = response;
        },
      },
      DELETE_VENDOR_ACCOUNT: {
        action: (ctx, payload) => ({
          method: 'delete',
          url: `/vendors/${payload.id}`,
        }),
        mutation: (state, { response }) => {
          delete state.vendors[response.id];
        },
      },
      GET_VENDOR_BY_ID: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/vendors/${payload.id}`,
        }),
        mutation: (state, { response }) => {
          const vendor = response;
          if (!vendor.vendorCategory) {
            vendor.vendorCategory = 'other';
          }
          state.selectedVendor = vendor;
        },
      },
      GET_REQUIRED_VENDOR_FIELDS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: '/vendors/required-fields',
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.requiredVendorFieldsWithValidation = response;
        },
      },
    },
    {
      actions: {
        UPDATE_SELECTED_VENDOR(context, payload) {
          context.commit('SET_SELECTED_VENDOR', payload);
        },
        CLEAR_SELECTED_VENDOR(context) {
          context.commit('SET_EMPTY_VENDOR');
        },
        ADD_SELECTED_VENDOR(context, payload) {
          context.commit('SET_CURRENT_VENDOR', payload);
        },
      },
      mutations: {
        SET_SELECTED_VENDOR(state, payload) {
          const nestedProp = payload.path?.split('.');
          if (nestedProp?.length > 1) {
            state.selectedVendor[nestedProp[0]][nestedProp[1]] = payload.val;
          } else {
            state.selectedVendor[payload.path] = payload.val;
          }
        },
        SET_CURRENT_VENDOR(state, payload) {
          state.selectedVendor = state.vendors[payload.id];
        },
        SET_EMPTY_VENDOR(state) {
          state.selectedVendor = emptyVendor();
        },
      },
    },
  ),
};
