<template>
  <BaseIcon
    icon-name="Chevron"
    :class="accordionClasses"
    :width="16"
    :height="9"
  />
</template>

<script>
import BaseIcon from '@/components/icons/base-icon';

export default {
  components: {
    BaseIcon,
  },
  props: {
    isInverted: { type: Boolean, default: false },
  },
  computed: {
    accordionClasses() {
      return {
        [this.$style['accordion-button']]: true,
        [this.$style.active]: this.isInverted,
      };
    },
  },
};
</script>

<style lang="less" module>
.accordion-button {
  margin-bottom: 2px;
  &.active {
    transform: scaleY(-1);
    margin-bottom: unset;
  }
}
</style>
