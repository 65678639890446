import useVuelidate from '@vuelidate/core';
import { computed, ref } from 'vue';

export const validateChildren = () => {
  const v$ = useVuelidate();
  const validationErrors = computed(() => v$.value.$errors);
  const hasError = async () => {
    // validate all child forms
    await v$.value.$validate();
    return !!validationErrors.value.length;
  };
  return {
    hasError,
    validationErrors,
  };
};

export const inputValidationSetup = (props) => {
  const isValid = ref(false);
  const hasError = computed(() => props?.error || !isValid.value);

  const rules = {
    hasError: { [props.label]: (val) => !val },
  };
  const v$ = useVuelidate(rules, { hasError });
  const isTouched = computed(() => v$.value.$dirty);

  return {
    isValid,
    isTouched,
    hasError,
  };
};
