// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_Bd4RK{display:flex}.button_ktulU{border-top:1px solid #caa8ff;border-left:1px solid #caa8ff;border-right:1px solid #caa8ff;border-top-right-radius:4px;border-top-left-radius:4px;padding:8px 16px;position:relative;color:#732cf8;text-decoration:underline;background:#fff}.button_ktulU:focus{outline:none}.button--align-right_ibLbv{margin-left:auto}.button--active_Vxl5A{text-decoration:none;color:inherit;font-weight:700}.button--active_Vxl5A:after{content:\"\";position:absolute;top:100%;left:0;width:100%;height:1px;background:#fff;z-index:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_Bd4RK",
	"button": "button_ktulU",
	"button--align-right": "button--align-right_ibLbv",
	"button--active": "button--active_Vxl5A"
};
export default ___CSS_LOADER_EXPORT___;
