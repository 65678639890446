<template>
  <div
    class="p-timeline-event-connector"
    :class="
      connector === 'complete' ? 'event-connector-complete' : 'event-connector'
    "
  />
</template>

<script>
export default {
  props: {
    connector: {
      type: String,
      default: 'default',
      validator: (val) => {
        return ['default', 'complete'].includes(val);
      },
    },
  },
};
</script>

<style lang="less" scoped>
.p-timeline .p-timeline-event-connector {
  background-color: transparent;
}

.event-connector-complete {
  border: 1px solid #23aa71;
  background-color: transparent;
}

.event-connector {
  border: 1px dashed #767676;
  background-color: transparent;
}
</style>
