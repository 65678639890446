// Used to display dropdown information in category selection
// Other is on the bottom
import * as _ from 'lodash';
import i18n from '@/plugins/i18n';

const { t } = i18n.global;

// a mapping from business category to its id
// used to make an appropriate API call to /businesses/:id/category
export const BUSINESS_CATEGORY_ID = () => {
  return {
    [t('data.categoryOptions.accessories')]: 1,
    [t('data.categoryOptions.apparel')]: 2,
    [t('data.categoryOptions.appsTechnology')]: 3,
    [t('data.categoryOptions.auto')]: 4,
    [t('data.categoryOptions.beauty')]: 5,
    [t('data.categoryOptions.beverages')]: 6,
    [t('data.categoryOptions.cannabis')]: 7,
    [t('data.categoryOptions.collectibles')]: 8,
    [t('data.categoryOptions.electronics')]: 9,
    [t('data.categoryOptions.events')]: 10,
    [t('data.categoryOptions.fitness')]: 11,
    [t('data.categoryOptions.food')]: 12,
    [t('data.categoryOptions.footwear')]: 13,
    [t('data.categoryOptions.healthWellness')]: 14,
    [t('data.categoryOptions.homeGoods')]: 15,
    [t('data.categoryOptions.homeServices')]: 16,
    [t('data.categoryOptions.media')]: 17,
    [t('data.categoryOptions.medicalSupplies')]: 18,
    [t('data.categoryOptions.officeSupplies')]: 19,
    [t('data.categoryOptions.packagingShipping')]: 21,
    [t('data.categoryOptions.petProducts')]: 22,
    [t('data.categoryOptions.sportsEquipment')]: 23,
    [t('data.categoryOptions.toys')]: 24,
    [t('data.categoryOptions.travel')]: 25,
    [t('data.categoryOptions.other')]: 20,
  };
};

// Map category id to name
export const BUSINESS_CATEGORY_NAME = () => _.invert(BUSINESS_CATEGORY_ID());
