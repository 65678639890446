<template>
  <div :class="$style['warning-message']">
    <ui-icon name="info-filled" size="16" color="#E8A524" />
    <div>
      <div v-if="title" :class="$style.title">{{ title }}</div>
      <!-- implements a slot so we can use arbitrary text and <a> tag combinations -->
      <slot />
    </div>
  </div>
</template>

<script>
import { UiIcon } from '@clearbanc/design-components/src/components';

export default {
  components: { UiIcon },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="less" module>
.warning-message {
  display: flex;
  font-family: @gerstner-font;
  font-size: @font-size-14;
  line-height: @spacing-20;

  & > svg {
    flex-shrink: 0;
  }

  & > div {
    display: inline-block;
    padding-left: @spacing-8;
    color: @black;

    & a {
      color: @black;
      text-decoration: underline;
    }

    & a:active {
      color: @color-info-500;
    }
  }

  & > div > .title {
    font-weight: bold;
  }
}
</style>
