// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_V3mci{padding:0}.container_V3mci p{position:relative;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:16px;text-align:center;margin:0}@media only screen and (max-width:500px){.container_V3mci p{text-align:left}}.exit-bottom-action_opmHC{display:flex;justify-content:space-between;align-items:center;margin:30px 0}.exit-bottom-action_opmHC div[class^=cta-container]{max-width:unset}.exit-bottom-action_opmHC a[class^=link]{font-style:normal;font-weight:300;font-size:16px;line-height:24px;text-align:center;-webkit-text-decoration-line:underline;text-decoration-line:underline;color:#297fe3}.exit-bottom-action_opmHC button[class^=button]{display:flex;flex-direction:row;justify-content:center;align-items:center;padding:10px 40px;width:197px;background:#000;border-radius:4px}@media only screen and (max-width:800px){.exit-bottom-action_opmHC div[class^=button-text]{white-space:no-wrap;min-width:148px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_V3mci",
	"exit-bottom-action": "exit-bottom-action_opmHC"
};
export default ___CSS_LOADER_EXPORT___;
