// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".banner-message_DHvId{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:14px;line-height:20px;background-color:#ffecbc;padding-left:8px;color:#111;padding:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner-message": "banner-message_DHvId"
};
export default ___CSS_LOADER_EXPORT___;
