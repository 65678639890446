<template>
  <div :class="$style['nav-bar']">
    <router-link :class="$style.logo" to="/">
      <span v-html="logoSVG" />
    </router-link>
    <NavbarDesktop v-if="!isTablet" />
    <NavbarMobile v-else />
  </div>
</template>

<script>
import { useTabletDetection } from '@/composables/responsive';
import NavbarDesktop from '@/layout/NavbarDesktop';
import NavbarMobile from '@/layout/NavbarMobile';

export default {
  setup() {
    const { isTablet } = useTabletDetection();
    return { isTablet };
  },
  components: {
    NavbarDesktop,
    NavbarMobile,
  },

  data() {
    return {
      logoSVG: require('@/assets/logos/clearco-logo.svg'),
    };
  },
};
</script>

<style lang="less" module>
.nav-bar {
  position: fixed;
  transition: background-color 0.5s ease;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  width: 100%;
  font-size: @font-size-15;
  font-weight: 600;
  font-family: 'Montserrat';
  z-index: 100;
  text-transform: uppercase;
  background: @color-white;
  margin: 0 auto;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

  .button {
    font-family: 'Montserrat';
    border-radius: 0;
    letter-spacing: 0;
  }
  @media only screen and (max-width: 1023px) {
    .badge {
      vertical-align: super;
      border-radius: 50%;
      margin-top: -25px;
      width: 16px;
      height: 16px;
      left: 271px;
      top: 366px;
      background: @mobile-alert;
      margin-left: 5px;
    }
    .burger-badge {
      vertical-align: super;
      border-radius: 50%;
      margin-top: -25px;
      margin-right: 5px;
      width: 16px;
      height: 16px;
      left: 271px;
      top: 366px;
      background: @mobile-alert;
    }
  }
  @media only screen and (min-width: 1024px) {
    .badge {
      display: none;
    }
    .burger-badge {
      display: none;
    }
  }

  .switch-wrapper {
    max-width: 50px;
    margin-top: 10px;

    .switch-wrapper {
      margin-top: 20px;
    }

    @media only screen and (max-width: 1170px) {
      margin: -25px 0 0;
      display: block;
    }
    @media only screen and (max-width: 825px) {
      margin: -25px auto 0;
      display: block;
    }
  }

  &.transparent {
    background: transparent;
  }

  .logo {
    min-width: 200px;
    max-width: 200px;
    margin-left: @gutter-20;
    margin-top: 10px;
    cursor: pointer;
    z-index: 2;

    @media only screen and (max-width: 500px) {
      margin-left: @gutter-20;
      width: 190px;
    }
  }

  @media only screen and (max-width: 500px) {
    border-bottom: 1px solid transparent;
  }
}
</style>
