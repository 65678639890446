// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_iuRm2{text-align:left;width:100%}.form_iuRm2 .form__controls_yhqcG{text-align:center;justify-content:center}.form__submit-button_29uO7{min-width:8rem;justify-content:center}.grey-container_nvf5M{min-height:390px;background-color:#f8f8fa;margin:32px 14%;display:flex;align-items:center;justify-content:center;flex-direction:column;width:auto}.grey-container_nvf5M p{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;color:#5f5f61;font-style:normal;font-weight:300;font-size:16px;margin:16px;line-height:24px;text-align:center}@media only screen and (max-width:800px){.grey-container_nvf5M{margin:16px}}.amount-wrapper_ZhCql{margin:0 auto;max-width:378px;text-align:left;display:flex}.bnpl-subject-to-approval_oy585{font-size:12px;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;line-height:16px;font-weight:300;color:#767676;margin-top:30px;margin-bottom:0;max-width:690px}.tooltip_U83hs{color:#000;padding:4px;cursor:help}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form_iuRm2",
	"form__controls": "form__controls_yhqcG",
	"form__submit-button": "form__submit-button_29uO7",
	"grey-container": "grey-container_nvf5M",
	"amount-wrapper": "amount-wrapper_ZhCql",
	"bnpl-subject-to-approval": "bnpl-subject-to-approval_oy585",
	"tooltip": "tooltip_U83hs"
};
export default ___CSS_LOADER_EXPORT___;
