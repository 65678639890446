// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".file-list_SbFw9{margin-bottom:20px}.dropzone-container_Z4VB3{max-width:800px;margin:30px auto 60px;text-align:center}.dropzone-container_Z4VB3 .upload-zone_RdM4A{margin:0 auto;max-width:350px;margin-bottom:25px}@media only screen and (max-width:500px){.dropzone-container_Z4VB3{margin:30px auto 40px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file-list": "file-list_SbFw9",
	"dropzone-container": "dropzone-container_Z4VB3",
	"upload-zone": "upload-zone_RdM4A"
};
export default ___CSS_LOADER_EXPORT___;
