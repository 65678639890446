<script setup>
import { ref } from 'vue';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { FormNewOwner } from '@/components';
import FormError from '@/components/forms/shared/FormError';
import { useRouter } from 'vue-router';

const router = useRouter();

const errorMessage = ref(null);

function handleError(error) {
  errorMessage.value = error;
}

function handleSuccess() {
  router.push({ name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST });
}

function handleBack() {
  router.push({ name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST });
}
</script>

<template>
  <div>
    <FormError v-if="!!errorMessage" :error-message="errorMessage" />
    <div :class="$style['owner-wrapper']">
      <FormNewOwner
        @onBack="handleBack"
        @onSuccess="handleSuccess"
        @onError="handleError"
      />
    </div>
  </div>
</template>

<style lang="less" module scoped>
.owner-wrapper {
  max-width: 556px;
  margin: 0 auto;
}
</style>
