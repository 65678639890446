// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_BByix{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;margin:50px auto;padding:0 20px;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_BByix"
};
export default ___CSS_LOADER_EXPORT___;
