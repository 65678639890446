<template>
  <div :class="$style.container">
    <slot name="header" />
    <VeeForm
      :class="$style.body"
      :validation-schema="schema"
      :initial-values="initialValues"
      data-cy="form"
      @submit="handleSubmit"
    >
      <Card :class="$style.widget">
        <template #header>
          <span
            :class="$style.logo"
            data-cy="clearbanc-logo"
            v-html="logoSVG"
          />
        </template>
        <template v-if="title" #title>
          {{ title }}
          <Message
            v-if="successMessage"
            data-cy="success-message"
            severity="success"
            :closable="false"
            :class="$style.success"
          >
            {{ successMessage }}
          </Message>
        </template>

        <template #content>
          <div class="small" :class="$style.content">
            <slot name="body" />
            <p v-if="error" class="p-error">{{ error }}</p>
          </div>
        </template>
        <template v-if="onSubmit" #footer>
          <div class="c-form__controls">
            <DSButton
              :loading="processing"
              :class="$style['submit-btn']"
              class="p-button-secondary"
              :label="submitText ?? 'Submit'"
              icon="pi pi-chevron-right"
              icon-pos="right"
              type="submit"
              :disabled="disabled"
            />
          </div>
        </template>
      </Card>
    </VeeForm>
    <slot name="footer" />
  </div>
</template>

<script>
import Card from '@clearbanc/clear-components/card';
import { Form as VeeForm } from 'vee-validate';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';

export default {
  components: {
    Card,
    DSButton,
    VeeForm,
    Message,
  },
  props: {
    title: String,
    submitText: String,
    error: String,
    onSubmit: Function,
    disabled: Boolean,
    successMessage: String,
    initialValues: Object,
    schema: Object,
  },
  data() {
    return {
      logoSVG: require('@/assets/logos/clearco-logo.svg'),
      processing: false,
    };
  },
  methods: {
    async handleSubmit(values) {
      this.processing = true;
      try {
        await this.onSubmit?.(values);
      } finally {
        this.processing = false;
      }
    },
    hasSlotElements() {
      return this.$slots?.default;
    },
  },
};
</script>

<style lang="less" module>
.container {
  min-height: 100vh;
  min-width: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  .body {
    padding: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .widget {
    width: 340px;

    .logo {
      display: block;
      padding: 30px 60px;
    }

    .submit-btn {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 14px !important;
      width: 100%;
    }

    .content {
      padding: 0 1rem;
      [class^='p-error'] {
        font-size: 13px !important;
        text-align: left;
        font-weight: 300;
      }
    }
  }
}
</style>
