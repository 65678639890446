<template>
  <div id="app">
    <deploy-notification file-name="clearcom.js" />
    <global-progress />
    <metainfo></metainfo>
    <component :is="navLayout">
      <router-view />
    </component>
    <api-error-popup />
  </div>
</template>

<script>
import faviconUrl from '@/assets/images/favicon.ico';
import '@/utils/init-table-component';
import { useMeta } from 'vue-meta';
import { useClearComp } from '@clearbanc/clear-components/config';

const components = {
  'deploy-notification': require('@/components/DeployNotification').default,
  'global-progress': require('@/components/progress/ProgressGlobal').default,
  'api-error-popup': require('@/components/popups/PopupApiError').default,
  'wayfinding-layout': require('@/layout/LayoutWayfinding').default,
};

const metaInfo = {
  title: 'Financing for the new economy | Clearco', // default title
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
    // meta description - override on all indexable pages
    {
      vmid: 'description',
      name: 'description',
      content: 'Financing for the new economy',
    },
  ],
  link: [{ rel: 'shortcut icon', type: 'image/ico', href: faviconUrl }],
  script: [],
};

export default {
  components,
  setup() {
    useMeta(metaInfo);
    // TODO: make this locale dynamic
    useClearComp('en_US');
  },
  computed: {
    navLayout() {
      return this.$route.meta.displayNav ? 'wayfinding-layout' : 'div';
    },
  },
};
</script>

<style lang="less">
@import '~assets/style/core.less';
@import '~assets/style/fonts/fonts.less';

#app {
  height: 100%;
}
</style>
