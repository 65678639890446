import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  state: {
    plaidErrorCodes: [],
  },
  getters: {
    plaidErrorCodes: (state) => {
      return state.plaidErrorCodes;
    },
  },
  ...buildApiActions({
    GET_PLAID_ERROR_CODES: {
      action: (_ctx) => {
        return {
          method: 'get',
          url: `/plaid-error-codes?is_enabled=true`,
        };
      },
      mutation: (state, { response }) => {
        state.plaidErrorCodes = response;
      },
    },
  }),
};
