<template>
  <div class="dashboard-page">
    <div v-if="getUserAdvancesRequest.isPending">
      <icon name="spinner" scale="1" />
    </div>
    <div
      v-else-if="
        blockAdvancesIfBankDisconnected && isPrimaryBankAccountDisconnected
      "
      class="error-bar"
      @click="rerouteToAccountConnectionsPage"
    >
      {{ $t('components.pageDashboard.reconnectBankAccount') }}
    </div>
    <div v-else>
      <h2 class="indent-heading">
        {{ $t('components.pageDashboard.advances') }}
      </h2>
      <div v-if="validActiveAdvances.length" class="section">
        <h4 class="indent-heading">
          {{ $t('components.pageDashboard.current') }}
        </h4>
        <ActiveAdvanceDetailsWithConditionalProgressBar
          v-for="a in validActiveAdvances"
          :key="a.id"
          :advance="a"
        />
      </div>
      <div v-if="displayFacebookSpendBar" class="section">
        <div class="facebook-details">
          <h4>{{ $t('components.pageDashboard.facebookAdvanceBalance') }}</h4>
          <p>
            {{ $t('components.pageDashboard.trackBalance') }}
          </p>
          <ui-button
            :text="$t('components.pageDashboard.goToFacebookAdAccount')"
            icon="launch"
            primary
            @click="loadFacebookAdAccount()"
          />
        </div>
        <form-row v-if="!activeCreditRefinanceAdvance" no-inputs="no-inputs">
          <v-button
            class="no-margin"
            data-cy="pay-invoice-btn"
            :to="{ name: 'pay-vendors' }"
            >{{ $t('components.pageDashboard.payAnInvoice') }}</v-button
          >
        </form-row>
      </div>
      <div v-if="settledValidAdvances.length">
        <h4 class="indent-heading">
          {{ $t('components.pageDashboard.previousAdvances') }}
        </h4>
        <custom-table
          :data="settledValidAdvances"
          :loading="getUserAdvancesRequest.isPending"
          data-cy="settled-advances-table"
          class="dashboard-table-component"
          bordered
          gerstner
        >
          <template #row="slotProps">
            <custom-column
              :row="slotProps?.row"
              show="id"
              :label="() => 'ID'"
              gerstner
            />
            <custom-column
              :row="slotProps?.row"
              show="activatedAt"
              :label="() => $t('components.pageDashboard.activated')"
              type="date"
              gerstner
            />
            <custom-column
              :row="slotProps?.row"
              show="settledAt"
              :label="() => $t('components.pageDashboard.settled')"
              type="date"
              gerstner
            />
            <custom-column
              :row="slotProps?.row"
              show="amountCents"
              :label="() => $t('components.pageDashboard.amount')"
              type="money"
              gerstner
            />
            <custom-column
              :row="slotProps?.row"
              show="feeCents"
              :label="() => $t('components.pageDashboard.fee')"
              type="money"
              gerstner
            />
            <custom-column
              :row="slotProps?.row"
              show="topUpAmountCents"
              :label="() => $t('components.pageDashboard.amountOutstanding')"
              type="money"
              gerstner
            />
            <custom-column
              :row="slotProps?.row"
              show="repaymentRate"
              :label="() => $t('components.pageDashboard.remittanceRate')"
              type="percent"
              gerstner
            />
            <custom-column
              v-if="hasCashback"
              :row="slotProps?.row"
              show="cashbackTotalCents"
              :label="() => $t('components.pageDashboard.discountCredit')"
              type="money"
              gerstner
            />
          </template>
        </custom-table>
        <router-link :to="{ name: 'payments' }" class="indent-heading">{{
          $t('advancesPage.viewPaymentDetailsCapitalized')
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { UiMsg } from 'ui-components';
import { UiButton } from '@clearbanc/design-components/src/components';
import ActiveAdvanceDetailsWithConditionalProgressBar from '@/components/ActiveAdvanceDetailsWithConditionalProgressBar';
import CustomTable from '@/components/CustomTablePayments';
import CustomColumn from '@/components/CustomColumnPayments';
import { ADVANCE_SUBTYPES } from '@/data/advance-subtypes';
import { WAYFINDING_ROUTES } from '@/data/wayfinding';
import analytics from '@/utils/analytics';
import ProgressBar from '@/components/progress/ProgressBar';

const components = {
  ActiveAdvanceDetailsWithConditionalProgressBar,
  ProgressBar,
  UiMsg,
  CustomTable,
  CustomColumn,
  UiButton,
};

export default {
  components,
  data() {
    return {
      // SC-154265. When this flag is removed, the associated test must be re-enabled inside PageDashboard.spec.js
      blockAdvancesIfBankDisconnected: false,
    };
  },
  computed: {
    ...mapGetters([
      'business',
      'advanceInNegotiation',
      'activeAdvances',
      'validActiveAdvances',
      'settledValidAdvances',
      'isPrimaryBankAccountDisconnected',
      'userHasEverHadActiveAdvance',
      'facebookUrl',
    ]),
    ...mapRequestStatuses({
      getUserAdvancesRequest: ['GET_USER_ADVANCES'],
    }),
    hasCashback() {
      return this.settledValidAdvances.some(
        (a) => !!Number(a.cashbackTotalCents),
      );
    },
    activeCreditRefinanceAdvance() {
      return this.activeAdvances
        .map((advance) => advance.subtype)
        .includes(ADVANCE_SUBTYPES.CREDIT_REFINANCE);
    },
    activeFacebookAdvance() {
      return this.activeAdvances
        .map((advance) => advance.subtype)
        .includes(ADVANCE_SUBTYPES.MARKETING_FACEBOOK);
    },
    displayFacebookSpendBar() {
      return this.activeFacebookAdvance && !!this.facebookUrl;
    },
  },
  watch: {
    async business() {
      await this.$store.dispatchApiAction('FETCH_USER_CARDS', {
        canceled: false,
      });
    },
  },
  async beforeMount() {
    await this.$store.dispatch('fetchFacebookMetadata');
  },
  async mounted() {
    if (this.business.id)
      this.$store.dispatchApiAction('FETCH_USER_CARDS', { canceled: false });
    await this.$store.dispatch('refreshAdvances');
    if (!this.userHasEverHadActiveAdvance) {
      await this.rerouteLinks();
    }
  },
  methods: {
    async rerouteLinks() {
      await this.$store.dispatchApiAction('FETCH_USER_EXTERNAL_ACCOUNTS');
      return this.$router.push({ name: 'application' });
    },
    loadFacebookAdAccount() {
      analytics.track('fe_caphome_spend_facebook_tile_click');
      window.open(this.facebookUrl, '_blank');
    },
    rerouteToAccountConnectionsPage() {
      this.$router.push({
        name: WAYFINDING_ROUTES.ACCOUNT_CONNECTIONS,
        params: { sectionToOpen: 'connect-banking' },
      });
    },
  },
};
</script>

<style lang="less">
.facebook-details {
  border: 2px solid #ddd;
  padding: 24px;
  background: white;
}

.dashboard-page {
  margin-top: 80px;

  * {
    font-family: @gerstner-font;
  }

  .section {
    padding: 28px 0;
  }

  .no-margin {
    margin: 0 !important;
  }

  .indent-heading {
    margin-left: 24px;
  }

  h2 {
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
  }

  h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
  }

  .dashboard-table-component {
    margin: 25px 0 25px;
    background-color: white;
    border: 1px solid @color-grey-100;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);

    table thead tr th {
      text-transform: none !important;
      padding: 6px !important;
    }
  }
}

.advance-details {
  margin-top: 18px;
  border: 1px solid @color-grey-200;
  border-radius: 8px;
}

.appstore-msg {
  padding: 20px;
  max-width: 100% !important;
  margin: 0 !important;
  color: @color-white !important ;
  background: #097e78 !important;
  border-radius: 10px !important;
}
.appstore-icon {
  margin-top: 20px;
  height: 50px;
}

.error-bar {
  position: absolute;
  left: 0;
  right: 0;
  top: @header-bar-height--desktop;
  margin-top: 0;
  background: @red;
  background: fade(@red, 55);
  padding: 2px 2px;
  color: white;
  text-align: center;
  z-index: 1000;
  width: 100%;
  cursor: pointer;
  text-decoration: underline;

  a {
    color: white;
  }

  &:hover {
    background: fade(@red, 75);
    color: @dark-gray;
  }

  &.is-disabled,
  &.is-loading {
    opacity: 0.5;
  }

  @media @mq-small-only {
    top: @header-bar-height--mobile;
  }
}
</style>
