// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter_kKNlU [class^=p-column-filter-constraint]{padding-bottom:0}.filter_kKNlU li+li{margin-top:10px}.filter_kKNlU div+label{margin-left:10px}.filter_kKNlU label{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "filter_kKNlU"
};
export default ___CSS_LOADER_EXPORT___;
