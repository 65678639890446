<template>
  <div
    :class="{
      [$style['container']]: true,
      [$style[`${type}`]]: true,
      [$style['error-container']]: type === 'error',
    }"
  >
    <div :class="{ [$style.wrapper]: true, [$style.small]: isSmall }">
      <svg
        v-if="isClosable"
        :class="$style['close-icon']"
        tabindex="0"
        @click="$emit('close')"
        @keyup.enter="$emit('close')"
        v-html="require('@/assets/icons/close-dark.svg')"
      />
      <svg
        :class="$style.icon"
        v-html="require(`@/assets/icons/${type}.svg`)"
      />
      <div :class="$style.msg" :data-cy="type + '-ui-msg'">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isClosable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      // TODO: uncomment once we have icon and design
      // default: 'success',
      validator(value) {
        // TODO: add success once we have icon and design
        return ['error'].includes(value);
      },
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" module>
.container {
  position: relative;
  display: flex;
  max-width: 800px;
  margin: @gutter-20 auto;
  padding: @gutter-20;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  &.success {
    color: @cc-light-brown;
    background-color: @color-teal-light;
  }
  &.error {
    color: @cc-light-brown;
    background-color: @error-bg;
  }
  @media only screen and (max-width: 500px) {
    display: block;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.wrapper {
  display: flex;
  &.small {
    align-items: center;
    margin: auto;
  }
}
.close-icon {
  position: absolute;
  top: 7px;
  right: 10px;
  width: 100%;
  max-width: 10px;
  height: 15px;
}
.icon {
  display: block;
  width: 100%;
  max-width: 35px;
  height: 25px;
  margin-right: 10px;
  @media only screen and (max-width: 500px) {
    margin-bottom: 8px;
  }
}
.msg {
  p:first-child {
    margin-top: 0;
  }
}
.error-container {
  @media screen and (max-width: 500px) {
    margin-left: -10px;
    margin-right: -10px;
  }
}
</style>
