<template>
  <div :class="$style.wrapper">
    <div :class="$style.criteria">
      <h3 :class="$style['heading-tertiary']">Requirements</h3>
      <ul :class="$style['criteria-list']">
        <li
          v-for="item in content.criteria"
          :class="$style['criteria-list__item']"
        >
          {{ item }}
        </li>
      </ul>
      <a
        v-if="content.criteriaLink && content.criteriaLabel"
        :href="content.criteriaLink"
        :class="$style.criteria__link"
        target="_blank"
        >{{ content.criteriaLabel }}</a
      >
    </div>
    <div :class="$style.categories">
      <h3 :class="$style['heading-tertiary']">Eligible Categories</h3>
      <ul :class="$style['category-list']">
        <li
          v-for="item in content.categories"
          :class="$style['category-list__item']"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div :class="$style.cta">
      <!-- eslint-disable-next-line -->
      <a :href="content.ctaLink" target="_blank">
        <DSButton
          :label="content.ctaLabel"
          @click="
            () => {
              analytics.track(
                `product_education_clicked_what_can_i_fund_${content.ctaLabel
                  .replaceAll(' ', '_')
                  .toLowerCase()}`,
              );
            }
          "
        />
      </a>
    </div>
  </div>
</template>

<script>
import DSButton from '@clearbanc/clear-components/button';
import analytics from '@/utils/analytics';

export default {
  components: { DSButton },
  props: {
    content: { type: Object, required: true },
  },
  data() {
    return {
      analytics,
    };
  },
};
</script>

<style lang="less" module>
.wrapper {
  margin-bottom: 25px;
  display: grid;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-columns: 1fr;
}

.criteria {
  background: #fff;
  border-radius: 4px;
  padding: 30px 40px;
}

.criteria__link {
  color: #297fe3;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration-line: underline;
  margin-top: 30px;
  display: block;
}

.criteria-list {
  position: relative;
}

.criteria-list:before {
  content: '';
  width: 1px;
  height: calc(100% - 12px);
  background: #ededed;
  position: absolute;
  top: 6px;
  left: 5px;
}

.criteria-list__item {
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  margin-top: 16px;
  display: flex;
}

.criteria-list__item:before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background: #29ca43;
  border-radius: 50%;
  flex-shrink: 0;
  transform: translateY(3px);
  margin: 0 12px 0 0;
}

.categories {
  background: #fff;
  border-radius: 4px;
  padding: 30px 40px;
}

.category-list {
  border: 1px solid #ededed;
  border-radius: 4px;
  min-width: 255px;
}

.category-list__item {
  border-top: 1px dashed #ededed;
  padding: 10px 18px;
  white-space: nowrap;
  color: #5e5e5e;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.category-list__item:first-child {
  border: 0;
}

.cta {
  white-space: nowrap;
}

.cta button {
  width: 100%;
}

.wrapper h3.heading-tertiary {
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  text-decoration-line: underline;
  white-space: nowrap;
  margin: 0 0 24px 0;
}

@media (min-width: 800px) {
  .wrapper {
    grid-template-columns: 1fr min-content;
  }

  .criteria {
    grid-row: 1/3;
    grid-column: 1/2;
  }

  .categories {
    grid-row: 1/2;
    grid-column: 2/3;
  }

  .cta {
    grid-row: 2/3;
    grid-column: 2/3;
  }
}
</style>
