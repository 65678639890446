<script setup>
import { computed, onMounted, reactive, watch, readonly } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useForm } from 'vee-validate';
import { object, string } from 'yup';

import DSButton from '@clearbanc/clear-components/button';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import FormInputDetails from '@/components/forms/shared/FormInputDetails';
import FormPhoneInput from '@/components/forms/FormPhoneInput';
import CalendarGroup from '@clearbanc/clear-components/calendargroup';
import { UploadFileWithDownloadableFileList } from '@/components';

import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { qualifiedNorthAmericanCountries } from '@/data/country-code-with-names';
import statesByCountry from '@/data/states-by-country';
import { corpTypesForCountry } from '@/data/corp-types-by-country';
import {
  getAllCallingCodes,
  getPhoneNumberCountryCode,
  getPhoneNumberWithoutCountryCode,
} from '@/utils/phone-numbers';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import { ENTITY_TYPE, BUSINESS_DOC_TYPE } from '@clearbanc/data-common-types';
import {
  stateLabel,
  federalTaxIdLabel,
  federalTaxIdShortLabel,
  registrationNumberLabel,
  getCountryObject,
} from '@/utils/local';
import { validatePhone, validateEin } from '@/utils/schemaValidators';
import { getParsedMessage } from '@/data/error-messages';

// -----
// Setup
// -----

const store = useStore();
const router = useRouter();
const emits = defineEmits(['validationSuccess', 'validationFailure']);

// ---------
// Variables
// ---------

const callingCodes = getAllCallingCodes();

const states = (country) => {
  return statesByCountry[country] || [];
};

const incorporationDocuments = computed(() =>
  store.getters.uploadsMatching(store.getters.incorporationUploadMatcher),
);

const countries = readonly(
  qualifiedNorthAmericanCountries().map((country) => ({
    value: country.value,
    nameString: country.nameString,
  })),
);

const requestStatuses = reactive({
  updateBusiness: computed(() =>
    store.getters.requestStatus('UPDATE_BUSINESS'),
  ),
});

// -------
// Methods
// -------

function handleGoBack() {
  router.push({
    name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
  });
}

function removeUpload(uploadId) {
  store.dispatchApiAction('DELETE_USER_UPLOAD', { id: uploadId });
}

// ----------
// Form Logic
// ----------

const validationSchema = object({
  country: object().nullable().required('Required'),
  state: object().nullable().required('Required'),
  name: string().nullable().required('Required'),
  phone: string()
    .nullable()
    .when('countryCallingCode', (countryCallingCode, schema) => {
      // https://www.npmjs.com/package/libphonenumber-js
      if (countryCallingCode) {
        validatePhone(schema, countryCallingCode);
      }

      return schema.required('Required');
    }),
  countryCallingCode: object().nullable().required('Required'),
  registrationNumber: string()
    .nullable()
    .matches(/^\d+$/, 'Invalid number') // Only allow numbers
    .required('Required'),
  federalTaxId: string()
    .nullable()
    .when('country', (country, schema) => {
      if (country?.value === COUNTRY_CODES.US) {
        return validateEin(schema);
      }

      return schema.required('Required');
    }),
  corpType: object().nullable().required('Required'),
  corpTypeOther: string().when('corpType', (corpType, schema) => {
    if (corpType?.value === 'other_option') {
      return schema.required('Required');
    }

    return schema;
  }),
  corpDate: string().nullable().required('Required'),
  articlesOfIncorporation: string()
    .nullable()
    .when([], {
      is: () => incorporationDocuments.value.length < 1,
      then: (schema) =>
        schema.required('Please upload your business incorporation documents.'),
      otherwise: (schema) => schema.notRequired(),
    }),
});

const { values, handleSubmit, setValues, isSubmitting, validateField } =
  useForm({
    validationSchema,
  });

const onSubmit = handleSubmit(async () => {
  await store.dispatchApiAction('UPDATE_BUSINESS', {
    corpCountry: values.country.value,
    corpState: values.state.value,
    name: values.name,
    phone: `${values.countryCallingCode.callingCode}${values.phone}`,
    registrationNumber: values.registrationNumber,
    federalTaxId: values.federalTaxId,
    corpType: values.corpTypeOther
      ? `other - ${values.corpTypeOther}`
      : values.corpType?.value,
    corpDate: values.corpDate,
  });

  if (requestStatuses.updateBusiness.isError) {
    emits(
      'validationFailure',
      getParsedMessage(requestStatuses.updateBusiness.error, true),
    );
    return;
  }

  emits('validationSuccess');
});

function populateSavedValues() {
  const initialValues = {};

  // Country
  if (store.getters.business.corpCountry) {
    initialValues.country = getCountryObject(
      store.getters.business.corpCountry,
    );
  }

  delete initialValues.country?.key;
  delete initialValues.country?.name;
  delete initialValues.country?.priority;

  // State
  if (store.getters.business.corpState) {
    initialValues.state = statesByCountry[
      store.getters.business.corpCountry
    ].find((state) => state.value === store.getters.business.corpState);
  }

  // Business Name
  initialValues.name = store.getters.business.name;

  // Phone
  if (store.getters.business.phone) {
    initialValues.countryCallingCode =
      getPhoneNumberCountryCode(store.getters.business.phone) ||
      callingCodes.find((item) => {
        return item.value === COUNTRY_CODES.US;
      });
    initialValues.phone = getPhoneNumberWithoutCountryCode(
      store.getters.business.phone,
    );
  } else {
    const callingCode = callingCodes.find((item) => {
      return item.value === COUNTRY_CODES.US;
    });

    initialValues.countryCallingCode = callingCode;
  }

  // Registration Number
  if (store.getters.business?.registrationNumber) {
    initialValues.registrationNumber =
      store.getters.business?.registrationNumber;
  }

  // Federal Tax ID
  if (store.getters.business?.federalTaxId) {
    initialValues.federalTaxId = store.getters.business?.federalTaxId;
  }

  // Legal Business Structure
  if (store.getters.business?.corpType) {
    initialValues.corpType = corpTypesForCountry(
      store.getters.business.corpCountry,
    ).find((type) => type.value === store.getters.business.corpType);
  }

  // Other Legal Business Structure
  if (store.getters.business?.corpTypeOther) {
    initialValues.corpTypeOther = store.getters.business.corpTypeOther;
  }

  // Business Incorporation Date
  if (store.getters.business?.corpDate) {
    initialValues.corpDate = store.getters.business.corpDate.split('T')[0];
  }

  setValues(initialValues);
}

watch(incorporationDocuments, () => {
  validateField('articlesOfIncorporation');
});

// -----------------
// Lifecycle Methods
// -----------------

onMounted(() => {
  populateSavedValues();
});
</script>

<template>
  <form
    class="c-form"
    :class="$style.form"
    :style="{ pointerEvents: isSubmitting ? 'none' : 'all' }"
    @submit="onSubmit"
  >
    <!-- //////////////////// -->
    <!-- Business Information -->
    <!-- //////////////////// -->

    <div class="c-group">
      <div class="c-group__header">
        <h3 class="c-group__header__title">Business Information</h3>
      </div>
      <div class="c-form__row grid grid-cols-12">
        <div class="col-span-6 md:col-span-12">
          <DropdownGroup
            name="country"
            label="Country"
            :options="countries"
            option-label="nameString"
            placeholder="Select"
            append-to="self"
            :reset-filter-on-hide="true"
            @change="
              setValues({
                ...values,
                state: null,
              })
            "
          />
        </div>
        <div class="col-span-6 md:col-span-12">
          <DropdownGroup
            name="state"
            :label="stateLabel(values.country?.value)"
            :options="states(values.country?.value)"
            option-label="nameString"
            placeholder="Select"
            append-to="self"
            :filter="true"
            :reset-filter-on-hide="true"
            :disabled="!values.country?.value"
          />
        </div>
      </div>
      <div class="c-form__row grid grid-cols-12">
        <div class="col-span-6 md:col-span-12">
          <InputTextGroup name="name" label="Registered Business Name" />
          <FormInputDetails>
            Include structure (i.e. ltd., inc., etc)
          </FormInputDetails>
        </div>
        <div class="col-span-6 md:col-span-12">
          <FormPhoneInput
            :dropdown-attrs="{
              name: 'countryCallingCode',
              options: callingCodes,
              optionLabel: 'nameString',
              placeholder: 'Select',
              filter: true,
            }"
            :input-attrs="{
              name: 'phone',
              label: 'Business Phone Number',
            }"
          />
        </div>
      </div>
      <div class="c-form__row grid grid-cols-12">
        <div class="col-span-6 md:col-span-12">
          <InputTextGroup
            name="registrationNumber"
            :label="registrationNumberLabel(values.country?.value)"
          />
        </div>
        <div class="col-span-6 md:col-span-12">
          <InputTextGroup
            name="federalTaxId"
            :label="
              federalTaxIdLabel(values.country?.value) ||
              federalTaxIdShortLabel(values.country?.value) ||
              federalTaxIdLabel(COUNTRY_CODES.US)
            "
            :disabled="!values.country?.value"
          />
        </div>
      </div>
      <div class="c-form__row grid grid-cols-12">
        <div class="col-span-6 md:col-span-12">
          <DropdownGroup
            name="corpType"
            label="Legal Business Structure"
            :options="corpTypesForCountry(values.country?.value)"
            option-label="nameString"
            placeholder="Select"
            append-to="self"
            :reset-filter-on-hide="true"
            :disabled="!values.country?.value"
          />
        </div>
        <div
          v-if="values.corpType?.value === 'other_option'"
          class="col-span-6 md:col-span-12"
        >
          <InputTextGroup
            name="corpTypeOther"
            label="Other Legal Business Structure"
          />
        </div>
      </div>
    </div>

    <!-- //////////////////////////////// -->
    <!-- Business Incorporation Documents -->
    <!-- //////////////////////////////// -->

    <div class="c-group">
      <div class="c-group__header">
        <h3 class="c-group__header__title">Business Incorporation Documents</h3>
      </div>
      <div class="c-form__row grid grid-cols-12">
        <div class="col-span-6 md:col-span-12">
          <CalendarGroup
            label="Business Incorporation Date"
            name="corpDate"
            :manual-input="false"
            :max-date="new Date()"
            placeholder="yyyy-mm-dd"
            show-icon
          />
        </div>
      </div>
      <div class="c-form__row grid grid-cols-12">
        <div class="col-span-6 md:col-span-12">
          <h3 :class="$style['field-heading']">
            Upload a copy of the Articles of Incorporation
          </h3>
          <p :class="$style['field-description']">
            Please provide the official document issued when your company was
            formed (e.g., Articles of Incorporation)
          </p>
          <UploadFileWithDownloadableFileList
            ref="upload-file-with-downloadable-file-list"
            :upload-filters="{
              entity: ENTITY_TYPE.BUSINESS,
              type: BUSINESS_DOC_TYPE.CORP_DOCS,
              businessId: store.getters.business.id,
            }"
            :display-filters="store.getters.incorporationUploadMatcher"
            empty-prompt="Drag & drop files here or browse"
            theme="onboarding"
            no-list
            :files="incorporationDocuments"
            @fileRemove="removeUpload"
          />
          <InputTextGroup
            name="articlesOfIncorporation"
            label=""
            :style="{ display: 'none' }"
          />
        </div>
        <div class="col-span-6 md:col-span-12">
          <h3 :class="$style['field-heading']">
            Upload a copy of the EIN document
          </h3>
          <p :class="$style['field-description']">
            Please upload the official EIN verification letter your business
            received from the IRS after applying for an EIN. You can do this now
            or anytime before funding. Learn more
          </p>
          <UploadFileWithDownloadableFileList
            ref="upload-file-with-downloadable-file-list"
            :upload-filters="{
              entity: ENTITY_TYPE.BUSINESS,
              type: BUSINESS_DOC_TYPE.EIN_DOC,
              businessId: store.getters.business.id,
            }"
            empty-prompt="Drag & drop files here or browse"
            theme="onboarding"
            no-list
            :files="store.getters.einDocuments"
            @fileRemove="removeUpload"
          />
        </div>
      </div>
    </div>

    <!-- ///////////// -->
    <!-- Form Controls -->
    <!-- ///////////// -->

    <div class="c-form__controls">
      <DSButton label="Back" class="p-button-outlined" @click="handleGoBack" />
      <DSButton label="Next" type="submit" :loading="isSubmitting" />
    </div>
  </form>
</template>

<style module scoped lang="less">
.form {
  margin: 40px 0 0;
}

div[class^='c-form__row']:empty {
  margin: 0;
}

.field-heading {
  color: var(--neutral-grey-400, #5f5f61);
  font-family: @gerstner-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 4px 0;
}

.field-description {
  color: var(--neutral-grey-500, #303032);
  font-family: @gerstner-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  margin: 0 0 20px 0;
}
</style>
