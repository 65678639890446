// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".details_kEG9L[data-v-7e9853b6]{margin:4px 0 0;color:var(--neutral-grey-400,#5f5f61);font-family:@gerstner-font;font-size:16px;font-style:normal;font-weight:300;line-height:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details": "details_kEG9L"
};
export default ___CSS_LOADER_EXPORT___;
