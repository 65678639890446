<template>
  <div :class="$style['dropdown-container']">
    <MultiSelect
      v-model="selectedOptions"
      data-cy="dropdown-multiselect"
      :class="$style['multiselect-custom']"
      :options="options"
      :option-label="optionLabel"
      :option-value="optionValue"
      :placeholder="placeholder"
      :display="displayChips ? 'chip' : 'comma'"
      :select-all="selectAll"
      :show-toggle-all="showToggleAll"
      @change="onChange($event)"
    />
  </div>
</template>

<script>
import MultiSelect from '@clearbanc/clear-components/multiselect';

export default {
  components: {
    MultiSelect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    displayChips: {
      type: Boolean,
      default: false,
    },
    showToggleAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOptions: null,
      selectAll: false,
    };
  },
  methods: {
    onChange(event) {
      this.$emit('on-change', event);
    },
  },
};
</script>

<style lang="less" module>
.dropdown-container {
  width: 100%;
  .multiselect-custom {
    width: 100%;
  }
}
</style>
