// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reconnect-border_TshRZ{border-top:1px solid #80808036;padding-top:20px;margin-top:20px}.reconnect-wrapper_NrlQi{max-height:100px;overflow-y:scroll}.reconnect_WfFpj{display:flex;align-items:center;justify-content:space-between;margin:5px auto}@media only screen and (max-width:500px){.reconnect_WfFpj{border-bottom:1px solid #80808036;max-width:300px}}.reconnect_WfFpj .connected_JXt23{margin-right:35px}.reconnect_WfFpj .shop-name_lwx7b{word-break:break-word;max-width:350px;padding-right:20px;text-align:left}@media only screen and (max-width:500px){.reconnect_WfFpj .shop-name_lwx7b{max-width:170px;padding-right:5px}}h3.subtitle_ZvzzR{font-weight:500;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:16px}.input-container_hBtNO{max-width:400px;margin:2rem auto;text-align:left}.input-container_hBtNO .button_WmcQD{display:block;margin-top:2rem;margin-left:auto}.help-section_T_yps{margin-top:1.5rem}.help-section_T_yps p{margin:0}@media only screen and (max-width:500px){.shopify-tip-content_Qo5O3{text-align:left}}.shopify-tip-content_Qo5O3 p{margin:0}.tooltip_YF4KY{color:#000;padding:4px;cursor:help}.shopify-modal-tooltip-content_IVJJl{max-width:400px!important}@media only screen and (max-width:500px){.shopify-modal-tooltip-content_IVJJl{max-width:calc(100% - 40px)!important;left:20px!important}}.shopify-modal-tooltip-content_IVJJl img,.shopify-modal-tooltip-content_IVJJl span,.shopify-modal-tooltip-content_IVJJl strong{display:block}.shopify-modal-tooltip-content_IVJJl img{max-width:100%;margin-top:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reconnect-border": "reconnect-border_TshRZ",
	"reconnect-wrapper": "reconnect-wrapper_NrlQi",
	"reconnect": "reconnect_WfFpj",
	"connected": "connected_JXt23",
	"shop-name": "shop-name_lwx7b",
	"subtitle": "subtitle_ZvzzR",
	"input-container": "input-container_hBtNO",
	"button": "button_WmcQD",
	"help-section": "help-section_T_yps",
	"shopify-tip-content": "shopify-tip-content_Qo5O3",
	"tooltip": "tooltip_YF4KY",
	"shopify-modal-tooltip-content": "shopify-modal-tooltip-content_IVJJl"
};
export default ___CSS_LOADER_EXPORT___;
