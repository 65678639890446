export const SelfRevenueRanges = {
  from_0_to_10: '0-10k',
  from_10_to_20: '10k-20k',
  from_20_to_50: '20k-50k',
  from_50_to_100: '50k-100k',
  over_100: '100k+',
  from_100_to_250: '100k-250k',
  from_100_to_300: '100k-300k',
  from_250_to_500: '250k-500k',
  from_300_to_500: '300k-500k',
  from_500_to_1m: '500k-1m',
  from_1m_to_5m: '1m-5m',
  over_5m: '5m+',
};

export const SelfRevenueOffers = {
  [SelfRevenueRanges.from_0_to_10]: '$10,000',
  [SelfRevenueRanges.from_10_to_20]: '$10,000 - $20,000',
  [SelfRevenueRanges.from_20_to_50]: '$20,000 - $50,000',
  [SelfRevenueRanges.from_50_to_100]: '$50,000 - $100,000',
  [SelfRevenueRanges.over_100]: '$100,000',
  [SelfRevenueRanges.from_100_to_250]: '$100,000 - $250,000',
  [SelfRevenueRanges.from_250_to_500]: '$250,000 - $500,000',
  [SelfRevenueRanges.from_100_to_300]: '$100,000 - $300,000',
  [SelfRevenueRanges.from_300_to_500]: '$300,000 - $500,000',
  [SelfRevenueRanges.from_500_to_1m]: '$500,000 - $1,000,000',
  [SelfRevenueRanges.from_1m_to_5m]: '$1,000,000 - $5,000,000',
  [SelfRevenueRanges.over_5m]: '$5,000,000',
};

export const SelfRevenueRangeToSelfReportedCapacityCents = {
  [SelfRevenueRanges.from_0_to_10]: 0,
  [SelfRevenueRanges.from_10_to_20]: 1650000,
  [SelfRevenueRanges.from_20_to_50]: 3200000,
  [SelfRevenueRanges.from_50_to_100]: 6350000,
  [SelfRevenueRanges.from_100_to_300]: 13400000,
  [SelfRevenueRanges.from_300_to_500]: 28000000,
  [SelfRevenueRanges.from_500_to_1m]: 53600000,
  [SelfRevenueRanges.from_1m_to_5m]: 127550000,
  [SelfRevenueRanges.over_5m]: 434200000,

  // legacy ranges
  [SelfRevenueRanges.over_100]: 6350000,
  [SelfRevenueRanges.from_100_to_250]: 13400000,
  [SelfRevenueRanges.from_250_to_500]: 28000000,
};

export const GenericPhrases = {
  selfReportedRevLimit: 'Up to $3,000,000',
};
