// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width:768px){.mobile-marketing-tooltip_d2vyo{left:0!important;margin:0 20px 0 20px;max-width:-webkit-fit-content!important;max-width:-moz-fit-content!important;max-width:fit-content!important}.mobile-marketing-tooltip_d2vyo div:first-child{display:none}}.tooltip_mfTM_{color:#000;padding:4px;cursor:help}.next-btn_I_jpB button{background-color:#7f30ff}.next-btn_I_jpB button:hover{background-color:#7f30ff!important}h2.heading_Hd5_c{font-size:21px;line-height:30px;font-weight:500;margin:0 0 24px}.sub-heading_yMLZY{margin:0 auto 20px;max-width:550px}@media screen and (max-width:500px){.sub-heading_yMLZY,h2.heading_Hd5_c{text-align:left}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile-marketing-tooltip": "mobile-marketing-tooltip_d2vyo",
	"tooltip": "tooltip_mfTM_",
	"next-btn": "next-btn_I_jpB",
	"heading": "heading_Hd5_c",
	"sub-heading": "sub-heading_yMLZY"
};
export default ___CSS_LOADER_EXPORT___;
