<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    hideTiles: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      return {
        [this.$style.container]: true,
        [this.$style['hide-some']]: this.hideTiles,
      };
    },
  },
};
</script>

<style lang="less" module>
.container {
  text-align: center;
  @media screen and (max-width: 500px) and (min-width: 375px) {
    [class^='ext-account'] {
      width: 154px;
      &:nth-child(odd) {
        margin: 7.5px 5px 7.5px 0;
      }
      &:nth-child(even) {
        margin: 7.5px 0 7.5px 5px;
      }
    }
    svg[class^='icon'] {
      z-index: 1;
    }
  }
  &.hide-some > * {
    &:last-child {
      display: none;
    }
    @media screen and (max-width: 500px) {
      &:nth-child(n + 5) {
        display: none;
      }
    }
  }
  &.hide-more > * {
    @media screen and (max-width: 500px) {
      &:nth-child(n + 3) {
        display: none;
      }
    }
  }
}
</style>
