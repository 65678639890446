// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_nyJZL .content_I3ZeY{padding-top:20px;text-align:center;transition:filter .2s linear}.container_nyJZL .content_I3ZeY.loading_azzve{filter:blur(2px)}.container_nyJZL .content_I3ZeY .link-more-accounts-button_QK3Ut{width:100%;max-width:350px}@media only screen and (max-width:500px){.container_nyJZL .content_I3ZeY .link-more-accounts-button_QK3Ut{float:unset}}.plaid-img_BPYmd{width:100%;max-width:260px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_nyJZL",
	"content": "content_I3ZeY",
	"loading": "loading_azzve",
	"link-more-accounts-button": "link-more-accounts-button_QK3Ut",
	"plaid-img": "plaid-img_BPYmd"
};
export default ___CSS_LOADER_EXPORT___;
