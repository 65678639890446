// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".description_cbJF6{margin:8px 0 0 0}.details_TeIrt{margin-top:40px}.help-link_avF8U{text-decoration:underline;margin:10px 0 0 0;display:inline-block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "description_cbJF6",
	"details": "details_TeIrt",
	"help-link": "help-link_avF8U"
};
export default ___CSS_LOADER_EXPORT___;
