// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_vRqdb div[class=p-dialog-header]{justify-content:space-between;padding:.5rem}.modal_vRqdb div[class=p-dialog-header] div[class=p-dialog-header-icons]{position:static}.form_MsfW2{text-align:left}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_vRqdb",
	"form": "form_MsfW2"
};
export default ___CSS_LOADER_EXPORT___;
