// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_UFDUY{display:flex;flex-direction:column;align-items:center}.plaid-img_bn5A6{width:100%;max-width:260px;padding-top:10px}.spacer_EkgdC{height:64px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_UFDUY",
	"plaid-img": "plaid-img_bn5A6",
	"spacer": "spacer_EkgdC"
};
export default ___CSS_LOADER_EXPORT___;
