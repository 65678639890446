// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".theme--purple_lKdl_ button{background-color:#721cff}.theme--purple_lKdl_ button:hover{background-color:#721cff!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theme--purple": "theme--purple_lKdl_"
};
export default ___CSS_LOADER_EXPORT___;
