// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropzone-container_nKPA1{text-align:center}.dropzone-container_nKPA1 .upload-zone_Wo4GB{margin:0 auto;max-width:350px;margin-bottom:25px}.dropzone-container_nKPA1 .uploaded-item_c_0N0{display:flex;align-items:center;justify-content:center}.dropzone-container_nKPA1 .uploaded-item_c_0N0 span{margin-right:.75rem}.dropzone-container_nKPA1 .uploaded-item_c_0N0 svg{height:12px;width:12px;cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone-container": "dropzone-container_nKPA1",
	"upload-zone": "upload-zone_Wo4GB",
	"uploaded-item": "uploaded-item_c_0N0"
};
export default ___CSS_LOADER_EXPORT___;
