// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_s3w56{display:flex;align-items:center}button.button-link_eCUeI{padding:0}.space_onixy{margin:0 5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_s3w56",
	"button-link": "button-link_eCUeI",
	"space": "space_onixy"
};
export default ___CSS_LOADER_EXPORT___;
