export const ADVANCE_SUBTYPES = {
  UNIFIED: 'unified', // Marketing
  INVENTORY: 'inventory',
  VENTURES: 'ventures',
  BNPL: 'bnpl',
  BILLS: 'bills',
  MARKETING_FACEBOOK: 'marketing_facebook',
  CREDIT_REFINANCE: 'credit_refinance',
};

export const ADVANCE_SUPRATYPES = {
  [ADVANCE_SUBTYPES.UNIFIED]: 'marketing',
  [ADVANCE_SUBTYPES.INVENTORY]: 'inventory',
  [ADVANCE_SUBTYPES.MARKETING_FACEBOOK]: 'marketing',
  marketing: 'marketing',
};

export const ADVANCE_SUBTYPES_OPTIONS = Object.values(ADVANCE_SUBTYPES);

export const VALID_ADVANCE_SUBTYPES = [
  ADVANCE_SUBTYPES.UNIFIED,
  ADVANCE_SUBTYPES.INVENTORY,
  ADVANCE_SUBTYPES.VENTURES,
];
