// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".desktop-menu_MLoUP{display:flex;flex:1;align-items:center;justify-content:flex-end;padding:40px}.desktop-menu_MLoUP a{color:#000;letter-spacing:1px;text-decoration:none;margin:0 20px;line-height:20px}.desktop-menu_MLoUP a span{font-size:10px;font-family:Montserrat;margin-top:-10px}.desktop-menu_MLoUP a span.recommendations-nav-sub_yIhEZ{position:absolute;bottom:18px;font-size:10px;color:#afafaf}.desktop-menu_MLoUP a:focus,.desktop-menu_MLoUP a:hover{color:#000;text-decoration:underline}.desktop-menu_MLoUP a.signup-link_ZZ3LF{letter-spacing:1px;line-height:50px}.desktop-menu_MLoUP a.signup-link_ZZ3LF:focus,.desktop-menu_MLoUP a.signup-link_ZZ3LF:hover{color:#00a69d;text-decoration:none}.desktop-menu_MLoUP .banking-nav_BaRp8{min-width:85px;text-align:center}.desktop-menu_MLoUP .banking-nav_BaRp8 .banking-nav-sub_jp2Zo{color:#721cff;font-size:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop-menu": "desktop-menu_MLoUP",
	"recommendations-nav-sub": "recommendations-nav-sub_yIhEZ",
	"signup-link": "signup-link_ZZ3LF",
	"banking-nav": "banking-nav_BaRp8",
	"banking-nav-sub": "banking-nav-sub_jp2Zo"
};
export default ___CSS_LOADER_EXPORT___;
