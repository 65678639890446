<template>
  <span>
    <ext-account
      title="googleplay"
      :logo="googleplay"
      :success="success"
      :disconnected="disconnected"
      :error="errors.playstore"
      logo-alt="Google Play Store logo"
      @click="openModal"
    />

    <ModalPlaystore
      ref="playstoreModal"
      @error="$emit('error', SALES_PLATFORMS.PLAYSTORE)"
      @submit="(payload) => $emit('submit', payload)"
      @modalAction="
        (platformName, action) => $emit('modalAction', platformName, action)
      "
    />
  </span>
</template>

<script>
import { SALES_PLATFORMS } from '@/data/platforms';
import ExtAccount from '@/components/modals/ModalExternalAccountOnboarding2';
import ModalPlaystore from '@/components/modals/ModalPlaystore';

export default {
  components: {
    ExtAccount,
    ModalPlaystore,
  },
  props: {
    success: Boolean,
    disconnected: Boolean,
    errors: Object,
  },
  computed: {
    googleplay: () => require('@/assets/logos/googleplay.png'),
    SALES_PLATFORMS: () => SALES_PLATFORMS,
  },
  methods: {
    openModal() {
      this.$emit('tileClick', SALES_PLATFORMS.PLAYSTORE);
      this.$refs.playstoreModal.open();
    },
  },
};
</script>
