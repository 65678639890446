import {
  ONBOARDING_ACTIONS,
  ONBOARDING_GETTERS,
  ONBOARDING_CONFIG,
  ONBOARDING_2_STATE_MODULE,
  ONBOARDING_2_ROUTES,
} from './onboarding';
import {
  PAYMENTS_ACTIONS,
  PAYMENTS_GETTERS,
  PAYMENTS_CONFIG,
  PAYMENTS_STATE_MODULE,
  PAYMENTS_ROUTE_NAMES,
} from './payments';

export const ACTIONS = {
  ...ONBOARDING_ACTIONS,
  ...PAYMENTS_ACTIONS,
};

export const GETTERS = {
  ...ONBOARDING_GETTERS,
  ...PAYMENTS_GETTERS,
};

export const CONFIGS = {
  ...ONBOARDING_CONFIG,
  ...PAYMENTS_CONFIG,
};

export const ROUTES = {
  [ONBOARDING_2_STATE_MODULE]: ONBOARDING_2_ROUTES,
  [PAYMENTS_STATE_MODULE]: PAYMENTS_ROUTE_NAMES,
};
