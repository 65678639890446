<template>
  <box data-cy="available-funds-box-lvl-1" elevated>
    <div :class="$style['title-header']">{{ $t('spendPage.totalFunds') }}</div>
    <div :class="$style['total-funding']" data-cy="available-funds">
      {{ $filters.currency(sumOfAvailableCreditCents, getAdvanceCurrencyCode) }}
    </div>
    <!--LOOP THROUGH VISIBLE ADVANCES-->
    <template v-for="a in visibleAdvancesWithBalance" :key="a.subtype">
      <div>
        <hr :class="$style.divider" />
        <div
          :data-cy="`advance-row-${a.subtype}`"
          :class="$style['advance-row']"
        >
          <div>
            <div :class="$style['advance-header']">
              {{
                $t('spendPage.advanceWithType', {
                  advanceType: capitalize(advanceTypeText(a.subtype)),
                })
              }}
            </div>
            <div
              :class="$style['advance-amount']"
              :data-cy="`total-funding-${a.subtype}`"
            >
              {{ $filters.currency(a.balance, getAdvanceCurrencyCode) }}
            </div>
          </div>
          <UiButton
            :data-cy="`use-funds-btn-${a.subtype}`"
            :text="$t('spendPage.useFunds')"
            :disabled="!a.balance"
            @click="ctaClicked(a.subtype)"
          />
        </div>
      </div>
    </template>
  </box>
</template>

<script>
import { mapGetters } from 'vuex';
import { ADVANCE_SUBTYPES } from '@/data/advance-subtypes';
import { UiButton, Box } from '@clearbanc/design-components/src/components';

export default {
  components: {
    UiButton,
    Box,
  },
  props: {
    visibleAdvancesWithBalance: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ADVANCE_SUBTYPES,
    };
  },
  computed: {
    ...mapGetters(['getAdvanceCurrencyCode']),
    sumOfAvailableCreditCents() {
      return this.visibleAdvancesWithBalance.reduce(
        (prev, a) => prev + a.balance,
        0,
      );
    },
  },
  methods: {
    advanceTypeText(advanceType) {
      if (advanceType === this.ADVANCE_SUBTYPES.UNIFIED)
        return this.$t('data.allAdvanceTypes.marketing');
      return this.$t(`data.allAdvanceTypes.${advanceType}`);
    },
    ctaClicked(val) {
      this.$emit('cta-clicked', val);
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="less" module>
.title-header {
  font-family: @gerstner-font;
  font-size: 16px;
  color: @color-grey-400;
  font-weight: bold;
  margin-bottom: 4px;
  line-height: 22px;
}

.total-funding {
  font-family: @gerstner-font;
  font-size: 36px;
  color: @black;
  font-weight: normal;
  margin-bottom: @gutter-24;
  line-height: 48px;
}

.advance-row {
  font-family: @gerstner-font;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.advance-amount {
  font-size: 22px;
  color: @black;
  font-weight: normal;
  line-height: 28px;
}

.advance-header {
  font-family: @gerstner-font;
  font-size: 14px;
  color: @color-grey-400;
  font-weight: bold;
  line-height: 20px;
}

.divider {
  background-color: @color-grey-200;
  border: none;
  color: @color-grey-200;
  height: 1px;
  margin: @spacing-24 -@spacing-24;
}
</style>
