import _ from 'lodash';

import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  state: {
    cards: {},
    cardStats: {},
  },
  getters: {
    cards: (state) => _.values(state.cards),
    // used on the clearcom side
    activeClearbancCard: (state, getters) => {
      if (
        !state.cardStats &&
        !state.cardStats.cardAdvances.totalCreditLimitCents
      ) {
        return null;
      }
      let activeCards = _.filter(getters.cards, (c) => c.isActive);
      activeCards = _.sortBy(activeCards, 'createdAt');
      return _.get(activeCards, '[0]');
    },
    // used on the clearadmin side
    issuedClearbancCard: (state, getters) => {
      let activeCards = _.filter(getters.cards, (c) => !c.hiddenAt);
      activeCards = _.sortBy(activeCards, 'createdAt');
      return _.get(activeCards, '[0]');
    },
    // Unified (Marketing) advances
    totalCreditLimitCents(state) {
      return state.cardStats?.cardAdvances?.totalCreditLimitCents || 0;
    },
    totalCreditSpentCents(state) {
      return state.cardStats?.cardAdvances?.totalCreditSpentCents || 0;
    },
    totalCreditRemainingCents(state, getters) {
      return getters.totalCreditLimitCents - getters.totalCreditSpentCents;
    },
    // Ventures advances
    totalVenturesCreditLimitCents(state) {
      return state.cardStats?.venturesAdvances?.totalCreditLimitCents || 0;
    },
    totalVenturesCreditSpentCents(state) {
      return state.cardStats?.venturesAdvances?.totalCreditSpentCents || 0;
    },
    totalVenturesCreditRemainingCents(state, getters) {
      return (
        getters.totalVenturesCreditLimitCents -
        getters.totalVenturesCreditSpentCents
      );
    },
    // Inventory advances
    totalInventoryCreditLimitCents(state) {
      return state.cardStats?.inventoryAdvances?.totalCreditLimitCents || 0;
    },
    totalInventoryCreditSpentCents(state) {
      return state.cardStats?.inventoryAdvances?.totalCreditSpentCents || 0;
    },
    totalInventoryCreditRemainingCents(state, getters) {
      return (
        getters.totalInventoryCreditLimitCents -
        getters.totalInventoryCreditSpentCents
      );
    },
  },
  ...buildApiActions({
    FETCH_USER_CARDS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/businesses/${ctx.rootGetters.businessId}/cards`,
        params: payload,
      }),
      mutation: (state, { response }) => {
        state.cards = _.keyBy(response.cards, 'id');
        state.cardStats = _.omit(response, 'cards');
      },
    },
    // this reveals card number IF you pass along user password
    GET_CARD_FULL_DETAILS: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/cards/${payload.cardId}/get-full-details`,
        params: _.omit(payload, 'cardId'),
        options: { timeout: 100000 },
      }),
      mutation: (state, { response }) => {
        state.cards[response.id] = response;
      },
    },
  }),
};
