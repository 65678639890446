<template>
  <div :class="$style.message">
    <div :class="$style.message__image">
      <slot name="image"></slot>
    </div>
    <h1 :class="$style.message__title">
      <slot name="title"></slot>
    </h1>
    <div :class="$style.message__description">
      <slot name="description"></slot>
    </div>
  </div>
</template>

<style lang="less" module scoped>
.message {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.message__image {
  width: 150px;
  height: 150px;
  margin-bottom: 40px;
}

.message__image img {
  width: 100%;
  height: 100%;
}

.message__title {
  all: unset;
  margin-bottom: 16px;
  color: var(--neutral-black, #000);
  font-family: @gerstner-font;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.message__description {
  color: var(--neutral-black, #000);
  text-align: center;
  font-family: @gerstner-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
</style>
