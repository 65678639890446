import _ from 'lodash';
import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  state: {
    manualReadOnlyAccounts: {},
  },
  getters: {
    manualReadOnlyAccounts: (state) =>
      Object.values(state.manualReadOnlyAccounts),
    manualReadOnlyAccountsConnected: (state, getters) =>
      getters.manualReadOnlyAccounts.length > 0,
  },
  ...buildApiActions({
    FETCH_MANUAL_READ_ONLY_ACCOUNTS: {
      action: (ctx, payload) => ({
        method: 'get',
        url: `/businesses/${ctx.rootGetters.businessId}/manual-read-only-accounts`,
      }),
      mutation: (state, { response }) => {
        state.manualReadOnlyAccounts = _.keyBy(response, 'id');
      },
    },
    ADD_MANUAL_READ_ONLY_ACCOUNT: {
      action: (ctx, payload) => ({
        method: 'post',
        url: `/businesses/${ctx.rootGetters.businessId}/manual-read-only-accounts`,
        params: {
          ..._.omit(payload, 'id'),
          options: { timeout: 30000 },
        },
      }),
      mutation: (state, { response }) => {
        state.manualReadOnlyAccounts[response.id] = response;
      },
    },
  }),
};
