<template>
  <div :class="$style.modal">
    <h2 :class="$style.modal__title">{{ title }}</h2>
    <svg v-if="image" :class="$style.modal__icon" v-html="image"></svg>
    <div :class="$style.modal__details">
      <slot></slot>
    </div>
    <div v-if="buttonPrimary || buttonSecondary" :class="$style.modal__nav">
      <DSButton
        v-if="buttonPrimary"
        :class="$style.modal__nav__button"
        :label="buttonPrimary.label"
        @click="$emit('primary-click')"
      />
      <DSButton
        v-if="buttonSecondary"
        :class="$style.modal__nav__button"
        class="p-button-text p-button-help"
        :label="buttonSecondary.label"
        @click="$emit('secondary-click')"
      />
    </div>
  </div>
</template>

<script>
import DSButton from '@clearbanc/clear-components/button';

export default {
  components: {
    DSButton,
  },
  props: {
    title: { type: String },
    image: { type: String },
    buttonPrimary: { type: Object },
    buttonSecondary: { type: Object },
  },
};
</script>

<style>
body .p-dialog .p-dialog-header {
  padding-bottom: 0;
}
</style>

<style lang="less" module>
.modal {
  width: 100%;
  max-width: 680px;
}

body h2.modal__title {
  font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  margin: 0;
}

.modal__icon {
  width: 100%;
  max-width: 130px;
  padding: 10px 0;
  margin: 0 auto;
  display: block;
}

body .modal__details p {
  font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.modal__details p a {
  color: #215cb6;
  text-decoration: none;
}

.modal__details + .modal__nav {
  margin-top: 48px;
}

.modal__nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__nav__button + .modal__nav__button {
  margin-top: 24px;
}
</style>
