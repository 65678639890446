<template>
  <Message
    v-if="
      isDocumentRequestsEnabled &&
      hasActiveDocumentRequests &&
      numCompletedSteps === totalNumberOfSteps
    "
    severity="warn"
    :closable="false"
  >
    <p v-if="hasBankStatementRequest">
      To receive accurate pricing, please ensure you have
      <router-link
        :to="{
          name: PAYMENTS_ROUTE_NAMES.ALL_DOCUMENTS,
        }"
        >uploaded</router-link
      >
      the most recent 6 months worth of bank statements for your connected bank
      account.
    </p>
    <p v-else>
      We require additional documents, please
      <router-link
        :to="{
          name: PAYMENTS_ROUTE_NAMES.ALL_DOCUMENTS,
        }"
        >upload required document(s)</router-link
      >
      to avoid funding delays.
    </p>
  </Message>
</template>
<script>
import { mapGetters } from 'vuex';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import Message from '@clearbanc/clear-components/message';

export default {
  data() {
    return {
      PAYMENTS_ROUTE_NAMES,
    };
  },
  components: {
    Message,
  },
  computed: {
    ...mapGetters([
      'isDocumentRequestsEnabled',
      'hasActiveDocumentRequests',
      'activeDocumentRequests',
      'businessProfileSteps',
    ]),
    numCompletedSteps() {
      return Object.values(this.businessProfileSteps).filter(
        (step) => step.isComplete,
      ).length;
    },
    totalNumberOfSteps() {
      return Object.keys(this.businessProfileSteps).length;
    },
    hasBankStatementRequest() {
      return this.activeDocumentRequests.some(
        (request) => request.type === 'bank_statements',
      );
    },
  },
};
</script>
