<template>
  <Modal
    id="modal-legacy-feature"
    v-model:visible="visible"
    data-cy="modal-legacy-feature"
    :modal="true"
    :draggable="false"
    append-to="self"
    :close-on-escape="false"
    :class="$style.modal"
    :closable="false"
    :dismissable-mask="false"
  >
    <template #header>
      <h2>{{ title }}</h2>
    </template>
    <div :class="$style['modal-content']">
      <div :class="$style['subtitle-container']">
        <slot name="subtitle">
          <p>{{ subtitle }}</p>
        </slot>
      </div>
    </div>
    <div :class="$style['btn-container']">
      <DSButton
        class="p-button-outlined p-button-secondary w-full"
        label="Cancel"
        @click="handleCancelClick"
      />
      <DSButton class="w-full" label="Continue" @click="handleContinueClick" />
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@clearbanc/clear-components/dialog';
import DSButton from '@clearbanc/clear-components/button';
import analytics from '@/utils/analytics';

export default {
  components: {
    Modal,
    DSButton,
  },
  props: {
    visible: Boolean,
    title: String,
    subtitle: String,
  },
  computed: {
    ...mapGetters(['userId', 'business']),
  },
  methods: {
    handleContinueClick() {
      analytics.track(`legacy_modal_continue`, {
        route: this.$route.name,
        userId: this.userId,
        businessId: this.business.id,
      });
      this.$store.dispatch('setShowLegacyModal', false);
    },
    handleCancelClick() {
      analytics.track(`legacy_modal_cancel`, {
        userId: this.userId,
        businessId: this.business.id,
        route: this.$route.name,
      });
      this.$router.push('/');
    },
  },
};
</script>

<style lang="less" module>
.modal {
  padding: 0;
  h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    font-family: Gerstner-Programm;
  }
  svg {
    height: 100px;
    width: 100%;
  }
}
.btn-container {
  text-align: center;
  display: flex;
  gap: 16px;
}
.modal-content {
  max-width: 700px;
  text-align: center;
  margin: 10px;
}
.title-container {
  margin: 0;
  h1 {
    margin: 0;
  }
}
.subtitle-container {
  margin-bottom: 40px;
}
</style>
