<template>
  <div></div>
</template>

<script>
import { plaidOAuthMixin } from '@/utils/vue-mixins';

export default {
  mixins: [plaidOAuthMixin],
  /**
   * The user needs to be redirected to the last page that they were on before they went to the Plaid OAuth page.
   * Also, the returned redirect URI from Plaid needs to be saved to be used in Plaid Link afterwards
   */
  async beforeMount() {
    const pageToRedirectTo = this.setUpPlaidOAuthRedirectFlow(
      window.location.href,
    );
    this.$router.push({ name: pageToRedirectTo });
  },
};
</script>
