<template>
  <div>
    <div :class="$style['hero']">
      <h1>{{ $t('account.pages.subPageRepayments.paymentSchedule') }}</h1>
      <div v-if="!this.isSummaryLoading">
        <DSButton
          v-if="disableInvoiceUpload"
          :label="waitlistCtaLabel"
          @click="$emit('disable-invoice-upload-modal')"
        />
        <DSButton
          v-else-if="!showSummary && userCanSubmitInvoices"
          :disabled="
            userCanSubmitInvoices &&
            isDocumentRequestsEnabled &&
            hasActiveDocumentRequests
          "
          :label="payAnInvoiceModalButtonText"
          @click="$emit('pay-an-invoice')"
        />
        <DSButton
          v-else-if="!isBusinessProfileComplete"
          label="Finish your application"
          @click="handleFinishApplicationButtonClick"
        />
        <TransactionsSummary v-else :summary="transactionsSummary" />
      </div>
    </div>
    <section :class="$style.section">
      <RepaymentsNav />
      <RepaymentsTable
        :repayments="filteredRepayments.transactions || []"
        :repayments-type="repaymentsType"
        :filters="filteredRepayments.filters || {}"
        :is-loading="isLoading"
        :selected-filters="selectedFilters"
        @on-selected-filters="updateSelectedFilters"
      />
    </section>
    <Toast position="bottom-right" group="payments-toast" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Toast from '@clearbanc/clear-components/toast';
import RepaymentsNav from '@/components/payments/RepaymentsNav';
import RepaymentsTable from '@/components/payments/VendorRepaymentsTable';
import DSButton from '@clearbanc/clear-components/button';
import TransactionsSummary from '@/components/payments/TransactionsSummary';
import { Subtype } from '@clearbanc/data-common-types';
import analytics from '@/utils/analytics';

export default {
  components: {
    DSButton,
    RepaymentsNav,
    RepaymentsTable,
    Toast,
    TransactionsSummary,
  },
  props: {
    repaymentsType: {
      validator(value) {
        return ['history', 'upcoming'].includes(value);
      },
    },
  },
  data() {
    const repaymentsType = this.repaymentsType;
    return {
      selectedFilters: {
        invoiceIds: [],
        monthYears: [],
        vendors: [],
        ...(repaymentsType === 'upcoming' ? { dueDate: 0 } : { statuses: [] }),
      },
      isLoading: true,
      isSummaryLoading: true,
    };
  },
  async mounted() {
    await this.$store.dispatchApiAction('GET_TRANSACTIONS_SUMMARY', {
      businessId: this.businessId,
    });

    await this.$store.dispatchApiAction('GET_TRANSACTIONS_WITH_FILTERS', {
      businessId: this.businessId,
      filterBy: this.selectedFilters,
      repaymentsType: this.repaymentsType,
      subtype: Subtype.BILLS,
    });
    this.isLoading = false;
    this.isSummaryLoading = false;
  },
  computed: {
    ...mapGetters([
      'businessId',
      'filteredRepayments',
      'transactionsSummary',
      'isBnplWaitlisted',
      'userCanSubmitInvoices',
      'disableInvoiceUpload',
      'isBusinessProfileComplete',
      'business',
      'isDocumentRequestsEnabled',
      'hasActiveDocumentRequests',
    ]),
    showSummary() {
      return Number(this.transactionsSummary.outstandingBalance) > 0;
    },
    payAnInvoiceModalButtonText() {
      return this.isBnplWaitlisted
        ? this.$t('account.pages.pageVendors.joinTheWaitlist')
        : this.$t('account.pages.pageVendors.paymentsButtonLabel', {
            receipt: this.$t('account.pages.pageVendors.orReceipt'),
          });
    },
    waitlistCtaLabel() {
      return this.$t('account.pages.pageVendors.joinTheWaitlist');
    },
  },
  methods: {
    async updateSelectedFilters(evt) {
      this.selectedFilters = evt;
      this.isLoading = true;

      await this.$store.dispatch('RESET_FILTERED_REPAYMENTS');
      await this.$store.dispatchApiAction('GET_TRANSACTIONS_WITH_FILTERS', {
        businessId: this.businessId,
        filterBy: this.selectedFilters,
        subtype: Subtype.BILLS,
        repaymentsType: this.repaymentsType,
      });
      this.isLoading = false;
    },
    handleFinishApplicationButtonClick() {
      analytics.track('finish_application_cta_clicked', {
        businessId: this.businessId,
        businessName: this.business.name,
      });
      this.$router.push({
        name: 'payments-profile',
      });
    },
  },
  watch: {
    repaymentsType: {
      async handler() {
        // reset filters and reload the transactions

        const repaymentsType = this.repaymentsType;

        await this.updateSelectedFilters({
          invoiceIds: [],
          monthYears: [],
          vendors: [],
          ...(repaymentsType === 'upcoming'
            ? { dueDate: 0 }
            : { statuses: [] }),
        });
      },
    },
  },
};
</script>

<style lang="less" module>
.hero {
  margin: 24px 0;

  h1 {
    margin-bottom: 22px;
    margin-top: 22px;
  }
}

.section {
  font-family: 'Montserrat';
  padding: 15px 30px;
  margin-bottom: 25px;
  background: @color-white;
  border: 1px solid @color-lightgray-300;
  box-sizing: border-box;
  border-radius: 4px;
  flex: 1 0 0;
}
</style>
