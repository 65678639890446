export default {
  state: {
    isGoogleLibraryLoaded: false,
    isGoogleAuthFailed: false,
  },
  getters: {
    isGoogleLibraryLoaded: (state) => state.isGoogleLibraryLoaded,
    isGoogleAuthFailed: (state) => state.isGoogleAuthFailed,
  },
  mutations: {
    SET_IS_GOOGLE_LIBRARY_LOADED: (state, value) => {
      state.isGoogleLibraryLoaded = value;
    },
    SET_IS_GOOGLE_AUTH_FAILED: (state, value) => {
      state.isGoogleAuthFailed = value;
    },
  },
};
