<template>
  <div>
    <h3 :class="$style.title">{{ title }}</h3>
    <p>
      {{ subTitle }} <a :href="ctaUrl">{{ ctaText }}</a>
    </p>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';

const components = {
  'main-layout': require('@/layout/layout-main').default,
};

export default {
  components,
  setup() {
    useMeta({ title: 'We Are Undergoing Scheduled Maintenance' });
  },
  data() {
    return {
      title: 'Redirecting...',
      subTitle:
        "You are being redirected to Clearco's maintenance page. If you're not redirected automatically, please ",
      ctaUrl: 'https://clear.co/maintenance',
      ctaText: 'click here.',
    };
  },
  mounted() {
    window.location.replace('https://clear.co/maintenance');
  },
};
</script>

<style lang="less" scoped module>
.title {
  font-family: @gerstner-font;
  margin: 50px auto 0;
  padding: 25px 25px 0;
  text-align: center;
}
p {
  text-align: center;
  padding: 0 25px;
  max-width: 600px;
  width: 100%;
  margin: auto;
}
</style>
