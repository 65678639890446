<template>
  <Timeline
    :value="timelineData"
    class="container"
    data-cy="repayment-timeline"
  >
    <template #content="slotProps">
      <div class="timeline-label-container">
        <span class="heading">{{ timelineHeading(slotProps.index) }}</span>
        <br />
        <strong
          >{{
            `${$filters.currency(slotProps.item.amount)} ${DEFAULT_CURRENCY}`
          }}
        </strong>
        scheduled on
        {{ formattedDate(slotProps.item.date) }}
      </div>
    </template>
    <template #connector>
      <div class="event-connector" />
      <div class="connector-text-container">
        Payments of
        {{
          `${$filters.currency(weeklyPaymentAmountCents)} ${DEFAULT_CURRENCY}`
        }}
        every 7 days
      </div>
    </template>
  </Timeline>
</template>

<script>
import * as dateFns from 'date-fns';
import Timeline from '@clearbanc/clear-components/timeline';
import { DEFAULT_CURRENCY } from '@/data/payments';

export default {
  components: {
    Timeline,
  },
  props: {
    weeklyPaymentAmountCents: { type: Number, required: true },
    firstPaymentDate: { type: Date, required: true },
    firstPaymentAmountCents: { types: Number, required: true },
    lastPaymentDate: { type: Date, required: true },
    lastPaymentAmountCents: { types: Number, required: true },
  },
  data() {
    return {
      timelineData: [
        {
          amount: this.firstPaymentAmountCents,
          date: this.firstPaymentDate,
        },
        {
          amount: this.lastPaymentAmountCents,
          date: this.lastPaymentDate,
        },
      ],
      DEFAULT_CURRENCY,
    };
  },
  methods: {
    timelineHeading(index) {
      return `${index === 0 ? 'First' : 'Last'} payment`;
    },
    formattedDate(date) {
      const dateUtc = new Date(date)
        .toISOString()
        .replace('T', ' ')
        .substring(0, 19);
      return dateFns.format(dateFns.parseISO(dateUtc), 'MMM dd, yyyy');
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.p-timeline-event-opposite) {
  display: none;
}

.timeline-label-container {
  margin-top: -4px;
  font-size: 14px;

  .heading {
    line-height: 22px;
    color: #767676;
    font-size: 14px;
  }
}

.event-connector {
  border: 1px dashed #767676;
  background-color: transparent;
  height: 100px;
}

.connector-text-container {
  position: absolute;
  top: 56%;
  left: 32px;
  color: #767676;
  font-size: 14px;
}
</style>
