// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".no-scroll-bar_wda_x{scrollbar-width:none}.advance-amount_ZePJz{color:#111;font-size:36px;font-weight:400;line-height:48px;margin-bottom:16px}.advance-amount_ZePJz,.advance-header_pJdXl{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif}.advance-header_pJdXl{font-size:16px;line-height:22px;color:#5f5f61;font-weight:700;margin-bottom:4px}.accordion-content_p12p6{padding-left:16px}.faq-para_kgdOD{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:14px;line-height:20px;color:#111}.faq-para_kgdOD>span{font-weight:700;display:block}.faq-footer_xJ2EA{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:14px;line-height:20px;color:#5f5f61;margin-bottom:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-scroll-bar": "no-scroll-bar_wda_x",
	"advance-amount": "advance-amount_ZePJz",
	"advance-header": "advance-header_pJdXl",
	"accordion-content": "accordion-content_p12p6",
	"faq-para": "faq-para_kgdOD",
	"faq-footer": "faq-footer_xJ2EA"
};
export default ___CSS_LOADER_EXPORT___;
