// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_Zd_Un{margin:40px auto 55px}@media only screen and (max-width:500px){.container_Zd_Un{margin-bottom:30px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_Zd_Un"
};
export default ___CSS_LOADER_EXPORT___;
