<template>
  <div :class="$style.container">
    <custom-table
      :data="transactions"
      :empty-msg="$t('spendPage.noTransactionsAvailable')"
      :loading="fetchTransactionsRequest.isPending"
      bordered
      gerstner
    >
      <template #row="slotProps">
        <custom-column
          :row="slotProps?.row"
          show="createdAt"
          :label="() => $t('spendPage.date')"
          type="date"
          gerstner
        />
        <custom-column
          :row="slotProps?.row"
          :label="() => $t('spendPage.advanceType')"
          gerstner
          capitalize
        >
          {{ advanceType(slotProps?.row) }}
        </custom-column>
        <custom-column
          :row="slotProps?.row"
          :label="() => $t('spendPage.advanceId')"
          gerstner
        >
          #{{
            slotProps?.row?.repaysAdvanceId || slotProps?.row?.fundsAdvanceId
          }}
        </custom-column>
        <custom-column
          :row="slotProps?.row"
          :label="() => $t('spendPage.type')"
          gerstner
        >
          {{ typeDisplayText(slotProps?.row?.type) }}
        </custom-column>
        <custom-column
          :row="slotProps?.row"
          :label="() => $t('spendPage.description')"
          gerstner
        >
          <a
            v-if="slotProps?.row?.childInvoiceId"
            href="#"
            @click.prevent="showDetailsPopup(slotProps?.row?.id)"
            >{{ slotProps?.row?.description }}</a
          >
          <span v-else>{{ slotProps?.row.description }}</span>
        </custom-column>
        <custom-column
          :row="slotProps?.row"
          :label="() => $t('spendPage.status')"
          uppercase
          gerstner
          :class="rowToStatusClass(slotProps?.row)"
        >
          {{ statusDisplayText(slotProps?.row.status) }}
        </custom-column>
        <custom-column
          v-if="userHasHadActiveMarketingAdvance"
          :row="slotProps?.row"
          show="cashbackAmountCents"
          :label="() => $t('spendPage.discountCredit')"
          type="money"
          gerstner
          :class="rowToCashBackClass(slotProps?.row)"
        />
        <custom-column
          :row="slotProps?.row"
          show="amountCents"
          :label="() => $t('spendPage.amount')"
          type="money"
          gerstner
        />
      </template>
    </custom-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { ADVANCE_SUBTYPES, ADVANCE_SUPRATYPES } from '@/data/advance-subtypes';
import CustomTable from './CustomTablePayments';
import CustomColumn from './CustomColumnPayments';

export default {
  components: {
    CustomTable,
    CustomColumn,
  },
  props: {
    transactions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['activatedAdvancesBySubtype', 'supratypeById']),
    ...mapRequestStatuses({
      fetchTransactionsRequest: 'FETCH_TRANSACTIONS',
    }),
    userHasHadActiveMarketingAdvance() {
      return !!this.activatedAdvancesBySubtype?.unified?.length;
    },
  },
  methods: {
    rowToCashBackClass(row) {
      if (!row?.cashbackAmountCents) {
        return '';
      }

      if (row.cashbackAmountCents === '0') {
        return this.$style.pending;
      }

      return this.$style.green;
    },
    rowToStatusClass(row) {
      if (!row?.status) {
        return '';
      }
      return this.$style[row.status.replace('-', '_')];
    },
    statusDisplayText(status) {
      return this.$t(`common.transactionStatuses.${status}`) || status;
    },
    typeDisplayText(type) {
      if (type) {
        return this.$t(`common.tranactionTypes.${type}`);
      }
      return type;
    },
    advanceType(row) {
      const advanceId = row?.fundsAdvanceId || row?.repaysAdvanceId;
      const advanceType = this.supratypeById[advanceId];

      switch (advanceType) {
        case ADVANCE_SUPRATYPES.marketing:
          return this.$t('data.allAdvanceTypes.marketing');
        case ADVANCE_SUBTYPES.UNIFIED:
          return this.$t('data.allAdvanceTypes.marketing');
        case ADVANCE_SUBTYPES.MARKETING_FACEBOOK:
          return this.$t('data.allAdvanceTypes.marketing');
        case ADVANCE_SUBTYPES.VENTURES:
          return this.$t('data.allAdvanceTypes.ventures');
        case ADVANCE_SUBTYPES.INVENTORY:
          return this.$t('data.allAdvanceTypes.inventory');
        case ADVANCE_SUBTYPES.BNPL:
          return this.$t('data.allAdvanceTypes.bnpl');
        default:
          return this.$t('data.allAdvanceTypes.unknown');
      }
    },
  },
};
</script>

<style lang="less" module>
.container {
  border: 1px solid @color-grey-200;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  background-color: @color-white;
}

.green {
  color: @color-success-400;
}
.pending {
  color: @medium-gray;
}
.in_transit {
  color: @medium-gray;
}
.failed {
  color: @color-danger-500;
}
.success {
  color: @color-success-400;
}
.cancelled {
  color: @color-danger-500;
}
.settled {
  color: @color-success-400;
}
.scheduled {
  color: @medium-gray;
}
</style>
