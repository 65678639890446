<template>
  <a
    :class="$style['link']"
    :tabindex="0"
    :href="url"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
    >{{ text }}</a
  >
</template>

<script>
export default {
  emits: ['click'],
  props: {
    url: { type: String, default: '/' },
    text: { type: String, required: true },
  },
};
</script>

<style lang="less" module>
.link {
  text-decoration: underline;
  cursor: pointer;
  color: @color-black;
}
</style>
