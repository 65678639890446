<template>
  <span v-if="marker === 'complete'" class="custom-marker-complete">
    <CheckCircleIcon />
  </span>
  <span v-if="marker === 'caution'" class="custom-marker-caution">
    <ExclamationTriangleIcon />
  </span>
  <span v-if="marker === 'fail'" class="custom-marker-fail">
    <ExclamationCircleIcon />
  </span>
  <span v-if="marker === 'processing'" class="custom-marker-processing">
    <ArrowPathIcon class="pi" />
  </span>
  <span v-if="marker === 'default'" class="custom-marker-not-started">
    <i class="pi pi-circle" />
  </span>
</template>

<script>
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  ArrowPathIcon,
} from '@heroicons/vue/24/solid';

export default {
  components: {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    ExclamationCircleIcon,
    ArrowPathIcon,
  },
  props: {
    marker: {
      type: String,
      default: 'default',
      validator: (val) => {
        return [
          'default',
          'complete',
          'caution',
          'fail',
          'processing',
        ].includes(val);
      },
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.p-timeline-event-marker) {
  width: 1.5rem;
  height: 1.5rem;
}

.custom-marker-complete {
  width: 24px;

  svg {
    fill: #23aa71;
  }
}

.custom-marker-caution {
  width: 24px;

  svg {
    fill: #e8a524;
  }
}

.custom-marker-fail {
  width: 24px;
  svg {
    fill: #bd3e3e;
  }
}

.custom-marker-processing {
  width: 24px;
  svg {
    fill: #767676;
  }
}

.custom-marker-not-started {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  i {
    color: #767676;
    font-weight: 600;
    font-size: 19px;
  }
}
</style>
