import { ACTIONS, CONFIGS, ROUTES } from '@/data/state-modules';

export default {
  computed: {
    defaultConfig() {
      const stateModule = this.$store.getters.currentStateModule;
      return CONFIGS[stateModule];
    },
    stateModuleRoutes() {
      const stateModule = this.$store.getters.currentStateModule;

      return ROUTES[stateModule];
    },
  },
  methods: {
    dispatchToStateModule(type, ...args) {
      const stateModule = this.$store.getters.currentStateModule;

      return this.$store.dispatch(ACTIONS[stateModule][type], ...args);
    },
  },
};
