<template>
  <div>
    <div :class="$style['banner-wrapper']">
      <LegacyBanner :name="firstName" feature="Capital Advances" />
      <MessageDocumentRequests />
      <MessageTaxes />
      <Message
        v-if="
          pureValidAdvanceUser &&
          Object.keys(waitListBannerContent).length &&
          showNewProductBanner
        "
        severity="info"
        :closable="true"
        @close="closeNewProductBanner"
      >
        <h3>
          {{ $t(waitListBannerContent.title, { firstName }) }}
        </h3>
        <p v-if="waitListBannerContent.paragraph1">
          {{ $t(waitListBannerContent.paragraph1, { firstName }) }}
        </p>
        <p v-if="waitListBannerContent.paragraph2">
          {{ $t(waitListBannerContent.paragraph2) }}
        </p>
        <p v-if="waitListBannerContent.linkName">
          <router-link :to="{ name: waitListBannerContent.linkName }">{{
            $t(waitListBannerContent.linkText)
          }}</router-link>
        </p>
        <p v-else-if="waitListBannerContent.cta">
          <a @click="joinWaitlist">{{ $t(waitListBannerContent.cta) }}</a>
        </p>
      </Message>
    </div>
    <router-view />
    <ModalPayAnInvoice ref="payAnInvoicePopup" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { waitlistBannerText, CLEARPAY_SEGMENT_EVENTS } from '@/data/payments';
import analytics from '@/utils/analytics';
import Message from '@clearbanc/clear-components/message';
import ModalPayAnInvoice from '@/components/payments/ModalPayAnInvoice';
import { ACCOUNT_MANAGER_DATA_BY_BUSINESS_ID } from '@/data/account-manager-data';
import MessageTaxes from '@/components/MessageTaxes';
import MessageDocumentRequests from '@/components/MessageDocumentRequests';
import LegacyBanner from '@/components/banners/LegacyBanner';
import { subMonths } from 'date-fns';

export default {
  components: {
    Message,
    ModalPayAnInvoice,
    MessageTaxes,
    MessageDocumentRequests,
    LegacyBanner,
  },
  data() {
    return {
      ACCOUNT_MANAGER_DATA_BY_BUSINESS_ID,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'business',
      'pureValidAdvanceUser',
      'userCategory',
      'businessCorpCountry',
      'businessId',
      'isOutfundPartnershipCountry',
      'activeValidAdvanceOrAvailableValidSpend',
      'businessCorpCountryName',
      'userHasForceBnplFlag',
      'bills',
      'showNewProductBanner',
      'MessageDocumentRequests',
      'primaryOrIntendedPrimaryBankAccount',
    ]),
    waitListBannerContent() {
      return waitlistBannerText(
        this.userCategory,
        this.businessCorpCountry,
        this.userHasForceBnplFlag,
        this.bills.length > 0,
      );
    },
    firstName() {
      if (!this.user.firstName) return 'Hello';
      return (
        this.user.firstName.charAt(0).toUpperCase() +
        this.user.firstName.slice(1)
      );
    },
    hasAccountManager() {
      return !!ACCOUNT_MANAGER_DATA_BY_BUSINESS_ID?.[this.businessId];
    },
    hasCalendlyLink() {
      return !!this.accountManagerCalendlyLink;
    },
    accountManagerCalendlyLink() {
      return ACCOUNT_MANAGER_DATA_BY_BUSINESS_ID?.[this.businessId]
        ?.am_calendly_link;
    },
    accountManagerEmail() {
      return ACCOUNT_MANAGER_DATA_BY_BUSINESS_ID?.[this.businessId]?.am_email;
    },
    accountManagerFullName() {
      return ACCOUNT_MANAGER_DATA_BY_BUSINESS_ID?.[this.businessId]
        ?.am_full_name;
    },
  },
  async beforeMount() {
    const statementStartDate = subMonths(new Date(), 6).toISOString();

    await this.$store.dispatchApiAction('GET_BANK_STATEMENTS_CHECK', {
      statementStartDate,
      bankAccountNumber:
        this.primaryOrIntendedPrimaryBankAccount?.accountNumber,
    });
  },
  async created() {
    if (this.business.id)
      this.$store.dispatchApiAction('FETCH_USER_CARDS', { canceled: false });
    this.$store.dispatchApiAction('FETCH_USER_CONTRACTS');

    await this.$store.dispatchApiAction('GET_DOCUMENT_REQUESTS');
  },
  methods: {
    joinWaitlist() {
      this.$refs.payAnInvoicePopup.open(this.businessId);
      analytics.track(CLEARPAY_SEGMENT_EVENTS.CLEARPAY_BNPL_WAITLISTED, {
        email: this.user.email,
        name: `${this.user.firstName} ${this.user.lastName}`,
        businessId: this.businessId,
        businessName: this.business.name,
      });
    },
    closeNewProductBanner() {
      localStorage.setItem('hide-phoenix-intro-banner', 'true');
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.p-message-text p) {
  font-weight: 400;
  text-align: left;
  margin: 0;
}
</style>
<style lang="less" module>
.banner-wrapper {
  max-width: 1440px;
  margin: auto;
  padding: 0 40px;

  @media only screen and (min-width: 513px) and (max-width: 1024px) {
    padding: 0 @spacing-40;
  }

  // mobile
  @media only screen and (max-width: 512px) {
    padding: 0 @spacing-16;
  }
}
</style>
