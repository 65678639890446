// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_t_mch{min-height:100vh;min-width:100%;background-color:#fff;position:absolute;top:0;left:0}.container_t_mch .body_xSFI8{padding:40px;height:100%;display:flex;justify-content:center;text-align:center}.container_t_mch .widget__lRxA{width:340px}.container_t_mch .widget__lRxA .logo_O6wYB{display:block;padding:30px 60px}.container_t_mch .widget__lRxA .submit-btn_wj1AO{text-transform:uppercase;letter-spacing:1px;font-size:14px!important;width:100%}.container_t_mch .widget__lRxA .content_qSnJZ{padding:0 1rem}.container_t_mch .widget__lRxA .content_qSnJZ [class^=p-error]{font-size:13px!important;text-align:left;font-weight:300}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_t_mch",
	"body": "body_xSFI8",
	"widget": "widget__lRxA",
	"logo": "logo_O6wYB",
	"submit-btn": "submit-btn_wj1AO",
	"content": "content_qSnJZ"
};
export default ___CSS_LOADER_EXPORT___;
