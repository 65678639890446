import analytics from '@/utils/analytics';

export function segmentErrorTracking(displayedMessage, page, props) {
  if (!displayedMessage) return;

  analytics.track('fe_self_serve_error', {
    displayedMessage,
    page,
    ...props,
  });
}
