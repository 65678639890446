<script setup>
import DSButton from '@clearbanc/clear-components/button';

// -----
// Setup
// -----

const props = defineProps({
  buttons: {
    type: Array,
    required: true,
  },
});
</script>

<template>
  <div :class="$style.footer">
    <DSButton
      v-for="(button, index) in props.buttons"
      :key="`footer-button-${index}`"
      :label="button.text"
      :class="[...button.classes, $style.footer__button]"
      v-bind="button.attributes"
      @click="button.action"
    />
  </div>
</template>

<style module scoped>
.footer {
  padding: 0;
  display: flex;
  justify-content: stretch;
  gap: 16px;
}

body button.footer__button {
  margin: 0;
  flex-grow: 1;
  justify-content: center;
  width: 50%;
}
</style>
