<template>
  <div :class="[$style.steps, $style['steps--capacity-guarantee']]">
    <div
      v-for="(step, index) in steps"
      :key="`step-${index}`"
      :class="$style.steps__item"
    >
      <span
        :class="$style.steps__item__icon"
        class="material-symbols-outlined"
        >{{ step.icon }}</span
      >
      <p :class="$style.steps__item__description">{{ step.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    steps: { type: Array, required: true },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" module>
.steps {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  counter-reset: section;
}

.steps__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  padding: 28px 20px 28px 70px;
  position: relative;
  border-radius: 4px;
  transform-style: preserve-3d;
  border-top: 1px dashed #f6f6f6;
}

.steps__item:first-child {
  border-top: 0;
}

.steps__item:before {
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translateX(50%) translateY(50%) rotate(45deg) translateZ(-1px);
  content: '';
  background: #ededed;
  width: 20px;
  height: 20px;
  display: none;
}

.steps__item:after {
  position: absolute;
  top: -1px;
  left: 0;
  background: #7f30ff;
  content: counter(section);
  width: 46px;
  height: 46px;
  counter-increment: section;
  border-radius: 4px 0 50% 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #fff;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.steps__item__icon {
  margin-bottom: 28px;
  display: none;
  width: 48px;
  height: 48px;
  overflow: hidden;
}

.steps__item__description {
  margin: 0;
  color: #111;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

@media (min-width: 900px) {
  .steps {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 40px;
  }

  .steps--capacity-guarantee {
    grid-template-columns: repeat(3, 1fr);
  }

  .steps__item {
    padding: 28px 20px 56px;
    align-items: center;
    border: 0;
  }

  .steps__item:before {
    display: block;
    top: 45px;
    bottom: auto;
    right: 0;
    transform: translateX(50%) rotate(45deg) translateZ(-1px);
  }

  .steps__item:last-child:before {
    display: none;
  }

  .steps--capacity-guarantee .steps__item:nth-child(3n):before {
    display: none;
  }

  .steps__item:after {
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-radius: 50%;
  }

  .steps__item__description {
    max-width: 250px;
  }

  .steps__item__icon {
    display: block;
  }
}

@media (min-width: 1740px) {
  .steps--capacity-guarantee {
    grid-template-columns: repeat(6, 1fr);
    row-gap: 20px;
  }
}
</style>
