<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    hideOnScroll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lastScrollPosition: 0,
      hidden: false,
    };
  },
  computed: {
    classes() {
      return {
        [this.$style.container]: true,
        [this.$style.hidden]: this.hideOnScroll && this.hidden,
      };
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // only start hiding if user scrolled past 1 window height
      if (window.scrollY < window.innerHeight) {
        this.hidden = false;
        this.lastScrollPosition = window.scrollY;
        return;
      }
      // user is scrolling down if the change in scrollY is negative
      const isScrollingDown = this.lastScrollPosition - window.scrollY < 0;
      this.hidden = isScrollingDown;
      this.lastScrollPosition = window.scrollY;
    },
  },
};
</script>

<style lang="less" module>
.container {
  background: @color-white;
  border-top: 1px solid @color-grey-200;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 2;
  height: 70px;
  transition: 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.hidden {
  transform: translateY(100px);
}
</style>
