// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accordion-button_J8Xq1{margin-bottom:2px}.accordion-button_J8Xq1.active_P1Zaf{transform:scaleY(-1);margin-bottom:unset}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion-button": "accordion-button_J8Xq1",
	"active": "active_P1Zaf"
};
export default ___CSS_LOADER_EXPORT___;
