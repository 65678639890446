<template>
  <button
    :class="$style['menu-button']"
    tabindex="0"
    aria-label="Toggle menu"
    @click="toggleMenu"
    @keyup.enter="toggleMenu"
  >
    <div :class="$style.line" />
    <div :class="$style.line" />
    <div :class="$style.line" />
    <div v-if="hasNotif" :class="notifClasses" />
  </button>
</template>

<script>
import { COLOR_THEME_VALUES } from '@/data/color-themes';

export default {
  props: {
    hasNotif: {
      type: Boolean,
      default: false,
    },
    notifTheme: {
      type: String,
      default: 'info',
      validator: (value) => COLOR_THEME_VALUES.includes(value),
    },
  },
  computed: {
    notifClasses() {
      return {
        [this.$style.notif]: true,
        [this.$style[this.notifTheme]]: true,
      };
    },
  },
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu');
    },
  },
};
</script>

<style lang="less" module>
.menu-button {
  border: none;
  cursor: pointer;
  position: relative;
  .line {
    height: 2px;
    width: 18px;
    background: @color-black;

    &:nth-child(2) {
      margin: 4px 0;
    }
  }

  .notif {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: -2px;
    right: 0;
    outline: 2px @color-grey-50 solid;

    &.neutral {
      background-color: @color-grey-300;
    }
    &.success {
      background-color: @color-success-300;
    }
    &.danger {
      background-color: @color-danger-300;
    }
    &.info {
      background-color: @color-info-300;
    }
    &.warning {
      background-color: @color-warning-300;
    }
  }
}
</style>
