<template>
  <onboarding-modal
    ref="cashbackDetailsModal"
    data-cy="popup-cashback-transactions-modal"
    :title="$t('advancesPage.discountCreditTransactions')"
    align="left"
    :class="$style['cashback-details']"
    large
  >
    <custom-table
      :data="transactions"
      :loading="fetchTransactionsRequest.isPending"
      class="dashboard-table-component"
      bordered
      gerstner
    >
      <template #row="slotProps">
        <custom-column
          :row="slotProps?.row"
          show="createdAt"
          :label="() => $t('advancesPage.date')"
          type="date"
          gerstner
        />
        <custom-column
          :row="slotProps?.row"
          :label="() => $t('advancesPage.description')"
          gerstner
        >
          {{ formatDescription(slotProps?.row.description) }}
        </custom-column>
        <custom-column
          :row="slotProps?.row"
          show="amountCents"
          :label="() => $t('advancesPage.amount')"
          type="money"
          gerstner
        />
        <custom-column
          :row="slotProps?.row"
          show="cashbackAmountCents"
          :label="() => $t('advancesPage.discountCredit')"
          type="money"
          gerstner
        />
        <custom-column
          :row="slotProps?.row"
          :label="() => $t('advancesPage.discountPercent')"
          gerstner
        >
          {{
            $filters.percent(
              slotProps?.row.cashbackAmountCents / slotProps?.row.amountCents,
            )
          }}
        </custom-column>
      </template>
    </custom-table>
  </onboarding-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import CustomTable from '@/components/CustomTablePayments';
import CustomColumn from '@/components/CustomColumnPayments';
import OnboardingModal from '@/components/modals/ModalOnboarding2';

export default {
  components: {
    OnboardingModal,
    CustomTable,
    CustomColumn,
  },
  props: {
    advanceId: Number,
  },
  computed: {
    ...mapGetters(['transactions', 'businessId']),
    ...mapRequestStatuses({
      fetchTransactionsRequest: 'FETCH_TRANSACTIONS',
    }),
  },
  methods: {
    open(advanceId) {
      this.$store.dispatchApiAction('FETCH_TRANSACTIONS', {
        cashbackRepaysAdvanceId: advanceId,
        businessId: this.businessId,
      });
      this.$refs.cashbackDetailsModal.open();
    },
    formatDescription(description) {
      return description && description.replace('CB card - ', '');
    },
  },
};
</script>

<style lang="less" module>
.cashback-details {
  div[class^='title__'] {
    font-family: @gerstner-font !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    margin-top: 32px !important;
  }
  div[class^='content__'] {
    padding: 30px !important;
  }
}
</style>
