<template>
  <Subtitle>
    {{ subtitleText }}
    <button
      v-if="!!tooltipText"
      v-tooltip.focus="tooltipText"
      type="button"
      class="pi pi-info-circle"
      :class="$style.tooltip"
      @focus="$emit('open')"
      @blur="$emit('close')"
    />
  </Subtitle>
</template>

<script>
import Subtitle from '@/components/Subtitle';

export default {
  components: {
    Subtitle,
  },
  props: {
    subtitleText: { type: String, required: true },
    tooltipText: { type: String },
  },
};
</script>

<style lang="less" module>
.tooltip {
  color: @color-black;
  padding: 4px;
  cursor: help;
}
</style>
