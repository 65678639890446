// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_T4o0M{text-align:center}.error_HWC9q{margin-top:20px}.cta_RqE4L{max-width:500px;margin:30px auto auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_T4o0M",
	"error": "error_HWC9q",
	"cta": "cta_RqE4L"
};
export default ___CSS_LOADER_EXPORT___;
