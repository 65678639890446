import analytics from '@/utils/analytics';

const SEGMENT_EVENT_SEPARATORS = {
  NAME: '_',
  PROPERTY: '-',
};

export const sendSegmentTrackEvent = (
  routeName,
  eventNameSuffix,
  additionalData,
) => {
  let eventName = `fe_${routeName.replace(
    /-/g,
    SEGMENT_EVENT_SEPARATORS.NAME,
  )}`;
  if (eventNameSuffix) {
    eventName += SEGMENT_EVENT_SEPARATORS.NAME + eventNameSuffix;
  }
  const eventData = {
    ...additionalData,
  };
  analytics.track(eventName, eventData);
};

export const formatStepLabelForSegment = (label, isEventName) => {
  const eventSeparator = isEventName
    ? SEGMENT_EVENT_SEPARATORS.NAME
    : SEGMENT_EVENT_SEPARATORS.PROPERTY;
  let stepLabel = label.toLowerCase().replace(/ /g, eventSeparator);
  if (stepLabel.includes('&')) {
    stepLabel = stepLabel.substring(0, stepLabel.indexOf('&') - 1);
  }
  return stepLabel;
};
