<template>
  <onboardingModal
    ref="googleadsModal"
    :title="$t('components.connectGoogleAdsSS.connectGoogleAds')"
    large
    @open="$emit('modalAction', MARKETING_PLATFORMS.AMAZONADS, 'open')"
    @close="$emit('modalAction', MARKETING_PLATFORMS.AMAZONADS, 'close')"
  >
    <LayoutModalAds>
      <ol>
        <li class="m-b-10">
          <i18n-t keypath="components.connectGoogleAdsSS.loginInstruction">
            <template #googleAdsAccount>
              <a target="_blank" href="https://ads.google.com/">
                {{ $t('components.connectGoogleAdsSS.googleAdsAccount') }}
              </a>
            </template>
          </i18n-t>
        </li>
        <li class="m-b-10">
          {{ $t('components.connectGoogleAdsSS.clickHelpInstruction') }}
        </li>
        <li class="m-b-10">
          <i18n-t
            keypath="components.connectGoogleAdsSS.findCustomerIDInstruction"
          >
            <template #customerID>
              <span>
                {{ $t('components.connectGoogleAdsSS.customerID') }}
              </span>
            </template>
          </i18n-t>
        </li>
        <li class="m-b-10">
          <i18n-t
            keypath="components.connectGoogleAdsSS.enterCustomerIDInstruction"
          >
            <template #customerID>
              <span>
                {{ $t('components.connectGoogleAdsSS.customerID') }}
              </span>
            </template>
          </i18n-t>
          <div>
            <div class="m-b-20">123-456-7890</div>
          </div>
          <inputWithEyebrow
            v-model="googleadsAuth.customerId"
            name="googleads-customer-id"
            type="text"
            autofill="off"
            :placeholder="
              $t('components.connectGoogleAdsSS.googleAdsCustomerID')
            "
            :error-msg="$t('common.errors.mandatory')"
            required
            class="m-b-20"
            :validate-on-blur="validateOnBlur"
          />
          <div class="m-b-10">
            <onboarding2Cta
              :text="$t('components.connectGoogleAdsSS.connect')"
              class="m-b-20"
              theme="modals"
              @click="handleSubmit"
            />
          </div>
        </li>
      </ol>
    </LayoutModalAds>
  </onboardingModal>
</template>
<script>
import { MARKETING_PLATFORMS } from '@/data/platforms';
import { clickTrackingMixin } from '@/utils/vue-mixins';
import { validateChildren } from '@/composables/validation';
import InputWithEyebrow from '@/components/inputs/InputWithEyebrow';
import OnboardingModal from '@/components/modals/ModalOnboarding2';
import Onboarding2Cta from '@/components/buttons/ButtonOnboarding2';
import LayoutModalAds from '@/components/layouts/LayoutModalAds';

export default {
  components: {
    InputWithEyebrow,
    OnboardingModal,
    Onboarding2Cta,
    LayoutModalAds,
  },
  mixins: [clickTrackingMixin],
  computed: {
    MARKETING_PLATFORMS: () => MARKETING_PLATFORMS,
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      validateOnBlur: false,
      googleadsAuth: {},
    };
  },
  methods: {
    open() {
      this.trackClick('googleads');
      this.$refs.googleadsModal.open();
    },
    async handleSubmit() {
      this.validateOnBlur = true;

      const error = await this.hasError();
      if (error) {
        this.$emit('error');
        return;
      }

      this.$refs.googleadsModal?.close();
      this.$emit('submit', this.googleadsAuth.customerId);
    },
  },
};
</script>
