<template>
  <ui-quick-link
    :id="getRef(link.title())"
    :data-cy="getRef(link.title())"
    :icon="link.icon"
    :text="link.title()"
    :mobile="mobile"
    :selected="selected"
    @click="redirect()"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { UiQuickLink } from '@clearbanc/design-components/src/components';
import analytics from '@/utils/analytics';

export default {
  components: {
    UiQuickLink,
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['productSegmentLabel']),
    selected() {
      if (this.link?.matchForChildren) {
        return this.$route.name.startsWith(this.link?.route);
      }
      return this.$route.name === this.link.route;
    },
  },
  methods: {
    redirect() {
      analytics.track(this.link.segmentEventName, {
        productSegment: this.productSegmentLabel,
        targetArea: this.$route.name,
      });
      this.$router.push({ name: this.link.route }).catch(() => {});
    },
    getRef(name) {
      return name.toLowerCase().split(' ').join('-').concat('-link');
    },
  },
};
</script>
