<template>
  <div :class="$style.phone">
    <div :class="$style.countryCode">
      <DropdownGroup
        append-to="self"
        label="country-code"
        v-bind="dropdownAttrs"
        sr-only
      >
        <template #value="slotProps">
          <div :class="$style.callingCodesValue">
            <div v-if="!slotProps.value">{{ slotProps.placeholder }}</div>
            <div v-if="slotProps.value">
              {{ slotProps?.value.flag }}
            </div>
            <div v-if="slotProps.value" :class="$style.callingCodesValue__code">
              +{{ slotProps?.value.callingCode }}
            </div>
          </div>
        </template>
        <template #option="slotProps">
          <div :class="$style.callingCodesOptions">
            <div :class="$style.callingCodesOptions__item">
              {{ slotProps.option.flag }}
            </div>
            <div :class="$style.callingCodesOptions__item">
              {{ slotProps.option.nameString }}
            </div>
            <div :class="$style.callingCodesOptions__item">
              +{{ slotProps.option.callingCode }}
            </div>
          </div>
        </template>
      </DropdownGroup>
    </div>
    <InputTextGroup
      data-cy="businessPhone"
      :class="$style.phoneNumber"
      v-bind="inputAttrs"
    />
  </div>
</template>

<script>
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';

export default {
  components: { InputTextGroup, DropdownGroup },
  props: {
    dropdownAttrs: {
      type: Object,
      default: () => {},
    },
    inputAttrs: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="less" module>
.phone {
  position: relative;

  div[class='p-dropdown-trigger'] {
    display: none;
  }
}

.countryCode {
  position: absolute;
  top: 24px;
  left: 0;
  width: 110px;
}

input.phoneNumber {
  padding-left: 130px;
}

input.phoneNumber[type='number']::-webkit-outer-spin-button,
input.phoneNumber[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.phoneNumber[type='number'] {
  -moz-appearance: textfield;
}

.callingCodesValue {
  display: flex;
  align-items: center;
}

.callingCodesValue__code {
  margin: 0 auto;
}

.callingCodesOptions {
  display: flex;
  align-items: center;
}

.callingCodesOptions__item + .callingCodesOptions__item {
  margin-left: 10px;
}
</style>
