<template>
  <div>
    <div :class="$style.warningMessage">
      {{ $t('vendorsPage.vendorSummary.summaryParagraph') }}
    </div>
    <div :class="$style.infoBoxContainer">
      <!-- Vendor Info -->
      <div :class="$style.vendorInfoBox" data-cy="vendor-info">
        <div :class="$style.infoBoxTitle">
          {{ $t('vendorsPage.vendorInfoComponent.info') }}
        </div>
        <div v-if="selectedVendor.nameOnAccount">
          {{ selectedVendor.nameOnAccount }}
        </div>
        <div v-if="selectedVendor.vendorCategory">
          {{ $t('common.category') }}:
          {{ selectedVendorCategoryName }}
        </div>
      </div>
      <!-- Vendor Contact -->
      <div
        :class="[$style.vendorInfoBox, $style.middleBox]"
        data-cy="vendor-contact"
      >
        <div :class="$style.infoBoxTitle">
          {{ $t('vendorsPage.vendorSummary.vendorContact') }}
        </div>
        <div v-if="selectedVendor.details.vendorName">
          {{ selectedVendor.details.vendorName }}
        </div>
        <div v-if="selectedVendor.details.vendorEmail">
          {{ selectedVendor.details.vendorEmail }}
        </div>
        <div v-if="selectedVendor.details.vendorPhone">
          {{ selectedVendor.details.vendorPhone }}
        </div>
        <div v-if="selectedVendor.beneficiaryAddress.line1">
          {{ selectedVendor.beneficiaryAddress.line1 }}
        </div>
        <div v-if="selectedVendor.beneficiaryAddress.line2">
          {{ selectedVendor.beneficiaryAddress.line2 }}
        </div>
        <div
          v-if="
            selectedVendor.beneficiaryAddress.city &&
            selectedVendor.beneficiaryAddress.state &&
            selectedVendor.beneficiaryAddress.postalCode
          "
        >
          {{ selectedVendor.beneficiaryAddress.city }},
          {{ selectedVendor.beneficiaryAddress.state }}.
          {{ selectedVendor.beneficiaryAddress.postalCode }}
        </div>
        <div v-if="selectedVendor.beneficiaryAddress.country">
          {{ getCountryName(selectedVendor.beneficiaryAddress.country) }}
        </div>
      </div>
      <!-- Vendor Banking info -->
      <div :class="$style.vendorInfoBox" data-cy="vendor-banking">
        <div :class="$style.infoBoxTitle">
          {{ $t('vendorsPage.vendorSummary.vendorBankAccount') }}
        </div>
        <div v-if="selectedVendor.bankName">{{ selectedVendor.bankName }}</div>
        <div v-if="selectedVendor.bankAddress.line1">
          {{ selectedVendor.bankAddress.line1 }}
        </div>
        <div v-if="selectedVendor.bankAddress.line2">
          {{ selectedVendor.bankAddress.line2 }}
        </div>
        <div
          v-if="
            selectedVendor.bankAddress.city &&
            selectedVendor.bankAddress.state &&
            selectedVendor.bankAddress.postalCode
          "
        >
          {{ selectedVendor.bankAddress.city }},
          {{ selectedVendor.bankAddress.state }}.
          {{ selectedVendor.bankAddress.postalCode }}
        </div>
        <div v-if="selectedVendor.nameOnAccount">
          {{ selectedVendor.nameOnAccount }}
        </div>
        <div v-if="selectedVendor.bankAddress.country">
          {{ $t('common.bankInfo.bankCountry') }}:
          {{ selectedVendor.bankAddress.country }}
        </div>
        <div v-if="selectedVendor.accountNumber">
          {{ $t('common.bankInfo.account') }}:
          {{ selectedVendor.accountNumber }}
        </div>
        <div v-if="selectedVendor.routingNumber">
          {{ $t('common.bankInfo.ach') }}:
          {{ selectedVendor.routingNumber }}
        </div>
        <div v-if="selectedVendor.wireRoutingNumber">
          {{ $t('common.bankInfo.wireNumber') }}:
          {{ selectedVendor.wireRoutingNumber }}
        </div>
        <div v-if="selectedVendor.swiftCode">
          {{ $t('common.bankInfo.swiftCode') }}:
          {{ selectedVendor.swiftCode }}
        </div>
        <div v-if="selectedVendor.iban">
          {{ $t('common.bankInfo.iban') }}:
          {{ selectedVendor.iban }}
        </div>
        <div v-if="selectedVendor.sortCode">
          {{ $t('common.bankInfo.sortCode') }}:
          {{ selectedVendor.sortCode }}
        </div>
        <div v-if="selectedVendor.bsbCode">
          {{ $t('common.bankInfo.bsbCode') }}:
          {{ selectedVendor.bsbCode }}
        </div>
        <div v-if="selectedVendor.institutionNumber">
          {{ $t('common.bankInfo.institution') }}:
          {{ selectedVendor.institutionNumber }}
        </div>
        <div v-if="selectedVendor.bankCode">
          {{ $t('common.bankInfo.bankCode') }}:
          {{ selectedVendor.bankCode }}
        </div>
        <div v-if="selectedVendor.branchCode">
          {{ $t('common.bankInfo.branchCode') }}:
          {{ selectedVendor.branchCode }}
        </div>
        <div v-if="selectedVendor.clabe">
          {{ $t('common.bankInfo.clabe') }}:
          {{ selectedVendor.clabe }}
        </div>
        <div v-if="selectedVendor.cnaps">
          {{ $t('common.bankInfo.cnaps') }}:
          {{ selectedVendor.cnaps }}
        </div>
        <div v-if="selectedVendor.ifsc">
          {{ $t('common.bankInfo.ifsc') }}:
          {{ selectedVendor.ifsc }}
        </div>
      </div>
    </div>
    <ui-msg
      v-if="request.isError"
      type="error"
      :visible="request.isError"
      :class="$style.errorMsg"
      @close="$store.commit('CLEAR_REQUEST_STATUS', requestName)"
      >{{
        request.error.errorCode
          ? $t(`backendError.${request.error.errorCode}`, request.error.details)
          : request.errorMessage
      }}</ui-msg
    >
    <ui-button :text="$t('common.buttons.back')" @click="$emit('prev-tab')" />
    <ui-button
      :text="buttonText"
      data-cy="continue-button"
      :disabled="request.isPending"
      @click="submitVendor"
    />
    <ui-fieldset></ui-fieldset>
  </div>
</template>

<script>
import { UiMsg, UiFieldset, UiButton } from 'ui-components';
import { validateChildren } from '@/composables/validation';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { COUNTRY_CODE_WITH_NAMES } from '@/data/country-code-with-names';
import { VENDOR_CATEGORIES } from '@/data/vendor-categories';

export default {
  components: {
    UiButton,
    UiMsg,
    UiFieldset,
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  computed: {
    ...mapGetters(['selectedVendor']),
    ...mapRequestStatuses({
      createVendorRequestStatus: 'CREATE_VENDOR_ACCOUNT',
      updateVendorRequestStatus: 'UPDATE_VENDOR_ACCOUNT',
    }),
    selectedVendorCategoryName() {
      return (
        this.vendorCategories.find(({ value }) => {
          return value === this.selectedVendor.vendorCategory;
        }) || {}
      ).name;
    },
    vendorCategories() {
      return Object.entries(VENDOR_CATEGORIES).map((v, i) => {
        return {
          key: i,
          value: v[0],
          name: v[1],
        };
      });
    },
    request() {
      return this.selectedVendor.id
        ? this.updateVendorRequestStatus
        : this.createVendorRequestStatus;
    },
    requestName() {
      return this.selectedVendor.id
        ? 'UPDATE_VENDOR_ACCOUNT'
        : 'CREATE_VENDOR_ACCOUNT';
    },
    buttonText() {
      if (this.request.isPending) return this.$t('common.buttons.loading');
      return this.selectedVendor.id
        ? this.$t('common.buttons.update')
        : this.$t('common.buttons.continue');
    },
  },
  methods: {
    getCountryName(countryCode) {
      return COUNTRY_CODE_WITH_NAMES[countryCode]();
    },
    async submitVendor() {
      const requestName = this.requestName;
      await this.$store.dispatchApiAction(
        this.requestName,
        this.selectedVendor,
      );
      if (this.$store.getters.requestStatus(requestName).isSuccess) {
        this.$emit('next-tab');
      }
    },
  },
};
</script>

<style lang="less" module>
.vendorInfoBox {
  vertical-align: top;
  flex-grow: 1;
}
.infoBoxContainer {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 200px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}
.middleBox {
  border-left: solid;
  border-right: solid;
  border-left-width: 2px;
  border-right-width: 2px;
  border-left-color: lightgray;
  border-right-color: lightgray;
  @media screen and (max-width: 600px) {
    border-left: none;
    border-right: none;
    border-top: solid;
    border-bottom: solid;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-top-color: lightgray;
    border-bottom-color: lightgray;
  }
}
.infoBoxTitle {
  color: black;
  padding-bottom: 8px;
  text-align: center;
  font-weight: bold;
}
.warningMessage {
  border: solid;
  border-color: orange;
  border-radius: 5px;
  border-width: 2px;
  padding: 10px;
  margin-bottom: 20px;
}
.errorMsg {
  color: red;
}
</style>
