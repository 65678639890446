<template>
  <div
    :class="$style.container"
    data-cy="bnpl-payment-schedule-confirmation-modal"
  >
    <p :class="$style.description">
      {{
        $t(
          'account.pages.pageVendors.bnplPaymentScheduleConfirmation.description',
        )
      }}
    </p>
    <div :class="$style['repayment-details-container']">
      <div :class="$style['row-div']">
        <div :class="[$style['row-div-column-merged'], 'm-b-25']">
          <h3>
            {{
              $t(
                'account.pages.pageVendors.bnplPaymentScheduleConfirmation.termCondition',
                {
                  terms: selectedRepaymentSchedule.totalNumPayments,
                  amount: moneyDisplayValue(
                    selectedRepaymentSchedule.paymentPerTermCents,
                  ),
                },
              )
            }}<sup>*</sup>
          </h3>
          <p :class="$style.footnote">
            *
            {{
              $t(
                'account.pages.pageVendors.bnplPaymentScheduleConfirmation.autoDebitCondition',
              )
            }}
            <button
              v-tooltip.focus="{
                value: `<strong>${$t(
                  'account.pages.pageVendors.bnplPaymentScheduleConfirmation.takeMoreTime',
                )}</strong><br />${$t(
                  'account.pages.pageVendors.bnplPaymentScheduleConfirmation.revenueDrop',
                )}`,
                escape: true,
              }"
              type="button"
              class="pi pi-info-circle"
              :class="$style.tooltip"
            />
          </p>
        </div>
      </div>
      <div
        v-if="
          selectedRepaymentSchedule.firstPayment &&
          selectedRepaymentSchedule.lastPayment
        "
        :class="$style['repayment-schedule-container']"
      >
        <BnplRepaymentTimeline
          :weekly-payment-amount-cents="
            selectedRepaymentSchedule.paymentPerTermCents
          "
          :first-payment-date="selectedRepaymentSchedule.firstPayment.date"
          :first-payment-amount-cents="
            selectedRepaymentSchedule.firstPayment.amountCents
          "
          :last-payment-date="selectedRepaymentSchedule.lastPayment.date"
          :last-payment-amount-cents="
            selectedRepaymentSchedule.lastPayment.amountCents
          "
        />
      </div>
      <div :class="$style['divider']"></div>
      <div :class="$style['row-div']"></div>
      <div :class="divRowClasses">
        <div :class="$style['row-div-column']">
          <span>{{
            $t(
              'account.pages.pageVendors.bnplPaymentScheduleConfirmation.billAmount',
            )
          }}</span>
          <p>
            {{ formatMoney(amountCents, selectedCurrency) }}
            {{ selectedCurrency }}
          </p>
        </div>
        <div :class="$style['row-div-column']">
          <span>{{ $t('paymentsPage.uploadedBill') }}</span>
          <p>
            <a :href="uploadUrl" target="_blank">{{
              limitFileName(uploadName)
            }}</a>
          </p>
        </div>
      </div>
      <div v-if="hasConvertedAmount" :class="divRowClasses">
        <div :class="$style['row-div-column']">
          <span>{{
            $t(
              'account.pages.pageVendors.bnplPaymentScheduleConfirmation.estimatedConvertedAmount',
            )
          }}</span>
          <p>{{ moneyDisplayValue(convertedAmount) }}</p>
        </div>
        <div :class="$style['row-div-column']">
          <span>{{ $t('paymentsPage.conversionRate') }}</span>
          <p>{{ exchangeRate }}</p>
        </div>
      </div>
      <div :class="divRowClasses">
        <div :class="$style['row-div-column']">
          <span
            >{{ $t('paymentsPage.extensionPlanFee')
            }}<span :class="$style['terms-summary-fee-label']"
              >({{ $filters.percent(repaymentDetails.termRate) }})</span
            >
          </span>
          <p>
            {{ moneyDisplayValue(repaymentDetails.feeCents)
            }}<template v-if="hasConvertedAmount"></template>
          </p>
        </div>
      </div>
      <div v-if="isReceiptFlow" :class="divRowClasses">
        <div :class="$style['row-div-column']">
          <span>{{ $t('paymentsPage.receiptVendor') }} </span>
          <p>{{ receiptVendorName }}</p>
        </div>
      </div>
      <div v-if="isReceiptFlow" :class="divRowClasses" data-cy="bank-account">
        <div
          :class="[
            $style['row-div-column'],
            $style['row-div-column-merged'],
            'm-b-10',
          ]"
        >
          <span>
            {{
              $t(
                'account.pages.pageVendors.bnplPaymentScheduleConfirmation.sendFundsLabel',
              )
            }}
          </span>
          <p v-if="!primaryBankAccount" :class="$style['no-account']">
            {{
              $t(
                'account.pages.pageVendors.bnplPaymentScheduleConfirmation.sendFundsNoBank',
              )
            }}
          </p>
          <p v-else data-cy="bank-info">
            {{ primaryBankAccount.bankName }}<br />
            {{ capitalize(primaryBankAccount.subtype) }}
            *** *** ** {{ primaryBankAccount.accountNumberLast4 }}
          </p>
        </div>
      </div>

      <div :class="$style['divider']"></div>
      <div :class="[divRowClasses, 'm-t-25']">
        <div :class="$style['row-div-column']">
          <span>{{
            $t(
              'account.pages.pageVendors.bnplPaymentScheduleConfirmation.totalAmount',
            )
          }}</span>
          <p>
            <strong
              >{{ moneyDisplayValue(displayTotal)
              }}<template v-if="hasConvertedAmount"></template
            ></strong>
          </p>
        </div>
      </div>
    </div>
    <div v-if="termsAvailable" :class="$style['checkboxContainer']">
      <input
        v-model="agreedToTerms"
        type="checkbox"
        :required="true"
        :aria-label="
          $t(
            'account.pages.pageVendors.bnplPaymentScheduleConfirmation.ariaLabelAggreToTerms',
          )
        "
      />
      <!-- eslint-disable -->
      <span>
        {{
          $t(
            'account.pages.pageVendors.bnplPaymentScheduleConfirmation.reviewAggrement',
          )
        }}
      </span>
      <!-- eslint-enable -->
      <span v-if="validationError" :class="$style['error']">
        <br />{{ validationErrorMsg }}
      </span>
    </div>
    <div :class="$style['bnpl-payment-bottom-actions']">
      <ButtonLinkContainer
        text="Submit for review"
        :link-props="linkProps"
        show-link
        :loading="submittingBill"
        :disabled="!!submissionError"
        @nextClick="confirmAndApply"
        @linkClick="$emit('prev-tab')"
      />
    </div>
    <Message v-if="submissionError" severity="error" :closable="false">
      <p>
        Unable to submit bill, please contact support.
        {{ submissionError.message }}
      </p>
    </Message>
    <div :class="$style['exchange-rate-legal']">
      {{ footnote }}
    </div>
  </div>
</template>

<script>
import { formatMoney } from '@/utils/currency';
import { mapGetters } from 'vuex';
import analytics from '@/utils/analytics';
import { limitFileName, getShortBillId } from '@/utils/payments';
import {
  CLEARPAY_SEGMENT_EVENTS,
  PAY_OUT_CURRENCY_OPTIONS,
  DEFAULT_CURRENCY,
} from '@/data/payments';
import ButtonLinkContainer from '@/components/buttons/ButtonLinkContainer';
import BnplRepaymentTimeline from '@/components/payments/BnplRepaymentTimeline';
import Message from '@clearbanc/clear-components/message';

export default {
  components: {
    ButtonLinkContainer,
    BnplRepaymentTimeline,
    Message,
  },
  props: {
    amountCents: {
      type: Number,
      required: true,
    },
    paymentMethod: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    uploadUrl: {
      type: String,
    },
    uploadName: {
      type: String,
    },
    repaymentDetails: {
      type: Object,
    },
    selectedCurrency: {
      type: String,
      required: true,
    },
    isReceipt: {
      type: Boolean,
      default: false,
    },
    beneficiaryId: {
      type: String,
    },
    submittingBill: {
      type: Boolean,
      default: false,
    },
    submissionError: {
      type: Object,
    },
    memo: {
      type: String,
    },
    receiptVendorName: {
      type: String,
    },
  },
  data() {
    return {
      termsAvailable: false,
      agreedToTerms: false,
      validationError: false,
      validationErrorMsg: '',
      hasConvertedAmount: false,
      convertedAmount: null,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'bills',
      'hasActiveSalesAccounts',
      'marketingAccountsConnected',
      'bankingAccountsConnected',
      'isBusinessDetailsStepComplete',
      'isBusinessOwnersStepComplete',
      'repaymentSchedule',
      'primaryBankAccount',
      'isReceiptFlow',
    ]),
    divRowClasses() {
      return {
        [this.$style['row-div']]: true,
        [this.$style['padding-top-0']]: true,
      };
    },
    divColumnClasses() {
      return {
        [this.$style['flex-center']]: true,
        [this.$style['row-div-column']]: true,
      };
    },
    displayTotal() {
      const billAmount =
        this.selectedCurrency !== DEFAULT_CURRENCY
          ? this.convertedAmount
          : this.amountCents;
      return Number(billAmount) + Number(this.repaymentDetails.feeCents);
    },
    linkProps() {
      return {
        text: 'Go back',
      };
    },
    maxAmount() {
      if (
        !this.repaymentDetails.repaymentSchedule ||
        this.repaymentDetails.repaymentSchedule?.length === 0
      )
        return 0.0;
      return this.repaymentDetails.repaymentSchedule[
        this.repaymentDetails.repaymentSchedule.length - 1
      ].amountCents;
    },
    exchangeRate() {
      return `${
        PAY_OUT_CURRENCY_OPTIONS[this.selectedCurrency].symbol_native
      }1 ${this.selectedCurrency} = $${Number(
        this.repaymentDetails.exchangeRateData.conversionRate,
      ).toFixed(4)} ${DEFAULT_CURRENCY}`;
    },
    footnote() {
      return `
        ${this.$t('paymentsPage.extensionPlanFootnote')}
        ${
          this.hasConvertedAmount
            ? this.$t('paymentsPage.exchangeRateLegal')
            : ''
        }
      `;
    },
    selectedRepaymentSchedule() {
      return this.repaymentSchedule.repaymentSchedulesByMonth[
        this.repaymentDetails.selectedTerm.toString()
      ];
    },
  },
  async mounted() {
    this.hasConvertedAmount = !!(
      this.selectedCurrency !== DEFAULT_CURRENCY &&
      this.repaymentDetails.exchangeRateData
    );

    this.convertedAmount =
      this.repaymentDetails.exchangeRateData?.convertedAmountCents;
  },
  methods: {
    formatMoney,
    limitFileName,
    moneyDisplayValue(amount, prefix = '') {
      return `${prefix}${formatMoney(
        amount,
        DEFAULT_CURRENCY,
      )} ${DEFAULT_CURRENCY}`;
    },
    confirmAndApply() {
      if (this.beneficiaryId !== '+newvendor') {
        analytics.track(
          CLEARPAY_SEGMENT_EVENTS.FOUNDER_PROVIDED_PAYMENT_DETAILS,
          {
            origin: 'existing vendor',
            billId: this.beneficiaryId,
            shortBillId: getShortBillId(this.beneficiaryId),
          },
        );
      }
      const fundedBills = this.bills.filter(
        (bill) =>
          bill.status === 'completed' || bill.status === 'auto_debiting_active',
      );

      if (!this.termsAvailable || this.agreedToTerms) {
        analytics.identify(this.user.id, {
          numOfBnplBills: this.bills.length + 1,
          numOfBnplBillsFunded: fundedBills.length,
          timeOfLastBnplBillSubmitted: new Date().toISOString(),
          hasConnectedSalesAccounts: this.hasActiveSalesAccounts,
          hasMarketingAccountConnected: this.marketingAccountsConnected,
          hasConnectedBankAccounts: this.bankingAccountsConnected,
          hasFilledBusinessDetails: this.isBusinessDetailsStepComplete,
          hasFilledOwnerInformation: this.isBusinessOwnersStepComplete,
        });

        this.$emit('create-bill');
      } else {
        this.validationError = true;
        this.validationErrorMsg = this.$t(
          'account.pages.pageVendors.bnplPaymentScheduleConfirmation.validationErrorMsg',
        );
      }
    },
    capitalize(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      return '';
    },
  },
};
</script>

<style lang="less" module>
.container {
  padding: 0;
  font-family: @gerstner-font;
  font-weight: 300;

  .description {
    max-width: 600px;
    font-size: 16px;
    margin: 0 auto;
    color: #393939;
  }

  p.details {
    position: relative;
    font-size: 16px;
    max-width: 550px;
    text-align: center;
    @media only screen and (max-width: 500px) {
      margin-left: 0;
      text-align: left;
    }
  }
  .exchange-rate-legal {
    margin: 65px auto 0;
    color: @medium-light-black;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    max-width: 690px;
    text-align: center;
    @media only screen and (max-width: 500px) {
      margin-left: 0;
      text-align: left;
    }
  }
}

.divider::after {
  content: ' ';
  display: block;
  border-bottom: 0.5px solid @onboarding-light-gray;
}

.repayment-details-container {
  border: 1px solid @color-grey-200;
  max-width: 350px;
  border-radius: 4px;
  box-shadow: 0 2px 8px @black-box-shadow-bill-details;
  margin: 40px auto 0;
  padding: 30px 0;
  position: relative;
  font-family: @gerstner-font;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  text-align: left;

  .no-account {
    color: #000;
    margin-top: 4px;
  }

  .row-div {
    padding: 10px 24px;
    display: flex;
    justify-content: space-between;
    &-column {
      flex-flow: column;
      text-align: left;
      width: 172px;

      &-merged {
        width: auto;
        h3 {
          font-family: @gerstner-font;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          color: #000;
          margin-top: 0;
        }
        .footnote {
          font-size: 12px;
          font-weight: 300;
          line-height: 16px;
          margin: 0;
        }
        .tooltip {
          color: @color-black;
          padding: 4px;
          cursor: help;
        }
      }

      p {
        margin: 0;
        color: @medium-light-black;
        overflow-wrap: break-word;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        a {
          text-decoration-line: underline;
          color: #215cb6;
        }
      }

      .terms-summary-fee-label {
        min-width: 3em;
        padding-left: 2px;
      }
    }

    div:nth-child(2) {
      padding-top: 0;
    }

    .flex-center {
      display: flex;
      align-items: center;
      flex-flow: row !important;
      span {
        margin-right: 5px;
      }
    }
  }

  .repayment-schedule-container {
    text-align: left;
    padding: 0px 10px 0 20px;
    font-size: 12px;
    color: @dark-black;
    margin-bottom: 25px;
  }

  span {
    font-size: 12px;
    color: @progress-bar-pending;
  }
}

.checkboxContainer {
  align-content: left;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
  min-width: 600px;
  max-width: 610px;
  margin-bottom: 18px;
  @media only screen and (max-width: 845px) {
    min-width: 0;
    max-width: 350px;
    white-space: pre-wrap;
  }

  input[type='checkbox'] {
    display: inline-flex;
    white-space: nowrap;
    margin-right: 12px;
  }

  span {
    font-family: @gerstner-font;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #303032;
    padding: 0;
    margin: 0;

    &.error {
      color: #ff6258;
    }
  }
}

.padding-top-0 {
  padding-top: 0 !important;
}

.bnpl-payment-bottom-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  margin: 75px auto 0;
  a[class^='link'] {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #297fe3;
  }
  button[class^='button'] {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    width: 180px;
    background: #000;
    border-radius: 4px;
  }
  div[class^='button-text'] {
    min-width: 186px;
    @media only screen and (max-width: 800px) {
      white-space: no-wrap;
      min-width: 148px;
    }
  }
}
</style>
