export const ENVIRONMENTS = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
  E2E: 'e2e',
  TEST: 'test',
};

export const isStagingEnvironment = () => {
  return [ENVIRONMENTS.STAGING, ENVIRONMENTS.DEVELOPMENT].includes(
    process.env.CLEARBANC_ENV,
  );
};

export const isTestingEnvironment = () => {
  return process.env.NODE_ENV === ENVIRONMENTS.TEST;
};
