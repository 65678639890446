<template>
  <Panel
    v-if="scheduledTransactions.length > 0"
    :header="
      $t('account.pages.pageVendors.billDetails.estimatedPaymentSchedule')
    "
    class="payments-timeline"
  >
    <Timeline :value="timelineData">
      <template #marker="slotProps">
        <BillStatusMarker :marker="getMarkerType(slotProps.item.status)" />
      </template>
      <template #content="slotProps">
        <div class="payments-timeline__content">
          <span :class="getTitleClass(slotProps.item.status)">{{
            slotProps.item.title
          }}</span>
          <span
            :class="getSubtitleClass(slotProps.item.status)"
            v-html="slotProps.item.subtitle"
          ></span>
          <template
            v-if="slotProps.item.cta.label && slotProps.item.cta.destination"
          >
            <DSButton
              :class="[
                'payments-timeline__button',
                isExpanded && 'payments-timeline__content--margin',
              ]"
              @click="
                () => $router.push({ name: slotProps.item.cta.destination })
              "
            >
              {{ slotProps.item.cta.label }}
            </DSButton>
          </template>
        </div>
      </template>
      <template #connector="slotProps">
        <BillStatusConnector
          :connector="getConnectorType(slotProps.item.status)"
        />
      </template>
    </Timeline>
    <a class="payments-timeline__expand" @click="toggleExpanded">{{
      isExpanded ? '- View less schedule' : '+ View full schedule'
    }}</a>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseISO, isAfter } from 'date-fns';
import Panel from '@clearbanc/clear-components/panel';
import Timeline from '@clearbanc/clear-components/timeline';
import {
  PAYMENTS_ROUTE_NAMES,
  CATEGORIZED_TRANSACTION_ERROR_CODES,
  TransactionStatus,
} from '@/data/payments';
import { getFormattedDateString } from '@/utils/payments';
import { formatMoney } from '@/utils/currency';
import DSButton from '@clearbanc/clear-components/button';
import analytics from '@/utils/analytics';
import { getBrowserWidth } from '@/utils/browser';

import BillStatusMarker from './BillStatusMarker';
import BillStatusConnector from './BillStatusConnector';

export default {
  components: {
    Timeline,
    Panel,
    DSButton,
    BillStatusMarker,
    BillStatusConnector,
  },
  props: {
    scheduledTransactions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters(['selectedBill']),
    allTimelineData() {
      let paymentCount = 0;
      return this.scheduledTransactions.map((transaction) => {
        let num;
        if (transaction.type === 'payment') {
          paymentCount += 1;
          num = paymentCount;
        }
        const dataObj = {
          title: this.makePaymentTitle(
            num,
            transaction.status,
            transaction.type,
          ),
          subtitle: this.makePaymentSubtitle(transaction),
          cta: this.makePaymentCta(transaction),
          status: transaction.status,
          date: transaction.scheduledChargeDate,
        };

        return dataObj;
      });
    },
    lastCompletedTransactionIndex() {
      return this.getLastCompletedTransactionIndex(this.allTimelineData);
    },
    timelineData() {
      if (this.isExpanded) {
        return this.allTimelineData;
      }
      if (this.statusIsPending(this.allTimelineData[0].status)) {
        return this.allTimelineData.slice(0, 1);
      }
      let collapsedIndexToShow = this.lastCompletedTransactionIndex;
      if (
        this.statusIsSuccess(
          this.allTimelineData[this.lastCompletedTransactionIndex].status,
        )
      ) {
        collapsedIndexToShow++;
      }
      return this.allTimelineData.slice(
        collapsedIndexToShow,
        collapsedIndexToShow + 1,
      );
    },
  },
  methods: {
    toggleExpanded() {
      const oldVal = this.isExpanded;
      this.isExpanded = !oldVal;

      if (this.isExpanded) {
        analytics.track('if_user_view_submitted_bill_payment_schedule', {
          type: this.selectedBill.isReceipt ? 'Receipt' : 'Invoice',
          browserWidth: getBrowserWidth(),
        });
      }
    },
    statusIsSuccess(status) {
      return [TransactionStatus.SUCCESS, TransactionStatus.SETTLED].includes(
        status,
      );
    },
    statusIsPending(status) {
      return [
        TransactionStatus.PENDING,
        TransactionStatus.SUBMITTED,
        TransactionStatus.IN_TRANSIT,
      ].includes(status);
    },
    getErrorContent(statusCode) {
      let description =
        'It seems there was an issue submitting this payment. Please contact <a href="mailto:payments@clear.co">payments@clear.co</a> to resolve the issue';
      let ctaLabel;
      let ctaDestination;

      if (CATEGORIZED_TRANSACTION_ERROR_CODES.NSF.includes(statusCode)) {
        description =
          'Insufficient Funds, please connect another bank account or add funds to the account connected. Please contact <a href="mailto:payments@clear.co">payments@clear.co</a> to arrange another attempt of this payment';
        ctaLabel = 'Connect Bank Account';
        ctaDestination = PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING;
      }
      if (CATEGORIZED_TRANSACTION_ERROR_CODES.GATEWAY.includes(statusCode)) {
        description =
          'Your Direct Debit Authorization failed. Please re-sign the direct debit authorization and contact <a href="mailto:payments@clear.co">payments@clear.co</a> to resume payments';
        ctaLabel = 'Re-Authorize Direct Debit';
        ctaDestination = PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING;
      }
      if (
        CATEGORIZED_TRANSACTION_ERROR_CODES.ACCOUNT_NUMBER.includes(statusCode)
      ) {
        description =
          'Account number was incorrect. Please connect a new account and/or contact <a href="mailto:payments@clear.co">payments@clear.co</a> to provide the correct details and resume payments';
        ctaLabel = 'Connect Bank Account';
        ctaDestination = PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING;
      }
      if (
        CATEGORIZED_TRANSACTION_ERROR_CODES.DIRECT_DEBIT.includes(statusCode)
      ) {
        description =
          'Your Direct Debit Authorization was cancelled by your bank. Please re-sign the direct debit authorization and contact <a href="mailto:payments@clear.co">payments@clear.co</a> to resume payments';
        ctaLabel = 'Re-Authorize Direct Debit';
        ctaDestination = PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING;
      }

      return { description, ctaLabel, ctaDestination };
    },
    makePaymentTitle(num, status, type) {
      const typeTitle = type.charAt(0).toUpperCase() + type.slice(1);
      if (this.statusIsSuccess(status)) {
        return `${typeTitle} completed`;
      }
      if (status === TransactionStatus.FAILED) {
        return `${typeTitle} failed`;
      }
      if (type === 'payment') {
        const title = [`${typeTitle} ${num}`];
        if (num === 1) {
          title.push(`(first ${type})`);
        }
        return title.join(' ');
      }
      return `${typeTitle} ${status}`;
    },
    makePaymentSubtitle(transaction) {
      const amount = this.getMoneyDisplayValue(transaction);
      const date = getFormattedDateString(transaction.scheduledChargeDate);
      if (this.statusIsSuccess(transaction.status)) {
        return `${amount} on ${date}`;
      }
      if (transaction.status === TransactionStatus.FAILED) {
        const { description } = this.getErrorContent(transaction.statusCode);
        return `Payment of ${amount} on ${date} failed. <br/>${description}.`;
      }
      return `${amount} scheduled on <span class="subheading--bold">${date}</span>`;
    },
    makePaymentCta(transaction) {
      if (
        transaction.status !== TransactionStatus.FAILED ||
        !transaction.statusCode
      ) {
        return {};
      }
      const { ctaLabel, ctaDestination } = this.getErrorContent(
        transaction.statusCode,
      );
      return {
        destination: ctaDestination,
        label: ctaLabel,
      };
    },
    getTitleClass(status) {
      return {
        heading: true,
        'heading--success': this.statusIsSuccess(status),
        'heading--pending': this.statusIsPending(status),
        'heading--failed': status === TransactionStatus.FAILED,
      };
    },
    getSubtitleClass(status) {
      return {
        subheading: true,
        'subheading--success': this.statusIsSuccess(status),
        'subheading--failed': status === TransactionStatus.FAILED,
      };
    },
    getMoneyDisplayValue(transaction) {
      return `${formatMoney(
        transaction.amountCents,
        transaction.currencyCode,
      )} ${transaction.currencyCode}`;
    },
    getMarkerType(status) {
      if (this.statusIsSuccess(status)) {
        return 'complete';
      }
      if (status === TransactionStatus.FAILED) {
        return 'fail';
      }
      return 'default';
    },
    getConnectorType(status) {
      if (this.statusIsSuccess(status)) {
        return 'complete';
      }
      return 'default';
    },
    getLastCompletedTransactionIndex(transactions) {
      let lastComplete = 0;
      const today = new Date();
      for (let i = 0; i < transactions.length; i++) {
        const txnDate = parseISO(transactions[i].date);
        if (!isAfter(txnDate, today)) {
          lastComplete = i;
        } else {
          break;
        }
      }
      return lastComplete;
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.p-timeline-event:last-of-type) {
  margin-bottom: 17px;
}

.payments-timeline {
  text-align: left;

  .payments-timeline__expand {
    color: @color-info-400;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }

  .payments-timeline__content {
    font-size: 14px;

    .payments-timeline__button {
      margin: 8px 0 0;
    }

    .payments-timeline__content--margin {
      margin: 8px 0 17px;
    }

    .heading {
      display: inline-block;
      font-family: @gerstner-font;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      padding: 0 6px;
      margin-bottom: 5px;
    }

    .heading--pending {
      color: @lighter-black;
    }

    .heading--success {
      color: #000000;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }

    .heading--failed {
      color: #000000;
      background: #ffe6e4;
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .subheading {
      font-family: @gerstner-font;
      color: @lighter-black;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      padding: 0 3px;
      display: block;
    }

    :deep(.subheading--bold) {
      font-weight: 500;
    }

    .subheading--success {
      color: #000000;
    }

    .subheading--failed {
      color: @color-danger-400;
    }
  }
}
</style>
