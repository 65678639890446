// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_R3mOe[data-v-7ed64460]{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;margin:50px auto 0;padding:25px 25px 0}.title_R3mOe[data-v-7ed64460],p[data-v-7ed64460]{text-align:center}p[data-v-7ed64460]{padding:0 25px;max-width:600px;width:100%;margin:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_R3mOe"
};
export default ___CSS_LOADER_EXPORT___;
