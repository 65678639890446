<template>
  <span>
    <ext-account
      title="amazonads"
      :logo="amazon"
      :success="success"
      :error="error"
      :logo-alt="$t('components.connectAmazonAds.amazonAdsLogo')"
      @click="openModal"
      @keyup.enter="openModal"
    />

    <!-- Amazon Modal -->
    <ModalAmazonads
      ref="amazonadsModal"
      :market="corpCountry"
      @submit="continueAmazonAdsIntegration"
      @error="$emit('error', MARKETING_PLATFORMS.AMAZONADS)"
      @modalAction="
        (platformName, action) => $emit('modalAction', platformName, action)
      "
    />
  </span>
</template>

<script>
import { MARKETING_PLATFORMS } from '@/data/platforms';
import ModalAmazonads from '@/components/modals/ModalAmazonads';
import ExtAccount from '@/components/modals/ModalExternalAccountOnboarding2';

export default {
  components: {
    ExtAccount,
    ModalAmazonads,
  },
  props: {
    corpCountry: String,
    authorizeUri: {
      type: String,
      required: true,
    },
    success: Boolean,
    error: Boolean,
  },
  computed: {
    amazon: () => require('@/assets/logos/amazon.png'),
    MARKETING_PLATFORMS: () => MARKETING_PLATFORMS,
  },
  methods: {
    openModal() {
      this.$refs.amazonadsModal.open();
    },
    continueAmazonAdsIntegration(amazonadsData) {
      this.$emit('submit', {
        authorizeUri: this.authorizeUri,
        extras: `${amazonadsData.sellerId},${amazonadsData.market}`,
      });
    },
  },
};
</script>
