// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-wrapper_M9SZ5{display:flex;max-width:500px;justify-content:center;align-items:center;gap:40px;margin:0 auto}.skip-for-now_qQYcG{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-style:normal;font-weight:300;font-size:16px;line-height:24px;color:#666}.skip-for-now_qQYcG span{display:inline-block}.skip-for-now_qQYcG span[class^=text_]{padding-left:10px}.skip-for-now_qQYcG span[class^=checkbox_]{width:24px;height:24px;border:1px solid #c4c4c4;box-sizing:border-box;border-radius:4px;box-shadow:none}.skip-for-now_qQYcG span[class^=checkbox_] svg{width:15px;height:15px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-wrapper": "button-wrapper_M9SZ5",
	"skip-for-now": "skip-for-now_qQYcG"
};
export default ___CSS_LOADER_EXPORT___;
