import {
  isSalesAccountConnected,
  isMarketingAccountConnected,
  isAccountingAccountConnected,
} from '@/utils/external-accounts';
import { isCountryWithPostalCode } from '@/data/country-code-with-names';

export function isOffersStepComplete(advanceInNegotiation, business) {
  if (business.productSegment.label === 'bnpl') {
    return true;
  }
  const { offerOptions } = advanceInNegotiation || {
    offerOptions: [],
  };

  if (!offerOptions || !offerOptions.length) {
    return false;
  }

  return offerOptions.some((offer) => !!offer.selectedAt);
}

function isConnectSalesAccountsStepComplete(externalAccounts) {
  return isSalesAccountConnected(externalAccounts);
}

function isConnectOtherAccountsStepComplete(externalAccounts) {
  return (
    isMarketingAccountConnected(externalAccounts) ||
    isAccountingAccountConnected(externalAccounts)
  );
}

export function isBusinessDetailsStepComplete(business) {
  const shippingFields = [
    'shippingAddressStreet1',
    'shippingAddressCity',
    'shippingAddressState',
    'shippingAddressCountryCode',
  ];
  const requiredFields = [
    'name',
    'addressStreet1',
    'addressCity',
    'addressState',
    'addressCountryCode',
  ];
  if (isCountryWithPostalCode(business.shippingAddressCountryCode))
    shippingFields.push('shippingAddressPostalCode');
  if (isCountryWithPostalCode(business.addressCountryCode))
    requiredFields.push('addressPostalCode');
  requiredFields.push('phone');
  const allRequiredFields = requiredFields.concat(
    business.shippingAddressSame ? [] : shippingFields,
  );
  return allRequiredFields.every((field) => !!business[field]);
}

export function isOwnersStepComplete(
  owners,
  isPrimaryUserInfoComplete,
  isOwnerInfoComplete,
) {
  return (
    isPrimaryUserInfoComplete ||
    owners.some((owner) => isOwnerInfoComplete(owner.id))
  );
}

export default {
  state: {
    steps: {
      'self-serve-connect-sales': {
        title: 'Connect accounts',
        validationMsg: () => 'One sales account must be connected',
        route: 'self-serve-connect-sales',
        nextRoute: { name: 'self-serve-offer-selection' },
        complete: false,
        locked: false,
        visible: true,
      },
      'self-serve-offer-selection': {
        title: 'Select offers',
        validationMsg: (corpCountry) => 'Advance offer must be selected',
        route: 'self-serve-offer-selection',
        nextRoute: { name: 'self-serve-business-details' },
        complete: false,
        locked: false,
        visible: true,
      },
      'self-serve-business-details': {
        title: 'Confirm Business Details',
        validationMsg: (corpCountry) =>
          'Every required business details field must be filled out',
        route: 'self-serve-business-details',
        nextRoute: { name: 'self-serve-sign-contract' },
        complete: false,
        locked: false,
        visible: false,
      },
      'self-serve-sign-contract': {
        title: 'Sign contract',
        validationMsg: (corpCountry) => 'Contract must be signed',
        route: 'self-serve-sign-contract',
        nextRoute: { name: 'self-serve-business-verification' },
        complete: false,
        locked: false,
        visible: false,
      },
      'self-serve-business-verification': {
        title: 'Business verification',
        validationMsg: (corpCountry) =>
          'Every required business verification field must be filled out',
        route: 'self-serve-business-verification',
        nextRoute: { name: 'self-serve-owners' },
        complete: false,
        locked: false,
        visible: false,
      },
      'self-serve-owners': {
        title: 'Add business owners',
        validationMsg: (corpCountry) =>
          'Business owner or board director who is a signatory must be added',
        route: 'self-serve-owners',
        nextRoute: { name: 'self-serve-connect-marketing' },
        complete: false,
        locked: false,
        visible: false,
      },
      'self-serve-connect-marketing': {
        title: 'Connect other accounts',
        validationMsg: () => 'One marketing account must be connected',
        route: 'self-serve-connect-marketing',
        nextRoute: { name: 'self-serve-bank-connections' },
        complete: false,
        locked: false,
        visible: false,
      },
      'self-serve-bank-connections': {
        title: 'Connect bank accounts',
        validationMsg: (corpCountry) => {
          return `Primary bank account must be connected & ${
            corpCountry === 'GB' ? 'Debit Auth Form' : 'ACH'
          } form signed`;
        },
        route: 'self-serve-bank-connections',
        nextRoute: { name: 'self-serve-business-upload' },
        complete: false,
        locked: false,
        visible: false,
      },
      'self-serve-business-upload': {
        title: 'Upload documents',
        validationMsg: (corpCountry) =>
          'Required business documents must be uploaded',
        route: 'self-serve-business-upload',
        nextRoute: { name: 'self-serve-submit-application' },
        complete: false,
        locked: false,
        visible: false,
      },
      'self-serve-submit-application': {
        title: 'Submit application',
        validationMsg: (corpCountry) => '',
        route: 'self-serve-submit-application',
        complete: false,
        locked: false,
        visible: false,
      },
    },
  },
  getters: {
    selfServeSteps: (state) => state.steps,
  },
  actions: {
    UPDATE_SELFSERVE_STEP: ({ state, commit, rootGetters }, step) => {
      const completionState = {
        'self-serve-connect-sales': isConnectSalesAccountsStepComplete(
          rootGetters.externalAccounts,
        ),
        'self-serve-offer-selection': isOffersStepComplete(
          rootGetters.advanceInNegotiation,
          rootGetters.business,
        ),
        'self-serve-business-details': isBusinessDetailsStepComplete(
          rootGetters.business,
        ),
        'self-serve-sign-contract': rootGetters.isAdvanceContractSigned,
        'self-serve-business-verification': rootGetters.businessDetailsComplete,
        'self-serve-owners': isOwnersStepComplete(
          rootGetters.businessOwners,
          rootGetters.isPrimaryUserInfoComplete,
          rootGetters.isOwnerInfoComplete,
        ),
        'self-serve-connect-marketing': isConnectOtherAccountsStepComplete(
          rootGetters.externalAccounts,
        ),
        'self-serve-bank-connections':
          rootGetters.isPrimaryBankAccountContractSigned ||
          rootGetters.primaryBankAccountAuthorizedGoCardless ||
          rootGetters.uploadsMatching(rootGetters.bankUploadMatcher).length > 0,
        'self-serve-business-upload': rootGetters.uploadsComplete,
        'self-serve-submit-application':
          rootGetters.productSegmentLabel === 'core',
      };
      const lockedState = {
        'self-serve-connect-sales': false,
        'self-serve-offer-selection': rootGetters.isAdvanceContractSigned,
        'self-serve-business-details': rootGetters.isAdvanceContractSigned,
        'self-serve-sign-contract': false,
        'self-serve-business-verification': rootGetters.business.isKYBinitiated,
        'self-serve-owners': rootGetters.business.isKYBinitiated,
        'self-serve-connect-marketing': false,
        'self-serve-bank-connections': false,
        'self-serve-business-upload': false,
      };
      const updatedStep = {
        ...state.steps[step],
        complete: completionState[step],
        locked: lockedState[step],
      };

      commit('UPDATE_SELFSERVE_STEP', updatedStep);
    },
    SHOW_ALL_SELFSERVE_STEPS: ({ commit }, isVisible) => {
      if (isVisible) {
        commit('SHOW_ALL_SELFSERVE_STEPS');
      }
    },
    UPDATE_ALL_SELFSERVE_STEPS: ({ state, dispatch }) => {
      Object.keys(state.steps).forEach((step) =>
        dispatch('UPDATE_SELFSERVE_STEP', step),
      );
    },
  },
  mutations: {
    UPDATE_SELFSERVE_STEP: (state, updatedStep) => {
      state.steps[updatedStep.route] = updatedStep;
    },
    SHOW_ALL_SELFSERVE_STEPS: (state) => {
      const updatedSteps = Object.values(state.steps).reduce((acc, val) => {
        acc[val.route] = { ...val, visible: true };
        return acc;
      }, {});

      state.steps = updatedSteps;
    },
  },
};
