<template>
  <popup ref="popup" class="p-50" title="Legal Updates" no-exit="no-exit">
    <form-row no-inputs="no-inputs">
      Please review the
      <a :href="addLocaleParam(clearbancURL + '/terms')" target="_blank">
        Terms of Service
      </a>
      - we have made recent updates.
    </form-row>
    <form-row>
      <v-button @click="closeButtonHandler"> I accept </v-button>
    </form-row>
  </popup>
</template>

<script>
import { mapGetters } from 'vuex';
import addLocaleParam from '@/utils/add-locale-param';

export default {
  computed: {
    ...mapGetters(['user', 'isImpersonatingUser']),
    clearbancURL() {
      return process.env.CLEARCOM_URL;
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.showUpdatePopup();
      },
    },
  },
  mounted() {
    this.showUpdatePopup();
  },
  methods: {
    addLocaleParam,
    open() {
      this.$refs.popup.open();
    },
    async closeButtonHandler() {
      await this.$store.dispatchApiAction('ADD_USER_AGREEMENT', {
        type: 'popup',
        version: process.env.TERMS_AND_POLICY_UPDATED_AT,
      });
      this.$refs.popup.close();
    },
    async showUpdatePopup() {
      if (this.isImpersonatingUser) return;
      if (
        this.user.legalAgreementVersion <
        process.env.TERMS_AND_POLICY_UPDATED_AT
      )
        this.open();
    },
  },
};
</script>
