// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".banner-wrapper_DwmHI{max-width:1440px;margin:auto;padding:0 40px}@media only screen and (min-width:513px) and (max-width:1024px){.banner-wrapper_DwmHI{padding:0 40px}}@media only screen and (max-width:512px){.banner-wrapper_DwmHI{padding:0 16px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner-wrapper": "banner-wrapper_DwmHI"
};
export default ___CSS_LOADER_EXPORT___;
