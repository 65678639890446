<template>
  <div :class="$style.form">
    <slot />
  </div>
</template>

<style lang="less" module>
.form {
  width: 420px;
  @media screen and (max-width: 500px) {
    width: unset;
  }
  & > * {
    margin-bottom: 20px;
    margin: auto auto 20px auto;
  }
}
</style>
