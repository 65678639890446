// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader_W17qR{margin:0 auto;width:1.5rem;height:1.5rem}.badge_sj_lt,.loader_W17qR{display:block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "loader_W17qR",
	"badge": "badge_sj_lt"
};
export default ___CSS_LOADER_EXPORT___;
