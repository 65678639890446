<template>
  <span>
    <ExtAccount
      title="amazon"
      :logo="amazon"
      :class="$style.container"
      :success="success"
      :disconnected="disconnected"
      :error="errors.amazon"
      logo-alt="Amazon logo"
      @click="openModal"
      @keyup.enter="openModal"
    />

    <ModalAmazonSeller
      ref="amazonModal"
      :corp-country="corpCountry"
      @error="$emit('error', SALES_PLATFORMS.AMAZON)"
      @success="$emit('success', SALES_PLATFORMS.AMAZON)"
      @oauthError="(payload) => $emit('oauthError', payload)"
      @modalAction="
        (platformName, action) => $emit('modalAction', platformName, action)
      "
      @submit="continueAmazonOauth"
    />
  </span>
</template>

<script>
import { SALES_PLATFORMS } from '@/data/platforms';
import { isMobileMixin } from '@/utils/vue-mixins';
import ExtAccount from '../modals/ModalExternalAccountOnboarding2';
import ModalAmazonSeller from '../modals/ModalAmazonspapi';

export default {
  components: {
    ExtAccount,
    ModalAmazonSeller,
  },
  mixins: [isMobileMixin],
  props: {
    success: Boolean,
    disconnected: Boolean,
    errors: Object,
    corpCountry: String,
  },
  computed: {
    amazon: () => require('@/assets/logos/amazon.png'),
    SALES_PLATFORMS: () => SALES_PLATFORMS,
  },
  methods: {
    openModal() {
      this.clearOauthParams();
      this.$emit('tileClick', SALES_PLATFORMS.AMAZON);
      this.$refs.amazonModal.open();
    },
    continueAmazonOauth(authorizeUri) {
      const options = { authorizeUri };

      if (this.isMobile()) {
        options.noPopup = true;
        options.redirectRoute = authorizeUri.split('&').find((arg) => {
          return !!arg.includes('redirect_uri');
        });
        localStorage.setItem('oauth-provider', SALES_PLATFORMS.AMAZON);
        localStorage.setItem('oauth-redirect-route', options.redirectRoute);
      }

      this.$emit('submit', options);
    },
    clearOauthParams() {
      localStorage.removeItem('oauth-provider');
      localStorage.removeItem('oauth-redirect-route');
    },
  },
};
</script>

<style lang="less" module>
.container {
  [class^='logo'] {
    max-height: unset;
  }
}
</style>
;
