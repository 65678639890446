// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-button_l6ag1{border:none;cursor:pointer;position:relative}.menu-button_l6ag1 .line_HSSKt{height:2px;width:18px;background:#000}.menu-button_l6ag1 .line_HSSKt:nth-child(2){margin:4px 0}.menu-button_l6ag1 .notif_SRTA4{height:8px;width:8px;border-radius:50%;position:absolute;bottom:-2px;right:0;outline:2px solid #f8f8fa}.menu-button_l6ag1 .notif_SRTA4.neutral_N75Wx{background-color:#ababad}.menu-button_l6ag1 .notif_SRTA4.success_gQucQ{background-color:#23aa71}.menu-button_l6ag1 .notif_SRTA4.danger_a141R{background-color:#e8564d}.menu-button_l6ag1 .notif_SRTA4.info_Th8fY{background-color:#297fe3}.menu-button_l6ag1 .notif_SRTA4.warning_N73td{background-color:#e8a524}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-button": "menu-button_l6ag1",
	"line": "line_HSSKt",
	"notif": "notif_SRTA4",
	"neutral": "neutral_N75Wx",
	"success": "success_gQucQ",
	"danger": "danger_a141R",
	"info": "info_Th8fY",
	"warning": "warning_N73td"
};
export default ___CSS_LOADER_EXPORT___;
