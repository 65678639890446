// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".requirements_OFxTE{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;color:#393939;background:linear-gradient(0deg,rgba(97,47,255,.1) 24.71%,#fff);border:1px solid #caa8ff;border-bottom-left-radius:4px;border-bottom-right-radius:4px;padding:1.5rem}.requirements_OFxTE .title_KSG7y{font-weight:700;font-size:16px;line-height:1.5;margin-bottom:2rem;display:block}.requirements_OFxTE .grid_tsnpm{display:grid;justify-items:center;gap:1rem;grid-template-columns:repeat(2,minmax(0,1fr))}@media only screen and (min-width:960px){.requirements_OFxTE .grid_tsnpm{grid-template-columns:repeat(3,minmax(0,1fr));row-gap:3rem}}.requirements_OFxTE .grid_tsnpm .col_RTmyd{grid-column:span 1/span 1;text-align:center}.requirements_OFxTE .grid_tsnpm .col_RTmyd .icon_DErt1{width:36px;height:auto;margin:0 auto}.requirements_OFxTE .grid_tsnpm .col_RTmyd .description_gquGn{font-size:12px;font-weight:300;line-height:1.33}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requirements": "requirements_OFxTE",
	"title": "title_KSG7y",
	"grid": "grid_tsnpm",
	"col": "col_RTmyd",
	"icon": "icon_DErt1",
	"description": "description_gquGn"
};
export default ___CSS_LOADER_EXPORT___;
