// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn-container_GSOwT{display:flex;justify-content:center;height:40px;width:100%;align-items:center;border:1px solid;cursor:pointer}.btn-container_GSOwT.rounded-border_wQf_i{border-radius:8px}.btn-container_GSOwT .logo_rLOVl{width:18px;margin-right:8px}.btn-container_GSOwT .logo_rLOVl svg{display:block}.btn-container_GSOwT .text_TOe7a{font-family:Roboto,sans-serif;font-weight:500;font-size:14px;line-height:28px;-webkit-user-select:none;-moz-user-select:none;user-select:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-container": "btn-container_GSOwT",
	"rounded-border": "rounded-border_wQf_i",
	"logo": "logo_rLOVl",
	"text": "text_TOe7a"
};
export default ___CSS_LOADER_EXPORT___;
