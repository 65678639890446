// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nav-bar_i6Vbh{position:fixed;transition:background-color .5s ease;top:0;left:0;display:flex;align-items:center;justify-content:space-between;height:90px;width:100%;font-size:15px;font-weight:600;font-family:Montserrat;z-index:100;text-transform:uppercase;background:#fff;margin:0 auto;box-shadow:0 4px 4px rgba(0,0,0,.1)}.nav-bar_i6Vbh .button_aJfUW{font-family:Montserrat;border-radius:0;letter-spacing:0}@media only screen and (max-width:1023px){.nav-bar_i6Vbh .badge_gEcQ5{margin-left:5px}.nav-bar_i6Vbh .badge_gEcQ5,.nav-bar_i6Vbh .burger-badge_hQETM{vertical-align:super;border-radius:50%;margin-top:-25px;width:16px;height:16px;left:271px;top:366px;background:#eb5757}.nav-bar_i6Vbh .burger-badge_hQETM{margin-right:5px}}@media only screen and (min-width:1024px){.nav-bar_i6Vbh .badge_gEcQ5,.nav-bar_i6Vbh .burger-badge_hQETM{display:none}}.nav-bar_i6Vbh .switch-wrapper_Zkz07{max-width:50px;margin-top:10px}.nav-bar_i6Vbh .switch-wrapper_Zkz07 .switch-wrapper_Zkz07{margin-top:20px}@media only screen and (max-width:1170px){.nav-bar_i6Vbh .switch-wrapper_Zkz07{margin:-25px 0 0;display:block}}@media only screen and (max-width:825px){.nav-bar_i6Vbh .switch-wrapper_Zkz07{margin:-25px auto 0;display:block}}.nav-bar_i6Vbh.transparent_QjiDp{background:transparent}.nav-bar_i6Vbh .logo_eCrh3{min-width:200px;max-width:200px;margin-left:20px;margin-top:10px;cursor:pointer;z-index:2}@media only screen and (max-width:500px){.nav-bar_i6Vbh .logo_eCrh3{margin-left:20px;width:190px}.nav-bar_i6Vbh{border-bottom:1px solid transparent}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav-bar": "nav-bar_i6Vbh",
	"button": "button_aJfUW",
	"badge": "badge_gEcQ5",
	"burger-badge": "burger-badge_hQETM",
	"switch-wrapper": "switch-wrapper_Zkz07",
	"transparent": "transparent_QjiDp",
	"logo": "logo_eCrh3"
};
export default ___CSS_LOADER_EXPORT___;
