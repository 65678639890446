<template>
  <ButtonLinkContainer
    :center-cta="centerCta && !showCheckbox"
    :text="ctaText"
    :class="$style.container"
    @nextClick="$emit('nextClick')"
  >
    <!-- Please do not remove id, used to identify checkbox in FullStory -->
    <Checkbox
      v-if="showCheckbox"
      :id="id"
      v-model="value"
      name="skipStep"
      :class="$style.checkbox"
      @input="trackCheckboxClick"
      >{{ checkboxText }}</Checkbox
    >
  </ButtonLinkContainer>
</template>

<script>
import { inject } from 'vue';
import analytics from '@/utils/analytics';
import { Checkbox, ButtonLinkContainer } from '@/components';

export default {
  components: {
    Checkbox,
    ButtonLinkContainer,
  },
  props: {
    checkboxStartingValue: { type: Boolean, default: false },
    checkboxText: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('common.buttons.skipForNow');
      },
    },
    ctaText: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('common.buttons.nextStep');
      },
    },
    id: { type: String, default: '' },
    showCheckbox: { type: Boolean, default: true },
    centerCta: { type: Boolean, default: true },
    eventTrackingFields: { type: Object, default: () => {} },
  },
  data() {
    return {
      value: false,
    };
  },
  mounted() {
    this.value = this.checkboxStartingValue;
  },
  methods: {
    trackCheckboxClick(value) {
      analytics.track(
        `fe_${this.checkboxText.toLowerCase().replace(/ /g, '_')}_clicked`,
        {
          ...this.eventTrackingFields,
          value,
        },
      );
      this.$emit('checkboxClick', value);
    },
  },
};
</script>

<style lang="less" module>
.container {
  margin: auto;
}
.checkbox {
  margin: auto 0;
}
</style>
