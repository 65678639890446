<template>
  <div :class="$style.container">
    <slot name="titles" />
    <slot name="errorMessage" />

    <BannerConnectAccountsError
      :show-banner="showError"
      :is-bank-connect-error="isBankConnectError"
      :is-bank-statements-error="isBankStatementsError"
      :custom-accounting-error="customAccountingError"
      :custom-banking-error="customBankingError"
      :class="$style.error"
      @errorBannerAction="
        (action, errorMsg) =>
          trackErrorBanner(action, eventTrackingFields, errorMsg)
      "
    />
    <slot />
    <div v-if="$slots.cta" :class="$style.cta">
      <slot name="cta" />
    </div>
    <EncryptionMessage v-if="showEncryptionMessage" />
  </div>
</template>
<script>
import EncryptionMessage from '@/components/EncryptionMessage';
import externalAccountsComposable from '@/composables/external-accounts';
import BannerConnectAccountsError from '@/components/banners/BannerConnectAccountsError';
import { useContextRoot } from '@/utils/context-root';

export default {
  components: {
    EncryptionMessage,
    BannerConnectAccountsError,
  },
  props: {
    showError: {
      type: Boolean,
      default: false,
    },
    showEncryptionMessage: {
      type: Boolean,
      default: true,
    },
    isBankStatementsError: {
      type: Boolean,
      default: false,
    },
    isBankConnectError: {
      type: Boolean,
      default: false,
    },
    customAccountingError: {
      type: String,
      default: '',
    },
    eventTrackingFields: {
      type: Object,
      default: () => {},
    },
    customBankingError: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const { trackErrorBanner } = externalAccountsComposable(useContextRoot());
    return {
      trackErrorBanner,
    };
  },
};
</script>

<style lang="less" module>
.container {
  text-align: center;
}
.error {
  margin-top: 20px;
}
.cta {
  max-width: 500px;
  margin: 30px auto auto;
}
</style>
