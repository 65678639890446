<template>
  <font-awesome-icon
    class="icon"
    :data-cy="name + '-icon'"
    :name="name"
    :spin="name === 'spinner'"
    :scale="scale"
  />
</template>

<script>
// see https://fontawesome.com/v4.7.0/icons/ for available icons
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/times-circle';
import 'vue-awesome/icons/check';
import 'vue-awesome/icons/check-circle';
import 'vue-awesome/icons/question-circle';
import 'vue-awesome/icons/arrow-right';
import 'vue-awesome/icons/arrow-left';
import 'vue-awesome/icons/arrow-circle-right';
import 'vue-awesome/icons/arrow-circle-left';
import 'vue-awesome/icons/exclamation-circle';
import 'vue-awesome/icons/exclamation-triangle';
import 'vue-awesome/icons/phone';
import 'vue-awesome/icons/info-circle';
import 'vue-awesome/icons/regular/address-card';
import 'vue-awesome/icons/star';
import 'vue-awesome/icons/regular/star';
import 'vue-awesome/icons/plus';
import 'vue-awesome/icons/plus-circle';
import 'vue-awesome/icons/minus-circle';
import 'vue-awesome/icons/spinner';
import 'vue-awesome/icons/search';
import 'vue-awesome/icons/trash';
import 'vue-awesome/icons/bars';
import 'vue-awesome/icons/user';
import 'vue-awesome/icons/briefcase';
import 'vue-awesome/icons/tasks';
import 'vue-awesome/icons/wrench';
import 'vue-awesome/icons/link';
import 'vue-awesome/icons/download';
import 'vue-awesome/icons/gift';
import 'vue-awesome/icons/retweet';
import 'vue-awesome/icons/eye';
import 'vue-awesome/icons/eye-slash';
import 'vue-awesome/icons/brands/facebook';
import 'vue-awesome/icons/brands/facebook-f';
import 'vue-awesome/icons/brands/twitter';
import 'vue-awesome/icons/brands/twitter-square';
import 'vue-awesome/icons/brands/medium-m';
import 'vue-awesome/icons/brands/linkedin-in';
import 'vue-awesome/icons/brands/linkedin';
import 'vue-awesome/icons/lock';
import 'vue-awesome/icons/angle-up';
import 'vue-awesome/icons/angle-down';
import 'vue-awesome/icons/angle-right';
import 'vue-awesome/icons/angle-left';
import 'vue-awesome/icons/certificate';
import 'vue-awesome/icons/ban';
import 'vue-awesome/icons/hourglass-half';
import 'vue-awesome/icons/bolt';
import 'vue-awesome/icons/external-link-square-alt';
import 'vue-awesome/icons/minus-square';
import 'vue-awesome/icons/plus-square';
import 'vue-awesome/icons/chevron-left';
import 'vue-awesome/icons/users';
import 'vue-awesome/icons/bullhorn';
import 'vue-awesome/icons/rocket';
import 'vue-awesome/icons/university';
import 'vue-awesome/icons/envelope';

const components = {
  FontAwesomeIcon: require('vue-awesome/components/Icon').default,
};

export default {
  components,
  props: {
    name: { type: String },
    scale: { type: String },
    title: String,
  },
};
</script>

<style lang="less">
.icon {
  display: inline-block;
  vertical-align: middle;
}

.link-with-icon {
  .icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    margin-top: -2px;
    width: 20px;
    height: 20px;
  }
}
</style>
