<template>
  <Modal
    v-model:visible="showModal"
    :header="$t('components.modalPlaystore.connectYourGooglePlayAccount')"
    :modal="true"
    :draggable="false"
    append-to="self"
    @show="$emit('modalAction', SALES_PLATFORMS.PLAYSTORE, 'open')"
    @hide="$emit('modalAction', SALES_PLATFORMS.PLAYSTORE, 'close')"
  >
    <div :class="$style.container">
      <ol :class="$style.list">
        <li>
          <i18n-t keypath="components.modalPlaystore.loginToGooglePlay">
            <template #googlePlay>
              <a
                :class="$style.link"
                target="_blank"
                href="https://play.google.com/apps/publish/"
                >{{ $t('components.modalPlaystore.googlePlay') }}</a
              >
            </template>
            <template #downloadReports>
              <strong>{{
                $t('components.modalPlaystore.downloadReports')
              }}</strong>
            </template>
          </i18n-t>
        </li>
        <li>
          <i18n-t keypath="components.modalPlaystore.clickFinancial">
            <template #financial>
              <strong>{{ $t('components.modalPlaystore.financial') }}</strong>
            </template>
          </i18n-t>
        </li>
        <li>
          <i18n-t
            keypath="components.modalPlaystore.scrollDownLocateDirectReport"
          >
            <template #directReport>
              <strong>{{
                $t('components.modalPlaystore.directReport')
              }}</strong>
            </template>
          </i18n-t>
        </li>
        <li>
          <p>
            {{ $t('components.modalPlaystore.copyTheFirstURI') }}
          </p>
          <p :class="$style.formats">
            <span>gs://pubsite_prod_000000000000000/sales/</span>
            <span>gs://pubsite_prod_rev_000000000000000/sales/</span>
          </p>
          <input-with-eyebrow
            v-model="reportUri"
            :class="$style['input-fields']"
            :placeholder="
              $t('components.modalPlaystore.googlePlayDirectReport')
            "
            required
            name="Report URI"
            :validate-on-blur="validateOnBlur"
          />
        </li>
        <li>
          <p>
            {{ $t('components.modalPlaystore.loginToAuthorizeAccess') }}
          </p>
          <google-button
            round
            :class="$style['google-button']"
            @click="continuePlaystoreIntegration"
          />
        </li>
      </ol>
    </div>
  </Modal>
</template>

<script>
import { isMobileMixin } from '@/utils/vue-mixins';
import { validateChildren } from '@/composables/validation';
import { SALES_PLATFORMS } from '@/data/platforms';
import GoogleButton from '@/components/GoogleButton';
import InputWithEyebrow from '@/components/inputs/InputWithEyebrow';
import Modal from '@clearbanc/clear-components/dialog';

export default {
  components: {
    InputWithEyebrow,
    Modal,
    GoogleButton,
  },
  mixins: [isMobileMixin],
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      reportUri: '',
      validateOnBlur: false,
      showModal: false,
    };
  },
  computed: {
    SALES_PLATFORMS: () => SALES_PLATFORMS,
  },
  methods: {
    open() {
      this.reportUri = '';
      this.showModal = true;
    },
    async continuePlaystoreIntegration() {
      this.validateOnBlur = true;
      const { reportUri } = this;
      const error = await this.hasError();
      if (error || !reportUri) {
        this.$emit('error');
        return;
      }
      this.showModal = false;
      this.$emit('submit', reportUri);
    },
  },
};
</script>

<style lang="less" module>
.container {
  text-align: left;
  max-width: 560px;
  margin: 0 auto;

  .list {
    list-style-type: decimal;
    padding-left: 1rem;

    li ~ li {
      margin-top: 1rem;
    }
  }

  .google-button {
    border-radius: 4px;
    max-width: 400px;
  }

  .link {
    text-decoration: underline;
    color: @link-blue;
    cursor: pointer;
  }

  .input-fields {
    max-width: 400px;
    margin: 1.5rem 0;
  }

  .formats {
    font-size: 14px;

    span {
      display: block;
    }
  }
}
</style>
