// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".message_vh78q[data-v-27d0fd82]{margin:40px 0 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "message_vh78q"
};
export default ___CSS_LOADER_EXPORT___;
