<template>
  <Modal
    v-model:visible="showModal"
    header="Connect to Shopify"
    :modal="true"
    :draggable="false"
    append-to="self"
    @show="$emit('modalAction', SALES_PLATFORMS.SHOPIFY_FIVETRAN, 'open')"
    @hide="$emit('modalAction', SALES_PLATFORMS.SHOPIFY_FIVETRAN, 'close')"
  >
    <Message v-if="apiError" severity="error" :closable="false">
      <p>
        An error occured while trying to connect your account, please try again.
        If this issue persists, please email us to
        <a class="error" href="mailto:support@clear.co" target="_blank"
          >support@clear.co</a
        >
      </p>
    </Message>
    <div v-if="canAddNewStoresViaFivetran">
      <div :class="$style['shopify-tip-content']">
        <p>Connect with Fivetran to link your Shopify stores.</p>
        <p>
          When adding a new store, log in to your Shopify admin to locate your
          store name in the URL.
          <button
            v-tooltip.focus.bottom="{
              value: handleShopifyToolTipContent,
              class: $style['shopify-modal-tooltip-content'],
              escape: true,
            }"
            type="button"
            class="pi pi-info-circle"
            :class="$style.tooltip"
            @focus="sendTooltipSegmentEvent"
          />
        </p>
      </div>
      <div :class="$style['input-container']">
        <div class="p-inputgroup">
          <InputText
            v-model="shopifyStoreName"
            data-cy="shopify-store-url"
            :placeholder="
              $t('components.tileGroupConnectSales.modalShopify.yourStoreName')
            "
            :class="errorMsg ? 'p-invalid' : ''"
          />
          <span class="p-inputgroup-addon">.myshopify.com</span>
        </div>
        <small v-if="errorMsg" class="p-error">{{ errorMsg }}</small>
        <DSButton
          data-cy="connect-shopify-button"
          label="Add Store"
          class="p-button-primary"
          :class="$style.button"
          :loading="isLoading"
          @click="handleSubmit"
        />
      </div>
      <div v-if="shopifyAccounts.length" :class="$style['reconnect-border']">
        <h3 :class="$style.subtitle">Your Stores</h3>
        <div :class="$style['reconnect-wrapper']">
          <div
            v-for="shop in shopifyAccounts"
            :key="shop.id"
            :class="$style.reconnect"
          >
            <span :class="$style['shop-name']">{{ shop.label }}</span>
            <Tag
              v-if="getShopifyAccStatus(shop) === 'connected'"
              class="reconnect-tag"
              severity="success"
              value="Connected"
            ></Tag>

            <DSButton
              v-else
              label="CONNECT"
              class="p-button-primary p-button-sm reconnect-button"
              :class="[$style.button, $style['tiny-button']]"
              :loading="isLoading"
              @click="reconnectStore(shop.label)"
            />
          </div>
        </div>
      </div>

      <Message severity="success" icon="pi-shield" :closable="false">
        <p>
          {{
            $t(
              'components.tileGroupConnectSales.modalShopify.shopifyUsersShareTheirData',
            )
          }}
          <a
            :href="
              addLocaleParam(
                'https://help.clear.co/s/article/Connecting-your-accounts',
              )
            "
            target="_blank"
            @click="trackLearnMoreSegmentEvent"
          >
            {{
              $t('components.tileGroupConnectSales.modalShopify.learnMore')
            }}</a
          >
        </p>
      </Message>
      <div v-if="shopifyAccounts.length" :class="$style['help-section']">
        Need help? Contact us at
        <a
          href="mailto:support@clear.co"
          target="_blank"
          @click="trackLinkClick('fe_shopify_help_centre_click')"
          >support@clear.co</a
        >.
      </div>
    </div>
    <div v-else>
      <div v-if="shopifyAccounts.length">
        <div :class="$style['reconnect-wrapper']">
          <div
            v-for="shop in shopifyAccounts"
            :key="shop.id"
            :class="$style.reconnect"
          >
            <span :class="$style['shop-name']">{{ shop.label }}</span>
            <Tag
              v-if="getShopifyAccStatus(shop) === 'connected'"
              class="reconnect-tag"
              severity="success"
              value="Connected"
            ></Tag>

            <DSButton
              v-else
              label="CONNECT"
              class="p-button-primary p-button-sm reconnect-button"
              :class="[$style.button, $style['tiny-button']]"
              :loading="isLoading"
              @click="reconnectStore(shop.label)"
            />
          </div>
        </div>
      </div>

      <Message severity="success" icon="pi-shield" :closable="false">
        <p>
          {{
            $t(
              'components.tileGroupConnectSales.modalShopify.shopifyUsersShareTheirData',
            )
          }}
          <a
            :href="
              addLocaleParam(
                'https://help.clear.co/s/article/Connecting-your-accounts',
              )
            "
            target="_blank"
            @click="trackLearnMoreSegmentEvent"
          >
            {{
              $t('components.tileGroupConnectSales.modalShopify.learnMore')
            }}</a
          >
        </p>
      </Message>
      <div v-if="shopifyAccounts.length" :class="$style['help-section']">
        Need help? Contact us at
        <a
          href="mailto:support@clear.co"
          target="_blank"
          @click="trackLinkClick('fe_shopify_help_centre_click')"
          >support@clear.co</a
        >.
      </div>
    </div>
  </Modal>
</template>

<script>
import { shopifyUrlMixin, isMobileMixin } from '@/utils/vue-mixins';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import stateModuleAware from '@/mixins/state-module-aware';
import { SALES_PLATFORMS } from '@/data/platforms';
import analytics from '@/utils/analytics';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import Tag from '@clearbanc/clear-components/tag';
import Modal from '@clearbanc/clear-components/dialog';
import InputText from '@clearbanc/clear-components/inputtext';
import addLocaleParam from '@/utils/add-locale-param';
import externalAccountsComposables from '@/composables/external-accounts';
import { useContextRoot } from '@/utils/context-root';

export default {
  components: {
    Modal,
    DSButton,
    InputText,
    Message,
    Tag,
  },
  mixins: [shopifyUrlMixin, isMobileMixin, stateModuleAware],
  props: {
    redirectUri: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const { getShopifyAccStatus } = externalAccountsComposables(
      useContextRoot(),
    );
    return {
      getShopifyAccStatus,
    };
  },
  data() {
    return {
      apiError: false,
      shopifyStoreName: '',
      errorMsg: '',
      storeName: '',
      showModal: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'productSegmentLabel',
      'business',
      'shopifyAccounts',
      'user',
    ]),
    ...mapRequestStatuses({
      addConnectedAccountRequest: 'ADD_CONNECTED_ACCOUNT',
    }),
    handleShopifyToolTipSrc() {
      if (this.isMobile()) {
        return require('@/assets/images/shopify-modal-tooltip-screenshot-mobile.jpg');
      }
      return require('@/assets/images/shopify-modal-tooltip-screenshot.jpg');
    },
    handleShopifyToolTipContent() {
      const src = this.handleShopifyToolTipSrc;
      const alt = this.$t(
        'components.tileGroupConnectSales.modalShopify.howToFindYourShopifyStore',
      );
      return `<strong>${this.$t(
        'components.tileGroupConnectSales.modalShopify.findYourShopifyStore',
      )}</strong><span>${this.$t(
        'components.tileGroupConnectSales.modalShopify.loginToYourShopifyAccount',
      )}</span><img src="${src}" alt="${alt}" title="${alt}" />`;
    },
    canAddNewStoresViaFivetran() {
      // TODO: sc-166906 remove logic for this once we're confident in rollout
      return true;
    },
    SALES_PLATFORMS: () => SALES_PLATFORMS,
  },
  methods: {
    addLocaleParam,
    open() {
      this.shopifyStoreName = '';
      this.storeName = '';
      this.errorMsg = '';
      this.showModal = true;
      this.apiError = false;
    },
    cleanStoreName() {
      // Remove spaces, .com, .ca and temporarily remove .myshopify.com as well
      const substringToRemove = [
        'http://',
        'https://',
        'www.',
        ' ',
        '.myshopify',
        '.com',
        '.ca',
        ')',
        '(',
        ',',
        '/admin',
      ];
      for (const substring of substringToRemove) {
        this.shopifyStoreName = this.shopifyStoreName?.replaceAll(
          substring,
          '',
        );
      }
    },
    async isInputValid() {
      this.cleanStoreName();
      const handleObject = this.handleUrl(this.shopifyStoreName, true);
      this.storeName = handleObject.storeName ? handleObject.storeName : '';
      this.errorMsg = handleObject.errorMessage ?? '';
      return !this.errorMsg;
    },
    async handleSubmit() {
      this.isLoading = true;
      analytics.track('fe_shopify_store_connect_click', {
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
      });
      const inputValid = await this.isInputValid();
      if (inputValid) {
        const response = await this.$store.dispatchApiAction(
          'ADD_CONNECTED_ACCOUNT',
          {
            shopName: this.shopifyStoreName,
          },
        );
        if (this.addConnectedAccountRequest.isError) {
          this.$emit('error', this.addConnectedAccountRequest.errorMessage);
        } else {
          this.$emit('submit', this.storeName, response.connectUrl);
        }
        this.showModal = false;
        this.isLoading = false;
      } else {
        // if the input is invalid, we want to emit to tell the ext-account component that oauth was attempted.
        // This will cause the ext-account tile to enter its error state. If the input IS valid, we don't want
        // to emit because otherwise the tile will enter its error state when an error hasn't yet occurred.
        this.$emit('error');
      }
      analytics.track('fe_shopify_store_entered', {
        storeName: this.shopifyStoreName,
        isValidStoreName: !this.errorMsg,
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
      });
      this.isLoading = false;
    },
    trackLinkClick(eventName) {
      analytics.track(eventName, {
        targetArea: this.$route.name,
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
      });
    },
    trackLearnMoreSegmentEvent() {
      analytics.track('fe_shopify_data_collect_info_click', {
        targetArea: this.$route.name,
      });
    },
    sendTooltipSegmentEvent() {
      analytics.track('fe_shopify_tooltip_click', {
        targetArea: this.$route.name,
      });
    },
    reconnectStore(shopName) {
      // example format shopname.myshopify.com
      this.shopifyStoreName = shopName.split('.')[0];
      this.handleSubmit();
    },
  },
};
</script>

<style>
.p-button.p-button-sm.reconnect-button {
  font-size: 0.7rem;
  height: 26px;
  width: 99px;
}
.p-tag.p-tag-success.reconnect-tag {
  width: 99px;
  background-color: transparent;
}
</style>
<style lang="less" module>
.reconnect-border {
  border-top: 1px solid #80808036;
  padding-top: 20px;
  margin-top: 20px;
}
.reconnect-wrapper {
  max-height: 100px;
  overflow-y: scroll;
}
.reconnect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px auto;

  @media only screen and (max-width: 500px) {
    border-bottom: 1px solid #80808036;
    max-width: 300px;
  }
  .connected {
    margin-right: 35px;
  }
  .shop-name {
    word-break: break-word;
    max-width: 350px;
    padding-right: 20px;
    text-align: left;
    @media only screen and (max-width: 500px) {
      max-width: 170px;
      padding-right: 5px;
    }
  }
}
h3.subtitle {
  font-weight: 500;
  font-family: @gerstner-font;
  font-size: 16px;
}
.input-container {
  max-width: 400px;
  margin: 2rem auto;
  text-align: left;
  .button {
    display: block;
    margin-top: 2rem;
    margin-left: auto;
  }
}
.help-section {
  margin-top: 1.5rem;
  p {
    margin: 0;
  }
}
.shopify-tip-content {
  @media only screen and (max-width: 500px) {
    text-align: left;
  }
  p {
    margin: 0;
  }
}
.tooltip {
  color: @color-black;
  padding: 4px;
  cursor: help;
}
.shopify-modal-tooltip-content {
  max-width: 400px !important;

  @media only screen and (max-width: 500px) {
    max-width: calc(100% - 40px) !important;
    left: 20px !important;
  }

  strong,
  span,
  img {
    display: block;
  }

  img {
    max-width: 100%;
    margin-top: 1rem;
  }
}
</style>
