<script setup>
import SectionFooter from '@/components/SectionFooter';
</script>

<template>
  <div :class="$style.wrapper">
    <div :class="$style.container">
      <div :class="$style.box">
        <router-view />
      </div>
      <SectionFooter :class="$style.footer" />
    </div>
  </div>
</template>

<style lang="less" module scoped>
.wrapper {
  width: 100%;
  height: 100%;
  background: var(--neutral-grey-50, #f8f8fa);
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.container {
  width: 100%;
  max-width: 1128px;
  height: 100%;
  max-height: 830px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.box {
  border-radius: 4px;
  border: 1px solid var(--neutral-grey-200, #ceced0);
  background: var(--neutral-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
  flex-grow: 1;
}

.footer {
  padding: 24px 0;
}

@media (min-width: 768px) {
  .box {
    padding: 80px;
  }
}
</style>
