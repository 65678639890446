// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_cOG_3{display:flex;flex-direction:column}.form_XpfhU{width:330px}.back-button_pEmZb{cursor:pointer;width:18px;height:18px;margin:auto auto auto 0}.button_KgXRl{min-width:186px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_cOG_3",
	"form": "form_XpfhU",
	"back-button": "back-button_pEmZb",
	"button": "button_KgXRl"
};
export default ___CSS_LOADER_EXPORT___;
