// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".calculator__cta_MjXqJ{display:grid;grid-template-columns:1fr;-moz-column-gap:20px;column-gap:20px;grid-column:1/3;grid-row:3/4}@media (min-width:980px){.calculator__cta_MjXqJ{grid-template-columns:-webkit-max-content 1fr;grid-template-columns:max-content 1fr}}.calculator__cta__filler_u9VKv{background:#ebebeb;border-radius:4px}.calculator__cta__content_hUzoP{background:#fff;border-radius:4px;display:flex;flex-direction:column;position:relative}@media (min-width:550px){.calculator__cta__content_hUzoP{flex-direction:row}}.calculator__cta__content_hUzoP:before{content:\"\";position:absolute;top:50%;left:0;transform:translate(-50%,-50%);width:24px;height:24px;border-radius:50%;background:#f7f7f9}.calculator__cta__content__left_mVkl_{display:flex;align-items:center;justify-content:center;padding:20px 40px;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;color:#111;font-size:20px;font-style:normal;font-weight:700;line-height:120%}@media (min-width:980px){.calculator__cta__content__left_mVkl_{font-size:28px}}.calculator__cta__content__left__icon_CkfIb{margin-right:11px}.calculator__cta__content__right_oqt_L{display:flex;align-items:center;border-top:1px solid #f6f6f6;padding:20px;white-space:nowrap;flex-grow:1}@media (min-width:550px){.calculator__cta__content__right_oqt_L{border-left:1px solid #f6f6f6;border-top:0}}.calculator__cta__content__right_oqt_L button{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calculator__cta": "calculator__cta_MjXqJ",
	"calculator__cta__filler": "calculator__cta__filler_u9VKv",
	"calculator__cta__content": "calculator__cta__content_hUzoP",
	"calculator__cta__content__left": "calculator__cta__content__left_mVkl_",
	"calculator__cta__content__left__icon": "calculator__cta__content__left__icon_CkfIb",
	"calculator__cta__content__right": "calculator__cta__content__right_oqt_L"
};
export default ___CSS_LOADER_EXPORT___;
