import { initializeStore } from '@/utils/vuex-api-utils';
import uploadsModule from '@/store/modules/uploads-module';
import bankingModule from '@/store/modules/banking-module';
import contractsModule from '@/store/modules/contracts-module';
import connectedAccountsModule from '@/store/modules/connected-accounts-module';
import creditsModule from '@/store/modules/credits-module';
import vendorModule from '@/store/modules/vendor-module';
import cardsModule from '@/store/modules/cards-module';
import externalAccountsModule from '@/store/modules/external-accounts-module';
import profileModule from '@/store/modules/profile-module';
import accountingModule from '@/store/modules/accounting-module';
import userSettingsModule from '@/store/modules/user-settings-module';
import partnersModule from '@/store/modules/partners-module';
import plaidWhitelistModule from '@/store/modules/plaid-whitelist-module';
import plaidProxyModule from '@/store/modules/plaid-proxy-module';
import heronPdfModule from '@/store/modules/heron-pdf-module';
import debtObligationsModule from '@/store/modules/debt-obligations-module';

import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';
import signupModule from './modules/signup-module';
import ownersModule from './modules/owners-module';
import selfserveModule from './modules/selfserve-module';
import onboardingModule from './modules/onboarding-module';
import manualReadOnlyAccountsModule from './modules/manual-read-only-accounts-module';
import googleSigninModule from './modules/google-signin-module';
import todoSectionModule from './modules/todo-section-module';
import prismicModule from './modules/prismic-module';
import paymentsOnboardingModule from './modules/payments-module';
import experimentsModule from './modules/experiments-module';
import clearcashOnboardingModule from './modules/clearcash-onboarding-module';
import productsModule from './modules/products-module';

const initialState = {
  // initialize an object to track request state for each api related action
  advances: {},
  adjudication: null,
  // used only to trigger the signup popup with some initial info
  signupStart: {
    triggeredAt: null,
    businessType: null,
    phone: null,
    email: null,
    businessAgeRange: null,
  },
  ecomRevenue: {},
  advanceFeesInfo: null,
  autocompleteEnabled: {},
  currentStateModule: '',
  showLegacyModal: false,
};

const store = initializeStore({
  state: initialState,
  actions,
  getters,
  mutations,
  modules: {
    uploads: uploadsModule,
    banking: bankingModule,
    contracts: contractsModule,
    connectedAccounts: connectedAccountsModule,
    credits: creditsModule,
    vendor: vendorModule,
    cards: cardsModule,
    externalAccounts: externalAccountsModule,
    profile: profileModule,
    accounting: accountingModule,
    signup: signupModule,
    owners: ownersModule,
    selfserve: selfserveModule,
    onboarding: onboardingModule,
    userSettings: userSettingsModule,
    manualReadOnlyAccounts: manualReadOnlyAccountsModule,
    partners: partnersModule,
    googleSignin: googleSigninModule,
    todoSections: todoSectionModule,
    prismic: prismicModule,
    paymentsOnboarding: paymentsOnboardingModule,
    experiments: experimentsModule,
    clearcash: clearcashOnboardingModule,
    products: productsModule,
    plaidWhitelist: plaidWhitelistModule,
    plaidProxy: plaidProxyModule,
    heronPdf: heronPdfModule,
    debtObligations: debtObligationsModule,
  },
  strict: process.env.NODE_ENV !== 'production',
});
window.store = store;

export default store;
