<template>
  <section :class="$style['dashboard-container']">
    <DashboardComponent />
  </section>
</template>

<script>
import { useMeta } from 'vue-meta';
import DashboardComponent from '@/pages/mca/dashboard/page-dashboard';

export default {
  setup() {
    useMeta({
      title: 'My advances | Clearco',
    });
  },
  components: {
    DashboardComponent,
  },
};
</script>

<style lang="less" module>
.dashboard-container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 @spacing-12;
}
</style>
