<template>
  <Message serverity="info" :closable="false">
    <h3>
      {{ name }}, {{ feature }} are legacy Clearco marketing and invetory
      products
    </h3>
    <p>
      Functionality is limited. All advance and transaction data in this page
      are associated with previous Clearco products. Navigate back to
      <router-link to="/">Funding</router-link>
      to fund your invoices and receipts.
    </p>
  </Message>
</template>

<script setup>
import Message from '@clearbanc/clear-components/message';

defineProps({
  name: String,
  feature: String,
});
</script>
