// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_DqJiR{width:100%;height:100%;overflow:auto}.wrapper_DqJiR table{display:table!important;border-spacing:0;border-collapse:collapse;border:0!important;width:100%;min-width:700px}.wrapper_DqJiR table thead{pointer-events:none}.wrapper_DqJiR table .row_KsZBc{border:0}.wrapper_DqJiR table .row_KsZBc:hover{background:#f7f7f7}.wrapper_DqJiR table .row_KsZBc .header-cell_IsDHP{padding:12px 6px!important;border:1px solid transparent;color:#333;text-transform:none;font-size:12px;font-weight:500;text-align:center}.wrapper_DqJiR table .row_KsZBc .header-cell_IsDHP.align-left_H48vB{text-align:left}.wrapper_DqJiR table .row_KsZBc .header-cell_IsDHP.align-right_NQlQZ{text-align:right}.wrapper_DqJiR table .row_KsZBc .header-cell_IsDHP.gerstner_M1bcY{padding:16px 24px;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-weight:700;font-size:14px;line-height:20px}.wrapper_DqJiR table .row_KsZBc .header-cell_IsDHP.bordered_OtSbi{border-bottom:1px solid #ceced0!important}.wrapper_DqJiR table .empty-state_hCFNk{height:300px;text-align:center;border:0;color:#a7a6a6;background:#f2f2f2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_DqJiR",
	"row": "row_KsZBc",
	"header-cell": "header-cell_IsDHP",
	"align-left": "align-left_H48vB",
	"align-right": "align-right_NQlQZ",
	"gerstner": "gerstner_M1bcY",
	"bordered": "bordered_OtSbi",
	"empty-state": "empty-state_hCFNk"
};
export default ___CSS_LOADER_EXPORT___;
