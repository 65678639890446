// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_ZrxUX{text-align:left;max-width:560px;margin:0 auto}.container_ZrxUX .list_eKUq_{list-style-type:decimal;padding-left:1rem}.container_ZrxUX .list_eKUq_ li~li{margin-top:1rem}.container_ZrxUX .google-button_JUnZf{border-radius:4px;max-width:400px}.container_ZrxUX .link_RAJLa{text-decoration:underline;color:#0d3685;cursor:pointer}.container_ZrxUX .input-fields_ayl2w{max-width:400px;margin:1.5rem 0}.container_ZrxUX .formats_V1ZLb{font-size:14px}.container_ZrxUX .formats_V1ZLb span{display:block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_ZrxUX",
	"list": "list_eKUq_",
	"google-button": "google-button_JUnZf",
	"link": "link_RAJLa",
	"input-fields": "input-fields_ayl2w",
	"formats": "formats_V1ZLb"
};
export default ___CSS_LOADER_EXPORT___;
