<template>
  <div ref="dropdownRef" :class="classes">
    <div
      :data-cy="kebabCase(name)"
      :class="$style.menu"
      :tabindex="disabled ? '-1' : '1'"
      @click="openMenu"
    >
      {{ label }}
      <ui-icon name="back" size="15" :class="$style.icon" />
    </div>
    <div
      :class="$style['option-wrapper']"
      :style="{ height: isOpen ? `${computedHeight}px` : '0px' }"
    >
      <div
        v-for="option in priorityOptions"
        :key="option.key"
        :class="optionClasses(option)"
        tabindex="0"
        :value="option.value"
        @click="onClick(option.value)"
        @keyup.enter="onClick(option.value)"
      >
        {{ option.name }}
      </div>
      <hr v-if="priorityOptions.length" />
      <div
        v-for="option in regularOptions"
        :key="option.key"
        :class="optionClasses(option)"
        tabindex="0"
        :value="option.value"
        @click="onClick(option.value)"
        @keyup.enter="onClick(option.value)"
      >
        {{ option.name }}
      </div>
    </div>
    <div v-if="isTouched && !isValid" :class="$style.message">
      {{ validationErrorMsg }}
    </div>
  </div>
</template>

<script>
import { UiIcon } from 'ui-components';
import kebabCase from '@/utils/kebabcase';
import { inputValidationSetup } from '@/composables/validation';

export default {
  emits: ['input', 'update:modelValue'],
  components: {
    UiIcon,
  },
  props: {
    name: String,
    label: String,
    required: Boolean,
    disabled: Boolean,
    options: Array,
    modelValue: {},
  },
  setup(props) {
    const { isValid, isTouched } = inputValidationSetup(props);
    return {
      isValid,
      isTouched,
    };
  },
  data() {
    return {
      isOpen: false,
      validationErrorMsg: '',
    };
  },
  computed: {
    classes() {
      return {
        [this.$style.wrapper]: true,
        [this.$style.disabled]: this.disabled,
        [this.$style.collapsed]: this.isOpen,
        [this.$style.error]: this.isTouched && !this.isValid,
      };
    },
    priorityOptions() {
      return this.options
        .filter((val) => val.priority)
        .sort((a, b) => a.priority - b.priority);
    },
    regularOptions() {
      return this.options.filter((val) => !val.priority);
    },
    selectedName() {
      const { name } =
        this.options.find((val) => val.value === this.modelValue) || {};

      return name;
    },
    computedHeight() {
      const length = this.priorityOptions.concat(this.regularOptions).length;

      return 42 * length < 169 ? 42 * length : 169;
    },
  },
  mounted() {
    window.addEventListener('click', this.handleClick);
  },
  unmounted() {
    window.removeEventListener('click', this.handleClick);
  },
  watch: {
    isTouched(val) {
      this.validate(val);
    },
    modelValue(val) {
      this.validate();
    },
  },
  methods: {
    validate(val) {
      const value = val || this.modelValue;
      // empty field validation
      if (this.required) {
        this.isValid = !!value;
        this.validationErrorMsg = 'This field is required';
      } else {
        this.isValid = true;
      }
    },
    openMenu() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
      }
    },
    onClick(val) {
      this.isOpen = false;
      this.$emit('input', val, this.name);
      this.validate(val);
    },
    optionClasses(option) {
      return {
        [this.$style.option]: true,
        [this.$style.disabled]: option.disabled,
        [this.$style.selected]: this.selectedName === option.name,
      };
    },
    handleClick(e) {
      const { dropdownRef } = this.$refs;

      if (dropdownRef && !dropdownRef.contains(e.target)) {
        this.isOpen = false;
      }
    },
    kebabCase,
  },
};
</script>

<style lang="less" module>
.wrapper {
  display: inline-block;
  position: relative;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: @font-size-15;
  height: 45px;
  min-height: 73px;
  line-height: 47px;
  text-align: left;
  flex: 1;

  @media only screen and (max-width: 800px) {
    width: 100%;
    margin: auto;
    padding: 0;
  }

  &.error {
    color: @color-red-new;
  }

  &.collapsed {
    height: auto;
    min-height: auto;
  }

  .menu {
    border: 0;
    border-radius: 0;
    padding: 17px 25px;
    color: @color-white;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    line-height: @font-size-15;
    min-width: 140px;
    -webkit-appearance: none;
    background-color: transparent;
    border-bottom: 1px solid @color-white;
    font-weight: 500;

    &:focus {
      outline: 0;
    }

    .error& {
      box-shadow: inset 0 0 0 1px @color-red-new;
      background: @color-light-red-new;
    }

    .icon {
      position: absolute;
      top: 15px;
      right: 0;
      transition: all 200ms ease;
      transform: rotate(-90deg);

      .collapsed& {
        transform: rotate(90deg);
      }
    }

    .disabled& {
      color: @color-gray;
      box-shadow: inset 0 0 0 1px @color-gray;
      pointer-events: none;
      cursor: default;
    }
  }

  .option-wrapper {
    overflow: scroll;
    position: absolute;
    top: 44px;
    left: 0;
    border-radius: 0;
    z-index: 3;
    transition: none;
    min-width: 100%;

    .collapsed& {
      transition: height 200ms ease;
      margin-bottom: @gutter-25;
      box-shadow: 0 9px 18px rgba(168, 172, 185, 0.62);
    }

    hr {
      margin: 0;
      padding: 0;
      border: 0;
      height: 1px;
      background: @color-lightgray-300;
    }

    .option {
      padding: 0 @gutter-15;
      width: 100%;
      z-index: 0;
      cursor: pointer;
      line-height: 42px;
      height: 42px;
      background: @color-white;
      color: @color-gray-new;
      border-top: 1px solid @color-lightgray-300;
      white-space: nowrap;
      min-width: 140px;

      &:first-child {
        border-top: none;
        padding-top: 2px;
      }

      &:hover {
        background: @color-lightgray-100;
      }

      &.disabled {
        color: @color-gray;
        pointer-events: none;
        cursor: pointer;
      }

      &.selected {
        background: @color-lightgray-100;
      }
    }
  }

  // message below core component
  .message {
    font-size: @font-size-10;
    font-family: 'Montserrat';
    margin-left: @gutter-10;
    margin-top: @gutter-10;
    line-height: 6px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: left;
    text-transform: none;
  }
}
</style>
