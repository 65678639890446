import { buildApiActions } from '@/utils/vuex-api-utils';
import { connectedAccountsRequest } from '@/utils/connected-accounts-request';
import { differenceInHours } from 'date-fns';
// const FIVETRAN_LAUNCH_DATE = new Date('06/19/2023');

export default {
  getters: {
    shopifyAccounts: (state, getters, rootState, rootGetters) =>
      rootGetters.externalAccounts.filter(
        (account) =>
          (account.accountType === 'ShopifyAccount' ||
            account.platform === 'shopify') &&
          !account.ignoreRevenueAt,
      ),
    showShopifyFivetranTile: (state, getters, rootState, rootGetters) => {
      // TODO: sc-166906 remove commented code once we're confident in shopify x fivetran connectiong
      // const hasDisconnectedLegacyShopifyAccount =
      //   !!getters.shopifyAccounts.find(
      //     (account) =>
      //       account.state === CONNECTION_STATUS.DISCONNECTED &&
      //       account.connectionDetails === null,
      //   );
      // const hasAccountsCreatedPreFivetranLaunch =
      //   !!getters.shopifyAccounts.find(
      //     (account) => new Date(account.createdAt) < FIVETRAN_LAUNCH_DATE,
      //   );
      // // Only users who did not have a shopify account connected before the FIVETRAN_LAUNCH_DATE
      // // or have a disconnected account via the old flow can connect via the legacy shopify tile
      // // Users who have had an exisiting shopify connection should connect via fivetran
      // return (
      //   hasAccountsCreatedPreFivetranLaunch &&
      //   !hasDisconnectedLegacyShopifyAccount
      // );

      // For Shopify app approval testing we need to be able to connect a native (non-fivetran) account
      // and show the checkmark but not have any further interactions.
      // users with a recently (48h) connected shopify native account must have originated from the app listing
      // they will be shown the shopify native tile with checkmark, but cannot click it
      // all other users (previous native acct, no acct, fivetran acct) will see the fivetran tile
      // see SC-170954
      const nativeConnections = getters.shopifyAccounts.filter(
        (acct) => !acct.connectionDetails,
      );

      const recentNativeConnections = nativeConnections.filter(
        (acct) => differenceInHours(new Date(), new Date(acct.createdAt)) < 48,
      );

      return recentNativeConnections.length === 0;
    },
  },
  ...buildApiActions({
    ADD_CONNECTED_ACCOUNT: {
      action: (ctx, payload) => ({
        method: 'post',
        interceptor: connectedAccountsRequest,
        url: `/api/v1/businesses/${ctx.rootGetters.businessId}/connections`,
        params: payload,
        returnResponse: true,
      }),
    },
    CONNECTED_ACCOUNT_CONFIGURE: {
      action: (ctx, payload) => ({
        method: 'patch',
        interceptor: connectedAccountsRequest,
        url: `/api/v1/businesses/${ctx.rootGetters.businessId}/connections/${payload.id}/actions/configure`,
        returnResponse: true,
      }),
    },
  }),
};
