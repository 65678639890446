export const PERSONA_TEMPLATE_NAMES = {
  BUSINESS: 'BUSINESS',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const PERSONA_TEMPLATES = {
  [PERSONA_TEMPLATE_NAMES.BUSINESS]: 'itmpl_zFgmL86gbThafPKgXuGmHSsi',
  [PERSONA_TEMPLATE_NAMES.INDIVIDUAL]: 'itmpl_BKZvZuUGTLNZN7FkEZUiwXMp',
};

export const PERSONA_STATUS = {
  COMPLETE: 'completed',
  CANCELED: 'cancelled',
  FAILED: 'failed',
  PENDING: 'pending',
  EXPIRED: 'expired',
  CREATED: 'created',
};

export const PERSONA_ENV = {
  PRODUCTION: 'env_yb2JyzUwEh4Y7pgrX2S8ZeP4',
  SANDBOX: 'env_JWuASUp8Z5qj4KxdxnvZC54M',
};

export const PERSONA_ENTITY = {
  BUSINESS: 'business',
  OWNER: 'owner',
  USER: 'user',
};

export const PERSONA_SCRIPT_ID = 'persona-script';
