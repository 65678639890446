// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container__sZwv{padding:0}.steps_wuk7V{bottom:auto}.title_bLmLg{color:currentColor;font-size:45px;line-height:45px;text-align:left;padding-bottom:10px}.subtitle_Aw0ix{color:gray;font-size:12px;text-align:left}.custom-modal_XXOQr div[class^=modal_]{min-height:400px;max-height:80%;height:auto;min-width:300px;max-width:750px;width:80%;overflow:auto}.custom-modal_XXOQr div[class^=modal_] div[class^=content_]{margin:0;max-width:100%}.custom-modal_XXOQr div[class^=modal_] div[class^=content_] div[class^=container_]{min-height:250px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;max-height:100%;margin-bottom:100px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container__sZwv",
	"steps": "steps_wuk7V",
	"title": "title_bLmLg",
	"subtitle": "subtitle_Aw0ix",
	"custom-modal": "custom-modal_XXOQr"
};
export default ___CSS_LOADER_EXPORT___;
