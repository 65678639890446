import analytics from '@/utils/analytics';
import * as LOCALES from 'date-fns/locale';
import moment from 'moment';

export const USER_SETTINGS = {
  LANG: 'en-us',
  FALLBACK_LANG: 'en-us',
};

export const SUPPORTED_LANGUAGE_LIST = [
  {
    name: () => 'English',
    value: 'en-us',
  },
  {
    name: () => 'Deutsch',
    value: 'de-de',
  },
];

export const SUPPORTED_LANGUAGES = {
  ENGLISH: 'en-us',
  GERMAN: 'de-de',
};

export const updateLanguage = async (store, payload) => {
  // Remove this condition once LanguageSwicher component is ready for production
  const language = payload.nextLanguage;
  if (
    language !== store.getters.selectedLanguage &&
    Object.values(SUPPORTED_LANGUAGES).includes(language)
  ) {
    if (store.getters.userIsLoggedIn) {
      await store.dispatchApiAction('UPDATE_USER_LANGUAGE', { language });
    } else {
      store.dispatch('setLanguage', language);
    }
    analytics.track('fe_toggle_language', {
      target: payload.target,
      previousLanguage: payload.previousLanguage,
      nextLanguage: payload.nextLanguage,
    });
  }
};

// for date-fns lib (using moment)
export const gettingDateTranslatedAccordingLocale = (locale) => {
  const defaultLocaleEnglish = moment().locale('en').format('MMMM DD, YYYY');
  if (!locale) return defaultLocaleEnglish;
  const splittingLocale = locale.split('-');
  return (
    moment().locale(splittingLocale[0]).format('MMMM DD, YYYY') ||
    defaultLocaleEnglish
  );
};

// for vuejs-datepicker lib
export const gettingLocaleForDatepickerLib = (locale) => {
  const defaultLocaleEnglish = LOCALES.en;
  if (!locale) return defaultLocaleEnglish;
  const splittingLocale = locale.split('-');
  const gettingLocaleFromLib =
    LOCALES[splittingLocale[0] + splittingLocale[1].toUpperCase()] ||
    LOCALES[splittingLocale[0]];
  return gettingLocaleFromLib || defaultLocaleEnglish;
};
