// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_cUu2h{margin:20px auto 0}.form_DGow_{margin-top:2rem}.form_DGow_:first-child{margin-top:0}.modal-message_RLNOU{max-width:280px;margin:0}.modal-message_RLNOU a{text-decoration:underline;color:#7f30ff;cursor:pointer}.modal-message--error_NW9Pp{color:#df0000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_cUu2h",
	"form": "form_DGow_",
	"modal-message": "modal-message_RLNOU",
	"modal-message--error": "modal-message--error_NW9Pp"
};
export default ___CSS_LOADER_EXPORT___;
