<script setup>
import { useStore } from 'vuex';
import { CONTACT_SUPPORT_MSG } from '@/data/error-messages';
import Message from '@clearbanc/clear-components/message';

// -----
// Setup
// -----

const store = useStore();
const props = defineProps({
  errorMessage: {
    type: String,
  },
});
</script>

<template>
  <Message :class="$style.message" severity="error" :closable="false">
    <p>
      {{
        props.errorMessage ||
        CONTACT_SUPPORT_MSG(store.getters.contactDetails.number)
      }}
    </p>
  </Message>
</template>

<style module scoped>
.message {
  margin: 40px 0 0;
}
</style>
