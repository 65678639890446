import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  state: {},
  getters: {
    /**
     * Users who are on a waitlist are blocked from uploading their invoices
     * This getter helps us determine whether users should have this feature disabled
     */
    disableInvoiceUpload: (state, getters, rootState, rootGetters) => {
      return rootGetters.isBnplWaitlisted;
    },
  },
  ...buildApiActions({}),
};
