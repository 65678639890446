// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_BFgO7{max-width:90%;margin:0 auto}@media screen and (min-width:500px){.wrapper_BFgO7{max-width:85%}}.section_UUl3n{padding:50px 0 60px}.section_UUl3n+.section_UUl3n{border-top:1px solid #e2e2e2;box-shadow:inset 0 1px 0 #fff}.underline_Y_MxX{-webkit-text-decoration-line:underline;text-decoration-line:underline}.wrapper_BFgO7 h2.heading-secondary_sQFkB{color:#111;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:28px;font-style:normal;font-weight:700;line-height:150%;margin:-10px 0 36px 0;position:relative}.heading-secondary__disclaimer_m5UDu{color:#767676;transform:translateX(3px);display:inline-block}.disclaimer_Kfzvn{color:#767676;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:14px;font-style:normal;font-weight:400;line-height:150%;margin:0}.toggle_TTWWG{background:#fff;border-radius:25px;display:inline-flex;position:relative;margin-bottom:37px}.toggle_TTWWG:before{position:absolute;top:0;left:0;width:50%;height:100%;background:#7f30ff;content:\"\";border-radius:25px;transform:translateZ(-1px);transition:transform .6s cubic-bezier(.76,0,.24,1),background .2s}.toggle_TTWWG:hover:before{background:#5b00f0}.toggle--right_nMisR:before{transform:translateX(100%)}.toggle__label_UlQO9{color:#111;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:18px;font-style:normal;font-weight:400;line-height:1;padding:16px 40px;transform-style:preserve-3d;transition:color .6s}.toggle__label_UlQO9:first-child{color:#fff}.toggle--right_nMisR .toggle__label_UlQO9:first-child{color:#111}.toggle--right_nMisR .toggle__label_UlQO9:last-child{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_BFgO7",
	"section": "section_UUl3n",
	"underline": "underline_Y_MxX",
	"heading-secondary": "heading-secondary_sQFkB",
	"heading-secondary__disclaimer": "heading-secondary__disclaimer_m5UDu",
	"disclaimer": "disclaimer_Kfzvn",
	"toggle": "toggle_TTWWG",
	"toggle--right": "toggle--right_nMisR",
	"toggle__label": "toggle__label_UlQO9"
};
export default ___CSS_LOADER_EXPORT___;
