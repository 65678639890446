<template>
  <component
    :is="tagType"
    :value="optionValue"
    :selected="optionSelected"
    :disabled="rootDisabled"
  >
    <template v-if="parentType === 'radio'">
      <input
        class="form-input-inline"
        type="radio"
        :value="modelValue"
        :name="this.$parent.formInputId"
        :disabled="computedDisabled"
        :checked="modelValue === parentValue"
        :aria-label="modelValue + ' radio input'"
        @focus="onFocus"
        @blur="onBlur"
        @change="onChange"
      />
      <slot>{{ modelValue }}</slot>
    </template>
    <template v-if="parentType === 'multi-checkbox'">
      <input
        class="form-input-inline"
        type="checkbox"
        :value="modelValue"
        :disabled="computedDisabled"
        :checked="parentValue && parentValue.indexOf(modelValue) > -1"
        :aria-label="modelValue + ' checkbox'"
        @focus="onFocus"
        @blur="onBlur"
        @change="onMultiCheckboxChange"
      />
      <slot>{{ modelValue }}</slot>
    </template>
    <template v-else-if="parentType === 'dropdown'">
      <slot>{{ modelValue }}</slot>
    </template>
  </component>
</template>

<script>
import _ from 'lodash';

export default {
  emits: ['input', 'update:modelValue'],
  props: {
    modelValue: {},
    disabled: Boolean,
  },
  inject: { formParentDisabled: { default: false } },
  computed: {
    computedDisabled() {
      if (this.disabled) return true;
      return _.isFunction(this.formParentDisabled) && this.formParentDisabled();
    },
    rootDisabled() {
      // need to put disabled on the root element for a dropdown option
      return this.computedDisabled && this.tagType === 'option';
    },
    parentType() {
      return this.$parent.type;
    },
    parentValue() {
      return this.$parent.modelValue;
    },
    tagType() {
      if (this.parentType === 'radio') return 'label';
      if (this.parentType === 'multi-checkbox') return 'label';
      if (this.parentType === 'dropdown') return 'option';
      const errorMessage =
        'Unsupported parent type -- This component must live inside a FormInput with type = radio|multi-checkbox|dropdown';
      throw Error(errorMessage);
    },
    optionValue() {
      if (this.tagType === 'option') {
        if (this.modelValue === undefined) return '_null_';
        if (this.modelValue === null) return '_null_';
        return this.modelValue.toString();
      }
      return undefined;
    },
    optionSelected() {
      if (this.tagType === 'option' && this.modelValue === this.parentValue)
        return true;
      return undefined;
    },
  },
  methods: {
    onBlur(event) {
      this.$parent.onBlur(event);
    },
    onFocus(event) {
      this.$parent.onFocus(event);
    },

    // we use this.modelValue instead of event.target.value
    // to handle booleans correctly, otherwise we get "true"
    onChange() {
      this.$parent.$emit('input', this.modelValue);
    },
    onMultiCheckboxChange() {
      const existingValue = this.parentValue || [];
      const valIndex = existingValue.indexOf(this.modelValue);
      // we must emit with a new array so Vue notices the change
      if (valIndex >= 0) {
        const valCopy = [].concat(existingValue);
        valCopy.splice(valIndex, 1);
        this.$parent.$emit('input', valCopy.length > 0 ? valCopy : null);
        this.$parent.$emit(
          'update:modelValue',
          valCopy.length > 0 ? valCopy : null,
        );
      } else {
        this.$parent.$emit('input', existingValue.concat(this.modelValue));
        this.$parent.$emit(
          'update:modelValue',
          existingValue.concat(this.modelValue),
        );
      }
    },
  },
};
</script>

<style lang="less">
.form-input-inline {
  margin-right: 8px;
}
</style>
