<template>
  <div v-click-outside="closeMenu" :class="classes">
    <fieldset
      :disabled="disabled"
      :class="$style['dropdown-container']"
      :data-cy="kebabCase(name)"
    >
      <legend v-if="showLegend" :class="$style.legend">
        {{ legend }}
      </legend>
      <div
        tabindex="0"
        :class="$style['selected-option-container']"
        data-cy="selected-option"
        @focus="setFocusStyles(true)"
        @blur="setFocusStyles(false)"
        @click="toggleMenu"
        @keyup.enter="toggleMenu"
        @keyup.up="changeFocus(false)"
        @keyup.left="changeFocus(false)"
        @keyup.down="changeFocus(true)"
        @keyup.right="changeFocus(true)"
        @keydown.tab="closeMenu"
      >
        <div
          :class="[$style['selected-text'], $style['truncated']]"
          data-cy="selected-option-value"
        >
          {{ selectedOption }}
        </div>
        <BaseIcon
          icon-name="Chevron"
          :class="$style.chevron"
          :width="25"
          :height="15"
        />
      </div>
      <div v-show="isOpen" ref="menu" :class="$style['options-menu']">
        <div
          v-for="(option, index) in options"
          :key="index"
          :class="$style.option"
          :tabindex="-1"
          :data-cy="kebabCase(option.name())"
          @click="selectOption(option)"
          @keyup.enter="selectOption(option)"
          @keyup.up="changeFocus(false)"
          @keyup.left="changeFocus(false)"
          @keyup.down="changeFocus(true)"
          @keyup.right="changeFocus(true)"
        >
          {{ option.name() }}
        </div>
      </div>
      <div v-if="shouldDisplayErrors" :class="$style.message">
        {{ errorMessage }}
      </div>
    </fieldset>
  </div>
</template>

<script>
import { inject } from 'vue';
import kebabCase from '@/utils/kebabcase';
import BaseIcon from '@/components/icons/base-icon';
import { inputValidationSetup } from '@/composables/validation';

const optionsValidator = (options) => {
  if (!(options instanceof Array)) {
    return false;
  }
  for (const option of options) {
    if (
      !(typeof option.value === 'string' && typeof option.name() === 'string')
    ) {
      return false;
    }
  }
  return true;
};
export default {
  emits: ['input', 'update:modelValue'],
  components: {
    BaseIcon,
  },
  props: {
    name: { type: String },
    options: {
      validator: optionsValidator,
    },
    placeholder: {
      type: String,
      required: true,
    },
    eyebrow: {
      type: String,
      default: '',
    },
    modelValue: { required: true },
    errorMessage: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('common.pleaseSelectADropDown');
      },
    },
    error: Boolean,
    required: {
      type: Boolean,
      default: true,
    },
    validateOnBlur: { type: Boolean, default: true },
    initialFocusIndex: { type: Number, default: 0 },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isValid, isTouched } = inputValidationSetup(props);
    return {
      isValid,
      isTouched,
    };
  },
  data() {
    return {
      isOpen: false,
      showLegend: false,
      focusIndex: this.initialFocusIndex,
      isFocused: false,
    };
  },
  computed: {
    classes() {
      return {
        [this.$style.container]: true,
        [this.$style.error]: this.shouldDisplayErrors,
        [this.$style.active]:
          this.isOpen || !!this.modelValue || this.isFocused,
        [this.$style['chevron-active']]: this.isOpen,
      };
    },
    selectedOption() {
      const { name } =
        this.options.find((val) => val.value === this.modelValue) || {};
      if (name) {
        return name();
      }
      return this.isOpen ? this.$t('common.select') : this.placeholder;
    },
    shouldDisplayErrors() {
      return this.error || (this.isTouched && !this.isValid);
    },
    legend() {
      return this.eyebrow || this.placeholder;
    },
  },
  watch: {
    modelValue(val) {
      this.validate();
      if (val) this.showLegend = true;
    },
    isTouched(val) {
      this.validate(val);
    },
  },
  mounted() {
    if (this.modelValue) this.showLegend = true;
  },
  methods: {
    validate(val) {
      const value = val || this.modelValue;
      // empty field validation
      if (this.required) {
        this.isValid = !!value;
      } else {
        this.isValid = true;
      }
    },
    setFocusStyles(focus) {
      this.isFocused = focus;
      this.showLegend = focus;
    },
    changeFocus(moveFocusDown) {
      if (!this.isOpen) {
        return;
      }
      if (moveFocusDown && this.focusIndex < this.options.length - 1) {
        this.focusIndex += 1;
      } else if (!moveFocusDown && this.focusIndex > 0) {
        this.focusIndex -= 1;
      }
      this.$refs.menu.children[this.focusIndex].focus();
    },
    toggleMenu() {
      this.showLegend = true;

      if (this.isOpen) {
        this.closeMenu();
      } else {
        this.isOpen = true;
        this.$refs.menu.children[this.focusIndex].focus();
      }
    },
    closeMenu() {
      if (!this.modelValue) {
        this.showLegend = false;
      }
      this.isOpen = false;
    },
    selectOption(option) {
      this.closeMenu();
      this.$emit('input', option.value, this.name);
      this.$emit('update:modelValue', option.value);
    },
    kebabCase,
  },
};
</script>

<style lang="less" module>
.container {
  position: relative;
  font-family: 'Montserrat';
  font-weight: 400;
  color: @medium-gray;
  min-height: 40px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  text-align: left;
  .dropdown-container {
    position: absolute;
    border-radius: 4px;
    border: 1.5px solid @color-gray;
    height: 43px;
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    min-width: 0;
    flex: 1;
    .legend {
      position: absolute;
      font-size: 11px;
      padding: 0 5px;
      display: inline;
      margin-left: 15px;
      line-height: 10px;
      top: -6px;
      color: @color-black-new;
      background-color: @color-white;
      z-index: 1;
    }
    .selected-option-container {
      display: flex;
      justify-content: space-between;
      height: 40px;
      max-width: 100%;
      cursor: pointer;
      user-select: none;
      &:focus-visible {
        outline: none;
      }
      .selected-text {
        padding: 8px 0 8px 20px;
      }
      .chevron {
        height: 20px;
        width: 20px;
        margin: 12px 10px 0 0;
        transition: all 0.2s;
        .chevron-active& {
          transform: translate(-5px, -5px) rotate(180deg);
        }
      }
    }
    .truncated {
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
    }

    .options-menu {
      position: absolute;
      min-width: 180px;
      width: 100%;
      max-height: 250px;
      overflow: scroll;
      z-index: 5;
      background: @color-white;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      border-radius: 0 0 6px 6px;
      cursor: pointer;
      user-select: none;
      animation: slideOpen 200ms ease-in-out;
      transform-origin: top center;

      .option {
        font-size: 16px;
        line-height: 24px;
        padding: 10px 20px 6px;

        &:focus-visible,
        &:hover {
          outline: none;
          background: @medium-gray;
          color: @color-white;
        }
      }
    }
  }
  .message {
    padding-top: 5px;
    margin-bottom: 20px;
    font-size: @font-size-12;
    margin-left: 2px;
    font-family: 'Montserrat';
    line-height: @font-size-12;
    text-align: left;
  }
  &:focus-visible,
  &.active {
    color: @color-black-new;
    fieldset {
      border-color: @color-black-new;
    }
    legend {
      color: @color-black-new;
    }
    ::-webkit-input-placeholder {
      color: @color-black-new;
    }
  }
  &.error {
    fieldset {
      border-color: @color-red;
    }
    .legend {
      color: @color-red;
    }
    .message {
      color: @color-red;
    }
  }
}
@keyframes slideOpen {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
</style>
