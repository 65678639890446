<template>
  <div>
    <div :class="$style.modalContext">
      {{ $t('vendorsPage.vendorCurrency.beneficiary') }}
    </div>
    <custom-fieldset>
      <UiDropdown
        :external-label="$t('vendorsPage.vendorCurrency.paymentCurrency')"
        name="currencyCode"
        data-cy="payment-currency"
        :label="$t('common.selectCurrency')"
        :error-msg="$t('common.errors.mandatory')"
        :options="currencyDropdownOptions"
        :model-value="selectedVendor.currencyCode"
        size="small"
        required
        @input="setVendorProps"
      />
      <UiDropdown
        :external-label="$t('vendorsPage.vendorCurrency.beneficiaryCountry')"
        name="beneficiaryAddress.country"
        data-cy="beneficiary-country"
        :label="$t('common.selectCountry')"
        :error-msg="$t('common.errors.mandatory')"
        :options="countryOptions"
        :model-value="selectedVendor.beneficiaryAddress.country"
        size="small"
        required
        @input="setVendorProps"
      />
      <UiDropdown
        :external-label="$t('common.bankInfo.bankCountry')"
        name="bankAddress.country"
        data-cy="bank-country"
        :label="$t('common.selectCountry')"
        :error-msg="$t('common.errors.mandatory')"
        :options="countryOptions"
        :model-value="selectedVendor.bankAddress.country"
        size="small"
        required
        @input="setVendorProps"
      />
    </custom-fieldset>
    <UiButton :text="$t('common.buttons.back')" @click="$emit('prev-tab')" />
    <UiButton
      :text="
        getRequiredVendorFieldsRequest.isPending
          ? $t('common.buttons.loading')
          : $t('common.buttons.next')
      "
      data-cy="next-button"
      :disabled="getRequiredVendorFieldsRequest.isPending"
      @click="getRequiredFields"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { validateChildren } from '@/composables/validation';
import { UiDropdown, UiButton } from 'ui-components';

import { SUPPORTED_CURRENCIES } from '@/data/currency-code-with-names';
import { COUNTRY_OPTIONS } from '@/data/country-code-with-names';

import CustomFieldset from '../FieldsetCustom';

export default {
  components: {
    UiButton,
    UiDropdown,
    CustomFieldset,
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  computed: {
    ...mapGetters(['selectedVendor', 'requiredVendorFieldsWithValidation']),
    ...mapRequestStatuses({
      getRequiredVendorFieldsRequest: 'GET_REQUIRED_VENDOR_FIELDS',
    }),
    countryOptions() {
      return COUNTRY_OPTIONS;
    },
    currencyDropdownOptions() {
      return Object.entries(SUPPORTED_CURRENCIES).map((currency, idx) => {
        return {
          key: idx,
          value: currency[0],
          name: currency[1],
        };
      });
    },
  },
  methods: {
    setVendorProps(val, name) {
      this.$store.dispatch('UPDATE_SELECTED_VENDOR', { path: name, val });
    },
    async getRequiredFields() {
      const error = await this.hasError();
      if (error) return;
      await this.$store.dispatchApiAction('GET_REQUIRED_VENDOR_FIELDS', {
        bankCountry: this.selectedVendor.bankAddress.country,
        beneficiaryCountry: this.selectedVendor.beneficiaryAddress.country,
        currencyCode: this.selectedVendor.currencyCode,
      });
      const fieldPaths = {
        beneficiaryPostcode: 'beneficiaryAddress.postalCode',
        beneficiaryStateOrProvince: 'beneficiaryAddress.state',
        beneficiaryCity: 'beneficiaryAddress.city',
        cnaps: 'cnaps',
        bicSwift: 'swiftCode',
        aba: 'routingNumber',
        acctNumber: 'accountNumber',
        iban: 'iban',
        sortCode: 'sortCode',
        branchCode: 'branchCode',
        bsbCode: 'bsbCode',
        ifsc: 'ifsc',
        clabe: 'clabe',
      };
      const requiredFields = Object.keys(
        this.requiredVendorFieldsWithValidation,
      );
      const fieldsToErase = Object.keys(fieldPaths).filter(
        (field) => !requiredFields.includes(field),
      );
      const pathsToErase = [];
      fieldsToErase.forEach((field) => {
        pathsToErase.push(fieldPaths[field]);
        // CurrencyCloud uses aba for both ACH & wire routing numbers
        // The two fields are coupled at the moment
        if (field === 'aba') pathsToErase.push('wireRoutingNumber');
      });
      pathsToErase.forEach((path) => {
        this.$store.dispatch('UPDATE_SELECTED_VENDOR', {
          path,
          val: null,
        });
      });
      this.$emit('next-tab');
    },
  },
};
</script>
<style lang="less" module>
.modalContext {
  color: black;
  padding-bottom: 30px;
  text-align: center;
  font-weight: bold;
}
</style>
