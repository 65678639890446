// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_KSZFQ{text-align:left;max-width:560px;margin:0 auto}.container_KSZFQ ol{margin-top:0}.container_KSZFQ ol li{padding-left:5px}.container_KSZFQ a{text-decoration:underline;color:#0d3685;cursor:pointer}.container_KSZFQ span{font-weight:500}.container_KSZFQ button{margin:0 0 0 auto;display:block}@media only screen and (max-width:500px){.container_KSZFQ ol{-webkit-padding-start:10px;padding-inline-start:10px;margin-top:0;margin-bottom:25px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_KSZFQ"
};
export default ___CSS_LOADER_EXPORT___;
