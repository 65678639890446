<template>
  <div :class="$style.container">
    <p>
      If you quit now, your data will be lost and you will have to reapply to
      extend your payment terms.
    </p>
    <div :class="$style['exit-bottom-action']">
      <ButtonLinkContainer
        text="Continue"
        :link-props="linkProps"
        show-link
        @nextClick="$emit('giveUpExit')"
        @linkClick="$emit('confirmExit')"
      />
    </div>
  </div>
</template>

<script>
import ButtonLinkContainer from '@/components/buttons/ButtonLinkContainer';

export default {
  components: {
    ButtonLinkContainer,
  },
  props: {
    amountCents: {
      type: Number,
    },
  },
  computed: {
    linkProps() {
      return {
        text: 'Discard my application',
      };
    },
  },
};
</script>

<style lang="less" module>
.container {
  padding: 0;
  p {
    position: relative;
    font-family: @gerstner-font;
    font-size: 16px;
    text-align: center;
    margin: 0;
    @media only screen and (max-width: 500px) {
      text-align: left;
    }
  }
}

.exit-bottom-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
  div[class^='cta-container'] {
    max-width: unset;
  }
  a[class^='link'] {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #297fe3;
  }
  button[class^='button'] {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    width: 197px;
    background: #000;
    border-radius: 4px;
  }
  div[class^='button-text'] {
    @media only screen and (max-width: 800px) {
      white-space: no-wrap;
      min-width: 148px;
    }
  }
}
</style>
