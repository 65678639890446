// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_JrvNf{position:relative;display:flex;max-width:800px;margin:20px auto;padding:20px;font-family:Montserrat;font-weight:400;font-size:14px;line-height:20px;text-align:left}.container_JrvNf.success_qyTAO{color:#666;background-color:#e6f5f4}.container_JrvNf.error_7WI4a{color:#666;background-color:#ffe8f1}@media only screen and (max-width:500px){.container_JrvNf{display:block;margin-left:-20px;margin-right:-20px}}.wrapper_dmqNQ{display:flex}.wrapper_dmqNQ.small_DCBeG{align-items:center;margin:auto}.close-icon_WQPNa{position:absolute;top:7px;right:10px;width:100%;max-width:10px;height:15px}.icon_O2yh1{display:block;width:100%;max-width:35px;height:25px;margin-right:10px}@media only screen and (max-width:500px){.icon_O2yh1{margin-bottom:8px}}.msg_AxRXj p:first-child{margin-top:0}@media screen and (max-width:500px){.error-container_jBmBe{margin-left:-10px;margin-right:-10px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_JrvNf",
	"success": "success_qyTAO",
	"error": "error_7WI4a",
	"wrapper": "wrapper_dmqNQ",
	"small": "small_DCBeG",
	"close-icon": "close-icon_WQPNa",
	"icon": "icon_O2yh1",
	"msg": "msg_AxRXj",
	"error-container": "error-container_jBmBe"
};
export default ___CSS_LOADER_EXPORT___;
