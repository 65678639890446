<template>
  <component :is="componentType" :to="routerLinkTo" :class="classes">
    <header
      :class="this.$style['header']"
      data-cy="todo-header"
      @click="headerClickHandler"
    >
      <icon
        v-if="['star', 'regular/star', 'times-circle'].includes(statusIcon)"
        :name="statusIcon"
        :class="[
          $style['status-icon'],
          $style['status-icon--theme-star'],
          iconClass,
        ]"
      />
      <ui-round-icon
        v-else
        :name="statusIcon"
        size="small"
        icon-size="small"
        :class="iconClass"
      />
      <div :class="this.$style['text']" :data-cy="kebabCase(title + ' header')">
        <slot v-if="theme === 'flat-white'" name="title">
          <span v-html="title"></span>
        </slot>
        <h4 v-else :class="titleClasses">
          <slot name="title"> <span v-html="title"></span></slot>
        </h4>
        <span v-if="!!label" :class="this.$style['label']">
          <slot name="label"> {{ label }}</slot>
        </span>

        <div :class="this.$style['details']">
          <slot v-if="!isOpen" name="closed-details" />
          <slot name="details">
            <template v-if="details">
              <span v-if="_isString(details)">{{ details }}</span>
              <ul v-else-if="_isArray(details) && details.length">
                <li v-for="detail in details" :key="detail">
                  {{ detail }}
                </li>
              </ul>
            </template>
          </slot>
        </div>
      </div>
      <BaseIcon
        v-if="actionIcon === 'Chevron'"
        icon-name="Chevron"
        :class="actionIconClass"
        :width="16"
        :height="9"
      />
      <icon
        v-else-if="actionIcon"
        :class="actionIconClass"
        :name="actionIcon"
      />
    </header>
    <transition-group
      :enter-active-class="$style['slide-open']"
      :leave-active-class="$style['slide-open']"
      :enter-from-class="$style['slide-close']"
      :leave-to-class="$style['slide-close']"
    >
      <div v-if="isOpen" :class="this.$style['content']">
        <hr v-if="theme === 'white' && !bottomBorder && !topBorder" />
        <slot />
      </div>
      <div v-if="isOpen" :class="this.$style['sub-content']">
        <slot name="sub-content" />
      </div>
    </transition-group>
  </component>
</template>

<script>
import _ from 'lodash';
import { UiRoundIcon } from 'ui-components';
import kebabCase from '@/utils/kebabcase';
import BaseIcon from '@/components/icons/base-icon';
import { validateChildren } from '@/composables/validation';

export default {
  components: {
    UiRoundIcon,
    BaseIcon,
  },
  props: {
    openDropdown: { type: Boolean, default: false },
    name: String,
    pending: Boolean,
    complete: Boolean,
    optional: Boolean,
    error: Boolean,
    title: String, // can accept rawHtml string
    label: String,
    details: [Array, String],
    linkTo: [String, Object], // passed to router-link props
    actionType: String,
    icon: String,
    clickAction: Function,
    disabled: Boolean,
    theme: String,
    hover: String,
    topBorder: Boolean,
    bottomBorder: Boolean,
    statusIconSize: { default: 'large', type: String },
    modifiedTitle: { default: false, type: Boolean },
    requireComplete: { default: false, type: Boolean },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return { isOpen: !!this.openDropdown };
  },
  computed: {
    componentType() {
      if (this.linkTo) return 'router-link';
      return 'div';
    },
    routerLinkTo() {
      if (!this.linkTo) return false;
      return typeof this.linkTo === 'string'
        ? { name: this.linkTo }
        : { ...this.linkTo };
    },
    statusIcon() {
      // pass in an icon to show it
      if (this.icon) return this.icon;
      if (this.actionType === 'add') return 'plus';
      // otherwise use error/complete to show a status
      if (this.error) return 'exclamation';
      if (this.pending) return 'exclamation';
      if (this.complete) return 'checkmark';
      if (this.optional) return 'minus';
      return null;
    },
    actionIcon() {
      if (this.linkTo) return 'external-link-square-alt';
      if (this.actionType === 'call') return 'phone';
      if (this.actionType === 'fix') return 'wrench';
      if (this.actionType === 'modal') return 'angle-right';
      if (this.expandable && !this.disabled) return 'Chevron';
      return null;
    },
    computedTheme() {
      if (this.disabled) return 'disabled';
      if (this.pending) return 'yellow';
      if (this.complete) return 'green';
      if (this.error) return 'red';
      // if (this.actionType === 'add') return 'add';
      return 'navy';
    },
    iconClass() {
      return {
        [this.$style['status-icon']]: true,
        [this.$style.shrink]: this.statusIconSize === 'small',
        [this.$style[`status-icon--theme-${this.computedTheme}`]]: true,
        [this.$style['status-icon--theme-hollow']]: !this.statusIcon,
        [this.$style['icon--times-circle']]: this.icon === 'times-circle',
      };
    },
    actionIconClass() {
      return {
        [this.$style['arrow-icon']]: true,
        [this.$style['arrow-icon-circle']]:
          this.theme === 'white' && !this.bottomBorder,
      };
    },
    classes() {
      if (this.disabled) {
        return {
          [this.$style['todo-section']]: true,
          [this.$style['todo-section--theme-disabled']]: true,
        };
      }
      return {
        [this.$style['todo-section']]: true,
        [this.$style['is-open']]: this.isOpen,
        [this.$style[`todo-section--theme-${this.computedTheme}`]]: !this.theme,
        [this.$style[`todo-section--theme-${this.theme}`]]: this.theme,
        [this.$style['border-top']]: this.topBorder,
        [this.$style['border-bottom']]: this.bottomBorder,
      };
    },
    titleClasses() {
      return {
        [this.$style.title]: true,
        [this.$style.unbolded]: this.modifiedTitle,
      };
    },
    expandable() {
      if (this.clickAction) return false;
      return true;
    },
  },
  watch: {
    openDropdown(newVal) {
      this.isOpen = newVal;
    },
  },
  methods: {
    _isString: _.isString,
    _isArray: _.isArray,
    async headerClickHandler() {
      const formIncomplete = await this.isFormIncomplete();
      if (formIncomplete && this.requireComplete) {
        return;
      }
      if (this.clickAction) this.clickAction();
      else {
        if (!this.isOpen) {
          this.$emit('open-toggler');
        }
        this.isOpen = !this.isOpen;

        if (this.name) {
          this.$store.commit('TOGGLE_TODO_SECTION', {
            name: this.name,
            isOpen: this.isOpen,
          });
        }
      }
    },
    toggleOpen() {
      this.headerClickHandler();
    },
    async isFormIncomplete() {
      const error = await this.hasError();
      return error;
    },
    kebabCase,
  },
};
</script>

<style lang="less" module>
.todo-section {
  padding: 4px;
  margin-bottom: 10px;
  border-radius: 3px;
  display: block;
  font-family: Montserrat !important;

  .header {
    padding: 20px 10px;
    display: flex;
    align-items: center;
    position: relative;
    color: white;

    @media (hover: hover) {
      &:hover {
        background: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
    }
  }

  .text {
    flex: 1 0 0;
    overflow-x: hidden;
  }

  .title {
    font-family: Montserrat;
    color: currentColor;
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    margin-right: @gutter-12;
    &.unbolded {
      font-weight: 400;
      margin-right: 0;
      width: 105%;
    }
    // Allows for using <br/> tags in the title prop for mobile screens
    & br {
      display: none;
    }

    @media only screen and (max-width: 760px) {
      font-size: 16px;
      display: block;

      & br {
        display: inline;
      }
    }
  }

  .label {
    color: @color-green-light;
    background: @color-teal-light;
    border: 1px solid;
    font-weight: 800;
    line-height: 16px;
    font-size: 12px;
    border-radius: 10px;
    padding: @gutter-5;

    @media only screen and (max-width: 300px) {
      font-size: 9px;
    }
  }

  .details {
    font-size: 14px;
    line-height: 1.5em;

    ul {
      margin: 5px;
      padding-left: 20px;
    }
  }

  .status-icon {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    flex: 0 0 1;
    border: 1px solid @color-white;
    color: @color-white;
    background: @color-white;

    &.status-icon--theme-hollow {
      background: rgba(0, 0, 0, 0);
    }
  }
  div[class^='round-icon'].status-icon {
    line-height: 40px;
  }

  .arrow-icon {
    width: 40px;
    height: 40px;
    flex: 0 0 1;
    margin-left: 10px;
    transition: all 0.25s;
    padding: 10px;
  }

  .arrow-icon-circle {
    border-radius: 60px;
    @media only screen and (min-width: 500px) {
      background: @self-serve-nav-bar-gray;
    }
  }

  .content {
    background: white;
    padding: 0 10px 10px;
    overflow: hidden;
    line-height: 24px;

    p {
      margin-top: 0;
    }

    b {
      font-weight: 500;
    }

    > .form-row:first-child {
      border: none;
    }

    .form-row {
      border: none;
    }

    hr {
      border: 1px solid rgba(0, 0, 0, 0.5);
      margin: 0 0 20px;
      width: 100%;
      height: 0;
    }
  }

  .sub-content {
    background: white;
    overflow: visible;
  }

  &.is-open {
    // have to be specific to handle if these are nested
    > .header > .arrow-icon {
      transform: scaleY(-1);
    }
    > .header > .arrow-icon-circle {
      @media only screen and (max-width: 500px) {
        background: @self-serve-nav-bar-gray;
      }
    }
  }

  // Colors / Themes
  &.todo-section--theme-navy {
    background: @navy;
    .status-icon {
      color: @navy;
    }
  }

  &.todo-section--theme-white {
    background: @color-white;
    color: @color-black;
    border: 1px solid @color-lightgray-400;

    div[class^='form-row'] {
      border-top: none;
    }

    .header {
      color: @color-black;
    }

    .status-icon {
      border: 1px solid @dark-shadow-gray;
      background-color: @white;
      color: @dark-shadow-gray;
      margin-right: 20px;
      width: 40px;
      height: 40px;

      &.shrink {
        width: 30px;
        height: 30px;
        padding: 5px;
        @media only screen and (max-width: 500px) {
          margin-right: 5px;
        }
      }
      &.status-icon--theme-green {
        background-color: @success-green;
        color: @white;
        border: none;
      }
      &.status-icon--theme-yellow {
        background-color: @yellow;
        color: @white;
        border: none;
      }
      &.status-icon--theme-red {
        background-color: @error-bright-red;
        color: @white;
        border: none;
      }
      &.status-icon--theme-black {
        background-color: @black;
        color: @white;
        border: none;
      }
      &.status-icon--theme-star {
        background: none;
        color: @black;
        border: none;
      }
      &.icon--times-circle {
        color: @error-bright-red;
      }
    }
  }

  &.todo-section--theme-flat-white {
    &:extend(.todo-section--theme-white all);

    border: 1px solid @color-black !important;
    padding: unset;

    &.border-bottom {
      border-bottom: 1px solid @color-black;
      border-radius: 0;
    }

    .content {
      border-top: 1px solid @color-black;
    }
  }

  &.todo-section--theme-black {
    background: @color-black;
    .header {
      color: @color-white !important;

      @media (hover: hover) {
        &:hover {
          background: rgba(255, 255, 255, 0.2);
          cursor: pointer;
        }
      }
    }
    .status-icon {
      color: @black;
    }
  }

  &.todo-section--theme-green {
    background: @green;
    .status-icon {
      color: @green;
    }
  }

  &.todo-section--theme-yellow {
    background: #daa601;
    .status-icon {
      color: #daa601;
    }
  }

  &.todo-section--theme-red {
    background: #820901;
    .status-icon {
      color: #820901;
    }
  }

  &.todo-section--theme-disabled {
    background: #aaa;

    .header {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &.border-top {
    border-top: 1px solid @account-divider-gray;
    border-radius: 0;
  }
  &.border-bottom {
    border-bottom: 1px solid @account-divider-gray;
    border-radius: 0;
  }

  &.hover-shadow {
    &:hover {
      border: 0.5px solid @todo-section-border-gray;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 4px;
    }
  }

  // if these are nested, make children smaller?
  .todo-section {
    padding: 2px;
    margin-bottom: 0;

    .title {
      font-size: 15px;
    }
  }
}
.slide-open {
  transition: all 0.2s;
  height: 200px;
}
.slide-close {
  height: 0;
  opacity: 0;
}
</style>
