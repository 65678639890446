// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_UnmBa{width:100%;max-width:680px}body h2.modal__title_M_jRH{font-family:Gerstner-Programm,Helvetica,Segoe UI,\"sans-serif\";font-size:26px;font-weight:500;line-height:1;text-align:center;margin:0}.modal__icon_ZnFaC{width:100%;max-width:130px;padding:10px 0;margin:0 auto;display:block}body .modal__details_P9OE4 p{font-family:Gerstner-Programm,Helvetica,Segoe UI,\"sans-serif\";font-size:16px;font-weight:300;line-height:24px;letter-spacing:0;text-align:center}.modal__details_P9OE4 p a{color:#215cb6;text-decoration:none}.modal__details_P9OE4+.modal__nav_RDaWe{margin-top:48px}.modal__nav_RDaWe{display:flex;flex-direction:column;align-items:center}.modal__nav__button_djG6m+.modal__nav__button_djG6m{margin-top:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_UnmBa",
	"modal__title": "modal__title_M_jRH",
	"modal__icon": "modal__icon_ZnFaC",
	"modal__details": "modal__details_P9OE4",
	"modal__nav": "modal__nav_RDaWe",
	"modal__nav__button": "modal__nav__button_djG6m"
};
export default ___CSS_LOADER_EXPORT___;
