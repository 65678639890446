<template>
  <Message
    v-if="showMessage"
    severity="info"
    :closable="true"
    icon="pi pi-copy"
    @close="rememberClose"
  >
    <h3>Looking to download your transactions for year end?</h3>
    <p>Here are quick links to get you there faster:</p>
    <ul>
      <li v-if="userHasActiveOrSettledInventoryOrUnifiedAdvance">
        <router-link :to="{ name: 'payments' }"
          >Click here for Capital Advances</router-link
        >
      </li>
      <li v-if="userHasActiveBillAdvances || userHasSettledBillAdvances">
        <router-link to="/pay-vendors/upcoming-payments"
          >Click here for Invoice &amp; Receipt Funding</router-link
        >
      </li>
    </ul>
  </Message>
</template>

<script>
import { mapGetters } from 'vuex';
import Message from '@clearbanc/clear-components/message';

export default {
  components: { Message },
  data() {
    return {
      showMessage: false,
    };
  },
  beforeMount() {
    this.checkMessageVisibility();
  },
  computed: {
    ...mapGetters([
      'userHasActiveBillAdvances',
      'userHasSettledBillAdvances',
      'userHasActiveOrSettledInventoryOrUnifiedAdvance',
    ]),
    isMessageDismissed() {
      if (localStorage.getItem('message-tax')) {
        return true;
      }

      return false;
    },
    isMessageExpired() {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();

      // Hide the message after Jan 31, 2023
      if (currentMonth > 1 && currentYear >= 2023) {
        return true;
      }

      return false;
    },
    userHadOrHasPreviousAdvances() {
      return (
        this.userHasActiveBillAdvances ||
        this.userHasSettledBillAdvances ||
        this.userHasActiveOrSettledInventoryOrUnifiedAdvance
      );
    },
  },
  methods: {
    rememberClose() {
      localStorage.setItem('message-tax', true);
    },
    checkMessageVisibility() {
      if (this.isMessageDismissed || this.isMessageExpired) {
        return;
      }

      if (this.userHadOrHasPreviousAdvances) {
        this.showMessage = true;
      }
    },
  },
  watch: {
    userHadOrHasPreviousAdvances(newVal) {
      this.checkMessageVisibility();
    },
  },
};
</script>
