<template>
  <div :class="divClasses">
    <svg
      data-cy="back-button"
      :class="$style['back-button']"
      tabindex="0"
      @click="$emit('click')"
      @keyup.enter="$emit('click')"
      v-html="require('@/assets/icons/' + svgPath + '.svg?raw')"
    ></svg>
    <p v-if="showBackText" @click="$emit('click')">
      {{ $t('recommendationsPage.back') }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    version: { type: Number, required: false, default: 1 },
    svgPath: {
      type: String,
      required: false,
      default: 'back-icon',
    },
  },
  emits: ['click'],
  computed: {
    showBackText() {
      return this.version !== 1;
    },
    divClasses() {
      if (this.version === 2) {
        return [this.$style['back-button2-content']];
      }
      return [this.$style['back-button1-content']];
    },
  },
};
</script>
<style lang="less" module>
.back-button1-content {
  .back-button {
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin: auto auto auto 0;
  }
}

.back-button2-content {
  display: flex;
  justify-content: flex-start;

  svg {
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin: auto 0 auto 0;
  }

  p {
    cursor: pointer;
    color: #000;
    text-decoration: underline;
    font-weight: 700;
  }
}
</style>
