<template>
  <TabMenu :model="navItems" />
</template>
<script>
import TabMenu from '@clearbanc/clear-components/tabmenu';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';

export default {
  components: {
    TabMenu,
  },
  props: {
    showVendorTab: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isRouteActive(route) {
      return this.$route.name === route;
    },
  },
  computed: {
    navItems() {
      const navArray = [
        {
          label: this.$t('components.paymentsNav.paymentsTab'),
          to: { name: PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE },
          disabled: this.isRouteActive(PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE),
        },
        {
          label: this.$t('components.paymentsNav.vendorsTab'),
          to: { name: PAYMENTS_ROUTE_NAMES.VENDOR_LIST },
          disabled: this.isRouteActive(PAYMENTS_ROUTE_NAMES.VENDOR_LIST),
        },
      ];
      if (!this.showVendorTab) {
        navArray.splice(1, 1);
      }
      return navArray;
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.p-tabmenuitem.p-highlight.p-disabled) {
  opacity: 1;
}
</style>
