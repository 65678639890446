// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h2.heading_kqlQu{font-size:21px;line-height:30px;font-weight:500;margin:0 0 24px}.sub-heading_wha_L{margin:0 auto 2rem;max-width:550px}@media screen and (max-width:500px){.sub-heading_wha_L,h2.heading_kqlQu{text-align:left}}.calendar-wrapper_wQAoI{max-width:350px;margin:50px auto 30px}.upload_CWxav{margin:0 auto 60px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "heading_kqlQu",
	"sub-heading": "sub-heading_wha_L",
	"calendar-wrapper": "calendar-wrapper_wQAoI",
	"upload": "upload_CWxav"
};
export default ___CSS_LOADER_EXPORT___;
