// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".section-header_BUVmU{margin-bottom:40px;text-align:center}.section-header__title_n_FDh{font-size:24px;font-weight:500;line-height:32px}.section-header__title_n_FDh,.subtitle_Anzid{color:var(--neutral-black,#000);font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-style:normal}.subtitle_Anzid{font-size:16px;font-weight:300;line-height:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-header": "section-header_BUVmU",
	"section-header__title": "section-header__title_n_FDh",
	"subtitle": "subtitle_Anzid"
};
export default ___CSS_LOADER_EXPORT___;
