import statesByCountry from '@/data/states-by-country';
import { countryOptionsWithPriority } from '@/data/country-code-with-names';
import { COUNTRY_CODES } from '@/data/supported-country-codes';

export const removeExtraObjectValues = (obj) => {};

export const parsePlaceData = (placeData) => {
  const addressComponents = {};

  const obj = {
    line1: null,
    country: null,
    state: null,
    city: null,
    postalCode: null,
  };

  // Convert the raw API data into something more useable
  // https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
  placeData.address_components.forEach((component) => {
    addressComponents[component.types[0]] = {
      long_name: component.long_name,
      short_name: component.short_name,
    };
  });

  // Populate our form fields using the API data

  // Not all addresses from the Google API are complete and formatted equally
  // Although we can't cover them all, the combination of if statements below cover the most common variations
  if (addressComponents.street_number || addressComponents.route) {
    obj.line1 = `${addressComponents.street_number?.long_name || ''} ${
      addressComponents.route?.long_name || ''
    }`;
  } else if (addressComponents.neighborhood?.long_name) {
    obj.line1 = addressComponents.neighborhood?.long_name;
  } else if (addressComponents.sublocality?.long_name) {
    obj.line1 = addressComponents.sublocality?.long_name;
  } else if (addressComponents.sublocality_level_1?.long_name) {
    obj.line1 = addressComponents.sublocality_level_1?.long_name;
  } else if (addressComponents.locality?.long_name) {
    obj.line1 = addressComponents.locality?.long_name;
  }

  if (addressComponents.subpremise) {
    obj.line2 = `#${addressComponents.subpremise.long_name}`;
  }

  if (addressComponents.postal_town) {
    obj.city = addressComponents.postal_town.long_name;
  } else if (addressComponents.locality) {
    obj.city = addressComponents.locality.long_name;
  } else if (addressComponents.administrative_area_level_2) {
    obj.city = addressComponents.administrative_area_level_2.long_name;
  }

  if (addressComponents.administrative_area_level_1) {
    const states = statesByCountry[addressComponents.country?.short_name];
    let selectedState;

    if (states) {
      selectedState = states.find((state) => {
        return (
          state.value ===
          addressComponents.administrative_area_level_1.short_name
        );
      });
    }

    obj.state =
      selectedState || addressComponents.administrative_area_level_1.short_name;
  }

  if (addressComponents.country) {
    obj.country = countryOptionsWithPriority([
      COUNTRY_CODES.US,
      COUNTRY_CODES.CA,
      COUNTRY_CODES.GB,
    ]).find(
      (country) => country.value === addressComponents.country.short_name,
    );
  }

  if (addressComponents.postal_code) {
    obj.postalCode = addressComponents.postal_code.long_name;
  }

  return obj;
};
