// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_GXkOM{text-align:center}@media screen and (max-width:500px) and (min-width:375px){.container_GXkOM [class^=ext-account]{width:154px}.container_GXkOM [class^=ext-account]:nth-child(odd){margin:7.5px 5px 7.5px 0}.container_GXkOM [class^=ext-account]:nth-child(2n){margin:7.5px 0 7.5px 5px}.container_GXkOM svg[class^=icon]{z-index:1}}.container_GXkOM.hide-some_Qqtp9>:last-child{display:none}@media screen and (max-width:500px){.container_GXkOM.hide-more_dArcZ>:nth-child(n+3),.container_GXkOM.hide-some_Qqtp9>:nth-child(n+5){display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_GXkOM",
	"hide-some": "hide-some_Qqtp9",
	"hide-more": "hide-more_dArcZ"
};
export default ___CSS_LOADER_EXPORT___;
