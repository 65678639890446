import { onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import analytics from '@/utils/analytics';

export default function sendUtmParamsToSegment() {
  onBeforeMount(async () => {
    const store = useStore();

    const signupParamsRaw = localStorage.getItem('signupParams') || '{}';
    const signupParamsParsed = JSON.parse(signupParamsRaw);

    if (signupParamsParsed?.utm_campaign) {
      const utmParams = {
        utmSource: signupParamsParsed.utm_source,
        utmMedium: signupParamsParsed.utm_medium,
        utmCampaign: signupParamsParsed.utm_campaign,
        utmContent: signupParamsParsed.utm_content,
      };

      analytics.identify(store.getters.userId, utmParams);

      localStorage.removeItem('signupParams');
    }
  });
}
