<template>
  <LayoutConnectAccounts :show-encryption-message="false">
    <template #titles>
      <h2 :class="$style.heading">Bank Statements</h2>
      <p :class="$style['sub-heading']">
        Please upload the <strong>last 6 months of bank statements</strong> for
        bank account
        <strong>
          {{ bankAccount.subtype }} account *** ***
          {{ bankAccount.accountNumberLast4 }}
        </strong>
      </p>
    </template>
    <FormUploadBankStatements
      show-date-range
      :meta-id="bankAccount.id"
      :account-number="bankAccount.accountNumberLast4"
      :event-tracking-fields="commonTrackingFields"
    />
    <div
      class="c-form"
      :style="{
        textAlign: 'center',
      }"
    >
      <div class="c-form__controls justify-center" :style="{ margin: '0' }">
        <DSButton
          class="p-button-outlined"
          :label="$t('common.buttons.back')"
          @click="backClick"
        />
        <DSButton
          :disabled="!isUploadBankStatementsStepComplete"
          :label="
            isInFinancialDocsSegment
              ? $t('common.buttons.next')
              : $t('common.buttons.done')
          "
          @click="done"
        />
      </div>
    </div>
  </LayoutConnectAccounts>
</template>

<script>
import { mapGetters } from 'vuex';
import DSButton from '@clearbanc/clear-components/button';
import { LayoutConnectAccounts, FormUploadBankStatements } from '@/components';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';

export default {
  components: {
    DSButton,
    LayoutConnectAccounts,
    FormUploadBankStatements,
  },
  data() {
    return {
      PAYMENTS_ROUTE_NAMES,
    };
  },
  async mounted() {
    await this.$store.dispatchApiAction('GET_FISCAL_DOCUMENT_CHECKS');
  },
  computed: {
    ...mapGetters([
      'isUploadBankStatementsStepComplete',
      'bankAccounts',
      'isInFinancialDocsSegment',
      'productSegmentLabel',
      'experimentName',
    ]),
    bankId() {
      return this.$route.params.id;
    },
    bankAccount() {
      return this.bankAccounts.find(
        (bank) => bank.id.toString() === this.bankId,
      );
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        experiment: this.experimentName,
        targetArea: this.$route.name,
      };
    },
  },
  methods: {
    navigationLocation(defaultRoute) {
      const routeName = this.$route.query.navigatedFrom || defaultRoute;
      this.$router.push({ name: routeName });
    },
    done() {
      this.navigationLocation(PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD);
    },
    backClick() {
      this.navigationLocation(PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING);
    },
  },
};
</script>

<style lang="less" module>
h2.heading {
  font-size: 21px;
  line-height: 30px;
  font-weight: 500;
  margin: 0 0 24px;
}

.sub-heading {
  margin: 0 auto 2rem;
  max-width: 550px;
}

@media screen and (max-width: 500px) {
  h2.heading,
  .sub-heading {
    text-align: left;
  }
}
</style>
