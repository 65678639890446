// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".message-banner_E53Tl{margin:20px 20px 0 20px!important}.reset-content_hvkeM input{padding-left:40px}.reset-content_hvkeM i{top:23px!important}.reset-content_hvkeM p,.reset-content_hvkeM span{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message-banner": "message-banner_E53Tl",
	"reset-content": "reset-content_hvkeM"
};
export default ___CSS_LOADER_EXPORT___;
