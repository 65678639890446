// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".phone_pChSL{position:relative}.phone_pChSL div[class=p-dropdown-trigger]{display:none}.countryCode_XMVnB{position:absolute;top:24px;left:0;width:110px}input.phoneNumber_wMoJ2{padding-left:130px}input.phoneNumber_wMoJ2[type=number]::-webkit-inner-spin-button,input.phoneNumber_wMoJ2[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}input.phoneNumber_wMoJ2[type=number]{-moz-appearance:textfield}.callingCodesValue_GkWEN{display:flex;align-items:center}.callingCodesValue__code_K0HFZ{margin:0 auto}.callingCodesOptions_qLCQL{display:flex;align-items:center}.callingCodesOptions__item_NWOSn+.callingCodesOptions__item_NWOSn{margin-left:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phone": "phone_pChSL",
	"countryCode": "countryCode_XMVnB",
	"phoneNumber": "phoneNumber_wMoJ2",
	"callingCodesValue": "callingCodesValue_GkWEN",
	"callingCodesValue__code": "callingCodesValue__code_K0HFZ",
	"callingCodesOptions": "callingCodesOptions_qLCQL",
	"callingCodesOptions__item": "callingCodesOptions__item_NWOSn"
};
export default ___CSS_LOADER_EXPORT___;
