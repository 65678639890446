// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_GKxCZ{display:inline-block;border:1px solid #f2f2f2;border-radius:8px;box-shadow:3px 4px 2px rgba(0,0,0,.08);margin:20px auto 80px;padding:30px 10%;background:#fff}@media only screen and (max-width:500px){.container_GKxCZ{display:inline-block;min-width:unset;margin:10px -10px auto;padding:30px 10px}}.container_GKxCZ .input-container_mxEYe{position:relative;margin:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_GKxCZ",
	"input-container": "input-container_mxEYe"
};
export default ___CSS_LOADER_EXPORT___;
