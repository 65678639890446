// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_oCtqt{text-align:left;max-width:560px;margin:0 auto}.container_oCtqt .list_AXl26{list-style-type:decimal;padding-left:1rem}.container_oCtqt .list_AXl26 li~li{margin-top:1rem}.container_oCtqt .button_YIXWn{display:block;margin-top:2rem}.container_oCtqt .input-fields_CXPOW{max-width:400px;margin-top:2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_oCtqt",
	"list": "list_AXl26",
	"button": "button_YIXWn",
	"input-fields": "input-fields_CXPOW"
};
export default ___CSS_LOADER_EXPORT___;
