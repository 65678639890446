<template>
  <div class="oauth-signup">
    <oauth-connect
      ref="oauthConnect"
      :provider-config="oauthSignupProviderConfig"
    />
    <error-message :message="error" />
  </div>
</template>

<script>
/*
SC-184519 DO NOT REMOVE - this is required to install the Shopify Native app and maintain data access

The flow is as follows
  - A User starts on the Shopify App page, and installs the Clearco app (see https://partners.shopify.com/483256/apps/1416066/overview)
  - The Clearco app redirects to `page-oauth-trigger` (`/oauth-trigger`) - authentication is not required
    - `page-oauth-trigger` redirects back to Shopify with the relevant Oauth config
  - User accepts the permissions and installs the app (if not already installed)
  - Shopify redirects back to Clearcom `page-oauth-signup` (`/oauth-signup`) with authorization code
    - `page-oauth-signup` stores the Oauth payload in local storage (`shopify-oauth-payload`)
    - `page-oauth-signup` requires the User to login/signup
  - User logs in / signs up on Auth0
    - User is redirected to the email verification page, verifies email
  - After email is verified, User is redirected back to `page-oauth-signup`
      - `POST /oauth/shopify` is called to connect the external account
      - User is redirected to the dashboard or user profile depending on profile completeness
*/

import { OAUTH_PROVIDER_CONFIG } from '@/data/oauth-provider-config';
import { useMeta } from 'vue-meta';

const components = {
  'oauth-connect': require('@/components/OauthConnect').default,
};

export default {
  components,
  setup() {
    useMeta({ title: 'Oauth Connect | Clearco' });
  },
  data: () => ({
    error: null,
  }),
  computed: {
    oauthSignupProviderConfig: () => OAUTH_PROVIDER_CONFIG,
  },
  mounted() {
    const { query } = this.$route;

    if (query.error) {
      this.error = query.error;
    } else {
      this.triggerOauth(query);
    }
  },
  methods: {
    async triggerOauth(oauthParams) {
      const provider = oauthParams.provider;

      const config = this.oauthSignupProviderConfig[provider];
      if (!config) {
        throw new Error(`Missing oauth config for provider - ${provider}`);
      }

      if (!config.authorizeUri) {
        throw new Error(`Missing authorizeUri for provider - ${provider}`);
      }

      if (provider === 'shopify') {
        localStorage.setItem('oauth-provider', 'shopify');
        localStorage.setItem(
          'oauth-redirect-route',
          '/pay-vendors/business-profile/connected-accounts',
        );

        this.$refs.oauthConnect.launch('shopify', {
          authorizeUri: config.authorizeUri.replace(
            'www.myshopify.com',
            oauthParams.shop,
          ),
          noPopup: true,
        });
      }
    },
  },
};
</script>

<style lang="less">
.oauth-signup {
  padding: 10px;
  text-align: center;
}
</style>
