<template>
  <td :class="classes" data-cy="custom-column-payments">
    <template v-if="show">
      <span v-if="type === 'date'">{{ $filters.date(row[show]) }}</span>
      <span v-else-if="type === 'percent'">{{
        $filters.percent(row[show])
      }}</span>
      <span v-else-if="type === 'money'">{{ moneyDisplayValue }}</span>
      <span v-else>{{ row[show] }}</span>
    </template>
    <slot v-else></slot>
  </td>
</template>

<script>
import { formatMoney } from '@/utils/currency';

export default {
  props: {
    row: Object,
    show: String,
    type: String,
    uppercase: Boolean,
    capitalize: Boolean,
    align: { type: String, default: 'center' },
    gerstner: Boolean,
    green: Boolean,
  },
  computed: {
    classes() {
      return {
        [this.$style.column]: true,
        [this.$style.uppercase]: this.uppercase,
        [this.$style.capitalize]: this.capitalize,
        [this.$style['align-left']]: this.align === 'left',
        [this.$style['align-right']]: this.align === 'right',
        [this.$style['align-center']]: this.align === 'center',
        [this.$style.gerstner]: this.gerstner,
      };
    },
    moneyDisplayValue() {
      return this.type === 'money' && this.row[this.show] > 0
        ? `${formatMoney(this.row[this.show], this.row.currencyCode)} `
        : ' - ';
    },
  },
};
</script>

<style lang="less" module>
.column {
  border: 1px solid @color-lightgray-100;
  padding: 12px 6px;
  color: @medium-gray;
  font-weight: 400;

  &.gerstner {
    font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &.align-left {
    text-align: left;
  }

  &.align-right {
    text-align: right;
  }

  &.align-center {
    text-align: center;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
}
</style>
