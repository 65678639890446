<template>
  <div class="main-wrap" :class="classes">
    <Navbar />
    <div class="flex-wrap">
      <div class="main-content" :class="{ 'main-content-lg': large }">
        <slot />
      </div>
    </div>
    <site-footer v-if="!noFooter" />
    <policy-updated-popup />
    <api-error-popup />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

const components = {
  'site-footer': require('./footer-site').default,
  'policy-updated-popup': require('@/components/popups/PopupPolicyUpdated')
    .default,
  'api-error-popup': require('@/components/popups/PopupApiError').default,
  Navbar: require('@/layout/Navbar').default,
};

export default {
  components,
  props: {
    fullWidth: Boolean,
    noFooter: Boolean,
    large: Boolean,
  },
  computed: {
    ...mapGetters(['userIsLoggedIn', 'userHasEverHadActiveAdvance']),
    ...mapRequestStatuses({
      getUserAdvancesRequest: 'GET_USER_ADVANCES',
    }),
    logo() {
      return require('@/assets/logos/clearco-logo.svg');
    },
    classes() {
      return {
        'full-width': this.fullWidth,
        'no-footer': this.noFooter,
      };
    },
  },
  mounted() {
    if (this.userIsLoggedIn && !this.getUserAdvancesRequest.wasRequested) {
      this.$store.dispatch('refreshAdvances');
    }
  },
};
</script>

<style lang="less">
// this flex stuff sets up the sticky footer
.main-wrap {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: clip;

  > .flex-wrap {
    flex: 1 0 auto;
  }
}

.main-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 15px;
  padding-top: 90px;
  padding-bottom: 0;

  .full-width & {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &.main-content-lg {
    max-width: 1320px;
  }

  .main-wrap.no-fixed-header & {
    padding-top: 0;
  }
}
</style>
