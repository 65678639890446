<script setup>
import UserInviteMessage from '@/components/UserInviteMessage';
import PictogramBrokenHeart from '@/assets/pictograms/broken-heart.svg';
</script>

<template>
  <UserInviteMessage>
    <template #image>
      <div v-html="PictogramBrokenHeart"></div>
    </template>
    <template #title>Expired Link</template>
    <template #description>
      This link has expired. Please contact your admin to resend the
      verification.
    </template>
  </UserInviteMessage>
</template>
