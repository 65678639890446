<template>
  <div :class="$style.container">
    <div :class="classes">
      <BankAccountList
        v-model:bank-account-id-to-be-primary="bankAccountIdToBePrimary"
        @relink="(account) => relinkAccount(account)"
        @update-primary="validate"
        @connect-new="connectNewAccount"
        @show-action-required-modal="
          (account) => $emit('showActionRequiredModal', account)
        "
      />
      <BankIntegrations
        ref="bankIntegrations"
        @success="handlePlaidSuccess"
        @error="handleErrors"
        @exit="handleBankingConnectExit"
        @plaidOpened="trackPlaidModal('opened')"
        @flowEvent="trackPlaidFlowEvent"
      />
    </div>
    <OverlayLoadingIcon v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import BankIntegrations from '@/components/BankIntegrations';
import analytics from '@/utils/analytics';
import OverlayLoadingIcon from '@/components/OverlayLoadingIcon';
import bankAccountUtils from '@/composables/bank-accounts';
import { useContextRoot } from '@/utils/context-root';
import BankAccountList from '@/components/BankAccountList';

export default {
  emits: [
    'showActionRequiredModal',
    'bankAccountChange',
    'validationSuccess',
    'showContactSupportModal',
    'showPrimaryChangeModal',
    'showContactSupportModal',
    'isPlaidLoading',
    'connect-new',
    'plaidError',
  ],
  components: {
    BankIntegrations,
    OverlayLoadingIcon,
    BankAccountList,
  },
  setup(props, context) {
    const { markBankAccountToBecomePrimary, canChangePrimaryBankAccount } =
      bankAccountUtils(useContextRoot());
    return {
      markBankAccountToBecomePrimary,
      canChangePrimaryBankAccount,
    };
  },
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
    error: { type: Object },
  },
  data() {
    return {
      bankAccountIdToBePrimary: null,
      plaidLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'bankAccounts',
      'primaryBankAccount',
      'currentBankAccountToBePrimary',
      'shownBankAccounts',
      'businessId',
      'businessOnPlaidBypass',
      'isReorderBankingStepsEnabled',
      'userHasActiveBillAdvances',
    ]),
    ...mapRequestStatuses({
      addBankConnectionRequest: 'ADD_USER_BANK_CONNECTION',
      isLoadingRequest: [
        'FETCH_USER_BANK_ACCOUNTS',
        'ADD_USER_BANK_CONNECTION',
        'UPDATE_BANK_ACCOUNT',
        'SIGN_BANK_ACCOUNT_AUTH_FORM',
      ],
    }),
    classes() {
      return {
        [this.$style.content]: true,
        [this.$style.loading]: this.isLoading,
      };
    },
    isLoading() {
      return this.plaidLoading || this.isLoadingRequest.isPending;
    },
    selectedNewPrimaryBankAccount() {
      const hasDifferentPrimaryAccount =
        this.primaryBankAccount &&
        this.primaryBankAccount.id !== this.bankAccountIdToBePrimary;

      const hasDifferentIntendedPrimaryAccount =
        this.currentBankAccountToBePrimary &&
        this.currentBankAccountToBePrimary.id !== this.bankAccountIdToBePrimary;

      return hasDifferentPrimaryAccount || hasDifferentIntendedPrimaryAccount;
    },
    plaidPreview: () => require('@/assets/images/plaid-preview.png'),
    currentRoute() {
      return this.$route.name;
    },
  },
  watch: {
    bankAccountIdToBePrimary(newVal) {
      this.$emit('bankAccountChange', newVal);
    },
  },
  methods: {
    async validate() {
      if (this.isReorderBankingStepsEnabled) {
        let eventToEmit;

        if (this.primaryBankAccount?.id !== this.bankAccountIdToBePrimary) {
          if (this.userHasActiveBillAdvances) {
            eventToEmit = 'showContactSupportModal';
          } else {
            await this.removeIntentFromAllBankAccounts();
            await this.$store.dispatchApiAction('UPDATE_BANK_ACCOUNT', {
              id: this.bankAccountIdToBePrimary,
              action: 'set-primary',
              isPrimary: true,
            });
          }
        }

        if (eventToEmit) {
          this.$emit(eventToEmit);
        }
      } else if (this.canChangePrimaryBankAccount()) {
        await this.markBankAccountToBecomePrimary(
          this.bankAccountIdToBePrimary,
        );
        analytics.track('plaid_mandate_primary_set_intent', {
          bankAccountId: this.bankAccountIdToBePrimary,
          businessId: this.businessId,
        });
        this.$emit('showPrimaryChangeModal');
      } else if (this.selectedNewPrimaryBankAccount) {
        analytics.track('plaid_mandate_primary_contact_support', {
          bankAccountId: this.bankAccountIdToBePrimary,
          businessId: this.businessId,
        });
        this.$emit('showContactSupportModal');
      } else {
        this.$emit('validationSuccess');
      }
    },
    async removeIntentFromAllBankAccounts() {
      const promises = [];

      for (const account of this.bankAccounts) {
        if (account.details?.setAsPrimary) {
          const detailsWithRemovedIntent = { ...account.details };

          delete detailsWithRemovedIntent.setAsPrimary;

          promises.push(
            this.$store.dispatchApiAction('UPDATE_BANK_ACCOUNT', {
              id: account.id,
              details: detailsWithRemovedIntent,
            }),
          );
        }
      }

      await Promise.all(promises);
    },
    handleErrors(plaidMetadata) {
      this.trackBankLinkFailed(plaidMetadata);
      if (!this.addBankConnectionRequest.isSuccess) {
        this.setPlaidLoading(false);
        this.$emit('update:error', {
          show: true,
          isBankConnectError:
            this.addBankConnectionRequest.error?.type === 'Conflict',
        });
      }
      this.$emit('plaidError', plaidMetadata);
    },
    handleBankingConnectExit() {
      this.setPlaidLoading(false);
      this.trackPlaidModal('quit');
    },
    handlePlaidSuccess() {
      this.$emit('update:error', { ...this.error, show: false });
      this.setPlaidLoading(false);
    },
    setPlaidLoading(isLoading) {
      this.plaidLoading = isLoading;
      this.$emit('isPlaidLoading', isLoading);
    },
    trackBankLinkFailed(metadata) {
      analytics.track('fe_bank_link_failed', {
        ...this.eventTrackingFields,
        bank: metadata.instituationName,
        instituation_id: metadata.instituation_id,
        link_session_id: metadata.link_session_id,
      });
    },
    trackPlaidFlowEvent({ eventName, metadata }, relink) {
      // sometimes if the user exits no error gets emitted by plaid but it is captured in the metadata in a flow event
      if (metadata.error_code) {
        this.handleErrors(metadata);
      }
      const bankTrackingProps = {
        ...this.eventTrackingFields,
        bank: metadata.institution_name,
        institution_id: metadata.institution_id,
        relink: !!relink,
      };

      if (eventName === 'SELECT_INSTITUTION') {
        analytics.track('fe_select_bank', bankTrackingProps);
      } else if (eventName === 'SUBMIT_CREDENTIALS') {
        analytics.track('fe_bank_account_attempt', {
          ...bankTrackingProps,
          link_session_id: metadata.link_session_id,
        });
      }
    },
    trackPlaidModal(action, relink) {
      this.showPlaidModal = false;
      analytics.track(`fe_plaid_${action}`, {
        ...this.eventTrackingFields,
        relink: !!relink,
      });
    },
    async relinkAccount(account) {
      this.$refs.bankIntegrations.reconnectAccount(this.currentRoute, account);
    },
    connectNewAccount(routingNumber) {
      if (routingNumber) {
        this.$refs.bankIntegrations.openBankIntegration(
          this.currentRoute,
          routingNumber,
        );
      } else {
        this.$emit('connect-new');
      }
    },
  },
};
</script>

<style lang="less" module>
.container {
  .content {
    padding-top: 20px;
    text-align: center;
    transition: 0.2s filter linear;
    &.loading {
      filter: blur(2px);
    }
    .link-more-accounts-button {
      width: 100%;
      max-width: 350px;
      @media only screen and (max-width: 500px) {
        float: unset;
      }
    }
  }
}

.plaid-img {
  width: 100%;
  max-width: 260px;
}
</style>
