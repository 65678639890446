import i18n from '@/plugins/i18n';
import { buildApiActions } from '@/utils/vuex-api-utils';
import { SUPPORTED_LANGUAGES } from '@/configs/i18n-constants';

const LANG_KEY = 'language';

const initLang = () => {
  // Remove this condition once LanguageSwicher component is ready for production
  const lang = window.localStorage.getItem(LANG_KEY);
  if (lang) {
    i18n.global.locale = lang;
  }
  return lang;
};

export const setLanguage = (state, language) => {
  // Remove this condition once LanguageSwicher component is ready for production
  if (
    language !== state.selectedLanguage &&
    Object.values(SUPPORTED_LANGUAGES).includes(language)
  ) {
    window.localStorage.setItem(LANG_KEY, language);
    state.selectedLanguage = language;
    i18n.global.locale = language;
  }
};

export default {
  state: {
    selectedLanguage: initLang() || SUPPORTED_LANGUAGES.ENGLISH,
  },
  getters: {
    selectedLanguage: (state) => {
      return state.selectedLanguage;
    },
  },
  ...buildApiActions(
    {
      UPDATE_USER_LANGUAGE: {
        action: (ctx, payload) => {
          return {
            method: 'patch',
            url: `/users/${ctx.rootGetters.userId}/settings`,
            params: payload,
          };
        },
        mutation: (state, { response }) => {
          setLanguage(state, response.language);
        },
      },
      GET_USER_LANGUAGE: {
        action: (ctx) => {
          return {
            method: 'get',
            url: `/users/${ctx.rootGetters.userId}/settings`,
          };
        },
        mutation: (state, { response }) => {
          setLanguage(state, response.language);
        },
      },
    },
    {
      actions: {
        setLanguage({ commit }, language) {
          commit('setLanguage', language);
        },
      },
      mutations: {
        setLanguage(state, language) {
          setLanguage(state, language);
        },
      },
    },
  ),
};
