import _ from 'lodash';

import { buildApiActions } from '@/utils/vuex-api-utils';
import { OBFUSCATING_PLAID_BANK_PROVIDERS } from '@/data/obfuscating-plaid-bank-providers';
import {
  BANK_CONNECTION_ACTIONS,
  BANK_ERROR_STATUSES,
} from '@/data/bank-connection';
import { businessDiligenceApi } from '../../utils/diligence-service';

export default {
  state: {
    bankAccounts: {},
    bankIdentities: {},
    bankAccountPlaidChecks: {},
    whitelistRoutingNumbers: [],
    whitelistBankDetails: [],
    plaidMetadata: {},
  },
  getters: {
    bankAccounts: (state) =>
      _.values(state.bankAccounts).filter((acc) => acc.type !== 'vendor'),
    shownBankAccounts(state, getters) {
      const notHiddenAccounts = getters.bankAccounts.filter(
        (ba) => !ba.hiddenAt,
      );
      // put primary account first
      return notHiddenAccounts.sort(
        (ba1, ba2) => ba2.isPrimary - ba1.isPrimary,
      );
    },
    hiddenBankAccounts(state, getters) {
      return getters.bankAccounts.filter((ba) => ba.hiddenAt);
    },
    visibleBankAccounts(state, getters) {
      return getters.bankAccounts.filter((ba) => !ba.hiddenAt);
    },
    visibleBankAccountsWithPassedPreFlightChecks(state, getters) {
      const allBankAccountChecks = getters.allBankAccountChecks;

      return getters.visibleBankAccounts.filter((ba) => {
        const bankAccountChecks = allBankAccountChecks[ba.id];

        return bankAccountChecks?.allChecksPass;
      });
    },
    anyBankAccountDisconnected(state, getters) {
      // https://app.shortcut.com/clearbanc/story/128825/s1-p2-plaid-item-login-required-error
      // These business IDs are being blocked from accessing the Advances Pages
      // While we figure out a proper fix, we are unblocking these users with this hack
      const businessIdsToBypassConnectionCheck = [
        159997, 197300, 174645, 89735, 178726, 48679,
      ];
      return _.some(
        getters.bankAccounts,
        (ba) =>
          ba.connection.plaidStatus.action === 'relink' &&
          !businessIdsToBypassConnectionCheck.includes(ba.businessId),
      );
    },
    isPrimaryBankAccountDisconnected: (
      state,
      getters,
      rootState,
      rootGetters,
    ) => {
      const intendedPrimaryAccount = getters.bankAccounts.find(
        (ba) => ba.details?.setAsPrimary,
      );
      const accountToCheck =
        intendedPrimaryAccount ?? getters.primaryBankAccount;

      return (
        !rootGetters.businessOnPlaidBypass &&
        (accountToCheck?.connection?.plaidStatus?.action ===
          BANK_CONNECTION_ACTIONS.RELINK ||
          BANK_ERROR_STATUSES.includes(
            accountToCheck?.connection?.plaidStatus?.summary,
          ))
      );
    },
    bankAccountById: (state) => (id) => state.bankAccounts[id],
    primaryBankAccount: (state, getters) =>
      _.find(getters.bankAccounts, { isPrimary: true }),
    intendedPrimaryAccount: (_state, getters) =>
      getters.bankAccounts.find((ba) => ba.details?.setAsPrimary),
    primaryOrIntendedPrimaryBankAccount: (_state, getters) =>
      getters.primaryBankAccount ?? getters.intendedPrimaryAccount,
    intendedPrimaryOrPrimaryBankAccount: (_state, getters) =>
      getters.intendedPrimaryAccount ?? getters.primaryBankAccount,
    primaryIsPlaidAccount: (state, getters) =>
      !!getters.intendedPrimaryOrPrimaryBankAccount?.bankConnectionId,
    accountActiveGoCardlessMandates: (state) => (bankAccount) => {
      const activeMandateAccounts = [];
      const { goCardlessAccountDetails } = bankAccount;
      if (goCardlessAccountDetails?.length) {
        goCardlessAccountDetails.forEach((gcAccountDetails) => {
          const isMandateActive = [
            'created',
            'pending_submission',
            'submitted',
            'active',
          ].includes(gcAccountDetails.gcStatus);

          if (isMandateActive) {
            activeMandateAccounts.push(gcAccountDetails);
          }
        });
      }
      return activeMandateAccounts;
    },
    accountHasActiveGoCardlessMandate: (state, getters) => (bankAccount) => {
      const activeMandates =
        getters.accountActiveGoCardlessMandates(bankAccount);

      return activeMandates.length;
    },
    primaryBankAccountAuthorizedGoCardless: (state, getters) => {
      const { primaryBankAccount, accountHasActiveGoCardlessMandate } = getters;
      return primaryBankAccount
        ? accountHasActiveGoCardlessMandate(primaryBankAccount)
        : false;
    },
    bankIdentities: (state) => state.bankIdentities,
    hasUploadedBankDocs: (state, getters, rootState, rootGetters) => {
      return (
        rootGetters.uploadsMatching(rootGetters.bankUploadMatcher).length > 0
      );
    },
    fiscalDocumentsUpToDate: (state, getters, rootState, rootGetters) => {
      if (!rootGetters.maxCapacityOverThreshold) {
        return true;
      }

      return rootGetters.fiscalDocumentChecks?.lastQuartersStatementUploaded;
    },
    isInFinancialDocsSegment: (state, getters, rootState, rootGetters) => {
      return rootGetters.maxCapacityOverThreshold;
    },
    currentBankAccountToBePrimary: (_state, getters) => {
      if (getters.bankAccounts.length) {
        return getters.bankAccounts.find(
          (bankAccount) => bankAccount.details?.setAsPrimary,
        );
      }
      return null;
    },
    bankStatementsRequired: (_state, getters) => {
      return (
        !getters.hasUploadedBankDocs &&
        getters.isBankAccountToBePrimaryObfuscatingData
      );
    },
    isBankAccountToBePrimaryObfuscatingData: (_state, getters) => {
      if (
        getters.bankAccounts.length &&
        getters.currentBankAccountToBePrimary
      ) {
        return OBFUSCATING_PLAID_BANK_PROVIDERS.includes(
          getters.currentBankAccountToBePrimary.bankName,
        );
      }
      return false;
    },
    allBankAccountChecks: (state) => {
      return state.bankAccountPlaidChecks;
    },
    whitelistBankDetails: (state) => {
      return state.whitelistBankDetails;
    },
    whitelistRoutingNumbers: (state, getters) => {
      return getters.whitelistBankDetails.map(
        (details) => details.routingNumber,
      );
    },
    whitelistDetailsForRoutingNumber: (state, getters) => (routingNumber) => {
      return getters.whitelistBankDetails.find(
        (details) => details.routingNumber === routingNumber,
      );
    },
    plaidMetadata: (state, getters) => {
      return state.plaidMetadata;
    },
  },
  ...buildApiActions(
    {
      FETCH_USER_BANK_ACCOUNTS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/users/${ctx.rootGetters.userId}/bank-accounts`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.bankAccounts = _.keyBy(response.bankAccounts, 'id');
        },
      },
      ADD_USER_BANK_CONNECTION: {
        action: (ctx, payload) => ({
          method: 'post',
          // TODO: switch to business
          url: `/users/${ctx.rootGetters.userId}/bank-connections`,
          params: payload,
          options: { timeout: 60000 }, // temporary measure to ensure no timeout on bank connection
          async afterSuccess() {
            // TODO: figure out how to deal with this
            await ctx.dispatch('api-FETCH_USER_BANK_ACCOUNTS');
          },
        }),
        // mutation: (state, { response }) => { state.bankAccounts = _.keyBy(response, 'id'); },
      },
      RECONNECT_USER_BANK_CONNECTION: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/bank-connections/${payload.bankConnectionId}/reconnect`,
          async afterSuccess() {
            // TODO: figure out how to deal with this
            await ctx.dispatch('api-FETCH_USER_BANK_ACCOUNTS');
          },
        }),
        // mutation: (state, { response }) => { state.bankAccounts = _.keyBy(response, 'id'); },
      },
      ADD_USER_BANK_ACCOUNT: {
        action: (ctx, payload) => ({
          method: 'post',
          // TODO: switch to business
          url: `/users/${ctx.rootGetters.userId}/bank-accounts`,
          params: payload,
          returnResponse: true, // returns the api response directly from the action!
        }),
        mutation: (state, { response }) => {
          state.bankAccounts[response.id] = response;
        },
      },
      GET_PLAID_LINK_TOKEN: {
        action: (ctx, payload) => ({
          method: 'post',
          url: '/link-token',
          params: payload,
          returnResponse: true,
        }),
      },
      HIDE_USER_BANK_ACCOUNT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/bank-accounts/${payload.id}/hide`,
          params: payload,
          returnResponse: true, // returns the api response directly from the action!
        }),
        mutation: (state, { response }) => {
          state.bankAccounts[response.id] = response;
        },
      },
      GET_BANK_CONNECTION_PLAID_TOKEN: {
        action: (ctx, payload) => ({
          keyRequestStatusBy: payload.bankConnectionId,
          method: 'get',
          url: `/bank-connections/${payload.bankConnectionId}/plaid-token`,
          returnResponse: true,
        }),
      },
      UPDATE_BANK_ACCOUNT: {
        action: (ctx, payload) => ({
          method: payload.action ? 'post' : 'patch',
          url: [`/bank-accounts/${payload.id}`, payload.action].join('/'),
          params: _.omit(payload, 'id', 'action'),
          async afterSuccess() {
            if (payload.action === 'set-primary')
              await ctx.dispatch('api-FETCH_USER_BANK_ACCOUNTS');
          },
        }),
        mutation: (state, { response }) => {
          state.bankAccounts[response.id] = response;
        },
      },
      REGENERATE_ACH_CONTRACT: {
        action: (ctx, payload) => ({
          method: 'patch',
          url: `/bank-accounts/${payload.id}/regenerate-ach`,
        }),
        mutation: (state, { response }) => {
          state.bankAccounts[response.id] = response;
        },
      },
      SIGN_BANK_ACCOUNT_AUTH_FORM: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/contracts/${payload.bankAccountId}/embedded-signing`,
          params: { returnURL: payload.returnURL?.split('?')[0] ?? null },
          options: { timeout: 15000 },
          returnResponse: true,
        }),
        mutation: (state, { actionSpec, response }) => {
          state.bankAccounts[
            actionSpec.payload.bankAccountId
          ].embeddedSigningSession = response.url;
        },
      },
      REFRESH_BANK_ACCOUNT_AUTH_FORM_STATUS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/bank-accounts/${payload.bankAccountId}/refresh-contract-details`,
          options: { timeout: 15000 }, // makes requests to DocuSign so can be slow
        }),
        mutation: (state, { response }) => {
          state.bankAccounts[response.id] = response;
        },
      },
      VERIFY_BANK_ACCOUNT_MICROS: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/bank-accounts/${payload.bankAccountId}/verify-microdeposits`,
          params: _.omit(payload, 'bankAccountId'),
          options: { timeout: 100000 },
        }),
        mutation: (state, { response }) => {
          state.bankAccounts[response.id] = response;
        },
      },
      TRIGGER_BANK_ACCOUNT_DWOLLA_ACTION: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/bank-accounts/${payload.bankAccountId}/dwolla-action`,
          params: _.omit(payload, 'bankAccountId'),
          options: { timeout: 100000 },
        }),
        mutation: (state, { response }) => {
          state.bankAccounts[response.id] = response;
        },
      },
      FETCH_BANK_CONNECTION_IDENTITIES: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/bank-connections/${payload.id}/identities`,
        }),
        mutation: (state, { response }) => {
          state.bankIdentities = response;
        },
      },
      FETCH_BANK_CONNECTION_BALANCES: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/bank-connections/${payload.id}/refresh-balance`,
          async afterSuccess() {
            await ctx.dispatch('api-FETCH_USER_BANK_ACCOUNTS');
          },
          afterFailure(err) {
            // eslint-disable-next-line no-alert
            alert(err.message);
          },
        }),
      },
      FETCH_PLAID_INSTITUTION_STATUS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/bank-connections/institution-status`,
          params: payload,
          returnResponse: true,
        }),
      },
      SUBMIT_GOCARDLESS_DEBIT_AUTHORIZATION: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/bank-accounts/${payload.id}/authorize-direct-debit`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.bankAccounts[response.bankAccount.id] = response.bankAccount;
        },
      },
      GET_BANK_ACCOUNT_CHECKS: {
        action: (ctx, payload) => ({
          requestFunc: async () => {
            const response =
              await businessDiligenceApi.businessDiligenceControllerPreFlightChecks(
                payload.businessId,
                payload.bankAccountId,
              );
            return response;
          },
        }),
        mutation: (state, { payload, response }) => {
          state.bankAccountPlaidChecks[payload.bankAccountId] = response;
        },
      },
      GET_BANKS_ON_PLAID_WHITELIST: {
        action: (ctx, payload) => ({
          method: 'get',
          url: '/plaid-whitelist-banks',
          returnResponse: true,
        }),
        mutation: (state, { payload, response }) => {
          state.whitelistBankDetails = response;
        },
      },
    },
    {
      mutations: {
        UPDATE_PLAID_METADATA_: (state, metadata) => {
          state.plaidMetadata = metadata;
        },
      },
    },
  ),
};
