// https://www.figma.com/file/VpTHfpSd6pzWqEZyVpJkSt/Design-System?node-id=157%3A0
export const COLOR_THEMES = {
  NEUTRAL: 'neutral',
  SUCCESS: 'success',
  DANGER: 'danger',
  INFO: 'info',
  WARNING: 'warning',
};

export const COLOR_THEME_VALUES = Object.values(COLOR_THEMES);
