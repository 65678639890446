<template>
  <div :class="$style['payments-layout']">
    <LoadingScreen v-if="isLoading" :class="$style['spinner']" />
    <!-- Net New International -->
    <div v-else-if="netNewInternationalUser">
      <Message severity="info" :closable="false">
        <h3>
          {{ $t(waitListBannerContent.title) }}
        </h3>
        <p>
          {{ $t(waitListBannerContent.paragraph1) }}
        </p>
        <p>
          <a :href="waitListBannerContent.linkUrl" target="_blank">{{
            $t(waitListBannerContent.linkText)
          }}</a>
        </p>
      </Message>
    </div>
    <!-- // End // Net New International -->
    <div v-else>
      <MessageDocumentRequests
        v-if="
          !isDocumentsPage &&
          !isBankStatementsPage &&
          !isFinancialStatementsPage
        "
      />
      <!--  Password Policy Update -->
      <div v-if="showResetPasswordMessage">
        <Message
          severity="info"
          data-cy="reset-password-message"
          :closable="false"
        >
          <h3>New Password Policies</h3>
          <p>Hi {{ firstName }}, We've updated our Password Policy.</p>
          <p>
            To further enhance the protection of your account and data, please
            <a href="/password-reset" target="_blank">click here</a> and update
            your password.
          </p>
        </Message>
      </div>
      <template v-if="isHome">
        <MessageTaxes />
        <Message
          v-if="
            !Object.keys(waitListBannerContent).length && disableInvoiceUpload
          "
          severity="info"
          :closable="false"
        >
          <h3>Expect delays.</h3>
          <p>
            We have seen a rapid influx of funding requests and we are
            unfortunately experiencing delays in our turnaround times for
            processing invoices. In order to make sure that we deliver a
            world-class experience for our founders, we are temporarily
            suspending uploads in order to best service our existing customers.
            Click the Join Waitlist button and get notified as soon as we are
            accepting new founders!
          </p>
        </Message>
        <Message
          v-if="
            Object.keys(waitListBannerContent).length && showNewProductBanner
          "
          severity="info"
          :closable="true"
          @close="closeNewProductBanner"
        >
          <h3>
            {{ $t(waitListBannerContent.title, { firstName }) }}
          </h3>
          <p v-if="waitListBannerContent.paragraph1">
            {{ $t(waitListBannerContent.paragraph1) }}
          </p>
          <p
            v-if="
              waitListBannerContent.paragraph2NoLink ||
              waitListBannerContent.paragraph2
            "
          >
            {{
              waitListBannerContent.paragraph2NoLink
                ? $t(waitListBannerContent.paragraph2NoLink)
                : $t(waitListBannerContent.paragraph2)
            }}
          </p>
          <i18n-t
            v-if="waitListBannerContent.paragraph3"
            :keypath="waitListBannerContent.paragraph3"
            tag="p"
          >
            <template #email>
              <a :href="`mailto:${$t(waitListBannerContent.supportEmail)}`">
                {{ $t(waitListBannerContent.supportEmail) }}
              </a>
            </template>
          </i18n-t>
          <p v-if="waitListBannerContent.linkName">
            <router-link :to="{ name: waitListBannerContent.linkName }">{{
              $t(waitListBannerContent.linkText)
            }}</router-link>
          </p>
        </Message>
        <Message
          v-if="showSuccessBanner"
          :id="bannerId"
          severity="success"
          :closable="true"
          @close="closeSuccessBanner"
        >
          <h3>
            {{ $t('account.pages.pageVendors.youHaveCreatedAnAccount') }}
          </h3>
          <p>
            {{ $t('account.pages.pageVendors.getStartedNow') }}
          </p>
        </Message>
        <Message
          v-if="showBizProfileNotification"
          severity="warn"
          :closable="false"
        >
          <h3>
            {{ $t('account.pages.pageVendors.toProcessAPayment') }}
          </h3>
          <p>
            {{
              $t('paymentsPage.ofNCompleted', {
                numberOfCompletedSteps,
                numberOfSteps,
              })
            }}
          </p>
          <router-link :to="{ name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD }">
            {{ $t('account.pages.pageVendors.completeProfile') }}
          </router-link>
        </Message>
        <Message
          v-if="
            billsTotalCount > 0 &&
            (!salesAccountsConnected || hasDisconnectedSalesAccounts)
          "
          severity="warn"
          :closable="false"
        >
          <p>
            Please
            <router-link
              :to="{ name: PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT }"
              >verify revenue</router-link
            >
            by connecting sales accounts to avoid funding delays.
          </p>
        </Message>
        <Message
          v-if="displayPaymentObligationsMessage"
          severity="warn"
          :closable="false"
        >
          <h3>Update Payment Obligations</h3>
          <p>
            Please
            <router-link
              :to="{ name: PAYMENTS_ROUTE_NAMES.PROFILE_DEBT_OBLIGATIONS }"
              >update your Payment Obligations</router-link
            >
            to avoid funding delays.
          </p>
        </Message>

        <CapacityContainer
          :self-reported-rev="selfReportedRev"
          :show-self-reported="showSelfReportedCapacity"
          :error-type="errorType"
          :currency-code="capacityCurrencyCode"
          @joinWaitlist="openPayAnInvoiceModal(ctaSources.joinWaitlistBanner)"
          @openPayInvoice="handleSubmitAnInvoiceClick(ctaSources.userCapacity)"
          @finishApplicationCtaClicked="handleFinishApplicationCtaClick"
        />

        <HowItWorks v-if="!hasSubmittedBill" />
        <template v-else>
          <section
            v-if="
              billsRequest.receivedAt &&
              !hasSubmittedBill &&
              !userIsDisqualified
            "
            class="accordion-section"
          >
            <Accordion :active-index="0">
              <AccordionTab
                :header="
                  $t('account.pages.pageVendors.financeInvoiceHowItWorks.title')
                "
              >
                <FinanceInvoiceHowItWorks>
                  <template v-slot:call-to-action>
                    <DSButton
                      v-if="disableInvoiceUpload"
                      :label="waitlistCtaLabel"
                      @click="openDisabledInvoiceModal"
                    />
                    <DSButton
                      v-else-if="userCanSubmitInvoices"
                      :label="payAnInvoiceModalButtonText"
                      @click="
                        handleSubmitAnInvoiceClick(ctaSources.onboardingBanner)
                      "
                    />
                  </template>
                </FinanceInvoiceHowItWorks>
              </AccordionTab>
            </Accordion>
          </section>

          <section
            v-if="
              billsRequest.receivedAt &&
              !hasSubmittedBill &&
              !userIsDisqualified
            "
            class="accordion-section"
          >
            <Accordion :active-index="0">
              <AccordionTab
                :header="
                  $t('account.pages.pageVendors.extendedPaymentOptions.title', {
                    receipts: $t(
                      'account.pages.pageVendors.extendedPaymentOptions.andReceipts',
                    ),
                  })
                "
              >
                <ExtendedPaymentOptions />
                <ReceiptsDisclaimer />
              </AccordionTab>
            </Accordion>
          </section>
        </template>
        <!-- Payments table -->
        <section v-if="hasSubmittedBill" :class="$style['page-section']">
          <div
            :class="[$style.section, $style['payments-nav-wrapper']]"
            data-cy="bills-table-section"
          >
            <PaymentsNav />
            <div :class="$style['payments-nav-cta-group']">
              <div :class="$style['payments-nav-buttons']">
                <DSButton
                  v-if="disableInvoiceUpload"
                  :label="waitlistCtaLabel"
                  @click="openDisabledInvoiceModal"
                />
                <DSButton
                  v-else-if="
                    $route.name !== 'vendor-list' && userCanSubmitInvoices
                  "
                  :label="payAnInvoiceModalButtonText"
                  @click="handleSubmitAnInvoiceClick(ctaSources.userCapacity)"
                />
                <DSButton
                  v-if="$route.name === 'pay-vendors/payment-table'"
                  icon="pi pi-filter-slash"
                  class="p-button-outlined"
                  label="Clear Filters"
                  @click="resetTableFilters"
                />
              </div>
              <a :href="addLocaleParam(faqLink)" target="_blank">
                {{ $t('components.paymentsNav.faqs') }}
              </a>
            </div>
            <router-view
              v-slot="{ Component }"
              :section="$route.name"
              @newPayment="handleSubmitAnInvoiceClick(ctaSources.userCapacity)"
            >
              <component :is="Component" ref="view" />
            </router-view>
          </div>
        </section>
      </template>
      <div v-if="isHome && showDisclaimer" :class="$style['bnpl-disclaimer']">
        <p>
          {{ $t('account.pages.pageVendors.disclaimer') }}
        </p>
      </div>
      <div v-if="isHome">
        <p :class="$style['vendor-logo-container']">
          <strong>{{
            $t('account.pages.pageVendors.worksGreatWithVendors')
          }}</strong>
          <img :src="require('@/assets/logos/google.png')" alt="Google" />
          <img :src="require('@/assets/logos/meta.png')" alt="Meta" />
          <img :src="require('@/assets/logos/amazon.png')" alt="Amazon" />
          <img :src="require('@/assets/logos/microsoft.png')" alt="Microsoft" />
          <img :src="require('@/assets/logos/klavio.png')" alt="Klavio" />
          <img :src="require('@/assets/logos/flexport.png')" alt="Flexport" />
          <img :src="require('@/assets/logos/dhl.png')" alt="DHL" />
        </p>
        <Message severity="info" icon="pi-lock" :closable="false">
          <h3>
            {{ $t('account.pages.pageVendors.bankGradeEncryption') }}
          </h3>
          <p>
            {{ $t('account.pages.pageVendors.allInformationTraveling') }}
          </p>
        </Message>
      </div>
      <template v-if="!isHome">
        <router-view
          :section="$route.name"
          @pay-an-invoice="
            handleSubmitAnInvoiceClick(ctaSources.repaymentsPage)
          "
          @disable-invoice-upload-modal="openDisabledInvoiceModal"
        />
        <div v-if="isRepayments" :class="$style['bnpl-disclaimer']">
          <p>
            {{ $t('account.pages.subPageRepayments.allFutureDatedAmounts') }}
          </p>
        </div>
      </template>
    </div>
    <ModalPayAnInvoice
      v-if="!isBnplWaitlisted"
      ref="payAnInvoicePopup"
      @done="searchVendors"
    />

    <Modal
      v-model:visible="showDisabledInvoiceModal"
      header="You're on the waitlist"
      :modal="true"
      :draggable="false"
      append-to="self"
    >
      <p>
        We will let you know the moment we are ready to deliver you a great
        experience.
      </p>
    </Modal>

    <Modal
      v-model:visible="showConnectPlaidModal"
      header="Bank Account Connection Issue"
      :modal="true"
      :draggable="false"
      append-to="self"
    >
      <div :class="$style['modal-content']">
        <div class="icon-set">
          <svg v-html="IconConnectPlaid"></svg>
        </div>
        <p>
          There is an issue with your Plaid bank connection and it is a
          requirement for funding. To ensure you remain eligible for funding
          connect with Plaid to link your bank accounts and resubmit any
          declined invoices or receipts as a result.
        </p>
        <p>
          Need help? Contact us at
          <a href="mailto:support@clear.co">support@clear.co</a> or
          1-(415)-610-5166 10am-6pm EST Monday to Friday.
        </p>
        <div class="plaid-btn">
          <DSButton label="Restore connection" @click="connectPlaid" />
        </div>
      </div>
    </Modal>

    <ModalSuccess
      :visible="showPreliminaryCapacityModal"
      title="Your Preliminary Funding Capacity is Ready!"
      subtitle="Submit a bill to receive your Approved Funding Capacity."
      button-label="Submit an invoice or receipt"
      @submit-modal="
        openPayAnInvoiceModal();
        showPreliminaryCapacityModal = false;
      "
      @hide="
        writeToLocalStorage({
          [LOCAL_STORAGE_KEYS.VIEWED_PRELIMINARY_CAPACITY_MODAL]:
            this.businessCapacity.id.toString(),
        })
      "
    >
      <svg v-html="ReportIcon"></svg>
    </ModalSuccess>

    <Modal
      v-model:visible="showReconnectExternalAccount"
      header="Reconnecting your Shopify account"
      :modal="true"
      :draggable="false"
      append-to="self"
    >
      <div :class="$style['modal-content']">
        <div class="icon-set">
          <svg v-html="IconReconnectExternaAccount"></svg>
        </div>
        <p>
          Your Shopify account is currently disconnected. To ensure you remain
          eligible for funding and/or compliant with your agreement with
          Clearco, please proceed with reconnecting it.
        </p>
        <p>
          Need help? Contact us at
          <a href="mailto:support@clear.co">support@clear.co</a> or
          1-(415)-610-5166 10am-6pm EST Monday to Friday.
        </p>
        <div class="plaid-btn">
          <DSButton
            label="Restore connection"
            @click="reconnectExternalAccount"
          />
        </div>
      </div>
    </Modal>

    <ModalSuccess
      :visible="showCapacityApprovedModal"
      title="Your Approved Funding Capacity is Ready!"
      data-cy="capacity-approved-modal"
      subtitle="Take advantage by uploading bills."
      button-label="Submit an invoice or receipt"
      @submit-modal="
        showCapacityApprovedModal = false;
        handleSubmitAnInvoiceClick();
      "
      @hide="
        writeToLocalStorage({
          [LOCAL_STORAGE_KEYS.DISMISSED_CAPACITY_APPROVED_MODAL]:
            businessCapacity.id,
        })
      "
    >
      <svg v-html="ReportIcon"></svg>
    </ModalSuccess>

    <ModalSignatory ref="modalSignatory" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Accordion from '@clearbanc/clear-components/accordion';
import AccordionTab from '@clearbanc/clear-components/accordiontab';
import Message from '@clearbanc/clear-components/message';
import { CurrencyCode } from '@clearbanc/data-common-types';
import sendUtmParamsToSegment from '@/composables/utm';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import analytics from '@/utils/analytics';
import addLocaleParam from '@/utils/add-locale-param';
import PaymentsNav from '@/components/payments/PaymentsNav';
import ModalPayAnInvoice from '@/components/payments/ModalPayAnInvoice';
import LoadingScreen from '@/components/LoadingScreen';
import DSButton from '@clearbanc/clear-components/button';
import ReceiptsDisclaimer from '@/components/payments/ReceiptsDisclaimer';
import {
  CLEARPAY_CTA_SOURCES,
  CLEARPAY_SEGMENT_EVENTS,
  PAYMENTS_ROUTE_NAMES,
  BillPaymentMethods,
  PAYMENTS_STATE_MODULE,
  waitlistBannerText,
  HELP_CENTER_URL,
} from '@/data/payments';
import { useMeta } from 'vue-meta';

import FinanceInvoiceHowItWorks from '@/components/payments/finance-invoice-how-it-works';
import ExtendedPaymentOptions from '@/components/payments/extended-payment-options';
import Modal from '@clearbanc/clear-components/dialog';
import MessageTaxes from '@/components/MessageTaxes';
import IconConnectPlaid from '@/assets/icons/connect-plaid.svg?inline';
import ReportIcon from '@/assets/icons/report.svg?inline';
import IconReconnectExternaAccount from '@/assets/icons/reconnect-external-account.svg?inline';
import HowItWorks from '@/pages/how-it-works';
import { useAuth0 } from '@auth0/auth0-vue';
import { isBefore, format, subMonths } from 'date-fns';
import { useRouter } from 'vue-router';
import MessageDocumentRequests from '@/components/MessageDocumentRequests';
import CapacityContainer from '@/components/payments/CapacityContainer';
import ModalSuccess from '@/components/modals/ModalSuccess';
import ModalSignatory from '@/components/modals/ModalSignatory';

const PASSWORD_POLICY_RELEASE_DATE = '2023-11-13T15:40:00.000-05:00';
const AUTH_TOKEN_EXPIRY_CHECK_IN_MIN = 5;

const components = {
  PaymentsNav,
  DSButton,
  ModalPayAnInvoice,
  ModalSuccess,
  ReceiptsDisclaimer,
  LoadingScreen,
  Accordion,
  AccordionTab,
  FinanceInvoiceHowItWorks,
  ExtendedPaymentOptions,
  Message,
  Modal,
  MessageTaxes,
  HowItWorks,
  MessageDocumentRequests,
  CapacityContainer,
  ModalSignatory,
};

export default {
  components,
  setup() {
    useMeta({
      title: 'Vendors | Clearco',
      meta: [{ name: 'robots', content: 'noindex' }],
    });

    const router = useRouter();
    const { getAccessTokenSilently } = useAuth0();

    async function checkTokenExpiry() {
      try {
        await getAccessTokenSilently();
      } catch (error) {
        router.push({
          name: 'logout',
        });
      }
    }
    // Check token validity periodically
    const timeToWaitInMs = AUTH_TOKEN_EXPIRY_CHECK_IN_MIN * 1000 * 60;
    setInterval(checkTokenExpiry, timeToWaitInMs);

    return sendUtmParamsToSegment();
  },
  data() {
    return {
      IconConnectPlaid,
      ReportIcon,
      IconReconnectExternaAccount,
      isLoading: false,
      showSuccessBanner: false,
      bannerId: 'successful-signup-banner',
      ctaSources: CLEARPAY_CTA_SOURCES,
      faqLink: HELP_CENTER_URL,
      showDisabledInvoiceModal: false,
      showConnectPlaidModal: false,
      showReconnectExternalAccount: false,
      topLevelRoutes: [
        PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE,
        PAYMENTS_ROUTE_NAMES.UPCOMING_REPAYMENTS,
        PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
      ],
      sumbitAnInvoiceClicked: false,
      connectPaymentsModalDismissed: false,
      restoreConnectionClicked: false,
      showPreliminaryCapacityModal: false,
      showCapacityApprovedModal: false,
      LOCAL_STORAGE_KEYS: {
        VIEWED_PRELIMINARY_CAPACITY_MODAL: 'viewedPreliminaryCapacityModal',
        DISMISSED_CAPACITY_APPROVED_MODAL: 'dismissedCapacityApprovedModal',
      },
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'businessId',
      'activeAdvances',
      'business',
      'businessProfileSteps',
      'bills',
      'hasActiveSalesAccounts',
      'marketingAccountsConnected',
      'isBnplWaitlisted',
      'bankingAccountsConnected',
      'isBusinessDetailsStepComplete',
      'isBusinessOwnersStepComplete',
      'businessCapacity',
      'netNewInternationalUser',
      'userCategory',
      'businessCorpCountry',
      'userCanSubmitInvoices',
      'businessCapacityGenerated',
      'activeValidAdvanceOrAvailableValidSpend',
      'userHasForceBnplFlag',
      'disableInvoiceUpload',
      'showNewProductBanner',
      'userHasActiveBillsAdvanceAndDisconnectedSalesAccount',
      'isUSBusiness',
      'isPersonaEnabled',
      'isPrimaryBankAccountDisconnected',
      'shopifyAccounts',
      'businessOnPlaidBypass',
      'remainingSelfReportedCapacityCents',
      'salesAccountsConnected',
      'billsTotalCount',
      'maxCapacityExceeded',
      'userIsDisqualified',
      'hasDisconnectedSalesAccounts',
      'debtObligationsRequired',
      'businessDebtObligations',
      'isDocumentRequestsEnabled',
      'hasActiveDocumentRequests',
      'primaryOrIntendedPrimaryBankAccount',
      'isInFinancialDocsSegment',
      'fiscalDocumentsUpToDate',
      'fiscalStartDate',
      'isImprovedKyFlowEnabled',
    ]),
    ...mapRequestStatuses({
      searchVendorsRequest: 'SEARCH_VENDORS',
      businessCapacityRequest: 'GET_BUSINESS_CAPACITY',
      billsRequest: 'GET_BILLS',
      createPayInvoiceRequest: 'CREATE_PAY_INVOICE',
      getExternalAccountsRequest: 'FETCH_USER_EXTERNAL_ACCOUNTS',
    }),
    shouldShowPreliminaryCapacityModal() {
      return (
        this.businessCapacityGenerated &&
        !this.maxCapacityExceeded &&
        !this.businessCapacity.approvedAt &&
        !this.preliminaryCapacityModalViewed
      );
    },
    preliminaryCapacityModalViewed() {
      return (
        localStorage.getItem(
          this.LOCAL_STORAGE_KEYS.VIEWED_PRELIMINARY_CAPACITY_MODAL,
        ) === this.businessCapacity?.id?.toString()
      );
    },
    capacityCurrencyCode() {
      return this.businessCapacity?.currencyCode ?? CurrencyCode.USD;
    },
    firstName() {
      if (!this.user.firstName) return '';
      return (
        this.user.firstName.charAt(0).toUpperCase() +
        this.user.firstName.slice(1)
      );
    },
    PAYMENTS_ROUTE_NAMES: () => PAYMENTS_ROUTE_NAMES,
    showVendorsTab() {
      return this.activeAdvances && this.activeAdvances.length > 0;
    },
    // TODO(SC-172836): Remove Password Policy Update Message
    showResetPasswordMessage() {
      const { user } = useAuth0();
      if (user?.value) {
        const { createdAt, lastPasswordReset } = user.value;
        const lastPasswordUpdate = new Date(lastPasswordReset || createdAt);
        const newPolicyReleasedAt = new Date(PASSWORD_POLICY_RELEASE_DATE);
        return isBefore(lastPasswordUpdate, newPolicyReleasedAt);
      }
      return false;
    },
    showDisclaimer() {
      const bnplBills = this.bills.filter(
        (bill) => bill.paymentMethod === BillPaymentMethods.BNPL,
      );
      return bnplBills?.length !== 0;
    },
    displayPaymentObligationsMessage() {
      if (this.numberOfCompletedSteps < this.numberOfSteps - 1) {
        return false;
      }
      return (
        (this.isInFinancialDocsSegment &&
          !this.fiscalDocumentsUpToDate &&
          !this.fiscalStartDate) ||
        this.debtObligationsRequired
      );
    },
    isHome() {
      return this.$route.name === this.PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE;
    },
    isRepayments() {
      return (
        this.$route.name === this.PAYMENTS_ROUTE_NAMES.REPAYMENTS ||
        this.$route.name === this.PAYMENTS_ROUTE_NAMES.UPCOMING_REPAYMENTS
      );
    },
    hasSubmittedBill() {
      return this.bills && this.bills.length > 0;
    },
    numberOfCompletedSteps() {
      return Object.values(this.businessProfileSteps).reduce(
        (pv, step) => pv + (step.isComplete ? 1 : 0),
        0,
      );
    },
    showBizProfileNotification() {
      const shouldShowModal =
        this.hasSubmittedBill &&
        !this.isLoading &&
        this.numberOfCompletedSteps !== this.numberOfSteps;

      if (shouldShowModal && this.debtObligationsRequired) {
        analytics.track('if_payment_obligations_update', {
          businessId: this.businessId,
          lastObligationsSubmissionDate: this.businessDebtObligations
            ?.created_at
            ? format(
                new Date(this.businessDebtObligations.created_at),
                'yyyy-MM-dd',
              )
            : 'Obligations never submitted',
          capacityId: this.businessCapacity.id,
        });
      }

      return shouldShowModal;
    },
    numberOfSteps() {
      return Object.keys(this.businessProfileSteps).length;
    },
    payAnInvoiceModalButtonText() {
      return this.isBnplWaitlisted
        ? this.$t('account.pages.pageVendors.joinTheWaitlist')
        : this.$t('account.pages.pageVendors.paymentsButtonLabel', {
            receipt: this.$t('account.pages.pageVendors.orReceipt'),
          });
    },
    selfReportedRev() {
      return this.business.qualifyingQuestions?.monthly_revenue;
    },
    waitListBannerContent() {
      return waitlistBannerText(
        this.userCategory,
        this.businessCorpCountry,
        this.userHasForceBnplFlag,
        this.bills.length > 0,
      );
    },
    showSelfReportedCapacity() {
      return !this.businessCapacityGenerated;
    },
    errorType() {
      return this.businessCapacityRequest.error?.type;
    },
    waitlistCtaLabel() {
      return this.$t('account.pages.pageVendors.joinTheWaitlist');
    },
    needsToMigrateShopifyAccount() {
      return !!this.shopifyAccounts.find(
        (account) => !account.connectionDetails,
      );
    },
    isDocumentsPage() {
      return this.$route.name === this.PAYMENTS_ROUTE_NAMES.ALL_DOCUMENTS;
    },
    isBankStatementsPage() {
      return (
        this.$route.name === this.PAYMENTS_ROUTE_NAMES.PROFILE_BANK_STATEMENTS
      );
    },
    isFinancialStatementsPage() {
      return (
        this.$route.name === this.PAYMENTS_ROUTE_NAMES.PROFILE_BANK_DOCUMENTS
      );
    },
  },
  async beforeMount() {
    const statementStartDate = subMonths(new Date(), 6).toISOString();
    this.isLoading = true;
    await this.$store.dispatch('refreshAdvances');
    await this.$store.dispatchApiAction('FETCH_USER_CARDS', {
      canceled: false,
    });

    if (
      !localStorage.getItem('cb-hide-success-banner') &&
      localStorage.getItem('new-user')
    ) {
      this.showSuccessBanner = true;
      localStorage.removeItem('new-user');
    }

    await this.$store.dispatchApiAction('FETCH_USER_EXTERNAL_ACCOUNTS');
    await this.$store.dispatchApiAction('FETCH_USER_BANK_ACCOUNTS');
    await this.$store.dispatchApiAction('FETCH_USER_UPLOADS');

    await this.$store.dispatch('setCurrentStateModule', PAYMENTS_STATE_MODULE);

    if (this.isPersonaEnabled) {
      await this.$store.dispatchApiAction('GET_PERSONA_VERIFICATIONS');
    }

    await this.$store.dispatchApiAction('FETCH_USER_CONTRACTS');
    await this.$store.dispatchApiAction('GET_BUSINESS_CAPACITY');
    await this.$store.dispatchApiAction('GET_BILLS');
    await this.$store.dispatchApiAction('GET_DEBT_OBLIGATIONS');
    await this.$store.dispatchApiAction('GET_DOCUMENT_REQUESTS');
    await this.$store.dispatchApiAction('GET_BANK_STATEMENTS_CHECK', {
      statementStartDate,
      bankAccountNumber:
        this.primaryOrIntendedPrimaryBankAccount?.accountNumber,
    });

    this.showPreliminaryCapacityModal = this.shouldShowPreliminaryCapacityModal;

    analytics.identify(this.user.id, {
      hasConnectedSalesAccounts: this.hasActiveSalesAccounts,
      hasMarketingAccountConnected: this.marketingAccountsConnected,
      hasConnectedBankAccounts: this.bankingAccountsConnected,
      hasFilledBusinessDetails: this.isBusinessDetailsStepComplete,
      hasFilledOwnerInformation: this.isBusinessOwnersStepComplete,
    });
    analytics.track(CLEARPAY_SEGMENT_EVENTS.VIEWED_DASHBOARD, {
      businessId: this.business.id,
      businessName: this.business.name,
    });
    this.isLoading = false;

    this.checkCapacityApprovedModalStatus();

    await this.$store.dispatch('UPDATE_ALL_PAYMENTS_BIZ_PROFILE_STEPS');
  },
  async mounted() {
    await this.$store.dispatchApiAction('FETCH_TRANSACTIONS', {
      type: 'vendor_invoice',
      businessId: this.businessId,
    });
    await this.searchVendors();

    if (this.$route.query['upload-invoice']) {
      this.$refs.payAnInvoicePopup.open(this.businessId);
    }

    await this.$store.dispatchApiAction('GET_FISCAL_DOCUMENT_CHECKS', {
      businessId: this.businessId,
    });

    this.toggleSignatoryModal();
  },
  watch: {
    businessCapacityRequest(newValue) {
      if (
        this.$route.name === PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE &&
        newValue.isSuccess &&
        this.isPrimaryBankAccountDisconnected &&
        !this.showReconnectExternalAccount &&
        !this.businessOnPlaidBypass
      ) {
        this.showConnectPlaidModal = true;
      }
    },
    getExternalAccountsRequest(newValue) {
      if (
        this.needsToMigrateShopifyAccount &&
        this.$route.name === PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE &&
        newValue.isSuccess
      ) {
        this.showReconnectExternalAccount = true;
      }
    },
    '$route.name': function (newRoute) {
      const fundingPage = this.PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE;

      if (newRoute === fundingPage) {
        this.toggleSignatoryModal();
      }
    },
  },
  methods: {
    addLocaleParam,
    async searchVendors() {
      await this.$store.dispatchApiAction('SEARCH_VENDORS', {
        // TODO: Revisit this limit once /vendors API has been reviewed.
        limit: 200,
        businessId: this.businessId,
      });
    },
    openPayAnInvoiceModal(source) {
      this.$refs.payAnInvoicePopup.open(this.businessId);
      analytics.track('submit_invoice_cta_clicked', {
        businessId: this.businessId,
        businessName: this.business.name,
      });
      analytics.track(CLEARPAY_SEGMENT_EVENTS.CLICKED_ADD_BILL, {
        businessId: this.businessId,
        businessName: this.business.name,
        origin: this.ctaSources.paymentsList,
      });

      if (this.isBnplWaitlisted) {
        // TODO: implement Slack message
        // TODO: store local storage to avoid duplicate clicks
        analytics.track(CLEARPAY_SEGMENT_EVENTS.CLEARPAY_BNPL_WAITLISTED, {
          email: this.user.email,
          name: `${this.user.firstName} ${this.user.lastName}`,
          businessId: this.businessId,
          businessName: this.business.name,
        });
      }
    },
    openDisabledInvoiceModal() {
      this.showDisabledInvoiceModal = true;
    },
    closeSuccessBanner() {
      localStorage.setItem('cb-hide-success-banner', 'true');
    },
    closeNewProductBanner() {
      analytics.track('fe_close_clicked', {
        targetArea: 'phoenix_intro_messaging',
      });
      localStorage.setItem('hide-phoenix-intro-banner', 'true');
    },
    routeToBizProfile() {
      return this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
      });
    },
    handleSubmitAnInvoiceClick(source) {
      this.sumbitAnInvoiceClicked = true;
      this.openPayAnInvoiceModal(source);
    },
    closePaymentsModalAndSumbitInvoice() {
      // First, user clicks "submit an invoice", but they have disconnected accounts
      // If they dismiss the modal without reconnecting their accounts, they can still submit an invoice
      if (this.sumbitAnInvoiceClicked && !this.restoreConnectionClicked) {
        this.sumbitAnInvoiceClicked = false; // reset
        this.openPayAnInvoiceModal(this.ctaSources.userCapacity);
      } else {
        // otherwise they proceed to reconnecting their account & dont see the second modal
        this.restoreConnectionClicked = false; // reset
        this.sumbitAnInvoiceClicked = false; // reset
      }
    },
    resetTableFilters() {
      this.$refs.view.resetFilters();
    },
    connectPlaid() {
      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING,
      });
      this.showConnectPlaidModal = false;
    },
    reconnectExternalAccount() {
      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT,
      });
      this.showReconnectExternalAccount = false;
    },
    handleFinishApplicationCtaClick() {
      analytics.track('finish_application_cta_clicked', {
        businessId: this.business.id,
        businessName: this.business.name,
      });
      this.$router.push({
        name: 'payments-profile',
      });
    },
    checkCapacityApprovedModalStatus() {
      const capcityExpiryDate = this.businessCapacity?.expiresAt;
      const storedCapacityId = localStorage.getItem(
        this.LOCAL_STORAGE_KEYS.DISMISSED_CAPACITY_APPROVED_MODAL,
      );

      const isCapacityExpired =
        capcityExpiryDate && new Date(capcityExpiryDate) < new Date();
      const isCapacityNotApproved = !this.businessCapacity?.approvedAt;
      const isModalAlreadyDismissed =
        Number(storedCapacityId) === this.businessCapacity.id;

      if (
        isCapacityNotApproved ||
        isCapacityExpired ||
        isModalAlreadyDismissed ||
        (this.isDocumentRequestsEnabled && this.hasActiveDocumentRequests) ||
        this.isPrimaryBankAccountDisconnected ||
        this.maxCapacityExceeded
      ) {
        return;
      }

      this.showCapacityApprovedModal = this.userCanSubmitInvoices;
    },
    writeToLocalStorage(valuesToWrite) {
      Object.entries(valuesToWrite).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
    },
    toggleSignatoryModal() {
      if (this.isImprovedKyFlowEnabled) {
        this.$refs.modalSignatory.toggleModal();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.icon-set {
  margin-bottom: 50px;
  margin-top: 15px;
  svg {
    height: 45px;
    width: 100%;
    display: inline-block;
  }
}
.plaid-btn {
  text-align: center;
  margin-top: 40px;
}

.accordion-section {
  margin-bottom: 25px;
}

:deep(.accordion-section
    .p-accordion
    .p-accordion-header
    .p-accordion-header-link),
:deep(.accordion-section
    .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link) {
  color: #393939;
  background-color: @color-white;
}
</style>
<style lang="less" module>
.payments-layout {
  max-width: 1120px;
  margin: 20px auto;
  padding: 0 20px;
  font-family: 'Gerstner-Programm';
  font-weight: 300;

  h1 {
    font-family: 'Gerstner-Programm';
    font-weight: 500;
    font-style: normal;
    line-height: 1em;
    font-size: 26px;
  }

  h2 {
    font-family: 'Gerstner-Programm';
    font-weight: 300;
    font-style: normal;
    font-display: block;
    line-height: 22px;
    font-size: 16px;
  }

  h3 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 21px;
  }

  [class^='wrapper'] {
    max-width: none;
  }
}

.section {
  font-family: 'Montserrat';
  padding: 15px 30px;
  margin-bottom: 25px;
  background: @color-white;
  border: 1px solid @color-lightgray-300;
  box-sizing: border-box;
  border-radius: 4px;
  flex: 1 0 0;
}

.vendor-container {
  border: none;
  margin-top: 30px;
}

.columns {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

  @media screen and (max-width: 650px) {
    justify-content: space-evenly;
  }
}

.icon-hand-card {
  width: 31px;
  height: 21px;
}

.icon-institution {
  width: 19px;
  height: 21px;
}

.pay-bill {
  background: @black;

  div[class^='round-icon'] {
    color: @black;
  }
}

a.no-underline {
  text-decoration: none;
}

.vendor-logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 500px) {
    gap: 25px;
    flex-wrap: wrap;
    justify-content: center;

    strong {
      flex: 100%;
    }
  }

  img {
    vertical-align: middle;
    height: 19px;
    width: auto;
  }
}

.payments-nav-wrapper {
  div[class*='vendor-container'] {
    margin: 0;
  }

  .payments-nav-cta-group {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px 0;

    @media (min-width: 500px) {
      align-items: center;
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;

      a {
        margin-top: 10px;
      }
    }
  }
}

.payments-nav-buttons {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  [class^='p-button'] + [class^='p-button'] {
    margin-left: 0.625rem;
  }

  @media screen and (max-width: 420px) {
    flex-direction: column;
    [class^='p-button'] + [class^='p-button'] {
      margin-left: 0;
      margin-top: 0.3125rem;
    }
  }
}

.user-capacity-container {
  border: 1px solid @color-grey-200;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  background-color: @color-white;
  padding: 36px 30px 20px;
  margin-bottom: 24px;

  &.bill-capacity {
    padding: 10px 25px 10px;

    @media screen and (max-width: 500px) {
      padding: 10px 15px 10px;
    }
  }
}

.spinner {
  > div {
    margin: 50px auto !important;
  }
}

.bnpl-disclaimer {
  font-size: 10px;
  color: gray;
}
.modal-content {
  max-width: 700px;
  text-align: center;
}

.capacity-modal-icon {
  font-size: 80px;
  margin-top: 25px;
}
</style>
