<template>
  <div :class="$style.container">
    <div :class="$style['filter-container']">
      <span :class="$style['float-left']">
        <DropdownMultiSelect
          v-if="monthYearFilters.length"
          :options="monthYearFilters"
          :placeholder="$t('account.pages.subPageRepayments.monthYear')"
          data-cy="multiselect-dropdown"
          display-chips
          @on-change="(evt) => updateSelectedFilters(evt, 'monthYears')"
        />
        <DropdownMultiSelect
          v-if="invoiceIdFilters.length"
          :options="invoiceIdFilters"
          :placeholder="$t('account.pages.subPageRepayments.invoiceId')"
          data-cy="multiselect-dropdown"
          display-chips
          @on-change="(evt) => updateSelectedFilters(evt, 'invoiceIds')"
        />
        <DropdownMultiSelect
          v-if="vendorFilters.length"
          :options="vendorFilters"
          :placeholder="$t('account.pages.subPagePaymentTransactions.vendor')"
          data-cy="multiselect-dropdown"
          display-chips
          @on-change="(evt) => updateSelectedFilters(evt, 'vendors')"
        />
        <DropdownMultiSelect
          v-if="showStatusFilters"
          :options="statusFilters"
          :placeholder="$t('account.pages.subPagePaymentTransactions.status')"
          data-cy="multiselect-dropdown"
          display-chips
          @on-change="(evt) => updateSelectedFilters(evt, 'statuses')"
        />
        <Dropdown
          v-if="showDueDateFilters"
          v-model="selectedFilters.dueDate"
          :options="dueDateFilters"
          :class="$style['due-date-filter']"
          option-label="label"
          option-value="value"
          name="dueDateName"
          display-chips
          @change="(evt) => updateSelectedFilters(evt, 'dueDate')"
        />
      </span>
      <span :class="$style['float-right']">
        <Onboarding2Cta
          :class="$style['download-csv-cta']"
          :text="$t('account.pages.subPageRepayments.downloadCsv')"
          @click="generateCsv()"
        />
      </span>
    </div>
    <DataTable
      :value="repayments"
      responsive-layout="scroll"
      selection-mode="single"
      :loading="isLoading"
      :paginator="true"
      :rows="20"
      class="table-component__spread-paginator-label"
      :paginator-template="{
        '640px':
          'CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink RowsPerPageDropdown',
        default:
          'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
      }"
      :rows-per-page-options="[10, 20, 50]"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      @rowSelect="onRowSelect"
    >
      <template #loading>
        <ui-icon
          v-if="isLoading"
          data-cy="custom-table-payments-loader"
          name="loader"
        />
      </template>
      <template #empty> {{ emptyMsg }} </template>
      <Column
        :field="repaymentsType === 'upcoming' ? 'date' : 'createdAt'"
        :header="$t('common.date')"
      >
        <template #body="slotProps">
          {{
            formattedDate(
              repaymentsType === 'upcoming'
                ? slotProps.data.date
                : slotProps.data.scheduledChargeDate
                ? slotProps.data.scheduledChargeDate
                : slotProps.data.createdAt,
            )
          }}
        </template>
      </Column>
      <Column field="billId" :header="$t('common.invoiceId')">
        <template #body="slotProps">
          {{ getShortBillId(slotProps?.data.billId) }}
        </template>
      </Column>
      <Column field="vendor" :header="$t('common.vendor')">
        <template #body="slotProps">
          {{ slotProps?.data.vendor }}
        </template>
      </Column>
      <Column field="amountCents" :header="$t('common.amount')">
        <template #body="slotProps">
          {{
            formatMoney(
              slotProps.data.amountCents,
              this.repaymentsType === 'upcoming'
                ? slotProps.data.currency
                : slotProps.data.currencyCode,
            )
          }}
        </template>
      </Column>
      <Column field="status" :header="$t('common.status')">
        <template #body="slotProps">
          <badge
            :text="getFormattedStatusLabel(slotProps?.data.status)"
            :theme="getBillStatusTheme(slotProps?.data.status)"
          />
        </template>
      </Column>
    </DataTable>
    <ModalBillDetailsActions v-if="true" ref="rowItemDetailsModal" />
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { Badge } from '@clearbanc/design-components/src/components';
import { formatMoney } from '@/utils/currency';
import {
  BillPaymentMethods,
  CLEARPAY_SEGMENT_EVENTS,
  TransactionStatusLabel,
} from '@/data/payments';
import analytics from '@/utils/analytics';
import {
  getBillStatusTheme,
  getBillPaymentMethod,
  getShortBillId,
  getBillFees,
  dueDateToEasternDateTime,
} from '@/utils/payments';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { CurrencyCode } from '@clearbanc/data-common-types';
import DropdownMultiSelect from '@/components/DropdownMultiSelect';
// TODO: update with dropdown from design system
import Dropdown from '@clearbanc/clear-components/dropdown';
import DataTable from '@clearbanc/clear-components/datatable';
import Column from '@clearbanc/clear-components/column';
import ModalBillDetailsActions from '@/components/payments/ModalBillDetailsActions';
import { Onboarding2Cta } from '@/components';
import { UiIcon } from 'ui-components';

export default {
  components: {
    Badge,
    Column,
    DataTable,
    Dropdown,
    DropdownMultiSelect,
    ModalBillDetailsActions,
    Onboarding2Cta,
    UiIcon,
  },
  props: {
    repayments: {
      type: Array,
      required: true,
    },
    repaymentsType: {
      type: String,
      required: true,
    },
    selectedFilters: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      areFiltersEmpty: true,
      filteredColumns: [
        'id',
        'createdAt',
        'updatedAt',
        'businessId',
        'adminCreatedAt',
      ],
      columnLabels: {
        billId: 'Invoice ID',
        type: 'Type',
        status: 'Status',
        currencyCode: 'Currency',
        amountCents: 'Amounts (cents)',
        scheduledChargeDate: 'Scheduled Date',
        settledDate: 'Settled Date',
        vendorId: 'Vendor Name',
      },
    };
  },
  methods: {
    getBillStatusTheme,
    getBillPaymentMethod,
    getShortBillId,
    getBillFees,
    dueDateToEasternDateTime,
    formatMoney,
    async onRowSelect(selectedRowItem) {
      let transaction = selectedRowItem.data;
      const id = transaction?.billId;

      if (id) {
        // GET bill details if selectedRowItem is a transaction
        await this.$store.dispatchApiAction('GET_BILL', {
          id,
        });
        transaction = this.selectedBill;
      }
      this.$refs.rowItemDetailsModal.open(transaction);
      if (transaction.paymentMethod !== BillPaymentMethods.BNPL) {
        analytics.track(CLEARPAY_SEGMENT_EVENTS.CLICKED_BILL_DETAILS, {
          businessId: this.businessId,
          businessName: this.business.name,
          billId: id,
        });
      }
      if (transaction.paymentMethod === BillPaymentMethods.BNPL) {
        analytics.track(CLEARPAY_SEGMENT_EVENTS.CLICKED_BNPL_BILL_DETAILS, {
          businessId: this.businessId,
          businessName: this.business.name,
          billId: id,
          uploadType: transaction.isReceipt ? 'receipt' : 'invoice',
        });
      }
    },
    getFormattedStatusLabel(status) {
      return TransactionStatusLabel[status];
    },
    updateSelectedFilters(evt, key) {
      const updatedFilters = { ...this.selectedFilters, [key]: evt.value };
      this.areFiltersEmpty = Object.values(updatedFilters).every(
        (v) => v.length === 0,
      );
      this.$emit('on-selected-filters', updatedFilters);
    },
    async generateCsv() {
      this.$toast.add({
        severity: 'info',
        summary: this.$t('account.pages.subPageRepayments.generatingCsv'),
        detail: this.$t('account.pages.subPageRepayments.downloadWillBegin'),
        group: 'payments-toast',
        life: 3000,
      });

      await this.$store.dispatchApiAction('DOWNLOAD_PAYMENTS_CSV', {
        businessId: this.businessId,
        subtype: 'bills',
      });

      if (this.downloadCSVRequest.isSuccess) {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(this.csvDownload);
        downloadLink.download = this.downloadFilename;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
      } else {
        this.$toast.add({
          severity: 'error',
          summary: this.$t(
            'account.pages.subPageRepayments.failedToGenerateCsv',
          ),
          detail: this.$t(
            'account.pages.subPageRepayments.noTransactionsToDownload',
          ),
          group: 'payments-toast',
          life: 3000,
        });
      }
    },
    formattedDate(date) {
      try {
        const dateEDT = new Date(date);
        return moment.utc(dateEDT).format('MMMM DD, yyyy');
      } catch (error) {
        return date;
      }
    },
  },
  computed: {
    ...mapGetters([
      'allRepayments',
      'business',
      'businessId',
      'csvDownload',
      'selectedBill',
    ]),
    ...mapRequestStatuses({
      downloadCSVRequest: 'DOWNLOAD_PAYMENTS_CSV',
    }),
    dueDateName() {
      return this.dueDate.toString();
    },
    monthYearFilters() {
      return (this.filters.monthYears ?? []).map((value) => ({
        label: new Date(this.formattedDate(value)).toLocaleDateString('en-US', {
          month: 'long',
          year: 'numeric',
        }),
        value,
      }));
    },
    invoiceIdFilters() {
      return (this.filters.invoiceIds ?? []).map((value) => ({
        label: getShortBillId(value),
        value,
      }));
    },
    vendorFilters() {
      return (this.filters.vendors ?? []).map((value) => ({
        label: value,
        value,
      }));
    },
    showStatusFilters() {
      return this.statusFilters.length && this.repaymentsType === 'history';
    },
    statusFilters() {
      return (this.filters.statuses ?? []).map((value) => ({
        label: TransactionStatusLabel[value],
        value,
      }));
    },
    currencyCodes: () => CurrencyCode,
    showDueDateFilters() {
      // eslint-disable-next-line no-unused-vars
      const { dueDate, ...rest } = this.selectedFilters;
      const filterCheck = !Object.values(rest).some(
        (filter) => filter.length > 0,
      );
      return (
        this.dueDateFilters.length &&
        filterCheck &&
        this.repaymentsType === 'upcoming'
      );
    },
    dueDateFilters() {
      return (this.filters.dueDate ?? []).map((value, i) => ({
        label:
          i > 0
            ? `${this.$t('account.pages.subPageRepayments.scheduledInNext', {
                numberOfDays: 30 * i,
              })}: ${this.$filters.currency(
                value,
                this.currencyCodes.USD,
                false,
              )}`
            : `${this.$t(
                'account.pages.subPageRepayments.scheduled',
              )}: ${this.$filters.currency(
                value,
                this.currencyCodes.USD,
                false,
              )}`,
        value: 30 * i,
      }));
    },
    vendorMap() {
      // create a vendor name map to have O(1) lookups
      const vendorMap = {};
      for (const value of this.filters.vendor ?? []) {
        vendorMap[value.id] = value.name;
      }
      return vendorMap;
    },
    downloadFilename() {
      return `${
        this.repaymentsType
      }-transactions-${new Date().toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      })}.csv`;
    },
    emptyMsg() {
      return this.areFiltersEmpty
        ? ''
        : this.$t('account.pages.subPageRepayments.tryClearingFilters');
    },
  },
};
</script>
<style lang="less" module>
.hidden {
  display: none;
}

.filter-container {
  margin: 32px 0;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 950px) {
    flex-wrap: wrap;
  }

  .float-left {
    display: inline-flex;
    align-items: center;

    @media only screen and (max-width: 500px) {
      display: flex;
      flex-wrap: wrap-reverse;
    }

    @media only screen and (max-width: 950px) {
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    > * {
      margin: 3px;

      @media only screen and (max-width: 500px) {
        flex: 100%;
        width: 100%;
      }
    }
  }

  .float-right {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    @media only screen and (max-width: 950px) {
      justify-content: space-between;
    }

    > * {
      margin: 3px;
      max-width: 12em;

      @media only screen and (max-width: 500px) {
        flex: 50%;
        width: 100%;
        justify-content: space-between;
      }
    }

    .due-date-filter {
      max-width: 16em;

      @media only screen and (max-width: 500px) {
        flex: 50%;
        max-width: 9em;
        justify-content: space-between;
      }
    }
  }

  .download-csv-cta {
    width: 140px;
    background-color: @color-cc-purple-200;

    &:hover {
      background-color: @color-cc-purple-100 !important;
    }
  }

  @media only screen and (max-width: 500px) {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    top: unset;
    right: unset;
    margin-top: 20px;
  }
}

.table-container {
  border: 1px solid @color-grey-200;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  background-color: @color-white;

  .custom-column {
    font-family: Gerstner-Programm;
    font-weight: normal;
  }

  tr[class^='row_'] {
    cursor: pointer;
  }

  .transaction-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;

    img {
      margin-left: auto;
      cursor: pointer;

      &:hover {
        background: #aeaeae;
        border-radius: 50%;
      }
    }
  }
}
</style>
