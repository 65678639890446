// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h2.heading_cCfCd{font-size:21px;line-height:30px;font-weight:500;margin:0 0 24px}.sub-heading_puDFB{margin:0 auto 2rem;max-width:550px}@media screen and (max-width:500px){.sub-heading_puDFB,h2.heading_cCfCd{text-align:left}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "heading_cCfCd",
	"sub-heading": "sub-heading_puDFB"
};
export default ___CSS_LOADER_EXPORT___;
