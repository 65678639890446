<template>
  <Modal
    v-model:visible="showModal"
    data-cy="modal-cratejoy"
    :header="$t('components.connectCrateJoy.connectYourCrateJoyAccount')"
    :modal="true"
    :draggable="false"
    append-to="self"
    :close-button-props="{ 'aria-label': 'close' }"
    @show="$emit('modalAction', SALES_PLATFORMS.CRATEJOY, 'open')"
    @hide="$emit('modalAction', SALES_PLATFORMS.CRATEJOY, 'close')"
  >
    <div :class="$style.container">
      <ol :class="$style.list">
        <li>
          <p>
            <i18n-t
              keypath="components.modalCreateJoy.loginToYourCrateJoyAccount"
            >
              <template #account>
                <a
                  href="https://my.cratejoy.com/v/login"
                  target="_blank"
                  @click="trackLinkClick"
                >
                  {{ $t('components.modalCreateJoy.account') }}
                </a>
              </template>
            </i18n-t>
          </p>
        </li>
        <li>
          <p>
            <i18n-t keypath="components.modalCreateJoy.generateAndEnter">
              <template #clientId>
                <strong>{{ $t('components.modalCreateJoy.clientId') }}</strong>
              </template>
              <template #clientSecretKey>
                <strong>{{
                  $t('components.modalCreateJoy.clientSecretKey')
                }}</strong>
              </template>
            </i18n-t>
          </p>
        </li>
      </ol>
      <input-with-eyebrow
        v-model="cratejoyAuth.apiClientId"
        :class="$style['input-fields']"
        :placeholder="$t('components.modalCreateJoy.clientId')"
        required
        name="Client ID"
        :validate-on-blur="validateOnBlur"
        @onBlur="trackTextEntered"
      />
      <input-with-eyebrow
        v-model="cratejoyAuth.apiSecretKey"
        :class="$style['input-fields']"
        :placeholder="$t('components.modalCreateJoy.clientSecretKey')"
        required
        name="Client Secret Key"
        :validate-on-blur="validateOnBlur"
        @onBlur="trackTextEntered"
      />
      <Message
        v-if="error"
        severity="error"
        :closable="true"
        @close="error = false"
      >
        <p>{{ $t('components.modalCreateJoy.somethingWentWrong') }}</p>
        <p>
          {{ $t('components.modalCreateJoy.ifThisIssuePersists') }}
          <a href="mailto:support@clear.co" target="_blank">support@clear.co</a>
        </p>
      </Message>
      <DSButton
        data-cy="connect-crate-joy-button"
        :label="$t('components.connectCrateJoy.connectCrateJoy')"
        class="p-button-secondary"
        :class="$style.button"
        @click="continueCratejoyIntegration"
      />
    </div>
  </Modal>
</template>

<script>
import { isMobileMixin } from '@/utils/vue-mixins';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { SALES_PLATFORMS } from '@/data/platforms';
import stateModuleAware from '@/mixins/state-module-aware';
import { validateChildren } from '@/composables/validation';
import analytics from '@/utils/analytics';
import InputWithEyebrow from '@/components/inputs/InputWithEyebrow';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import Modal from '@clearbanc/clear-components/dialog';

export default {
  components: {
    InputWithEyebrow,
    DSButton,
    Message,
    Modal,
  },
  mixins: [isMobileMixin, stateModuleAware],
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      cratejoyAuth: {
        apiClientId: '',
        apiSecretKey: '',
      },
      error: false,
      validateOnBlur: false,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(['productSegmentLabel']),
    ...mapRequestStatuses({
      addExternalAccountsRequest: 'ADD_USER_EXTERNAL_ACCOUNT',
    }),
    SALES_PLATFORMS: () => SALES_PLATFORMS,
  },
  methods: {
    open() {
      this.error = false;
      this.cratejoyAuth.apiClientId = '';
      this.cratejoyAuth.apiSecretKey = '';
      this.showModal = true;
    },
    async continueCratejoyIntegration() {
      this.validateOnBlur = true;
      analytics.track('fe_cratejoy_connect_button_click', {
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
      });
      const error = await this.hasError();
      if (error) {
        this.$emit('error');
        return;
      }

      await this.$store.dispatchApiAction('ADD_USER_EXTERNAL_ACCOUNT', {
        type: SALES_PLATFORMS.CRATEJOY,
        cratejoyAuth: this.cratejoyAuth,
      });
      if (this.addExternalAccountsRequest.isSuccess) {
        this.$emit('success');
        this.error = false;
        this.dispatchToStateModule('UPDATE_STEP', {
          step: this.$route.name,
        });
        this.showModal = false;
      } else {
        this.error = true;
        const translatedError = this.addExternalAccountsRequest.error.errorCode
          ? this.$t(
              `backendError.${this.addExternalAccountsRequest.error.errorCode}`,
              this.addExternalAccountsRequest.error.details,
            )
          : this.addExternalAccountsRequest.errorMessage;
        this.$emit('oauthError', {
          provider: SALES_PLATFORMS.CRATEJOY,
          error: translatedError,
        });
      }
    },
    trackLinkClick() {
      analytics.track('fe_cratejoy_account_link_click', {
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
      });
    },
    trackTextEntered(inputFieldName) {
      analytics.track('fe_cratejoy_text_entered', {
        textField: inputFieldName,
        productSegment: this.productSegmentLabel,
        experiment: this.defaultConfig?.experiments?.modals,
      });
    },
  },
};
</script>

<style lang="less" module>
.container {
  text-align: left;
  max-width: 560px;
  margin: 0 auto;

  .list {
    list-style-type: decimal;
    padding-left: 1rem;

    li ~ li {
      margin-top: 1rem;
    }
  }

  .button {
    display: block;
    margin-top: 2rem;
  }

  .input-fields {
    max-width: 400px;
    margin-top: 2rem;
  }
}
</style>
