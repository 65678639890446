// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".first-connector_viTS5{border-top:1px solid #dadada}.logos-list_xZ8X0{text-align:left;padding:5px 10px;margin:40px 20px}.modal_reMrs,.modal_reMrs div{font-family:Montserrat,sans-serif}.modal_reMrs div div{text-transform:unset}.modal_reMrs .paragraph_knY1w{font-weight:500;font-size:15px;line-height:20px;margin:0 30px;white-space:pre-line}.modal_reMrs .button_TrNUY{color:#fff;background-color:#000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"first-connector": "first-connector_viTS5",
	"logos-list": "logos-list_xZ8X0",
	"modal": "modal_reMrs",
	"paragraph": "paragraph_knY1w",
	"button": "button_TrNUY"
};
export default ___CSS_LOADER_EXPORT___;
