import i18n from '@/plugins/i18n';

export const VENDOR_CATEGORIES = {
  facebook_ads: i18n.t('data.vendorCategories.facebookAds'),
  google_ads: i18n.t('data.vendorCategories.googleAds'),
  pinterest_ads: i18n.t('data.vendorCategories.pinterestAds'),
  amazon_ads: i18n.t('data.vendorCategories.amazonAds'),
  twitter_ads: i18n.t('data.vendorCategories.twitterAds'),
  linkedin_ads: i18n.t('data.vendorCategories.linkedinAds'),
  microsoft_bing_ads: i18n.t('data.vendorCategories.microsoftBingAds'),
  reddit_ads: i18n.t('data.vendorCategories.redditAds'),
  snapchat_ads: i18n.t('data.vendorCategories.snapchatAds'),
  other_marketing: i18n.t('data.vendorCategories.otherMarketing'),
  inventory: i18n.t('data.vendorCategories.inventory'),
  payroll: i18n.t('data.vendorCategories.payroll'),
  logistics: i18n.t('data.vendorCategories.logistics'),
  legal: i18n.t('data.vendorCategories.legal'),
  administrative_expenses: i18n.t(
    'data.vendorCategories.administrativeExpenses',
  ),
  other: i18n.t('data.vendorCategories.other'),
};

export const INDIEGOGO_VENDOR_CATEGORIES = {
  facebook_ads: i18n.t('data.indieGogoVendorCategories.facebookAds'),
  google_ads: i18n.t('data.indieGogoVendorCategories.googleAds'),
  other_marketing: i18n.t('data.indieGogoVendorCategories.otherMarketing'),
};
