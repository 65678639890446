<template>
  <div :class="$style.wrapper">
    <Box :class="$style['input-container']">
      <div>
        <div :class="$style['top-section']">
          <div>
            <Checkbox
              :name="OWNER_ROLES.OWNER"
              :model-value="isOwner"
              :class="$style['input-fields']"
              @input="addOrRemoveOwnerRole"
            >
              {{ $t('common.owner') }}
            </Checkbox>
            <Checkbox
              :name="OWNER_ROLES.DIRECTOR"
              :model-value="isDirector"
              :class="$style['input-fields']"
              @input="addOrRemoveOwnerRole"
            >
              {{ $t('components.formOwnersStepTwo.directorBoardMember') }}
            </Checkbox>
            <Checkbox
              v-if="canSelectedOwnerBeSignatory"
              :name="OWNER_ROLES.SIGNATORY"
              :model-value="isSignatory"
              :class="$style['input-fields']"
              :disabled="isOnboardingCheckboxDisabled"
              @input="addOrRemoveOwnerRole"
            >
              {{ $t('common.authorizedSignatory') }}
            </Checkbox>
          </div>
          <DownloadableFileList
            :class="$style['file-list']"
            :files="userUploads"
            :is-closable="false"
            shorten-filenames
            @trackUploadedDocClicked="handleDownloadFile"
          />
        </div>
        <div :class="sideContainerClasses(true)">
          <InputWithEyebrow
            name="firstName"
            :model-value="!!selectedOwner ? selectedOwner.firstName : ''"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.firstName')"
            :label="$t('common.firstName')"
            disabled
          />
          <InputWithEyebrow
            name="lastName"
            :model-value="!!selectedOwner ? selectedOwner.lastName : ''"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.lastName')"
            :label="$t('common.lastName')"
            disabled
          />
        </div>
        <div :class="sideContainerClasses(true)">
          <InputWithEyebrow
            name="email"
            type="email"
            :model-value="!!selectedOwner ? selectedOwner.email : ''"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.email')"
            :label="$t('common.email')"
            required
            disabled
            @input="updateOwnerParams"
          />
        </div>
        <div :class="sideContainerClasses(true)">
          <DropdownWithEyebrow
            :model-value="!!selectedOwner ? selectedOwner.jobTitle : ''"
            :options="jobTitleOptions"
            :placeholder="$t('common.jobTitle')"
            name="jobTitle"
            required
            :class="sideInputClasses(true)"
            :validate-on-blur="showError"
            :error="!!selectedOwner && !selectedOwner.jobTitle && showError"
            @input="updateOwnerParams"
          />
          <InputWithEyebrow
            name="phoneNumber"
            type="phoneWithCountry"
            :model-value="selectedOwner.phoneNumber"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.phoneNumber')"
            :label="$t('common.phoneNumber')"
            required
            :validate-on-blur="phoneNumberValid || showError"
            :error="!phoneNumberValid && showError"
            :error-msg="phoneNumberErrorMsg"
            :disabled="isOnboardingCheckboxDisabled"
            @input="updateOwnerParams"
          />
        </div>
        <Autocomplete
          id="auto"
          :model-value="selectedOwner.address.line1"
          name="line1"
          required
          :validate-on-blur="showError"
          :label="$t('common.address.homeAddressLine1')"
          :placeholder="$t('common.address.homeAddressLine1')"
          :class="[$style['input-fields'], $style['address']]"
          :error="!selectedOwner.address.line1 && showError"
          :eyebrow="$t('common.address.homeAddressLine1')"
          @address-selected="updateAddress"
          @onBlur="saveManualAddressEntry"
        />
        <InputWithEyebrow
          :model-value="!!selectedOwner ? selectedOwner.address.line2 : ''"
          name="line2"
          type="text"
          autofill="new-address"
          :class="[$style['input-fields'], $style['address']]"
          :placeholder="$t('common.address.homeAddressLine2')"
          :label="$t('common.address.homeAddressLine2')"
          @input="updateOwnerAddressParams"
        />
        <div :class="sideContainerClasses(true)">
          <InputWithEyebrow
            :model-value="!!selectedOwner ? selectedOwner.address.city : ''"
            name="city"
            type="text"
            autofill="new-address"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.address.city')"
            :label="$t('common.address.city')"
            required
            :validate-on-blur="showError"
            :error="!!selectedOwner && !selectedOwner.address.city && showError"
            @input="updateOwnerAddressParams"
          />
          <DropdownWithEyebrow
            v-if="
              !!stateOptionsForCountry(selectedOwner.address.country) &&
              !isMobile()
            "
            :model-value="!!selectedOwner ? selectedOwner.address.state : ''"
            :options="
              stateOptionsForCountry(
                selectedOwner.address.country || COUNTRY_CODES.CA,
              )
            "
            name="state"
            required
            :class="sideInputClasses(true)"
            :validate-on-blur="showError"
            :error="
              !!selectedOwner && !selectedOwner.address.state && showError
            "
            :placeholder="stateLabel(selectedOwner.address.country)"
            @input="updateOwnerAddressParams"
          />
        </div>
        <div :class="sideContainerClasses(false)">
          <DropdownWithEyebrow
            v-if="
              !!stateOptionsForCountry(selectedOwner.address.country) &&
              isMobile()
            "
            :model-value="!!selectedOwner ? selectedOwner.address.state : ''"
            :options="
              stateOptionsForCountry(
                selectedOwner.address.country || COUNTRY_CODES.CA,
              )
            "
            name="state"
            required
            :class="sideInputClasses(false)"
            :validate-on-blur="showError"
            :error="
              !!selectedOwner && !selectedOwner.address.state && showError
            "
            :placeholder="stateLabel(selectedOwner.address.country)"
            @input="updateOwnerAddressParams"
          />
          <InputWithEyebrow
            v-if="
              isCountryWithPostalCode(selectedOwner.address.country) &&
              isMobile()
            "
            :model-value="
              !!selectedOwner ? selectedOwner.address.postalCode : ''
            "
            autofill="new-address"
            name="postalCode"
            type="postalCode"
            :label="postalCodeLabel(selectedOwner.address.country)"
            required
            :validate-on-blur="showError"
            :special-number-regex="postalCodeRegexAccordingToCountry"
            :error="
              !!selectedOwner && !selectedOwner.address.postalCode && showError
            "
            :placeholder="postalCodeLabel(selectedOwner.address.country)"
            :class="sideInputClasses(false)"
            @input="updateOwnerAddressParams"
          />
        </div>
        <div :class="sideContainerClasses(true)">
          <InputWithEyebrow
            v-if="
              isCountryWithPostalCode(selectedOwner.address.country) &&
              !isMobile()
            "
            :model-value="
              !!selectedOwner ? selectedOwner.address.postalCode : ''
            "
            autofill="new-address"
            name="postalCode"
            type="postalCode"
            :label="postalCodeLabel(selectedOwner.address.country)"
            required
            :validate-on-blur="showError"
            :special-number-regex="postalCodeRegexAccordingToCountry"
            :error="
              !!selectedOwner && !selectedOwner.address.postalCode && showError
            "
            :placeholder="postalCodeLabel(selectedOwner.address.country)"
            :class="sideInputClasses(true)"
            @input="updateOwnerAddressParams"
          />
          <DropdownWithEyebrow
            :model-value="!!selectedOwner ? selectedOwner.address.country : ''"
            name="country"
            autofill="new-address"
            :placeholder="$t('common.address.country')"
            :label="$t('common.address.country')"
            required
            :class="sideInputClasses(true)"
            :options="countryOptionsWithPriority()"
            :validate-on-blur="showError"
            :error="
              !!selectedOwner && !selectedOwner.address.country && showError
            "
            @input="updateOwnerAddressParams"
          />
        </div>
        <div :class="sideContainerClasses(true)">
          <DropdownWithEyebrow
            :model-value="!!selectedOwner ? selectedOwner.citizenship : ''"
            name="citizenship"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.address.countryOfCitizenship')"
            :label="$t('common.address.countryOfCitizenship')"
            required
            :options="
              countryOptionsWithPriority([
                COUNTRY_CODES.US,
                COUNTRY_CODES.CA,
                COUNTRY_CODES.GB,
              ])
            "
            :validate-on-blur="showError"
            :error="!!selectedOwner && !selectedOwner.citizenship && showError"
            @input="updateOwnerParams"
          />
          <InputWithEyebrow
            name="birthday"
            type="date"
            :model-value="!!selectedOwner ? selectedOwner.birthday : ''"
            :class="sideInputClasses(true)"
            :placeholder="$t('common.dateOfBirth')"
            :label="$t('common.dateOfBirth')"
            :eyebrow="$t('common.dateOfBirth')"
            required
            :validate-on-blur="showError"
            :error="!!selectedOwner && !selectedOwner.birthday && showError"
            @input="updateOwnerParams"
          />
        </div>
        <InputWithEyebrow
          v-if="!!personalTaxIdCountry"
          name="personalTaxId"
          :model-value="!!selectedOwner ? selectedOwner.personalTaxId : ''"
          type="number"
          required
          :label="personalTaxIdLabelShort(personalTaxIdCountry)"
          :placeholder="personalTaxIdLabelShort(personalTaxIdCountry)"
          :validate-on-blur="personalTaxIdError || showError"
          :error="!!selectedOwner && !!personalTaxIdError && showError"
          :error-msg="personalTaxIdError"
          :tooltip-msg="ssnTooltipMsg"
          :class="$style['input-fields']"
          @openTooltip="trackTooltipClick('open')"
          @closeTooltip="trackTooltipClick('close')"
          @input="updateOwnerParams"
          @onBlur="trackInputUpdate('SSN')"
        />
        <InputWithEyebrow
          v-if="!personalTaxIdCountry"
          name="passportNumber"
          placeholder="Passport Number"
          :model-value="!!selectedOwner ? selectedOwner.passportNumber : ''"
          :error="!!selectedOwner && !selectedOwner.passportNumber && showError"
          :validate-on-blur="showError"
          type="text"
          required
          label="Passport Number"
          :class="$style['input-fields']"
          @input="updateOwnerParams"
          @onBlur="trackInputUpdate('passportNumber')"
        />
      </div>
      <div class="c-form__row grid"></div>
      <UiMsg v-if="showUploadError" type="error">
        {{ computedErrorMsg }}
      </UiMsg>
      <div class="c-form__row">
        <p class="c-form__row__description">{{ idRequirements }}</p>
      </div>
      <div class="c-form__row">
        <UploadFileWithDownloadableFileList
          ref="uploadFileWithDownloadableFileList"
          :upload-filters="uploadFilters"
          :display-filters="displayFilters"
          :empty-prompt="uploadButtonText"
          theme="onboarding"
          :accepted-file-types="acceptedFileTypes"
          no-list
          :files="userUploads"
          @trackEvent="trackEvent"
          @dropzoneClicked="trackEvent('upload_doc_click')"
          @uploadDragged="trackEvent('upload_dragged')"
          @docsUploaded="trackDocsUploaded"
          @uploadFailed="trackUploadFailed"
          @trackUploadedDocClicked="trackEvent('uploaded_doc_clicked')"
          @fileRemove="removeUpload"
        />
      </div>
      <error-message v-if="errorMessage" :class="$style['error-container']">
        <template #message>
          <span>
            {{ errorMessage }}
            <span v-if="showErrorSupportContact">
              Please contact us at
              <a href="mailto:support@clear.co" target="_blank"
                >support@clear.co</a
              >.
            </span>
          </span>
        </template>
      </error-message>
      <slot name="cta" :class="$style['button-container']" />
    </Box>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { isMobileMixin } from '@/utils/vue-mixins';
import analytics from '@/utils/analytics';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import {
  countryOptionsWithPriority,
  isCountryWithPostalCode,
} from '@/data/country-code-with-names';
import {
  ENTITY_TYPE,
  ENVIRONMENTS,
  ID_TYPE,
  OWNER_ROLES,
  isAuthorizedOwner,
} from '@clearbanc/data-common-types';
import { JobTitlesByCorpCountry } from '@/data/owner';
import {
  personalTaxIdLabel,
  personalTaxIdRegex,
  personalTaxIdLabelShort,
  postalCodeLabel,
  stateLabel,
  stateOptionsForCountry,
  getPostalCodeRegexAccordingToCountry,
  idRequirementsByCountry,
} from '@/utils/local';
import {
  CONTACT_SUPPORT_MSG,
  shouldDisplayContactSupport,
  getDisplayedErrorMessage,
} from '@/data/error-messages';
import { validateChildren } from '@/composables/validation';
import DropdownWithEyebrow from '@/components/DropdownWithEyebrow';
import Autocomplete from '@/components/inputs/InputAutocompleteAddress';
import {
  DownloadableFileList,
  UploadFileWithDownloadableFileList,
} from '@/components';
import Checkbox from '@/components/Checkbox';
import Box from '@/components/Box';
import InputWithEyebrow from '@/components/inputs/InputWithEyebrow';
import { getAllCallingCodes, parsePhoneNumber } from '@/utils/phone-numbers';
import UiMsg from '@/components/UiMsg';
import { objectDiff } from '@/utils/object-comparison';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { validateSSN } from '@/utils/ssn';

export default {
  components: {
    DownloadableFileList,
    Checkbox,
    InputWithEyebrow,
    DropdownWithEyebrow,
    Autocomplete,
    Box,
    UiMsg,
    UploadFileWithDownloadableFileList,
  },
  mixins: [isMobileMixin],
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
    acceptedFileTypes: {
      type: String,
      default: 'image/jpeg,image/png,application/pdf',
    },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      errorMessage: '',
      showError: false,
      COUNTRY_CODES,
      OWNER_ROLES,
      ssnTooltipMsg: this.$t('components.formOwnersStepTwo.ssnTooltipMsg'),
      countryCallingCodes: getAllCallingCodes(),
      showUploadError: false,
      originalOwner: null,
    };
  },
  computed: {
    CONTACT_SUPPORT_MSG() {
      return CONTACT_SUPPORT_MSG(this.contactDetails.number);
    },
    ...mapGetters([
      'selectedOwner',
      'user',
      'business',
      'advanceInNegotiation',
      'advanceContracts',
      'contactDetails',
      'isDiligenceServiceReadDataEnabled',
      'businessOwners',
      'isBausIntegrationEnabled',
      'authorizedSignatory',
    ]),
    ...mapRequestStatuses({
      updateBusinessRequest: ['UPDATE_NON_PRIMARY_USER'],
      createBusinessRequest: ['CREATE_NON_PRIMARY_USER'],
      updateUserRequest: 'UPDATE_USER',
      fetchUserUploadDetailsRequest: 'GET_USER_UPLOAD_DETAILS',
    }),
    currentRoute() {
      return this.$route.name;
    },
    requiredDropdownFieldsComplete() {
      const stateIsRequired = stateOptionsForCountry(
        this.selectedOwner.address.country || COUNTRY_CODES.CA,
      );
      const isJobTitleValid = !!this.selectedOwner?.jobTitle;
      const isCountryValid = !!this.selectedOwner?.address?.country;
      const isCitizenshipValid = !!this.selectedOwner?.citizenship;

      let isStateValid = true;
      if (stateIsRequired) {
        isStateValid = !!this.selectedOwner?.address?.state;
      }

      return (
        isJobTitleValid && isCountryValid && isCitizenshipValid && isStateValid
      );
    },
    phoneNumberValid() {
      if (!this.selectedOwner?.phoneNumber) {
        return false;
      }
      const parsedNumber = parsePhoneNumber(this.selectedOwner.phoneNumber);
      return isValidPhoneNumber(
        this.selectedOwner.phoneNumber,
        parsedNumber?.country ?? 'US',
      );
    },
    phoneNumberErrorMsg() {
      if (!this.selectedOwner?.phoneNumber) {
        return this.$t('common.thisFieldIsRequired');
      }

      return this.selectedOwner.phoneNumber.length >= 4
        ? this.$t('common.phoneNumberNotValid')
        : this.$t('common.thisFieldIsRequired');
    },
    personalTaxIdError() {
      if (!this.selectedOwner?.personalTaxId) {
        return false;
      }
      try {
        validateSSN(this.selectedOwner?.personalTaxId);
      } catch (err) {
        return err.message;
      }
      return null;
    },
    postalCodeRegexAccordingToCountry() {
      return getPostalCodeRegexAccordingToCountry(
        this.selectedOwner?.address?.country,
      );
    },
    isPrimary() {
      return !!this.selectedOwner?.isPrimary;
    },
    jobTitleOptions() {
      return JobTitlesByCorpCountry(this.business.corpCountry);
    },
    uploadFilters() {
      return {
        metaId: `${this.selectedOwner?.id}`,
        entity: this.isPrimary ? ENTITY_TYPE.USER : ENTITY_TYPE.OWNER,
        type: 'passport_or_license',
      };
    },
    displayFilters() {
      return this.isDiligenceServiceReadDataEnabled
        ? {
            externalId: `${this.selectedOwner?.id}`,
            externalIdType: this.isPrimary
              ? ENTITY_TYPE.USER
              : ENTITY_TYPE.OWNER,
            documentType: [ID_TYPE.ID, ID_TYPE.PASSPORT],
          }
        : {
            metaId: `${this.selectedOwner?.id}`,
            entity: this.isPrimary ? ENTITY_TYPE.USER : ENTITY_TYPE.OWNER,
            type: [
              'passport_or_license',
              ID_TYPE.ID,
              ID_TYPE.PASSPORT,
              ID_TYPE.DRIVERS_LICENSE,
            ],
          };
    },
    userUploads() {
      return this.$store.getters.uploadsMatching(this.displayFilters);
    },
    advanceContractUserSigned() {
      return this.advanceContracts[this.advanceInNegotiation.id]?.userSignedAt;
    },
    personalTaxIdCountry() {
      const { citizenship } = this.selectedOwner;
      if (
        [COUNTRY_CODES.US, COUNTRY_CODES.CA, COUNTRY_CODES.GB].includes(
          citizenship,
        )
      ) {
        return citizenship;
      }
      return null;
    },
    isSignatory() {
      return this.selectedOwner?.jobRoles?.includes(OWNER_ROLES.SIGNATORY);
    },
    isDirector() {
      return this.selectedOwner?.jobRoles?.includes(OWNER_ROLES.DIRECTOR);
    },
    isOwner() {
      return this.selectedOwner?.jobRoles?.includes(OWNER_ROLES.OWNER);
    },
    canSelectedOwnerBeSignatory() {
      const { jobTitle, jobRoles } = this.selectedOwner ?? {};
      const signatoryExistsAndBelongsToAnotherOwner =
        this.authorizedSignatory &&
        this.authorizedSignatory.id !== this.selectedOwner?.id;

      if (signatoryExistsAndBelongsToAnotherOwner) {
        return false;
      }

      return isAuthorizedOwner(jobTitle, jobRoles);
    },
    selectedOwnerJobTitle() {
      return this.selectedOwner?.jobTitle;
    },
    isRoleSelected() {
      return this.isOwner || this.isDirector;
    },
    isOnboardingCheckboxDisabled() {
      return this.isPrimary && !!this.advanceContractUserSigned;
    },
    showErrorSupportContact() {
      return shouldDisplayContactSupport(
        this.updateUserRequest.error?.errorCode,
      );
    },
    isMissingUserId() {
      return !this.userUploads.length;
    },
    idRequirements() {
      return idRequirementsByCountry(this.business.corpCountry);
    },
    computedErrorMsg() {
      return this.showUploadError
        ? this.idRequirements
        : CONTACT_SUPPORT_MSG(this.contactDetails.number);
    },
    uploadButtonText() {
      return this.isMobile()
        ? this.$t('common.messagePrompt.browse')
        : this.$t('common.messagePrompt.dragAndDropFilesOrBrowse');
    },
  },
  watch: {
    canSelectedOwnerBeSignatory(canBeSignatory) {
      if (!canBeSignatory) {
        this.addOrRemoveOwnerRole(false, OWNER_ROLES.SIGNATORY);
      }
    },
  },
  mounted() {
    this.$router
      .push({
        params: { id: this.selectedOwner.id },
      })
      .catch(() => {});
    this.originalOwner = _.cloneDeep(this.selectedOwner);
  },
  methods: {
    isCountryWithPostalCode,
    countryOptionsWithPriority,
    personalTaxIdLabelShort,
    personalTaxIdRegex,
    personalTaxIdLabel,
    postalCodeLabel,
    stateOptionsForCountry,
    stateLabel,
    sideContainerClasses(wrapOnMobile) {
      return {
        [this.$style['side-container']]: true,
        [this.$style.wrap]: wrapOnMobile,
      };
    },
    sideInputClasses(wrapOnMobile) {
      return {
        [this.$style['side-inputs']]: true,
        [this.$style['wrap-inputs']]: wrapOnMobile,
      };
    },
    addOrRemoveOwnerRole(addRole, role) {
      let ownerRoles = [...(this.selectedOwner?.jobRoles ?? [])];
      if (addRole) {
        ownerRoles.push(role);
      } else {
        ownerRoles = ownerRoles.filter((existingRole) => existingRole !== role);
      }
      this.updateOwnerParams(ownerRoles, 'jobRoles');
    },
    updateOwnerParams(val, name) {
      // if user changes citizenship to country other than US, CA, then reset
      // personalTaxId since it is only required for NA
      if (name === 'citizenship') {
        if (![COUNTRY_CODES.US, COUNTRY_CODES.CA].includes(val)) {
          this.updateOwnerParams(null, 'personalTaxId');
        }
      }
      this.$store.dispatch('UPDATE_OWNER_PARAMS', { [name]: val });
    },
    updateOwnerAddressParams(val, name) {
      this.$store.dispatch('UPDATE_OWNER_ADDRESS_PARAMS', { [name]: val });
    },
    updateAddress(id, autoAddress) {
      for (const [name, val] of Object.entries(autoAddress)) {
        this.updateOwnerAddressParams(val, name);
      }
    },
    saveManualAddressEntry(id, result) {
      this.trackInputUpdate('home_address');
      this.updateOwnerAddressParams(result.value, 'line1');
    },
    removeUpload(id) {
      this.$store.dispatchApiAction('DELETE_USER_UPLOAD', { id });
      this.trackEvent('doc_deleted');
    },
    trackDocsUploaded(uploadData) {
      this.showUploadError = false;
      this.trackEvent('docs_uploaded', { numDocs: uploadData.numDocs });
    },
    trackUploadFailed(uploadData) {
      this.trackEvent('upload_failed', {
        userId: this.user.id,
        businessId: this.business.id,
        errorMsg: uploadData.errorMsg,
      });
    },
    async validateForm() {
      this.showError = true;
      const error = await this.hasError();
      if (
        error ||
        this.personalTaxIdError ||
        !this.phoneNumberValid ||
        !this.requiredDropdownFieldsComplete
      ) {
        return;
      }
      window.scrollTo(0, 0);

      this.showUploadError = false;
      if (this.isMissingUserId) {
        this.showUploadError = true;
        this.trackEvent('error_banner_displayed', {
          errorMsg: this.idValidationErrorMsg,
        });
        return;
      }
      // skip check in unit tests
      if (process.env.CLEARBANC_ENV !== ENVIRONMENTS.TEST) {
        if (await this.hasError()) {
          return;
        }
      }

      const ownerToUpdate = _.omit(this.selectedOwner, 'agreedToKyc');

      this.isPrimary
        ? await this.saveUser(ownerToUpdate)
        : await this.saveSecondaryUser(ownerToUpdate);

      this.trackEvent('self_serve_stage_complete', {
        businessId: this.business.id,
        userId: this.user.id,
        stage: this.currentRoute,
      });
    },
    async saveUser(owner) {
      if (this.isBausIntegrationEnabled) {
        const changedProps = objectDiff(owner, this.originalOwner).changedProps;
        if (!Object.keys(changedProps)?.length) {
          return;
        }
        await this.$store.dispatchApiAction('UPDATE_USER', changedProps);
      } else {
        await this.$store.dispatchApiAction('UPDATE_USER', owner);
      }
      if (!this.updateUserRequest.isSuccess) {
        const { message } = this.updateUserRequest.error;
        this.errorMessage = getDisplayedErrorMessage(
          this.updateUserRequest.error,
        );
        this.trackEvent('self_serve_error', {
          errorMessage: message,
        });
        return;
      }
      this.errorMessage = undefined;
      this.$emit('validationSuccess');
    },
    async saveSecondaryUser(owner) {
      const isNewOwner = !this.businessOwners.some(
        (existingOwner) => existingOwner.id === owner.id,
      );

      let error;
      if (isNewOwner) {
        const newUser = await this.$store.dispatchApiAction(
          'CREATE_NON_PRIMARY_USER',
          owner,
        );
        // SC-177347 remove this condition when we rollout multi user
        const createdId = this.isBausIntegrationEnabled
          ? newUser.id
          : newUser.owners.find(({ email }) => email === owner.email)?.id;
        await this.$store.dispatch('SET_SELECTED_OWNER', createdId);

        error = this.createBusinessRequest.error;
      } else if (this.isBausIntegrationEnabled) {
        const changedProps = objectDiff(owner, this.originalOwner).changedProps;
        if (Object.keys(changedProps)?.length) {
          await this.$store.dispatchApiAction('UPDATE_NON_PRIMARY_USER', {
            ...changedProps,
            id: owner.id,
          });
          error = this.updateBusinessRequest.error;
        }
      } else {
        await this.$store.dispatchApiAction('UPDATE_NON_PRIMARY_USER', owner);
        error = this.updateBusinessRequest.error;
      }

      if (error) {
        this.errorMessage = error.message;
        this.trackEvent('self_serve_error', {
          errorMessage: this.errorMessage,
        });
        return;
      }
      this.$emit('validationSuccess');
    },
    handleDownloadFile() {
      this.trackEvent('uploaded_doc_clicked');
    },
    trackEvent(name, props) {
      analytics.track(`fe_${name}`, { ...this.eventTrackingFields, ...props });
    },
    trackInputUpdate(name) {
      this.trackEvent(`${name}_capture`, {
        businessId: this.business.id,
        userId: this.user.id,
      });
    },
    trackTooltipClick(type) {
      this.trackEvent('tooltip_click', {
        content: 'ssn/sin',
        type,
      });
    },
  },
};
</script>

<style lang="less" module>
.wrapper {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: @gutter-10;
  font-family: 'Montserrat';
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.top-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
  }
}
.input-container {
  width: 650px;
  @media screen and (max-width: 700px) {
    width: unset;
  }
  .file-list {
    margin-bottom: 10px;
    @media screen and (max-width: 500px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .input-fields {
    margin-bottom: 20px;
    max-height: 40px;
  }

  .address {
    margin-bottom: 30px;
  }

  .side-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &.wrap {
      @media screen and (max-width: 500px) {
        flex-flow: row wrap;
      }
    }
    .side-inputs {
      height: 40px;
      min-height: unset;
      margin-bottom: 30px;
      &:nth-child(2) {
        margin-left: 15px;
        &.wrap-inputs {
          @media screen and (max-width: 500px) {
            margin-left: unset;
          }
        }
      }
    }
  }
}
.button-container {
  margin-top: 30px;
}

.error-container {
  margin-bottom: 10px;
  text-align: left;
}
</style>
