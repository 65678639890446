import _ from 'lodash';
import { differenceInHours } from 'date-fns';
import { CONNECTION_STATUS } from '@/data/connection-types';
import { buildApiActions } from '@/utils/vuex-api-utils';

// check if all accounts have been connected less than 24 hours
// use first connection date as right now last connection date is not reliable
const _areAllAccountsImporting = (allAccounts) => {
  if (allAccounts == null) return false;
  if (allAccounts.length === 0) return false;
  let allInProgress = true;
  for (const account of allAccounts) {
    if (differenceInHours(new Date(), new Date(account.createdAt)) >= 24) {
      allInProgress = false;
      break;
    }
  }
  return allInProgress;
};

export default {
  state: {
    externalAccounts: {},
    oauthPayload: null,
  },
  getters: {
    externalAccounts: (state) => _.values(state.externalAccounts),
    connectedAccountingAccounts(state, getters) {
      getters.externalAccounts.filter(
        (account) =>
          account.platformType === 'accounting' &&
          account.state === CONNECTION_STATUS.CONNECTED,
      );
    },
    connectedMarketingAccounts: (state, getters) => {
      if (
        getters.hasConnectedFacebookAccount &&
        getters.hasConnectedGoogleAdAccount &&
        getters.hasConnectedAmazonAdsAccount
      ) {
        return getters.externalAccounts.filter(
          (account) =>
            account.platformType === 'marketing' &&
            account.state === CONNECTION_STATUS.CONNECTED,
        );
      } else if (getters.hasConnectedGoogleAdAccount) {
        return getters.externalAccounts.filter(
          (account) =>
            account.platformType === 'marketing' &&
            account.platform === 'googleads' &&
            account.state === CONNECTION_STATUS.CONNECTED,
        );
      } else if (getters.hasConnectedFacebookAccount) {
        return getters.externalAccounts.filter(
          (account) =>
            account.platformType === 'marketing' &&
            account.platform === 'facebookad' &&
            account.state === CONNECTION_STATUS.CONNECTED,
        );
      } else if (getters.hasConnectedAmazonAdsAccount) {
        return getters.externalAccounts.filter(
          (account) =>
            account.platformType === 'marketing' &&
            account.platform === 'amazonads' &&
            account.state === CONNECTION_STATUS.CONNECTED,
        );
      }
      return [];
    },
    connectedSalesAccounts: (state, getters) =>
      getters.externalAccounts.filter(
        (account) =>
          !account.ignoreRevenueAt &&
          account.platformType === 'sales' &&
          account.state === CONNECTION_STATUS.CONNECTED,
      ),
    disconnectedSalesAccounts: (state, getters) =>
      getters.externalAccounts.filter(
        (account) =>
          !account.ignoreRevenueAt &&
          account.platformType === 'sales' &&
          account.state === CONNECTION_STATUS.DISCONNECTED,
      ),
    connectedBillingAccounts(state, getters) {
      const isBillingAccount = (account) =>
        account.platformType === 'recurring_billing' ||
        ['stripe', 'braintree'].includes(account.platform);

      return getters.externalAccounts.filter(
        (account) =>
          isBillingAccount(account) &&
          account.state === CONNECTION_STATUS.CONNECTED,
      );
    },
    billingAccountsConnected: (state, getters) =>
      getters.connectedBillingAccounts.length > 0,
    billingOrManualAccountsConnected(state, getters, rootState, rootGetters) {
      return (
        getters.billingAccountsConnected ||
        rootGetters.manualReadOnlyAccountsConnected
      );
    },
    marketingAccountsImportInProgress: (state, getters) => {
      const allAccounts = getters.connectedMarketingAccounts;
      return _areAllAccountsImporting(allAccounts);
    },
    salesAccounts: (state, getters) =>
      _.filter(getters.externalAccounts, { platformType: 'sales' }),
    salesAccountsImportInProgress: (state, getters) => {
      const allAccounts = getters.connectedSalesAccounts;
      return _areAllAccountsImporting(allAccounts);
    },
    marketingAccounts(state, getters) {
      const isMarketingAccount = (account) =>
        account.platformType === 'marketing' || account.platform === 'facebook';
      return getters.externalAccounts.filter(
        (account) => isMarketingAccount(account) && !account.ignoreRevenueAt,
      );
    },
    hasConnectedAccountingAccount(state, getters) {
      return getters.connectedAccountingAccounts?.length > 0;
    },
    salesAccountsConnected: (state, getters) =>
      getters.salesAccounts.length > 0,
    marketingAccountsConnected: (state, getters) =>
      getters.marketingAccounts.length > 0,
    hasActiveSalesAccounts: (state, getters) =>
      getters.connectedSalesAccounts.length > 0,
    hasDisconnectedSalesAccounts: (state, getters) =>
      getters.disconnectedSalesAccounts.length > 0,
    hasActiveMarketingAccounts: (state, getters) =>
      getters.connectedMarketingAccounts.length > 0,
    noAccountsConnected: (state, getters) =>
      !getters.hasActiveSalesAccounts && !getters.hasActiveMarketingAccounts,
    hasConnectedGoogleAdAccount: (state, getters) =>
      getters.externalAccounts.filter(
        (account) =>
          account.platformType === 'marketing' &&
          account.platform === 'googleads' &&
          account.state === CONNECTION_STATUS.CONNECTED,
      ).length > 0,
    hasConnectedFacebookAccount: (state, getters) =>
      getters.externalAccounts.filter(
        (account) =>
          account.platformType === 'auth' &&
          account.platform === 'facebook' &&
          account.state === CONNECTION_STATUS.CONNECTED,
      ).length > 0,
    hasConnectedAmazonAdsAccount: (state, getters) =>
      getters.externalAccounts.filter(
        (account) =>
          account.platformType === 'marketing' &&
          account.platform === 'amazonads' &&
          account.state === CONNECTION_STATUS.CONNECTED,
      ).length > 0,
    hasConnectedMarketingAccount: (state, getters) =>
      getters.connectedMarketingAccounts.length,
    hasBigCommerceAccount: (state, getters) =>
      getters.externalAccounts.filter(
        (account) =>
          account.platform === 'bigcommerce' &&
          account.state === CONNECTION_STATUS.CONNECTED,
      ).length > 0,
    oauthPayload: (state) => state.oauthPayload,
  },
  ...buildApiActions(
    {
      // fetch all external accounts for a business
      FETCH_USER_EXTERNAL_ACCOUNTS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/businesses/${ctx.rootGetters.businessId}/external-accounts`,
        }),
        mutation: (state, { response }) => {
          state.externalAccounts = response.reduce(
            (accumulator, currentValue) => {
              const account = currentValue.account;
              return {
                ...accumulator,
                [account.id]: {
                  ...account,
                },
              };
            },
            {},
          );
        },
      },
      GET_USER_EXTERNAL_ACCOUNT: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/external-accounts/${payload.id}`,
        }),
        mutation: (state, { response }) => {
          state.externalAccounts[response.id] = response;
        },
      },
      UPDATE_USER_EXTERNAL_ACCOUNT: {
        action: (ctx, payload) => ({
          method: payload.action ? 'post' : 'patch',
          url: [`/external-accounts/${payload.id}`, payload.action].join('/'),
          params: _.omit(payload, 'id', 'action'),
        }),
        mutation: (state, { response }) => {
          state.externalAccounts[response.id] = response;
        },
      },
      ADD_USER_EXTERNAL_ACCOUNT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/businesses/${ctx.rootGetters.businessId}/external-accounts`,
          params: {
            ..._.omit(payload, 'id'),
            options: { timeout: 30000 },
          },
        }),
        mutation: (state, { response }) => {
          state.externalAccounts[response.id] = response;
        },
      },
    },
    {
      actions: {
        SET_OAUTH_PAYLOAD: ({ commit }, oauthPayload) => {
          commit('SET_OAUTH_PAYLOAD', oauthPayload);
        },
      },
      mutations: {
        SET_OAUTH_PAYLOAD: (state, oauthPayload) => {
          state.oauthPayload = oauthPayload;
        },
      },
    },
  ),
};
