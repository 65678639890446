// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".explainer-text_IDefX{max-width:615px}.explainer-text_IDefX:first-child{margin-top:0}div[class^=inner-container] div[class^=spinner]{margin:0 auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"explainer-text": "explainer-text_IDefX"
};
export default ___CSS_LOADER_EXPORT___;
