<template>
  <div :class="$style['dropzone-container']">
    <UploadFileWidget
      ref="uploadFileWidget"
      :class="$style['upload-zone']"
      :style="{ display: this.file ? 'none' : 'block' }"
      data-cy="upload-file-widget"
      name="uploadData"
      :empty-prompt="`\n ${this.emptyPrompt}`"
      :upload-filters="uploadFilters"
      no-list
      single-upload
      :hideable="false"
      :auto-upload="this.autoUpload"
      :theme="this.theme || 'onboarding'"
      @docUploaded="handleFileUploaded"
    />
    <div
      v-if="this.file"
      :class="$style['uploaded-item']"
      data-cy="uploaded-file-panel"
    >
      <span>
        {{ this.file.name }}
      </span>
      <svg
        @click="handleRemoveUpload"
        @keydown="handleRemoveUpload"
        v-html="require('@/assets/icons/close-icon.svg')"
      ></svg>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
  props: {
    uploadFilters: Object,
    theme: String,
    file: Object,
    autoUpload: { type: Boolean, default: true },
    emptyPrompt: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t(
          'account.pages.pageVendors.uploadInvoiceModal.dragAndDrop',
        );
      },
    },
  },
  methods: {
    handleFileUploaded(file) {
      if (file) {
        const { name, size, key, url, type: contentType, dbProps } = file;
        const selectedFile = {
          name,
          size,
          key,
          url,
          contentType,
          ...dbProps,
        };
        this.$emit('onUpload', selectedFile);
      }
    },
    handleRemoveUpload() {
      // just using the dropzone removeAllFiles method since it's already in the component
      this.$refs.uploadFileWidget.removeAllFiles(this.file);
      this.$emit('onRemove');
    },
    async processFiles(uploadFilters) {
      await this.$refs.uploadFileWidget.processFiles(uploadFilters);
    },
  },
};
</script>

<style lang="less" module>
.dropzone-container {
  text-align: center;
  .upload-zone {
    margin: 0 auto;
    max-width: 350px;
    margin-bottom: 25px;
  }
  .uploaded-item {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 0.75rem;
    }
    svg {
      height: 12px;
      width: 12px;
      cursor: pointer;
    }
  }
}
</style>
