// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-content-container_iym9U{text-align:left;max-width:560px;margin:0 auto;color:#666}.modal-content-container_iym9U p{margin-top:0;margin-bottom:1rem}.modal-content-container_iym9U p:last-child{margin-bottom:0}.modal-content-container_iym9U button.button-link_eHAmt{padding:0 4px}.modal-content-container_iym9U button.region-button_FFUSX{display:block;margin:0 auto}.modal-content-container_iym9U .dropdown_MAjaW{margin:1rem auto 2rem}@media only screen and (min-width:501px){.modal-content-container_iym9U .region-selection-heading_jV6N6{text-align:center}.modal-content-container_iym9U .dropdown_MAjaW{max-width:335px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-content-container": "modal-content-container_iym9U",
	"button-link": "button-link_eHAmt",
	"region-button": "region-button_FFUSX",
	"dropdown": "dropdown_MAjaW",
	"region-selection-heading": "region-selection-heading_jV6N6"
};
export default ___CSS_LOADER_EXPORT___;
