<template>
  <div :class="$style.container">
    <div :class="[$style['flex-container'], $style['rainbow-container']]">
      <div
        v-for="(_, index) in 5"
        :key="index"
        :class="[$style['flex-item'], $style['rainbow-item']]"
      >
        <div :class="$style['card']">
          <div :class="$style['card-title']">
            <div :class="$style['card-subtitle']">
              {{
                $t(
                  `account.pages.pageVendors.financeInvoiceHowItWorks.steps.${index}.subtitle`,
                )
              }}
            </div>
            {{
              $t(
                `account.pages.pageVendors.financeInvoiceHowItWorks.steps.${index}.title`,
              )
            }}
          </div>
          <p>
            {{ contentValues(index) }}
          </p>
        </div>
      </div>
    </div>
    <p :class="$style.title">
      {{ $t('account.pages.pageVendors.financeInvoiceHowItWorks.tableTitle') }}
    </p>
    <DataTable
      class="p-datatable-sm data-table"
      :value="tableValues"
      striped-rows
      responsive-layout="scroll"
      data-cy="table-test"
    >
      <Column
        :header="
          $t(
            'account.pages.pageVendors.financeInvoiceHowItWorks.tablePayments.title',
          )
        "
        field="payment"
      />
      <Column
        :header="
          $t(
            'account.pages.pageVendors.financeInvoiceHowItWorks.tableFees.title',
          )
        "
        field="fee"
      />
    </DataTable>
    <p :class="$style.disclaimer">
      {{ $t('account.pages.pageVendors.financeInvoiceHowItWorks.disclaimer') }}
    </p>
    <slot name="call-to-action" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Column from '@clearbanc/clear-components/column';
import DataTable from '@clearbanc/clear-components/datatable';

const feeRateFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export default {
  components: {
    Column,
    DataTable,
  },
  computed: {
    ...mapGetters(['advertisedRates']),
    tableValues() {
      if (
        this.advertisedRates &&
        Object.keys(this.advertisedRates).length !== 0
      ) {
        const useMarketingRates = this.advertisedRates.useMarketingRates;
        const sortedRates = Array.from(this.advertisedRates.rates).sort(
          (leftRatesItem, rightRatesItem) => {
            return leftRatesItem.num_months - rightRatesItem.num_months;
          },
        );

        return sortedRates.map((ratesItem) => ({
          payment: this.$i18n.tc(
            'account.pages.pageVendors.financeInvoiceHowItWorks.tablePayments.item',
            ratesItem.num_months, // used to determine if plural
            { payments: ratesItem.num_months },
          ),
          fee: this.$i18n.t(
            'account.pages.pageVendors.financeInvoiceHowItWorks.tableFees.item',
            {
              feeRate: feeRateFormatter.format(100 * ratesItem.rate),
              defaultPrefix: useMarketingRates
                ? `${this.$i18n.t('paymentsPage.upTo')} `
                : '',
            },
          ),
        }));
      }

      return [
        {
          payment: this.$i18n.t(
            'account.pages.pageVendors.financeInvoiceHowItWorks.tablePayments.itemLoading',
          ),
          fee: this.$i18n.t(
            'account.pages.pageVendors.financeInvoiceHowItWorks.tableFees.itemLoading',
          ),
        },
      ];
    },
  },
  async mounted() {
    await this.$store.dispatchApiAction('GET_ADVERTISED_FEES');
  },
  methods: {
    contentValues(index) {
      const receiptContentVersion = this.$t(
        `account.pages.pageVendors.financeInvoiceHowItWorks.steps.${index}.receipt_content_version`,
      );
      const content = this.$t(
        `account.pages.pageVendors.financeInvoiceHowItWorks.steps.${index}.content`,
      );
      return receiptContentVersion || content;
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.p-component.data-table) {
  font-size: 14px;
}
</style>
<style lang="less" module>
.container {
  padding-bottom: 15px;
}

.title {
  font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: black;
}

.disclaimer {
  color: black;
  font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}

.flex-container {
  /* https://css-tricks.com/almanac/properties/a/align-items/ */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .flex-item {
    flex: 1;
    font-weight: bold;
    text-align: center;
    p {
      margin: 0;
      padding: 0;
    }
  }
}

// disable flex for container on mobile
// move card-title from top-left to center-left
// move paragraph text to center right
@media only screen and (max-width: 800px) {
  .flex-container {
    display: block;
  }
  .card {
    display: flex;
    align-items: center;
    .card-title {
      align-items: left;
      padding-right: 15px;
      text-align: center;
    }
    p {
      align-items: left;
    }
  }
}

.rainbow-container {
  padding: 28px 35px;
  background: radial-gradient(
    118.79% 153.25% at 40.92% 12.72%,
    #f5ab9b 1.92%,
    #eb83b8 60.33%,
    #a879f5 100%
  );
  border-radius: 8px;
  .rainbow-item {
    margin: 7px;
    padding: 8px 7px;
    background: radial-gradient(
      98.68% 212.38% at 1.32% 3.1%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    border-radius: 5px;
  }
}

.card {
  height: 100%;
  padding: 18px 14px;
  text-align: left;
  background: radial-gradient(
    98.68% 212.38% at 1.32% 3.1%,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  border-radius: 5px;
  font-family: 'Gerstner-Programm';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  .card-title {
    font-family: 'Gerstner-Programm';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    background: linear-gradient(
      89.95deg,
      #8b2fff 3.14%,
      #8b2fff 3.14%,
      #8b2fff 3.15%,
      #ff4686 98.22%
    );
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .card-subtitle {
    font-family: 'Gerstner-Programm';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    background: linear-gradient(
      89.95deg,
      #8b2fff 3.14%,
      #8b2fff 3.14%,
      #8b2fff 3.15%,
      #ff4686 98.22%
    );
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
