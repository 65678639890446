// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_ReKWI{text-align:left;max-width:560px;margin:0 auto}.container_ReKWI .list_KwrCM{list-style-type:decimal;padding-left:1rem}.container_ReKWI .list_KwrCM li~li{margin-top:1rem}.container_ReKWI .button_EC3KI{display:block;margin-top:1.5rem}.container_ReKWI button.button-link_WcPsF{padding:0 4px}.container_ReKWI .link_ZjFnS{text-decoration:underline;color:#0d3685;cursor:pointer}.container_ReKWI .input-fields_eB2c5{max-width:400px;margin-top:1.5rem}.container_ReKWI .upload-widget_FByVo{max-width:400px;margin-bottom:10px}.container_ReKWI .required-upload_GOnlW{color:#f05758;font-size:12px;margin-left:2px;line-height:12px;margin-top:-5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_ReKWI",
	"list": "list_KwrCM",
	"button": "button_EC3KI",
	"button-link": "button-link_WcPsF",
	"link": "link_ZjFnS",
	"input-fields": "input-fields_eB2c5",
	"upload-widget": "upload-widget_FByVo",
	"required-upload": "required-upload_GOnlW"
};
export default ___CSS_LOADER_EXPORT___;
