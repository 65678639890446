<template>
  <div :class="$style['bill-cancellation-modal']">
    <h2 :class="$style.title">Cancel payment</h2>
    <div v-if="shouldDisplayCancellationForm">
      <p :class="$style['cancel-box-text']">
        If you chose to pay this bill again, you will need to start the process
        over.<br />Are you sure you want to cancel?
      </p>
      <div :class="$style['bill-pay-popup-container']">
        <div>
          <Message
            severity="info"
            :closable="false"
            :class="$style['info-message']"
          >
            <p>It typically takes 1 business day to cancel a payment.</p>
          </Message>
          <div :class="$style['input-group']">
            <Dropdown
              v-model="cancellationType"
              :options="cancellationTypes"
              option-label="label"
              option-value="value"
              placeholder="Cancellation Reason"
              :class="$style['cancel-dropdown']"
            />
            <DSTextarea
              v-if="shouldDisplayOtherReason"
              v-model="cancellationReason"
              rows="4"
              cols="30"
              placeholder="Reason for cancelling payment"
              aria-label="Cancel Reason"
              :class="$style['cancel-textarea']"
            >
            </DSTextarea>
            <div v-if="showCancellationReasonRequired" :class="$style['error']">
              Cancel Reason is required
            </div>
          </div>

          <div :class="$style['paybill-bottom-action-cancel']">
            <DSButton
              label="Go back"
              class="p-button-link"
              :class="$style['button-link']"
              @click="$emit('prev-tab')"
              @keydown="$emit('prev-tab')"
            />
            <DSButton
              label="Yes, cancel this payment"
              class="p-button-secondary"
              :disabled="cancelInvoiceRequest.isPending"
              @click="confirmCancel"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div :class="$style['modal-details']">
        <div v-if="showSuccessModal">
          <svg
            :class="$style['modal-icon']"
            v-html="require('@/assets/icons/goodbye.svg')"
          ></svg>
          <p>Your bill has been successfully cancelled.</p>
          <p>
            If you wish to upload additional bills, please do so<br />
            through your dashboard.
          </p>
          <div :class="$style['modal-buttons']">
            <DSButton
              class="p-button-text p-button-help"
              label="Done"
              @click="$emit('done')"
            />
            <DSButton label="Submit a Bill" @click="handleNewPayment()" />
          </div>
        </div>
        <div v-else-if="showSignedContractModal">
          <p>
            Unfortunately you are unable to cancel a bill with<br />
            a signed contract.
          </p>
          <p>
            Please contact a member of our support team<br />
            for assistance.
          </p>
          <a href="mailto:payments@clear.co" @click="$emit('done')">
            payments@clear.co
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import useVuelidate from '@vuelidate/core';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import Message from '@clearbanc/clear-components/message';
import DSButton from '@clearbanc/clear-components/button';
import Textarea from '@clearbanc/clear-components/textarea';
import Dropdown from '@clearbanc/clear-components/dropdown';
import { BillCancellationType } from '@clearbanc/data-common-types';

export default {
  components: {
    DSButton,
    Message,
    Dropdown,
    DSTextarea: Textarea,
  },
  props: {
    dueDate: {
      default: new Date(),
    },
    isReceipt: {
      default: false,
      type: Boolean,
    },
    billId: {
      required: true,
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      PAYMENTS_ROUTE_NAMES,
      cancellationReason: '',
      cancellationType: null,
      showCancellationReason: false,
      showCancellationReasonRequired: false,
      showSuccessModal: false,
      showSignedContractModal: false,
    };
  },
  computed: {
    ...mapRequestStatuses({
      cancelInvoiceRequest: 'CANCEL_INVOICE',
    }),
    shouldDisplayCancellationForm() {
      return !this.showSuccessModal && !this.showSignedContractModal;
    },
    shouldDisplayOtherReason() {
      return this.cancellationType === BillCancellationType.OTHER;
    },
    cancellationTypes() {
      return Object.values(BillCancellationType).map((type) => ({
        value: type,
        label: this.$t(
          `account.pages.pageVendors.billDetails.cancellationTypes.${type}`,
        ),
      }));
    },
  },
  methods: {
    handleNewPayment() {
      this.$emit('newPayment');
      this.$emit('done');
    },
    async confirmCancel() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.showCancellationReasonRequired =
        !this.cancellationType ||
        (this.shouldDisplayOtherReason &&
          this.cancellationReason.trim() === '');

      if (!this.showCancellationReasonRequired) {
        const cancellationReason = this.shouldDisplayOtherReason
          ? this.cancellationReason
          : '';
        const payload = {
          cancellationReason,
          cancellationType: this.cancellationType,
          billId: this.billId,
        };
        await this.$store.dispatchApiAction('CANCEL_INVOICE', payload);

        const { error, isSuccess } = this.cancelInvoiceRequest;
        this.showSignedContractModal = error?.errorCode === 'BILL001';
        this.showSuccessModal = isSuccess;
      }

      if (this.showSuccessModal) {
        await this.$store.dispatchApiAction('GET_BILLS');
        await this.$store.dispatch('refreshAdvances');
      }
    },
  },
};
</script>

<style lang="less" module>
.bill-cancellation-modal h2.title {
  text-align: center;
  font-size: 18px;
  margin: 0;
  margin-top: 20px;
  font-weight: 600;
}
.bill-cancellation-modal p {
  max-width: 500px;
}
.cancel-box-text {
  font-size: 16px;
  text-align: center;
}
.bill-pay-popup-container {
  max-width: 640px;
  margin: 0 auto;
  text-align: center;
  padding: 0;
}

.paybill-bottom-action-cancel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 45px;
}
.cancel-dropdown {
  width: 100%;
}
textarea.cancel-textarea {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid @color-grey-200;
  transition: border-color ease-in-out 0.15s;
  padding: 9px 8px 7px;
  margin-top: 10px;
  min-height: 130px;
  color: @color-grey-400;
  font: inherit;
  border-radius: 4px;
}
.input-group {
  text-align: left;
  margin: 0 auto;
}
.error {
  color: @color-danger-300;
  font-size: 12px;
}
button.button-link {
  padding: 0;
}
.info-message {
  display: inline-block;
}
.modal-icon {
  width: 100%;
  max-width: 130px;
  padding-top: 10px;
  margin: 0 auto;
  display: block;
}
.modal-details {
  font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}
.modal-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  button {
    margin: 0 10px;
  }
}
</style>
