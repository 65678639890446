export const BANK_CONNECTION_STATUS_SUMMARY = {
  ERROR: 'error',
  CONNECTING: 'connecting',
  UNKNOWN: 'unknown',
  INITIAL_SCRAPE: 'initial_scrape',
  CONNECTED: 'connected',
  SCRAPING: 'scraping',
  NOT_CONNECTED: 'not_connected',
  PERMISSION_REVOKED: 'permission_revoked',
};

export const BANK_ERROR_STATUSES = [
  BANK_CONNECTION_STATUS_SUMMARY.ERROR,
  BANK_CONNECTION_STATUS_SUMMARY.UNKNOWN,
  BANK_CONNECTION_STATUS_SUMMARY.NOT_CONNECTED,
  BANK_CONNECTION_STATUS_SUMMARY.PERMISSION_REVOKED,
];

export const BANK_IMPORTING_STATUSES = [
  BANK_CONNECTION_STATUS_SUMMARY.CONNECTING,
  BANK_CONNECTION_STATUS_SUMMARY.INITIAL_SCRAPE,
  BANK_CONNECTION_STATUS_SUMMARY.SCRAPING,
];

export const BANK_CONNECTION_ACTIONS = {
  RELINK: 'relink',
  RETRY: 'retry',
  CONNECT_NEW: 'connect_new',
};
