// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inner-container_mXsdG{width:100%;min-height:100%;position:relative;top:0}.inner-container_mXsdG .spinner_Te2yJ{display:block;max-width:32px;margin:50vh auto 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner-container": "inner-container_mXsdG",
	"spinner": "spinner_Te2yJ"
};
export default ___CSS_LOADER_EXPORT___;
