<template>
  <div :class="$style.wrapper">
    <div>
      <h2 :class="$style.header__heading">Payment Obligations</h2>
      <p v-if="getSubtitle" :class="$style.subtitle">
        {{ getSubtitle }}
      </p>
    </div>

    <div
      v-if="
        currentDebtObligationDateChunk &&
        businessDebtObligations?.created_at &&
        debtObligationsRequired
      "
      :class="$style.prompt"
    >
      <i class="pi pi-clock"></i>
      Please provide your payment obligations for this period. You will be
      required to update these after
      {{ format(currentDebtObligationDateChunk.endDate, 'PPP') }}.
    </div>

    <div :class="$style.instructions">
      <h3 :class="$style.instructions__heading">
        <i
          class="pi pi-info-circle"
          :class="$style.instructions__heading__icon"
        ></i>
        What do I include?
      </h3>
      <div :class="$style.instructions__body">
        <p>
          We require you to update your payment obligations each month. For
          non-monthly payment obligations, please estimate the monthly payment.
          You can always return to add any missed items.
        </p>
        <p>
          <strong>Examples:</strong> Small Business Loans, Business Loans,
          Merchant Cash Advances, Revenue Share Agreements, Auto loans
        </p>
        <p>
          <a
            href="https://help.clear.co/s/article/How-to-apply-to-Clearco?language=en_US"
            target="_blank"
            >Learn More</a
          >
        </p>
      </div>
    </div>

    <div :class="$style['no-obligations']">
      <Checkbox
        v-model="userSelectedNoObligations"
        input-id="no-obligations"
        invalid
        binary
      />
      <label for="no-obligations"
        >I do <strong><u>not</u></strong> have payment obligations</label
      >
    </div>

    <div v-if="!userSelectedNoObligations" :class="$style['table-wrapper']">
      <table :class="$style.table">
        <tr>
          <th>
            Capital Provider
            <span style="color: red"> * </span>
          </th>
          <th>
            Monthly Amount
            <span style="color: red"> * </span>
          </th>
          <th>Total Balance Outstanding</th>
          <th>Estimated Pay Off Date</th>
          <th></th>
        </tr>
        <tr v-if="isLoading">
          <td colspan="5">
            <i class="pi pi-spin pi-spinner"></i>
          </td>
        </tr>
        <tr v-for="(entry, index) in debtObligations">
          <td
            v-if="
              entry.name !== null &&
              (entry.name === 'Other' || !vendors.includes(entry.name))
            "
            :class="$style.table__vendor"
          >
            <InputText
              v-model="entry.name"
              type="text"
              :class="(entry.name === 'Other' || !entry.name) && $style.error"
              placeholder="Enter a Provider"
              @input="validateInput(entry)"
            />
            <i
              v-if="entry.adminOverride"
              class="pi pi-exclamation-triangle"
              :class="$style['admin-override__icon']"
            ></i>
          </td>
          <td v-else :class="$style.table__vendor">
            <Dropdown
              v-model="entry.name"
              :options="vendors"
              :class="!entry.name && $style.error"
              placeholder="Select a Provider"
              @change="nameDropdownChange(entry)"
            />
            <i
              v-if="entry.adminOverride"
              class="pi pi-exclamation-triangle"
              :class="$style['admin-override__icon']"
            ></i>
          </td>
          <td :class="$style.table__monthly_amount">
            <InputNumber
              v-model="entry.monthlyValueDollars"
              :min="0"
              mode="currency"
              currency="USD"
              placeholder="$0.00"
              :class="
                (!entry.monthlyValueDollars ||
                  entry.monthlyValueDollars === 0) &&
                $style.error
              "
              @input="(event) => updateMonthlyValue(event, entry)"
            />
          </td>
          <td :class="$style.table__total_amount">
            <InputNumber
              v-model="entry.totalBalanceInDollars"
              :min="0"
              mode="currency"
              currency="USD"
              placeholder="$0.00"
              @input="(event) => updateTotalBalance(event, entry)"
            />
          </td>
          <td :class="$style.table__calendar">
            <Calendar
              v-model="entry.payoffDate"
              showtime="false"
              name="calendar"
              :min-date="new Date()"
              placeholder="Select a date"
            />
          </td>
          <td>
            <button
              :class="$style.table__remove"
              @click="removeObligation(index)"
            >
              <i class="pi pi-times-circle"></i>
            </button>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <button :class="$style.table__add" @click="addObligation">
              <i class="pi pi-plus"></i> Add An Item
            </button>
          </td>
          <td></td>
        </tr>
        <tr>
          <td><strong>Total</strong></td>
          <td>
            <strong>{{ formatCurrency(totalMonthlyObligations) }}</strong>
          </td>
          <td>
            <strong>{{ formatCurrency(totalObligationsBalances) }}</strong>
          </td>
          <td></td>
        </tr>
        <tr v-if="adminOverrideEntryExists(debtObligations)">
          <td colspan="3">
            <div :class="$style['admin-override']">
              <i
                class="pi pi-exclamation-triangle"
                :class="$style['admin-override__icon']"
              ></i>
              <p :class="$style['admin-override__text']">
                Updated by Internal Team
              </p>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div v-if="!userSelectedNoObligations">
      <p :class="$style.notes_label">Notes</p>
      <DSTextarea
        v-model="businessDebtObligations.notes"
        :auto-resize="true"
        rows="4"
        cols="30"
        aria-label="Debt Obligation Notes"
        placeholder="Add anything you want us to know"
        :class="!notesAreValid && $style['notes_textarea_error']"
        @input="assertMaxLength($event.value)"
      />
      <p v-if="!notesAreValid" :class="$style['notes_error_msg']">
        Notes must be less than 300 characters
      </p>
    </div>

    <div :class="$style.button_container">
      <div :class="$style.left_buttons">
        <DSButton
          class="p-button-outlined"
          :label="$t('common.buttons.back')"
          @click="
            $router.push({
              name: isInFinancialDocsSegment
                ? PAYMENTS_ROUTE_NAMES.PROFILE_BANK_DOCUMENTS
                : PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
            })
          "
        />
      </div>
      <div :class="$style.right_buttons" :style="{ margin: '0' }">
        <DSButton
          :label="$t('common.buttons.skipForNow')"
          class="p-button-text p-button-info"
          severity="info"
          :style="{ paddingRight: '40px' }"
          @click="skipForNow"
        />
        <DSButton
          :disabled="!userSelectedNoObligations && !alldebtObligationsAreValid"
          :label="$t('common.buttons.submit')"
          :loading="isSubmitting"
          @click="submitObligations"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DSButton from '@clearbanc/clear-components/button';
import Checkbox from '@clearbanc/clear-components/checkbox';
import InputNumber from '@clearbanc/clear-components/inputnumber';
import InputText from '@clearbanc/clear-components/inputtext';
import Dropdown from '@clearbanc/clear-components/dropdown';
import Calendar from '@clearbanc/clear-components/calendar';
import Textarea from '@clearbanc/clear-components/textarea';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { centsToDollars, dollarsToCents } from '@/utils/currency';
import analytics from '@/utils/analytics';
import { format } from 'date-fns';

export default {
  components: {
    DSButton,
    Checkbox,
    InputText,
    InputNumber,
    Dropdown,
    Calendar,
    DSTextarea: Textarea,
  },
  props: {
    section: { type: String, required: true },
    theme: { type: String, required: true },
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      PAYMENTS_ROUTE_NAMES,
      debtObligations: [],
      userSelectedNoObligations: false,
      vendors: [
        '8fig',
        'Ampla',
        'Bluevine',
        'Forward Finance',
        'Kabbage',
        'Ondeck Capital',
        'Parafin',
        'SellersFi',
        'Shopify',
        'Wayflyer',
        'Other',
      ],
      inputKey: 0, // This key is used to force re-render the InputText component
    };
  },
  computed: {
    ...mapGetters([
      'isInFinancialDocsSegment',
      'businessDebtObligations',
      'currentDebtObligationDateChunk',
      'businessId',
      'debtObligationsRequired',
    ]),
    getSubtitle() {
      const latestUpdate = this.businessDebtObligations?.updated_at;
      return latestUpdate
        ? `Last update: ${format(new Date(latestUpdate), 'PPP')}`
        : '';
    },
    totalMonthlyObligations() {
      return this.debtObligations?.reduce(
        (acc, entry) => acc + entry.monthlyValueDollars,
        0,
      );
    },
    totalObligationsBalances() {
      return this.debtObligations?.reduce(
        (acc, entry) => acc + (entry.totalBalanceInDollars ?? 0),
        0,
      );
    },
    notesAreValid() {
      if (!this.businessDebtObligations?.notes) return true;
      return this.businessDebtObligations?.notes?.length <= 300;
    },
    alldebtObligationsAreValid() {
      if (!this.notesAreValid) return false;

      if (!this.debtObligations?.length) {
        return false;
      }

      return this.debtObligations.every((entry) => {
        return (
          entry?.name !== 'Other' && entry?.name && entry?.monthlyValueDollars
        );
      });
    },
  },
  methods: {
    format,
    adminOverrideEntryExists(debtObligations) {
      return debtObligations.some((entry) => entry.adminOverride);
    },
    async submitObligations() {
      this.isSubmitting = true;

      if (this.userSelectedNoObligations) {
        await this.$store.dispatchApiAction('SAVE_DEBT_OBLIGATIONS', {
          debts: [],
        });
      } else {
        const obligationsToSubmit = this.formatObligations(
          this.clearAdminOverrides(this.debtObligations),
        );

        await this.$store.dispatchApiAction('SAVE_DEBT_OBLIGATIONS', {
          debts: obligationsToSubmit,
          notes: this.businessDebtObligations.notes,
        });
      }

      this.isSubmitting = false;
      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
      });
    },
    updateMonthlyValue(event, obligationsEntry) {
      const entryToUpdate = this.debtObligations.find(
        (obligation) => obligation === obligationsEntry,
      );
      entryToUpdate.monthlyValueDollars = event.value;
    },
    updateTotalBalance(event, obligationsEntry) {
      const entryToUpdate = this.debtObligations.find(
        (obligation) => obligation === obligationsEntry,
      );
      entryToUpdate.totalBalanceInDollars = event.value;
    },
    formatCurrency(value) {
      return value?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    validateInput(obligationToEdit) {
      if (!obligationToEdit || !obligationToEdit.name) {
        return;
      }

      const selectedObligation = this.debtObligations.find(
        (obligation) => obligationToEdit === obligation,
      );

      const sanitizedVendor = obligationToEdit.name.replace(
        /[^a-zA-Z0-9\s-]/g,
        '',
      );

      const updatedObligation = {
        ...selectedObligation,
        name: sanitizedVendor,
      };

      const index = this.debtObligations.indexOf(selectedObligation);
      if (index !== -1) {
        this.debtObligations.splice(index, 1, updatedObligation);
      }
    },
    convertToDollars(debtObligations) {
      return debtObligations.map((obligation) => {
        const updatedObligation = {
          ...obligation,
          monthlyValueDollars: centsToDollars(obligation.valueInCents),
          totalBalanceInDollars: centsToDollars(
            obligation.totalBalanceInCents ?? 0,
          ),
        };
        delete updatedObligation.valueInCents;
        delete updatedObligation.totalBalanceInCents;
        return updatedObligation;
      });
    },
    formatObligations(debtObligations) {
      return debtObligations.map((obligation) => {
        const updatedObligation = {
          ...obligation,
          valueInCents: dollarsToCents(obligation.monthlyValueDollars),
          totalBalanceInCents: dollarsToCents(obligation.totalBalanceInDollars),
          payoffDate: obligation.payoffDate
            ? format(new Date(obligation.payoffDate), 'yyyy-MM-dd')
            : null,
        };
        delete updatedObligation.monthlyValueDollars;
        delete updatedObligation.totalBalanceInDollars;
        return updatedObligation;
      });
    },
    clearAdminOverrides(debtObligations) {
      return debtObligations.map((obligation) => {
        const updatedObligation = {
          ...obligation,
          adminOverride: false,
        };
        return updatedObligation;
      });
    },
    removeObligation(index) {
      this.debtObligations.splice(index, 1);
    },
    addObligation() {
      this.debtObligations.push({
        name: null,
        monthlyValueDollars: null,
        totalBalanceInDollars: null,
        payoffDate: null,
        adminOverride: false,
      });
    },
    nameDropdownChange(entry) {
      const entryIndex = this.debtObligations.findIndex(
        (obligation) => obligation === entry,
      );

      if (entry.name === 'Other') {
        this.debtObligations[entryIndex].name = '';
      }
    },
    skipForNow() {
      analytics.track('if_payment_obligations_skip', {
        businessId: this.businessId,
      });

      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
      });
    },
  },
  async mounted() {
    await this.$store.dispatchApiAction('GET_DEBT_OBLIGATIONS');

    if (this.businessDebtObligations?.debts) {
      this.debtObligations = this.convertToDollars(
        this.businessDebtObligations.debts,
      );
    }
  },
};
</script>

<style>
.justify-center {
  justify-content: center !important;
}
</style>

<style lang="less" module>
.wrapper {
  display: flex;
  flex-direction: column;
}

h2.header__heading {
  font-size: 21px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
}

.subtitle {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 14px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .header__heading {
    text-align: left;
  }
}

.instructions {
  border: 1px solid #e0e0e1;
  border-radius: 4px;
  background: #f8f8fa;
  padding: 30px;
  margin: 0 auto 40px;
  text-align: left;
}

h3.instructions__heading {
  all: unset;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

i.instructions__heading__icon {
  font-size: 22px;
  margin-right: 10px;
}

.instructions__body {
  border-left: 1px solid #dfdfdf;
  padding-left: 20px;
  margin-left: 10px;
}

.instructions__body p a {
  color: #7f30ff;
}

.instructions__body p a:hover {
  color: #5b00f0;
}

// ---------------

.prompt {
  text-align: left;
  border-radius: 4px;
  padding: 20px 30px;
  background: #ffecbc;
  line-height: 1.4;
  display: flex;
  align-items: center;
  margin: 0 auto 20px;
  max-width: 560px;
  color: #684d25;
}

.prompt i {
  margin-right: 14px;
  color: #e8a524;
  font-size: 22px;
}

.table-wrapper {
  position: relative;
  border-radius: 4px;
  border: 1px solid #e0e0e1;
  display: inline-block;
  margin: 0 auto 40px;
  width: 100%;
  overflow: scroll;
}

.table {
  width: 100%;
  text-align: right;
  white-space: nowrap;
}

.table input {
  text-align: left;
}

.table th {
  border-bottom: 1px solid #e0e0e1;
}

table th,
table td {
  position: relative;
}

.table th,
.table td {
  text-align: left;
}

.table th,
.table td {
  padding: 10px 16px;
}

.table th + th,
.table td + td {
  padding-left: 0;
}

.table tr + tr {
  border-top: 1px dashed #e0e0e1;
}

.table tr + tr:nth-last-child(-n + 2) {
  border-style: solid;
}

.table__add {
  color: #7f30ff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.table__add:hover {
  color: #5b00f0;
}

.table__add i {
  margin-right: 6px;
}

.table__remove {
  color: #7f30ff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s;
}

.table__remove:hover {
  color: #5b00f0;
}

.table__remove i {
  font-size: 22px;
}

.table__vendor {
  width: 25%;
  min-width: 150px;
  max-width: 200px;
}

.table__monthly_amount {
  width: 25%;
  min-width: 150px;
}

.table__total_amount {
  width: 25%;
  min-width: 150px;
}

.table__calendar {
  width: 25%;
  min-width: 150px;
  max-width: 200px;
}

.error,
.error:hover,
.error:focus,
.error input {
  border-color: #fa2d40 !important;
}

.admin-override {
  display: flex;
  align-items: center;
}

.admin-override__text {
  margin: 0;
}

.admin-override__icon {
  position: absolute;
  top: 50%;
  right: calc(100% - 26px);
  transform: translateY(-50%);
  margin: 0;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: green;
}

.admin-override .admin-override__icon {
  position: static;
  display: inline-block;
  transform: translateY(0);
  margin-right: 10px;
}

.no-obligations {
  text-align: left;
  margin: 0 auto 40px;
  display: flex;
  align-items: center;
  width: 100%;
}

.no-obligations label {
  margin-left: 10px;
  cursor: pointer;
}

.notes_label {
  text-align: left;
  margin: 5px 0;
  font-size: 14px;
  color: #666;
  font-weight: 450;
}

.notes_textarea_error {
  border-color: #fa2d40 !important;
}

.notes_error_msg {
  color: #fa2d40;
  font-size: 14px;
  margin: 5px 0;
  text-align: left;
}

.button_container {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding: 0 20px;
}

.left_buttons {
  justify-content: flex-start;
}

.right_buttons {
  justify-content: flex-end;
}

@media (min-width: 500px) {
  .table-wrapper {
    overflow: scroll;
  }

  .admin-override__icon {
    right: calc(100% - 9px);
  }
}
</style>
