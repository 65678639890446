<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.124 5.34773H15L12.5301 2.87632C11.735 2.08114 10.7445 1.50931 9.65825 1.21831C8.57202 0.927309 7.42831 0.927394 6.34212 1.21856C5.25593 1.50972 4.26552 2.0817 3.47048 2.87699C2.67543 3.67228 2.10376 4.66286 1.81293 5.74915M1.00078 13.342V9.46597M1.00078 9.46597H4.87677M1.00078 9.46597L3.46986 11.9374C4.26502 12.7326 5.25551 13.3044 6.34175 13.5954C7.42798 13.8864 8.57169 13.8863 9.65788 13.5951C10.7441 13.304 11.7345 12.732 12.5295 11.9367C13.3246 11.1414 13.8962 10.1508 14.1871 9.06455M15 1.47174V5.34617"
      stroke="#303032"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
