<template>
  <button
    :class="{
      [$style.button]: true,
      [$style.disabled]: disabled,
    }"
    :disabled="disabled"
    @click.stop="disabled ? null : $emit('click')"
  >
    <div v-if="text">
      <ui-icon v-if="icon" :name="icon" size="13" :class="$style.icon" />
      <p :class="$style['button-text']">
        {{ text }}
      </p>
    </div>
    <div v-else :class="[$style['button-text']]">
      <slot />
    </div>
  </button>
</template>

<script>
import { UiIcon } from 'ui-components';

export default {
  emits: ['click'],
  components: {
    UiIcon,
  },
  props: {
    backgroundColor: String,
    disabled: { type: Boolean, default: false },
    icon: String,
    text: String,
  },
};
</script>

<style lang="less" module>
.button {
  border: none;
  background: @color-black;
  color: @color-white;
  cursor: pointer;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  height: 50px;
  padding: 0 @gutter-30;
  transition: all 200ms ease;

  .button-text {
    display: inline-block;
    line-height: 0.8;
    margin: auto 0;
    position: relative;
    top: 1px;
  }

  .icon {
    margin-right: 10px;
  }

  &.disabled {
    background-color: @color-lightgray-500;
    cursor: default;
  }

  &:hover:not(.disabled) {
    background: @color-purple;
  }

  &:focus {
    box-shadow: 0 0 0 2px @color-blue-focus;
  }
}
</style>
