<template>
  <Modal
    v-model:visible="showModal"
    :closable="false"
    :modal="true"
    :draggable="false"
    :dismissable-mask="false"
    append-to="self"
  >
    <template #header>
      <span v-if="modalTitle" class="p-dialog-title">
        {{ modalTitle }}
      </span>
      <div class="p-dialog-header-icons">
        <DSButton
          v-if="!stateMachine.matches('exitFlow')"
          aria-label="close"
          icon="pi pi-times"
          class="p-dialog-header-icon p-dialog-header-close p-link"
          @click="attemptAbandonFlow"
        />
      </div>
    </template>

    <div v-if="showSubheading">
      <div :class="$style['clarify-wrapper']">
        <template v-if="showSelfReported">
          <CapacityOverview
            title="Funding Capacity"
            title-tag="Self-Reported"
            :available-capacity="remainingSelfReportedCapacityCents"
            :used-capacity="
              selfReportedCapacityCents - remainingSelfReportedCapacityCents
            "
            :total-capacity="selfReportedCapacityCents"
            :capacity-calculating="capacityCalculating"
            :disclaimer="DISCLAIMERS.VERIFYING_SALES_ACCOUNTS"
            condensed-version
          />
        </template>
        <template v-else-if="showPreliminaryCapacity">
          <CapacityOverview
            title="Funding Capacity"
            title-tag="Preliminary"
            :available-capacity="businessCapacity?.remainingCapacityCents"
            :used-capacity="businessCapacity?.usedCapacityCents"
            :total-capacity="businessCapacity?.capacityCents"
            :disclaimer="DISCLAIMERS.PLEASE_SUBMIT_A_BILL"
            condensed-version
          />
        </template>
        <template v-else>
          <CapacityOverview
            title="Funding Capacity"
            title-tag="Approved"
            :available-capacity="businessCapacity?.remainingCapacityCents"
            :used-capacity="businessCapacity?.usedCapacityCents"
            :total-capacity="businessCapacity?.capacityCents"
            :capacity-calculating="capacityCalculating"
            :disclaimer="DISCLAIMERS.REMAINING_CAPACITY_AVAILABLE"
            condensed-version
          />
        </template>
      </div>
    </div>

    <UiContainer :class="$style.container" size="large">
      <div v-if="isBnplWaitlisted" :class="$style['bnpl-waitlist']">
        <p>
          {{ $t('account.pages.pageVendors.bnplWaitlistedModalContent') }}
        </p>
        <DSButton
          :label="$t('account.pages.pageVendors.bnplWaitlistedModalClose')"
          class="p-button-secondary"
          @click="done"
        />
      </div>
      <template v-else>
        <!-- invoice or receipt -->
        <InvoiceOrReceipt
          v-if="stateMachine.matches('flow.invoiceOrReceipt')"
          @amount="updateAmount"
          @prev-tab="prev"
          @next-tab="next"
        />
        <!-- upload invoice -->
        <UploadInvoice
          v-if="stateMachine.matches('flow.uploadInvoice')"
          :file-uploaded="fileUploaded"
          :business-id="payload.businessId"
          @next-tab="next"
          @prev-tab="prev"
          @checkFileUploaded="updateFileUploaded"
          @updateFile="uploadedFileParameters"
          @getUploadedFile="prefillBillDetails"
          @removeFile="removeFile"
        />
        <!-- Bill Details -->
        <BillDetailsForm
          v-if="stateMachine.matches('flow.billDetailsForm')"
          :business-id="payload.businessId"
          :due-date="payload.dueDate"
          :issued-date="payload.issuedDate"
          :beneficiary-id="payload.beneficiaryId"
          :amount-cents="payload.amountCents"
          :selected-currency="payload.payOutCurrency"
          :memo="payload.memo"
          :receipt-vendor-name="payload.receiptVendorName"
          :receipt-vendor-is-other="payload.receiptVendorIsOther"
          @next-tab="next"
          @prev-tab="prev"
          @dueDate="updateDueDate"
          @issuedDate="updateIssuedDate"
          @beneficiaryId="updateBeneficiaryId"
          @updateReceiptVendor="updateReceiptVendor"
          @update-memo="updateMemo"
          @update-repayment-schedule="updateRepaymentSchedule"
        />
        <!-- beneficiary details -->
        <BeneficiaryDetails
          v-if="stateMachine.matches('flow.beneficiaryDetails')"
          :bill="payload"
          :processed-data="fileProcessedData"
          is-invoice-upload-step
          @vendor-created="handleVendorCreation"
          @beneficiaryId="updateBeneficiaryId"
          @next-tab="next"
          @prev-tab="prev"
        />
        <!-- payment schedule page for BNPL -->
        <BnplPaymentSchedule
          v-if="stateMachine.matches('flow.bnplPaymentSchedule')"
          :amount="`${amount}`"
          :due-date="payload.dueDate"
          :is-receipt="isReceiptFlow"
          :selected-term="repaymentTerm"
          :payment-method="payload.paymentMethod"
          :payload-currency="payload.payOutCurrency"
          @repayment-details="updateRepaymentDetails"
          @prev-tab="prev"
          @next-tab="next"
          @get-repayment-term="getRepaymentTerm"
          @amount="updateAmount"
          @pay-out-currency="updatePayOutCurrency"
        />
        <!-- payment schedule confirmation page for BNPL -->
        <BnplPaymentScheduleConfirmation
          v-if="stateMachine.matches('flow.bnplPaymentScheduleConfirmation')"
          :amount-cents="payload.amountCents"
          :payment-method="payload.paymentMethod"
          :due-date="payload.dueDate"
          :is-receipt="isReceiptFlow"
          :upload-url="payload.uploadUrl"
          :repayment-details="repaymentDetails"
          :selected-currency="payload.payOutCurrency"
          :upload-name="payload.uploadFilename"
          :beneficiary-id="payload.beneficiaryId"
          :submitting-bill="submittingBill"
          :submission-error="billSubmissionError"
          :receipt-vendor-name="payload.receiptVendorName"
          :memo="payload.memo"
          @create-bill="createBill"
          @prev-tab="prev"
          @next-tab="next"
        />
        <!-- connect sales account -->
        <ConnectSalesAccounts
          v-if="stateMachine.matches('flow.connectSalesAccounts')"
          button-text="Next"
          @button-action="next"
        />
        <!-- connect bank account -->
        <ConnectBankAccounts
          v-if="stateMachine.matches('flow.connectBankAccounts')"
          :part-of-a-flow="true"
          @next-tab="next"
        />
        <!-- payment done -->
        <PaymentAdded
          v-if="stateMachine.matches('flow.paymentAdded')"
          :payload="payload"
          @go-to="goTo"
          @next-tab="next"
          @prev-tab="prev"
          @done="done"
          @addAnotherInvoice="addAnotherInvoice"
          @verifyRevenue="goToVerifyRevenuePage"
        />

        <ExitConfirmation
          v-if="stateMachine.matches('exitFlow')"
          @confirmExit="discardApplication"
          @giveUpExit="giveUpExit"
        />
      </template>
    </UiContainer>

    <div v-if="showCancellationBanner()" :class="$style['cancellation-banner']">
      <Message severity="info" @close="closeCancellationBanner">
        <p>
          You can now cancel your bill submissions up until the contract signing
          stage.
          <a
            href="https://help.clear.co/s/article/How-to-submit-an-invoice"
            target="_blank"
          >
            Learn More</a
          >
        </p>
      </Message>
    </div>
  </Modal>
</template>

<script>
import { UiContainer } from 'ui-components';
import { mapGetters } from 'vuex';
import analytics from '@/utils/analytics';

import { dollarsToCents, formatMoney } from '@/utils/currency';
import { validateChildren } from '@/composables/validation';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

import Modal from '@clearbanc/clear-components/dialog';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';

import {
  BillPaymentMethods,
  BNPL_TYPES,
  PAY_OUT_CURRENCY_OPTIONS,
  DEFAULT_CURRENCY,
  CLEARPAY_SEGMENT_EVENTS,
  PAYMENTS_ROUTE_NAMES,
  V2_CAPACITY_ERROR_TYPES,
} from '@/data/payments';
import { DISCLAIMERS } from '@/data/capacity';

import { LOCAL_STORAGE_KEYS } from '@/data/local-storage';
import { SEGMENT_EVENTS } from '@/data/segment-events';

import { useMachine } from '@xstate/vue';
import { getPaymentStateMachine } from '@/stateMachines/payments';
import { getShortBillId } from '@/utils/payments';
import ConnectBankAccounts from './ConnectBankAccounts';
import ConnectSalesAccounts from './ConnectSalesAccounts';
import UploadInvoice from './UploadInvoice';
import BillDetailsForm from './BillDetailsForm';
import PaymentAdded from './PaymentAdded';
import BnplPaymentSchedule from './BnplPaymentSchedule';
import InvoiceOrReceipt from './InvoiceOrReceipt';
import ExitConfirmation from './ExitConfirmation';
import BnplPaymentScheduleConfirmation from './BnplPaymentScheduleConfirmation';
import BeneficiaryDetails from './BeneficiaryDetails';
import CapacityOverview from './CapacityOverview';

const CAPACITY_ALERT_LEVEL_IN_CENTS = 100000;

const initialState = () => {
  return {
    fileUploaded: false,
    showModal: false,
    amount: '',
    repaymentTerm: 1,
    repaymentDetails: {
      termRate: 0,
      selectedTerm: 0,
      paymentPerTerm: 0,
      feeCents: 0,
      feeVersionId: 0,
    },
    payload: {
      amountCents: '',
      paymentMethod: BillPaymentMethods.BNPL,
      businessId: 0,
      dueDate: '',
      uploadUrl: '',
      uploadFilename: '',
      uploadId: 0,
      payOutCurrency: PAY_OUT_CURRENCY_OPTIONS.USD.code,
      payOutFxRate: null,
      payOutConvertedAmountCents: null,
      beneficiaryId: '',
      bnplDetails: {
        repaymentTerm: 0,
        feeVersionId: 0,
      },
      issuedDate: '',
      memo: '',
      receiptVendorName: '',
      receiptVendorIsOther: false,
    },
    userSelectedOtherVendor: false,
    newBeneficiaryCreated: false,
    submittingBill: false,
    billSubmissionError: null,
    fileProcessedData: {},
    DISCLAIMERS,
  };
};

const confirmationExitStates = [
  'uploadInvoice',
  'paymentMethods',
  'bnplPaymentSchedule',
  'billDetailsForm',
  'bnplPaymentScheduleConfirmation',
  'beneficiaryDetails',
];

export default {
  components: {
    BeneficiaryDetails,
    BnplPaymentSchedule,
    InvoiceOrReceipt,
    BnplPaymentScheduleConfirmation,
    ConnectBankAccounts,
    ConnectSalesAccounts,
    UiContainer,
    UploadInvoice,
    BillDetailsForm,
    PaymentAdded,
    ExitConfirmation,
    Modal,
    DSButton,
    Message,
    CapacityOverview,
  },
  setup(props, context) {
    const { state: stateMachine, send } = useMachine(getPaymentStateMachine());
    const { hasError } = validateChildren();
    return { stateMachine, send, hasError };
  },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters([
      'user',
      'bills',
      'business',
      'businessId',
      'currentUpload',
      'createdBill',
      'repaymentSchedule',
      'hasActiveSalesAccounts',
      'bankingAccountsConnected',
      'isBnplWaitlisted',
      'isReceiptFlow',
      'isBusinessProfileComplete',
      'receiptVendorOptions',
      'beneficiaries',
      'userId',
      'businessCapacity',
      'isProcessDocumentOcrEnabled',
      'selfReportedCapacityCents',
      'remainingSelfReportedCapacityCents',
      'businessCapacityGenerated',
    ]),
    ...mapRequestStatuses({
      createPayInvoiceRequest: 'CREATE_PAY_INVOICE',
      businessCapacityRequest: 'GET_BUSINESS_CAPACITY',
    }),
    commonAnalyticsProperties() {
      return {
        businessId: this.business.id,
        businessName: this.business.name,
        billType: this.isReceiptFlow ? 'receipt' : 'invoice',
      };
    },
    modalTitle() {
      if (this.isBnplWaitlisted) {
        return this.$t('account.pages.pageVendors.bnplWaitlistedModalTitle');
      } else if (this.stateMachine.matches('exitFlow')) {
        return 'Not ready to free up your cash flow?';
      }

      switch (this.stateMachine.value.flow) {
        case 'invoiceOrReceipt':
          return this.$t('account.pages.pageVendors.invoiceOrReceipt.title');
        case 'bnplPaymentSchedule':
          return this.$t('account.pages.pageVendors.bnplPaymentSchedule.title');
        case 'billDetailsForm':
          return this.$t('account.pages.pageVendors.billDetailsForm.title', {
            type: this.isReceiptFlow
              ? this.$t('account.pages.pageVendors.billDetailsForm.aReceipt')
              : this.$t('account.pages.pageVendors.billDetailsForm.anInvoice'),
          });
        case 'uploadInvoice':
          return this.$t('account.pages.pageVendors.uploadInvoiceModal.title', {
            type: this.isReceiptFlow
              ? this.$t('account.pages.pageVendors.uploadInvoiceModal.aReceipt')
              : this.$t(
                  'account.pages.pageVendors.uploadInvoiceModal.anInvoice',
                ),
          });
        case 'bnplPaymentScheduleConfirmation':
          return this.$t(
            'account.pages.pageVendors.bnplPaymentScheduleConfirmation.title',
          );
        case 'beneficiaryDetails':
          return this.$t('paymentsPage.vendorDetails');
        case 'connectSalesAccounts':
          return 'Verify your revenue';
        case 'connectBankAccounts':
          return 'Setup banking';
        default:
          return '';
      }
    },
    showSubheading() {
      return this.stateMachine.value.flow === 'bnplPaymentSchedule';
    },
    showSelfReported() {
      return !this.businessCapacityGenerated;
    },
    showPreliminaryCapacity() {
      return (
        this.businessCapacityGenerated && !this.businessCapacity.approvedAt
      );
    },
    capacityCalculating() {
      const errorType = this.businessCapacityRequest.error?.type;

      return (
        errorType === V2_CAPACITY_ERROR_TYPES.CAPACITY_NOT_FOUND &&
        !this.businessCapacity?.capacityCents &&
        this.hasActiveSalesAccounts
      );
    },
  },
  methods: {
    resetScrollPosition() {
      const modalContent = document.querySelector('.p-dialog-content');
      if (modalContent) {
        modalContent.scrollTop = 0;
        modalContent.scrollLeft = 0;
      }
    },
    toAmountNoDecimal(amountCents, currencyCode) {
      return this.$filters.noDecimal(
        this.$filters.currency(amountCents, currencyCode),
        currencyCode,
      );
    },
    formatMoneyAndCurrency(amountCents, currencyType) {
      return `${formatMoney(amountCents, currencyType)} ${currencyType}`;
    },
    getRepaymentTerm(val) {
      this.repaymentTerm = Number(val);
    },
    giveUpExit() {
      this.send('GIVE_UP');
    },
    uploadedFileParameters() {
      this.payload.uploadUrl = this.currentUpload.downloadUrl;
      this.payload.uploadFilename = this.currentUpload.name;
      this.payload.uploadId = this.currentUpload.id;
    },
    async open(businessId) {
      this.resetUploadSteps();
      this.showModal = true;
      this.payload.businessId = businessId;
    },
    async attemptAbandonFlow() {
      if (confirmationExitStates.includes(this.stateMachine.value.flow)) {
        analytics.track(SEGMENT_EVENTS.if_start_abandon_bill_submission, {
          ...this.commonAnalyticsProperties,
          billType: this.isReceiptFlow ? 'receipt' : 'invoice',
        });
        this.send('EXIT');
        return;
      }
      await this.closeModal();
    },
    async discardApplication() {
      analytics.track(SEGMENT_EVENTS.if_abandon_bill_submission, {
        ...this.commonAnalyticsProperties,
        billType: this.isReceiptFlow ? 'receipt' : 'invoice',
      });
      this.$store.dispatchApiAction('DELETE_USER_UPLOAD', {
        id: this.currentUpload.id,
      });
      await this.closeModal();
    },
    async done() {
      if (this.isBnplWaitlisted) {
        this.showModal = false;
        this.$emit('done');
        return;
      }
      await this.closeModal();
    },
    async closeModal() {
      this.$store.commit('RESET_BENEFICIARY_FORM_FIELD');
      this.resetUploadSteps();
      this.send('RESET');
      this.showModal = false;
      this.$emit('done');
      await this.$store.dispatch('IS_RECEIPT_FLOW', false);
    },
    async addAnotherInvoice() {
      this.goBackToInvoiceOrReceipt();
      await this.refreshBills();
    },
    goToVerifyRevenuePage() {
      this.showModal = false;
      this.$router.push({ name: PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT });
    },
    async next(skip) {
      if (!skip) {
        const error = await this.hasError();
        if (error) {
          return;
        }
      }

      if (this.stateMachine.matches('flow.beneficiaryDetails')) {
        this.payload.beneficiarySkipped = skip;
      }

      this.send('NEXT', {
        isReceiptFlow: this.isReceiptFlow,
        userSelectedOtherVendor: this.userSelectedOtherVendor,
        isNewBeneficiary: this.payload.beneficiaryId === '+newvendor',
        hasActiveSalesAccounts: this.hasActiveSalesAccounts,
        isBankingConnected: this.bankingAccountsConnected,
      });
    },
    goBackToInvoiceOrReceipt() {
      this.resetUploadSteps();
      this.send('RESET');
      this.showModal = true;
    },
    prev() {
      this.send('PREVIOUS', {
        isReceiptFlow: this.isReceiptFlow,
      });
    },
    goTo(event) {
      this.send(event);
    },
    prefillBillDetails(file) {
      const { processedDocumentEntities } = file;
      if (
        this.isProcessDocumentOcrEnabled &&
        Object.keys(processedDocumentEntities ?? {}).length
      ) {
        this.fileProcessedData = processedDocumentEntities;
        if (processedDocumentEntities?.totalAmount) {
          this.updateAmount(parseFloat(processedDocumentEntities.totalAmount));
        }
        this.payload.dueDate = processedDocumentEntities?.dueDate;
        this.payload.issuedDate = processedDocumentEntities?.receiptDate;
        // Receipt only allows USD and Invoice allows those listed in PAY_OUT_CURRENCY_OPTIONS
        if (
          !this.isReceiptFlow &&
          PAY_OUT_CURRENCY_OPTIONS[processedDocumentEntities?.currency ?? '']
        ) {
          this.payload.payOutCurrency = processedDocumentEntities?.currency;
        }
      }
    },
    updateFileUploaded(fileId) {
      if (fileId) {
        this.fileUploaded = true;
      }
    },
    removeFile() {
      this.fileUploaded = false;
    },
    updateAmount(amount) {
      this.amount = amount || 0;
      this.payload.amountCents = dollarsToCents(amount);
    },
    updatePayOutCurrency(currency) {
      this.payload.payOutCurrency = currency;
    },
    updateDueDate(dueDate) {
      this.payload.dueDate = dueDate;
    },
    updateIssuedDate(issuedDate) {
      this.payload.issuedDate = issuedDate;
    },
    updateRepaymentDetails(repaymentDetails) {
      this.repaymentDetails = repaymentDetails;
      this.payload.bnplDetails = {
        repaymentTerm: repaymentDetails.selectedTerm,
        feeVersionId: repaymentDetails.feeVersionId,
      };
      this.startRepaymentTerm = repaymentDetails.selectedTerm;
    },
    updateRepaymentSchedule() {
      this.repaymentDetails.repaymentSchedule =
        this.repaymentSchedule?.repaymentSchedulesByMonth[
          this.repaymentDetails.selectedTerm
        ].schedule;
      this.repaymentDetails.exchangeRateData =
        this.repaymentSchedule.exchangeRateData;
    },
    updateBeneficiaryId(beneficiaryId) {
      this.userSelectedOtherVendor = beneficiaryId === 'other';
      this.payload.beneficiaryId = beneficiaryId;
    },
    updateReceiptVendor(vendorName, isOtherVendor) {
      this.payload.receiptVendorIsOther = isOtherVendor;
      this.payload.receiptVendorName = vendorName;
    },
    async resetUploadSteps() {
      Object.assign(this.$data, initialState());
      this.$store.dispatch('RESET_SELECTED_BILL');
    },
    async createBill() {
      this.submittingBill = true;
      this.payload.bnplDetails.bnplType = BNPL_TYPES.CAPPED_IRR_MCA;

      // Send along draft conversion data for non-default currency payouts
      if (this.payload.payOutCurrency !== DEFAULT_CURRENCY) {
        this.payload.payOutFxRate =
          this.repaymentDetails.exchangeRateData.conversionRate;
        this.payload.payOutConvertedAmountCents =
          this.repaymentDetails.exchangeRateData.convertedAmountCents;
      }
      this.payload.isReceipt = this.isReceiptFlow;

      // send beneficiary id only if it is valid
      let payload, vendorName;
      if (this.isReceiptFlow) {
        if (this.payload.receiptVendorIsOther) {
          vendorName = this.payload.receiptVendorName;
        } else {
          vendorName = this.receiptVendorOptions.find(
            (item) => item.value === this.payload.beneficiaryId,
          )?.name;
        }
        this.payload.beneficiaryId = null;
      } else {
        vendorName = this.beneficiaries.find(
          (item) => item.id === this.payload.beneficiaryId,
        )?.name;
      }
      const { beneficiaryId, ...restOfPayload } = this.payload;
      if (beneficiaryId === '+newvendor') {
        payload = restOfPayload;
      } else {
        // if deprecate v0 beneficiaries is enabled the beneficiary record is actually a v2 record, therefore
        // we can drop replace the `beneficiaryId` key with `beneficiaryV2Id` and keep the same value
        if (this.isDeprecateV0BeneficiariesEnabled) {
          this.payload.beneficiaryV2Id = this.payload.beneficiaryId;
          this.payload.beneficiaryId = null;
        }

        payload = this.payload;
      }

      await this.$store.dispatchApiAction('CREATE_PAY_INVOICE', payload);

      if (this.createPayInvoiceRequest.isSuccess) {
        if (this.createdBill) {
          const analyticsProperties = {
            ...this.commonAnalyticsProperties,
            billId: this.createdBill.id,
            shortBillId: getShortBillId(this.createdBill.id),
            selectedTerm: this.repaymentDetails.numWeeklyPayments,
            billAmount: this.formatMoneyAndCurrency(
              this.createdBill.amountCents,
              this.createdBill.payOutCurrency,
            ),
            cappedAmount: this.formatMoneyAndCurrency(
              100 * this.repaymentDetails.paymentPerTerm,
              this.createdBill.payOutCurrency,
            ),
            dueDate: this.createdBill.dueDate,
            uploadType: this.createdBill.isReceipt ? 'receipt' : 'invoice',
            issuedDate: this.createdBill.issuedDate,
            hasPaymentDetails:
              (!!beneficiaryId && beneficiaryId !== '+newvendor') ||
              this.newBeneficiaryCreated,
            vendorName,
          };
          analytics.track(
            CLEARPAY_SEGMENT_EVENTS.CLICKED_BNPL_CONFIRM_APPLY,
            analyticsProperties,
          );

          analytics.track(SEGMENT_EVENTS.if_bill_submission_ocr_details, {
            ...this.commonAnalyticsProperties,
            billId: this.createdBill.id,
            billType: this.createdBill.isReceipt ? 'receipt' : 'invoice',
            isOCREnabled: this.isProcessDocumentOcrEnabled,
            ocr: {
              dueDate: this.fileProcessedData?.dueDate,
              invoiceDate: this.fileProcessedData?.invoiceDate,
              receiptDate: this.fileProcessedData?.receiptDate,
              supplierName: this.fileProcessedData?.supplierName,
              totalAmount: this.fileProcessedData?.totalAmount,
              currency: this.fileProcessedData?.payOutCurrency,
            },
            userInput: {
              shortBillId: getShortBillId(this.createdBill.id),
              vendorName,
              dueDate: this.createdBill.dueDate,
              issuedDate: this.createdBill.issuedDate,
              billAmount: this.formatMoneyAndCurrency(
                this.createdBill.amountCents,
                this.createdBill.payOutCurrency,
              ),
            },
          });

          analytics.track(CLEARPAY_SEGMENT_EVENTS.RECEIPT_VENDOR_SUBMITTED, {
            ...this.commonAnalyticsProperties,
            billId: this.createdBill.id,
            userId: this.userId,
            vendorName,
          });
        }

        await this.refreshBills();

        if (
          this.businessCapacity.remainingCapacityCents <
          CAPACITY_ALERT_LEVEL_IN_CENTS
        ) {
          analytics.track('if_user_reach_full_capacity', {
            capacity: this.businessCapacity.remainingCapacityCents,
          });
        }
        this.next();
        this.submittingBill = false;
      } else {
        this.billSubmissionError = this.createPayInvoiceRequest.error;
      }
    },
    async refreshBills() {
      await this.$store.dispatchApiAction('GET_BILLS');
      await this.$store.dispatchApiAction('GET_BUSINESS_CAPACITY');
    },
    updateMemo(value) {
      this.payload.memo = value;
    },
    handleVendorCreation() {
      this.newBeneficiaryCreated = true;
    },
    showCancellationBanner() {
      return (
        this.stateMachine.value.flow === 'invoiceOrReceipt' &&
        localStorage.getItem(LOCAL_STORAGE_KEYS.CANCELLATION_BANNER) !== 'true'
      );
    },
    closeCancellationBanner() {
      localStorage.setItem(LOCAL_STORAGE_KEYS.CANCELLATION_BANNER, true);
    },
  },
  mounted() {
    this.$store.commit('RESET_BENEFICIARY_FORM_FIELD');
  },
  watch: {
    stateMachine(newState) {
      this.resetScrollPosition();
      let eventName;

      if (newState.matches('flow.uploadInvoice')) {
        if (this.isReceiptFlow) {
          eventName = CLEARPAY_SEGMENT_EVENTS.VIEWED_INVOICE_MODAL_ADD_RECEIPT;
        } else {
          eventName = CLEARPAY_SEGMENT_EVENTS.VIEWED_INVOICE_MODAL_ADD_INVOICE;
        }
      } else if (newState.matches('flow.bnplPaymentSchedule')) {
        eventName = CLEARPAY_SEGMENT_EVENTS.VIEWED_INVOICE_MODAL_CHOOSE_PLAN;
      } else if (newState.matches('flow.bnplPaymentScheduleConfirmation')) {
        eventName = CLEARPAY_SEGMENT_EVENTS.VIEWED_INVOICE_MODAL_PLAN_DETAILS;
      } else if (newState.matches('flow.beneficiaryDetails')) {
        eventName = CLEARPAY_SEGMENT_EVENTS.VIEWED_INVOICE_MODAL_VENDOR_DETAILS;
      } else if (newState.matches('flow.billDetailsForm')) {
        eventName = CLEARPAY_SEGMENT_EVENTS.VIEWED_BILL_DETAILS_FORM;
      }

      if (eventName) {
        analytics.track(eventName, this.commonAnalyticsProperties);
      }
    },
  },
};
</script>
<style lang="less" module>
.container {
  padding: 0;
  margin: 1rem auto 0;
  min-height: unset;

  h2,
  h3,
  h4 {
    font-family: @gerstner-font;
  }

  @media only screen and (max-width: 500px) {
    text-align: left;
  }
}

.clarify-wrapper {
  margin: 20px 0 40px;
}

.subheading {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
}

.subheading__item + .subheading__item {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 6px;
  margin-top: 6px;
}

.subheading__item span {
  color: #7f30ff;
}

.bnpl-waitlist {
  font-family: @gerstner-font;

  p {
    color: @color-black;
    font-weight: 300;
    font-size: 1rem;
    line-height: 24px;
    margin: 0 0 30px;
  }
}

.cancellation-banner {
  margin: 20px 0 0;
  max-width: 465px;
}
</style>
