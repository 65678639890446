// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".file_FkYFF{font-size:18px}.file-text_i8USs{cursor:pointer;text-decoration:underline}.file-text_i8USs.blue-link_Dsuzw{color:#0d3685}.file-text_i8USs.closable-list_bSPCS{text-align:left}.file-text_i8USs.list-only_z54Mu{position:relative;z-index:1;margin:0 0 10px;text-align:right;max-width:290px}@media screen and (max-width:500px){.file-text_i8USs.list-only_z54Mu{text-align:left}}.file-button_AaXVn{cursor:pointer;width:18px;height:18px}.calendar_Iq5Yr{width:245px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file": "file_FkYFF",
	"file-text": "file-text_i8USs",
	"blue-link": "blue-link_Dsuzw",
	"closable-list": "closable-list_bSPCS",
	"list-only": "list-only_z54Mu",
	"file-button": "file-button_AaXVn",
	"calendar": "calendar_Iq5Yr"
};
export default ___CSS_LOADER_EXPORT___;
