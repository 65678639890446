<template>
  <template v-if="isImprovedKyFlowEnabled">
    <FormError v-if="showError" :error-message="errorMessage" />
    <FormBusinessDetailsNew
      @validationSuccess="handleValidationSuccess"
      @validationFailure="handleError"
    />
  </template>
  <LayoutFormPage
    v-else
    :show-error-message="showError"
    :error-message="errorMessage"
  >
    <template #form>
      <FormBusinessDetails
        ref="form"
        :event-tracking-fields="commonTrackingFields"
        :collect-ein="true"
        show-amer-country-options-only
        @validationSuccess="handleValidationSuccess"
        @validationFailure="handleError"
      />
    </template>
  </LayoutFormPage>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapStateModuleGetters } from '@/utils/state-modules';
import stateModuleAware from '@/mixins/state-module-aware';
import { LayoutFormPage } from '@/components';
import FormBusinessDetails from '@/components/forms/FormBusinessDetails';
import FormBusinessDetailsNew from '@/components/forms/FormBusinessDetailsNew';
import FormError from '@/components/forms/shared/FormError';

export default {
  components: {
    FormBusinessDetails,
    LayoutFormPage,
    FormError,
    FormBusinessDetailsNew,
  },
  mixins: [stateModuleAware],
  data() {
    return {
      showError: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters(['productSegmentLabel', 'isImprovedKyFlowEnabled']),
    ...mapStateModuleGetters(['steps', 'experimentName']),
    currentStep() {
      return this.steps[this.$route.name];
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        targetArea: this.$route.name,
        experiment: this.experimentName,
        page: this.$route.path,
      };
    },
  },
  methods: {
    async nextStep() {
      await this.$refs.form.validateForm();
    },
    handleValidationSuccess() {
      this.dispatchToStateModule('UPDATE_STEP', {
        step: this.currentRoute,
      });
      this.$router.push({ name: this.currentStep.nextRoute.name });
    },
    handleError(error) {
      this.errorMessage = error;
      this.showError = true;
    },
  },
  mounted() {
    this.$store.dispatch('MODIFY_PAYMENT_STEPS');
  },
};
</script>
