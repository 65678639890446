<script setup>
import { onMounted, computed, ref, readonly, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import { cloneDeep } from 'lodash';
import { format, parse, isDate } from 'date-fns';

import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import InputNumberGroup from '@clearbanc/clear-components/inputnumbergroup';
import DropdownGroup from '@clearbanc/clear-components/dropdowngroup';
import CheckboxGroup from '@clearbanc/clear-components/checkboxgroup';
import DSButton from '@clearbanc/clear-components/button';
import FormPhoneInput from '@/components/forms/FormPhoneInput';
import CalendarGroup from '@clearbanc/clear-components/calendargroup';
import { UploadFileWithDownloadableFileList } from '@/components';
import AutoCompleteAddress from '@/components/AutoCompleteAddress';
import FileUpload from 'primevue/fileupload';
import UploadFileList from '@/components/UploadFileList';

import {
  getAllCallingCodes,
  getPhoneNumberCountryCode,
  getPhoneNumberWithoutCountryCode,
} from '@/utils/phone-numbers';
import { REGEXES } from '@clearbanc/validation';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { parsePlaceData } from '@/utils/address';
import { getDisplayedErrorMessage } from '@/data/error-messages';
import { objectDiff } from '@/utils/object-comparison';
import { JobTitlesByCorpCountry } from '@/data/owner';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import {
  OWNER_ROLES,
  ENTITY_TYPE,
  ID_TYPE,
} from '@clearbanc/data-common-types';
import {
  stateOptionsForCountry,
  stateLabel,
  postalCodeLabel,
  personalTaxIdLabelShort,
} from '@/utils/local';
import {
  countryOptionsWithPriority,
  isCountryWithPostalCode,
} from '@/data/country-code-with-names';
import {
  validateSSN,
  validatePhone,
  validatePostalCode,
} from '@/utils/schemaValidators';

// -----
// Setup
// -----

const store = useStore();
const router = useRouter();
const props = defineProps({
  selectedOwner: {
    type: Object,
  },
  businessCorpCountry: {
    type: String,
  },
  removeContainer: {
    type: Boolean,
    default: false,
  },
  isExternalForm: {
    type: Boolean,
    default: false,
  },
});

// ---------
// Constants
// ---------

const COUNTRY_CALLING_CODES = getAllCallingCodes();
const COUNTRIES = countryOptionsWithPriority([
  COUNTRY_CODES.US,
  COUNTRY_CODES.CA,
  COUNTRY_CODES.GB,
]);
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ACCEPTABLE_FILE_TYPES =
  '.docx,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,image/jpeg,image/png';

// ---------
// Variables
// ---------

const isPrimaryUser = computed(() => !!props.selectedOwner?.isPrimary);
const errorMessage = ref(null);
const filesPassport = ref([]);
const filesDriversLicense = ref([]);
const requestStatuses = readonly({
  updateUser: computed(() => store.getters.requestStatus('UPDATE_USER')),
  updateUserWithoutAuth: computed(() =>
    store.getters.requestStatus('UPDATE_USER_WITHOUT_AUTH'),
  ),
});

// -------
// Methods
// -------

function citizenshipIsTaxIdCountry(usersCitizenshipCountry) {
  return [COUNTRY_CODES.US, COUNTRY_CODES.CA, COUNTRY_CODES.GB].includes(
    usersCitizenshipCountry,
  );
}

function onBack() {
  router.push({
    name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS,
  });

  store.dispatch('UNSELECT_OWNER');
}

// -------
// Uploads
// -------

function generateFileFilter(...args) {
  return {
    metaId: props.selectedOwner?.id,
    entity: isPrimaryUser.value ? ENTITY_TYPE.USER : ENTITY_TYPE.OWNER,
    type: args.length > 1 ? args : args[0],
  };
}

function removeUpload(id) {
  store.dispatchApiAction('DELETE_USER_UPLOAD', { id });
}

const userUploads = computed(() => {
  const filter = generateFileFilter(
    ID_TYPE.PASSPORT_OR_LICENSE,
    ID_TYPE.ID,
    ID_TYPE.PASSPORT,
    ID_TYPE.DRIVERS_LICENSE,
  );

  return store.getters.uploadsMatching(filter);
});

// ----------------------------
// Unauthenticated File Uploads
// ----------------------------

function addFileToList(fileObject, type) {
  switch (type) {
    case ID_TYPE.DRIVERS_LICENSE:
      filesDriversLicense.value.push(fileObject);
      break;
    case ID_TYPE.PASSPORT:
      filesPassport.value.push(fileObject);
      break;
    default:
      throw new Error('Unknown document type:', type);
  }
}

function processFile(file, type) {
  const reader = new FileReader();

  reader.onload = (e) => {
    const result = e.target?.result;
    const base64Data = typeof result === 'string' ? result.split(',')[1] : '';
    const extension = file.name.split('.').pop().toUpperCase();

    const fileObject = {
      name: file.name,
      extension,
      data: base64Data,
      documentType: type,
    };

    addFileToList(fileObject, type);
  };

  reader.readAsDataURL(file);
}

function onFileSelect(event, type) {
  const filesToUpload = event.files;

  if (type === ID_TYPE.DRIVERS_LICENSE) {
    filesDriversLicense.value = [];
  } else if (type === ID_TYPE.PASSPORT) {
    filesPassport.value = [];
  }

  filesToUpload?.forEach((file) => {
    processFile(file, type);
  });
}

// ----------
// Form Logic
// ----------

const validationSchema = object({
  firstName: string()
    .required('Required')
    .matches(
      REGEXES.NAME,
      'Only letters, valid punctuation, and no consecutive or trailing spaces',
    ),
  lastName: string()
    .required('Required')
    .matches(
      REGEXES.NAME,
      'Only letters, valid punctuation, and no consecutive or trailing spaces',
    ),
  email: string().nullable().required('Required').email(),
  countryCallingCode: object().nullable().required('Required'),
  phone: string()
    .nullable()
    .when('countryCallingCode', (countryCallingCode, schema) => {
      if (countryCallingCode) {
        validatePhone(schema, countryCallingCode);
      }

      return schema.required('Required');
    }),
  jobTitle: object().nullable().required('Required'),
  citizenship: object().nullable().required('Required'),
  personalTaxId: string()
    .nullable()
    .when('citizenship', (citizenship, schema) => {
      if (citizenshipIsTaxIdCountry(citizenship?.value)) {
        if (citizenship?.value === 'US') {
          return validateSSN(schema);
        }

        return schema.required('Required');
      }

      return schema;
    }),
  passportNumber: string()
    .nullable()
    .when('citizenship', (citizenship, schema) => {
      if (!citizenshipIsTaxIdCountry(citizenship?.value)) {
        return schema
          .required('Required')
          .matches(/^[A-Za-z0-9]*$/, 'Only letters and numbers are allowed');
      }

      return schema;
    }),
  birthday: string().nullable().required('Required'),
  line1: string().typeError('').required('Required'),
  line2: string().nullable(),
  city: string().required('Required'),
  state: object()
    .nullable()
    .when('country', (country, schema) => {
      if (country) {
        const countryHasStates = stateOptionsForCountry(country.value);
        if (countryHasStates) {
          return schema.required('Required');
        }
      }
      return schema;
    }),
  country: object().nullable().required('Required'),
  postalCode: string()
    .nullable()
    .when('country', (country, schema) => {
      return validatePostalCode(schema, country);
    }),
  documentUploads: string()
    .nullable()
    .when([], {
      is: () => {
        if (props.isExternalForm) {
          return (
            !filesPassport.value.length && !filesDriversLicense.value.length
          );
        }

        return userUploads.value.length < 1;
      },
      then: (schema) =>
        schema.required('Please upload your passport or drivers license.'),
      otherwise: (schema) => schema.notRequired(),
    }),
});

const { values, handleSubmit, setValues, isSubmitting, errors, validateField } =
  useForm({
    validationSchema,
  });

function populateSavedValues() {
  const initialValues = {};

  initialValues.firstName = props.selectedOwner?.firstName;
  initialValues.lastName = props.selectedOwner?.lastName;
  initialValues.phone = getPhoneNumberWithoutCountryCode(
    props.selectedOwner?.phoneNumber,
  );
  initialValues.countryCallingCode =
    getPhoneNumberCountryCode(props.selectedOwner?.phoneNumber) ||
    COUNTRY_CALLING_CODES.find((item) => {
      return item.value === COUNTRY_CODES.US;
    });
  initialValues.email = props.selectedOwner?.email;
  initialValues.personalTaxId =
    props.selectedOwner?.personalTaxId &&
    Number(props.selectedOwner?.personalTaxId);
  initialValues.passportNumber = props.selectedOwner?.passportNumber;
  initialValues.birthday = props.selectedOwner?.birthday;
  initialValues.line1 = props.selectedOwner?.address?.line1;
  initialValues.line2 = props.selectedOwner?.address?.line2;
  initialValues.city = props.selectedOwner?.address?.city;
  initialValues.postalCode = props.selectedOwner?.address?.postalCode;

  // Checkboxes
  if (props.selectedOwner?.jobRoles?.includes(OWNER_ROLES.DIRECTOR)) {
    initialValues.isDirector = [OWNER_ROLES.DIRECTOR];
  }

  if (props.selectedOwner?.jobRoles?.includes(OWNER_ROLES.OWNER)) {
    initialValues.isSignificantPercentageOwner = [OWNER_ROLES.OWNER];
  }

  // Job Title
  if (props.selectedOwner?.jobTitle) {
    initialValues.jobTitle = JobTitlesByCorpCountry(
      props.businessCorpCountry || COUNTRY_CODES.US,
    )?.find((item) => {
      return item.value === props.selectedOwner?.jobTitle;
    });
  }

  // State
  if (props.selectedOwner?.address?.state) {
    initialValues.state = stateOptionsForCountry(
      props.selectedOwner?.address?.country,
    )?.find((state) => state.value === props.selectedOwner?.address?.state);
  }

  // Country
  if (props.selectedOwner?.address?.country) {
    initialValues.country = COUNTRIES.find(
      (country) => country.value === props.selectedOwner?.address?.country,
    );
  }

  // Citizenship
  if (props.selectedOwner?.citizenship) {
    initialValues.citizenship = COUNTRIES?.find(
      (country) => country.value === props.selectedOwner?.citizenship,
    );
  }

  setValues(initialValues);
}

function formatFormDataForEndpoint(initialData, formValues) {
  const updatedData = cloneDeep(initialData);

  updatedData.firstName = formValues.firstName;
  updatedData.lastName = formValues.lastName;
  updatedData.phoneNumber = `${formValues.countryCallingCode.callingCode}${formValues.phone}`;
  updatedData.jobTitle = formValues.jobTitle.value;
  updatedData.citizenship = formValues.citizenship.value;
  updatedData.personalTaxId =
    formValues.personalTaxId && String(formValues.personalTaxId);
  updatedData.passportNumber = formValues.passportNumber;
  updatedData.address = {
    line1: formValues.line1,
    line2: formValues.line2,
    city: formValues.city,
    state: formValues.state?.value || null,
    country: formValues.country.value,
    postalCode: formValues.postalCode,
  };

  const birthdayDate = isDate(formValues.birthday)
    ? formValues.birthday
    : parse(formValues.birthday, 'yyyy-MM-dd', new Date());
  updatedData.birthday = format(birthdayDate, 'yyyy-MM-dd');

  updatedData.jobRoles = [];

  if (formValues.isSignificantPercentageOwner?.length) {
    updatedData.jobRoles.push(OWNER_ROLES.OWNER);
  }

  if (formValues.isDirector?.length) {
    updatedData.jobRoles.push(OWNER_ROLES.DIRECTOR);
  }

  if (props.selectedOwner?.jobRoles?.includes(OWNER_ROLES.SIGNATORY)) {
    updatedData.jobRoles.push(OWNER_ROLES.SIGNATORY);
  }

  return updatedData;
}

function handleAddressSelected(placeData) {
  const parsedData = parsePlaceData(placeData);

  setValues({
    ...values,
    ...parsedData,
  });
}

async function submitForm() {
  // Gather and format data for the endpoint
  const updatedSelectedOwner = formatFormDataForEndpoint(
    props.selectedOwner,
    values,
  );

  // Compare the data to confirm there were changes made
  const { changedProps: payload } = objectDiff(
    updatedSelectedOwner,
    props.selectedOwner,
  );

  // Make the request
  if (Object.keys(payload)?.length) {
    await store.dispatchApiAction('UPDATE_USER', payload);

    if (requestStatuses.updateUser.error) {
      errorMessage.value = getDisplayedErrorMessage(
        requestStatuses.updateUser.error,
      );
      return;
    }
  }

  router.push({
    name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS,
  });

  store.dispatch('UNSELECT_OWNER');
}

async function submitExternalForm() {
  const updatedSelectedOwner = formatFormDataForEndpoint(
    props.selectedOwner,
    values,
  );

  await store.dispatchApiAction('UPDATE_USER_WITHOUT_AUTH', {
    ...updatedSelectedOwner,
    files: [...filesPassport.value, ...filesDriversLicense.value],
  });

  if (requestStatuses.updateUserWithoutAuth.error) {
    return (errorMessage.value = getDisplayedErrorMessage(
      requestStatuses.updateUserWithoutAuth.error,
    ));
  }

  return router.push({ name: 'user-invite-success' });
}

const onSubmit = handleSubmit(async () => {
  errorMessage.value = null;

  if (props.isExternalForm) {
    return submitExternalForm();
  }

  return submitForm();
});

// Vee Validate doesn't play well with our custom file upload component
// This is required to revalidate the form when a file is uploaded
watch([userUploads, filesPassport, filesDriversLicense], () => {
  validateField('documentUploads');
});

// -----------------
// Lifecycle Methods
// -----------------

onMounted(() => {
  if (props.selectedOwner?.email) {
    populateSavedValues();
  }
});
</script>

<template>
  <div :class="props.removeContainer ? '' : $style.container">
    <form
      class="c-form"
      :style="{ pointerEvents: isSubmitting ? 'none' : 'all' }"
      @submit="onSubmit"
    >
      <!-- ////////// -->
      <!-- Checkboxes -->
      <!-- ////////// -->

      <div class="c-group">
        <div class="c-form__row">
          <CheckboxGroup
            name="isDirector"
            :options="[
              {
                value: OWNER_ROLES.DIRECTOR,
                label: 'This individual is a Director / Board member',
              },
            ]"
          />
          <CheckboxGroup
            name="isSignificantPercentageOwner"
            :options="[
              {
                value: OWNER_ROLES.OWNER,
                label: 'This individual has 25%+ ownership',
              },
            ]"
          />
        </div>
      </div>

      <!-- //////////////////// -->
      <!-- Personal Information -->
      <!-- //////////////////// -->

      <div class="c-group">
        <div class="c-group__header">
          <h3 class="c-group__header__title">Personal Information</h3>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <InputTextGroup name="firstName" label="First Name" />
          </div>
          <div class="col-span-6 md:col-span-12">
            <InputTextGroup name="lastName" label="Last Name" />
          </div>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <InputTextGroup disabled name="email" label="Email" />
          </div>
          <div class="col-span-6 md:col-span-12">
            <FormPhoneInput
              :dropdown-attrs="{
                name: 'countryCallingCode',
                options: COUNTRY_CALLING_CODES,
                optionLabel: 'nameString',
                placeholder: 'Select',
                filter: true,
              }"
              :input-attrs="{
                name: 'phone',
                label: 'Business Phone Number',
              }"
            />
          </div>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <DropdownGroup
              name="jobTitle"
              :label="$t('common.jobTitle')"
              option-label="label"
              :options="JobTitlesByCorpCountry(props.businessCorpCountry)"
              :placeholder="$t('common.selectOption')"
              append-to="self"
            />
          </div>
          <div class="col-span-6 md:col-span-12">
            <DropdownGroup
              filter
              reset-filter-on-hide
              name="citizenship"
              label="Country of Citizenship"
              option-label="nameString"
              :options="COUNTRIES"
              :placeholder="$t('common.selectOption')"
              append-to="self"
              @change="
                setValues({
                  ...values,
                  personalTaxId: null,
                  passportNumber: null,
                })
              "
            />
          </div>
        </div>

        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <InputNumberGroup
              v-if="citizenshipIsTaxIdCountry(values?.citizenship?.value)"
              name="personalTaxId"
              :label="personalTaxIdLabelShort(values?.citizenship?.value)"
              :use-grouping="false"
            />
            <InputTextGroup
              v-else
              name="passportNumber"
              label="Passport Number"
              :use-grouping="false"
            />
          </div>
          <div class="col-span-6 md:col-span-12">
            <CalendarGroup
              :label="$t('common.dateOfBirth')"
              name="birthday"
              :max-date="new Date()"
              :manual-input="false"
              placeholder="yyyy-mm-dd"
              show-icon
            />
          </div>
        </div>
      </div>

      <!-- /////// -->
      <!-- Address -->
      <!-- /////// -->

      <div class="c-group">
        <div class="c-group__header">
          <h3 class="c-group__header__title">Address</h3>
        </div>
        <div class="c-form__row">
          <AutoCompleteAddress
            name="line1"
            :label="$t('common.address.companyAddress')"
            @addressSelected="(place) => handleAddressSelected(place)"
          />
        </div>
        <div class="c-form__row">
          <InputTextGroup
            name="line2"
            :label="$t('common.address.companyAddressLine2')"
          />
        </div>
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <DropdownGroup
              name="country"
              :label="$t('common.address.country')"
              :reset-filter-on-hide="true"
              :filter="true"
              option-label="nameString"
              :options="COUNTRIES"
              :placeholder="$t('common.selectOption')"
              append-to="self"
            />
          </div>
          <div class="col-span-6 md:col-span-12">
            <InputTextGroup name="city" :label="$t('common.address.city')" />
          </div>
        </div>
        <div
          v-if="
            stateOptionsForCountry(values.country?.value) ||
            (values.country && isCountryWithPostalCode(values.country?.value))
          "
          class="c-form__row grid grid-cols-12"
        >
          <div
            v-if="stateOptionsForCountry(values.country?.value)"
            class="col-span-6 md:col-span-12"
          >
            <DropdownGroup
              name="state"
              :label="stateLabel(values.country)"
              :filter="true"
              :reset-filter-on-hide="true"
              option-label="nameString"
              :options="stateOptionsForCountry(values.country?.value)"
              :placeholder="$t('common.selectOption')"
              append-to="self"
            />
          </div>

          <div class="col-span-6 md:col-span-12">
            <InputTextGroup
              v-if="
                values.country && isCountryWithPostalCode(values.country?.value)
              "
              name="postalCode"
              :label="postalCodeLabel(values.country?.value)"
            />
          </div>
        </div>
      </div>

      <!-- ///////// -->
      <!-- Documents -->
      <!-- ///////// -->

      <div class="c-group">
        <div class="c-group__header">
          <h3 class="c-group__header__title">Documents</h3>
          <p class="c-group__header__description">
            Upload any available documents to support your application.
          </p>
          <InputTextGroup
            name="documentUploads"
            label=""
            :style="{ display: 'none' }"
          />
        </div>
        <div class="c-form__row grid grid-cols-12">
          <div class="col-span-6 md:col-span-12">
            <p class="c-group__header__description mb-2">
              Passport (front and back)
            </p>

            <div v-if="isExternalForm" :class="$style['unauthenticaed-upload']">
              <FileUpload
                :show-upload-button="false"
                :show-cancel-button="false"
                choose-icon="pi pi-upload"
                :max-file-size="MAX_FILE_SIZE"
                severity="info"
                class="p-button-outlined"
                :accept="ACCEPTABLE_FILE_TYPES"
                @select="(e) => onFileSelect(e, ID_TYPE.PASSPORT)"
                @remove="(e) => onFileSelect(e, ID_TYPE.PASSPORT)"
              >
                <template #empty>
                  <p>
                    {{ $t('common.messagePrompt.dragAndDropFilesOrBrowse') }}
                  </p>
                </template>
                <template #content="{ files, removeFileCallback }">
                  <UploadFileList
                    :files="files"
                    @remove-file="(index) => removeFileCallback(index)"
                  />
                </template>
              </FileUpload>
            </div>

            <UploadFileWithDownloadableFileList
              v-else
              ref="uploadFileWithDownloadableFileList"
              :upload-filters="generateFileFilter(ID_TYPE.PASSPORT)"
              :display-filters="generateFileFilter(ID_TYPE.PASSPORT)"
              :empty-prompt="
                $t('common.messagePrompt.dragAndDropFilesOrBrowse')
              "
              theme="onboarding"
              no-list
              :files="
                store.getters.uploadsMatching(
                  generateFileFilter(ID_TYPE.PASSPORT),
                )
              "
              @fileRemove="removeUpload"
            />
          </div>
          <div class="col-span-6 md:col-span-12">
            <p class="c-group__header__description mb-2">
              Driver’s license (front and back)
            </p>

            <div v-if="isExternalForm" :class="$style['unauthenticaed-upload']">
              <FileUpload
                :show-upload-button="false"
                :show-cancel-button="false"
                choose-icon="pi pi-upload"
                :max-file-size="MAX_FILE_SIZE"
                severity="info"
                class="p-button-outlined"
                :accept="ACCEPTABLE_FILE_TYPES"
                @select="(e) => onFileSelect(e, ID_TYPE.DRIVERS_LICENSE)"
                @remove="(e) => onFileSelect(e, ID_TYPE.DRIVERS_LICENSE)"
              >
                <template #empty>
                  <p>
                    {{ $t('common.messagePrompt.dragAndDropFilesOrBrowse') }}
                  </p>
                </template>
                <template #content="{ files, removeFileCallback }">
                  <UploadFileList
                    :files="files"
                    @remove-file="(index) => removeFileCallback(index)"
                  />
                </template>
              </FileUpload>
            </div>

            <UploadFileWithDownloadableFileList
              v-else
              ref="uploadFileWithDownloadableFileList"
              :upload-filters="generateFileFilter(ID_TYPE.DRIVERS_LICENSE)"
              :display-filters="generateFileFilter(ID_TYPE.DRIVERS_LICENSE)"
              :empty-prompt="
                $t('common.messagePrompt.dragAndDropFilesOrBrowse')
              "
              theme="onboarding"
              no-list
              :files="
                store.getters.uploadsMatching(
                  generateFileFilter(ID_TYPE.DRIVERS_LICENSE),
                )
              "
              @fileRemove="removeUpload"
            />
          </div>
        </div>
      </div>

      <!-- //////// -->
      <!-- Controls -->
      <!-- //////// -->

      <div class="c-form__controls" :style="{ flexWrap: 'wrap' }">
        <DSButton
          v-if="!props.isExternalForm"
          label="Back"
          class="p-button-outlined"
          :loading="isSubmitting"
          @click="onBack"
        />
        <DSButton label="Save Profile" type="submit" :loading="isSubmitting" />
        <div
          v-if="Object.keys(errors).length >= 1 || errorMessage"
          class="p-error"
          :class="$style['controls-error']"
        >
          <span v-if="errorMessage">
            {{ errorMessage }}
          </span>
          <span v-else>See errors above</span>
        </div>
      </div>
    </form>
  </div>
</template>

<style module>
.container {
  padding: 20px;
  margin-top: 40px;
  border: 1px solid #e8e8ea;
  box-shadow: 0 2px 8px 0 #0000000f;
  background: #fff;
}

.controls-error {
  text-align: right;
  width: 100%;
  margin-top: 20px;
}

@media (min-width: 800px) {
  .container {
    padding: 40px;
  }
}

.unauthenticaed-upload [class^='p-badge'] {
  display: none;
}
</style>
