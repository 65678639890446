import { countryOptionsWithPriority } from '@/data/country-code-with-names';
import {
  getCountryCallingCode,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { COUNTRY_CODES } from '@/data/supported-country-codes';

/**
 * This only works with NA/GB phone numbers (+1 or +44)
 * @param phoneNumber {string} - The phone number to format.
 * @returns {string} A formatted phone number.
 */
export const formatPhoneNumber = (phoneNumber) => {
  // TODO: Expand this to handle more international phone numbers other than NA/GB
  // Sanitize the phone number of any non 0-9 characters
  const cleanPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');

  // Slice the last 10 characters off the string and format. The remaining characters are the
  // country calling code
  // Check if this is US or GB
  if (
    cleanPhoneNumber.substring(0, 1) === '1' &&
    cleanPhoneNumber.length === 11
  ) {
    // This is a North American phone number
    return cleanPhoneNumber.replace(
      /(\d)(\d{3})(\d{3})(\d{4})/,
      '+$1 ($2) $3-$4',
    );
  } else if (
    cleanPhoneNumber.substring(0, 2) === '44' &&
    cleanPhoneNumber.length === 12
  ) {
    // This is a GB phone number
    return cleanPhoneNumber.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})/,
      '+$1 ($2) $3-$4',
    );
  }
  // Unable to determine type, return what was passed in
  return phoneNumber;
};

/**
 * Returns a calling code
 * @param {string} country - 2 digit alpha country code
 * @returns {(string|null)} calling code for a specified coutnry
 */
const getCallingCode = (country) => {
  try {
    return getCountryCallingCode(country);
  } catch {
    return null;
  }
};

/**
 * Converts a 2 digit alpha country code into a flag emoji
 * @param {string} countryCode - 2 digit alpha country code
 * @returns {string} Flag emoji of the country
 */
const getFlagEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

/**
 * Get list of all available calling codes
 * @returns {array} An array with objects for each calling code containing detailed data for each
 */
export const getAllCallingCodes = () => {
  // https://github.com/clearbanc/code/pull/17133
  // Our dropdowns are unable to invoke the name methods, we require a string
  const allCountries = countryOptionsWithPriority().map((value) => {
    return {
      ...value,
      nameString: value.name(),
      callingCode: getCallingCode(value.value),
      flag: getFlagEmoji(value.value),
    };
  });

  return allCountries.filter((item) => item.callingCode);
};

export const parsePhoneNumber = (phoneNumber, defaultCountry) => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);
  if (parsedNumber) {
    const callingCode = getAllCallingCodes().find(
      (code) => code.value === parsedNumber.country,
    );
    if (callingCode) {
      return {
        ...parsedNumber,
        countryCode: {
          nameString: callingCode.name(),
          callingCode: getCallingCode(callingCode.value),
          flag: getFlagEmoji(callingCode.value),
        },
      };
    }
  }
  if (defaultCountry) {
    const defaultCountryCode = getAllCallingCodes().find(
      (code) => code.value === defaultCountry,
    );
    return {
      countryCode: defaultCountryCode,
    };
  }
  return undefined;
};

export const getPhoneNumberCountryCode = (phoneNumber) => {
  if (!phoneNumber) return undefined;
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber, COUNTRY_CODES.US);
  const countryCallingCodes = getAllCallingCodes();

  const callingCode = countryCallingCodes.find((item) => {
    return item.value === parsedPhoneNumber.country;
  });

  return callingCode;
};

export const getPhoneNumberWithoutCountryCode = (
  phoneNumber,
  country = COUNTRY_CODES.US,
) => {
  if (!phoneNumber) return undefined;
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, country);
  return parsedPhoneNumber?.nationalNumber;
};
