import { SUPPORTED_LANGUAGES } from '@/configs/i18n-constants';
import account from '@/lang/account/';
import advancesPage from '@/lang/advances-page/';
import general from '@/lang/general/';
import onboarding2 from '@/lang/onboarding-2/';
import paymentsPage from '@/lang/payments-page/';
import spendPage from '@/lang/spend-page/';
import vendorsPage from '@/lang/vendors-page/';
import applicationPage from '@/lang/application-page/';
import backendErrors from '@/lang/backend-errors/';

const translations = [
  account,
  advancesPage,
  general,
  onboarding2,
  paymentsPage,
  spendPage,
  vendorsPage,
  applicationPage,
  backendErrors,
];

const locales = Object.values(SUPPORTED_LANGUAGES);

const messages = translations.reduce((acc, current) => {
  locales.forEach((locale) => {
    const currentLocale = acc?.[locale] ?? {};
    acc[locale] = { ...current[locale], ...currentLocale };
  });
  return acc;
}, {});

export default messages;
