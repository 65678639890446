// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list_Dn8O0{flex-direction:column}.list_Dn8O0,.list__item_OF3j2{display:flex;gap:10px}.list__item_OF3j2{align-items:flex-start}.list__item__button_KZjaA{flex-shrink:0}.list__item__text_Ol7EL{padding-top:4px;word-break:break-all}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "list_Dn8O0",
	"list__item": "list__item_OF3j2",
	"list__item__button": "list__item__button_KZjaA",
	"list__item__text": "list__item__text_Ol7EL"
};
export default ___CSS_LOADER_EXPORT___;
