<template>
  <div class="save-bar">
    <div class="spacer" />
    <div class="bar" :class="classObject">
      <div v-if="updateRequest.isPending" class="status">
        <icon name="spinner" />{{ $t('components.saveBar.savingYourChanges') }}
      </div>
      <div v-else-if="saveSuccessWasRecent" class="status">
        <icon name="check" />{{ $t('components.saveBar.saveSuccess') }}
      </div>
      <template v-else>
        <div v-if="updateRequest.isError" class="status">
          <icon name="exclamation-circle" />{{ updateRequest.error.message }}
        </div>
        <div v-if="hasUnsavedChanges" class="buttons">
          <v-button
            theme="transparent-light"
            class="full-width-on-mobile"
            style="margin-bottom: 8px"
            @click="$emit('reset')"
          >
            {{ $t('components.saveBar.undo') }}
          </v-button>
          <v-button
            :disabled="saveDisabled"
            class="full-width-on-mobile"
            theme="dark"
            @click="saveButtonHandler"
          >
            {{ $t('components.saveBar.saveChanges') }}
          </v-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

const SHOW_SAVE_DELAY = 2000;

export default {
  props: {
    draftValue: Object,
    storeValue: Object,
    updateRequest: Object,
    saveDisabled: Boolean,
  },
  data() {
    return {
      now: Date.now(),
    };
  },
  computed: {
    hasUnsavedChanges() {
      return _.keys(this.changesToSave).length > 0;
    },
    changesToSave() {
      if (this.draftValue === undefined || this.storeValue === undefined)
        return {};

      return _.pickBy(this.draftValue, (draftVal, key) => {
        const dv = JSON.stringify(draftVal);
        const sv = JSON.stringify(this.storeValue[key]);
        return dv !== sv;
      });
    },
    classObject() {
      return {
        'is-visible': this.hasUnsavedChanges || this.saveSuccessWasRecent,
        'is-error': this.updateRequest.isError,
        'is-success': this.saveSuccessWasRecent,
      };
    },
    statusMessage() {
      if (this.updateRequest.isPending)
        return this.$t('components.saveBar.savingChanges');
      if (this.updateRequest.isSuccess)
        return this.$t('components.saveBar.saveSuccess');
      if (this.hasUnsavedChanges)
        return this.$t('components.saveBar.readyToSave');
      return this.$t('components.saveBar.everythingInSync');
    },
    saveSuccessWasRecent() {
      if (this.updateRequest.isSuccess) {
        const timeSinceSave = this.now - this.updateRequest.receivedAt;
        return timeSinceSave < SHOW_SAVE_DELAY;
      }
      return false;
    },
  },
  created() {
    this.timerInterval = setInterval(() => {
      this.now = Date.now();
    }, 250);
  },
  beforeUnmount() {
    clearInterval(this.timerInterval);
  },
  methods: {
    warnAboutUnsavedChanges() {
      if (this.updateRequest.isPending) {
        return false;
      } else if (this.hasUnsavedChanges) {
        const warningMessage = this.$t(
          'components.saveBar.areYouSureYouWantToLeave',
        );
        return window.confirm(warningMessage); // eslint-disable-line no-alert
      }
      return true;
    },
    saveButtonHandler() {
      this.$emit('save', {
        // add back the ID so the
        id: this.draftValue.id,
        ...this.changesToSave,
      });
    },
  },
};
</script>

<style lang="less">
@save-bar-height: 60px;

.save-bar {
  > .spacer {
    min-height: @save-bar-height;
  }

  > .bar {
    width: 100%;
    min-height: @save-bar-height;
    position: fixed;
    z-index: 100;
    bottom: 0;
    bottom: -@save-bar-height;
    left: 0;
    background: #4286f4;
    color: white;
    transition: 0.5s all;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 380px) {
      padding: 12px;
    }

    .status {
      font-weight: bold;
      padding-right: 20px;
    }

    &.is-visible {
      bottom: 0;
    }

    &.is-error {
      background: @error-red-bg;
    }

    &.is-success {
      background: @green;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin: 0 10px;
  }

  .buttons {
    margin: 0 10px;
    padding-left: 50px;
    padding-right: 50px;

    @media only screen and (max-width: 380px) {
      width: 100%;
      padding: 0;
    }
  }

  .full-width-on-mobile {
    @media only screen and (max-width: 380px) {
      width: 100%;
    }
  }
}
</style>
