<template>
  <div ref="dropdownRef" :class="classes">
    <div :class="$style['dropdown-label']" @click="openMenu">
      {{ required ? `${externalLabel}*` : externalLabel }}
      <ui-tooltip v-if="tooltipMsg" :text="tooltipMsg" />
    </div>
    <div
      :data-cy="kebabCase(name)"
      :class="$style.menu"
      :tabindex="disabled ? '-1' : '1'"
      @click="openMenu"
    >
      <span :class="$style.selected">{{ selectedName || `- ${label}` }}</span>
      <ui-icon name="back" size="15" :class="$style.icon" />
    </div>
    <div
      :class="$style['option-wrapper']"
      :style="{ height: isOpen ? `${computedHeight}px` : '0px' }"
    >
      <div
        v-for="(option, index) in sortTopSectionOptions"
        :key="`${option.value}-${index}`"
        tabindex="0"
        :class="optionClasses(option)"
        :value="option.value"
        @click="onClick(option.value)"
        @keyup.enter="onClick(option.value)"
      >
        {{ option.name() }}
      </div>
      <hr v-if="sortTopSectionOptions.length" />
      <div
        v-for="(option, index) in regularOptions"
        :key="`${option.value}-${index}`"
        :class="optionClasses(option)"
        tabindex="0"
        :value="option.value"
        @click="onClick(option.value)"
        @keyup.enter="onClick(option.value)"
      >
        {{ option.name() }}
      </div>
    </div>
    <div v-if="isTouched && !isValid" :class="$style.message">
      {{ validationErrorMsg }}
    </div>
  </div>
</template>

<script>
import { UiIcon, UiTooltip } from 'ui-components';
import kebabCase from '@/utils/kebabcase';
import { inputValidationSetup } from '@/composables/validation';

export default {
  emits: ['input', 'update:modelValue'],
  components: {
    UiIcon,
    UiTooltip,
  },
  props: {
    name: String,
    label: String,
    externalLabel: String,
    required: Boolean,
    disabled: Boolean,
    options: Array,
    modelValue: {},
    newDesign: Boolean,
    tooltipMsg: String,
    applySort: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isValid, isTouched } = inputValidationSetup(props);
    return {
      isValid,
      isTouched,
    };
  },
  data() {
    return {
      isOpen: false,
      validationErrorMsg: '',
    };
  },
  computed: {
    classes() {
      return {
        [this.$style.wrapper]: !this.newDesign,
        [this.$style['wrapper-design']]: this.newDesign,
        [this.$style.disabled]: this.disabled,
        [this.$style.collapsed]: this.isOpen,
        [this.$style.error]: this.isTouched && !this.isValid,
      };
    },
    sortTopSectionOptions() {
      return this.options
        .filter((val) => val.priority)
        .sort((a, b) => a.name().localeCompare(b.name()));
    },
    regularOptions() {
      const filteredOptions = this.options.filter((val) => !val.priority);
      return this.applySort
        ? filteredOptions.sort((a, b) => a.name().localeCompare(b.name()))
        : filteredOptions;
    },
    selectedName() {
      const { name } =
        this.options.find((val) => val.value === this.modelValue) || {};
      if (name) {
        return name();
      }
      return this.$t('common.select');
    },
    computedHeight() {
      const length = this.sortTopSectionOptions.concat(
        this.regularOptions,
      ).length;

      return 42 * length + 9 < 177 ? 42 * length + 9 : 177;
    },
  },
  watch: {
    isTouched() {
      this.validate(this.modelValue);
    },
  },
  mounted() {
    window.addEventListener('click', this.handleClick);
  },
  unmounted() {
    window.removeEventListener('click', this.handleClick);
  },
  methods: {
    validate(val) {
      const value = val || this.modelValue;
      // empty field validation
      if (this.required) {
        this.isValid = !!value;
        this.validationErrorMsg = this.$t('common.requiredField');
      } else {
        this.isValid = true;
      }
    },
    openMenu() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
      }
    },
    onClick(val) {
      this.isOpen = false;
      this.$emit('input', val, this.name);
      this.$emit('update:modelValue', val);
      this.validate(val);
    },
    optionClasses(option) {
      return {
        [this.$style.option]: true,
        [this.$style.disabled]: option.disabled,
      };
    },
    handleClick(e) {
      const { dropdownRef } = this.$refs;

      if (dropdownRef && !dropdownRef.contains(e.target)) {
        this.isOpen = false;
      }
    },
    kebabCase,
  },
};
</script>

<style lang="less" module>
.wrapper {
  position: relative;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: @font-size-15;
  height: 45px;
  min-height: 73px;
  max-width: 400px;
  line-height: 47px;
  text-align: left;
  flex: 1;

  &.error {
    color: @color-red-new;
  }

  &.collapsed {
    height: auto;
    min-height: auto;
  }

  .menu {
    position: relative;
    box-shadow: inset 0 0 0 1px @color-black-new;
    border: 0;
    border-radius: 3px;
    color: currentColor;
    box-sizing: border-box;
    padding: 0 @gutter-15;
    height: 45px;
    width: 100%;
    min-width: 180px;
    background: @color-white;
    cursor: pointer;
    z-index: 2;

    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 1.5px currentColor;
    }

    .error& {
      box-shadow: inset 0 0 0 1px @color-red-new;
      background: @color-light-red-new;
    }

    .icon {
      position: absolute;
      top: 15px;
      right: @gutter-15;
      transition: all 200ms ease;
      transform: rotate(-90deg);

      .collapsed& {
        transform: rotate(90deg);
      }
    }

    .disabled& {
      color: @color-gray;
      box-shadow: inset 0 0 0 1px @color-gray;
      pointer-events: none;
      cursor: default;
      background: rgb(250, 250, 250);
    }

    .selected {
      width: 85%;
      position: absolute;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .option-wrapper {
    overflow: scroll;
    margin: -7px 3px 7px;
    border-radius: 3px;
    z-index: 3;
    transition: none;

    .collapsed& {
      border: 1px solid currentColor;
      transition: height 200ms ease;
      margin-bottom: @gutter-25;
    }

    hr {
      margin: 0;
      padding: 0;
      border: 0;
      height: 1px;
      background: @color-lightgray-300;
    }

    .option {
      padding-left: @gutter-12;
      width: 100%;
      z-index: 0;
      cursor: pointer;
      line-height: 42px;
      background: @color-white;

      &:hover {
        background: @color-lightgray-100;
      }

      &:first-child {
        padding-top: @gutter-7;
      }

      &.disabled {
        color: @color-gray;
        pointer-events: none;
        cursor: pointer;
      }
    }
  }

  // external label for dropdown
  .dropdown-label {
    position: absolute;
    top: -30px;
    left: 0;
    font-family: 'Montserrat';
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    vertical-align: top;
    text-align: left;
    color: @color-black;

    .collapsed& {
      font-weight: 600;
    }
  }

  // message below core component
  .message {
    font-size: @font-size-10;
    font-family: 'Montserrat';
    margin-left: @gutter-10;
    margin-top: @gutter-10;
    line-height: 6px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: left;
    text-transform: none;
  }
}

.wrapper-design {
  position: relative;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: @font-size-15;
  height: 45px;
  min-height: 73px;
  line-height: 47px;
  text-align: left;
  flex: 1;

  @media only screen and (max-width: 840px) {
    width: 100%;
    margin: auto;
    padding: 0;
  }

  &.error {
    color: @color-red-new;
  }

  .menu {
    border: 0;
    border-radius: 0;
    padding: 17px 25px;
    color: #4f5362;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    line-height: @font-size-15;
    min-width: 180px;
    -webkit-appearance: none;
    background-color: #eff0f4;

    &:focus {
      outline: 0;
      font-weight: 500;
      box-shadow: 0 9px 18px rgba(168, 172, 185, 0.62);
      background: @color-white;
    }

    .error& {
      box-shadow: inset 0 0 0 1px @color-red-new;
      background: @color-light-red-new;
    }

    .icon {
      position: absolute;
      top: 15px;
      right: @gutter-15;
      transition: all 200ms ease;
      transform: rotate(-90deg);

      .collapsed& {
        transform: rotate(90deg);
      }
    }

    .disabled& {
      color: @color-gray;
      box-shadow: inset 0 0 0 1px @color-gray;
      pointer-events: none;
      cursor: default;
    }
  }

  .option-wrapper {
    overflow: scroll;
    position: absolute;
    border-radius: 0;
    z-index: 3;
    transition: none;
    width: 100%;

    .collapsed& {
      transition: height 200ms ease;
      margin-bottom: @gutter-25;
    }

    hr {
      margin: 0;
      padding: 0;
      border: 0;
      height: 1px;
      background: @color-lightgray-300;
    }

    .option {
      padding-left: @gutter-12;
      width: 100%;
      z-index: 0;
      cursor: pointer;
      line-height: 42px;
      background: @color-white;
      color: #000;

      &:first-child {
        padding-top: @gutter-7;
      }

      &.disabled {
        color: @color-gray;
        pointer-events: none;
        cursor: pointer;
      }
    }
  }

  // external label for dropdown
  .dropdown-label {
    position: absolute;
    top: -40px;
    left: 0;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    vertical-align: top;
    color: @color-black;

    .collapsed& {
      font-weight: 600;
    }
  }

  // message below core component
  .message {
    font-size: @font-size-10;
    font-family: 'Montserrat';
    margin-left: @gutter-10;
    margin-top: @gutter-10;
    line-height: 6px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: left;
    text-transform: none;
  }
}
</style>
