<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="getViewBox"
    :aria-labelledby="iconName"
    role="presentation"
    fill="none"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <g :stroke="strokeColor" :fill="fillColor">
      <component :is="`${iconName}Icon`"></component>
    </g>
  </svg>
</template>

<script>
import GiftIcon from './gift-icon';
import ChevronIcon from './chevron-icon';
import CheckmarkIcon from './checkmark-icon';
import WarningIcon from './warning-icon';
import LoadingIcon from './loading-icon';
import ArrowIcon from './arrow-icon';

export default {
  components: {
    GiftIcon,
    ChevronIcon,
    CheckmarkIcon,
    WarningIcon,
    LoadingIcon,
    ArrowIcon,
  },
  computed: {
    getViewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },
  },
  props: {
    iconName: {
      type: String,
      required: true,
      validator(value) {
        return [
          'Gift',
          'Chevron',
          'Checkmark',
          'Warning',
          'Loading',
          'Arrow',
        ].includes(value);
      },
    },
    width: {
      type: Number,
      default: 21,
    },
    height: {
      type: Number,
      default: 21,
    },
    fillColor: {
      type: String,
      default: 'none',
    },
    strokeColor: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
