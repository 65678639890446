// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title-header_T7Hfm{font-size:16px;color:#5f5f61;font-weight:700;margin-bottom:4px;line-height:22px}.title-header_T7Hfm,.total-funding_uH5PV{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif}.total-funding_uH5PV{font-size:36px;color:#111;font-weight:400;margin-bottom:24px;line-height:48px}.advance-row_zcu86{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-between}.advance-amount_W6T2J{font-size:22px;color:#111;font-weight:400;line-height:28px}.advance-header_P9B_w{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:14px;color:#5f5f61;font-weight:700;line-height:20px}.divider_EBCCd{background-color:#ceced0;border:none;color:#ceced0;height:1px;margin:24px -24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title-header": "title-header_T7Hfm",
	"total-funding": "total-funding_uH5PV",
	"advance-row": "advance-row_zcu86",
	"advance-amount": "advance-amount_W6T2J",
	"advance-header": "advance-header_P9B_w",
	"divider": "divider_EBCCd"
};
export default ___CSS_LOADER_EXPORT___;
