// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wayfinding-footer_kJ6VC{min-width:320px;white-space:nowrap;display:flex;flex-direction:row;align-items:flex-end;justify-content:space-between;text-align:center;padding:30px;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:14px}.wayfinding-footer__copy_R4lDN{margin:0;color:#ababad;white-space:nowrap;display:inline}.wayfinding-footer__links_j_Aiu{display:flex;flex-direction:column;align-items:flex-end}.wayfinding-footer__links__item_M_WQ9{color:#ababad;text-decoration:underline}@media (min-width:600px){.wayfinding-footer_kJ6VC{justify-content:space-between;flex-direction:row;border:0}.wayfinding-footer__links_j_Aiu{flex-direction:row}.wayfinding-footer__links__item_M_WQ9+.wayfinding-footer__links__item_M_WQ9{margin-left:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wayfinding-footer": "wayfinding-footer_kJ6VC",
	"wayfinding-footer__copy": "wayfinding-footer__copy_R4lDN",
	"wayfinding-footer__links": "wayfinding-footer__links_j_Aiu",
	"wayfinding-footer__links__item": "wayfinding-footer__links__item_M_WQ9"
};
export default ___CSS_LOADER_EXPORT___;
