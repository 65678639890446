// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".connector_SwIqs{max-width:800px;margin:60px auto 90px;text-align:center}.connector_SwIqs.error_Ejaqr{margin-top:10px}@media only screen and (max-width:500px){.connector_SwIqs{margin:30px auto 40px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"connector": "connector_SwIqs",
	"error": "error_Ejaqr"
};
export default ___CSS_LOADER_EXPORT___;
