// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown-container__i3Ez,.dropdown-container__i3Ez .multiselect-custom_reQ4P{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-container": "dropdown-container__i3Ez",
	"multiselect-custom": "multiselect-custom_reQ4P"
};
export default ___CSS_LOADER_EXPORT___;
