// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_mcJhe{margin:55px auto auto;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}@media screen and (max-width:500px){.container_mcJhe{margin-top:30px;width:100%}}.container_mcJhe.with-error_z30oj{margin-top:30px}@media screen and (max-width:500px){.container_mcJhe.with-error_z30oj{margin-top:20px}}.cta_EzDHV{margin-top:50px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_mcJhe",
	"with-error": "with-error_z30oj",
	"cta": "cta_EzDHV"
};
export default ___CSS_LOADER_EXPORT___;
