<template>
  <div>
    <div :class="$style.modalContext">
      {{ $t('vendorsPage.vendorInfoComponent.info') }}
    </div>
    <custom-fieldset>
      <UiInput
        name="label"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.nickname')"
        :model-value="selectedVendor.label"
        required
        @input="setVendorProps"
      />
      <UiDropdown
        :external-label="$t('vendorsPage.vendorInfoComponent.vendorCategory')"
        name="vendorCategory"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.selectCategory')"
        data-cy="vendor-category"
        :options="vendorCategories"
        :model-value="selectedVendor.vendorCategory"
        size="small"
        required
        @input="setVendorProps"
      />
      <UiInput
        name="details.website"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.website')"
        :model-value="selectedVendor.details.website"
        @input="setVendorProps"
      />
    </custom-fieldset>
    <custom-fieldset>
      <UiInput
        :external-label="$t('vendorsPage.vendorInfoComponent.reasonForPayment')"
        name="details.reason"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('vendorsPage.vendorInfoComponent.reasonForPayment')"
        :model-value="selectedVendor.details.reason"
        @input="setVendorProps"
      />
    </custom-fieldset>
    <custom-fieldset>
      <UiInput
        name="details.vendorName"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.contactName')"
        :model-value="selectedVendor.details.vendorName"
        required
        @input="setVendorProps"
      />
      <UiInput
        name="details.vendorEmail"
        type="email"
        :email-validation-msg="$t('common.invalidEmail')"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.contactEmail')"
        :model-value="selectedVendor.details.vendorEmail"
        :required="selectedVendor.details.vendorPhone === ''"
        @input="setVendorProps"
      />
      <UiInput
        type="number"
        name="details.vendorPhone"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.contactPhone')"
        :model-value="selectedVendor.details.vendorPhone"
        :required="selectedVendor.details.vendorEmail === ''"
        @input="setVendorProps"
      />
    </custom-fieldset>
    <UiButton
      :text="$t('common.buttons.next')"
      data-cy="next-button"
      @click="$emit('next-tab')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UiDropdown, UiButton, UiInput } from 'ui-components';
import {
  VENDOR_CATEGORIES,
  INDIEGOGO_VENDOR_CATEGORIES,
} from '@/data/vendor-categories';
import { validateChildren } from '@/composables/validation';
import CustomFieldset from '../FieldsetCustom';

export default {
  components: {
    UiButton,
    UiInput,
    UiDropdown,
    CustomFieldset,
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  computed: {
    ...mapGetters(['selectedVendor', 'activeAdvances']),
    hasActiveIndiegogoAdvance() {
      return (
        this.activeAdvances.length === 1 &&
        [this.activeAdvances[0].feesVersionId].includes(9)
      );
    },
    vendorCategories() {
      const categoryOptions = this.hasActiveIndiegogoAdvance
        ? INDIEGOGO_VENDOR_CATEGORIES
        : VENDOR_CATEGORIES;
      return Object.entries(categoryOptions).map((v, i) => {
        return {
          key: i,
          value: v[0],
          name: v[1],
        };
      });
    },
  },
  methods: {
    setVendorProps(val, name) {
      this.$store.dispatch('UPDATE_SELECTED_VENDOR', { path: name, val });
    },
  },
};
</script>
<style lang="less" module>
.modalContext {
  color: black;
  padding-bottom: 30px;
  text-align: center;
  font-weight: bold;
}
</style>
