<template>
  <div :class="$style['progress-bar-track']">
    <span
      ref="progressBarId"
      :style="progressBar"
      :class="$style['progress-bar']"
    />
  </div>
</template>

<script>
export default {
  props: {
    percentComplete: {
      type: Number,
    },
  },
  computed: {
    stepClasses() {
      return {
        [this.$style.step]: true,
        [this.$style.complete]: this.complete,
      };
    },
    labelClasses() {
      return {
        [this.$style['inactive-label']]: !this.isActive,
      };
    },
    progressBar() {
      return {
        width: `${this.percentComplete}%`,
      };
    },
  },
};
</script>

<style lang="less" module>
.progress-bar-track {
  height: 4px;
  background: @line-gray;
  border-radius: 3px;
  .progress-bar {
    transition: width 0.5s;
    display: block;
    height: 100%;
    background: linear-gradient(
      239.57deg,
      @bright-green 20.53%,
      @turquoise 79.85%
    );
    border-radius: 10px;
  }
}
</style>
