// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-footer-processing_PDLzf{max-width:350px;margin:0 auto;text-align:center}.modal-footer-processing_PDLzf p{font-size:12px;margin:0}.row-content_QGe1G{margin:0;color:#000;overflow-wrap:break-word;font-weight:400}.row-content_QGe1G a{font-size:14px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-footer-processing": "modal-footer-processing_PDLzf",
	"row-content": "row-content_QGe1G"
};
export default ___CSS_LOADER_EXPORT___;
