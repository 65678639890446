<template>
  <div :class="$style['white-background']">
    <slot />
  </div>
</template>
<style lang="less" module>
.white-background {
  min-height: 100vh;
  min-width: 100vw;
  background-color: @color-white;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
