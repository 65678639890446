// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".verification-container_UcnqZ{display:flex;flex-direction:column;align-items:center}.message-banner_VLPWp{margin:20px 20px 0 20px!important;max-width:700px}.confirmation-content_movNq span{font-weight:500}.loading-content_vCNA0{margin:30px auto}.actions-panel_wO2rV{border-top:1px solid #a7a6a6;padding-top:10px;margin-top:30px;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;line-height:18px}.actions-panel_wO2rV>button{margin-top:8px;display:inline-block;color:#0e4787}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verification-container": "verification-container_UcnqZ",
	"message-banner": "message-banner_VLPWp",
	"confirmation-content": "confirmation-content_movNq",
	"loading-content": "loading-content_vCNA0",
	"actions-panel": "actions-panel_wO2rV"
};
export default ___CSS_LOADER_EXPORT___;
