import { OBFUSCATING_PLAID_BANK_PROVIDERS } from '@/data/obfuscating-plaid-bank-providers';
import { computed } from 'vue';

export default function (context) {
  const ctx = context.root;
  const { getters } = ctx.$store;

  const bankAccounts = computed(() => getters.bankAccounts);
  const currentBankAccountToBePrimary = computed(
    () => getters.currentBankAccountToBePrimary,
  );
  const userHasActiveBillAdvances = computed(
    () => getters.userHasActiveBillAdvances,
  );
  const primaryBankAccount = computed(() => getters.primaryBankAccount);
  const contracts = computed(() => getters.contracts);
  const advances = computed(() => getters.advances);

  const bankStatementsRequired = (bankAccountId) => {
    const selectedBankAccount = bankAccounts.value.find(
      (bankAccount) => bankAccount.id === bankAccountId,
    );

    return OBFUSCATING_PLAID_BANK_PROVIDERS.includes(
      selectedBankAccount.bankName,
    );
  };

  /**
   * Adds 'setAsPrimary: true' to details column of a bank account.
   * Also unmarks the previous bank account as primary, if exists.
   * @param bankAccountId bank account id to set as primary
   */
  const markBankAccountToBecomePrimary = async (bankAccountId) => {
    const apiActions = [];
    if (
      currentBankAccountToBePrimary.value &&
      currentBankAccountToBePrimary.value.id !== bankAccountId
    ) {
      const currentBankAccountToBePrimaryDetails = {
        ...currentBankAccountToBePrimary.value.details,
      };

      delete currentBankAccountToBePrimaryDetails.setAsPrimary;

      // unmark currentBankAccountToBePrimary as primary
      const unmarkCurrentBankAccountAsPrimaryRequest =
        ctx.$store.dispatchApiAction('UPDATE_BANK_ACCOUNT', {
          id: currentBankAccountToBePrimary.value.id,
          details: currentBankAccountToBePrimaryDetails,
        });

      apiActions.push(unmarkCurrentBankAccountAsPrimaryRequest);
    }
    // mark new bankAccount as primary

    const newBankAccountToBePrimary = bankAccounts.value.find(
      (bankAccount) => bankAccount.id === bankAccountId,
    );

    const markNewBankAccountAsPrimaryRequest = ctx.$store.dispatchApiAction(
      'UPDATE_BANK_ACCOUNT',
      {
        id: bankAccountId,
        details: {
          ...newBankAccountToBePrimary.details,
          setAsPrimary: true,
        },
      },
    );

    apiActions.push(markNewBankAccountAsPrimaryRequest);

    await Promise.all(apiActions);
  };

  const isAdvanceInNegotiation = (advanceId) => {
    const advance = advances.value.find((adv) => adv.id === advanceId);

    if (advance) {
      return !advance.activatedAt && !advance.rejectedAt;
    }

    return false;
  };

  const bankAccountHasActiveContract = (bankAccountId) => {
    const filteredContracts = contracts.value.filter(
      (contract) =>
        contract.bankAccountsId === bankAccountId &&
        contract.type === 'bnpl_bill',
    );

    return filteredContracts.some((contract) =>
      isAdvanceInNegotiation(contract.advanceId),
    );
  };

  // Users can change their primary bank account as long as:
  // 1. They have no active bill advances
  // 2. They have no active contracts for their current primary bank account. A
  // contract is active if it's for an advance that's currently in negotiation
  const canChangePrimaryBankAccount = () => {
    if (
      userHasActiveBillAdvances.value ||
      (primaryBankAccount.value &&
        bankAccountHasActiveContract(primaryBankAccount.value.id)) ||
      (currentBankAccountToBePrimary.value &&
        bankAccountHasActiveContract(currentBankAccountToBePrimary.value.id))
    ) {
      return false;
    }
    return true;
  };

  return {
    bankStatementsRequired,
    markBankAccountToBecomePrimary,
    canChangePrimaryBankAccount,
  };
}
