// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".message-wrapper_BllNj{max-width:683px;margin:0 auto}.message-wrapper_BllNj .contact_Q_lUK{color:#0d3685;text-decoration:underline}@media only screen and (min-width:500px){.message-wrapper_BllNj .contact_Q_lUK.link_p00Au{display:none}}.message-wrapper_BllNj .contact_Q_lUK.unlink_Ly8qp{color:unset;text-decoration:none}@media only screen and (max-width:500px){.message-wrapper_BllNj .contact_Q_lUK.unlink_Ly8qp{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message-wrapper": "message-wrapper_BllNj",
	"contact": "contact_Q_lUK",
	"link": "link_p00Au",
	"unlink": "unlink_Ly8qp"
};
export default ___CSS_LOADER_EXPORT___;
