<template>
  <div :class="$style.calculator__cta">
    <div :class="$style.calculator__cta__content">
      <div :class="$style.calculator__cta__content__left">
        <span
          :class="$style.calculator__cta__content__left__icon"
          class="material-symbols-outlined"
          >receipt_long</span
        >
        Ready to submit your first invoice?
      </div>
      <div :class="$style.calculator__cta__content__right">
        <DSButton
          label="Finish Application"
          @click="
            () => {
              analytics.track(
                'product_education_clicked_cta_finish_application',
              );
              $router.push({
                name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
              });
            }
          "
        />
      </div>
    </div>
    <div :class="$style.calculator__cta__filler"></div>
  </div>
</template>

<script>
import DSButton from '@clearbanc/clear-components/button';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import analytics from '@/utils/analytics';

export default {
  components: { DSButton },
  data() {
    return {
      PAYMENTS_ROUTE_NAMES,
      analytics,
    };
  },
};
</script>

<style lang="less" module>
.calculator__cta {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  grid-column: 1/3;
  grid-row: 3/4;
}

@media (min-width: 980px) {
  .calculator__cta {
    grid-template-columns: max-content 1fr;
  }
}

.calculator__cta__filler {
  background: #ebebeb;
  border-radius: 4px;
}

.calculator__cta__content {
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (min-width: 550px) {
  .calculator__cta__content {
    flex-direction: row;
  }
}

.calculator__cta__content:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #f7f7f9;
}

.calculator__cta__content__left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
  color: #111;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@media (min-width: 980px) {
  .calculator__cta__content__left {
    font-size: 28px;
  }
}

.calculator__cta__content__left__icon {
  margin-right: 11px;
}

.calculator__cta__content__right {
  display: flex;
  align-items: center;
  border-top: 1px solid #f6f6f6;
  padding: 20px;
  white-space: nowrap;
  flex-grow: 1;
}

@media (min-width: 550px) {
  .calculator__cta__content__right {
    border-left: 1px solid #f6f6f6;
    border-top: 0;
  }
}

.calculator__cta__content__right button {
  width: 100%;
}
</style>
