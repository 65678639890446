<template>
  <LayoutForm>
    <UploadFileWithDownloadableFileList
      :upload-filters="uploadFilters"
      :display-filters="financialStatementUploadMatcher"
      :empty-prompt="messagePrompt"
      theme="onboarding"
      no-list
      :files="financialStatements"
      @dropzoneClicked="trackEvent('upload-btn')"
      @trackUploadedDocClicked="trackEvent('download-link')"
      @docsUploaded="trackDocsUploaded"
      @fileRemove="removeUpload"
    />
  </LayoutForm>
</template>

<script>
import analytics from '@/utils/analytics';
import { format, subMonths } from 'date-fns';
import { isMobileMixin } from '@/utils/vue-mixins';
import { mapGetters } from 'vuex';
import { ENTITY_TYPE, BUSINESS_DOC_TYPE } from '@clearbanc/data-common-types';
import { UploadFileWithDownloadableFileList, LayoutForm } from '@/components';

export default {
  components: {
    UploadFileWithDownloadableFileList,
    LayoutForm,
  },
  mixins: [isMobileMixin],
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
  },
  computed: {
    ...mapGetters(['business', 'financialStatementUploadMatcher']),
    todayMinusFiveMonths() {
      return format(subMonths(new Date(), 5), 'MMMM yyy');
    },
    uploadFilters() {
      return {
        entity: ENTITY_TYPE.BUSINESS,
        type: BUSINESS_DOC_TYPE.FINANCIAL_STATEMENTS,
        businessId: this.business.id,
      };
    },
    financialStatements() {
      return this.$store.getters.uploadsMatching(
        this.financialStatementUploadMatcher,
      );
    },
    messagePrompt() {
      return this.isMobile()
        ? this.$t('common.messagePrompt.browse')
        : this.$t('common.messagePrompt.dragAndDropFilesOrBrowse');
    },
  },
  methods: {
    trackEvent(name, props) {
      analytics.track(`fe_${name}`, { ...this.eventTrackingFields, ...props });
    },
    removeUpload(id) {
      this.trackEvent('delete-document');
      this.$store.dispatchApiAction('DELETE_USER_UPLOAD', { id });
    },
    trackDocsUploaded(uploadData) {
      this.$emit('fileUploaded', uploadData);
    },
  },
};
</script>
