// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".progress-bar-track_vyZDQ{height:4px;background:#ebeff2;border-radius:3px}.progress-bar-track_vyZDQ .progress-bar_xDwCx{transition:width .5s;display:block;height:100%;background:linear-gradient(239.57deg,#0bc83d 20.53%,#00a69d 79.85%);border-radius:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress-bar-track": "progress-bar-track_vyZDQ",
	"progress-bar": "progress-bar_xDwCx"
};
export default ___CSS_LOADER_EXPORT___;
