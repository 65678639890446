// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".message_gR6rc[data-v-1979cc6a]{height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center}.message__image_iXSA3[data-v-1979cc6a]{width:150px;height:150px;margin-bottom:40px}.message__image_iXSA3 img[data-v-1979cc6a]{width:100%;height:100%}.message__title_pQW2x[data-v-1979cc6a]{all:unset;margin-bottom:16px;font-size:24px;font-weight:500;line-height:32px}.message__description_guKyO[data-v-1979cc6a],.message__title_pQW2x[data-v-1979cc6a]{color:var(--neutral-black,#000);font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-style:normal}.message__description_guKyO[data-v-1979cc6a]{text-align:center;font-size:16px;font-weight:300;line-height:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "message_gR6rc",
	"message__image": "message__image_iXSA3",
	"message__title": "message__title_pQW2x",
	"message__description": "message__description_guKyO"
};
export default ___CSS_LOADER_EXPORT___;
