// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_obstm{height:100vh}.subcontainer_xVjh2{width:100%;display:flex;justify-content:space-between;align-items:center;margin-top:48px}.container_gv5_q{text-align:left;padding:36px 36px 36px 36px}.fit-width_YY_GA{width:150px}.button-width_m1ScR{width:186px}@media only screen and (max-width:500px){.container_gv5_q{padding:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_obstm",
	"subcontainer": "subcontainer_xVjh2",
	"container": "container_gv5_q",
	"fit-width": "fit-width_YY_GA",
	"button-width": "button-width_m1ScR"
};
export default ___CSS_LOADER_EXPORT___;
