// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".warning-message_RGpsZ{display:flex;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-size:14px;line-height:20px}.warning-message_RGpsZ>svg{flex-shrink:0}.warning-message_RGpsZ>div{display:inline-block;padding-left:8px;color:#111}.warning-message_RGpsZ>div a{color:#111;text-decoration:underline}.warning-message_RGpsZ>div a:active{color:#1e4a9f}.warning-message_RGpsZ>div>.title_p0dEZ{font-weight:700}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning-message": "warning-message_RGpsZ",
	"title": "title_p0dEZ"
};
export default ___CSS_LOADER_EXPORT___;
