<template>
  <div :class="[$style['flex-container'], $style.baseline]">
    <div :class="[$style['flex-item']]">
      <svg
        width="32"
        height="33"
        v-html="require(`@/assets/icons/inventory-invoice.svg`)"
      />
      <p>
        {{
          $t(
            'account.pages.pageVendors.extendedPaymentOptions.inventoryInvoiceLabel',
          )
        }}
      </p>
    </div>
    <div :class="[$style['flex-item']]">
      <svg
        width="32"
        height="34"
        v-html="require(`@/assets/icons/marketing-invoice.svg`)"
      />
      <p>
        {{
          $t(
            'account.pages.pageVendors.extendedPaymentOptions.marketingInvoiceLabel',
          )
        }}
      </p>
    </div>
    <div :class="[$style['flex-item']]">
      <svg
        width="42"
        height="30"
        v-html="require(`@/assets/icons/shipping-and-logistics.svg`)"
      />
      <p>
        {{
          $t(
            'account.pages.pageVendors.extendedPaymentOptions.shippingAndLogisticsInvoiceLabel',
          )
        }}
      </p>
    </div>
    <div :class="[$style['flex-item']]">
      <svg
        width="47"
        height="38"
        v-html="require(`@/assets/icons/professional-services.svg`)"
      />
      <p>
        {{
          $t(
            'account.pages.pageVendors.extendedPaymentOptions.professionalServicesLabel',
          )
        }}
      </p>
    </div>
    <div :class="[$style['flex-item']]">
      <svg width="32" height="36" v-html="require(`@/assets/icons/gift.svg`)" />
      <p>
        {{
          $t(
            'account.pages.pageVendors.extendedPaymentOptions.otherInvoiceLabel',
          )
        }}
      </p>
    </div>
  </div>
</template>

<style lang="less" module>
.flex-container {
  /* https://css-tricks.com/almanac/properties/a/align-items/ */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .flex-item {
    font-weight: bold;
    text-align: center;
    p {
      margin: 0;
      padding: 0;
    }
  }
}

.baseline {
  align-items: baseline;
}

.flex-container {
  padding: 15px;
  .flex-item {
    width: 125px;
    p {
      font-size: 12px;
    }
  }
}
</style>
