// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_bgCI1{background:#fff;border-top:1px solid #ceced0;position:fixed;bottom:0;left:0;box-shadow:3px 3px 6px rgba(0,0,0,.1);width:100%;z-index:2;height:70px;transition:.6s cubic-bezier(.785,.135,.15,.86);-webkit-transition:.6s cubic-bezier(.785,.135,.15,.86)}.hidden_D0RhG{transform:translateY(100px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_bgCI1",
	"hidden": "hidden_D0RhG"
};
export default ___CSS_LOADER_EXPORT___;
