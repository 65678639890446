// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".heading_DReLS{margin-bottom:1rem}.heading_DReLS p{margin:0}.form_c3XTc{text-align:left;padding:0}.info-box-content_Xg7NT p:first-child{font-weight:700!important}.requirements-checkbox_cMNxr{margin:1rem;width:1.3rem;height:1.3rem;accent-color:#7f30ff}.receipt-checkbox-container_odb9H{display:flex;flex-direction:row;align-items:center;justify-content:flex-start;margin-bottom:1rem}.link_IQlys{color:#145bce}.error_Tqhic{margin:.5rem 0 0}button.back-button_gXNdF{margin-right:auto;padding:0}.uploadWrapper_YTwXt{margin:0;width:100%;padding:2.5rem}.uploadWrapper__file-types_FvZbI{margin-bottom:.5rem}.uploaded-files_N8w1i{display:flex;align-items:center}.uploaded-files__item_q6H1H{margin-right:.75rem}.error_Tqhic{margin-top:.5rem;font-weight:400}.icon__pWTh{height:12px;width:12px;cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "heading_DReLS",
	"form": "form_c3XTc",
	"info-box-content": "info-box-content_Xg7NT",
	"requirements-checkbox": "requirements-checkbox_cMNxr",
	"receipt-checkbox-container": "receipt-checkbox-container_odb9H",
	"link": "link_IQlys",
	"error": "error_Tqhic",
	"back-button": "back-button_gXNdF",
	"uploadWrapper": "uploadWrapper_YTwXt",
	"uploadWrapper__file-types": "uploadWrapper__file-types_FvZbI",
	"uploaded-files": "uploaded-files_N8w1i",
	"uploaded-files__item": "uploaded-files__item_q6H1H",
	"icon": "icon__pWTh"
};
export default ___CSS_LOADER_EXPORT___;
