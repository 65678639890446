// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bill-cancellation-modal_UAv2q h2.title_uXsto{text-align:center;font-size:18px;margin:0;margin-top:20px;font-weight:600}.bill-cancellation-modal_UAv2q p{max-width:500px}.cancel-box-text_CXvN_{font-size:16px;text-align:center}.bill-pay-popup-container_cZjYr{max-width:640px;margin:0 auto;text-align:center;padding:0}.paybill-bottom-action-cancel_jLuTA{display:flex;justify-content:space-between;align-items:center;margin-top:45px}.cancel-dropdown_EY9nB{width:100%}textarea.cancel-textarea_WWZuj{box-sizing:border-box;width:100%;border:1px solid #ceced0;transition:border-color .15s ease-in-out;padding:9px 8px 7px;margin-top:10px;min-height:130px;color:#5f5f61;font:inherit;border-radius:4px}.input-group_Rh628{text-align:left;margin:0 auto}.error_wj2zd{color:#e8564d;font-size:12px}button.button-link_uuwhn{padding:0}.info-message_BPZXa{display:inline-block}.modal-icon_t54zg{width:100%;max-width:130px;padding-top:10px;margin:0 auto;display:block}.modal-details_VH5iB{font-family:Gerstner-Programm,Helvetica,Segoe UI,\"sans-serif\";font-size:16px;font-weight:400;line-height:24px;letter-spacing:0;text-align:center}.modal-buttons_ZMsl_{margin-top:30px;display:flex;justify-content:center}.modal-buttons_ZMsl_ button{margin:0 10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bill-cancellation-modal": "bill-cancellation-modal_UAv2q",
	"title": "title_uXsto",
	"cancel-box-text": "cancel-box-text_CXvN_",
	"bill-pay-popup-container": "bill-pay-popup-container_cZjYr",
	"paybill-bottom-action-cancel": "paybill-bottom-action-cancel_jLuTA",
	"cancel-dropdown": "cancel-dropdown_EY9nB",
	"cancel-textarea": "cancel-textarea_WWZuj",
	"input-group": "input-group_Rh628",
	"error": "error_wj2zd",
	"button-link": "button-link_uuwhn",
	"info-message": "info-message_BPZXa",
	"modal-icon": "modal-icon_t54zg",
	"modal-details": "modal-details_VH5iB",
	"modal-buttons": "modal-buttons_ZMsl_"
};
export default ___CSS_LOADER_EXPORT___;
