// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-content-container_MQZjF{text-align:left;max-width:560px;margin:0 auto;color:#666}.modal-content-container_MQZjF p{margin-top:0;margin-bottom:1rem}.modal-content-container_MQZjF p:last-child{margin-bottom:0}.modal-content-container_MQZjF button.button-link__WGEU{padding:0 4px}.modal-content-container_MQZjF button.region-button_pQUXH{display:block;margin:0 auto}.modal-content-container_MQZjF .dropdown_PssUb{margin:1rem auto 2rem}@media only screen and (min-width:501px){.modal-content-container_MQZjF .region-selection-heading_mwZ3o{text-align:center}.modal-content-container_MQZjF .dropdown_PssUb{max-width:335px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-content-container": "modal-content-container_MQZjF",
	"button-link": "button-link__WGEU",
	"region-button": "region-button_pQUXH",
	"dropdown": "dropdown_PssUb",
	"region-selection-heading": "region-selection-heading_mwZ3o"
};
export default ___CSS_LOADER_EXPORT___;
