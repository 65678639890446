<template>
  <div>
    <span>{{ $t('vendorsPage.vendorInvoice.instructions1') }}</span>
    <span>{{ $t('vendorsPage.vendorInvoice.instructions2') }}</span>
    <!-- Drag and drop section -->
    <ui-fieldset>
      <!--
      What happens if we use v-if instead of v-show?
      The rendering condition depends on a computed value called `matchedUpload`,
      which when you remove the uploaded file becomes empty. That will cause the
      block below to unmount, removing the callback for the 'complete' event.
      When you upload a new file, a 'complete' event will be emitted that will try
      to call the old callback, failing to do so.
      -->
      <div v-show="!matchedUpload.length" class="uploadZone">
        <UploadFileWidget
          :empty-prompt="$t('vendorsPage.vendorInvoice.emptyPrompt')"
          single-upload="single-upload"
          accepted-file-types="application/pdf"
          :upload-filters="{
            entity: 'bank_account',
            type: 'vendor_invoice',
            metaId: selectedVendor.id,
          }"
          @complete="invoiceUploaded"
        />
      </div>
      <table v-if="matchedUpload.length">
        <tbody>
          <tr :class="$style['tr-header']">
            <th :class="$style['trash-column']">
              {{ $t('vendorsPage.vendorInvoice.deleteDoc') }}
            </th>
            <th :class="$style['document-column']">
              {{ $t('vendorsPage.vendorInvoice.docName') }}
            </th>
          </tr>
          <tr v-for="file in matchedUpload" :key="file.id">
            <td :class="$style['trash-column']">
              <UiButton
                text="X"
                small
                :class="$style.trashButton"
                @click="removeDocs(file.id)"
              />
            </td>
            <td :class="$style['document-column']">{{ file.name }}</td>
          </tr>
        </tbody>
      </table>
    </ui-fieldset>
    <div :class="$style.subtitle">
      {{ $t('vendorsPage.vendorInvoice.subtitle') }}
    </div>
    <UiButton
      :text="actionText"
      data-cy="finish-button"
      :disabled="request.isPending"
      @click="$emit('next-tab')"
    />
  </div>
</template>

<script>
import { validateChildren } from '@/composables/validation';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { UiFieldset, UiButton } from 'ui-components';

export default {
  components: {
    UiButton,
    UiFieldset,
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  computed: {
    ...mapGetters(['selectedVendor']),
    ...mapRequestStatuses({
      updateVendorRequestStatus: 'UPDATE_VENDOR_ACCOUNT',
    }),
    matchedUpload() {
      const uploads = this.$store.getters.uploadsMatching({
        entity: 'bank_account',
        type: 'vendor_invoice',
        metaId: `${this.selectedVendor.id}`,
      })[0];
      return uploads ? [uploads] : [];
    },
    request() {
      return this.updateVendorRequestStatus;
    },
    actionText() {
      if (this.request.isPending) {
        return this.$t('common.buttons.loading');
      }
      return this.matchedUpload.length
        ? this.$t('common.buttons.finish')
        : this.$t('common.buttons.skip');
    },
  },
  async mounted() {
    await this.$store.dispatchApiAction('FETCH_USER_UPLOADS');
  },
  methods: {
    async removeDocs(id) {
      await this.$store.dispatchApiAction('DELETE_USER_UPLOAD', { id });
    },
    async invoiceUploaded() {
      // Notify our API that the vendor information has been updated.
      // This will set the vendor approval status to 'PENDING'.
      await this.$store.dispatchApiAction(
        'UPDATE_VENDOR_ACCOUNT',
        this.selectedVendor,
      );
    },
  },
};
</script>
<style lang="less" module>
.uploadZone {
  height: 100% !important;
  width: 100% !important;
  margin: 20px;
  .upload-list {
    display: none !important;
  }
  .dropzone {
    height: 90% !important;
    width: 90% !important;
    vertical-align: bottom !important;
    margin: 5%;
    border: 1px dashed @color-fountain-blue !important;
    .dz-message {
      font-size: 20px !important;
      font-family: Montserrat, sans-serif;
      font-style: normal !important;
      color: @color-black;
      white-space: pre-wrap !important;
      margin: auto;
    }
  }
  .dropzone.dz-clickable {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.trashButton {
  width: 0.5em;
}
.trashButton > * {
  text-align: center;
}
.uploadSuccess {
  border: solid;
  border-color: @color-fountain-blue;
  border-radius: 5px;
  border-width: 2px;
  padding: 10px;
  margin-bottom: auto 20px;
  width: 100%;
}
.document-column {
  color: @color-black;
  width: 260px;
  font-weight: normal;
  @media only screen and (max-width: 960px) {
    width: 140px;
  }
  @media only screen and (max-width: 670px) {
    display: inline-block;
    width: max-content;
  }
}
.trash-column {
  width: 30px;
  @media only screen and (max-width: 960px) {
    width: 25px;
  }
  @media only screen and (max-width: 670px) {
    display: inline-block;
    width: max-content;
  }
}
.subtitle {
  color: gray;
  font-size: 12px;
  text-align: left;
  width: 60%;
  @media screen and (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
}
</style>
