// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_bMCiI{margin:20px auto 0}.container_bMCiI.load-all-platforms__OK58{margin-top:0}.connector_Kd4Au{max-width:800px;margin:auto;text-align:center}.support-link_muSx0{color:#f05758;text-decoration:underline}button.list-toggle_LnlnS{display:block;margin:30px auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_bMCiI",
	"load-all-platforms": "load-all-platforms__OK58",
	"connector": "connector_Kd4Au",
	"support-link": "support-link_muSx0",
	"list-toggle": "list-toggle_LnlnS"
};
export default ___CSS_LOADER_EXPORT___;
