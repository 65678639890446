import { OAUTH_PROVIDER_CONFIG } from '@/data/oauth-provider-config';
import store from '..';

const googleConfig = OAUTH_PROVIDER_CONFIG.google;

const googlePlugin = function () {
  async function installGoogleLibrary() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.onload = resolve;
      script.onerror = (event) => {
        reject(new Error(`Failed to load ${event?.target?.src}`));
      };
      script.setAttribute('src', googleConfig.apiUrl);
      document.head.appendChild(script);
    });
  }

  async function initLibrary(libraryName, config) {
    try {
      return window.gapi[libraryName].init(config).then(() => window.gapi);
    } catch (error) {
      throw new Error(
        `Failed to initialize ${libraryName} library due to error - ${error?.message}`,
      );
    }
  }

  async function loadAndInitLibrary(libraryName, config) {
    return new Promise((resolve, reject) => {
      if (window.gapi) {
        window.gapi.load(libraryName, {
          callback() {
            resolve(initLibrary(libraryName, config));
          },
          onerror(error) {
            reject(
              new Error(
                `${libraryName} failed to load due to error - ${error?.message}`,
              ),
            );
          },
          timeout: 5000, // 5 seconds
          ontimeout() {
            reject(
              new Error(`${libraryName} took too long to load and timed out!`),
            );
          },
        });
      } else {
        reject();
      }
    });
  }

  class Plugin {
    constructor() {
      // Install Google library, initialize auth2 client and the
      // Google People client (required to get the users phone number)
      this.load = async (config) => {
        await installGoogleLibrary();
        setTimeout(async () => {
          this.googleAuth2 = (
            await loadAndInitLibrary('auth2', config).catch(() => {
              store.commit('SET_IS_GOOGLE_AUTH_FAILED', true);
            })
          )?.auth2;
          this.googleClientInstance = (
            await loadAndInitLibrary('client', {
              apiKey: process.env.GOOGLE_PEOPLE_API_KEY,
              discoveryDocs: [googleConfig.peopleApiDiscoveryDocs],
            })
          ).client;
          if (this.googleAuth2 && this.googleClientInstance) {
            store.commit('SET_IS_GOOGLE_LIBRARY_LOADED', true);
          }
        }, 100);
      };

      // Make request to Google People API to get phone number
      this.getPhoneNumber = async () => {
        if (!this.googleClientInstance) {
          throw new Error(
            'We were unable to successfully sign into your Google account. Please refresh the page and try again or sign up natively using the form below.',
          );
        }

        try {
          return await this.googleClientInstance.people.people.get({
            resourceName: 'people/me',
            'requestMask.includeField': 'person.phoneNumbers',
          });
        } catch (error) {
          throw new Error(
            'We were unable to capture your phone number. Please refresh the page and try again or sign up natively using the form below.',
          );
        }
      };

      // Use auth2 client to sign the user in
      this.signIn = async (options) => {
        if (!this.googleAuth2) {
          throw new Error(
            'Google Auth library was unable to load. Please refresh the page and try again or sign up natively by filling out the form below.',
          );
        }

        try {
          const googleUser = await this.googleAuth2
            .getAuthInstance()
            .signIn(options);
          this.isAuthorized = this.googleAuth2
            .getAuthInstance()
            .isSignedIn.get();
          this.isSignedIn = true;
          return googleUser;
        } catch (error) {
          this.isSignedIn = false;
          if (error.error !== 'popup_closed_by_user') {
            throw new Error(
              `Google sign-in failed due to: ${error?.error}. Please ensure that you are allowing the requested permissions for your Google account.`,
            );
          }
          return null;
        }
      };
    }
  }
  return new Plugin();
};

const authOptions = {
  clientId: process.env.GOOGLE_CLIENT_ID_FOR_FEDERATED_LOGIN,
  scope: googleConfig.scope,
  prompt: 'select_account',
  ux_mode: 'popup',
  apiKey: process.env.GOOGLE_PEOPLE_API_KEY,
  discoveryDocs: [googleConfig.peopleApiDiscoveryDocs],
};

export { authOptions, googlePlugin };
