<template>
  <div :class="$style.dashboard">
    <div :class="$style.header">
      <h1 :class="$style.title">Business Profile</h1>
      <p :class="$style.description">
        {{ subheading }}
      </p>
      <p :class="$style.steps">
        {{ numCompletedSteps }}/{{ numberOfSteps }} Steps Complete
      </p>
    </div>
    <div :class="$style.body">
      <InfoBoxPersona />
      <div>
        <ApplicationStep
          v-for="(step, index) in Object.values(businessProfileSteps)"
          :key="step.name"
          :title="step.label"
          :is-disabled="step.isDisabled"
          :state="getStepState(step)"
          :destination="getStepDestination(step)"
          :step-count="index + 1"
          :action-required="getActionRequired(step)"
        />
        <DSButton
          v-if="isBusinessProfileComplete"
          :class="$style.cta"
          label="Finish"
          @click="handleCtaClick()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApplicationStep from '@/components/ApplicationStep';
import { InfoBoxPersona } from '@/components';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import DSButton from '@clearbanc/clear-components/button';

export default {
  components: {
    ApplicationStep,
    DSButton,
    InfoBoxPersona,
  },
  data() {
    return {
      isLoading: false,
      isSubmitted: false,
    };
  },
  async mounted() {
    await this.$store.dispatchApiAction('GET_FISCAL_DOCUMENT_CHECKS', {
      businessId: this.businessId,
    });
  },
  computed: {
    ...mapGetters([
      'businessProfileSteps',
      'isPersonaEnabled',
      'hasSignatory',
      'allOwnersCompleted',
      'isBusinessDetailsStepComplete',
      'primaryBankAccount',
      'currentBankAccountToBePrimary',
      'isPrimaryBankAccountDisconnected',
      'fiscalDocumentsUpToDate',
      'bankAccounts',
      'businessId',
      'isInFinancialDocsSegment',
      'isUploadBankStatementsStepComplete',
      'businessOnPlaidBypass',
      'debtObligationsRequired',
      'isBusinessProfileComplete',
      'fiscalStartDate',
    ]),
    numCompletedSteps() {
      return Object.keys(this.businessProfileSteps).filter(
        (step) => this.businessProfileSteps[step].isComplete,
      ).length;
    },
    numberOfSteps() {
      return Object.keys(this.businessProfileSteps).length;
    },
    subheading() {
      return this.numCompletedSteps < 4
        ? 'For access to funding, please complete your business profile.'
        : 'Your business profile is complete. Access or edit your information at anytime through the sections below.';
    },
  },
  methods: {
    getStepState(step) {
      // Non - persona steps:
      if (
        step.name === PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT &&
        this.userHasActiveBillsAdvanceAndDisconnectedSalesAccount &&
        !this.isPersonaEnabled
      ) {
        return 'warning';
      }

      // Persona steps:
      if (step.isDisabled && this.isPersonaEnabled) {
        return 'incomplete';
      }
      if (
        step.name === PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS &&
        this.isBusinessDetailsStepComplete &&
        (!this.hasSignatory || !this.allOwnersCompleted) &&
        this.isPersonaEnabled
      ) {
        return 'warning';
      }
      // Common persona and non-persona steps
      if (step.name === PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING) {
        if (!this.bankAccounts.length) {
          return 'incomplete';
        } else if (
          (this.isPrimaryBankAccountDisconnected &&
            !this.businessOnPlaidBypass) ||
          (this.businessOnPlaidBypass &&
            !this.isUploadBankStatementsStepComplete)
        ) {
          return 'warning';
        }
      }

      return step.isComplete ? 'complete' : 'incomplete';
    },
    getActionRequired(step) {
      if (step.isDisabled) {
        return false;
      }

      if (step.name === PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING) {
        if (!this.bankAccounts.length) {
          return false;
        }

        if (
          this.isPrimaryBankAccountDisconnected &&
          !this.businessOnPlaidBypass
        ) {
          return true;
        }

        if (
          this.businessOnPlaidBypass &&
          !this.isUploadBankStatementsStepComplete
        )
          return true;
      }

      if (step.name === PAYMENTS_ROUTE_NAMES.PROFILE_FINANCIAL_DETAILS) {
        if (
          this.isInFinancialDocsSegment &&
          !this.fiscalDocumentsUpToDate &&
          !this.fiscalStartDate
        ) {
          return true;
        }

        if (this.debtObligationsRequired) {
          return true;
        }
      }

      return false;
    },
    getStepDestination(step) {
      if (
        step.name === PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS &&
        this.isPersonaEnabled
      ) {
        return PAYMENTS_ROUTE_NAMES.PERSONA_PROFILE_OWNERS;
      }

      if (step.name === PAYMENTS_ROUTE_NAMES.PROFILE_FINANCIAL_DETAILS) {
        if (
          this.isInFinancialDocsSegment &&
          (!this.fiscalDocumentsUpToDate || step.isComplete)
        ) {
          return PAYMENTS_ROUTE_NAMES.PROFILE_BANK_DOCUMENTS;
        }

        return PAYMENTS_ROUTE_NAMES.PROFILE_DEBT_OBLIGATIONS;
      }

      return step.name;
    },
    handleCtaClick() {
      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE,
      });
    },
  },
};
</script>

<style lang="less" module>
.dashboard {
  font-family: @gerstner-font;

  .header {
    text-align: left;

    @media only screen and (min-width: 960px) {
      text-align: center;
    }
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
    margin: 1.125rem 0 1.5rem;
    color: #000000;

    @media only screen and (min-width: 960px) {
      margin: 1.875rem 0 1.5rem;
      font-size: 26px;
      line-height: 1.23;
    }
  }

  .description {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    color: #393939;
  }

  .steps {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 3rem;
    color: #393939;

    @media only screen and (min-width: 960px) {
      margin-bottom: 5rem;
    }
  }

  .body {
    display: grid;
    row-gap: 3rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    .cta {
      width: 100%;
    }

    @media only screen and (min-width: 960px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 1.5625rem;
    }
  }
}
</style>
