<template>
  <g>
    <path
      d="M20.1458 9.34909C20.1458 9.56986 20.0581 9.78159 19.902 9.9377C19.7459 10.0938 19.5342 10.1815 19.3134 10.1815H1.83243C1.61165 10.1815 1.39992 10.0938 1.24381 9.9377C1.0877 9.78159 1 9.56986 1 9.34909V7.68423C1 7.46346 1.0877 7.25173 1.24381 7.09562C1.39992 6.93951 1.61165 6.85181 1.83243 6.85181H19.3134C19.5342 6.85181 19.7459 6.93951 19.902 7.09562C20.0581 7.25173 20.1458 7.46346 20.1458 7.68423V9.34909Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.481 19.3381C18.481 19.5589 18.3933 19.7706 18.2372 19.9267C18.0811 20.0828 17.8694 20.1705 17.6486 20.1705H3.49734C3.27657 20.1705 3.06484 20.0828 2.90873 19.9267C2.75262 19.7706 2.66492 19.5589 2.66492 19.3381V10.1814H18.481V19.3381Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.65942 6.85181V20.1706"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.4865 20.1706V6.85181"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.0905 3.80502C14.4546 4.44183 11.4054 5.18685 11.4054 5.18685C11.4054 5.18685 12.1546 2.13767 12.7872 1.5017C12.9373 1.34518 13.1171 1.22019 13.316 1.13406C13.515 1.04792 13.7292 1.00238 13.946 1.00009C14.1628 0.997803 14.3779 1.03882 14.5787 1.12074C14.7794 1.20265 14.9618 1.32382 15.1151 1.47714C15.2684 1.63046 15.3896 1.81284 15.4715 2.01359C15.5534 2.21435 15.5944 2.42943 15.5922 2.64624C15.5899 2.86306 15.5443 3.07723 15.4582 3.27621C15.3721 3.47519 15.2471 3.65497 15.0905 3.80502V3.80502Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.05527 3.805C6.69124 4.44181 9.74042 5.18683 9.74042 5.18683C9.74042 5.18683 8.99124 2.13765 8.35859 1.50168C8.051 1.2068 7.64012 1.04418 7.21405 1.04867C6.78797 1.05317 6.38061 1.22443 6.07931 1.52572C5.77801 1.82702 5.60676 2.23438 5.60226 2.66046C5.59777 3.08654 5.76039 3.49742 6.05527 3.805Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>
