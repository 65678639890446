// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body .footer_Mrajb button.footer__button_alvHf{margin:0;width:calc(50% - 8px)}body .footer_Mrajb button.footer__button_alvHf+button.footer__button_alvHf{margin-left:16px}.modal-content_t2lAA p{margin:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer_Mrajb",
	"footer__button": "footer__button_alvHf",
	"modal-content": "modal-content_t2lAA"
};
export default ___CSS_LOADER_EXPORT___;
