<template>
  <Modal
    v-model:visible="showModal"
    :header="$t('components.modalAppstore.connectYourAppstoreAccount')"
    :modal="true"
    :draggable="false"
    append-to="self"
    @show="$emit('modalAction', SALES_PLATFORMS.APPSTORE, 'open')"
    @hide="$emit('modalAction', SALES_PLATFORMS.APPSTORE, 'close')"
  >
    <div :class="$style.container">
      <ol :class="$style.list" :start="listStartValue">
        <template v-if="!isMobile() || (isMobile() && !showSecondPage)">
          <li>
            <p>
              <i18n-t
                keypath="components.modalAppstore.loginToYourAppstoreAccount"
              >
                <template #appstore>
                  <a
                    :class="$style.link"
                    target="_blank"
                    href="https://appstoreconnect.apple.com/"
                    >{{ $t('components.modalAppstore.appstore') }}</a
                  >
                </template>
              </i18n-t>
            </p>
          </li>
          <li>
            <p>
              <i18n-t keypath="components.modalAppstore.clickKeys">
                <template #keys>
                  <strong>{{ $t('components.modalAppstore.keys') }}</strong>
                </template>
                <template #generateApiKey>
                  <strong>{{
                    $t('components.modalAppstore.generateApiKey')
                  }}</strong>
                </template>
              </i18n-t>
            </p>
            <p>
              <i18n-t keypath="components.modalAppstore.nameReportAccess">
                <template #reportAccess>
                  <strong>{{
                    $t('components.modalAppstore.reportAccess')
                  }}</strong>
                </template>
                <template #reportAccessCopyText>
                  <DSButton
                    :label="reportAccessCopyText"
                    class="p-button-link"
                    :class="$style['button-link']"
                    @click="copy('report')"
                  />
                </template>
              </i18n-t>
            </p>
            <p>
              <i18n-t keypath="components.modalAppstore.accessFinances">
                <template #finance>
                  <strong>{{ $t('components.modalAppstore.finance') }}</strong>
                </template>
                <template #financeCopyText>
                  <DSButton
                    :label="financeCopyText"
                    class="p-button-link"
                    :class="$style['button-link']"
                    @click="copy('finance')"
                  />
                </template>
              </i18n-t>
            </p>
          </li>
          <li>
            <p>
              <i18n-t
                keypath="components.modalAppstore.clickGenerateAndEnterKeyId"
              >
                <template #keyId>
                  <strong>{{ $t('components.modalAppstore.keyId') }}</strong>
                </template>
              </i18n-t>
            </p>
            <input-with-eyebrow
              v-model="appstoreAuth.keyId"
              :class="$style['input-fields']"
              :placeholder="$t('components.modalAppstore.keyIdCapitalized')"
              required
              name="Key ID"
              :validate-on-blur="validateOnBlur"
            />
          </li>
          <li>
            <p>
              <i18n-t
                keypath="components.modalAppstore.downloadTheGeneratedKeyFile"
              >
                <template #keyFile>
                  <strong>{{ $t('components.modalAppstore.keyFile') }}</strong>
                </template>
              </i18n-t>
            </p>
            <UploadFileWidget
              :upload-filters="{ entity: 'appstore_keyfile' }"
              :display-filters="
                isDiligenceServiceReadDataEnabled
                  ? { documentType: 'appstore_keyfile' }
                  : { entity: 'appstore_keyfile' }
              "
              process-locally
              :empty-prompt="
                $t('common.messagePrompt.dragAndDropFilesOrBrowse')
              "
              accepted-file-types=".p8"
              add-remove-links
              single-upload
              :class="$style['upload-widget']"
              theme="onboarding"
              @complete="storeKeyFile"
            />
            <p
              v-if="showErrorState && !appstoreAuth.keyFile"
              :class="$style['required-upload']"
            >
              {{ $t('common.required') }}
            </p>
          </li>
        </template>
        <template v-if="!isMobile() || (isMobile() && showSecondPage)">
          <li>
            <p>
              <i18n-t keypath="components.modalAppstore.findIssuerId">
                <template #issuerId>
                  <strong>{{ $t('components.modalAppstore.issuerId') }}</strong>
                </template>
              </i18n-t>
            </p>
            <input-with-eyebrow
              v-model="appstoreAuth.issuerId"
              :class="$style['input-fields']"
              :placeholder="$t('components.modalAppstore.issuerIdCapitalized')"
              required
              name="Issuer ID"
              :validate-on-blur="validateOnBlur"
            />
          </li>
          <li>
            <p>
              <i18n-t keypath="components.modalAppstore.goToMainDashboard">
                <template #mainDashboard>
                  <strong>{{
                    $t('components.modalAppstore.mainDashboard')
                  }}</strong>
                </template>
                <template #paymentsAndFinancialReports>
                  <strong>{{
                    $t('components.modalAppstore.paymentsAndFinancialReports')
                  }}</strong>
                </template>
                <template #vendorId>
                  <strong>{{ $t('components.modalAppstore.vendorId') }}</strong>
                </template>
              </i18n-t>
            </p>
            <input-with-eyebrow
              v-model="appstoreAuth.vendorId"
              :class="$style['input-fields']"
              :placeholder="$t('components.modalAppstore.vendorIdCapitalized')"
              required
              remove-special-characters
              name="Vendor ID"
              :validate-on-blur="validateOnBlur"
            />
          </li>
        </template>
      </ol>
      <Message v-if="error" severity="error" @close="error = false">
        <p>{{ $t('components.modalAppstore.somethingWentWrong') }}</p>
        <p>
          {{ $t('components.modalAppstore.ifThisIssuePersists') }}
          <a href="mailto:support@clear.co" target="_blank">support@clear.co</a>
        </p>
      </Message>
      <DSButton
        v-if="isMobile() && !showSecondPage"
        :label="$t('common.buttons.next')"
        class="p-button-secondary"
        :class="$style.button"
        @click="validateFirstPage"
      />
      <DSButton
        v-if="!isMobile() || (isMobile() && showSecondPage)"
        :label="$t('components.modalAppstore.connectAppstore')"
        class="p-button-secondary"
        :class="$style.button"
        @click="continueAppstoreIntegration"
      />
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { isMobileMixin, copyTextMixin } from '@/utils/vue-mixins';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { SALES_PLATFORMS } from '@/data/platforms';
import stateModuleAware from '@/mixins/state-module-aware';
import { validateChildren } from '@/composables/validation';
import InputWithEyebrow from '@/components/inputs/InputWithEyebrow';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import Modal from '@clearbanc/clear-components/dialog';

export default {
  components: {
    InputWithEyebrow,
    DSButton,
    Message,
    Modal,
  },
  mixins: [isMobileMixin, copyTextMixin, stateModuleAware],
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      error: false,
      appstoreAuth: {
        keyId: '',
        vendorId: '',
        issuerId: '',
        keyFile: '',
      },
      reportAccessCopyText: this.$t('components.modalAppstore.copy'),
      financeCopyText: this.$t('components.modalAppstore.copy'),
      showSecondPage: false,
      showErrorState: false,
      validateOnBlur: false,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(['isDiligenceServiceReadDataEnabled']),
    ...mapRequestStatuses({
      addExternalAccountsRequest: 'ADD_USER_EXTERNAL_ACCOUNT',
    }),
    SALES_PLATFORMS: () => SALES_PLATFORMS,
    listStartValue() {
      if (!this.isMobile() || (this.isMobile() && !this.showSecondPage)) {
        return 1;
      }
      return 5;
    },
  },
  methods: {
    open() {
      this.error = false;
      this.appstoreAuth.keyId = '';
      this.appstoreAuth.vendorId = '';
      this.appstoreAuth.issuerId = '';
      this.appstoreAuth.keyFile = '';
      this.showSecondPage = false;
      this.showErrorState = false;
      this.showModal = true;
    },
    storeKeyFile(file) {
      this.appstoreAuth.keyFile = file;
    },
    async continueAppstoreIntegration() {
      this.validateOnBlur = true;
      this.showErrorState = true;
      const error = await this.hasError();
      if (error || !this.appstoreAuth.keyFile) {
        this.$emit('error', SALES_PLATFORMS.APPSTORE);
        return;
      }
      await this.$store.dispatchApiAction('ADD_USER_EXTERNAL_ACCOUNT', {
        type: SALES_PLATFORMS.APPSTORE,
        appstoreAuth: this.appstoreAuth,
      });
      if (this.addExternalAccountsRequest.isSuccess) {
        this.$emit('success');
        this.error = false;
        this.dispatchToStateModule('UPDATE_STEP', {
          step: this.$route.name,
        });
        this.showModal = false;
      } else {
        this.error = true;
        const translatedError = this.addExternalAccountsRequest.error.errorCode
          ? this.$t(
              `backendError.${this.addExternalAccountsRequest.error.errorCode}`,
              this.addExternalAccountsRequest.error.details,
            )
          : this.addExternalAccountsRequest.errorMessage;
        this.$emit('oauthError', {
          provider: SALES_PLATFORMS.APPSTORE,
          error: translatedError,
        });
      }
    },
    async copy(type) {
      const variableToUpdate =
        type === 'report' ? 'reportAccessCopyText' : 'financeCopyText';
      const textToCopy = type === 'report' ? 'Report Access' : 'Finance';
      // use the copyText mixin
      const result = await this.copyText(textToCopy);
      if (result.success) {
        this[variableToUpdate] = this.$t('components.modalAppstore.copied');
        window.setTimeout(() => {
          this[variableToUpdate] = this.$t('components.modalAppstore.copy');
        }, 800);
      }
    },
    async validateFirstPage() {
      this.showErrorState = true;
      const error = await this.hasError();
      if (error || !this.appstoreAuth.keyFile) {
        this.$emit('error', SALES_PLATFORMS.APPSTORE);
        return;
      }
      this.showSecondPage = true;
    },
  },
};
</script>

<style lang="less" module>
.container {
  text-align: left;
  max-width: 560px;
  margin: 0 auto;

  .list {
    list-style-type: decimal;
    padding-left: 1rem;

    li ~ li {
      margin-top: 1rem;
    }
  }

  .button {
    display: block;
    margin-top: 1.5rem;
  }

  button.button-link {
    padding: 0 4px;
  }

  .link {
    text-decoration: underline;
    color: @link-blue;
    cursor: pointer;
  }

  .input-fields {
    max-width: 400px;
    margin-top: 1.5rem;
  }

  .upload-widget {
    max-width: 400px;
    margin-bottom: 10px;
  }

  .required-upload {
    color: @color-red;
    font-size: 12px;
    margin-left: 2px;
    line-height: 12px;
    margin-top: -5px;
  }
}
</style>
