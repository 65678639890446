// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_vvnfA{margin:0 auto}.container_vvnfA h3{font-size:17px}.container_vvnfA .description_vfpbi{margin:0}.container_vvnfA .message_wbWu6{margin:0 0 2rem 0}.form_CeIBf{margin-top:2rem}.form_CeIBf:first-child{margin-top:0}@media only screen and (min-width:768px){.form_CeIBf{width:685px}}.divider_gzbAw{border-top:1px dotted rgba(0,0,0,.2);margin-top:1rem;padding-top:1rem}.spinner-container_EmjAh>div{margin:150px auto!important}.wire-checkbox_Pg5W6{margin-top:2rem;display:flex;align-items:center;justify-content:flex-end}.wire-checkbox_Pg5W6 .label_WPd9Q{margin-left:12px;line-height:1}.link_I12fV{text-align:center}.button-container_Q8Rqi{display:flex;justify-content:flex-end;align-items:center;margin-top:25px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_vvnfA",
	"description": "description_vfpbi",
	"message": "message_wbWu6",
	"form": "form_CeIBf",
	"divider": "divider_gzbAw",
	"spinner-container": "spinner-container_EmjAh",
	"wire-checkbox": "wire-checkbox_Pg5W6",
	"label": "label_WPd9Q",
	"link": "link_I12fV",
	"button-container": "button-container_Q8Rqi"
};
export default ___CSS_LOADER_EXPORT___;
