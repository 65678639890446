<template>
  <div
    :class="buttonClasses"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  >
    <div :class="$style.logo" v-html="googleLogoSVG" />
    <div :class="$style.text">
      {{ text }}
    </div>
  </div>
</template>
<script>
import { inject } from 'vue';

export default {
  emits: ['click'],
  props: {
    text: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('common.buttons.loginWithGoogle');
      },
    },
    round: { type: Boolean, default: false },
  },
  computed: {
    buttonClasses() {
      return {
        [this.$style['btn-container']]: true,
        [this.$style['rounded-border']]: this.round,
      };
    },
    googleLogoSVG() {
      return require('@/assets/logos/google-logo.svg');
    },
  },
};
</script>
<style lang="less" module>
.btn-container {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 100%;
  align-items: center;
  border: 1px solid;
  cursor: pointer;
  &.rounded-border {
    border-radius: 8px;
  }
  .logo {
    width: 18px;
    margin-right: 8px;
    svg {
      display: block;
    }
  }
  .text {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    user-select: none;
  }
}
</style>
