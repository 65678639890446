// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_UxPlp{padding:0;margin:0 auto;min-height:unset}.container_UxPlp h2,.container_UxPlp h3,.container_UxPlp h4{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif}@media only screen and (max-width:500px){.container_UxPlp{text-align:left}}.timeline_PJG47 .timeline__header--sm_s7_21{font-weight:300;font-size:14px;line-height:20px;color:#767676;margin:0 0 4px 0}.timeline_PJG47 .timeline__header--b_a_V36,.timeline_PJG47 .timeline__header--sm_s7_21{font-family:Gerstner-Programm,Helvetica,Segoe UI,\"sans-serif\";text-align:center}.timeline_PJG47 .timeline__header--b_a_V36{font-weight:500;font-size:16px;line-height:24px;color:#111;margin:0 0 23px 0}.timeline_PJG47 .timeline__cancel_B34rd{text-align:center}.timeline_PJG47 .timeline__cancel_B34rd a{font-family:Gerstner-Programm,Helvetica,Segoe UI,\"sans-serif\";font-size:16px;font-weight:300;cursor:pointer;color:#215cb6}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_UxPlp",
	"timeline": "timeline_PJG47",
	"timeline__header--sm": "timeline__header--sm_s7_21",
	"timeline__header--b": "timeline__header--b_a_V36",
	"timeline__cancel": "timeline__cancel_B34rd"
};
export default ___CSS_LOADER_EXPORT___;
