// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttons-container_tFGEx{display:flex;align-items:center;justify-content:center}.next-btn_WFOQD button{background-color:#7f30ff}.next-btn_WFOQD button:hover{background-color:#7f30ff!important}h2.heading_NvpFC{font-size:21px;line-height:30px;font-weight:500;margin:0 0 24px}.sub-heading_v00S7{margin:0 auto 20px;max-width:550px}@media screen and (max-width:500px){.sub-heading_v00S7,h2.heading_NvpFC{text-align:left}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons-container": "buttons-container_tFGEx",
	"next-btn": "next-btn_WFOQD",
	"heading": "heading_NvpFC",
	"sub-heading": "sub-heading_v00S7"
};
export default ___CSS_LOADER_EXPORT___;
