/* eslint-disable no-param-reassign */
import Axios from 'axios';

import camelizeKeysDeep from 'camelcase-keys-deep';
import { auth0 } from '@/utils/auth0';

export const creditsRequest = Axios.create({
  timeout: process.env.API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

creditsRequest.interceptors.request.use(async (config) => {
  config.url = `${process.env.CHARGE_CARD_API_URL}${config.url}`;
  const token = await auth0.getAccessTokenSilently();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
creditsRequest.interceptors.response.use((response) => {
  if (
    response.config.url.match(/.*\/v[12]\/.*/) &&
    response.headers['content-type'].indexOf('application/json') === 0
  ) {
    response.data = camelizeKeysDeep(response.data);
  }
  return response;
});
