const _ = require('lodash');

module.exports = {
  data() {
    return { page: null, filters: this.getFilterValuesFromUrl() };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.updateFiltersInUrl();
      },
    },
  },
  methods: {
    updateFiltersInUrl() {
      // reset page to 1 when a filter param (other than page) changes
      this.filters.page !== this.page
        ? (this.page = this.filters.page)
        : (this.filters.page = 1);

      // keep new filter values in sync in the URL
      this.$router
        .replace({
          query: _.pickBy(
            this.filters,
            (val) => val !== null && val !== undefined,
          ),
        })
        .catch(() => {});
    },
    getFilterValuesFromUrl() {
      // set initial filter values from query params based on `filterKeys` option
      if (!this.$options.trackFilters) return {};
      const filters = {};
      if (_.isArray(this.$options.trackFilters.keys)) {
        _.each(this.$options.trackFilters.keys, (k) => {
          filters[k] = this.$route.query[k];
        });
      } else {
        _.each(this.$options.trackFilters.keys, (type, k) => {
          let val = this.$route.query[k];
          if (!val) return;
          if (type === Number) {
            val = parseFloat(val);
            /* eslint-disable-next-line no-restricted-globals */
            if (isNaN(val)) return;
          } else if (type === Boolean) {
            if (val === 'true') {
              val = true;
            } else if (val === 'false') {
              val = false;
            }
          }
          // TODO: add more here...
          filters[k] = val;
        });
      }
      return filters;
    },
  },
};
