export const MILESTONE_STATUSES = {
  COMPLETE: 'complete',
  ACTION_REQUIRED_VENDOR_DETAILS: 'action-required-vendor-details',
  ACTION_REQUIRED_BIZ_PROFILE: 'action-required-biz-profile',
  ACTION_REQUIRED_BANK_DOCUMENTS: 'action-requied-bank-documents',
  ACTION_REQUIRED_SIGN_CONTRACT: 'action-required-sign-contract',
  ACTION_REQUIRED_VERIFY_REVENUE: 'action-required-verify-revenue',
  ACTION_REQUIRED_INSUFFICIENT_CAPACITY:
    'action-required-insufficient-capacity',
  FAILED: 'failed',
  PROCESSING: 'processing',
  NOT_STARTED: 'not-started',
};

export const MILESTONES = {
  INVOICE_SUBMITTED: 'invoice-submitted',
  DILIGENCE: 'diligence',
  SIGN_CONTRACT: 'sign-contract',
  PAID: 'paid',
  CANCELLED: 'cancelled',
};
