<template>
  <div class="invoice-receipt-container">
    <div>
      <div class="card" @click="nextStep('invoice')">
        <div class="left-side">
          <span data-cy="invoice-title" class="title">
            {{ $t('account.pages.pageVendors.invoiceOrReceipt.invoice') }}
          </span>
          <span data-cy="invoice-description">
            {{
              $t('account.pages.pageVendors.invoiceOrReceipt.invoiceSubtitle')
            }}
          </span>
        </div>
        <div class="right-side">
          <i class="pi pi-chevron-right icon"></i>
        </div>
      </div>
      <div class="card" @click="nextStep('receipt')">
        <div class="left-side">
          <span data-cy="receipt-title" class="title">
            {{ $t('account.pages.pageVendors.invoiceOrReceipt.receipt') }}
          </span>
          <span data-cy="receipt-description">
            {{
              $t('account.pages.pageVendors.invoiceOrReceipt.receiptSubtitle')
            }}
          </span>
        </div>
        <div class="right-side">
          <i class="pi pi-chevron-right icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import analytics from '@/utils/analytics';
import { CLEARPAY_SEGMENT_EVENTS } from '@/data/payments';

export default {
  mounted() {
    analytics.track(CLEARPAY_SEGMENT_EVENTS.VIEWED_SUBMISSION_TYPE_MODAL);
  },
  methods: {
    async nextStep(step) {
      analytics.track(CLEARPAY_SEGMENT_EVENTS.CLICKED_INVOICE_OR_RECEIPT, {
        type: step,
      });
      await this.$store.dispatch('IS_RECEIPT_FLOW', step === 'receipt');
      this.$emit('amount', 0);
      this.$emit('next-tab');
    },
  },
};
</script>

<style lang="less">
.invoice-receipt-container {
  .card {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 15px 25px;
    color: @color-grey-500;
    font-weight: 100;
    font-family: @gerstner-font;
    box-shadow: 0 3px 6px 0 @color-grey-200;
    border-radius: 4px;
    max-width: 465px;
    height: 140px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 4px 8px @color-grey-300;
    }

    & ~ .card {
      margin-top: 20px;
    }

    .left-side {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: left;
      width: 90%;

      .title {
        font-size: 20px;
        font-weight: bold;
        color: @black;
        font-family: @gerstner-font;
      }
    }

    .right-side {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 10%;

      i {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
