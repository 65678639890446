export const COUNTRY_CODES = {
  AT: 'AT',
  AU: 'AU',
  BE: 'BE',
  CA: 'CA',
  DE: 'DE',
  FI: 'FI',
  GB: 'GB',
  IE: 'IE',
  NL: 'NL',
  SG: 'SG',
  US: 'US',
  DK: 'DK',
  SE: 'SE',
  CH: 'CH',
};

export const SUPPORTED_SELF_SERVE_COUNTRY_CODES = [
  COUNTRY_CODES.CA, // Canada
  COUNTRY_CODES.US, // United States
  COUNTRY_CODES.GB, // United Kingdom
  COUNTRY_CODES.AU, // Australia
  COUNTRY_CODES.IE, // Ireland
  COUNTRY_CODES.FI, // Finland
  COUNTRY_CODES.BE, // Belgium
  COUNTRY_CODES.DE, // Germany
];
