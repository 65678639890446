<template>
  <svg viewBox="0 0 426.53 426.83" xml:space="preserve">
    <path
      d="M64.11,277.45c-21.51,0-42.17,0-64.11,0c35.85,50.18,71.17,99.62,106.71,149.38c35.59-49.81,70.82-99.11,106.62-149.22
		c-21.72,0-42.57,0-63.93,0c0-2.19,0-3.81,0-5.43c0-33.17-0.03-66.34,0.01-99.52c0.05-49.65,37.68-87.21,87.44-87.24
		c61.19-0.04,122.38-0.01,183.58-0.01c1.96,0,3.91,0,6.11,0c0-28.58,0-56.65,0-85.04c-1.38-0.09-2.5-0.23-3.63-0.23
		c-64.86,0.04-129.73-0.52-194.58,0.3C153.28,1.41,83.97,58.96,68.61,132.39c-2.81,13.43-4.05,27.38-4.31,41.12
		c-0.61,32.49-0.2,65.01-0.2,97.51C64.11,272.98,64.11,274.95,64.11,277.45z"
    ></path>
  </svg>
</template>
