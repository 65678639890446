<template>
  <experiments-button
    :class="classes"
    :disabled="disabled"
    :data-cy="kebabCase(text + ' button')"
    :tabindex="0"
    @click="handleClick"
    @keyup.enter="handleClick"
  >
    <icon v-if="loading" name="spinner" scale="1" :class="$style.loader" />
    {{ text }}
  </experiments-button>
</template>

<script>
import { inject } from 'vue';
import { ExperimentsButton } from '@/components';
import kebabCase from '@/utils/kebabcase';

export default {
  emits: ['click'],
  components: {
    ExperimentsButton,
  },
  props: {
    disabled: { type: Boolean, default: false },
    text: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('common.buttons.nextStep');
      },
    },
    stickToTop: { type: Boolean, default: false },
    theme: { type: String },
    loading: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      return {
        [this.$style.rounded]: true,
        [this.$style.focused]: this.stickToTop,
        [this.$style.white]: this?.theme === 'white',
        [this.$style['modals-theme']]: this?.theme === 'modals',
        [this.$style.transparent]: this?.theme === 'transparent',
        [this.$style.grey]: this?.theme === 'grey',
        [this.$style.purple]: this.theme === 'purple',
      };
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
    kebabCase,
  },
};
</script>

<style lang="less" module>
.rounded {
  border-radius: 4px;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: intrinsic;
  font-size: 16px;
  min-width: 186px;

  &.purple {
    background-color: @color-purple-2022;
    &:hover {
      background-color: @color-purple-2022 !important;
    }
  }

  &.white {
    background: @white;
    color: @onboarding-alt-black;
    line-height: 24px;
    font-weight: normal;
    padding: 0 20px;
    &:hover:not(.disabled) {
      background: @white;
    }
  }

  &.transparent {
    background: none;
    color: @onboarding-alt-black;
    line-height: 24px;
    padding: 0 20px;
    &:hover:not(.disabled) {
      background: none;
    }

    border: 1px solid @dark-black;
  }

  &.modals-theme {
    letter-spacing: 0.1px;
    width: 170px;
    padding: unset;
  }

  // push cta to top of page when an input field is focused
  &.focused {
    display: none;
    @media only screen and (max-width: 500px) {
      display: unset;
      position: fixed;
      top: 7px;
      z-index: 7;
      right: 7px;
      height: 40px;
    }
  }

  &.grey {
    background: @color-grey-100;
    color: black;
    font-weight: 600;
    width: fit-content;
    min-width: 0;
    box-shadow: none;
    &:hover:not(.disabled) {
      background: @color-grey-200;
    }
  }

  &:hover:not([class*='disabled']) {
    background: @color-black;
  }

  @media only screen and (max-width: 500px) {
    float: right;
    margin: unset;
    min-width: unset;
  }

  .loader {
    margin-right: 5px;
  }
}
</style>
