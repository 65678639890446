<template>
  <div>
    <div :class="this.$style.container">
      <header v-if="title" :class="this.$style.title">{{ title }}</header>
      <slot name="wayfinding-upper" />
      <router-view />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="less" module>
.container {
  font-family: 'Montserrat';
  padding: 0 20px 30px;
  background: @color-white;
  border: 1px solid @color-lightgray-300;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 700px;
  flex: 1 0 0;
  margin: 24px auto auto;

  @media only screen and (max-width: 768px) {
    margin: 0 auto auto;
    width: unset;
    min-width: unset;
  }
  .title {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 21px;
    margin: 30px 0;
  }
}
</style>
