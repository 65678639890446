<template>
  <div :class="$style.container">
    <div :class="$style['input-container']">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" module>
.container {
  display: inline-block;
  border: 1px solid @self-serve-nav-bar-gray;
  border-radius: 8px;
  box-shadow: 3px 4px 2px rgba(0, 0, 0, 0.08);
  margin: 20px auto 80px;
  padding: 30px 10%;
  background: @color-white;
  @media only screen and (max-width: 500px) {
    display: inline-block;
    min-width: unset;
    margin: 10px -10px auto;
    padding: 30px 10px;
  }
  .input-container {
    position: relative;
    margin: auto;
  }
}
</style>
