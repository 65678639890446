<script setup>
import DSButton from '@clearbanc/clear-components/button';

// -----
// Setup
// -----

const emits = defineEmits(['remove-file']);
const props = defineProps({
  files: {
    type: Array,
    required: true,
  },
});
</script>

<template>
  <div v-if="props.files.length > 0">
    <div :class="$style.list">
      <div
        v-for="(file, index) of props.files"
        :key="file.name + file.type + file.size"
        :class="$style.list__item"
      >
        <DSButton
          icon="pi pi-times"
          outlined
          rounded
          severity="danger"
          :class="$style.list__item__button"
          @click="emits('remove-file', index)"
        />
        <span :class="$style.list__item__text">{{ file.name }}</span>
      </div>
    </div>
  </div>
</template>

<style module>
.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list__item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.list__item__button {
  flex-shrink: 0;
}

.list__item__text {
  padding-top: 4px;
  word-break: break-all;
}
</style>
