<template>
  <p>
    <a
      href="https://help.clear.co/s/article/Understanding-Capacity"
      target="_blank"
      @click="$emit('linkClicked')"
    >
      <slot>How Funding Capacity works</slot>
    </a>
  </p>
</template>

<script>
export default {
  data() {
    return {
      capacityHelpCenterUrl:
        'https://help.clear.co/s/article/Understanding-Capacity',
    };
  },
};
</script>
