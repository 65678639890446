// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vendorInfoBox_jkyQE{vertical-align:top;flex-grow:1}.infoBoxContainer_AmrYW{margin-bottom:10px;display:flex;flex-direction:row;align-items:stretch;min-height:200px}@media screen and (max-width:600px){.infoBoxContainer_AmrYW{flex-direction:column}}.middleBox_an05Z{border-left:solid;border-right:solid;border-left-width:2px;border-right-width:2px;border-left-color:#d3d3d3;border-right-color:#d3d3d3}@media screen and (max-width:600px){.middleBox_an05Z{border-left:none;border-right:none;border-top:solid;border-bottom:solid;border-top-width:2px;border-bottom-width:2px;border-top-color:#d3d3d3;border-bottom-color:#d3d3d3}}.infoBoxTitle_gXI8W{color:#000;padding-bottom:8px;text-align:center;font-weight:700}.warningMessage_zhZOM{border:solid;border-color:orange;border-radius:5px;border-width:2px;padding:10px;margin-bottom:20px}.errorMsg_eLNQG{color:red}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vendorInfoBox": "vendorInfoBox_jkyQE",
	"infoBoxContainer": "infoBoxContainer_AmrYW",
	"middleBox": "middleBox_an05Z",
	"infoBoxTitle": "infoBoxTitle_gXI8W",
	"warningMessage": "warningMessage_zhZOM",
	"errorMsg": "errorMsg_eLNQG"
};
export default ___CSS_LOADER_EXPORT___;
