// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rounded_TL3Nd{border-radius:4px;width:-moz-max-content;width:-webkit-max-content;width:intrinsic;font-size:16px;min-width:186px}.rounded_TL3Nd.purple_ubIqK{background-color:#7f30ff}.rounded_TL3Nd.purple_ubIqK:hover{background-color:#7f30ff!important}.rounded_TL3Nd.white_mpmbw{color:#020202;line-height:24px;font-weight:400;padding:0 20px}.rounded_TL3Nd.white_mpmbw,.rounded_TL3Nd.white_mpmbw:hover:not(.disabled_rQnGX){background:#fff}.rounded_TL3Nd.transparent_PdR1d{background:none;color:#020202;line-height:24px;padding:0 20px;border:1px solid #000}.rounded_TL3Nd.transparent_PdR1d:hover:not(.disabled_rQnGX){background:none}.rounded_TL3Nd.modals-theme_JpWcZ{letter-spacing:.1px;width:170px;padding:unset}.rounded_TL3Nd.focused_m2rT5{display:none}@media only screen and (max-width:500px){.rounded_TL3Nd.focused_m2rT5{display:unset;position:fixed;top:7px;z-index:7;right:7px;height:40px}}.rounded_TL3Nd.grey_IPmJh{background:#e8e8ea;color:#000;font-weight:600;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;min-width:0;box-shadow:none}.rounded_TL3Nd.grey_IPmJh:hover:not(.disabled_rQnGX){background:#ceced0}.rounded_TL3Nd:hover:not([class*=disabled]){background:#000}@media only screen and (max-width:500px){.rounded_TL3Nd{float:right;margin:unset;min-width:unset}}.rounded_TL3Nd .loader_gueFZ{margin-right:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rounded": "rounded_TL3Nd",
	"purple": "purple_ubIqK",
	"white": "white_mpmbw",
	"disabled": "disabled_rQnGX",
	"transparent": "transparent_PdR1d",
	"modals-theme": "modals-theme_JpWcZ",
	"focused": "focused_m2rT5",
	"grey": "grey_IPmJh",
	"loader": "loader_gueFZ"
};
export default ___CSS_LOADER_EXPORT___;
