import { computed } from 'vue';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

export default function (context, contextRoot) {
  const ctx = contextRoot.root;
  const { getters } = ctx.$store;

  const requestedStatuses = computed(() => {
    return mapRequestStatuses({
      signAuthFormRequest: 'SIGN_BANK_ACCOUNT_AUTH_FORM',
    });
  });

  const isAuthorizedSignatory = computed(() => {
    return getters.isAuthorizedSignatory;
  });

  /**
   * Redirects user to ACH contract for bank authorization
   * @param bankAccountId for which contract is being signed
   * @returns error message or empty string
   */
  const signAchContract = async (bankAccountId) => {
    const { embeddedSigningSession, contract } =
      await ctx.$store.dispatchApiAction('SIGN_BANK_ACCOUNT_AUTH_FORM', {
        bankAccountId,
        returnURL: isAuthorizedSignatory.value
          ? window.location.href.split('?')[0]
          : null,
      });

    if (requestedStatuses.value.signAuthFormRequest.isError) {
      const translatedError = requestedStatuses.value.signAuthFormRequest.error
        .errorCode
        ? this.$t(
            `backendError.${requestedStatuses.value.signAuthFormRequest.error.errorCode}`,
            requestedStatuses.value.signAuthFormRequest.error.details,
          )
        : requestedStatuses.value.signAuthFormRequest.errorMessage;
      return translatedError;
    }

    if (!isAuthorizedSignatory.value && contract) {
      await ctx.$store.dispatchApiAction('FETCH_USER_CONTRACTS');
      return '';
    }

    window.location = embeddedSigningSession.url;
    return '';
  };

  return {
    signAchContract,
  };
}
