<template>
  <div>
    <ui-fieldset>
      <design-input
        v-model="modelValue.line1"
        name="line1"
        :label="$t('applicationPage.line1')"
        :error-msg="$t('common.requiredField')"
        required
      />
      <design-input
        v-model="modelValue.line2"
        name="line2"
        :error-msg="$t('common.requiredField')"
        :label="$t('applicationPage.line2')"
      />
    </ui-fieldset>
    <ui-fieldset>
      <design-dropdown
        v-model="modelValue.country"
        name="country"
        :error-msg="$t('common.requiredField')"
        :label="$t('applicationPage.selectCountry')"
        :external-label="$t('applicationPage.country')"
        :options="countryOptions"
        required
        @input="trackCountryChange"
      />
      <design-dropdown
        v-if="stateOptionsForCountry(modelValue.country)"
        v-model="modelValue.state"
        name="state"
        :error-msg="$t('common.requiredField')"
        :external-label="stateLabel(modelValue.country)"
        :label="$t('applicationPage.select')"
        :options="stateOptionsForCountry(modelValue.country)"
        required
      />
      <design-input
        v-else
        v-model="modelValue.state"
        name="state"
        :error-msg="$t('common.requiredField')"
        :external-label="stateLabel(modelValue.country)"
        :label="stateLabel(modelValue.country)"
        required
      />
      <design-input
        v-model="modelValue.city"
        name="city"
        :error-msg="$t('common.requiredField')"
        :label="$t('applicationPage.city')"
        required
      />
      <design-input
        v-if="
          modelValue.country &&
          !withoutPostalCodeCountries.includes(modelValue.country)
        "
        v-model="modelValue.postalCode"
        :error-msg="$t('common.requiredField')"
        :label="postalCodeLabel(modelValue.country)"
        required
      />
    </ui-fieldset>
  </div>
</template>

<script>
import { UiFieldset } from 'ui-components';
import analytics from '@/utils/analytics';

import { DesignInput, DesignDropdown } from '@/components/deprecated';

import {
  COUNTRY_OPTIONS,
  WITHOUT_POSTAL_CODE_COUNTRY_CODE,
} from '@/data/country-code-with-names';

import {
  stateLabel,
  stateOptionsForCountry,
  postalCodeLabel,
} from '@/utils/local';

export default {
  components: {
    UiFieldset,
    DesignInput,
    DesignDropdown,
  },
  props: {
    modelValue: { type: Object },
  },
  computed: {
    countryOptions: () => COUNTRY_OPTIONS,
    withoutPostalCodeCountries() {
      return WITHOUT_POSTAL_CODE_COUNTRY_CODE;
    },
  },
  methods: {
    stateOptionsForCountry,
    postalCodeLabel,
    stateLabel,
    trackCountryChange() {
      analytics.track('fe_country_changed', {
        page: this.$router.path,
        type: 'business address',
      });
    },
  },
};
</script>
