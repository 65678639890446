// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown_c6NRR{width:127px}.language-switcher-container_o_ndW{max-width:900px;display:flex;flex-direction:column;align-items:flex-end;margin:70px auto 0}@media only screen and (max-width:960px){.language-switcher-container_o_ndW{max-width:340px}}@media only screen and (max-width:500px){.language-switcher-container_o_ndW{margin:0 auto}}@media only screen and (max-width:460px){.language-switcher-container_o_ndW{position:absolute;top:14px;right:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "dropdown_c6NRR",
	"language-switcher-container": "language-switcher-container_o_ndW"
};
export default ___CSS_LOADER_EXPORT___;
