// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".column_NR4jL{border:1px solid #f2f2f2;padding:12px 6px;color:#666;font-weight:400}.column_NR4jL.gerstner_PHjka{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-weight:400;font-size:14px;line-height:20px}.column_NR4jL.align-left_HUGAn{text-align:left}.column_NR4jL.align-right_fLg8B{text-align:right}.column_NR4jL.align-center_niktk{text-align:center}.column_NR4jL.uppercase_Aob4R{text-transform:uppercase}.column_NR4jL.capitalize_FWbrS{text-transform:capitalize}.column_NR4jL:first-child{border-left:none}.column_NR4jL:last-child{border-right:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column": "column_NR4jL",
	"gerstner": "gerstner_PHjka",
	"align-left": "align-left_HUGAn",
	"align-right": "align-right_fLg8B",
	"align-center": "align-center_niktk",
	"uppercase": "uppercase_Aob4R",
	"capitalize": "capitalize_FWbrS"
};
export default ___CSS_LOADER_EXPORT___;
