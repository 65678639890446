<template>
  <div :class="$style['payments-layout']">
    <a href="#" @click.prevent="this.$router.back()">
      <icon style="color: black; width: 16px" name="chevron-left" />
      <h3>Pay a Vendor</h3>
    </a>
    <LoadingScreen v-if="isLoading" :class="$style['spinner']" />
    <div v-if="showVendorsTab" :class="$style['vendor-container']">
      <icon
        v-if="searchVendorsRequest.isPendingOrEmpty"
        name="spinner"
        scale="1"
      />
      <div v-else data-cy="vendor-list">
        <div>
          <todo-section
            :title="$t('vendorsPage.vendorModal.add')"
            icon="plus"
            status-icon-size="small"
            :theme="'flat-white'"
            :click-action="openVendorModal"
          ></todo-section>
        </div>
        <VendorDetails
          v-for="vendor in vendorsSearchResults"
          :key="vendor.id"
          :vendor="vendor"
          :data-cy="vendor.label"
          @openVendorModal="openVendorModal"
        />
        <VendorDetailsModal
          ref="vendorDetailsModal"
          @done="resetToStoreState"
        />
      </div>
    </div>
    <div v-else>
      <h3>{{ $t('vendorsPage.myVendors') }}</h3>
      <p v-if="isIndiegogoMerchant">
        {{ $t('vendorsPage.activeAdvance') }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import LoadingScreen from '@/components/LoadingScreen';
import VendorDetails from '@/components/payments/VendorDetails';
import VendorDetailsModal from '@/components/deprecated/modal-vendor-details';
import { useMeta } from 'vue-meta';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';

const components = {
  VendorDetailsModal,
  VendorDetails,
  LoadingScreen,
};

export default {
  components,
  setup() {
    useMeta({
      title: 'Vendors | Clearco',
      meta: [{ name: 'robots', content: 'noindex' }],
    });
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'userHasEverHadActiveAdvance',
      'feeVersions',
      'businessId',
      'vendorsSearchResults',
      'getAdvanceCurrencyCode',
      'isIndiegogoMerchant',
      'activatedAdvancesBySubtype',
      'business',
    ]),
    PAYMENTS_ROUTE_NAMES: () => PAYMENTS_ROUTE_NAMES,
    onlyHadActivatedFacebookAdvance() {
      return (
        Object.keys(this.activatedAdvancesBySubtype).length === 1 && // only one activated subtype
        Boolean(this.activatedAdvancesBySubtype.marketing_facebook?.length) // and the subtype is Facebook
      );
    },
    showVendorsTab() {
      if (
        (this.isIndiegogoMerchant && !this.userHasEverHadActiveAdvance) ||
        this.onlyHadActivatedFacebookAdvance
      ) {
        return false;
      }

      return true;
    },
    ...mapRequestStatuses({
      searchVendorsRequest: 'SEARCH_VENDORS',
    }),
  },
  async beforeMount() {
    this.isLoading = true;
    await this.$store.dispatch('refreshAdvances');
    await this.$store.dispatchApiAction('FETCH_USER_BANK_ACCOUNTS');
    this.isLoading = false;
  },
  async mounted() {
    await this.resetToStoreState();
  },
  methods: {
    async resetToStoreState() {
      await this.$store.dispatchApiAction('SEARCH_VENDORS', {
        // TODO: Revisit this limit once /vendors API has been reviewed.
        limit: 200,
        businessId: this.businessId,
      });
    },
    openVendorModal(vendorId, step) {
      if (vendorId) {
        this.$store.dispatch('ADD_SELECTED_VENDOR', { id: vendorId });
      }
      this.$refs.vendorDetailsModal.open(step);
    },
  },
};
</script>
<style lang="less" module>
.payments-layout {
  margin: 20px auto;
  max-width: 85%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Gerstner-Programm';
  font-weight: 300;

  @media screen and (max-width: 500px) {
    max-width: 90%;
  }

  a {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  h1 {
    font-family: 'Gerstner-Programm';
    font-weight: 500;
    font-style: normal;
    line-height: 1em;
    font-size: 26px;
  }

  h2 {
    font-family: 'Gerstner-Programm';
    font-weight: 300;
    font-style: normal;
    font-display: block;
    line-height: 22px;
    font-size: 16px;
  }

  h3 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 21px;
  }
}

// .vendor-container {
//   border: none;
//   margin-top: 30px;
// }

.spinner {
  > div {
    margin: 50px auto !important;
  }
}

.vendor-container {
  width: 98%;
  margin: 12px;
  padding: 20px 10px 10px;
}
</style>
