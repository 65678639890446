<template>
  <span>
    <ext-account
      title="wooCommerce"
      :logo="wooCommerce"
      :success="success"
      :disconnected="disconnected"
      :error="errors.woocommerce"
      logo-alt="WooCommerce logo"
      @click="openModal"
    />

    <ModalWoocommerce
      ref="wooCommModal"
      @error="$emit('error', SALES_PLATFORMS.WOOCOMMERCE)"
      @submit="(payload) => $emit('submit', payload)"
      @modalAction="
        (platformName, action) => $emit('modalAction', platformName, action)
      "
    />
  </span>
</template>

<script>
import { SALES_PLATFORMS } from '@/data/platforms';
import ExtAccount from '@/components/modals/ModalExternalAccountOnboarding2';
import ModalWoocommerce from '@/components/modals/ModalWoocommerce';

export default {
  components: {
    ExtAccount,
    ModalWoocommerce,
  },
  props: {
    success: Boolean,
    disconnected: Boolean,
    errors: Object,
  },
  computed: {
    wooCommerce: () => require('@/assets/logos/woo-commerce.png'),
    PLATFORM_NAME: () => SALES_PLATFORMS.WOOCOMMERCE,
  },
  methods: {
    openModal() {
      this.$emit('tileClick', SALES_PLATFORMS.WOOCOMMERCE);
      this.$refs.wooCommModal.open();
    },
  },
};
</script>
