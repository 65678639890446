// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_vkRIv{width:80%}.container_vkRIv.design-input_xXjkJ{width:100%}.container_vkRIv.design-input_xXjkJ .img_wulE0{top:12px;height:20px}.container_vkRIv [class^=vc-pane-container]{font-family:Montserrat}.container_vkRIv [class^=vc-title]{font-size:16px;color:#666;font-weight:400}.container_vkRIv [class^=vc-weekday]{font-size:12px;font-weight:400;color:#666}.container_vkRIv [class^=vc-day-content]{font-size:16px;font-weight:400!important}.container_vkRIv .img_wulE0{position:absolute;right:10px;top:10px;width:8%;height:50%}.container_vkRIv .img_wulE0.active_lY1jU path{stroke:#333}.container_vkRIv .clickable_fP2Hq{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_vkRIv",
	"design-input": "design-input_xXjkJ",
	"img": "img_wulE0",
	"active": "active_lY1jU",
	"clickable": "clickable_fP2Hq"
};
export default ___CSS_LOADER_EXPORT___;
