<template>
  <div>
    <div :class="$style.modalContext">
      {{ $t('vendorsPage.vendorAddress') }}
    </div>
    <custom-fieldset>
      <!-- Beneficiary Address Line 1 -->
      <UiInput
        name="beneficiaryAddress.line1"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.address.addressLine1')"
        required
        :model-value="selectedVendor.beneficiaryAddress.line1"
        @input="setVendorProps"
      />
      <!-- Beneficiary Address Line 2 -->
      <UiInput
        name="beneficiaryAddress.line2"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.address.addressLine2')"
        :model-value="selectedVendor.beneficiaryAddress.line2"
        @input="setVendorProps"
      />
      <!-- Beneficiary Address Country -->
      <UiInput
        name="beneficiaryAddress.country"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.address.country')"
        :model-value="getCountryName(selectedVendor.beneficiaryAddress.country)"
        size="small"
        required
        disabled
        @input="setVendorProps"
      />
      <!-- Beneficiary Address State -->
      <div v-if="requiredVendorFields.includes('beneficiaryStateOrProvince')">
        <UiDropdown
          v-if="stateOptions(selectedVendor.beneficiaryAddress.country)"
          size="small"
          name="beneficiaryAddress.state"
          :external-label="
            stateLabel(selectedVendor.beneficiaryAddress.country)
          "
          data-cy="state"
          :label="
            $t('common.selectOption', {
              option: `${stateLabel(
                selectedVendor.beneficiaryAddress.country,
              )} -`,
            })
          "
          :options="stateOptions(selectedVendor.beneficiaryAddress.country)"
          :model-value="selectedVendor.beneficiaryAddress.state"
          required
          @input="setVendorProps"
        />
        <UiInput
          v-else
          type="text"
          :error-msg="$t('common.errors.mandatory')"
          name="beneficiaryAddress.state"
          :label="stateLabel(selectedVendor.beneficiaryAddress.country)"
          :model-value="selectedVendor.beneficiaryAddress.state"
          required
          @input="setVendorProps"
        />
      </div>

      <!-- Beneficiary Address City -->
      <UiInput
        v-if="requiredVendorFields.includes('beneficiaryCity')"
        name="beneficiaryAddress.city"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="cityLabel(selectedVendor.beneficiaryAddress.country)"
        :model-value="selectedVendor.beneficiaryAddress.city"
        required
        @input="setVendorProps"
      />
      <!-- Beneficiary Address Postal Code -->
      <UiInput
        v-if="
          requiredVendorFields.includes('beneficiaryPostcode') &&
          selectedVendor.bankAddress.country &&
          !withoutPostalCodeCountries.includes(
            selectedVendor.bankAddress.country,
          )
        "
        name="beneficiaryAddress.postalCode"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="postalCodeLabel(selectedVendor.beneficiaryAddress.country)"
        :model-value="selectedVendor.beneficiaryAddress.postalCode"
        required
        @input="setVendorProps"
      />
    </custom-fieldset>
    <UiButton :text="$t('common.buttons.back')" @click="$emit('prev-tab')" />
    <UiButton
      :text="$t('common.buttons.next')"
      data-cy="next-button"
      @click="$emit('next-tab')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateChildren } from '@/composables/validation';
import { UiDropdown, UiButton, UiInput } from 'ui-components';

import {
  COUNTRY_OPTIONS,
  COUNTRY_CODE_WITH_NAMES,
  WITHOUT_POSTAL_CODE_COUNTRY_CODE,
} from '@/data/country-code-with-names';

import {
  stateOptionsForCountry,
  cityLabel,
  postalCodeLabel,
  stateLabel,
} from '@/utils/local';

import CustomFieldset from '../FieldsetCustom';

export default {
  components: {
    UiButton,
    UiInput,
    UiDropdown,
    CustomFieldset,
  },
  props: {
    requiredVendorFieldsWithValidation: { type: Object, required: true },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  computed: {
    ...mapGetters(['selectedVendor']),
    countryOptions() {
      return COUNTRY_OPTIONS;
    },
    requiredVendorFields() {
      return Object.keys(this.requiredVendorFieldsWithValidation);
    },
    withoutPostalCodeCountries() {
      return WITHOUT_POSTAL_CODE_COUNTRY_CODE;
    },
  },
  methods: {
    stateOptions(val) {
      return stateOptionsForCountry(val);
    },
    stateLabel(val) {
      return stateLabel(val);
    },
    postalCodeLabel(val) {
      return postalCodeLabel(val);
    },
    cityLabel(val) {
      return cityLabel(val);
    },
    getCountryName(countryCode) {
      return COUNTRY_CODE_WITH_NAMES[countryCode]();
    },
    setVendorProps(val, name) {
      this.$store.dispatch('UPDATE_SELECTED_VENDOR', { path: name, val });
    },
  },
};
</script>
<style lang="less" module>
.modalContext {
  color: black;
  padding-bottom: 30px;
  text-align: center;
  font-weight: bold;
}
</style>
