<template>
  <input-with-eyebrow
    v-if="autocompleteEnabled[id]"
    :ref="id"
    autofill="new-address"
    :placeholder="placeholder"
    :eyebrow="eyebrow"
    :label="label"
    :disabled="disabled"
    :required="required"
    :name="name"
    :model-value="modelValue"
    :validate-on-blur="validateOnBlur"
    type="text"
    :error="error"
    :error-msg="errorMsg"
    @focus="disableAutofill"
    @onBlur="onBlur"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { stateOptionsForCountry } from '@/utils/local';
import InputWithEyebrow from '@/components/inputs/InputWithEyebrow';

export default {
  emits: ['onBlur'],
  components: { InputWithEyebrow },
  props: {
    id: { type: String, required: true },
    disabled: Boolean,
    required: Boolean,
    name: String,
    label: { type: String, default: 'Find me' },
    error: Boolean,
    errorMsg: String,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: { type: String, default: 'Registered Address Line 1' },
    eyebrow: { type: String, default: 'Company Address' },
    validateOnBlur: { type: Boolean, default: true },
  },
  data() {
    return {
      autocomplete: {},
    };
  },
  computed: {
    ...mapGetters(['autocompleteEnabled']),
    loadError() {
      return this.autocompleteEnabled.globalLoadError;
    },
  },
  watch: {
    loadError() {
      if (this.loadError) {
        // Something is wrong with the API, disable autocomplete
        this.setAutocomplete(false);
      }
    },
  },
  created() {
    if (this.loadError) {
      // Something is wrong with the API, disable autocomplete
      this.setAutocomplete(false);
    } else {
      this.setAutocomplete(true);
    }
  },
  unmounted() {
    window.google.maps.event.clearInstanceListeners(this.autocomplete[this.id]);
  },
  mounted() {
    this.loadAutocomplete();
  },
  methods: {
    /**
     * Innitializes the Google Places API widget, designed to work with our components given that the
     * component has a nested <input> inside it
     *
     * @param {string} elementId The id of the element you want to attach the autocomplete widget to.
     */
    initAutocomplete() {
      try {
        this.autocomplete[this.id] = new window.google.maps.places.Autocomplete(
          this.$refs[this.id].$refs.input,
          { types: ['geocode'] },
        );
        this.setAutocomplete(true);
      } catch (error) {
        // Could not attach widget, disable autocomplete entirely
        this.setAutocomplete(false);
        return;
      }

      // Avoid paying extra for data by restricting the set of place fields that are returned
      this.autocomplete[this.id].setFields(['address_component']);

      this.autocomplete[this.id].addListener('place_changed', () => {
        const place = this.autocomplete[this.id].getPlace();
        let addressComponents = {};
        try {
          if (place.address_components) {
            addressComponents = this.regionalAddressMapping(
              place.address_components,
            );
          }
          this.$emit('address-selected', this.id, addressComponents);
        } catch (error) {
          // Something wrong with listener, disable autocomplete
          this.setAutocomplete(false);
        }
      });
    },
    loadAutocomplete() {
      const googleScript = document.getElementById('google-places-script');
      if (window.google) {
        this.initAutocomplete();
      } else {
        googleScript.onload = () => this.initAutocomplete();
      }
    },
    /**
     * Maps the different ways addresses are stored by Google to the way we store in our db
     */
    regionalAddressMapping(googleAddress) {
      // Find country
      const country = googleAddress.find((field) =>
        field.types.includes('country'),
      ).short_name;
      const cbAddress = {};
      // Sometimes Google cannot return a street number, just in case that happens
      // take the street number from the user input
      cbAddress.line1 =
        parseInt(this.$refs[this.id].$refs.input.value.split(' ')[0]) || '';
      googleAddress.forEach((field) => {
        if (field.types.includes('street_number')) {
          // Street number
          cbAddress.line1 = `${field.long_name}`;
        } else if (field.types.includes('route')) {
          // Street
          cbAddress.line1 = cbAddress.line1
            ? `${cbAddress.line1} ${field.long_name}`
            : `${field.long_name}`;
        } else if (field.types.includes('postal_town')) {
          // City
          cbAddress.city = field.long_name;
        } else if (field.types.includes('locality')) {
          // City
          cbAddress.city = field.long_name;
        } else if (field.types.includes('administrative_area_level_1')) {
          if (country === 'GB') {
            // State/province is treated differently for UK
            cbAddress.state = stateOptionsForCountry(country).find(
              (state) => state.label === field.long_name,
            ).value;
          } else {
            cbAddress.state = field.short_name;
          }
        } else if (field.types.includes('country')) {
          // Country
          cbAddress.country = field.short_name;
        } else if (field.types.includes('postal_code')) {
          // Postal Code
          cbAddress.postalCode = field.long_name;
        }
      });
      return cbAddress;
    },
    /**
     * By design, when the autocomplete widget is attached to a input element, Google sets
     * autocomplete='off'. Chrome browsers ignore this so we need to manually reassign autocomplete
     * value so that browsers won't autofill the form
     */
    disableAutofill() {
      this.$refs[this.id].$refs.input.setAttribute(
        'autocomplete',
        'new-address',
      );
    },
    /**
     * Sets input to be the value of store.autocompleteEnabled[autocompleteWidgetId]
     * @param {Boolean} val
     */
    setAutocomplete(val) {
      this.$store.dispatch('setAutocomplete', {
        id: this.id,
        enabled: val,
      });
    },
    onBlur(val) {
      this.$emit('onBlur', this.id, val);
    },
  },
};
</script>
