// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_M66X0{padding:0}.modal_M66X0 h2{font-size:24px;font-style:normal;font-weight:500;line-height:32px;font-family:Gerstner-Programm}.modal_M66X0 svg{height:100px;width:100%}.btn-container_ZJHSw{text-align:center;display:flex;gap:16px}.modal-content_GK7f4{max-width:700px;text-align:center;margin:10px}.title-container_CZ7sA,.title-container_CZ7sA h1{margin:0}.subtitle-container_LFqyS{margin-bottom:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_M66X0",
	"btn-container": "btn-container_ZJHSw",
	"modal-content": "modal-content_GK7f4",
	"title-container": "title-container_CZ7sA",
	"subtitle-container": "subtitle-container_LFqyS"
};
export default ___CSS_LOADER_EXPORT___;
