// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".business-profile-container_sxk8A{margin:0 auto}.business-profile-container_sxk8A .dashboard-container_k9kPg{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;padding:48px;background:#fff;border:1px solid #dadada;box-sizing:border-box;border-radius:4px;flex:1;justify-content:center;margin-top:0}@media only screen and (min-width:960px){.business-profile-container_sxk8A .dashboard-container_k9kPg{min-width:900px}}@media screen and (max-width:800px){.business-profile-container_sxk8A .dashboard-container_k9kPg{background:transparent;border:none;padding:0}.business-profile-container_sxk8A .dashboard-container_k9kPg [class^=subtitle]{max-width:100%}}.business-profile-container_sxk8A .dashboard-container_k9kPg .section-header_hZXiq{text-align:center}.business-profile-container_sxk8A .dashboard-container_k9kPg .section-header__title_yCABz{font-size:21px;line-height:30px;font-weight:500;margin-bottom:24px}.business-profile-container_sxk8A .footer-container_YMlNN{margin-top:1.5rem}@media only screen and (min-width:960px){.business-profile-container_sxk8A .footer-container_YMlNN{display:flex;justify-content:space-between;align-items:flex-start}}.business-profile-container_sxk8A .footer-container_YMlNN span{display:block;margin-bottom:1.5rem}@media only screen and (min-width:960px){.business-profile-container_sxk8A .footer-container_YMlNN span{display:inline}}.business-profile-container_sxk8A .footer-container_YMlNN .link_oevfj{color:#215cb6}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"business-profile-container": "business-profile-container_sxk8A",
	"dashboard-container": "dashboard-container_k9kPg",
	"section-header": "section-header_hZXiq",
	"section-header__title": "section-header__title_yCABz",
	"footer-container": "footer-container_YMlNN",
	"link": "link_oevfj"
};
export default ___CSS_LOADER_EXPORT___;
