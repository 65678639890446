// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cashback-details_I8LuR div[class^=title__]{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif!important;font-size:18px!important;font-weight:700!important;margin-top:32px!important}.cashback-details_I8LuR div[class^=content__]{padding:30px!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cashback-details": "cashback-details_I8LuR"
};
export default ___CSS_LOADER_EXPORT___;
