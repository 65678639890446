<template>
  <div :class="$style.link">
    <icon :class="classes" :name="iconName" />
    <a :href="link">{{ text }}</a>
  </div>
</template>

<script>
export default {
  props: {
    iconName: { type: String, default: null },
    link: { type: String, default: null },
    text: { type: String, default: null },
  },
  computed: {
    classes() {
      return {
        [this.$style.rotate]: this.iconName === 'phone',
      };
    },
  },
};
</script>

<style lang="less" module>
.link {
  padding: 5px;
  a {
    text-decoration: none;
  }
  svg {
    margin-right: 5px;
    color: @color-blue;
  }
  .rotate {
    transform: rotate(90deg);
  }
  @media only screen and (max-width: 500px) {
    text-align: left;
  }
}
</style>
