// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cta-container_T7XHG{display:flex;flex-direction:row;justify-content:flex-end;align-items:center}.cta-container--dual-btns_YmW7q{justify-content:space-between;max-width:600px;width:100%}.cta-container_T7XHG.center-cta_N1oHi{justify-content:center}.button_gMWqp{min-width:186px}@media screen and (max-width:500px){.button_gMWqp{min-width:110px}}button.button-link_Rxh4q{padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cta-container": "cta-container_T7XHG",
	"cta-container--dual-btns": "cta-container--dual-btns_YmW7q",
	"center-cta": "center-cta_N1oHi",
	"button": "button_gMWqp",
	"button-link": "button-link_Rxh4q"
};
export default ___CSS_LOADER_EXPORT___;
