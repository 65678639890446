<template>
  <AutocompleteGroup
    :name="name"
    :label="label"
    option-label="description"
    :suggestions="filteredPredictions"
    v-bind="$attrs"
    @item-select="emitAddress"
    @complete="getPlacePredictions($event)"
  />
  <div ref="attribution"></div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AutocompleteGroup from '@clearbanc/clear-components/autocompletegroup';

defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['addressSelected', 'error']);

let autocompleteService;
let placesService;
const filteredPredictions = ref([]);
const attribution = ref(null);

const initAutocomplete = () => {
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  // https://developers.google.com/maps/documentation/javascript/reference/places-service

  autocompleteService = new window.google.maps.places.AutocompleteService();
  placesService = new window.google.maps.places.PlacesService(
    attribution.value,
  );
};

const setPlacePredicitons = (predictions, status) => {
  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
    filteredPredictions.value = predictions;
  } else {
    filteredPredictions.value = [];
    emit('error', status);
  }
};

const getPlacePredictions = ({ query }) => {
  if (autocompleteService) {
    autocompleteService.getPlacePredictions(
      {
        input: query,
        types: ['geocode'],
      },
      setPlacePredicitons,
    );
  } else {
    filteredPredictions.value = [];
  }
};

const emitAddress = (event) => {
  const placeId = event.value.place_id;

  const callback = (place, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      return emit('addressSelected', place);
    }

    filteredPredictions.value = [];
    return emit('error', status);
  };

  placesService.getDetails({ placeId }, callback);
};

onMounted(() => {
  if (window.google) {
    initAutocomplete();
  } else {
    const googleScript = document.getElementById('google-places-script');
    googleScript.onload = () => initAutocomplete();
  }
});
</script>
