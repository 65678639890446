<template>
  <div :class="$style.wrapper">
    <button
      v-for="section in sections"
      :class="[
        $style.button,
        section.alignRight && $style['button--align-right'],
        activeSection === section.sectionToToggle && $style['button--active'],
      ]"
      @click="$emit('toggleSection', section.sectionToToggle)"
    >
      {{ section.label }}
    </button>
  </div>
</template>

<script>
/**
 * @typedef {Object} SectionItem
 * @property {string} label - The label name.
 * @property {string} sectionToToggle - The section name to toggle.
 * @property {boolean} [alignRight] - Whether to align the section to the right.
 */

export default {
  props: {
    /**
     * An array of objects representing sections.
     * @type {SectionItem[]}
     */
    sections: {
      type: Array,
      required: true,
    },
    activeSection: {
      type: String,
    },
  },
};
</script>

<style lang="less" module>
.wrapper {
  display: flex;
}

.button {
  border-top: 1px solid #caa8ff;
  border-left: 1px solid #caa8ff;
  border-right: 1px solid #caa8ff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 8px 16px;
  position: relative;
  color: #732cf8;
  text-decoration: underline;
  background: #fff;
}

.button:focus {
  outline: none;
}

.button--align-right {
  margin-left: auto;
}

.button--active {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

.button--active:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  z-index: 1;
}
</style>
