<template>
  <div :class="classes">
    <DSButton
      v-if="showLink && !linkProps.url"
      :label="linkProps.text"
      class="p-button-link"
      :class="$style['button-link']"
      @click="$emit('linkClick')"
    />
    <CustomLink
      v-else-if="showLink && linkProps.url"
      :url="linkProps.url"
      :text="linkProps.text"
      @click="$emit('linkClick')"
    />
    <BackButton v-else-if="showBackBtn" @click="$emit('previousClick')" />
    <div v-else-if="hasSlotElements">
      <slot />
    </div>
    <Onboarding2Cta
      v-if="showOnInputFocused || showBtnOnScroll"
      stick-to-top
      :text="text"
      :loading="loading"
      :disabled="disabled"
      :theme="theme"
      @click="$emit('nextClick')"
      @keyup.enter="$emit('nextClick')"
    />
    <Onboarding2Cta
      :text="text"
      :loading="loading"
      :disabled="disabled"
      :class="$style['button']"
      :theme="theme"
      @click="$emit('nextClick')"
      @keyup.enter="$emit('nextClick')"
    />
  </div>
</template>

<script>
import DSButton from '@clearbanc/clear-components/button';
import { Onboarding2Cta, BackButton, CustomLink } from '@/components';
import { detectScrollMixin } from '@/utils/vue-mixins';

export default {
  components: {
    Onboarding2Cta,
    BackButton,
    CustomLink,
    DSButton,
  },
  mixins: [detectScrollMixin],
  props: {
    showBackBtn: { type: Boolean, default: false },
    showLink: { type: Boolean, default: false },
    linkProps: { type: Object },
    showOnInputFocused: { type: Boolean, default: false },
    text: { type: String, default: 'Next Step' },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    centerCta: { type: Boolean, default: false },
    showButtonOnTopOnScroll: { type: Boolean },
    theme: { type: String, default: 'black' },
  },
  computed: {
    hasSlotElements() {
      return this.$slots?.default;
    },
    showBtnOnScroll() {
      return this.showButtonOnTopOnScroll ?? this.onScroll;
    },
    classes() {
      return {
        [this.$style['cta-container']]: true,
        [this.$style['cta-container--dual-btns']]:
          this.showBackBtn || this.showLink || this.hasSlotElements,
        [this.$style['center-cta']]: this.centerCta,
      };
    },
  },
};
</script>

<style lang="less" module>
.cta-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  &--dual-btns {
    justify-content: space-between;
    max-width: 600px;
    width: 100%;
  }
  &.center-cta {
    justify-content: center;
  }
}

.button {
  min-width: 186px;
  @media screen and (max-width: 500px) {
    min-width: 110px;
  }
}

button.button-link {
  padding: 0;
}
</style>
