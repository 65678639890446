// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hero_VyJUW{margin:24px 0}.hero_VyJUW h1{margin-bottom:22px;margin-top:22px}.section_BE0UR{font-family:Montserrat;padding:15px 30px;margin-bottom:25px;background:#fff;border:1px solid #dadada;box-sizing:border-box;border-radius:4px;flex:1 0 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": "hero_VyJUW",
	"section": "section_BE0UR"
};
export default ___CSS_LOADER_EXPORT___;
