import { createMachine } from 'xstate';

const receiptAndDynamicVendorDetailsFlow = {
  id: 'newBnplPayment',
  initial: 'flow',
  states: {
    flow: {
      initial: 'invoiceOrReceipt',
      states: {
        invoiceOrReceipt: {
          on: {
            NEXT: 'uploadInvoice',
            RESET: 'invoiceOrReceipt',
          },
        },
        uploadInvoice: {
          on: {
            NEXT: 'bnplPaymentSchedule',
            PREVIOUS: 'invoiceOrReceipt',
            RESET: 'invoiceOrReceipt',
          },
        },
        bnplPaymentSchedule: {
          on: {
            NEXT: 'billDetailsForm',
            PREVIOUS: 'uploadInvoice',
            RESET: 'invoiceOrReceipt',
          },
        },
        billDetailsForm: {
          on: {
            NEXT: [
              {
                target: 'beneficiaryDetails',
                cond: 'isNewBeneficiary',
              },
              { target: 'bnplPaymentScheduleConfirmation' },
            ],
            PREVIOUS: 'bnplPaymentSchedule',
            RESET: 'invoiceOrReceipt',
          },
        },
        beneficiaryDetails: {
          on: {
            NEXT: 'bnplPaymentScheduleConfirmation',
            PREVIOUS: 'billDetailsForm',
            RESET: 'invoiceOrReceipt',
          },
        },
        bnplPaymentScheduleConfirmation: {
          on: {
            NEXT: [{ target: 'paymentAdded' }],
            PREVIOUS: [
              {
                target: 'beneficiaryDetails',
                cond: 'isNewBeneficiary',
              },
              { target: 'billDetailsForm' },
            ],
            RESET: 'invoiceOrReceipt',
          },
        },
        paymentAdded: {
          on: {
            RESET: 'invoiceOrReceipt',
            GO_TO_BENEFICIARY_DETAILS: 'beneficiaryDetails',
          },
        },
        hist: { type: 'history' },
      },
      on: { EXIT: 'exitFlow' },
    },
    exitFlow: {
      on: { GIVE_UP: 'flow.hist', RESET: 'flow.invoiceOrReceipt' },
    },
  },
};

const guards = {
  userSelectedOtherVendor: (_, query) => query.userSelectedOtherVendor,
  isReceiptFlow: (_, query) => query.isReceiptFlow,
  isNewBeneficiary: (_, query) => query.isNewBeneficiary,
};

const getPaymentStateMachine = () => {
  return createMachine(receiptAndDynamicVendorDetailsFlow, { guards });
};

export { getPaymentStateMachine };
