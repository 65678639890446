<template>
  <div>
    <OnboardingUiMsg v-if="showErrorMessage" type="error">
      {{ errorMessageToDisplay }}
    </OnboardingUiMsg>
    <div :class="classes">
      <div>
        <slot name="form" />
      </div>
      <slot />
      <div v-if="$slots.cta" :class="$style.cta">
        <slot name="cta" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import OnboardingUiMsg from '@/components/UiMsg';
import { CONTACT_SUPPORT_MSG } from '@/data/error-messages';

export default {
  components: {
    OnboardingUiMsg,
  },
  props: {
    showErrorMessage: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
  },
  computed: {
    ...mapGetters(['contactDetails']),
    errorMessageToDisplay() {
      return this.errorMessage.length
        ? this.errorMessage
        : CONTACT_SUPPORT_MSG(this.contactDetails.number);
    },
    classes() {
      return {
        [this.$style.container]: true,
        [this.$style['with-error']]: this.showErrorMessage === true,
      };
    },
  },
};
</script>

<style lang="less" module>
.container {
  margin: 55px auto auto;
  width: fit-content;
  @media screen and (max-width: 500px) {
    margin-top: 30px;
    width: 100%;
  }
  &.with-error {
    margin-top: 30px;
    @media screen and (max-width: 500px) {
      margin-top: 20px;
    }
  }
}

.cta {
  margin-top: 50px;
}
</style>
