// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_P3yVm{max-width:400px;margin:0 auto}.input_l7fBs{margin:1rem auto 2rem}.button_DZJ4B{display:block;margin:0 auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_P3yVm",
	"input": "input_l7fBs",
	"button": "button_DZJ4B"
};
export default ___CSS_LOADER_EXPORT___;
