import _ from 'lodash';

import { makeAsyncMutations } from '@/utils/vuex-api-utils';

import * as types from './mutation-types';

export default {
  ...makeAsyncMutations(types.OAUTH_CONNECT, (state, { response }) => {
    // another mutation is trigerred in the action to put the external account in the store
  }),

  // SIGNUP
  // mutation sets some data that gets pre-filled into the signup page
  // most common case is business type and email
  [types.SET_SIGNUP_PARAMS]: (state, payload = {}) => {
    state.signupStart = {
      ...state.signupStart,
      ...payload,
    };
  },

  [types.SET_SIGNED_AT]: (state, payload) => {
    payload.userSignedAt = new Date();
  },
  ...makeAsyncMutations(types.CREATE_ADVANCE, (state, { response }) => {
    state.advances = _.keyBy([response], 'id');
  }),
  ...makeAsyncMutations(types.GET_USER_ADVANCES, (state, { response }) => {
    state.advances = _.keyBy(response, 'id');
  }),
  ...makeAsyncMutations(types.CREATE_BNPL_ADVANCE, (state, { response }) => {
    state.advances[response.id] = response;
  }),
  ...makeAsyncMutations(types.SELECT_ADVANCE_OFFER, (state, { response }) => {
    state.advances[response.id] = response;
  }),
  ...makeAsyncMutations(types.ADJUDICATE_BUSINESS, (state, { response }) => {
    state.adjudication = response.adjudication;
  }),
  ...makeAsyncMutations(types.GET_ADJUDICATION, (state, { response }) => {
    state.adjudication = response.adjudication;
  }),

  ...makeAsyncMutations(
    types.SIGN_ADVANCE_CONTRACT,
    (state, { actionSpec, response }) => {
      state.advances[actionSpec.payload.advanceId].signatureParams =
        response.embeddedSigningSession;
    },
  ),
  ...makeAsyncMutations(
    types.SIGN_CLEARCARD_CONTRACT,
    (state, { actionSpec, response }) => {
      const credit = Object.values(state.credits.credits)[0];
      credit.signatureParams = response;
    },
  ),
  ...makeAsyncMutations(
    types.REFRESH_ADVANCE_SIGNATURE_STATUS,
    (state, { response }) => {
      state.advances[response.id] = response;
    },
  ),
  ...makeAsyncMutations(
    types.FETCH_FACEBOOK_AD_ACCOUNT_INFO,
    (state, { response }) => {
      state.facebookUrl = response.facebookUrl;
    },
  ),
  ...makeAsyncMutations(types.GET_ECOM_REVENUE, (state, { response }) => {
    const aggregatedMonthly = response.revenue.reduce((acc, val) => {
      const month = val.date.slice(0, -3);
      /* eslint-disable-next-line no-param-reassign */
      acc[month] = acc[month]
        ? acc[month] + Number(val.amountCents)
        : Number(val.amountCents);

      return acc;
    }, {});
    const monthlyRevenue = Object.keys(aggregatedMonthly).map((key) => ({
      date: key,
      amountCents: aggregatedMonthly[key],
    }));

    state.ecomRevenue.daily = response;
    state.ecomRevenue.monthly = monthlyRevenue;
    state.ecomRevenue.currencyCode = response.currencyCode;
  }),
  [types.SET_AUTOCOMPLETE]: (state, response) => {
    state.autocompleteEnabled[response.id] = response.enabled;
  },
  [types.GLOBAL_AUTOCOMPLETE_LOADED]: (state, response) => {
    state.autocompleteEnabled.globalLoadError = response.loadError;
  },
  [types.SET_CURRENT_STATE_MODULE]: (state, stateModule) => {
    state.currentStateModule = stateModule;
  },

  [types.SET_SHOW_LEGACY_MODAL]: (state, show) => {
    state.showLegacyModal = show;
  },
};
