<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    large: Boolean,
  },
  computed: {
    classes() {
      return {
        [this.$style.customFieldset]: true,
      };
    },
  },
};
</script>

<style lang="less" module>
.customFieldset {
  display: flex;
  align-items: top;
  justify-content: space-between;
  flex-flow: row wrap;

  > div {
    margin: 0 5px;
  }
}
</style>
