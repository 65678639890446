<template>
  <div :class="$style.container">
    <DSButton
      label="English"
      class="p-button-link"
      :class="$style['button-link']"
      @click="handleClick(SUPPORTED_LANGUAGES.ENGLISH)"
    />
    <span :class="$style.space">|</span>
    <DSButton
      label="Deutsch"
      class="p-button-link"
      :class="$style['button-link']"
      @click="handleClick(SUPPORTED_LANGUAGES.GERMAN)"
    />
  </div>
</template>
<script>
import { updateLanguage, SUPPORTED_LANGUAGES } from '@/configs/i18n-constants';
import { mapGetters } from 'vuex';
import DSButton from '@clearbanc/clear-components/button';

export default {
  components: {
    DSButton,
  },
  computed: {
    ...mapGetters(['selectedLanguage']),
    SUPPORTED_LANGUAGES: () => SUPPORTED_LANGUAGES,
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    updateLanguage,
    async handleClick(language) {
      const nextLanguage = language;
      const previousLanguage = this.selectedLanguage;
      const target = this.currentRoute;

      updateLanguage(this.$store, { target, previousLanguage, nextLanguage });
    },
  },
};
</script>

<style lang="less" module>
.container {
  display: flex;
  align-items: center;
}
button.button-link {
  padding: 0;
}
.space {
  margin: 0 5px;
}
</style>
