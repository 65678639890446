<template>
  <Modal
    v-model:visible="showModal"
    header="Important"
    :modal="true"
    :draggable="false"
    append-to="self"
    data-cy="set-primary-modal"
  >
    <div :class="$style['modal-content']">
      <p>
        We noticed that you didn't include your Primary Bank Account (ending in
        {{ this.intendedPrimaryOrPrimaryBankAccount?.accountNumberLast4 }}) when
        selecting bank accounts. <br />
        If this was unintentional, click Reauthorize Connection and select the
        account previously set as primary. <br />
        If this was intentional you can continue and set a new Primary Account
        for debiting purposes
      </p>
    </div>
    <template #footer>
      <div :class="$style.footer">
        <DSButton
          label="Continue"
          class="p-button-outlined"
          :class="$style.footer__button"
          @click="closeModal"
        />
        <DSButton
          label="Reauthorize Connection"
          :class="$style.footer__button"
          @click="reauthorize"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@clearbanc/clear-components/dialog';
import DSButton from '@clearbanc/clear-components/button';
import { mapGetters } from 'vuex';

export default {
  emits: ['reauthorize'],
  components: {
    Modal,
    DSButton,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(['intendedPrimaryOrPrimaryBankAccount']),
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async reauthorize() {
      this.closeModal();
      this.$emit('reauthorize');
    },
  },
};
</script>

<style lang="less" module>
body .footer button.footer__button {
  margin: 0;
  width: calc(50% - 8px);
}

body .footer button.footer__button + button.footer__button {
  margin-left: 16px;
}

.modal-content p {
  margin: 0;
}
</style>
