<template>
  <div :class="$style.wrapper">
    <table data-cy="custom-table-payments">
      <thead>
        <tr :class="$style.row">
          <th
            v-for="column in columns"
            :key="column.id"
            :class="headerCellClasses(column)"
          >
            {{ column.label() }}
          </th>
        </tr>
      </thead>
      <tbody v-if="loading || !data.length">
        <td :colspan="columns.length" :class="$style['empty-state']">
          <ui-icon
            v-if="loading"
            data-cy="custom-table-payments-loader"
            name="loader"
          />
          <span v-else>{{ emptyMsg }}</span>
        </td>
      </tbody>
      <tbody v-else>
        <tr
          v-for="row in data"
          :key="row.id"
          :class="$style.row"
          :data-cy="row.index"
          @click="openDetailsModal(row)"
          @keydown="openDetailsModal(row)"
        >
          <slot name="row" :row="row" />
        </tr>
      </tbody>
    </table>
    <ModalBillDetailsActions v-if="rowDetailsModal" ref="rowItemDetailsModal" />
  </div>
</template>

<script>
import { UiIcon } from 'ui-components';
import { mapGetters } from 'vuex';

import ModalBillDetailsActions from '@/components/payments/ModalBillDetailsActions';

import { BillPaymentMethods, CLEARPAY_SEGMENT_EVENTS } from '@/data/payments';
import { getShortBillId } from '@/utils/payments';

import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import analytics from '@/utils/analytics';

export default {
  components: {
    UiIcon,
    ModalBillDetailsActions,
  },
  props: {
    data: { type: Array, default: () => [] },
    emptyMsg: { type: String, default: 'No results' },
    loading: Boolean,
    bordered: Boolean,
    gerstner: Boolean,
    rowDetailsModal: Boolean,
  },
  data() {
    return {
      columns: [],
    };
  },
  computed: {
    ...mapGetters(['advances', 'business', 'businessId', 'selectedBill']),
    ...mapRequestStatuses({
      fetchBillRequest: 'GET_BILL',
    }),
  },
  watch: {
    // need to re-map columns once advances are loaded
    // because cashback column conditionally renders
    // based on user having had an active MCA
    advances: {
      handler() {
        this.mapColumns();
      },
    },
  },
  async mounted() {
    this.mapColumns();
    const { billId } = this.$route.params;
    if (billId) {
      await this.$store.dispatchApiAction('GET_BILL', {
        id: billId,
      });
      if (this.fetchBillRequest.isSuccess) {
        this.openDetailsModal(this.selectedBill);
      }
    }
  },
  methods: {
    headerCellClasses(column) {
      return {
        [this.$style['header-cell']]: true,
        [this.$style['align-left']]: column.align === 'left',
        [this.$style['align-right']]: column.align === 'right',
        [this.$style.gerstner]: this.gerstner,
        [this.$style.bordered]: this.bordered,
      };
    },
    mapColumns() {
      this.columns = this.$slots
        .row()
        .filter((col) => !!col.props)
        .map((col) => {
          return col.props;
        });
    },
    async openDetailsModal(selectedRowItem) {
      if (this.rowDetailsModal) {
        let currentBill = selectedRowItem;
        if (selectedRowItem.billId) {
          // GET bill details if selectedRowItem is a transaction
          await this.$store.dispatchApiAction('GET_BILL', {
            id: selectedRowItem.billId,
          });
          currentBill = this.selectedBill;
        }
        this.$refs.rowItemDetailsModal.open(currentBill);
        if (currentBill.paymentMethod !== BillPaymentMethods.BNPL) {
          analytics.track(CLEARPAY_SEGMENT_EVENTS.CLICKED_BILL_DETAILS, {
            businessId: this.businessId,
            businessName: this.business.name,
            billId: currentBill.id,
            shortBillId: getShortBillId(currentBill.id),
          });
        }
        if (currentBill.paymentMethod === BillPaymentMethods.BNPL) {
          analytics.track(CLEARPAY_SEGMENT_EVENTS.CLICKED_BNPL_BILL_DETAILS, {
            businessId: this.businessId,
            businessName: this.business.name,
            billId: currentBill.id,
            uploadType: currentBill.isReceipt ? 'receipt' : 'invoice',
            shortBillId: getShortBillId(currentBill.id),
          });
        }
      }
    },
  },
};
</script>

<style lang="less" module>
.wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;

  table {
    display: table !important;
    border-spacing: 0;
    border-collapse: collapse;
    border: 0 !important;
    width: 100%;
    min-width: 700px;

    thead {
      pointer-events: none;
    }

    .row {
      border: 0;

      &:hover {
        background: #f7f7f7;
      }

      .header-cell {
        padding: 12px 6px !important;
        border: 1px solid transparent;
        color: @color-black-new;
        text-transform: none;
        font-size: 12px;
        font-weight: 500;
        text-align: center;

        &.align-left {
          text-align: left;
        }

        &.align-right {
          text-align: right;
        }

        &.gerstner {
          padding: 16px 24px;
          font-family: Gerstner-Programm, Helvetica, 'Segoe UI', sans-serif;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
        }

        &.bordered {
          border-bottom: 1px solid #ceced0 !important;
        }
      }
    }

    .empty-state {
      height: 300px;
      text-align: center;
      border: 0;
      color: @color-gray;
      background: @color-lightgray-100;
    }
  }
}
</style>
