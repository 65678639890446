/* An attempt at a very flexible form input component. For select,
multi-checkbox, and radio types, this is meant to be used in conjunction with
the FormInputOption component. Although it is not required, as you can pass in
options via props. */ // NOTE -- vue sometimes reuses these inputs which means
hooks do not get called // we work around this by watching the v-model
"expression" (ie `user.email`) // and can treat this as equivalent to "this
input is being reused" // ideally we could bind to :key from the component, but
this does not work // NOTE -- if unexpected things start happening with
type=textarea and type=regular // then try setting ':value.prop' instead of
':value'

<template>
  <div :key="formInputId" class="form-input" :class="classObject">
    <label v-if="!noLabel" class="form-input-label" :for="formInputId">
      <icon v-if="disabled" class="form-input-locked-icon" name="lock" />{{
        label || '&nbsp;'
      }}
    </label>
    <div class="form-input-wrapper" :data-cy="type">
      <template v-if="type === 'container'">
        <slot />
      </template>
      <template v-else-if="type === 'radio' || type === 'multi-checkbox'">
        <form-input-option
          v-for="(o, i) in optionsFromProps"
          :key="optionKey(o, i)"
          ref="input"
          :disabled="computedDisabled"
          :model-value="o.value"
        >
          {{ o.label }}
        </form-input-option>
        <slot />
      </template>
      <template v-else-if="type === 'checkbox'">
        <label class="form-input-inline-wrapper">
          <input
            class="form-input-inline"
            type="checkbox"
            :disabled="computedDisabled"
            :checked="modelValue"
            @focus="onFocus"
            @blur="onBlur"
            @change="onCheckboxChange"
          />
          <slot />
        </label>
      </template>
      <template v-else-if="type === 'dropdown'">
        <select
          :id="formInputId"
          ref="input"
          class="form-input-input"
          :disabled="computedDisabled"
          :value="valueForSelectField"
          @focus="onFocus"
          @blur="onBlur"
          @change="onSelectChange"
        >
          <form-input-option
            v-if="placeholder"
            :model-value="null"
            :hidden="!placeholderSelectable"
            :disabled="!placeholderSelectable"
          >
            {{ placeholder }}
          </form-input-option>
          <form-input-option
            v-for="(o, i) in optionsFromProps"
            :key="optionKey(o, i)"
            :model-value="o.value"
          >
            {{ o.label }}
          </form-input-option>
        </select>
      </template>
      <template v-else-if="type === 'textarea'">
        <textarea
          :id="formInputId"
          ref="input"
          class="form-input-input"
          :placeholder="computedPlaceholder"
          :disabled="computedDisabled"
          :model-value="modelValue"
          @focus="onFocus"
          @blur="onBlur"
          @input="onChange"
        />
      </template>
      <template v-else>
        <div v-if="prefix" class="form-input-prefix">{{ prefix }}</div>
        <input
          :id="formInputId"
          ref="input"
          class="form-input-input"
          :autocomplete="autocomplete"
          :name="name"
          :type="nativeInputType"
          :placeholder="computedPlaceholder"
          :disabled="computedDisabled"
          :value="modelValue"
          :step="numberInputStepValue"
          :maxlength="maxLength"
          @keydown="keyHandler"
          @focus="onFocus"
          @blur="onBlur"
          @input="onChange"
        /><a
          v-if="type === 'password' && allowShowPassword"
          class="pass-show-hide-toggle"
          @click="isPasswordMasked = !isPasswordMasked"
          @keyup.enter="isPasswordMasked = !isPasswordMasked"
          >{{ isPasswordMasked ? $t('common.show') : $t('common.hide') }}</a
        >
      </template>
    </div>
    <div class="form-input-instructions">
      <slot name="instructions">
        {{ instructions }}
      </slot>
    </div>
    <div v-if="hasError" class="form-input-error" data-cy="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import useVuelidate from '@vuelidate/core';
import isValidDate from 'date-fns/isValid';
import * as validators from '@vuelidate/validators';
import { inject } from 'vue';

const components = {
  'form-input-option': require('@/components/forms/FormInputOption').default,
};

// shared counter to generate unique IDs used for label + input tag binding
let inputIndex = 0;

const TYPES_WITH_OPTIONS = ['radio', 'dropdown', 'multi-checkbox'];
const NUMERIC_TYPES = ['number', 'integer', 'decimal', 'money', 'percent'];

export default {
  emits: ['focus', 'blur', 'input', 'update:modelValue'],
  components,
  props: {
    customError: { type: String },
    autocomplete: { type: String },
    name: { type: String },
    type: { type: String, default: 'text' },
    label: { type: String },
    prefix: { type: String },
    noLabel: { type: Boolean },
    instructions: String,
    placeholder: String,
    placeholderSelectable: Boolean,
    required: Boolean,
    requiredWarning: Boolean,
    requiredMessage: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('common.requiredField');
      },
    },
    requireUpdate: Boolean, // require the value gets updated
    requireUpdateMessage: { type: String, default: 'Please update this field' },
    min: [Date, Number],
    max: [Date, Number],
    maxLength: Number,
    toUpperCase: Boolean,
    toLowerCase: Boolean,
    disabled: Boolean,
    modelValue: {},
    falseValue: {},
    digitsOnly: Boolean,
    regex: { type: [String, RegExp] },
    regexMessage: { type: String, default: 'This field is invalid' },
    // for radio / select / multi-checkbox
    options: { type: [Object, Array] },
    autoSelect: Boolean,
    // for checkbox
    checkedValue: {},
    // for password fields
    allowShowPassword: Boolean,
    customStep: Number,
    checkPasswordStrength: Boolean,
  },
  setup: () => ({ v$: useVuelidate() }),
  provide() {
    return { formParentDisabled: () => this.computedDisabled };
  },
  inject: { formParentDisabled: { default: false } },
  data() {
    // DO NOT USE AN ARROW FN HERE - need `this` accessible
    return {
      idCounter: inputIndex++,
      vmodelName: _.get(this, 'v$node.data.model.expression', ''),
      originalValue: this.modelValue,
      hasFocus: false,
      // TODO: figure out bugs with component reuse -- switch to
      isPasswordMasked: true, // only relevant for password
      // ...this.type === 'password' && { isPasswordMasked: true },
    };
  },
  computed: {
    computedDisabled() {
      if (this.disabled) return true;
      return _.isFunction(this.formParentDisabled) && this.formParentDisabled();
    },
    formInputId() {
      const cleanModel = this.vmodelName.replace(/[^a-z]/gi, '');
      return `form-input-${this.idCounter}-${cleanModel}`;
    },
    classObject() {
      return {
        'is-error': this.v$.modelValue.$error,
        'is-focused': this.hasFocus,
        'is-disabled': this.computedDisabled,
        [this.type]: true,
      };
    },
    nativeInputType() {
      // note relevant for dropdown, radio, etc
      if (this.type === 'textarea') return undefined;
      if (this.type === 'password' && this.isPasswordMasked) return 'password';
      if (NUMERIC_TYPES.includes(this.type)) return 'number';
      return 'text';
    },
    valueForSelectField() {
      if (this.modelValue === undefined) return '_null_';
      if (this.modelValue === null) return '_null_';
      if (typeof this.modelValue === 'boolean') return String(this.modelValue);
      return this.modelValue;
    },
    numberInputStepValue() {
      if (this.customStep) return this.customStep;
      if (
        this.type === 'decimal' ||
        this.type === 'money' ||
        this.type === 'percent'
      )
        return 0.01;
      if (this.type === 'integer' || this.type === 'number') return 1;
      return undefined;
    },
    computedPlaceholder() {
      if (this.placeholder) return this.placeholder;
      if (this.type === 'date') return 'YYYY-MM-DD';
      return undefined;
    },
    hasError() {
      return !!this.customError || this.v$.modelValue.$error;
    },
    errorMessage() {
      if (this.customError) {
        return this.customError;
      } else if (
        this.v$.modelValue.required?.$invalid ||
        this.v$.modelValue.requiredWarning?.$invalid
      ) {
        return this.requiredMessage;
      } else if (this.v$.modelValue.requireUpdate?.$invalid) {
        return this.requireUpdateMessage;
      } else if (this.v$.modelValue.url?.$invalid) {
        return this.$t('common.invalidUrl');
      } else if (this.v$.modelValue.email?.$invalid) {
        return this.$t('common.invalidEmailAddress');
      } else if (this.v$.modelValue.number?.$invalid) {
        return this.$t('common.mustBeAPositiveNumber');
      } else if (this.v$.modelValue.integer?.$invalid) {
        return this.$t('common.mustBeADecimal');
      } else if (this.v$.modelValue.decimal?.$invalid) {
        return this.$t('common.mustBeANumber');
      } else if (this.v$.modelValue.money?.$invalid) {
        return this.$t('common.mustBeValidAmount');
      } else if (this.v$.modelValue.percent?.$invalid) {
        return this.$t('common.mustBeValidPercentage');
      } else if (this.v$.modelValue.min?.$invalid) {
        return this.$t('common.mustBeGreater', { min: this.min });
      } else if (this.v$.modelValue.max?.$invalid) {
        return this.$t('common.mustBeLess', { max: this.max });
      } else if (this.v$.modelValue.date?.$invalid) {
        return this.$t('common.invalidDate');
      } else if (this.v$.modelValue.regex?.$invalid) {
        return this.regexMessage;
      } else if (this.v$.modelValue.passwordLength?.$invalid) {
        return this.$t('common.mustBe8characters');
      } else if (this.v$.modelValue.passwordStrength?.$invalid) {
        return this.$t('common.passwordStrength');
      }
      return undefined;
    },
    isTypeWithOptions() {
      return TYPES_WITH_OPTIONS.includes(this.type);
    },
    optionsFromProps() {
      /* eslint-disable consistent-return */

      // only for types that support form-input-options
      if (!this.isTypeWithOptions) return;
      if (!this.options) return [];
      if (_.isArray(this.options)) {
        // TODO: convert array of strings into proper format
        if (_.isString(this.options[0])) {
          return _.map(this.options, (val) => ({ value: val, label: val }));
        }
        return this.options;
      } else if (_.isObject(this.options)) {
        // map object of options {value: label} to array
        return _.map(this.options, (val, key) => ({ value: key, label: val }));
      }
      return [];
    },
  },
  watch: {
    vmodelName() {
      this.v$.modelValue.$reset();
      this.originalValue = _.cloneDeep(this.modelValue);
    },
  },
  beforeUpdate() {
    // we watch what is the "expression" that vmodel is bound to
    // ex: `user.email`
    // we use this to detect when Vue is reusing the component
    this.vmodelName = _.get(this, 'v$node.data.model.expression', '');
  },
  updated() {
    if (this.isTypeWithOptions) this.fixOptionSelection();
  },
  mounted() {
    if (this.isTypeWithOptions) this.fixOptionSelection();
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    onFocus() {
      this.hasFocus = true;
      this.$emit('focus');
    },
    onBlur() {
      this.hasFocus = false;
      if (!this.isTypeWithOptions && this.modelValue) {
        this.$emit('input', this.cleanValue(this.modelValue));
        this.$emit('update:modelValue', this.cleanValue(this.modelValue));
      }
      this.v$.modelValue.$touch();
      this.$emit('blur');
    },
    onSelectChange(event) {
      const val = event.target.value;
      this.$emit('input', val);
      this.$emit('update:modelValue', val);
    },
    onCheckboxChange(event) {
      let newVal;
      if (event.target.checked) {
        newVal = this.checkedValue || true;
      } else {
        newVal = this.falseValue ? false : null;
      }
      this.$emit('input', newVal);
      this.$emit('update:modelValue', newVal);
    },
    onChange(event) {
      this.$emit('input', event.target.value);
      this.$emit('update:modelValue', event.target.value);
    },
    fixOptionSelection() {
      const allowableOptionValues = this.optionsFromProps.map(
        (option) => option.value,
      );
      if (
        this.type === 'dropdown' &&
        !allowableOptionValues.includes(this.modelValue)
      ) {
        if (this.autoSelect) {
          this.$emit('input', this.options[0]);
          this.$emit('update:modelValue', this.options[0]);
        } else {
          this.$emit('input', null);
          this.$emit('update:modelValue', null);
        }
      }
    },
    keyHandler(event) {
      const keyCode = event.which;
      if (NUMERIC_TYPES.includes(this.type)) {
        // prevent typing e/E/+
        if ([69, 91, 187].includes(keyCode)) {
          event.preventDefault();
        }
        // prevent more than one "."
        // TODO: more work on this - you can still type 123..
        if (
          keyCode === 190 &&
          this.modelValue &&
          this.modelValue.toString().includes('.')
        ) {
          event.preventDefault();
        }
      }
    },
    optionKey(o, i) {
      return `inputopt-${this.formInputId}-${i}-${o.value}`;
    },
    cleanValue(val) {
      // called on field "blur" to sanitize values
      if (val === '') return null;
      if (!val) return val;
      if (this.type === 'number') {
        return Math.max(0, Math.round(val));
      } else if (this.type === 'url') {
        const hasProtocol = this.modelValue.includes('http');
        return `${hasProtocol ? '' : 'https://'}${this.modelValue}`;
      } else if (this.type === 'integer') {
        return Math.round(val);
      } else if (this.type === 'decimal' || this.type === 'money') {
        return parseFloat(val);
      } else if (this.type === 'integer') {
        return parseInt(val);
      } else if (this.type === 'text') {
        let cleanVal = val.toString().trim();
        if (this.digitsOnly) cleanVal = cleanVal.replace(/[^0-9]/g, '');
        if (this.toLowerCase) cleanVal = cleanVal.toLowerCase();
        if (this.toUpperCase) cleanVal = cleanVal.toUpperCase();
        return cleanVal;
      } else if (this.type === 'email') {
        return val.trim().toLowerCase();
      } else if (this.type === 'phone') {
        return val.replace(/[^0-9+]/g, '');
      } else if (this.type === 'date') {
        let cleanVal = val.replace(/[,./:;_]/g, '').slice(0, 10);
        if (!val.includes('-')) {
          const year = cleanVal.slice(0, 4);
          const month = cleanVal.slice(4, 6);
          const day = cleanVal.slice(6, 8);
          cleanVal = `${year}-${month}-${day}`;
        }
        return cleanVal;
      }
      return val;
    },
  },
  validations() {
    const validations = {
      ...(this.required && { required: validators.required }),
      ...(this.requiredWarning && { requiredWarning: validators.required }),
      ...(this.type === 'url' && { url: validators.url }),
      ...(this.type === 'email' && { email: validators.email }),
      ...(this.type === 'number' && { number: validators.numeric }),
      ...(this.type === 'decimal' && { decimal: validators.decimal }),
      ...(this.type === 'integer' && { integer: validators.integer }),
      ...(this.type === 'money' && { money: validators.decimal }),
      ...(this.type === 'percent' && { percent: validators.decimal }),
      ...(this.type === 'date' && {
        date: (val) => !val || isValidDate(new Date(val)),
      }),
      ...(this.min !== undefined && { min: validators.minValue(this.min) }),
      ...(this.max !== undefined && { max: validators.maxValue(this.max) }),
      ...(this.requireUpdate && {
        requireUpdate: (val) => val !== this.originalValue,
      }),
      ...(this.regex && {
        regex: validators.helpers.regex(
          this.regex instanceof RegExp ? this.regex : new RegExp(this.regex),
        ),
      }),
      ...(this.checkPasswordStrength && {
        passwordLength: (val) => !val || val.length >= 8,
        passwordStrength: (val) => {
          if (!val) return true;
          const hasDigit = val.match(/[0-9]/) ? 1 : 0;
          const hasUppercase = val.match(/[A-Z]/) ? 1 : 0;
          const hasLowercase = val.match(/[a-z]/) ? 1 : 0;
          const hasSpecialChar = val.match(/[^0-9A-Za-z]/) ? 1 : 0;
          return hasDigit + hasUppercase + hasLowercase + hasSpecialChar >= 3;
        },
      }),
    };
    return { modelValue: validations };
  },
};
</script>

<style lang="less">
.form-input {
  &.is-error {
    .form-input-input {
      border-color: fade(@error-red-border, 40%);
      color: @error-red-text;
    }
  }

  &.is-disabled {
    input,
    select,
    textarea {
      cursor: not-allowed;
      color: #bbb;
    }

    .form-input-input {
      border-color: #eee;
    }

    &.checkbox {
      .form-input-inline-wrapper {
        color: #aaa;
      }
    }
  }

  .form-input-locked-icon {
    margin-right: 4px;
    width: 13px;
    height: 13px;
  }
}

.form-input-input {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #d3d5d8;
  transition: border-color ease-in-out 0.15s;
  padding: 9px 8px 7px;
  height: 40px;
  color: #444;
  border-radius: 3px;
  font: inherit;

  &:hover {
    border-color: #829ca9;
  }

  textarea& {
    min-height: 70px;
    display: block;
  }

  select& {
    background: white;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='%23666'><polygon points='10,15 30,15 20,25'/></svg>")
      no-repeat;
    font-size: 16px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 20px; // so text doesnt go behind arrow
    -webkit-appearance: none;

    // dropdown arrow
    background-size: 25px 25px;
    background-position: right center;
    background-repeat: no-repeat;

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }

  input[type='text']&,
  input[type='number']&,
  input[type='password']& {
    font-size: 16px;
    line-height: 24px;
  }

  //- TODO: make height 44px for mobile

  &::placeholder {
    color: #bbb;
  }

  &:focus {
    outline: none;
  }

  .form-input.is-focused & {
    border-color: #aaa;
    border-color: #00b9ff;
  }
}

.form-input-prefix {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.form-input-label {
  font-size: 14px;
  line-height: 18px;
  display: block;
  padding-bottom: 4px;
  padding-left: 0;
  font-weight: bold;
}

.form-input-instructions,
.form-input-error {
  font-size: 11px;
  line-height: 14px;
  margin-top: 5px;
}

.form-input-instructions {
  color: #aaa;

  a {
    color: currentColor;
    text-decoration: underline;
  }

  &:empty {
    display: none;
  }
}

.form-input-error {
  color: @error-red-text;
}

.form-input-wrapper > label {
  display: block;
}

.form-input-wrapper {
  position: relative;

  .pass-show-hide-toggle {
    cursor: pointer;
    color: #333;
    user-select: none;
    opacity: 0.8;
    font-size: 12px;
    line-height: 40px;
    position: absolute;
    right: 0;
    padding: 0 10px;
  }
}

.form-input.radio {
  .form-input-wrapper {
    padding-left: 2px;
  }
}

.form-input.container {
  .button {
    width: 100%;
  }
}
</style>
