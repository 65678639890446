<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.391 11.0615C15.7931 10.0909 16 9.05058 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 9.05058 0.206926 10.0909 0.608964 11.0615C1.011 12.0321 1.60028 12.914 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C9.05058 16 10.0909 15.7931 11.0615 15.391C12.0321 14.989 12.914 14.3997 13.6569 13.6569C14.3997 12.914 14.989 12.0321 15.391 11.0615ZM8.75 5.3335C8.75 4.91928 8.41421 4.5835 8 4.5835C7.58579 4.5835 7.25 4.91928 7.25 5.3335V8.66683C7.25 9.08104 7.58579 9.41683 8 9.41683C8.41421 9.41683 8.75 9.08104 8.75 8.66683V5.3335ZM8 10.5835C7.58579 10.5835 7.25 10.9193 7.25 11.3335V11.3406C7.25 11.7548 7.58579 12.0906 8 12.0906H8.00711C8.42132 12.0906 8.75711 11.7548 8.75711 11.3406V11.3335C8.75711 10.9193 8.42132 10.5835 8.00711 10.5835H8Z"
      fill="url(#paint0_linear_3520_2130)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3520_2130"
        x1="2.10479e-07"
        y1="16"
        x2="16"
        y2="-7.43195e-07"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF6258" />
        <stop offset="1" stop-color="#E8564D" />
      </linearGradient>
    </defs>
  </svg>
</template>
