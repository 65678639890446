<template>
  <h2 :class="$style.subtitle"><slot /></h2>
</template>

<style lang="less" module>
.subtitle {
  color: @medium-gray;
  z-index: 1;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>
