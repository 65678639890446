<template>
  <Modal
    :visible="true"
    :close-on-escape="false"
    :modal="true"
    :closable="false"
    :show-header="false"
  >
    <div :class="$style.wrapper">
      <h2>Whoops!</h2>
      <p>
        {{ displayedErrorMessage }}
      </p>
      <p>
        Please <router-link to="/logout">Logout</router-link> in order to
        reattempt login or you will be redirected shortly.
      </p>
      <p class="bold">If this issue persists please contact support at:</p>
      <div>
        {{ $t('components.popupApiError.email') }}
        <a :href="contactSupportMethods.email.href">{{
          contactSupportMethods.email.display
        }}</a>
      </div>
      <div>
        {{ $t('components.popupApiError.phone') }}
        <a :href="contactSupportMethods.phone.href">{{
          contactSupportMethods.phone.display
        }}</a>
      </div>
    </div>
  </Modal>
</template>

<script>
import * as Sentry from '@sentry/vue';
import Modal from '@clearbanc/clear-components/dialog';
import { ContactSupportMethods } from '@/data/contact-support';
import { auth0, isTokenExpiredError } from '@/utils/auth0';

export default {
  components: {
    Modal,
  },
  props: {
    errorMessage: { type: String },
  },
  data() {
    return { contactSupportMethods: ContactSupportMethods };
  },
  mounted() {
    if (isTokenExpiredError(auth0.error)) {
      Sentry.captureException(
        new Error('Encountered expired token during login'),
      );
    }
    setTimeout(() => {
      this.$router.push({ name: 'logout' });
    }, 10000);
  },
  computed: {
    displayedErrorMessage() {
      if (this.errorMessage) {
        return this.errorMessage;
      }

      if (isTokenExpiredError(auth0.error)) {
        return 'We encountered an issue, please ensure your system time is set correctly.';
      }

      return 'An unexpected error occurred.';
    },
  },
};
</script>

<style lang="less" module>
.wrapper {
  padding: 20px 20px 0;
  color: #000;
  h2 {
    font-size: 1.75rem;
    font-weight: 500;
  }
}
</style>
