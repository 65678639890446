<template>
  <LayoutConnectAccounts
    :show-error="showError"
    :show-encryption-message="false"
    :event-tracking-fields="commonTrackingFields"
  >
    <template #titles>
      <h2 :class="$style.heading">
        {{ $t('data.onboarding.steps.connectMarketing.title') }}
      </h2>
      <p :class="$style['sub-heading']">
        {{ $t('data.onboarding.steps.connectMarketing.subtitle') }}
        <button
          v-tooltip.focus.bottom="{
            value: $t('paymentsPage.marketingTooltip'),
            class: $style['mobile-marketing-tooltip'],
            escape: true,
          }"
          type="button"
          class="pi pi-info-circle"
          :class="$style.tooltip"
        />
      </p>
    </template>
    <EncryptionMessage />
    <TileGroupConnectMarketing
      ref="tileGroup"
      v-model:show-error="showError"
      :event-tracking-fields="commonTrackingFields"
      load-all-platforms
    />
    <template #cta>
      <ButtonLinkContainerWithCheckbox
        :id="`${$route.name}-checkbox`"
        center-cta
        cta-text="Next Step"
        :checkbox-text="$t('common.buttons.skipForNow')"
        :value="skipStep"
        :event-tracking-fields="commonTrackingFields"
        :class="$style['next-btn']"
        @nextClick="nextStep"
        @checkboxClick="
          (newVal) => {
            skipStep = newVal;
          }
        "
      />
    </template>
  </LayoutConnectAccounts>
</template>

<script>
import { mapGetters } from 'vuex';
import stateModuleAware from '@/mixins/state-module-aware';
import { mapStateModuleGetters } from '@/utils/state-modules';
import TileGroupConnectMarketing from '@/components/TileGroupConnectMarketing';
import { LayoutConnectAccounts } from '@/components';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import ButtonLinkContainerWithCheckbox from '@/components/buttons/ButtonLinkContainerWithCheckbox';

export default {
  components: {
    TileGroupConnectMarketing,
    LayoutConnectAccounts,
    ButtonLinkContainerWithCheckbox,
  },
  mixins: [stateModuleAware],
  data() {
    return {
      showError: false,
      skipStep: false,
    };
  },
  computed: {
    ...mapGetters(['productSegmentLabel', 'hasConnectedMarketingAccount']),
    ...mapStateModuleGetters(['experimentName']),
    currentRoute() {
      return this.$route.name;
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        experiment: this.experimentName,
        targetArea: this.currentRoute,
      };
    },
  },
  methods: {
    nextStep() {
      if (!this.skipStep && !this.hasConnectedMarketingAccount) {
        this.showError = true;
        return;
      }
      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE,
      });
    },
  },
};
</script>

<style lang="less" module>
.mobile-marketing-tooltip {
  @media (max-width: @medium-screen) {
    left: 0 !important;
    margin: 0 20px 0 20px;
    max-width: fit-content !important;
    div:first-child {
      display: none;
    }
  }
}

.tooltip {
  color: @color-black;
  padding: 4px;
  cursor: help;
}

.next-btn {
  button {
    background-color: @color-purple-2022;
    &:hover {
      background-color: @color-purple-2022 !important;
    }
  }
}
h2.heading {
  font-size: 21px;
  line-height: 30px;
  font-weight: 500;
  margin: 0 0 24px;
}
.sub-heading {
  margin: 0 auto 20px;
  max-width: 550px;
}
@media screen and (max-width: 500px) {
  h2.heading,
  .sub-heading {
    text-align: left;
  }
}
</style>
