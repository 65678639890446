<template>
  <LayoutContainer>
    <LoadingScreen v-if="isLoading" />
    <div v-else :class="$style.container">
      <div :class="$style.content">
        <slot />
      </div>
    </div>
  </LayoutContainer>
</template>

<script>
import LoadingScreen from '@/components/LoadingScreen';
import LayoutContainer from '@/components/layouts/LayoutContainer';

export default {
  components: { LoadingScreen, LayoutContainer },
  props: {
    isLoading: Boolean,
  },
};
</script>

<style lang="less" module>
.container {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  background: @color-white;

  .content {
    margin: 0 auto;
    position: relative;
  }
}
</style>
