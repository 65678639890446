import { ref, onMounted, onUnmounted } from 'vue';

export function useTabletDetection() {
  const isTablet = ref(false);

  function updateIsTablet(event) {
    isTablet.value = event.target.window.matchMedia(
      'only screen and (max-width: 1024px)',
    ).matches;
  }
  function updateIsTabletOnLoad() {
    isTablet.value = window.matchMedia(
      'only screen and (max-width: 1024px)',
    ).matches;
  }

  onMounted(() => {
    updateIsTabletOnLoad();
    window.addEventListener('resize', updateIsTablet);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateIsTablet);
  });
  return { isTablet };
}

export function useMobileDetection() {
  const isMobile = ref(false);

  function updateIsMobile(event) {
    isMobile.value = event.target.window.matchMedia(
      'only screen and (max-width: 500px)',
    ).matches;
  }
  function updateIsMobileOnLoad() {
    isMobile.value = window.matchMedia(
      'only screen and (max-width: 500px)',
    ).matches;
  }

  onMounted(() => {
    updateIsMobileOnLoad();
    window.addEventListener('resize', updateIsMobile);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
  });
  return { isMobile };
}
