<template>
  <TabMenu :model="navItems" />
</template>
<script>
import TabMenu from '@clearbanc/clear-components/tabmenu';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';

export default {
  components: {
    TabMenu,
  },
  methods: {
    isRouteActive(route) {
      return this.$route.name === route;
    },
  },
  computed: {
    navItems() {
      return [
        {
          label: this.$t('account.pages.subPageRepayments.upcoming'),
          to: { name: PAYMENTS_ROUTE_NAMES.UPCOMING_REPAYMENTS },
          disabled: this.isRouteActive(
            PAYMENTS_ROUTE_NAMES.UPCOMING_REPAYMENTS,
          ),
        },
        {
          label: this.$t('account.pages.subPageRepayments.history'),
          to: { name: PAYMENTS_ROUTE_NAMES.REPAYMENTS },
          disabled: this.isRouteActive(PAYMENTS_ROUTE_NAMES.REPAYMENTS),
        },
      ];
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.p-tabmenuitem.p-highlight.p-disabled) {
  opacity: 1;
}
</style>
