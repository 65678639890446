<template>
  <p :class="$style.details"><slot></slot></p>
</template>

<style module scoped>
.details {
  margin: 4px 0 0;
  color: var(--neutral-grey-400, #5f5f61);
  font-family: @gerstner-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
</style>
