<template>
  <template v-if="userIsDisqualified">
    <CapacityOverview
      :title="this.$t('paymentsPage.disqualifiedMessage')"
      :cta-visible="isCtaVisible"
      :cta-label="getLabelForCta"
      :cta-click="handleCtaClick"
      :connected-accounts-visible="hasActiveSalesAccounts || userIsDisqualified"
      :connected-accounts-title="connectedAccountsTitle"
      :connected-accounts-show-error="showError"
      :connected-accounts-common-tracking-fields="commonTrackingFields"
      help-paragraph="paymentsPage.getHelpText"
      help-link="mailto:support@clear.co"
      help-link-text="support@clear.co"
    />
  </template>

  <template v-else-if="showSelfReported && !selfReportedCapacityExceeded">
    <CapacityOverview
      title="Funding Capacity"
      title-tag="Self-Reported"
      :cta-visible="isCtaVisible"
      :cta-label="getLabelForCta"
      :cta-click="handleCtaClick"
      :description="
        this.$t('paymentsPage.selfReportedRevCapacityWithoutNumber')
      "
      :available-capacity="remainingSelfReportedCapacityCents"
      :used-capacity="
        selfReportedCapacityCents - remainingSelfReportedCapacityCents
      "
      :total-capacity="selfReportedCapacityCents"
      :track-education-link="trackEducationLink"
      :connected-accounts-visible="hasActiveSalesAccounts || userIsDisqualified"
      :connected-accounts-title="connectedAccountsTitle"
      :connected-accounts-show-error="showError"
      :connected-accounts-common-tracking-fields="commonTrackingFields"
      :capacity-calculating="capacityCalculating"
      :disclaimer="DISCLAIMERS.VERIFYING_SALES_ACCOUNTS"
    />
  </template>

  <template v-else-if="showSelfReported && selfReportedCapacityExceeded">
    <CapacityOverview
      title="You have exceeded your Self Reported Capacity"
      :cta-visible="isCtaVisible"
      :cta-label="getLabelForCta"
      :cta-click="handleCtaClick"
      :track-education-link="trackEducationLink"
      :connected-accounts-visible="hasActiveSalesAccounts || userIsDisqualified"
      :connected-accounts-title="connectedAccountsTitle"
      :connected-accounts-show-error="showError"
      :connected-accounts-common-tracking-fields="commonTrackingFields"
      help-paragraph="paymentsPage.maxedOutCapacityHelp"
      :help-link="contactSupportMethods.email.href"
      :help-link-text="contactSupportMethods.email.display"
    >
      <template v-slot:custom-description>
        <p>
          You have exceeded your Self Reported Funding Capacity of
          <strong>{{ toAmountNoDecimal(selfReportedCapacityCents) }}</strong
          >. To avoid funding delays, connect additional sales accounts to
          increase Funding Capacity or cancel and resubmit bills based on your
          Self Reported Funding Capacity.
        </p>
      </template>
    </CapacityOverview>
  </template>

  <template v-else-if="showPlaidDisconnectedText">
    <CapacityOverview
      title="Bank accounts not connected"
      :cta-visible="isCtaVisible"
      :cta-label="getLabelForCta"
      :cta-click="handleCtaClick"
      :track-education-link="trackEducationLink"
      :connected-accounts-visible="hasActiveSalesAccounts || userIsDisqualified"
      :connected-accounts-title="connectedAccountsTitle"
      :connected-accounts-show-error="showError"
      :connected-accounts-common-tracking-fields="commonTrackingFields"
    >
      <template v-slot:custom-description>
        To view your Funding Capacity,
        <router-link :to="{ name: 'payments-profile/connect-banking' }">
          connect with Plaid
        </router-link>
        to link your bank accounts or contact us at
        <a href="mailto:support@clear.co">support@clear.co</a>
      </template>
    </CapacityOverview>
  </template>

  <template v-else-if="!capacityIsApproved && maxCapacityExceeded">
    <CapacityOverview
      title="You have exceeded your Preliminary Funding Capacity"
      :cta-visible="isCtaVisible"
      :cta-label="getLabelForCta"
      :cta-click="handleCtaClick"
      :track-education-link="trackEducationLink"
      :connected-accounts-visible="hasActiveSalesAccounts || userIsDisqualified"
      :connected-accounts-title="connectedAccountsTitle"
      :connected-accounts-show-error="showError"
      :connected-accounts-common-tracking-fields="commonTrackingFields"
      help-paragraph="paymentsPage.maxedOutCapacityHelp"
      :help-link="contactSupportMethods.email.href"
      :help-link-text="contactSupportMethods.email.display"
    >
      <template v-slot:custom-description>
        <p>
          You have exceeded your Preliminary Funding Capacity of
          <strong>{{
            toAmountNoDecimal(businessCapacity.capacityCents)
          }}</strong
          >. To avoid funding delays, connect additional sales accounts to
          increase Funding Capacity or cancel and resubmit bills based on your
          Preliminary Funding Capacity.
        </p>
      </template>
    </CapacityOverview>
  </template>

  <template v-else-if="maxedOutCapacity">
    <CapacityOverview
      :title="this.$t('paymentsPage.maxedOutCapacity')"
      :connected-accounts-visible="hasActiveSalesAccounts || userIsDisqualified"
      :connected-accounts-title="connectedAccountsTitle"
      :connected-accounts-show-error="showError"
      :connected-accounts-common-tracking-fields="commonTrackingFields"
      help-paragraph="paymentsPage.getHelpText"
      :help-link="contactSupportMethods.email.href"
      :help-link-text="contactSupportMethods.email.display"
    />
  </template>

  <template v-else-if="showPreliminaryCapacity">
    <CapacityOverview
      title="Funding Capacity"
      title-tag="Preliminary"
      :cta-visible="isCtaVisible"
      :cta-label="getLabelForCta"
      :cta-click="handleCtaClick"
      description="Based on connected accounts revenue. Submit a bill to get Approved Funding Capacity."
      :available-capacity="businessCapacity?.remainingCapacityCents"
      :used-capacity="businessCapacity?.usedCapacityCents"
      :total-capacity="businessCapacity?.capacityCents"
      :track-education-link="trackEducationLink"
      :connected-accounts-visible="hasActiveSalesAccounts || userIsDisqualified"
      :connected-accounts-title="connectedAccountsTitle"
      :connected-accounts-show-error="showError"
      :connected-accounts-common-tracking-fields="commonTrackingFields"
      :disclaimer="DISCLAIMERS.PLEASE_SUBMIT_A_BILL"
    />
  </template>

  <template v-else>
    <CapacityOverview
      title="Funding Capacity"
      title-tag="Approved"
      :cta-visible="isCtaVisible"
      :cta-label="getLabelForCta"
      :cta-click="handleCtaClick"
      :description="`Approved as of ${approvedDate}.`"
      :description-renewal-in-days="
        daysTillCapacityRenew > 0 ? daysTillCapacityRenew : 1
      "
      description-tooltip="Upon Funding Capacity refresh, your maximum Funding Capacity may change based on your connected revenue accounts"
      :available-capacity="businessCapacity?.remainingCapacityCents"
      :used-capacity="businessCapacity?.usedCapacityCents"
      :total-capacity="businessCapacity?.capacityCents"
      :track-education-link="trackEducationLink"
      :connected-accounts-visible="hasActiveSalesAccounts || userIsDisqualified"
      :connected-accounts-title="connectedAccountsTitle"
      :connected-accounts-show-error="showError"
      :connected-accounts-common-tracking-fields="commonTrackingFields"
      :capacity-calculating="capacityCalculating"
      :disclaimer="DISCLAIMERS.REMAINING_CAPACITY_AVAILABLE"
    />
  </template>
</template>
<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import {
  CLEARPAY_SEGMENT_EVENTS,
  V2_CAPACITY_ERROR_TYPES,
} from '@/data/payments';
import {
  SelfRevenueOffers,
  SelfRevenueRanges,
  GenericPhrases,
} from '@/data/self-revenue-range-offers';
import { DISCLAIMERS } from '@/data/capacity';
import analytics from '@/utils/analytics';
import moment from 'moment';
import { differenceInDays, startOfToday, startOfDay, parseISO } from 'date-fns';
import { ContactSupportMethods } from '@/data/contact-support';
import CapacityOverview from './CapacityOverview';

const CAPACITY_BOTTOM_OUT_CENTS = 100000; // $1000.00

export default {
  components: {
    CapacityOverview,
  },
  data() {
    return {
      showError: false,
      contactSupportMethods: ContactSupportMethods,
      DISCLAIMERS,
    };
  },
  props: {
    showSelfReported: { type: Boolean },
    selfReportedRev: { type: String },
    errorType: { type: String },
    currencyCode: { type: String, default: 'USD' },
  },
  computed: {
    ...mapGetters([
      'hasActiveSalesAccounts',
      'isBnplWaitlisted',
      'userCanSubmitInvoices',
      'productSegmentLabel',
      'isPrimaryBankAccountDisconnected',
      'businessOnPlaidBypass',
      'isBusinessProfileComplete',
      'business',
      'businessCapacity',
      'selfReportedCapacityCents',
      'maxCapacityExceeded',
      'businessCapacityGenerated',
      'remainingSelfReportedCapacityCents',
      'userIsDisqualified',
    ]),
    ...mapRequestStatuses({
      getCapacityRequest: 'GET_BUSINESS_CAPACITY',
    }),
    daysTillCapacityRenew() {
      if (!this.businessCapacity?.expiresAt) {
        return 0;
      }

      return differenceInDays(
        startOfDay(parseISO(this.businessCapacity?.expiresAt)),
        startOfToday(),
      );
    },
    showPreliminaryCapacity() {
      return this.businessCapacity && !this.businessCapacity.approvedAt;
    },
    selfReportedCapacityText() {
      const topOutRanges = [
        SelfRevenueRanges.from_1m_to_5m,
        SelfRevenueRanges.over_5m,
      ];
      return {
        capacity:
          this.selfReportedRev && topOutRanges.includes(this.selfReportedRev)
            ? GenericPhrases.selfReportedRevLimit
            : SelfRevenueOffers[this.selfReportedRev],
        range: SelfRevenueOffers[this.selfReportedRev],
      };
    },
    connectedAccountsTitle() {
      if (this.userIsDisqualified) {
        return this.$t('paymentsPage.disqualifiedTryToQuailify');
      }
      return this.$t('paymentsPage.calculatingCapacityConnectAccounts');
    },
    capacityCalculating() {
      return (
        this.errorType === V2_CAPACITY_ERROR_TYPES.CAPACITY_NOT_FOUND &&
        !this.businessCapacity?.capacityCents &&
        this.hasActiveSalesAccounts
      );
    },
    showPlaidDisconnectedText() {
      return (
        this.isPrimaryBankAccountDisconnected &&
        this.getCapacityRequest.isSuccess &&
        !this.businessOnPlaidBypass
      );
    },
    maxedOutCapacity() {
      return (
        (this.businessCapacity.remainingCapacityCents <=
          CAPACITY_BOTTOM_OUT_CENTS &&
          !this.errorType) ||
        (!this.businessCapacityGenerated &&
          this.remainingSelfReportedCapacityCents <= 0)
      );
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        targetArea: this.$route.name,
      };
    },
    submitInvoiceCtaLabel() {
      return this.$t('paymentsPage.submitInvoice', {
        receipt: this.$t('paymentsPage.orReceipt'),
      });
    },
    waitlistCtaLabel() {
      return this.$t('account.pages.pageVendors.joinTheWaitlist');
    },
    getLabelForCta() {
      if (this.isBnplWaitlisted) {
        return `${this.waitlistCtaLabel}`;
      }
      if (this.userCanSubmitInvoices) {
        return `${this.submitInvoiceCtaLabel}`;
      }
      return 'Finish your application';
    },
    isCtaVisible() {
      return !!(
        this.isBnplWaitlisted ||
        this.userCanSubmitInvoices ||
        !this.isBusinessProfileComplete
      );
    },
    approvedDate() {
      return moment(
        this.businessCapacity.approvedAt.split('T')[0].replaceAll('-', '/'),
      ).format('MMMM DD, YYYY');
    },
    capacityIsApproved() {
      return this.businessCapacity?.approvedAt;
    },
    selfReportedCapacityExceeded() {
      return (
        this.showSelfReported && this.remainingSelfReportedCapacityCents <= 0
      );
    },
  },
  methods: {
    toAmountNoDecimal(amountCents, currencyCode) {
      return this.$filters.noDecimal(
        this.$filters.currency(amountCents, currencyCode),
        currencyCode,
      );
    },
    trackEducationLink() {
      let userState;

      if (this.disconnectedAccountsError) {
        userState = 'account-disconnected';
      } else if (this.capacityCalculating) {
        userState = 'capacity-generating';
      } else if (this.maxedOutCapacity) {
        userState = 'capacity-consumed';
      } else if (this.showSelfReported) {
        userState = 'self-reported-capacity';
      } else {
        userState = 'capacity';
      }

      analytics.track(CLEARPAY_SEGMENT_EVENTS.CLICKED_CAPACITY_EDUCATION, {
        state: userState,
      });
    },
    handleCtaClick() {
      if (this.isBnplWaitlisted) {
        analytics.track('bnpl_waitlist_cta_clicked', {
          businessId: this.business.id,
          businessName: this.business.name,
        });
        this.$emit('joinWaitlist');
      } else if (this.userCanSubmitInvoices) {
        this.$emit('openPayInvoice');
      } else {
        this.$emit('finishApplicationCtaClicked');
      }
    },
  },
};
</script>
