<template>
  <div class="progress-tooltip" :style="style" :class="classes">
    <h5 class="progress-tooltip__title" :class="classes">
      {{ title }}
    </h5>
    <div class="progress-tooltip__amount">
      {{ $filters.currency(amount, currencyCode) }}
    </div>
    <ui-button
      v-if="details"
      data-cy="progress-tooltip-button"
      text="Details"
      size="small"
      class="progress-tooltip__button"
      :class="classes"
      primary
      @click="openCashBackDetails"
    />
  </div>
</template>

<script>
import { UiButton } from '@clearbanc/design-components/src/components';

export default {
  components: { UiButton },
  props: {
    amount: Number,
    details: Boolean,
    currencyCode: String,
    title: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    // percentage (0 - 100)
    offset: {
      type: Number,
      required: true,
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      cHeight: 0,
      popupVisible: false,
    };
  },
  computed: {
    style() {
      return {
        left: `${this.offset}%`,
        top: `${0 - this.cHeight - 14}px`,
      };
    },
    classes() {
      return {
        visible: this.isVisible,
        [this.theme]: true,
      };
    },
  },
  mounted() {
    this.cHeight = this.$el.clientHeight;
  },
  methods: {
    openCashBackDetails() {
      this.$emit('openCashBackDetails');
    },
  },
};
</script>

<style lang="less">
.progress-tooltip {
  position: absolute;
  border-radius: 8px;
  padding: 9px 17px;
  transition: all 300ms ease;
  transform: translateX(-50%);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  z-index: 4;
  width: fit-content;

  &.light {
    background: white;
    border: 1px solid @navy;

    &::after {
      background: white;
    }
  }

  &.dark {
    background: @color-grey-500;
    color: @color-grey-50;
    border-bottom: 1px solid @color-grey-500;
    border-right: 1px solid @color-grey-500;

    &::after {
      background: @color-grey-500;
      border: none;
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -6px;
    margin-left: -5px;
    height: 10px;
    width: 10px;
    left: 50%;
    border-bottom: 1px solid @navy;
    border-right: 1px solid @navy;
    transform: rotate(45deg);
    z-index: 15;
  }

  &:hover {
    opacity: 1 !important;
    visibility: visible !important;
  }

  .progress-tooltip__title {
    font-weight: 700;
    font-size: 12px;
    text-transform: none;

    &.light {
      color: @navy;
    }

    &.dark {
      color: @color-grey-50;
    }
  }

  .progress-tooltip__button {
    width: 100%;
    padding: 2px;
    margin-top: 10px;
    letter-spacing: 0;
    text-transform: none;

    &.dark {
      background-color: @color-grey-500;
      border: 1px solid white;
    }
  }

  .progress-tooltip__amount {
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
  }
}
</style>
