<template>
  <div>
    <template>
      <ContentToggle
        :active-section="activeSection"
        :sections="[
          { label: 'What To Have Ready', sectionToToggle: 'requirements' },
          {
            label: 'What\'s New',
            sectionToToggle: 'whats-new',
            alignRight: true,
          },
        ]"
        @toggleSection="(clickedSection) => (activeSection = clickedSection)"
      />
      <WhatsNewContent v-if="activeSection === 'whats-new'" />
    </template>
    <div v-if="activeSection === 'requirements'" :class="$style.requirements">
      <div :class="$style.grid">
        <div
          v-for="({ icon, content }, i) in documentList"
          :key="`document-${i}`"
          :class="$style.col"
        >
          <div :class="$style.icon" v-html="icon" />
          <p :class="$style.description" v-html="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconSsi from '@/assets/icons/business-profile-ssi.svg?inline';
import IconEin from '@/assets/icons/business-profile-ein.svg?inline';
import IconId from '@/assets/icons/business-profile-id.svg?inline';
import IconAoc from '@/assets/icons/business-profile-aoc.svg?inline';
import IconBank from '@/assets/icons/business-profile-bank.svg?inline';
import IconVoidCheck from '@/assets/icons/business-profile-void-check.svg?inline';
import ContentToggle from '@/components/ContentToggle';
import { WhatsNewContent } from '@/components';

export default {
  components: {
    ContentToggle,
    WhatsNewContent,
  },
  data() {
    return {
      documentList: [
        {
          icon: IconVoidCheck,
          content: 'Void Check or <br/>Direct Deposit Form',
        },
        {
          icon: IconBank,
          content: 'Bank Statements <br/>(Last 6 Mos)',
        },
        {
          icon: IconAoc,
          content: 'Articles of <br/>Incorporation',
        },
        {
          icon: IconId,
          content: 'Government ID, <br/>eg. Driver’s License',
        },
        {
          icon: IconEin,
          content: 'Employer Identification <br/>Number (If App.)',
        },
        {
          icon: IconSsi,
          content:
            'Social Security <br/>or Social Insurance <br/>Number (If App.)',
        },
      ],
      activeSection: 'requirements', // 'requirements' | 'whats-new'
    };
  },
};
</script>

<style lang="less" module>
.requirements {
  font-family: @gerstner-font;
  color: #393939;
  background: linear-gradient(0deg, rgba(97, 47, 255, 0.1) 24.71%, #fff 100%);
  border: 1px solid #caa8ff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 1.5rem;

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 2rem;
    display: block;
  }

  .grid {
    display: grid;
    justify-items: center;
    gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media only screen and (min-width: 960px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      row-gap: 3rem;
    }

    .col {
      grid-column: span 1 / span 1;
      text-align: center;

      .icon {
        width: 36px;
        height: auto;
        margin: 0 auto;
      }

      .description {
        font-size: 12px;
        font-weight: 300;
        line-height: 1.33;
      }
    }
  }
}
</style>
