<template>
  <div>
    <div v-if="editingMemo">
      <VeeForm
        ref="form"
        class="c-form"
        :validation-schema="schema"
        :initial-values="
          (initialValues = {
            memo: updatedMemo,
          })
        "
        @submit="saveMemo"
      >
        <TextareaGroup
          v-if="editingMemo"
          v-model="updatedMemo"
          data-cy="memo"
          name="memo"
          rows="1"
          cols="50"
        />
        <DSButton
          label="Save"
          type="submit"
          class="p-button-link"
          :class="$style.link"
        />
      </VeeForm>
    </div>
    <div v-else>
      <div>
        {{ updatedMemo }}
      </div>
      <DSButton
        label="Edit"
        class="p-button-link"
        :class="$style.link"
        @click="editMemo"
      />
    </div>
  </div>
</template>

<script>
import { Form as VeeForm } from 'vee-validate';
import { string, object } from 'yup';
import DSButton from '@clearbanc/clear-components/button';
import TextareaGroup from '@clearbanc/clear-components/textareagroup';

export default {
  components: {
    DSButton,
    TextareaGroup,
    VeeForm,
  },
  props: {
    memo: {
      type: String,
    },
  },
  data() {
    return {
      editingMemo: false,
      updatedMemo: this.memo,
      schema: object({
        memo: string()
          .required('Payment reference/memo is required')
          .max(70)
          .matches(
            /^[a-zA-Z0-9 ]*$/,
            'Payment reference must contain alphanumerical characters only',
          ),
      }),
    };
  },
  methods: {
    async editMemo() {
      this.editingMemo = !this.editingMemo;
    },
    async saveMemo() {
      // will update selectedBill
      await this.$store.dispatchApiAction('UPDATE_BILL', {
        memo: this.updatedMemo,
      });
      this.editingMemo = !this.editingMemo;
    },
  },
};
</script>

<style lang="less" module>
button.link {
  display: inline;
  padding: 0;
  line-height: 26px;
}

.error {
  color: red;
  align-content: left;
  padding: 0;
  margin: 0;
}
</style>
