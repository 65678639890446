import { computed } from 'vue';
import { MILESTONE_STATUSES, MILESTONES } from '@/data/bill-status-timeline';
import { FAILURE_REASONS, PAYMENT_STATUSES } from '@/data/currency-cloud';
import {
  BillPaymentMethods,
  BillStatus,
  PAY_IN_PAY_OUT_STATUS,
} from '@/data/payments';

export default function (context, contextRoot) {
  const ctx = contextRoot.root;
  const { getters, state } = ctx.$store;

  const bills = computed(() => getters.bills);
  const isBusinessProfileComplete = computed(
    () => getters.isBusinessProfileComplete,
  );
  const isInFinancialDocsSegment = computed(
    () => getters.isInFinancialDocsSegment,
  );
  const maxCapacityExceeded = computed(() => getters.maxCapacityExceeded);
  const userIsDisqualified = computed(() => getters.userIsDisqualified);
  const salesAccountsConnected = computed(() => getters.salesAccountsConnected);
  const hasDisconnectedSalesAccounts = computed(
    () => getters.hasDisconnectedSalesAccounts,
  );
  const fiscalDocumentsUpToDate = computed(
    () => getters.fiscalDocumentsUpToDate,
  );
  const advances = computed(() => state.advances);

  /**
   * Returns various fields required for determining bill status.
   * Also returns fields related to the BillStatusTimeline component
   * @param billId bill id to gather status data for
   * @param billContract contract for bill
   */
  const billStatusData = (billId, billContract, billPayments) => {
    const selectedBill = computed(() =>
      bills.value.find((bill) => bill.id === billId),
    );
    const isReceipt = selectedBill.value?.isReceipt;
    const isCancelled = selectedBill.value.status === BillStatus.CANCELLED;
    const isLegacyPaymentMethod =
      selectedBill.value.paymentMethod !== BillPaymentMethods.BNPL;
    const isBillStatusCompleted = [
      BillStatus.COMPLETED,
      BillStatus.AUTO_DEBITING_ACTIVE,
    ].includes(selectedBill.value.status);

    const billCancelledAt = computed(() =>
      isCancelled
        ? advances.value[selectedBill.value.advanceId]?.rejectedAt
        : null,
    );

    const isDiligenceMilestoneInProgress = computed(() => {
      return (
        !selectedBill.value?.bnplDetails?.diligenceDeclinedAt &&
        !selectedBill.value?.bnplDetails?.diligenceApprovedAt
      );
    });

    const wasBillCancelledBeforeDiligenceMilestone = computed(() => {
      return (
        !!billCancelledAt.value &&
        (isDiligenceMilestoneInProgress.value ||
          billCancelledAt.value <
            selectedBill.value?.bnplDetails?.diligenceDeclinedAt ||
          billCancelledAt.value <
            selectedBill.value?.bnplDetails?.diligenceApprovedAt)
      );
    });

    const diligenceMilestoneStatus = computed(() => {
      let status;
      let label;

      if (wasBillCancelledBeforeDiligenceMilestone.value) {
        status = MILESTONE_STATUSES.NOT_STARTED;
        label = 'data.payments.billStatus.processing';
      } else if (
        !isReceipt &&
        !isLegacyPaymentMethod &&
        !selectedBill.value?.beneficiaryV2Id
      ) {
        status = MILESTONE_STATUSES.ACTION_REQUIRED_VENDOR_DETAILS;
        label = 'paymentsPage.actionRequired';
      } else if (
        !isBusinessProfileComplete.value &&
        (!billContract || billContract.userSignedAt)
      ) {
        status = MILESTONE_STATUSES.ACTION_REQUIRED_BIZ_PROFILE;
        label = 'paymentsPage.actionRequired';
      } else if (
        isInFinancialDocsSegment.value &&
        !fiscalDocumentsUpToDate.value &&
        (!billContract || billContract.userSignedAt)
      ) {
        status = MILESTONE_STATUSES.ACTION_REQUIRED_BANK_DOCUMENTS;
        label = 'paymentsPage.actionRequired';
      } else if (
        !salesAccountsConnected.value ||
        hasDisconnectedSalesAccounts.value
      ) {
        status = MILESTONE_STATUSES.ACTION_REQUIRED_VERIFY_REVENUE;
        label = 'paymentsPage.actionRequired';
      } else if (maxCapacityExceeded.value || userIsDisqualified.value) {
        status = MILESTONE_STATUSES.ACTION_REQUIRED_INSUFFICIENT_CAPACITY;
        label = 'Insufficient Funding Capacity';
      } else if (selectedBill.value?.bnplDetails?.diligenceDeclinedAt) {
        status = MILESTONE_STATUSES.FAILED;
        label = isReceipt
          ? 'paymentsPage.cancelled'
          : 'paymentsPage.invoiceDeclined';
      } else if (
        isLegacyPaymentMethod ||
        selectedBill.value?.bnplDetails?.diligenceApprovedAt
      ) {
        status = MILESTONE_STATUSES.COMPLETE;
        label = isReceipt
          ? 'paymentsPage.receiptApproved'
          : 'paymentsPage.invoiceApproved';
      } else {
        status = MILESTONE_STATUSES.PROCESSING;
        label = isReceipt
          ? 'paymentsPage.receiptInReview'
          : 'paymentsPage.invoiceInReview';
      }

      return {
        status,
        label,
      };
    });

    const contractSignatureMilestoneStatus = computed(() => {
      let status;
      let label;
      const completedStatus = {
        status: MILESTONE_STATUSES.COMPLETE,
        label: 'paymentsPage.agreementSigned',
      };
      const notStartedStatus = {
        status: MILESTONE_STATUSES.NOT_STARTED,
        label: 'paymentsPage.agreementSignaturePending',
      };

      // Legacy bill contracts were handled offline and therefore do not have entries in the contracts table
      if (isLegacyPaymentMethod && isCancelled) {
        return notStartedStatus;
      } else if (
        (isLegacyPaymentMethod &&
          diligenceMilestoneStatus.value.status ===
            MILESTONE_STATUSES.COMPLETE) ||
        isBillStatusCompleted
      ) {
        return completedStatus;
      }

      if (
        diligenceMilestoneStatus.value.status !== MILESTONE_STATUSES.COMPLETE ||
        (isCancelled && !wasBillCancelledBeforeDiligenceMilestone.value)
      ) {
        status = notStartedStatus.status;
        label = notStartedStatus.label;
      } else if (!billContract) {
        status = MILESTONE_STATUSES.PROCESSING;
        label = 'paymentsPage.agreementSignaturePending';
      } else if (!billContract.userSignedAt) {
        status = MILESTONE_STATUSES.ACTION_REQUIRED_SIGN_CONTRACT;
        label = 'paymentsPage.actionRequired';
      } else {
        status = completedStatus.status;
        label = completedStatus.label;
      }

      return {
        status,
        label,
      };
    });

    let mostRecentPayment = null;
    let mostRecentDeclinedPayment = null;

    const paidMilestoneStatus = computed(() => {
      let status;
      let label;
      const isPayOutStatusCompleted =
        selectedBill.value?.payOutStatus ===
          PAY_IN_PAY_OUT_STATUS.PAYMENT_COMPLETED ||
        selectedBill.value?.payOutStatus === BillStatus.COMPLETED;
      const completedStatus = {
        status: MILESTONE_STATUSES.COMPLETE,
        label: 'paymentsPage.sent',
      };

      // Legacy payments were handled offline and don't have payments set up in Currency Cloud
      if (
        (!billPayments || !billPayments.length) &&
        isBillStatusCompleted &&
        isPayOutStatusCompleted
      ) {
        return completedStatus;
      }

      if (
        contractSignatureMilestoneStatus.value.status !==
        MILESTONE_STATUSES.COMPLETE
      ) {
        status = MILESTONE_STATUSES.NOT_STARTED;
        label = 'paymentsPage.paid';
      } else if (billPayments && billPayments.length) {
        const declinedPayments = billPayments.filter(
          (payment) =>
            payment.status === PAYMENT_STATUSES.FAILED &&
            !FAILURE_REASONS.COMPLIANCE.find((reason) =>
              payment.failureReason.includes(reason),
            ),
        );
        mostRecentPayment = billPayments.reduce((a, b) =>
          new Date(a.createdAt) > new Date(b.createdAt) ? a : b,
        );
        mostRecentDeclinedPayment = declinedPayments.length
          ? declinedPayments.reduce((a, b) =>
              new Date(a.createdAt) > new Date(b.createdAt) ? a : b,
            )
          : null;

        if (
          declinedPayments.length &&
          mostRecentDeclinedPayment.id === mostRecentPayment.id
        ) {
          status = MILESTONE_STATUSES.FAILED;
          label = 'paymentsPage.declined';
        } else if (mostRecentPayment.status === PAYMENT_STATUSES.COMPLETED) {
          status = completedStatus.status;
          label = completedStatus.label;
        } else if (
          mostRecentPayment.status === PAYMENT_STATUSES.RELEASED ||
          mostRecentPayment.status === PAYMENT_STATUSES.SUBMITTED
        ) {
          status = MILESTONE_STATUSES.PROCESSING;
          label = 'paymentsPage.scheduled';
        } else {
          status = MILESTONE_STATUSES.PROCESSING;
          label = 'paymentsPage.processing';
        }
      } else {
        status = MILESTONE_STATUSES.PROCESSING;
        label = 'paymentsPage.processing';
      }
      return {
        status,
        label,
      };
    });

    const milestoneStatuses = {
      [MILESTONES.INVOICE_SUBMITTED]: {
        name: MILESTONES.INVOICE_SUBMITTED,
        status: MILESTONE_STATUSES.COMPLETE,
        label: isReceipt
          ? 'paymentsPage.receiptSubmitted'
          : 'paymentsPage.invoiceSubmitted',
      },
      [MILESTONES.DILIGENCE]: {
        name: MILESTONES.DILIGENCE,
        status: diligenceMilestoneStatus.value.status,
        label: diligenceMilestoneStatus.value.label,
      },
      [MILESTONES.SIGN_CONTRACT]: {
        name: MILESTONES.SIGN_CONTRACT,
        status: contractSignatureMilestoneStatus.value.status,
        label: contractSignatureMilestoneStatus.value.label,
      },
      [MILESTONES.PAID]: {
        name: MILESTONES.PAID,
        status: paidMilestoneStatus.value.status,
        label: paidMilestoneStatus.value.label,
      },
    };

    const orderedMilestoneStatuses = [
      milestoneStatuses[MILESTONES.INVOICE_SUBMITTED],
      milestoneStatuses[MILESTONES.DILIGENCE],
      milestoneStatuses[MILESTONES.SIGN_CONTRACT],
      milestoneStatuses[MILESTONES.PAID],
    ];

    if (isCancelled) {
      //   insert 'cancelled' milestone either before or after the diligence milestone since
      //   user cannot cancel bill after the contract has been signed
      orderedMilestoneStatuses.splice(
        wasBillCancelledBeforeDiligenceMilestone.value ? 1 : 2,
        0,
        {
          name: MILESTONES.CANCELLED,
          status: MILESTONE_STATUSES.FAILED,
          label: 'data.payments.billStatus.cancelled',
        },
      );
    }

    const milestoneInProgress = orderedMilestoneStatuses
      .slice()
      .reverse()
      .find((milestone) => milestone.status !== MILESTONE_STATUSES.NOT_STARTED);

    return {
      billStatus: milestoneInProgress.status,
      billCancelledAt: billCancelledAt.value,
      isDiligenceMilestoneInProgress,
      wasBillCancelledBeforeDiligenceMilestone:
        wasBillCancelledBeforeDiligenceMilestone.value,
      milestoneStatuses,
      milestoneInProgress,
      mostRecentPayment,
      mostRecentDeclinedPayment,
    };
  };

  return {
    billStatusData,
  };
}
