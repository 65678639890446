<template>
  <div :class="classes">
    <div v-if="!!infoMsg" :class="$style.info">{{ infoMsg }}</div>
    <input
      v-if="computedType !== 'date'"
      ref="input"
      :type="computedType"
      :name="name"
      :data-cy="modelValue"
      :value="computedValue"
      :placeholder="placeholder"
      :tabindex="disabled ? '-1' : '1'"
      :disabled="disabled"
      :required="required"
      :autocomplete="autofill"
      @blur="onBlur"
      @input="onInput"
      @focus="onFocus"
    />
    <date-selector
      v-else
      v-model="dateModel"
      :apply-old-design="true"
      :name="'date-' + name"
      :placeholder="placeholder"
      :disabled="disabled"
      :required="required"
      @blur="onBlur"
      @input="onInput"
      @focus="onFocus"
      @calendarOpened="toggleCalendar($event, 'open')"
      @calendarClosed="toggleCalendar($event, 'close')"
    />
    <label
      :for="name"
      @click="$refs.input.focus()"
      @keyup.enter="$refs.input.focus()"
    >
      {{ required ? `${label}*` : label }}
      <ui-tooltip v-if="tooltipMsg" :text="tooltipMsg" />
    </label>
    <div v-if="error || (isTouched && !isValid)" :class="$style.message">
      {{ validationErrorMsg() || errorMsg }}
    </div>
    <span
      v-if="type === 'password'"
      :class="$style['password-msg']"
      @click="togglePasswordVisibility"
      >{{ isPasswordVisible ? 'HIDE' : 'SHOW' }}</span
    >
  </div>
</template>

<script>
import { UiTooltip } from 'ui-components';
import { isValidPostalCode } from '@/utils/local';
import DateSelector from '@/components/DateSelector';
import moment from 'moment';
import { inputValidationSetup } from '@/composables/validation';
import { inject } from 'vue';

export default {
  emits: ['focus', 'onBlur', 'input', 'update:modelValue'],
  components: { UiTooltip, DateSelector },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    newDesign: Boolean,
    disabled: Boolean,
    required: Boolean,
    name: String,
    label: String,
    error: Boolean,
    errorMsg: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('common.requiredField');
      },
    },
    infoMsg: String,
    tooltipMsg: String,
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: String,
    autofill: { type: String, default: 'on' },
  },
  setup(props) {
    const { isValid, isTouched } = inputValidationSetup(props);
    return {
      isValid,
      isTouched,
    };
  },
  data() {
    return {
      isPasswordVisible: false,
      validationErrorMsg: () => null,
    };
  },
  watch: {
    isTouched() {
      this.validate();
    },
  },
  computed: {
    classes() {
      return {
        [this.$style['input-container']]: !this.newDesign,
        [this.$style['input-design']]: this.newDesign,
        [this.$style.disabled]: this.disabled,
        [this.$style.error]: this.error || (this.isTouched && !this.isValid),
        [this.$style.filled]: !!this.modelValue,
        [this.$style.password]: this.type === 'password',
      };
    },
    dateModel: {
      get() {
        if (!this.modelValue) return '';
        return moment.parseZone(this.modelValue).format('YYYY-MM-DD');
      },
      set(value) {
        const date = moment.parseZone(value).format('YYYY-MM-DD');
        this.validate(date);
        this.$emit('input', date, this.name);
        this.$emit('update:modelValue', date);
      },
    },
    computedType() {
      if (this.type === 'password') {
        return this.isPasswordVisible ? 'text' : 'password';
      }

      return this.type;
    },
    computedValue() {
      return this.modelValue;
    },
  },
  mounted() {
    if (!this.required) {
      this.isValid = true;
    }
  },
  methods: {
    onInput(e) {
      if (this.type === 'phone') {
        // eslint-disable-next-line no-param-reassign
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
      }
      this.validate(e.target.value);
      this.$emit('input', e.target.value, this.name);
      this.$emit('update:modelValue', e.target.value);
    },
    onBlur(e) {
      this.validate();
      this.$emit('onBlur', e.target.name);
    },
    onFocus() {
      this.$emit('focus');
    },
    toggleCalendar(e, action) {
      if (action === 'open') {
        this.showLegend = true;
      }
      if (action === 'close') {
        this.showLegend = !!e;
      }
    },
    validate(val) {
      const value = val || this.modelValue;

      if (!this.required && !value) return;

      // empty field validation
      if (this.required) {
        this.isValid = !!value;
        this.validationErrorMsg = () =>
          this.errorMsg || this.$t('common.requiredField');
      } else {
        this.isValid = true;
      }

      // email validation
      if (this.type === 'email') {
        /* eslint-disable-next-line no-useless-escape */
        const validEmailRegex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.required) {
          this.isValid = !!validEmailRegex.test(value);
        } else {
          this.isValid =
            this.modelValue === '' || !!validEmailRegex.test(value);
        }
        this.validationErrorMsg = () => this.$t('common.invalidEmail');
      }

      // password validation
      if (this.type === 'password') {
        const hasDigit = value.match(/[0-9]/) ? 1 : 0;
        const hasUppercase = value.match(/[A-Z]/) ? 1 : 0;
        const hasLowercase = value.match(/[a-z]/) ? 1 : 0;
        const hasSpecialChar = value.match(/[^0-9A-Za-z]/) ? 1 : 0;

        if (value.length < 8) {
          this.isValid = false;
          this.validationErrorMsg = () => this.$t('common.passwordIsTooShort');
        } else if (
          hasDigit + hasUppercase + hasLowercase + hasSpecialChar <
          3
        ) {
          this.isValid = false;
          this.validationErrorMsg = () =>
            this.$t('common.passwordMustBeAtLeast');
        } else {
          this.isValid = true;
          this.validationErrorMsg = () => null;
        }
      }

      // integer validation
      if (this.type === 'integer') {
        const validNumberRegex = /^[0-9]{1,}$/;

        this.isValid = !!validNumberRegex.test(value);
        this.validationErrorMsg = () => this.$t('common.invalidNumber');
      }

      // postal code validation
      if (this.type === 'postalCode' && !isValidPostalCode(value)) {
        this.isValid = false;
        this.validationErrorMsg = () =>
          this.$t('common.address.invalidPostalCode');
      }

      // url validation - ensure input is in https://<domain_name>.<tld>
      if (this.type === 'url') {
        const urlRegex =
          /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        if (this.required) this.isValid = !!urlRegex.test(value);
        else this.isValid = this.modelValue === '' || !!urlRegex.test(value);
        this.validationErrorMsg = () => this.$t('common.invalidUrl');
      }
      // website validation - accepts any website format; https://<domain_name>.<tld>, www.<domain_name>.<tld>, <domain_name>.<tld>, etc...
      // When making changes to this regex note that some product segment signup flows use ui-components repo components which has duplicated logic.
      // See repo clearbanc/ui-components ui-input.vue: https://github.com/clearbanc/ui-components/blob/staging/src/components/ui-input.vue#L153.
      if (this.type === 'website') {
        // Regex expression from :  https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
        const websiteRegex =
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        // check for too many w's. risk of 4 consecutive w's being legitimate deemed to be low.
        const tooManyWsRegex = /wwww/;
        if (this.required) {
          this.isValid =
            !!websiteRegex.test(value) && !tooManyWsRegex.test(value);
        } else {
          this.isValid = this.modelValue === '' || !!websiteRegex.test(value);
        }
        this.validationErrorMsg = () => this.$t('common.invalidWebsite');
      }
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
};
</script>

<style lang="less" module>
.input-container {
  display: block;
  position: relative;
  font-family: 'Montserrat';
  font-weight: 400;
  color: @color-black-new;
  vertical-align: top;
  min-height: 70px;
  max-width: 400px;
  flex: 1;

  &.error {
    color: @color-red-new;
  }

  &.disabled {
    color: @color-gray;
    pointer-events: none;
    cursor: not-allowed;
  }

  input {
    font-size: @font-size-15;
    box-shadow: inset 0 0 0 1px currentColor;
    border: 0;
    border-radius: 3px;
    padding: @gutter-10;
    color: currentColor;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    line-height: @font-size-15;
    min-width: 180px;
    -webkit-appearance: none;

    .password& {
      padding: 10px 50px 10px 10px;
    }

    .error& {
      background: @color-light-red-new;
    }

    &:focus {
      outline: 0;
      font-weight: 500;
      box-shadow: inset 0 0 0 1.5px currentColor;
      background: @color-white;

      & + label {
        font-weight: 600;
      }
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  label {
    position: absolute;
    top: -@gutter-15;
    left: 0;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 10px;
    line-height: @font-size-12;
    text-transform: uppercase;
    letter-spacing: 1px;
    vertical-align: top;
    color: @color-black;
  }

  .info {
    font-size: @font-size-10;
    line-height: @font-size-10;
    margin-bottom: 2px;
    font-weight: 400;
    text-align: left;
    color: @color-black !important;
  }

  .message {
    font-size: @font-size-10;
    margin-left: @gutter-10;
    line-height: @font-size-12;
    font-weight: 500;
    text-align: left;
  }

  .password-msg {
    position: absolute;
    top: 11px;
    right: 15px;
    font-size: 10px;
    cursor: pointer;
  }
}

.input-design {
  display: block;
  position: relative;
  font-family: 'Montserrat';
  font-weight: 400;
  color: @color-black-new;
  vertical-align: top;
  min-height: 73px;
  flex: 1;

  @media only screen and (max-width: 840px) {
    width: 100%;
    margin: auto;
    padding: 0;
    text-align: center;
  }

  &.error {
    color: @color-red-new;
  }

  &.disabled {
    color: @color-gray;
    pointer-events: none;
    cursor: not-allowed;
  }

  input {
    font-size: 14px;
    border: 0;
    border-radius: 0;
    padding: 10px 25px;
    color: #4f5362;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    line-height: @font-size-15;
    min-width: 180px;
    -webkit-appearance: none;
    background-color: #eff0f4;

    .password& {
      padding: 10px 50px 10px 10px;
    }

    .error& {
      background: @color-light-red-new;
    }

    &:focus {
      outline: 0;
      font-weight: 500;
      box-shadow: 0 9px 18px rgba(168, 172, 185, 0.62);
      background: @color-white;

      & + label {
        font-weight: 600;
      }
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  label {
    position: absolute;
    top: -30px;
    left: 0;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    vertical-align: top;
    color: @color-black;
  }

  .info {
    font-size: @font-size-10;
    line-height: @font-size-10;
    margin-bottom: 2px;
    font-weight: 400;
    text-align: left;
    color: @color-black !important;
  }

  .message {
    font-size: @font-size-10;
    margin-left: @gutter-10;
    line-height: @font-size-12;
    font-weight: 500;
    text-align: left;
  }

  .password-msg {
    position: absolute;
    top: 11px;
    right: 15px;
    font-size: 10px;
    cursor: pointer;
  }
}
</style>
