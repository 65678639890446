<template>
  <div :class="classes">
    <span :class="$style.text"><slot /></span>
    <input
      type="checkbox"
      :checked="modelValue"
      :aria-label="name + ' checkbox'"
    />
    <span
      :data-cy="kebabCase(name)"
      :required="required"
      :class="$style.checkbox"
      :style="
        modelValue && newDesign == true ? 'background-color:#1D444B;' : ''
      "
      tabIndex="0"
      @click="onClick"
    >
      <ui-icon
        v-if="modelValue"
        name="checkmark"
        :color="newDesign ? '#fff' : ''"
        size="8"
      />
    </span>
    <div v-if="error || (isTouched && !isValid)" :class="$style['error-msg']">
      {{ validationErrorMsg || errorMsg }}
    </div>
  </div>
</template>

<script>
import { UiIcon } from 'ui-components';
import kebabCase from '@/utils/kebabcase';
import { inputValidationSetup } from '@/composables/validation';
import { inject } from 'vue';

export default {
  emits: ['input', 'update:modelValue'],
  components: {
    UiIcon,
  },
  props: {
    name: String,
    required: Boolean,
    disabled: Boolean,
    error: Boolean,
    errorMsg: {
      type: String,
      default() {
        const i18n = inject('i18n');
        return i18n.t('common.requiredField');
      },
    },
    modelValue: {},
    newDesign: Boolean,
  },
  setup(props) {
    const { isValid, isTouched } = inputValidationSetup(props);
    return {
      isValid,
      isTouched,
    };
  },
  data() {
    return {
      validationErrorMsg: '',
    };
  },
  computed: {
    classes() {
      return {
        [this.$style['checkbox-container']]: true,
        [this.$style.disabled]: this.disabled,
        [this.$style.error]: this.error || (this.isTouched && !this.isValid),
      };
    },
  },
  watch: {
    isTouched() {
      this.validate();
    },
  },
  mounted() {
    if (!this.required) {
      this.isValid = true;
    }
  },
  methods: {
    onClick(e) {
      this.isValid = this.required ? !this.modelValue : true;

      this.$emit('input', !this.modelValue, this.name);
      this.$emit('update:modelValue', !this.modelValue);
    },
    validate() {
      this.isValid = this.required ? !!this.modelValue : true;
    },
    kebabCase,
  },
};
</script>

<style lang="less" module>
.checkbox-container {
  position: relative;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: @color-gray-new;
  min-height: 20px;
  user-select: none;
  padding-left: 25px;
  text-align: left;
  clear: both;

  input[type='checkbox'] {
    display: none;
  }

  &.error {
    min-height: 40px;
  }

  &.disabled {
    color: @color-gray;
    pointer-events: none;
  }

  .text {
    display: inline-block;
    line-height: 15px !important;
  }

  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    box-shadow: inset 0 0 0 1px @color-black-new;
    background: @color-white;
    border-radius: 2px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: @color-black;
    line-height: 16px;
    padding-left: 1px;

    &:focus {
      outline: none;
    }

    .disabled& {
      color: @color-gray;
      box-shadow: inset 0 0 0 1px @color-gray;
    }
    @media only screen and (max-width: 670px) {
      height: 20px;
      width: 20px;
    }
  }

  .error-msg {
    font-size: @font-size-10;
    line-height: @font-size-15;
    font-weight: 500;
    text-align: left;
    color: @color-red-new;
  }

  @media only screen and (max-width: 670px) {
    margin-bottom: 10px;
    padding-top: 3px;
  }
}
</style>
