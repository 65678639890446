// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".flex-container_wY2Zc{display:flex;flex-wrap:wrap;justify-content:space-evenly}.flex-container_wY2Zc .flex-item_YFkvg{font-weight:700;text-align:center}.flex-container_wY2Zc .flex-item_YFkvg p{margin:0;padding:0}.baseline_efgO_{align-items:baseline}.flex-container_wY2Zc{padding:15px}.flex-container_wY2Zc .flex-item_YFkvg{width:125px}.flex-container_wY2Zc .flex-item_YFkvg p{font-size:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex-container": "flex-container_wY2Zc",
	"flex-item": "flex-item_YFkvg",
	"baseline": "baseline_efgO_"
};
export default ___CSS_LOADER_EXPORT___;
