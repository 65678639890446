<template>
  <div :class="$style.container">
    <quick-link v-for="link in links" :key="link.title()" :link="link" />
  </div>
</template>

<script>
import QuickLink from './QuickLinkWayfinding';

export default {
  components: {
    QuickLink,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="less" module>
.container {
  margin: 16px;
  background: @color-white;
  border-radius: @spacing-4;
}
</style>
