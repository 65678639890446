<template>
  <box
    data-cy="available-funds-box-lvl-2"
    elevated
    :class="$style['no-scroll-bar']"
  >
    <UiIconButton
      v-if="showBackButton"
      size="small"
      style="margin-bottom: 16px"
      @click="goBack"
    />
    <div :class="$style['advance-header']">
      {{
        $t('spendPage.advanceWithType', {
          advanceType: capitalize(advanceTypeText),
        })
      }}
    </div>
    <div
      :data-cy="`funds-remaining-${advanceType}`"
      :class="$style['advance-amount']"
    >
      {{ $filters.currency(fundsRemaining, getAdvanceCurrencyCode) }}
    </div>
    <template v-if="advanceType !== ADVANCE_SUBTYPES.INVENTORY">
      <WarningMessage
        v-if="!activeClearbancCard && fundsRemaining > 0"
        :title="$t('spendPage.noActiveCard.title')"
        style="padding-bottom: 12px"
      >
        <i18n-t keypath="spendPage.noActiveCard.paragraph">
          <template v-slot:linkText>
            <a
              :href="addLocaleParam('https://help.clear.co/s/')"
              :title="$t('spendPage.noActiveCard.linkText')"
              target="_blank"
            >
              {{ $t('spendPage.noActiveCard.linkText') }}
            </a>
          </template>
        </i18n-t>
      </WarningMessage>
      <UiButton
        :text="$t('spendPage.clearcoCard')"
        icon="card"
        full-width
        primary
        data-cy="clearco-card-desktop"
        :disabled="!activeClearbancCard || fundsRemaining === 0"
        @click="cardSpendCtaCallback"
      />
    </template>
    <hr :style="{ border: 'none' }" />
    <UiButton
      :text="$t('spendPage.payVendor')"
      icon="invoice"
      full-width
      data-cy="pay-vendor-desktop"
      :disabled="fundsRemaining === 0"
      @click="payInvoiceCtaCallback"
    />
    <hr :style="{ border: 'none' }" />
    <accordion
      v-if="fundsRemaining > 0"
      color="#215CB6"
      :title="
        $t('spendPage.howToSpend.question', { advanceType: advanceTypeText })
      "
      bold
    >
      <div :class="$style['accordion-content']">
        <template v-if="advanceType !== ADVANCE_SUBTYPES.INVENTORY">
          <p :class="$style['faq-para']">
            {{ $t('spendPage.howToSpend.multipleWays') }}:
          </p>

          <p :class="$style['faq-para']">
            <span>{{ $t('spendPage.clearcoCard') }}</span>
            {{ $t('spendPage.howToSpend.clearcoCard') }}
          </p>
        </template>

        <p :class="$style['faq-para']">
          <span>{{ $t('spendPage.payVendor') }}</span>
          {{ $t('spendPage.howToSpend.payVendor') }}
        </p>
      </div>
    </accordion>
  </box>
</template>

<script>
import { ADVANCE_SUBTYPES } from '@/data/advance-subtypes';
import { mapGetters } from 'vuex';
import {
  UiIconButton,
  Accordion,
  UiButton,
  Box,
} from '@clearbanc/design-components/src/components';
import { WAYFINDING_ROUTES } from '@/data/wayfinding';
import addLocaleParam from '@/utils/add-locale-param';
import WarningMessage from './WarningMessageFinancing';

export default {
  components: {
    UiIconButton,
    UiButton,
    Box,
    Accordion,
    WarningMessage,
  },
  props: {
    advanceType: {
      type: String,
      default: '',
    },
    fundsRemaining: {
      type: Number,
      default: 0,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ADVANCE_SUBTYPES,
    };
  },
  computed: {
    ...mapGetters(['getAdvanceCurrencyCode', 'activeClearbancCard']),
    advanceTypeText() {
      if (this.advanceType === this.ADVANCE_SUBTYPES.UNIFIED)
        return this.$t('data.allAdvanceTypes.marketing');
      return this.$t(`data.allAdvanceTypes.${this.advanceType}`);
    },
    useFundsHyperLink() {
      return this.advanceType === this.ADVANCE_SUBTYPES.INVENTORY
        ? this.addLocaleParam(
            'https://help.clear.co/s/article/Understanding-the-Vendor-Payment-Process',
          )
        : this.addLocaleParam(
            'https://help.clear.co/s/article/Using-Your-Funding',
          );
    },
  },
  methods: {
    addLocaleParam,
    goBack() {
      this.$emit('back');
    },
    cardSpendCtaCallback() {
      this.$router.push({ name: WAYFINDING_ROUTES.CARD });
    },
    payInvoiceCtaCallback() {
      this.$router.push({ name: WAYFINDING_ROUTES.VENDOR_LIST });
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="less" module>
.no-scroll-bar {
  scrollbar-width: none;
}

.advance-amount {
  font-family: @gerstner-font;
  color: @black;
  font-size: @font-size-36;
  font-weight: normal;
  line-height: @spacing-48;
  margin-bottom: @spacing-16;
}

.advance-header {
  font-family: @gerstner-font;
  font-size: @font-size-16;
  line-height: @spacing-22;
  color: @color-grey-400;
  font-weight: bold;
  margin-bottom: @spacing-4;
}

.accordion-content {
  padding-left: @spacing-16;
}

.faq-para {
  font-family: @gerstner-font;
  font-size: @font-size-14;
  line-height: @spacing-20;
  color: @black;
}

.faq-para > span {
  font-weight: bold;
  display: block;
}

.faq-footer {
  font-family: @gerstner-font;
  font-size: @font-size-14;
  line-height: @spacing-20;
  color: @color-grey-400;
  margin-bottom: 0;
}
</style>
