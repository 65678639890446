<template>
  <UiModal
    ref="vendorDetailsPopup"
    :class="$style['custom-modal']"
    @close="close"
  >
    <div :class="$style.title">
      {{
        selectedVendor.id
          ? $t('vendorsPage.vendorModal.edit', { vendor: selectedVendor.label })
          : $t('vendorsPage.vendorModal.add')
      }}
    </div>
    <div :class="$style.subtitle">
      {{ $t('vendorsPage.vendorModal.note') }}
    </div>
    <UiSteps :total="5" :active="step" :class="$style.steps" />
    <UiContainer :class="$style.container" size="large">
      <!-- Vendor Info -->
      <VendorInfo v-if="step === 0" @next-tab="next" />
      <!-- Beneficiary & Bank Currencies -->
      <VendorCurrency v-if="step === 1" @next-tab="next" @prev-tab="prev" />
      <!-- Beneficiary Address -->
      <VendorAddress
        v-if="step === 2"
        :required-vendor-fields-with-validation="
          requiredVendorFieldsWithValidation
        "
        @next-tab="next"
        @prev-tab="prev"
      />
      <!-- Bank Account Information -->
      <BankInfo
        v-if="step === 3"
        :required-vendor-fields-with-validation="
          requiredVendorFieldsWithValidation
        "
        @next-tab="next"
        @prev-tab="prev"
      />
      <!-- Summary -->
      <VendorSummary v-if="step === 4" @prev-tab="prev" @next-tab="next" />
      <!-- Attach Invoice -->
      <VendorInvoice v-if="step === 5" @next-tab="next" />
    </UiContainer>
  </UiModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateChildren } from '@/composables/validation';
import { UiModal, UiSteps, UiContainer } from 'ui-components';

import VendorInfo from './vendor-info';
import BankInfo from './bank-info';
import VendorCurrency from './vendor-currency';
import VendorAddress from './vendor-address';
import VendorSummary from './vendor-summary';
import VendorInvoice from './vendor-invoice';

export default {
  components: {
    UiModal,
    UiSteps,
    UiContainer,
    VendorInfo,
    BankInfo,
    VendorCurrency,
    VendorAddress,
    VendorSummary,
    VendorInvoice,
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      step: 0,
    };
  },
  computed: {
    ...mapGetters(['requiredVendorFieldsWithValidation', 'selectedVendor']),
  },
  methods: {
    open(step) {
      if (step) this.step = step;
      this.$refs.vendorDetailsPopup.open();
    },
    close() {
      this.step = 0;
      this.$store.dispatch('CLEAR_SELECTED_VENDOR');
      this.$emit('done');
    },
    async next() {
      const error = await this.hasError();
      if (error) {
        return;
      }
      if (this.step === 5) {
        this.$refs.vendorDetailsPopup.close();
        this.close();
        return;
      }
      this.step++;
    },
    prev() {
      if (this.step) this.step--;
    },
  },
};
</script>
<style lang="less" module>
.container {
  padding: 0;
}
.steps {
  bottom: auto;
}
.title {
  color: currentColor;
  font-size: 45px;
  line-height: 45px;
  text-align: left;
  padding-bottom: 10px;
}
.subtitle {
  color: gray;
  font-size: 12px;
  text-align: left;
}
.custom-modal {
  div[class^='modal_'] {
    min-height: 400px;
    max-height: 80%;
    height: auto;
    min-width: 300px;
    max-width: 750px;
    width: 80%;
    overflow: auto;
    div[class^='content_'] {
      margin: 0;
      max-width: 100%;
      div[class^='container_'] {
        min-height: 250px;
        height: fit-content;
        max-height: 100%;
        margin-bottom: 100px;
      }
    }
  }
}
</style>
