<template>
  <div />
</template>

<script>
import { mapGetters } from 'vuex';
import analytics from '@/utils/analytics';
import { useContextRoot } from '@/utils/context-root';
import reroutingUtils from '@/composables/rerouting';
import { useAuth0 } from '@auth0/auth0-vue';
import { auth0 } from '@/utils/auth0';
import { fetchUserData } from '@/store/actions';

export default {
  setup(props, context) {
    const { rerouteUser } = reroutingUtils(useContextRoot());
    const { user } = useAuth0();
    const { loginsCount } = user.value;

    return {
      rerouteUser,
      loginsCount,
    };
  },
  computed: {
    ...mapGetters(['user', 'getAllEnabledExperiments']),
  },
  async mounted() {
    this.rerouteUser();
    await auth0.getAccessTokenSilently();
    const userId = auth0?.user?.value?.userId;
    await fetchUserData(this.$store, userId);
  },
  beforeUnmount() {
    analytics.identify(this.user.id, {
      enabledExperiments: this.getAllEnabledExperiments,
      email: this.user.email,
    });
  },
};
</script>
