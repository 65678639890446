<template>
  <div :class="$style.wrapper">
    <ul :class="$style.list">
      <li v-for="(item, index) in whatsNew" :class="$style.list__item">
        <div :class="$style.list__item__content">
          <div :class="$style.list__item__content__index">
            {{ addLeadingZero(index) }}
          </div>
          {{ item }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      whatsNew: [
        'A new financial details step was added so you can provide disclosure of your debt and other payment obligations.',
        'Financial statements have moved from the bank accounts step into the financial details step.',
      ],
    };
  },
  methods: {
    addLeadingZero(index) {
      return `0${index + 1}`.slice(-2);
    },
  },
};
</script>

<style lang="less" module>
.wrapper {
  background: linear-gradient(0deg, rgba(97, 47, 255, 0.08) 25%, #fff 100%);
  border: 1px solid #caa8ff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.list {
  counter-reset: count;
}

.list__item {
  padding: 2rem;
  counter-increment: count;
}

.list__item + .list__item {
  border-top: 1px dashed #caa8ff;
}

.list__item__content {
  position: relative;
  padding-left: 40px;
}

.list__item__content__index {
  position: absolute;
  top: 0;
  left: 0;
  content: counter(count, decimal-leading-zero);
  font-weight: bold;
  color: #732cf8;
}
</style>
