// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_wkMWh{padding:0;margin:1rem auto 0;min-height:unset}.container_wkMWh h2,.container_wkMWh h3,.container_wkMWh h4{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif}@media only screen and (max-width:500px){.container_wkMWh{text-align:left}}.clarify-wrapper_yNbiH{margin:20px 0 40px}.subheading_tRtyp{text-align:center;background:rgba(0,0,0,.05);border-radius:4px;padding:10px 20px;font-size:14px}.subheading__item_l9uNL+.subheading__item_l9uNL{border-top:1px solid rgba(0,0,0,.2);padding-top:6px;margin-top:6px}.subheading__item_l9uNL span{color:#7f30ff}.bnpl-waitlist_DSI4X{font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif}.bnpl-waitlist_DSI4X p{color:#000;font-weight:300;font-size:1rem;line-height:24px;margin:0 0 30px}.cancellation-banner_kM3ek{margin:20px 0 0;max-width:465px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_wkMWh",
	"clarify-wrapper": "clarify-wrapper_yNbiH",
	"subheading": "subheading_tRtyp",
	"subheading__item": "subheading__item_l9uNL",
	"bnpl-waitlist": "bnpl-waitlist_DSI4X",
	"cancellation-banner": "cancellation-banner_kM3ek"
};
export default ___CSS_LOADER_EXPORT___;
