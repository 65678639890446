<script setup>
import UserInviteMessage from '@/components/UserInviteMessage';
import PictogramFileCheck from '@/assets/pictograms/file-check.svg';
</script>

<template>
  <UserInviteMessage>
    <template #image>
      <div v-html="PictogramFileCheck"></div>
    </template>
    <template #title>Profile Submitted</template>
    <template #description>
      Your profile is now saved in your company’s Clearco account.
      <br />You can close this page now.
    </template>
  </UserInviteMessage>
</template>
