<script setup>
import { ref, onMounted, readonly, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import FormOwners from '@/components/forms/FormOwners';
import SectionHeader from '@/components/SectionHeader';

// -----
// Setup
// -----

const store = useStore();
const router = useRouter();
const route = useRoute();

// ---------
// Variables
// ---------

const inviteDetails = ref(null);
const requestStatuses = readonly({
  verifyUserInvite: computed(() =>
    store.getters.requestStatus('VERIFY_USER_INVITE'),
  ),
});

// ------------
// Misc Methods
// ------------

async function validateUUID() {
  inviteDetails.value = await store.dispatchApiAction('VERIFY_USER_INVITE', {
    uuid: route.params.uuid,
  });

  if (requestStatuses.verifyUserInvite.isError) {
    router.push({ name: 'user-invite-error' });
  }
}

// ---------
// Lifecycle
// ---------

onMounted(() => {
  validateUUID();
});
</script>

<template>
  <div v-if="inviteDetails" :class="$style['verify-invite']">
    <SectionHeader
      :title="$t('data.onboarding.steps.ownersStepOne.title')"
      subtitle="Enter your information below, so we can verify your identity."
    />
    <FormOwners
      remove-container
      is-external-form
      :selected-owner="{
        id: inviteDetails.invitedUser.id,
        email: inviteDetails.invitedUser.email,
        firstName: inviteDetails.invitedUser.firstName,
        lastName: inviteDetails.invitedUser.lastName,
        jobRoles: inviteDetails.invitedUser.jobRoles,
        jobTitle: inviteDetails.invitedUser.jobTitle,
        uuid: route.params.uuid,
      }"
    />
  </div>
</template>

<style module>
.verify-invite [class^='c-group__header__title'] {
  font-weight: 700;
  font-size: 21px;
}
</style>
