<template>
  <button
    :class="{
      [$style.button]: true,
      [$style.disabled]: disabled,
      'p-15': !textLine2,
      'p-y-7': textLine2,
    }"
    :disabled="disabled"
    @click.stop="disabled ? null : $emit('click')"
  >
    <ui-icon v-if="icon" :name="icon" :size="computedIconSize" />
    <div v-if="text">
      <p
        :class="{
          [$style['button-text']]: true,
          [$style['button-text-2-lines']]: textLine2,
        }"
      >
        {{ text }}
      </p>
      <p v-if="textLine2" :class="$style['button-text-2-lines']">
        {{ textLine2 }}
      </p>
    </div>
  </button>
</template>

<script>
import { UiIcon } from 'ui-components';

export default {
  emits: ['click'],
  components: { UiIcon },
  props: {
    backgroundColor: String,
    disabled: { type: Boolean, default: false },
    icon: String,
    size: {
      type: String,
      default: 'tiny',
    },
    text: String,
    textLine2: String,
  },
  computed: {
    computedIconSize() {
      const sizes = {
        tiny: 10,
        small: 41,
        large: 58,
      };
      if (typeof this.iconSize === 'number' && this.iconSize < sizes[this.size])
        return this.iconSize;
      const size =
        this.iconSize && sizes[this.iconSize] < sizes[this.size]
          ? this.iconSize
          : this.size;
      return sizes[size];
    },
  },
};
</script>

<style lang="less" module>
.button {
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
  min-width: 175px;
  text-align: center;
  .button-text {
    display: inline-block;
    line-height: 0.8;
    margin: auto 0;
    position: relative;
    top: 1px;
  }
  .button-text-2-lines {
    line-height: 0.75;
    margin: 0 @gutter-50;
    position: relative;
  }
  &.disabled {
    background-color: @color-lightgray-500;
    cursor: default;
  }
}
</style>
