<template>
  <span>
    <ext-account
      title="cratejoy"
      :logo="cratejoy"
      small-logo
      :success="success"
      :disconnected="disconnected"
      :error="errors.cratejoy"
      logo-alt="CrateJoy logo"
      @click="openModal"
    />

    <ModalCratejoy
      ref="cratejoyModal"
      @error="$emit('error', SALES_PLATFORMS.CRATEJOY)"
      @oauthError="(payload) => $emit('oauthError', payload)"
      @success="$emit('success', SALES_PLATFORMS.CRATEJOY)"
      @modalAction="
        (platformName, action) => $emit('modalAction', platformName, action)
      "
    />
  </span>
</template>

<script>
import { SALES_PLATFORMS } from '@/data/platforms';
import ExtAccount from '@/components/modals/ModalExternalAccountOnboarding2';
import ModalCratejoy from '@/components/modals/ModalCreatejoy';

export default {
  components: {
    ExtAccount,
    ModalCratejoy,
  },
  props: {
    success: Boolean,
    disconnected: Boolean,
    errors: Object,
  },
  computed: {
    cratejoy: () => require('@/assets/logos/cratejoy.png'),
    SALES_PLATFORMS: () => SALES_PLATFORMS,
  },
  methods: {
    openModal() {
      this.$emit('tileClick', SALES_PLATFORMS.SHOPIFY);
      this.$refs.cratejoyModal.open();
    },
  },
};
</script>
