import { buildApiActions } from '@/utils/vuex-api-utils';

export default {
  state: {
    url: null,
    status: null,
    errorMsg: '',
  },
  getters: {
    clearcashOnboardingUrl: (state) => state.url,
    clearcashOnboardingStatus: (state) => state.status,
    clearcashOnboardingErrorMsg: (state) => state.errorMsg,
  },
  ...buildApiActions(
    {
      GET_CLEARCASH_ONBOARDING_STATUS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/users/${payload.userId}/clearcash-onboarding-status`,
        }),
        mutation: (state, { response }) => {
          state.status = response.status;
        },
      },
      SAVE_CLEARCASH_ONBOARDING_DATA: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/users/${payload.userId}/clearcash-onboarding-data`,
        }),
        mutation: (state, { response }) => {
          state.url = response.onboardingUrl;
        },
      },
    },
    {
      mutations: {
        UPDATE_CLEARCASH_ERROR: (state, { text }) => {
          state.errorMsg = text;
        },
      },
    },
  ),
};
