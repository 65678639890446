<template>
  <ProgressBar
    mode="indeterminate"
    style="height: 0.5em"
    aria-label="Loading progress"
  />
</template>

<script>
import ProgressBar from '@clearbanc/clear-components/progressbar';

export default {
  components: {
    ProgressBar,
  },
};
</script>
