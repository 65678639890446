<template>
  <button
    :class="classes"
    :data-cy="kebabCase(text + ' button')"
    :disabled="disabled"
    @click.stop="emitClick"
    @mouseover="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <ui-icon v-if="icon" class="m-r-7" :name="icon" :size="iconSize" />

    <span v-if="text">{{ text }}</span>
    <div v-if="$slots.tooltip && isTooltipVisible" :class="$style.tooltip">
      <slot name="tooltip" />
    </div>
  </button>
</template>

<script>
import { UiIcon } from 'ui-components';
import kebabCase from '@/utils/kebabcase';

export default {
  emits: ['click'],
  components: { UiIcon },
  props: {
    text: String,
    icon: String,
    iconSize: {
      type: Number,
      default: 41,
    },
    small: Boolean,
    tiny: Boolean,
    grey: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTooltipVisible: false,
    };
  },
  computed: {
    classes() {
      return {
        [this.$style.button]: true,
        [this.$style.small]: this.small,
        [this.$style.tiny]: this.tiny,
        [this.$style.grey]: this.grey,
        [this.$style.disabled]: this.disabled,
      };
    },
  },
  methods: {
    emitClick() {
      this.$emit('click');
    },
    kebabCase,
    onMouseEnter() {
      if (this.$slots.tooltip) {
        this.isTooltipVisible = true;
      }
    },
    onMouseLeave() {
      if (this.$slots.tooltip) {
        this.isTooltipVisible = false;
      }
    },
  },
};
</script>

<style lang="less" module>
.button {
  position: relative;
  display: inline-block;
  font-family: 'Montserrat Next';
  font-weight: 600;
  font-size: 14px;
  line-height: 51px;
  background: @color-green-dark;
  color: @color-white;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  transition: all 200ms ease;
  cursor: pointer;
  padding: 0 @gutter-25;
  border: 0;
  height: 50px;

  &.small {
    font-size: 12px;
    line-height: 37px;
    padding: 0 @gutter-15;
    height: 36px;
  }

  &.tiny {
    font-size: 10px;
    font-weight: 500;
    line-height: 31px;
    padding: 0 @gutter-15;
    height: 30px;
  }

  &.grey {
    background: @color-lightgray-200;
    color: @color-black;
  }

  &.disabled {
    background: @color-lightgray-300;
    color: @color-gray;
    pointer-events: none;
    cursor: default;
  }

  &:hover {
    background: @color-black;
    color: @color-white;
  }

  &:focus {
    outline: none;
    background: @color-black;
  }

  .tooltip {
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    color: @color-black-new;
    background: @color-lightgray-100;
    border: 1px solid @color-white;
    line-height: 16px;
    text-transform: none;
    padding: 10px 15px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}
</style>
