<template>
  <div />
</template>

<script>
import loadExternalScriptsMixin from '@/mixins/load-external-scripts';

export default {
  mixins: [loadExternalScriptsMixin],
  externalScripts: [
    'https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.3/jquery.min.js',
    'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
  ],
  props: {
    plaidEnv: {
      type: String,
    },
    token: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    openWidget(linkToken, redirectURI) {
      this.widget = window.Plaid.create({
        env: this.plaidEnv,
        token: linkToken,
        receivedRedirectUri: redirectURI,
        linkCustomizationName: 'account_select',
        onLoad: () => {
          this.$emit('widgetLoaded');
        },
        onSuccess: (token, metadata) => {
          this.$emit('success', { token, metadata });
        },
        onExit: (err, metadata) => {
          // if particular errors ever need to be addressed please refer to https://plaid.com/docs/errors/oauth/
          if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
            // handle invalid link token
          }

          this.$emit('exit', { err, metadata });
        },
        onEvent: (eventName, metadata) => {
          if (eventName === 'ERROR') {
            this.$emit('error', metadata);
          } else {
            this.$emit('flowEvent', { eventName, metadata });
          }
        },
      });
      this.widget.open();
    },
  },
};
</script>
