<template>
  <div :class="classes">
    <slot :class="$style.container" />
  </div>
</template>

<script>
export default {
  computed: {
    classes() {
      return {
        [this.$style.container]: true,
      };
    },
  },
};
</script>

<style lang="less" module>
.container {
  text-align: left;
  max-width: 560px;
  margin: 0 auto;
  ol {
    margin-top: 0;
    li {
      padding-left: 5px;
    }
  }
  a {
    text-decoration: underline;
    color: @link-blue;
    cursor: pointer;
  }
  span {
    font-weight: 500;
  }
  button {
    margin: 0 0 0 auto;
    display: block;
  }

  @media only screen and (max-width: 500px) {
    ol {
      padding-inline-start: 10px;
      margin-top: 0;
      margin-bottom: 25px;
    }
  }
}
</style>
