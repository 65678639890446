<template>
  <div :class="$style.container">
    <slot />
  </div>
</template>

<style lang="less" module>
.container {
  margin: @gutter-40 auto 55px;

  @media only screen and (max-width: 500px) {
    margin-bottom: 30px;
  }
}
</style>
