// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".link_NaiJF{padding:5px}.link_NaiJF a{text-decoration:none}.link_NaiJF svg{margin-right:5px;color:#297fe3}.link_NaiJF .rotate_peEqB{transform:rotate(90deg)}@media only screen and (max-width:500px){.link_NaiJF{text-align:left}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "link_NaiJF",
	"rotate": "rotate_peEqB"
};
export default ___CSS_LOADER_EXPORT___;
