// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_oO6PL{display:flex;flex-direction:column}.title_fejlH{margin-bottom:10px;font-family:Montserrat;font-size:21px;font-style:normal;font-weight:500;line-height:30px}.top-section_NmxfI{display:flex;align-items:flex-start;justify-content:space-between}@media screen and (max-width:500px){.top-section_NmxfI{flex-direction:column-reverse}}.input-container_gYOQ7{width:650px}@media screen and (max-width:700px){.input-container_gYOQ7{width:unset}}.input-container_gYOQ7 .file-list_RNHFt{margin-bottom:10px}@media screen and (max-width:500px){.input-container_gYOQ7 .file-list_RNHFt{width:100%;margin-bottom:20px}}.input-container_gYOQ7 .input-fields_f5_zn{margin-bottom:20px;max-height:40px}.input-container_gYOQ7 .address_PFS6d{margin-bottom:30px}.input-container_gYOQ7 .side-container_ACSS5{display:flex;flex-direction:row;justify-content:space-between}@media screen and (max-width:500px){.input-container_gYOQ7 .side-container_ACSS5.wrap_b2HMO{flex-flow:row wrap}}.input-container_gYOQ7 .side-container_ACSS5 .side-inputs_zrceL{height:40px;min-height:unset;margin-bottom:30px}.input-container_gYOQ7 .side-container_ACSS5 .side-inputs_zrceL:nth-child(2){margin-left:15px}@media screen and (max-width:500px){.input-container_gYOQ7 .side-container_ACSS5 .side-inputs_zrceL:nth-child(2).wrap-inputs_orofJ{margin-left:unset}}.button-container_GjRsP{margin-top:30px}.error-container_zUOpW{margin-bottom:10px;text-align:left}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_oO6PL",
	"title": "title_fejlH",
	"top-section": "top-section_NmxfI",
	"input-container": "input-container_gYOQ7",
	"file-list": "file-list_RNHFt",
	"input-fields": "input-fields_f5_zn",
	"address": "address_PFS6d",
	"side-container": "side-container_ACSS5",
	"wrap": "wrap_b2HMO",
	"side-inputs": "side-inputs_zrceL",
	"wrap-inputs": "wrap-inputs_orofJ",
	"button-container": "button-container_GjRsP",
	"error-container": "error-container_zUOpW"
};
export default ___CSS_LOADER_EXPORT___;
