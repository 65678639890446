import i18n from '@/plugins/i18n';

export const PERSONAL_TAX_ID_CONFIG = {
  US: {
    label: () => i18n.t('common.socialSecurityNumber'),
    shortLabel: () => i18n.t('common.ssn'),
    regex: /^\d{9}$/,
    instructions: () =>
      i18n.t('data.taxIdConfig.personalTaxIdConfig.usInstructions'),
  },
  CA: {
    label: () => i18n.t('common.socialInsuranceNumber'),
    shortLabel: () => i18n.t('common.sin'),
    regex: /^\d{9}$/,
    instructions: () => '',
  },
  GB: {
    label: () => i18n.t('common.nationalId'),
    shortLabel: () => i18n.t('common.nationalId'),
    regex: /\b[a-zA-Z0-9]{8}\b/,
    instructions: () => '',
  },
};

export const BUSINESS_TAX_ID_CONFIG = {
  US: {
    businessNameLabel: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.legalBusinessName'),
    businessNameInstructions: () =>
      i18n.t('common.shouldMatchIncorporationDocuments'),
    federalTaxIdLabel: i18n.t(
      'data.taxIdConfig.businessTaxIdConfig.employerIdentificationNumber',
    ),
    registrationNumberLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.businessRegistrationNumber',
      ),
    shortLabel: 'EIN',
    regex: /^\d{9}$/,
    stateLabels: {
      AK: i18n.t('data.taxIdConfig.stateLabels.alaskaEntity'),
      AL: i18n.t('data.taxIdConfig.stateLabels.alabamaEntityId'),
      AR: i18n.t('data.taxIdConfig.stateLabels.arkansasFiling'),
      AZ: i18n.t('data.taxIdConfig.stateLabels.azCorpCommissionFile'),
      CA: i18n.t('data.taxIdConfig.stateLabels.californiaCorp'),
      CO: i18n.t('data.taxIdConfig.stateLabels.coloradoId'),
      CT: i18n.t('data.taxIdConfig.stateLabels.connecticutBusinessId'),
      DE: i18n.t('data.taxIdConfig.stateLabels.delawareStateFile'),
      FL: i18n.t('data.taxIdConfig.stateLabels.floridaDocument'),
      GA: i18n.t('data.taxIdConfig.stateLabels.georgiaControl'),
      HI: i18n.t('data.taxIdConfig.stateLabels.hawaiiFile'),
      IA: i18n.t('data.taxIdConfig.stateLabels.iowaBusiness'),
      ID: i18n.t('data.taxIdConfig.stateLabels.idahoOrganizationalId'),
      IL: i18n.t('data.taxIdConfig.stateLabels.illinoisFile'),
      IN: i18n.t('data.taxIdConfig.stateLabels.indianaBusinessId'),
      KS: i18n.t('data.taxIdConfig.stateLabels.ksBusinessEntityId'),
      KY: i18n.t('data.taxIdConfig.stateLabels.kentuckyOrg'),
      LA: i18n.t('data.taxIdConfig.stateLabels.louisianaCharter'),
      MA: i18n.t('data.taxIdConfig.stateLabels.massachusettsId'),
      MD: i18n.t('data.taxIdConfig.stateLabels.marylandDepartmentId'),
      ME: i18n.t('data.taxIdConfig.stateLabels.maineCharter'),
      MI: i18n.t('data.taxIdConfig.stateLabels.michiganId'),
      MN: i18n.t('data.taxIdConfig.stateLabels.minnesotaFile'),
      MO: i18n.t('data.taxIdConfig.stateLabels.missouriCharter'),
      MS: i18n.t('data.taxIdConfig.stateLabels.mississippiBusinessId'),
      MT: i18n.t('data.taxIdConfig.stateLabels.montanaBusinessId'),
      MV: i18n.t('data.taxIdConfig.stateLabels.nevadaBusinessId'),
      NC: i18n.t('data.taxIdConfig.stateLabels.ncSecOfStateId'),
      ND: i18n.t('data.taxIdConfig.stateLabels.northDakotaSystemId'),
      NE: i18n.t('data.taxIdConfig.stateLabels.neSecOfStateAccount'),
      NH: i18n.t('data.taxIdConfig.stateLabels.newHampshireBusinessId'),
      NJ: i18n.t('data.taxIdConfig.stateLabels.newJerseyEntityId'),
      NM: i18n.t('data.taxIdConfig.stateLabels.newMexicoBusinessId'),
      NY: i18n.t('data.taxIdConfig.stateLabels.nyDepartmentOfStateId'),
      OH: i18n.t('data.taxIdConfig.stateLabels.ohioCharter'),
      OK: i18n.t('data.taxIdConfig.stateLabels.oklahomaFiling'),
      OR: i18n.t('data.taxIdConfig.stateLabels.oregonRegistry'),
      PA: i18n.t('data.taxIdConfig.stateLabels.pennsylvaniaEntity'),
      RI: i18n.t('data.taxIdConfig.stateLabels.rhodeIslandId'),
      SC: null,
      SD: i18n.t('data.taxIdConfig.stateLabels.southDakotaBusinessId'),
      TN: i18n.t('data.taxIdConfig.stateLabels.tennesseeControl'),
      TX: i18n.t('data.taxIdConfig.stateLabels.texasTaxpayer'),
      UT: i18n.t('data.taxIdConfig.stateLabels.utahEntity'),
      VA: i18n.t('data.taxIdConfig.stateLabels.vaStateCorpCommissionId'),
      VT: i18n.t('data.taxIdConfig.stateLabels.vermontBusinessId'),
      WA: i18n.t('data.taxIdConfig.stateLabels.waUnifiedBusinessId'),
      WI: i18n.t('data.taxIdConfig.stateLabels.wisconsinEntityId'),
      WV: i18n.t('data.taxIdConfig.stateLabels.westVirginiaOrg'),
      WY: i18n.t('data.taxIdConfig.stateLabels.wyomingFilingId'),
    },
    corpTypes: {
      corp: () => i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.corp'),
      llc: () => i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.llc'),
      sole: () => i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.sole'),
      partnership: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.partnership'),
      nonprofit: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.nonprofit'),
    },
  },
  CA: {
    businessNameLabel: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.legalBusinessName'),
    businessNameInstructions: () =>
      i18n.t('common.shouldMatchIncorporationDocuments'),
    registrationNumberLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.corporationNumber',
      ),
    shortLabel: 'Business Number (BN)',
    regex: /^\d{9}$/,
    stateLabels: {
      AB: i18n.t('data.taxIdConfig.stateLabels.albertaBusiness'),
      BC: i18n.t('data.taxIdConfig.stateLabels.britishColumbia'),
      MB: i18n.t('data.taxIdConfig.stateLabels.manitobaBusiness'),
      NB: i18n.t('data.taxIdConfig.stateLabels.newBrunswickReference'),
      NL: i18n.t('data.taxIdConfig.stateLabels.newfoundlandCorp'), // abbr corp
      NT: i18n.t('data.taxIdConfig.stateLabels.ntFile'),
      NS: i18n.t('data.taxIdConfig.stateLabels.novaScotiaRegistry'),
      NU: i18n.t('data.taxIdConfig.stateLabels.nunavutBusiness'),
      ON: i18n.t('data.taxIdConfig.stateLabels.ontarioCorp'), // abbr corp
      PE: i18n.t('data.taxIdConfig.stateLabels.peiRegistration'),
      QC: i18n.t('data.taxIdConfig.stateLabels.quebecEnterprise'),
      SK: i18n.t('data.taxIdConfig.stateLabels.saskatchewanBusiness'),
      YT: i18n.t('data.taxIdConfig.stateLabels.yukonRegistry'),
    },
    corpTypes: {
      corp: () => i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.corp'),
      limited_partnership: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.limitedPartnership',
        ),
      general_partnership: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.generalPartnership',
        ),
      sole: () => i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.sole'),
      coop: () => i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.coop'),
      nonprofit: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.nonprofit'),
    },
  },
  GB: {
    businessNameLabel: () => () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.legalBusinessName'),
    businessNameInstructions: () => () =>
      i18n.t('common.shouldMatchIncorporationDocuments'),
    registrationNumberLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.companyRegistrationNumber',
      ),
    shortLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.companyRegistrationNumber',
      ),
    regex: /\b[a-zA-Z0-9]{8}\b/,
    stateLabels: {
      NI: 'Northern Ireland',
      SC: 'Scotland',
      WA: 'Wales',
      EN: 'England',
    },
    corpTypes: {
      limited_partnership: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.gb.limitedPartnership',
        ),
      general_partnership: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.partnership'),
      limited_corporation: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.gb.limitedCompany',
        ),
    },
  },
  AU: {
    businessNameLabel: () => i18n.t('common.registeredBusinessName'),
    businessNameInstructions: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.fullNameRegistered'),
    registrationNumberLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.australianBusinessNumber',
      ),
    corpTypes: {
      proprietary_limited: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.au.proprietaryLimited',
        ),
      limited: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.au.limited'),
      no_liability: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.au.noLiability'),
    },
    supportingDocumentsLabel: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.registrationDocuments'),
  },
  DE: {
    businessNameLabel: () => i18n.t('common.registeredBusinessName'),
    businessNameInstructions: () =>
      i18n.t('common.shouldMatchIncorporationDocuments'),
    registrationNumberLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.businessNumber',
      ),
    corpTypes: {
      limited_liability: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.de.limitedLiability',
        ),
      limited_partnership: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.de.limitedPartnership',
        ),
      general_partnership: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.de.generalPartnership',
        ),
      limited_commercial: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.de.limitedCommercial',
        ),
      joint_stock: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.de.jointStock'),
      small_limited_company: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.de.smallLimitedCompany',
        ),
      societas_europaea: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.de.societasEuropaea',
        ),
    },
  },
  BE: {
    businessNameLabel: () => i18n.t('common.registeredBusinessName'),
    businessNameInstructions: () =>
      i18n.t('common.shouldMatchIncorporationDocuments'),
    registrationNumberLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.enterpriseNumber',
      ),
    corpTypes: {
      public_limited_liability: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.be.publicLimitedLiability',
        ),
      private_limited_liability: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.be.privateLimitedLiability',
        ),
      limited_partnership: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.be.limitedPartnership',
        ),
      general_partnership: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.be.generalPartnership',
        ),
      cooperative: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.be.cooperative'),
      societas_europaea: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.be.societasEuropaea',
        ),
      coop_society: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.be.coopSociety'),
    },
  },
  AT: {
    businessNameLabel: () => i18n.t('common.registeredBusinessName'),
    businessNameInstructions: () =>
      i18n.t('common.shouldMatchIncorporationDocuments'),
    registrationNumberLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.atBusinessRegistrationNumber',
      ),
    corpTypes: {
      limited_liability: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.at.limitedLiability',
        ),
      joint_stock: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.at.jointStock'),
      societas_europaea: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.at.societasEuropaea',
        ),
      limited_partnership: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.at.limitedPartnership',
        ),
      general_partnership: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.at.generalPartnership',
        ),
      limited_commercial: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.at.limitedCommercial',
        ),
      small_limited: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.at.smallLimited',
        ),
    },
  },
  FI: {
    businessNameLabel: () => i18n.t('common.registeredBusinessName'),
    businessNameInstructions: () =>
      i18n.t('common.shouldMatchIncorporationDocuments'),
    registrationNumberLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.businessId',
      ),
    corpTypes: {
      limited: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.fi.limited'),
      coop_society: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.fi.coopSociety'),
      general_partnership: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.fi.generalPartnership',
        ),
      limited_partnership: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.fi.limitedPartnership',
        ),
      societas_europaea: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.fi.societasEuropaea',
        ),
    },
  },
  IE: {
    businessNameLabel: () => i18n.t('common.registeredBusinessName'),
    businessNameInstructions: () =>
      i18n.t('common.shouldMatchIncorporationDocuments'),
    registrationNumberLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.companyNumber',
      ),
    corpTypes: {
      private_limited: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.ie.privateLimited',
        ),
      designated_activity: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.ie.designatedActivity',
        ),
      guarantee_limited: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.ie.guaranteeLimited',
        ),
      public_limited: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.ie.publicLimited',
        ),
      unlimited: () =>
        i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.ie.unlimited'),
      public_unlimited: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.ie.publicUnlimited',
        ),
      societas_europaea: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.ie.societasEuropaea',
        ),
      undertakings_securities: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.ie.undertakingsSecurities',
        ),
    },
  },
  NL: {
    businessNameLabel: () => i18n.t('common.registeredBusinessName'),
    businessNameInstructions: () =>
      i18n.t('common.shouldMatchIncorporationDocuments'),
    registrationNumberLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.businessRegistrationNumber',
      ),
    registrationNumberInstructions: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.corpTypes.nl.kvkNumber'),
    stateLabels: {
      Drenthe: 'Drenthe',
      Flevoland: 'Flevoland',
      Fryslan: 'Fryslan',
      Gelderland: 'Gelderland',
      Groningen: 'Groningen',
      Limburg: 'Limburg',
      'Noord-Brabant': 'Noord-Brabant',
      'Noord-Holland': 'Noord-Holland',
      Overijssel: 'Overijssel',
      Utrecht: 'Utrecht',
      Zeeland: 'Zeeland',
      'Zuid-Holland': 'Zuid-Holland',
    },
    corpTypes: {
      private_limited: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.nl.privateLimited',
        ),
      public_limited: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.nl.publicLimited',
        ),
      societas_europaea: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.nl.societasEuropaea',
        ),
    },
    supportingDocumentsLabel: () =>
      i18n.t('data.taxIdConfig.businessTaxIdConfig.registrationDocuments'),
  },
  SG: {
    businessNameLabel: () => i18n.t('common.registeredBusinessName'),
    businessNameInstructions: () =>
      i18n.t('common.shouldMatchIncorporationDocuments'),
    registrationNumberLabel: () =>
      i18n.t(
        'data.taxIdConfig.businessTaxIdConfig.registrationNumberLabel.businessRegistrationNumber',
      ),
    stateLabels: {
      '01': 'Central Singapore',
      '02': 'North East',
      '03': 'North West',
      '04': 'South East',
      '05': 'South West',
    },
    corpTypes: {
      private_limited: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.sg.privateLimited',
        ),
      public_company_limited_by_shares: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.sg.publicCompanyLimitedByShares',
        ),
    },
  },
  SE: {
    businessNameLabel: () => '',
    businessNameInstructions: () => '',
    registrationNumberLabel: () => '',
    corpTypes: {
      limited_company: () =>
        i18n.i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.se.limitedCompany',
        ),
      societas_europaea: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.se.societasEuropaea',
        ),
    },
  },
  DK: {
    businessNameLabel: () => '',
    businessNameInstructions: () => '',
    registrationNumberLabel: () => '',
    corpTypes: {
      private_limited_company: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.dk.privateLimitedCompany',
        ),
      public_limited_company: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.dk.publicLimitedCompany',
        ),
      limited_liability_cooperative: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.dk.limitedLiabilityCooperative',
        ),
      limited_liability_company: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.dk.limitedLiabilityCompany',
        ),
      limited_liability_association: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.dk.limitedLiabilityAssociation',
        ),
      societas_europaea: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.dk.societasEuropaea',
        ),
    },
  },
  CH: {
    corpTypes: {
      limited_company: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.ch.limitedCompany',
        ),
      limited_liability_company: () =>
        i18n.t(
          'data.taxIdConfig.businessTaxIdConfig.corpTypes.ch.limitedLiabilityCompany',
        ),
    },
  },
};
