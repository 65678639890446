<template>
  <div :class="$style['summary-container']">
    <p :class="$style['sub-heading']">
      {{ $t('account.pages.subPageRepayments.paymentsRemaining') }}
      <button
        v-tooltip.focus="
          $t('account.pages.subPageRepayments.totalRemainingPayments')
        "
        type="button"
        class="pi pi-info-circle"
        :class="$style.tooltip"
        aria-label="Payments remaining info"
      />
    </p>
    <h1 :class="$style['pending']">
      {{
        $filters.currency(summary.outstandingBalance, currencyCodes.USD, false)
      }}
    </h1>
  </div>
</template>

<script>
import { CurrencyCode } from '@clearbanc/data-common-types';

export default {
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currencyCodes: () => CurrencyCode,
  },
};
</script>

<style lang="less" module>
.summary-container {
  width: 100%;
  margin-top: 10px;

  .sub-heading,
  .pending,
  .settled {
    margin: 0;
  }

  .status-bar-track {
    width: 100%;
    border-radius: 5px;
    height: 8px;
    margin: 10px 0px;
    background: @color-grey-100;
    overflow: hidden;
    .status-bar-percent {
      background: linear-gradient(90deg, #b563ec 2.68%, #f6c8a4 90.69%);
      border-radius: 5px;
      height: 8px;
    }
  }

  .tooltip {
    color: @color-black;
    padding: 4px;
    cursor: help;
  }
}
</style>
