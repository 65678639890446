// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_QMSb_{margin:20px auto 0}.form_dXRii{margin-top:2rem}.form_dXRii:first-child{margin-top:0}.void-check-container_Z46Uw p{margin:2rem 0 1rem 0;text-align:center;font-weight:500;font-size:.875rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_QMSb_",
	"form": "form_dXRii",
	"void-check-container": "void-check-container_Z46Uw"
};
export default ___CSS_LOADER_EXPORT___;
