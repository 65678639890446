export const API_FAILURE_CODES = {
  MISSING_ADVANCE: 'missing_advance',
  MISSING_TRANSACTION: 'missing_transaction',
  TRANSACTION_FAILURE: 'transaction_failure',
};

export const FAILURE_REASONS = {
  COMPLIANCE: ['compliance', 'CCC'],
};

export const PAYMENT_STATUSES = {
  READY_TO_SEND: 'ready_to_send',
  RELEASED: 'released',
  SUBMITTED: 'submitted',
  COMPLETED: 'completed',
  FAILED: 'failed',
  DELETED: 'deleted',
};

export const PAYMENT_TYPES = {
  PRIORITY: 'priority',
  REGULAR: 'regular',
};
