<template>
  <div class="clearbanc-card-widget">
    <div v-if="fetchCardsRequest.isPendingOrEmpty">
      {{ $t('account.pages.subpageClearbancCard.loading') }}
    </div>
    <div v-else-if="!card">
      {{ $t('account.pages.subpageClearbancCard.youDoNotHaveAClearcoCard') }}
    </div>
    <template v-else>
      <div
        class="card-container"
        data-cy="card-container"
        :class="{ blurred: hideCardNumber }"
      >
        <div class="card-number-container">
          <div class="card-number" data-cy="card-number">
            <span>{{ cardNumberChunks[0] }}</span
            ><span>{{ cardNumberChunks[1] }}</span
            ><span>{{ cardNumberChunks[2] }}</span
            ><span>{{ cardNumberChunks[3] }}</span>
          </div>
          <v-button
            v-if="hideCardNumber"
            class="reveal-button"
            data-cy="card-reveal-button"
            theme="transparent-light"
            icon="eye"
            size="small"
            @click="getCardNumber"
          >
            {{ $t('account.pages.subpageClearbancCard.showCardNumber') }}
          </v-button>
        </div>
        <div class="card-expiration">
          <div class="small-label">
            {{ $t('account.pages.subpageClearbancCard.validUntil') }}
          </div>
          <div class="expiry-date">
            <span class="month">{{ card.expiryMonth || '00' }}</span
            ><span class="slash">/</span
            ><span class="year">{{ card.expiryYear || '2000' }}</span>
          </div>
        </div>
        <div class="card-cvc">
          <div class="small-label">
            {{ $t('account.pages.subpageClearbancCard.cvc').toUpperCase() }}
          </div>
          <div class="cvc">
            {{ card.cvc || '999' }}
          </div>
        </div>
        <img
          class="card-img"
          src="@/assets/images/card.png"
          alt="Clearco black visa card"
        />
      </div>
      <div class="other-info-container">
        <form-row>
          <form-input
            :label="$t('account.pages.subpageClearbancCard.nameOnCard')"
            type="container"
          >
            {{ card.fullName }}
          </form-input>
        </form-row>
        <form-row>
          <form-input
            :label="$t('account.pages.subpageClearbancCard.billingAddress')"
            type="container"
          >
            {{ card.address.line1 }}
            {{ card.address.city }}, {{ card.address.state }},
            {{ card.address.postal_code }}
          </form-input>
        </form-row>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';

export default {
  data() {
    return {
      validatePassword: '',
    };
  },
  computed: {
    ...mapGetters({
      card: 'issuedClearbancCard',
    }),
    ...mapRequestStatuses({
      fetchCardsRequest: 'FETCH_USER_CARDS',
      revealCardDetailsRequest: 'GET_CARD_FULL_DETAILS',
    }),
    hideCardNumber() {
      return !_.get(this.card, 'fullCardNumber');
    },
    cardNumberChunks() {
      const num = _.get(this.card, 'fullCardNumber', '1234123412341234');
      return [
        num.slice(0, 4),
        num.slice(4, 8),
        num.slice(8, 12),
        num.slice(12),
      ];
    },
  },
  methods: {
    async getCardNumber() {
      await this.$store.dispatchApiAction('GET_CARD_FULL_DETAILS', {
        cardId: this.card.id,
      });
    },
  },
};
</script>

<style lang="less">
.clearbanc-card-widget {
  .card-container {
    position: relative;
    max-width: 350px;
    display: inline-block;
  }

  .other-info-container {
    display: inline-block;
    vertical-align: top;
  }

  .card-img {
    height: 100%;
    width: 100%;
    min-height: 175px;
    display: inline-block;
  }

  .card-number,
  .expiry-date,
  .cvc {
    color: #ddd;
    font-weight: bold;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.7);
    font-family: 'Courier New', Courier, 'Lucida Sans Typewriter',
      'Lucida Typewriter', monospace;
  }

  .card-container.blurred {
    .card-number {
      color: #fff;
      filter: blur(0.18em);
    }
  }

  .card-number-container {
    z-index: 2;
    position: absolute;
    top: 35%;
    width: 100%;
    text-align: center;
  }

  .card-number {
    font-size: 22px;

    > span {
      margin: 0 5px;
    }
  }

  .reveal-button {
    z-index: 5;
    margin: 10px auto 0;
    display: inline-block;
  }

  .card-expiration,
  .card-cvc {
    z-index: 2;
    position: absolute;
    font-size: 14px;
    bottom: 10%;
  }

  .small-label {
    font-size: 11px;
    line-height: 1em;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
  }

  .card-expiration {
    left: 8%;
  }

  .card-cvc {
    left: 35%;
  }
}
</style>
