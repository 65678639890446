import provincesByCountry from './states-by-country';

const jurisdictionsByCountry = {
  US: [...provincesByCountry.US],
  CA: [...provincesByCountry.CA],
  GB: [...provincesByCountry.GB],
  AU: [...provincesByCountry.AU],
  NL: [...provincesByCountry.NL],
  SG: [...provincesByCountry.SG],
  BE: [...provincesByCountry.BE],
  IE: [...provincesByCountry.IE],
  FI: [...provincesByCountry.FI],
  AT: [...provincesByCountry.AT],
  // Germany has different jurisdictions than provinces
  DE: [
    { name: () => 'Augsburg', value: 'Augsburg', label: 'Augsburg' },
    { name: () => 'Aurich', value: 'Aurich', label: 'Aurich' },
    { name: () => 'Bad', value: 'Bad', label: 'Bad' },
    { name: () => 'Bamberg', value: 'Bamberg', label: 'Bamberg' },
    { name: () => 'Berlin', value: 'Berlin', label: 'Berlin' },
    { name: () => 'Bielefeld', value: 'Bielefeld', label: 'Bielefeld' },
    { name: () => 'Bochum', value: 'Bochum', label: 'Bochum' },
    { name: () => 'Bonn', value: 'Bonn', label: 'Bonn' },
    {
      name: () => 'Braunschweig',
      value: 'Braunschweig',
      label: 'Braunschweig',
    },
    {
      name: () => 'Charlottenburg',
      value: 'Berlin (Charl Ottenburg)',
      label: 'Charlottenburg',
    },
    { name: () => 'Chemnitz', value: 'Chemnitz', label: 'Chemnitz' },
    { name: () => 'Coesfeld', value: 'Coesfeld', label: 'Coesfeld' },
    { name: () => 'Cottbus', value: 'Cottbus', label: 'Cottbus' },
    { name: () => 'Darmstadt', value: 'Darmstadt', label: 'Darmstadt' },
    { name: () => 'Deggerdorf', value: 'Deggerdorf', label: 'Deggerdorf' },
    { name: () => 'Dortmund', value: 'Dortmund', label: 'Dortmund' },
    { name: () => 'Dresden', value: 'Dresden', label: 'Dresden' },
    { name: () => 'Duderstadt', value: 'Duderstadt', label: 'Duderstadt' },
    { name: () => 'Duisburg', value: 'Duisburg', label: 'Duisburg' },
    { name: () => 'Dusseldorf', value: 'Dusseldorf', label: 'Dusseldorf' },
    { name: () => 'Essen', value: 'Essen', label: 'Essen' },
    { name: () => 'Flensburg', value: 'Flensburg', label: 'Flensburg' },
    { name: () => 'Frankfurt', value: 'Frankfurt', label: 'Frankfurt' },
    { name: () => 'Freiburg', value: 'Freiburg', label: 'Freiburg' },
    { name: () => 'Fritzlar', value: 'Fritzlar', label: 'Fritzlar' },
    { name: () => 'Furth', value: 'Furth', label: 'Furth' },
    {
      name: () => 'Gelsenkirchen',
      value: 'Gelsenkirchen',
      label: 'Gelsenkirchen',
    },
    { name: () => 'Gottingen', value: 'Gottingen', label: 'Gottingen' },
    { name: () => 'Gütersloh', value: 'Gutersloh', label: 'Gütersloh' },
    { name: () => 'Hagen', value: 'Hagen', label: 'Hagen' },
    { name: () => 'Hamburg', value: 'Hamburg', label: 'Hamburg' },
    { name: () => 'Hamm', value: 'Hamm', label: 'Hamm' },
    { name: () => 'Hanau', value: 'Hanau', label: 'Hanau' },
    { name: () => 'Hannover', value: 'Hannover', label: 'Hannover' },
    { name: () => 'Hildeshein', value: 'Hildeshein', label: 'Hildeshein' },
    { name: () => 'Hof', value: 'Hof', label: 'Hof' },
    {
      name: () => 'Hollenbach',
      value: 'Hollenbach-motzenhofen',
      label: 'Hollenbach',
    },
    { name: () => 'Ingolstadt', value: 'Ingolstadt', label: 'Ingolstadt' },
    { name: () => 'Iserlohn', value: 'Iserlohn', label: 'Iserlohn' },
    { name: () => 'Jena', value: 'Jena', label: 'Jena' },
    { name: () => 'Kiel', value: 'Kiel', label: 'Kiel' },
    { name: () => 'Kleve', value: 'Kleve', label: 'Kleve' },
    { name: () => 'Koblenz', value: 'Koblenz', label: 'Koblenz' },
    { name: () => 'Koln', value: 'Koln', label: 'Koln' },
    { name: () => 'Krefeld', value: 'Krefeld', label: 'Krefeld' },
    { name: () => 'Landau', value: 'Landau', label: 'Landau' },
    { name: () => 'Landshut', value: 'Landshut', label: 'Landshut' },
    { name: () => 'Leipzig', value: 'Leipzig', label: 'Leipzig' },
    { name: () => 'Lubeck', value: 'Lubeck', label: 'Lubeck' },
    {
      name: () => 'Ludwigshafen',
      value: 'Ludwigshafen',
      label: 'Ludwigshafen',
    },
    { name: () => 'Luneburg', value: 'Luneburg', label: 'Luneburg' },
    { name: () => 'Mainz', value: 'Mainz', label: 'Mainz' },
    { name: () => 'Mannheim', value: 'Mannheim', label: 'Mannheim' },
    { name: () => 'Memmingen', value: 'Memmingen', label: 'Memmingen' },
    { name: () => 'Montabaur', value: 'Montabaur', label: 'Montabaur' },
    { name: () => 'München', value: 'Munchen', label: 'München' },
    { name: () => 'Münster', value: 'Munster', label: 'Münster' },
    { name: () => 'Neuss', value: 'Neuss', label: 'Neuss' },
    { name: () => 'Nurnberg', value: 'Nurnberg', label: 'Nurnberg' },
    { name: () => 'Oldenburg', value: 'Oldenburg', label: 'Oldenburg' },
    { name: () => 'Osnabruck', value: 'Osnabruck', label: 'Osnabruck' },
    { name: () => 'Paderborn', value: 'Paderborn', label: 'Paderborn' },
    { name: () => 'Pinneberg', value: 'Pinneberg', label: 'Pinneberg' },
    { name: () => 'Potsdam', value: 'Potsdam', label: 'Potsdam' },
    {
      name: () => 'Recklinghausen',
      value: 'Recklinghausen',
      label: 'Recklinghausen',
    },
    { name: () => 'Regensburg', value: 'Regensburg', label: 'Regensburg' },
    { name: () => 'Rostock', value: 'Rostock', label: 'Rostock' },
    { name: () => 'Saarbrücken', value: 'SaarBrucken', label: 'Saarbrücken' },
    { name: () => 'Schwerin', value: 'Schwerin', label: 'Schwerin' },
    { name: () => 'Siegburg', value: 'Siegburg', label: 'Siegburg' },
    { name: () => 'Steinfurt', value: 'Steinfurt', label: 'Steinfurt' },
    { name: () => 'Stendal', value: 'Stendal', label: 'Stendal' },
    { name: () => 'Stralsund', value: 'Stralsund', label: 'Stralsund' },
    { name: () => 'Straubing', value: 'Straubing', label: 'Straubing' },
    { name: () => 'Stuttgart', value: 'Stuttgart', label: 'Stuttgart' },
    { name: () => 'Tostedt', value: 'Tostedt', label: 'Tostedt' },
    { name: () => 'Traunstein', value: 'Traunstein', label: 'Traunstein' },
    { name: () => 'Ulm', value: 'Ulm', label: 'Ulm' },
    { name: () => 'Walsrode', value: 'Walsrode', label: 'Walsrode' },
    { name: () => 'Wetzlar', value: 'Wetzlar', label: 'Wetzlar' },
    { name: () => 'Wiesbaden', value: 'Wiesbaden', label: 'Wiesbaden' },
    { name: () => 'Wurzburg', value: 'Wurzburg', label: 'Wurzburg' },
    { name: () => 'ZweiBrucken', value: 'ZweiBrucken', label: 'ZweiBrucken' },
  ],
};
export default jurisdictionsByCountry;
