import { buildApiActions } from '@/utils/vuex-api-utils';
import { creditsRequest } from '@/utils/credits-request';

export default {
  state: {
    credits: {},
    creditStats: {},
    creditTransactions: [],
    creditStatements: [],
  },
  getters: {
    credits: (state) => state.credits,
    credit: (state) => {
      const arr = Object.values(state.credits);
      if (arr && arr.length > 0) return arr[0];
      return undefined;
    },
    creditStats: (state) => state.creditStats,
    creditOffer: (state) =>
      Object.values(state.credits).find((credit) => credit.amountCents),
    creditTransactions: (state) => state.creditTransactions,
    availableCreditOffers: (state) =>
      Object.values(state.credits).filter(
        (credit) => credit.amountCents > 0 && !credit.activatedAt,
      ),
    creditStatements: (state) => state.creditStatements,
    userHasActiveClearCard: (state) =>
      Object.values(state.credits).some(
        (credit) =>
          credit.amountCents > 0 && credit.approvedAt && credit.activatedAt,
      ),
  },
  ...buildApiActions(
    {
      GET_CREDITS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/businesses/${ctx.rootGetters.businessId}/credits`,
        }),
        mutation: (state, { response }) => {
          state.credits = response.reduce((credits, credit) => {
            // eslint-disable-next-line no-param-reassign
            credits[credit.id] = credit;
            return credits;
          }, {});
        },
      },
      SAVE_CREDIT: {
        action: (ctx, payload) => ({
          method: 'patch',
          interceptor: creditsRequest,
          url: `/credits/${payload.id}`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.credits[response.id] = response;
        },
      },
      CREATE_CREDIT: {
        action: (ctx, payload) => ({
          method: 'post',
          interceptor: creditsRequest,
          url: `/credits`,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.credits[response.id] = response;
        },
      },
      APPROVE_CREDIT: {
        action: (ctx, payload) => ({
          method: 'post',
          interceptor: creditsRequest,
          url: `/credits/${payload.id}/approve`,
        }),
        mutation: (state, { response }) => {
          state.credits[response.id] = response;
        },
      },
      ACTIVATE_CREDIT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/credits/${payload.id}/activate`,
        }),
        mutation: (state, { response }) => {
          state.credits[response.id] = response;
        },
      },
      DEACTIVATE_CREDIT: {
        action: (ctx, payload) => ({
          method: 'patch',
          url: `/credits/${payload.id}/deactivate`,
          interceptor: creditsRequest,
        }),
        mutation: (state, { response }) => {
          state.credits[response.id] = response;
        },
      },
      REFINANCE_CREDIT: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/credits/${payload.id}/refinance`,
          interceptor: creditsRequest,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.credits[response.id] = response;
        },
      },
      FETCH_CREDIT_STATS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/businesses/${ctx.rootGetters.businessId}/credits/stats`,
          interceptor: creditsRequest,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.creditStats = response;
        },
      },
      FETCH_CREDIT_TRANSACTIONS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/credits/${payload.creditId}/transactions`,
          interceptor: creditsRequest,
          params: payload,
        }),
        mutation: (state, { response }) => {
          state.creditTransactions = response;
        },
      },
      FETCH_CREDIT_STATEMENTS: {
        action: (ctx) => ({
          method: 'get',
          url: `/businesses/${ctx.rootGetters.businessId}/credit-statements`,
          interceptor: creditsRequest,
        }),
        mutation: (state, { response }) => {
          state.creditStatements = response;
        },
      },
    },
    {
      actions: {
        EDIT_CREDIT(context, payload) {
          context.commit('EDIT_CREDIT', payload);
        },
      },
      mutations: {
        EDIT_CREDIT(state, payload) {
          state.credits[payload.id][payload.path] = payload.val;
        },
      },
    },
  ),
};
