<template>
  <div class="receipts-disclaimer">
    <i18n-t
      keypath="account.pages.pageVendors.extendedPaymentOptions.receiptsDisclaimer"
    >
      <template v-slot:eligibleVendorsLink>
        <a
          href="https://help.clear.co/s/article/Approved-vendors?language=en_US"
          title="eligible vendors link"
          target="_blank"
          @click="trackLinkClick('fe_eligible_vendors_clicked')"
        >
          {{
            $t(
              'account.pages.pageVendors.extendedPaymentOptions.eligibleVendorsLink',
            )
          }}
        </a>
      </template>
    </i18n-t>
  </div>
</template>

<script>
import analytics from '@/utils/analytics';

export default {
  methods: {
    trackLinkClick(event) {
      analytics.track(event);
    },
  },
};
</script>

<style lang="less">
.receipts-disclaimer {
  color: black;
  font-family: 'Gerstner-Programm', 'Helvetica', 'Segoe UI', 'sans-serif';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}
</style>
