import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

export const useContextRoot = () => {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();

  return {
    root: {
      $router: router,
      $route: route,
      $store: store,
    },
  };
};
