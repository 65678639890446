<template>
  <Teleport to="body">
    <div :class="isActive ? 'popup-active' : ''">
      <div v-if="isActive" class="popup" :class="classes">
        <div class="popup-mask" />
        <div class="popup-wrapper">
          <h2 class="popup-title" :data-cy="kebabCase(title)">
            <img
              v-if="popupIcon"
              class="popup-icon"
              :src="popupIcon"
              alt=""
            />{{ title }}
          </h2>
          <div v-if="!noExit" class="popup-close-x" @click="close">
            <icon name="times" />
          </div>
          <div class="popup-content">
            <div class="popup-content-top">
              <slot name="top" />
            </div>
            <div class="popup-content-scroll">
              <slot />
            </div>
            <div class="popup-content-bottom">
              <slot name="bottom" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { mapGetters } from 'vuex';
import kebabCase from '@/utils/kebabcase';
import { ref, computed, onMounted, onUnmounted } from 'vue';

export default {
  props: {
    popupIcon: { type: String },
    title: { type: String },
    fullScreen: { type: Boolean, default: false },
    noExit: { type: Boolean, default: false },
    startOpen: Boolean,
    onBeforeClose: Function,
    customClass: { type: String },
  },
  setup(props, context) {
    const isActive = ref(props.startOpen);
    const close = async () => {
      let toClose = true;
      if (props.onBeforeClose) {
        toClose = props.onBeforeClose();
      }
      if (toClose) {
        isActive.value = false;
        context.emit('close');
      }
    };

    const onKeyUp = (e) => {
      if (e.key === 'Escape') {
        close();
      }
    };
    onMounted(() => {
      if (!props.noExit) {
        window.addEventListener('keyup', onKeyUp);
      }
    });

    onUnmounted(() => window.removeEventListener('keyup', onKeyUp));

    const open = () => {
      isActive.value = true;
    };

    const classes = computed(() => {
      return {
        'popup--fullscreen': props.fullScreen,
        'popup--modal': !props.fullScreen,
        [props.customClass]: props.customClass,
      };
    });

    return { open, close, kebabCase, classes, isActive };
  },
  computed: {
    ...mapGetters(['apiErrorCode']),
  },
};
</script>

<style lang="less">
.popup-active {
  overflow: hidden;

  .popup-mask {
    display: block;
  }
}

.popup-mask {
  background: fade(#dee8f9, 95);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  display: none;
}

.header-bar .popup-mask {
  position: absolute;
}

.popup {
  .popup-wrapper {
    z-index: 2001;
    position: fixed;
    text-shadow: none;
    bottom: 20px;

    @media @mq-medium {
      margin: 0 auto;
      width: 650px;
      left: 50%;
      margin-left: -300px;
      top: 150px;
    }

    @media @mq-small-only {
      left: 10px;
      right: 10px;
      margin-top: 40px;
      bottom: 10px;
      //- override fullscreen setup below
      top: 0 !important;
      margin-left: 0 !important;
    }
  }

  &.popup--fullscreen {
    .popup-wrapper {
      width: 96%;
      margin-left: -48%;
      top: 44px;
    }

    .popup-content {
      min-height: 100%;
    }

    .popup-content-scroll {
      flex: 1 0 0;
    }
  }

  .popup-content {
    position: relative;
    background: white;
    border-top: 4px solid @navy;
    border-radius: 3px;
    box-shadow: 0 10px 20px 6px rgba(0, 0, 0, 0.05);
    max-height: 100%;
    z-index: 2002;
    display: flex;
    flex-direction: column;

    .popup.padded & {
      padding: 20px;
    }

    .popup.centered & {
      text-align: center;
    }

    @media @mq-small-only {
      max-height: 92%;
    }
  }

  .popup-content-scroll {
    overflow: auto;
  }

  .popup-close-x {
    position: absolute;
    top: -42px;
    right: -12px;
    color: @navy;
    width: 44px;
    height: 44px;
    padding: 8px;
    cursor: pointer;
    z-index: 2002;

    svg {
      scale: 1;
      transition: all 0.3s;
      display: block;
      width: 100%;
      height: 100%;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .popup-title {
    font-size: 24px;
    font-weight: bold;
    color: @navy;
    margin: 0;
    height: 44px;
    border-radius: 3px 3px 0 0;
    position: absolute;
    left: 2px;
    top: -36px;
  }
}
</style>
