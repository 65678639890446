// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button.link_Fdi85{display:inline;padding:0;line-height:26px}.error_fZWZj{color:red;align-content:left;padding:0;margin:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "link_Fdi85",
	"error": "error_fZWZj"
};
export default ___CSS_LOADER_EXPORT___;
