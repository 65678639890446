// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".grid-wrap_SLLor{width:100%;background-color:#f8f8fa;padding-top:50px}.financing-grid-layout_LJV_e{max-width:1440px;margin:auto;padding:0 40px;display:grid;grid-template-columns:repeat(12,1fr);grid-template-rows:repeat(3,-webkit-min-content);grid-template-rows:repeat(3,min-content);grid-column-gap:24px}@media only screen and (min-width:513px) and (max-width:1024px){.financing-grid-layout_LJV_e{padding:0 40px}}@media only screen and (max-width:512px){.financing-grid-layout_LJV_e{padding:0 16px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid-wrap": "grid-wrap_SLLor",
	"financing-grid-layout": "financing-grid-layout_LJV_e"
};
export default ___CSS_LOADER_EXPORT___;
