<template>
  <div :class="$style['inner-container']">
    <div :class="$style['spinner']">
      <icon name="spinner" scale="2" />
    </div>
  </div>
</template>

<style lang="less" module>
.inner-container {
  width: 100%;
  min-height: 100%;
  position: relative;
  top: 0;

  .spinner {
    display: block;
    max-width: 32px;
    margin: 50vh auto 0;
  }
}
</style>
