<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.391 11.0615C15.7931 10.0909 16 9.05058 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 9.05058 0.206926 10.0909 0.608964 11.0615C1.011 12.0321 1.60028 12.914 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C9.05058 16 10.0909 15.7931 11.0615 15.391C12.0321 14.989 12.914 14.3997 13.6569 13.6569C14.3997 12.914 14.989 12.0321 15.391 11.0615ZM11.2766 6.43599C11.5174 6.09893 11.4393 5.63052 11.1023 5.38976C10.7652 5.149 10.2968 5.22707 10.056 5.56413L7.2373 9.51036L5.86334 8.13639C5.57044 7.8435 5.09557 7.8435 4.80268 8.1364C4.50978 8.42929 4.50978 8.90416 4.80268 9.19706L6.80268 11.1971C6.95857 11.3529 7.17515 11.4324 7.39487 11.4142C7.61458 11.396 7.81517 11.2821 7.94331 11.1027L11.2766 6.43599Z"
      fill="url(#paint0_linear_3520_1992)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3520_1992"
        x1="-8.10251e-08"
        y1="16"
        x2="16"
        y2="-8.10251e-08"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3DD47F" />
        <stop offset="1" stop-color="#23AA71" />
      </linearGradient>
    </defs>
  </svg>
</template>
