<template>
  <div class="data-security-page">
    <h3>{{ $t('account.pages.dataAndPrivacy.manageYourPersonalData') }}</h3>
    <p>
      {{ $t('account.pages.dataAndPrivacy.accessYourDataBy') }}
    </p>
    <router-link
      class="data-security-page__link with-border"
      :to="{ name: 'export-data' }"
      @click="exportDataHandler"
    >
      <icon class="data-security-page__link-icon" name="download" scale="1.5" />
      <div class="data-security-page__link-title">
        {{
          $t('account.pages.dataAndPrivacy.pageAccessData.accessMyPersonalData')
        }}
      </div>
      <div class="data-security-page__link-content" />
      <icon
        class="data-security-page__link-icon"
        name="angle-right"
        scale="1.2"
      />
    </router-link>
    <div v-if="!userHasEverHadActiveAdvance">
      <router-link
        class="data-security-page__link"
        :to="{ name: 'remove-data' }"
        @click="anonymizeDataHandler"
      >
        <icon class="data-security-page__link-icon" name="trash" scale="1.5" />
        <div class="data-security-page__link-title">
          {{ $t('account.pages.dataAndPrivacy.removeYourUserAccount') }}
        </div>
        <div class="data-security-page__link-content">
          {{ $t('account.pages.dataAndPrivacy.anonymizeYourAccount') }}
        </div>
        <icon
          class="data-security-page__link-icon"
          name="angle-right"
          scale="1.2"
        />
      </router-link>
    </div>
    <div v-else class="data-security-page__contact">
      <i18n-t keypath="account.pages.dataAndPrivacy.contactUsAt">
        <template #email>
          <a class="email" href="mailto:support@clear.co">support@clear.co</a>
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import analytics from '@/utils/analytics';
import { useMeta } from 'vue-meta';

export default {
  setup() {
    useMeta({
      title: 'Account security | Clearco',
    });
  },
  computed: {
    ...mapGetters(['userHasEverHadActiveAdvance', 'productSegmentLabel']),
    getTargetArea() {
      return {
        targetArea: this.$route.name,
      };
    },
    eventCommonProps() {
      return {
        productSegment: this.productSegmentLabel,
        ...this.getTargetArea,
      };
    },
  },
  methods: {
    trackWayfindingUserClickEvent(name) {
      analytics.track(`fe_${name}_clicked`, this.eventCommonProps);
    },
    exportDataHandler() {
      this.trackWayfindingUserClickEvent('new_archive');
    },
    anonymizeDataHandler() {
      this.trackWayfindingUserClickEvent('anonymize_data');
    },
  },
};
</script>

<style lang="less">
.data-security-page {
  .data-security-page__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    color: @bluegray-text;

    &:hover {
      background: #f5f5f5;
      cursor: pointer;
    }

    &.with-border {
      border-bottom: 1px solid #f0f0f0;
    }

    .data-security-page__link-icon {
      width: 50px;
      margin-right: 5px;
    }

    .data-security-page__link-title {
      font-weight: 400;
      width: 300px;
      padding: 0 5px 0 0;
    }

    .data-security-page__link-content {
      font-size: 14px;
      line-height: 15px;
      width: 100%;
      max-width: 570px;
    }
  }

  .data-security-page__success-msg {
    color: @green;
    border: 1px solid @green;
    padding: 10px 12px;
    width: 100%;
    margin-top: 5px;

    .icon {
      margin-right: 7px;
    }
  }

  .data-security-page__contact {
    padding: 20px 0;
    text-align: center;
    font-weight: bold;
    color: @navy;

    .email {
      color: @navy;
      text-decoration: underline;
    }
  }
}
</style>
