<template>
  <div>
    <p>Bank Statements must be in PDF format</p>
    <div :class="$style['dropzone-container']">
      <UploadFileWidget
        data-cy="upload-file-widget"
        tabindex="0"
        :class="$style['upload-zone']"
        :upload-filters="uploadFilters"
        :display-filters="bankUploadMatcher"
        :empty-prompt="messagePrompt"
        no-list
        theme="onboarding"
        accepted-file-types="application/pdf"
        @click="trackUploadDocClick($event)"
        @uploadDragged="trackUploadDragged($event)"
        @docsUploaded="trackDocsUploaded($event)"
        @uploadFailed="trackUploadFailed($event)"
        @apiError="handleApiError($event)"
        @complete="handleComplete($event)"
      />
    </div>

    <p v-if="isHeronPdfAutomationEnabled">
      Displaying statements dated within the last 12 months
    </p>
    <BankStatementFilesList
      v-if="isHeronPdfAutomationEnabled"
      :class="$style['file-list']"
      :files="bankStatements"
      :show-empty-table="true"
      :account-number="accountNumber"
      shorten-filenames
      @trackUploadedDocClicked="trackUploadedDocClicked"
    ></BankStatementFilesList>

    <DownloadableFileListWithDateRange
      v-else
      :class="$style['file-list']"
      :files="bankStatements"
      :is-closable="true"
      :shorten-file-names="true"
      @fileRemove="removeUpload"
      @updateFile="updateFile"
      @trackUploadedDocClicked="trackUploadedDocClicked"
    />
  </div>

  <Message
    v-if="displayProcessingMessage"
    severity="info"
    :closable="false"
    :class="$style.info"
  >
    We are currently processing your bank statements. This may take a few
    minutes. Please check back later or you can continue to upload more
    statements in the meantime. If you have any questions, please contact us at
    <a href="mailto:support@clear.co" target="_blank" style="margin-top: 0"
      >support@clear.co</a
    >.
  </Message>

  <Message
    v-if="
      isHeronPdfAutomationEnabled && !pdfStatementsCompletionDetails.isComplete
    "
    severity="warn"
    :closable="false"
  >
    <p>
      You must upload the last 6 months of bank statements in order to continue.
      {{ heronErrorSolution }}
    </p>
    <p>
      For more information, please contact us at
      <a href="mailto:support@clear.co" target="_blank">support@clear.co</a>.
    </p>
  </Message>

  <error-message v-if="errorMessage" :class="$style['error-container']">
    <template #message>
      <span>
        {{ errorMessage }}
        <span v-if="showErrorSupportContact">
          Please contact us at
          <a href="mailto:support@clear.co" target="_blank">support@clear.co</a
          >.
        </span>
      </span>
    </template>
  </error-message>
</template>

<script>
import { mapGetters } from 'vuex';
import { auth0 } from '@/utils/auth0';
import { subMonths } from 'date-fns';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { isMobileMixin } from '@/utils/vue-mixins';
import {
  ENTITY_TYPE,
  BANK_ACCOUNT_DOC_TYPE,
} from '@clearbanc/data-common-types';
import analytics from '@/utils/analytics';
import Message from '@clearbanc/clear-components/message';
import { DownloadableFileListWithDateRange } from '@/components';
import BankStatementFilesList from '@/components/BankStatementFilesList';
import {
  shouldDisplayContactSupport,
  getDisplayedErrorMessage,
} from '@/data/error-messages';
import { SSE_READY_STATES } from '@/data/sse-data';

const EVENT_SOURCE_TIMEOUT = 50000;

export default {
  components: {
    DownloadableFileListWithDateRange,
    BankStatementFilesList,
    Message,
  },
  mixins: [isMobileMixin],
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
    showError: { type: Boolean, required: true },
    metaId: { type: Number, required: false },
    accountNumber: { type: String, required: false },
  },
  computed: {
    ...mapGetters([
      'businessId',
      'userId',
      'bankUploadMatcher',
      'pdfStatements',
      'isHeronPdfAutomationEnabled',
      'pdfStatementsCompletionDetails',
      'primaryOrIntendedPrimaryBankAccount',
    ]),
    HERON_API_URL() {
      return process.env.HERON_API_URL;
    },
    clearbancURL() {
      return process.env.CLEARCOM_URL;
    },
    uploadFilters() {
      return {
        entity: ENTITY_TYPE.BANK_ACCOUNT,
        type: BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS,
        businessId: this.businessId,
        metaId: this.metaId,
      };
    },
    bankStatements() {
      const uploads = this.$store.getters.uploadsMatching(
        this.bankUploadMatcher,
      );
      if (!this.isHeronPdfAutomationEnabled) {
        return uploads.filter((upload) => upload.metaId === `${this.metaId}`);
      }
      uploads.filter(
        (upload) => !upload.deletedAt && upload.metaId === this.metaId,
      );
      return uploads
        .flatMap((upload) => {
          const pdfStatement = this.pdfStatements.find(
            (pdf) => pdf.fileUploadsId === upload.id,
          );
          if (pdfStatement && pdfStatement.statements) {
            return pdfStatement.statements.map((statement) => ({
              id: upload.id,
              name: upload.name,
              bank_name: statement.bank_name,
              account_number: statement.account_number,
              statement_end_date: statement.statement_end_date,
              statement_start_date: statement.statement_start_date,
              extension: upload.extension,
              created_at: upload.createdAt,
            }));
          }
          // if we havent received statement info yet
          return [
            {
              id: upload.id,
              name: upload.name,
              bank_name: null,
              account_number: null,
              statement_end_date: null,
              statement_start_date: null,
              extension: upload.extension,
              created_at: upload.createdAt,
            },
          ];
        })
        .sort((a, b) => {
          if (a.statement_start_date && b.statement_start_date) {
            return (
              new Date(a.statement_start_date) -
              new Date(b.statement_start_date)
            );
          }
          return 0;
        });
    },
    messagePrompt() {
      if (this.isMobile()) {
        return this.$t('common.messagePrompt.browse');
      }
      return this.$t('common.messagePrompt.dragAndDropFilesOrBrowse');
    },
    showErrorSupportContact() {
      return shouldDisplayContactSupport(this.apiError?.errorCode);
    },
    heronErrorSolution() {
      const heronError = getDisplayedErrorMessage(
        this.pdfStatementsCompletionDetails,
      );
      if (heronError) {
        return this.$t(heronError, this.pdfStatementsCompletionDetails.params);
      }
      return null;
    },
    isSSEConnected() {
      return (
        this.sseClient && this.sseClient.readyState !== SSE_READY_STATES.CLOSED
      );
    },
  },
  methods: {
    async handleComplete() {
      // We start SSE connection on the docs uploaded event so we don't miss any quick event
      // But in some cases, creating the PDF can take more then our EVENT_SOURCE_TIMEOUT
      // Therefore, if the connection as closed before the file fully uploads, we can open the connection again
      if (this.isHeronPdfAutomationEnabled && !this.isSSEConnected) {
        await this.createEventSourceConnection();
      }
    },
    trackUploadedDocClicked() {
      analytics.track('fe_uploaded_doc_clicked', this.eventTrackingFields);
    },
    removeUpload(id) {
      analytics.track('fe_doc_deleted', this.eventTrackingFields);
      this.$store.dispatchApiAction('DELETE_USER_UPLOAD', { id });
    },
    updateFile(fileChanges) {
      this.$store.dispatchApiAction('UPDATE_USER_UPLOAD', fileChanges);
    },
    trackUploadDocClick() {
      analytics.track('fe_upload_doc_click', this.eventTrackingFields);
    },
    trackUploadDragged(numDocsDragged) {
      analytics.track('fe_upload_dragged', {
        ...this.eventTrackingFields,
        numDocs: numDocsDragged,
      });
    },
    async getStatements() {
      const statementStartDate = subMonths(new Date(), 6).toISOString();
      await this.$store.dispatchApiAction('GET_BANK_STATEMENTS_CHECK', {
        statementStartDate,
        bankAccountNumber:
          this.primaryOrIntendedPrimaryBankAccount?.accountNumber,
      });
      await this.$store.dispatchApiAction('GET_BANK_STATEMENTS');
    },
    async trackDocsUploaded(uploadData) {
      this.$emit('update:showError', false);
      analytics.track('fe_docs_uploaded', {
        ...this.eventTrackingFields,
        numDocs: uploadData.numDocs,
        userId: this.userId,
        businessId: this.businessId,
        uploadType: uploadData.isDragUpload ? 'drag' : 'click',
      });
      if (this.isHeronPdfAutomationEnabled) {
        this.numberOfPdfsProcessingCount += uploadData.numDocs;
        await this.createEventSourceConnection();
      }
    },
    trackUploadFailed(uploadData) {
      analytics.track('fe_upload_failed', {
        ...this.eventTrackingFields,
        userId: this.userId,
        businessId: this.businessId,
        errorMsg: uploadData.errorMessage,
        uploadType: uploadData.isDragUpload ? 'drag' : 'click',
      });
    },
    saveRequestFailedToast(errorMessage) {
      this.$toast.add({
        severity: 'error',
        detail: errorMessage,
        life: 30000,
      });
    },
    handleApiError(error) {
      const errorMessage =
        getDisplayedErrorMessage(error) ??
        'An error occured and we could not process your PDF. Please try again or contact us as support@clear.co.';
      this.saveRequestFailedToast(errorMessage);

      clearTimeout(this.sseTimer);
      this.closeEventSource();
    },
    // The purpose of this function is to create a sse connection to listen for pdf.processed events
    // That will remain open for a maximum of EVENT_SOURCE_TIMEOUT seconds after the last file is uploaded
    async createEventSourceConnection() {
      // If there is already a timer, clear it and close the connection
      // We'll start a new one after we create the new connection
      if (this.sseTimer) {
        clearTimeout(this.sseTimer);
        this.closeEventSource();
      }

      const token = await auth0.getAccessTokenSilently();
      this.sseClient = new EventSourcePolyfill(
        `${process.env.HERON_API_URL}/events`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      this.setSseTimer();

      this.sseClient.addEventListener('pdf.processed', async (event) => {
        const pdf = JSON.parse(event.data);
        this.$store.dispatch('UPDATE_PDF', pdf.heronPdf);
        this.numberOfPdfsProcessingCount--;
        // if we've received events for all PDFs, close the connection
        if (this.numberOfPdfsProcessingCount === 0) {
          this.closeEventSource();
          await this.getStatements();
        }
      });
      this.sseClient.onerror = () => {
        clearTimeout(this.sseTimer);
        this.closeEventSource();
      };
    },
    setSseTimer() {
      this.displayProcessingMessage = false;
      // If this timer runs out we want to close the connection and let the user know to check back later
      this.sseTimer = setTimeout(() => {
        this.closeEventSource();
        this.displayProcessingMessage = true;
        analytics.track('if_limit_reached_pdf_processing', {
          ...this.eventTrackingFields,
          userId: this.userId,
          businessId: this.businessId,
        });
      }, EVENT_SOURCE_TIMEOUT);
    },
    closeEventSource() {
      if (this.sseClient && this.sseClient.readyState !== 2) {
        this.sseClient.close();
        // in case we manually close the connection lets also clear the timer
        clearTimeout(this.sseTimer);
      }
    },
  },
  data() {
    return {
      apiError: null,
      errorMessage: '',
      sseClient: null,
      sseTimer: null,
      displayProcessingMessage: false,
      incompleteData: false,
      numberOfPdfsProcessingCount: 0,
    };
  },
  beforeUnmount() {
    this.closeEventSource();
  },
};
</script>

<style lang="less">
.p-message a {
  margin-top: 0;
}
</style>

<style lang="less" module>
.file-list {
  margin-bottom: 20px;
}
.dropzone-container {
  max-width: 800px;
  margin: 30px auto 60px;
  text-align: center;

  .upload-zone {
    margin: 0 auto;
    max-width: 350px;
    margin-bottom: 25px;
  }

  @media only screen and (max-width: 500px) {
    margin: 30px auto 40px;
  }
}
</style>
