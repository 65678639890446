import { COUNTRY_CODES } from '@/data/country-code-with-names';
import i18n from '@/plugins/i18n';

export const PAYMENTS_STATE_MODULE = 'payments-module';

export const MIN_RECEIPT_PAID_DATE_IN_DAYS = 60;

export const PAYMENTS_ROUTE_NAMES = {
  // Payments Onboarding Routes
  ROOT: 'payments-onboarding',
  SIGN_UP: 'payments-signup',
  USER_PROFILE: 'payments-user-profile',
  BUSINESS_DETAILS: 'payments-business-details',
  ADDRESS: 'payments-address',
  // Payments Dashboard Routes
  PAY_VENDORS: 'pay-vendors',
  REPAYMENTS: 'pay-vendors/repayments',
  UPCOMING_REPAYMENTS: 'pay-vendors/upcoming-repayments',
  PAYMENT_TABLE: 'pay-vendors/payment-table',
  ALL_DOCUMENTS: 'pay-vendors/all-documents',
  // Payments Business Profile Routes
  PROFILE_DASHBOARD: 'payments-profile',
  PROFILE_VERIFY_ACCOUNT: 'payments-profile/verify-account',
  PROFILE_CONNECT_MARKETING: 'payments-profile/connect-marketing',
  PROFILE_CONNECT_BANKING: 'payments-profile/connect-banking',
  PROFILE_BANK_STATEMENTS: 'payments-profile/bank-statements',
  PROFILE_FINANCIAL_DETAILS: 'payments-profile/financial-details',
  PROFILE_BANK_DOCUMENTS: 'payments-profile/bank-documents',
  PROFILE_DEBT_OBLIGATIONS: 'payments-profile/debt-obligations',
  PROFILE_BUSINESS_DETAILS: 'payments-profile/business-details',
  PROFILE_BUSINESS_DETAILS_PERSONA: 'payments-profile/business-details-persona',
  PROFILE_BUSINESS_REGISTERED_ADDRESS: 'payments-profile/registered-address',
  PROFILE_BUSINESS_INCORPORATION_DETAILS:
    'payments-profile/incorporation-details',
  PROFILE_OWNERS: 'payments-profile/business-owners',
  PROFILE_OWNERS_STEP_ONE: 'payments-profile/owners-step-one',
  PROFILE_OWNERS_STEP_TWO: 'payments-profile/owners-step-two',
  PROFILE_OWNERS_LIST: 'payments-profile/owners-list',
  PROFILE_NEW_OWNER: 'payments-profile/new-owner',
  // Persona Business Profile Routes
  PERSONA_PROFILE_OWNERS: 'payments-profile/persona-business-owners',
  // legacy mca routes
  VENDOR_LIST: 'vendor-list',
};

export const PAYMENTS_ROUTE_NAME_TYPEFORM_SIGNUP =
  'https://clearbanc-capital.typeform.com/to/VVZtX0CB#';
// temporary array of routes to safeguard until epic release
export const PROTECTED_PAYMENTS_ROUTE_NAMES = [
  PAYMENTS_ROUTE_NAMES.USER_PROFILE,
  PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS,
  PAYMENTS_ROUTE_NAMES.ADDRESS,
];

// analytics
export const PAYMENTS_EXPERIMENT_NAME = 'ClearPayments';

// url params
export const PAYMENTS_EXTEND_CASHFLOW_BETA_URL_PARAM = 'extendCashflowBeta';
export const PAYMENTS_BNPL_URL_PARAM = 'bnpl';
// ?bnpl-marketing override SC-129500
export const PAYMENTS_BNPL_NO_EMAIL_VERIFICATION_URL_PARAM = 'bnpl-marketing';

export const ScheduledTransactionType = {
  PAYMENT: 'payment',
  VENDOR_INVOICE: 'vendor_invoice',
};

export const TransactionPlatform = {
  CURRENCY_CLOUD: 'currency_cloud',
};

export const TransactionStatus = {
  PENDING: 'pending',
  IN_TRANSIT: 'in_transit',
  FAILED: 'failed',
  SUCCESS: 'success',
  CANCELED: 'canceled',
  SETTLED: 'settled',
  SCHEDULED: 'scheduled',
  SUBMITTED: 'submitted',
  DRAFT: 'draft',
};

const TransactionStatusCode = {
  NO_USABLE_GATEWAY: 'no_usable_gateway',
  INSUFFICIENT_FUNDS: 'ach_R01',
  ACCOUNT_CLOSED: 'ach_R02',
  ACCOUNT_NOT_FOUND: 'ach_R03',
  INVALID_ACCOUNT_NUMBER: 'ach_R04',
  UNAUTHORIZED_DEBIT: 'ach_R05',
  AUTHORIZATION_REVOKED: 'ach_R07',
  PAYMENT_STOPPED: 'ach_R08',
  UNCOLLECTED_FUNDS: 'ach_R09',
  ACCOUNT_FROZEN: 'ach_R16',
  NON_TRANSACTION_AMOUNT: 'ach_R20',
  CORPORATE_CUSTOMER_ADVISES_UNAUTHORIZED: 'ach_R29',
};

// Categorization determines the error message shown to the user
export const CATEGORIZED_TRANSACTION_ERROR_CODES = {
  GATEWAY: [TransactionStatusCode.NO_USABLE_GATEWAY],
  DIRECT_DEBIT: [
    TransactionStatusCode.PAYMENT_STOPPED,
    TransactionStatusCode.UNAUTHORIZED_DEBIT,
    TransactionStatusCode.CORPORATE_CUSTOMER_ADVISES_UNAUTHORIZED,
    TransactionStatusCode.AUTHORIZATION_REVOKED,
  ],
  NSF: [
    TransactionStatusCode.INSUFFICIENT_FUNDS,
    TransactionStatusCode.UNCOLLECTED_FUNDS,
  ],
  ACCOUNT_NUMBER: [
    TransactionStatusCode.INVALID_ACCOUNT_NUMBER,
    TransactionStatusCode.ACCOUNT_NOT_FOUND,
    TransactionStatusCode.ACCOUNT_CLOSED,
  ],
};

/*
Configuration to simplify things for customers
pending, submitted, in_transit -> Pending
scheduled -> Scheduled
failed -> Failed
success, settled -> Success
canceled -> Canceled
*/
export const TransactionStatusLabel = {
  [TransactionStatus.PENDING]: i18n.t(
    'data.payments.transactionStatusLabels.pending',
  ),
  [TransactionStatus.IN_TRANSIT]: i18n.t(
    'data.payments.transactionStatusLabels.pending',
  ),
  [TransactionStatus.SUBMITTED]: i18n.t(
    'data.payments.transactionStatusLabels.pending',
  ),
  [TransactionStatus.SCHEDULED]: i18n.t(
    'data.payments.transactionStatusLabels.scheduled',
  ),
  [TransactionStatus.FAILED]: i18n.t(
    'data.payments.transactionStatusLabels.failed',
  ),
  [TransactionStatus.SUCCESS]: i18n.t(
    'data.payments.transactionStatusLabels.success',
  ),
  [TransactionStatus.SETTLED]: i18n.t(
    'data.payments.transactionStatusLabels.success',
  ),
  [TransactionStatus.CANCELED]: i18n.t(
    'data.payments.transactionStatusLabels.canceled',
  ),
  [TransactionStatus.DRAFT]: i18n.t(
    'data.payments.transactionStatusLabels.draft',
  ),
};

export const BillStatus = {
  UNREAD: 'unread',
  PROCESSING: 'processing',
  APPROVING_VENDOR: 'approving_vendor',
  WAITING_ON_FOUNDER: 'waiting_on_founder',
  WAITING_ON_CLEARCO: 'waiting_on_clearco',
  COMPLETED: 'completed',
  ERROR: 'error',
  CANCELLED: 'cancelled',
  SCHEDULED: 'scheduled',
  PAYING_VENDOR: 'paying_vendor',
  WAITING_FOR_PAYMENT: 'waiting_for_payment',
  BNPL_APPROVED: 'bnpl_approved',
  BNPL_DECLINED: 'bnpl_declined',
  AUTO_DEBITING_ACTIVE: 'auto_debiting_active',
};

export const TransactionToBillStatus = {
  [TransactionStatus.SUCCESS]: BillStatus.COMPLETED,
  [TransactionStatus.FAILED]: BillStatus.ERROR,
  [TransactionStatus.CANCELED]: BillStatus.CANCELLED,
  [TransactionStatus.IN_TRANSIT]: BillStatus.PAYING_VENDOR,
  [TransactionStatus.PENDING]: BillStatus.SCHEDULED,
  [TransactionStatus.DRAFT]: BillStatus.PROCESSING,
};

export const BillLabel = {
  [BillStatus.UNREAD]: i18n.t('data.payments.billStatus.unread'),
  [BillStatus.PROCESSING]: i18n.t('data.payments.billStatus.processing'),
  [BillStatus.APPROVING_VENDOR]: i18n.t('data.payments.billStatus.approving'),
  [BillStatus.WAITING_ON_FOUNDER]: i18n.t(
    'data.payments.billStatus.waitingFounder',
  ),
  [BillStatus.WAITING_ON_CLEARCO]: i18n.t(
    'data.payments.billStatus.waitingClearco',
  ),
  [BillStatus.COMPLETED]: i18n.t('data.payments.billStatus.success'),
  [BillStatus.ERROR]: i18n.t('data.payments.billStatus.error'),
  [BillStatus.CANCELLED]: i18n.t('data.payments.billStatus.cancelled'),
  [BillStatus.SCHEDULED]: i18n.t('data.payments.billStatus.scheduled'),
  [BillStatus.PAYING_VENDOR]: i18n.t('paymentsPage.pending'),
  [BillStatus.WAITING_FOR_PAYMENT]: i18n.t(
    'data.payments.billStatus.waitingPayment',
  ),
  [BillStatus.BNPL_APPROVED]: i18n.t('data.payments.billStatus.approved'),
  [BillStatus.BNPL_DECLINED]: i18n.t('data.payments.billStatus.declined'),
  [BillStatus.AUTO_DEBITING_ACTIVE]: i18n.t(
    'data.payments.billStatus.autoDebitingActive',
  ),
};

export const BillStatusLabel = {
  CANCELLED: 'Cancelled',
  DECLINED: 'Declined',
  PROCESSING: 'Processing',
  SCHEDULED: 'Scheduled',
  ACTION_REQUIRED: 'Action Required',
  PAID: 'Paid',
};

export const BillPaymentMethods = {
  CREDIT_CARD: 'credit_card',
  ADVANCE: 'advance',
  BANK_ACCOUNT: 'bank_account',
  BNPL: 'bnpl',
};

export const BillPaymentFees = {
  FREE: 'free',
  CREDIT_CARD_FEES: '2.9%',
};

// TODO: i18n
export const BillPaymentLabels = {
  [BillPaymentMethods.CREDIT_CARD]: 'Credit Card',
  [BillPaymentMethods.ADVANCE]: 'Clearco Advance',
  [BillPaymentMethods.BANK_ACCOUNT]: 'Bank Account',
  [BillPaymentMethods.BNPL]: 'Extension Plan',
};

// TODO: i18n
export const BillStatusTooltipMessages = {
  [BillStatus.PROCESSING]:
    'We are processing your invoice. No action for you to take. We will email you if we need to.',
  [BillStatus.APPROVING_VENDOR]:
    'We are currently verifying the vendor you are trying to pay. Typically takes 1 business day.',
  [BillStatus.WAITING_ON_FOUNDER]:
    'We are waiting for you to take an action. Please read the message below for details.',
  [BillStatus.WAITING_ON_CLEARCO]:
    'We are currently waiting on an internal process to finish.',
  [BillStatus.COMPLETED]: 'Your invoice has been successfully paid.',
  [BillStatus.ERROR]:
    'There is a problem processing your invoice. Please see the message for more details.',
  [BillStatus.CANCELLED]: 'Your invoice payment has been cancelled.',
  [BillStatus.SCHEDULED]: ' Your payment has been scheduled.',
  [BillStatus.PAYING_VENDOR]: 'We are currently paying your vendor.',
  [BillStatus.WAITING_FOR_PAYMENT]:
    'We are waiting for you to pay a balance on your Clear Payments account.',
  [BillStatus.BNPL_APPROVED]: 'Your invoice payment has been approved',
  [BillStatus.BNPL_DECLINED]: 'Your invoice payment has been declined',
  [BillStatus.AUTO_DEBITING_ACTIVE]:
    'Your invoice has been paid and is currently being debitted',
};

const DILIGENCE_DECLINED_REASONS = {
  CASE_CREATED_IN_ERROR: 'Case Created in Error',
  BILL_DENIED_INVALID_FORMAT: 'Bill Denied (Invalid Format)',
  BILL_DENIED_INVALID_AMOUNT: 'Bill Denied (Invalid Amount)',
  BILL_DENIED_PASSED_DUE: 'Bill Denied (Passed Due)',
  DUPLICATED: 'Duplicate',
  EXPIRED: 'Expired',
  INCOMPLETE_INFORMATION: 'Incomplete Information',
  COMPLIANCE_RISK_AML: 'Compliance Risk | AML',
  COMPLIANCE_RISK_KYB: 'Compliance Risk | KYB',
  COMPLIANCE_RISK_KYC: 'Compliance Risk | KYC',
  COMPLIANCE_RISK_PROHIBITED_INDUSTRY: 'Compliance Risk | Prohibited Industry',
  CONCENTRATION_RISK_MONTHY_LIMIT: 'Concentration Risk | Monthly Limit',
  CONCENTRATION_RISK_PORTIFOLIO_LIMIT: 'Concentration Risk | Portfolio Limit',
  FINANCIAL_STATEMENTS_ERROR: 'Financial Statements Error',
  FINANCIAL_STATEMENTS_INCOMPLETE: 'Financial Statements Incomplete',
  FINANCIAL_STATEMENTS_UNAVAILABLE: 'Financial Statements Unavailable',
  INCOME_RISK_GROSS_PROFIT_MARGIN: 'Income Risk | Gross Profit Margin',
  INCOME_RISK_MARKETING_EXPENSE_TO_REVENUE:
    'Income Risk | Marketing Expense to Revenue',
  INCOME_RISK_NET_INCOME_MARGIN: 'Income Risk | Net Income Margin',
  INCOME_RISK_REVENUE_DECLINE: 'Income Risk | Revenue Decline',
  INTERNAL_BUSINESS_RISK_HOSTILE_TAG: 'Internal Business Risk | Hostile Tag',
  INTERNAL_BUSINESS_RISK_ICEBOX_TAG: 'Internal Business Risk | Icebox Tag',
  LIQUIDITY_RISK_CURRENT_RATIO: 'Liquidity Risk | Current Ratio',
  LIQUIDITY_RISK_DEBT_SERVICE_RATIO: 'Liquidity Risk | Debt Service Ratio',
  LIQUIDITY_RISK_DEBT_TO_ASSETS: 'Liquidity Risk | Debt to Assets',
  LIQUIDITY_RISK_QUICK_RATIO: 'Liquidity Risk | Quick Ratio',
  SALES_LOST: 'Sales Lost',
  TECH_BLOCKER_OTHER: ' Tech Blocker | Other',
  TECH_BLOCKER_PLAID_CONNECTION: 'Tech Blocker | Plaid Connection',
  TECH_BLOCKER_REVENUE_CONNECTION: 'Tech Blocker | Revenue Connection',
  INVOICE_AMOUNT_EXCEEDS_MAX_OFFER: 'Invoice Amount Exceeds Max Offer',
  VENDOR_DENIED_KYB_FAILED: 'Vendor Denied (KYB Failed)',
  VENDOR_DENIED_UNSUPPORTED_COUNTRY_OR_CURRENCY:
    'Vendor Denied (Unsupported Country or Currency)',
  VENDOR_DENIED_INVALID_FORMAT: 'Vendor Denied (Invalid Format)',
  VENDOR_DENIED_INVALID_AMOUNT: 'Vendor Denied (Invalid Amount)',
  VENDOR_DENIED_VENDOR_DENIED: 'Vendor Denied (Passed Due)',
  VENDOR_DENIED_UNSUPPORTED_SPEND_CATEGORY:
    'Vendor Denied (Unsupported Spend Category)',
  VENDOR_DENIED_UNSUPPORTED_VENDOR_FOR_RECEIPT:
    'Vendor Denied (Unsupported Vendor for Receipt)',
  SHOULD_BE_AN_INVOICE: 'Should be an Invoice',
  SHOULD_BE_A_RECEIPT: 'Should be a Receipt',
  OVER_100K_30_DAYS: 'Over 500K per 30 days limit',
  OTHER: 'Other',
};

export const VENDOR_DENIED_REASONS = [
  DILIGENCE_DECLINED_REASONS.VENDOR_DENIED_KYB_FAILED,
  DILIGENCE_DECLINED_REASONS.VENDOR_DENIED_UNSUPPORTED_COUNTRY_OR_CURRENCY,
  DILIGENCE_DECLINED_REASONS.VENDOR_DENIED_INVALID_FORMAT,
  DILIGENCE_DECLINED_REASONS.VENDOR_DENIED_INVALID_AMOUNT,
  DILIGENCE_DECLINED_REASONS.VENDOR_DENIED_VENDOR_DENIED,
  DILIGENCE_DECLINED_REASONS.VENDOR_DENIED_UNSUPPORTED_SPEND_CATEGORY,
  DILIGENCE_DECLINED_REASONS.VENDOR_DENIED_UNSUPPORTED_VENDOR_FOR_RECEIPT,
];

export const isVendorDenied = (status) => {
  return VENDOR_DENIED_REASONS.includes(status);
};

export const BillDeclinedDescription = {
  [DILIGENCE_DECLINED_REASONS.NOT_VALID_VENDOR]: `The receipt you submitted is for a vendor that is not currently on our eligible vendor list. The current list that can be found <a href="https://help.clear.co/s/article/Approved-vendors?language=en_US" target="_new">here.</a>`,
  [DILIGENCE_DECLINED_REASONS.OVERDUE]: `We do not provide funding where the receipt has been issued more than 45 days from your receipt funding application submission date. Please submit application for anothe receipt that was issued within 45 days of payment.`,
  [DILIGENCE_DECLINED_REASONS.SHOULD_BE_INVOICE]: `You submitted an invalid receipt. Please resubmit with a valid receipt.`,
  [DILIGENCE_DECLINED_REASONS.OVER_100K_30_DAYS]: `The maximum receipt funding submission amount is your maximum Funding Capacity up to an upper limit of $200,000 USD per 30 day period.`,
  [DILIGENCE_DECLINED_REASONS.NOT_ECOMMERCE]: `Your business is in a category that we can’t fund at this time. To learn more about the businesses we can and can’t fund, <a href="https://help.clear.co/s/article/Invoice-Funding-Eligibility-Requirements?language=en_US" target="_new">click here.</a>`,
  [DILIGENCE_DECLINED_REASONS.LIQUIDITY_ISSUE]: `Your financial health did not meet the threshold required in our diligence review.`,
  [DILIGENCE_DECLINED_REASONS.DECLINING_REVENUE]: `Your revenue is trending downwards or is inconsistent. Please feel free to add additional revenue accounts and reapply. If you have connected all your available sales processors, please feel free to reapply when your revenue is more consistent and/or beings to increase (2-3 Months).`,
  [DILIGENCE_DECLINED_REASONS.MAX_RR_REACHED_CORE]: `Your business has reached it's current maximum Funding Capacity. Please reapply out when you have repaid 80-100% of your current advance and/or your revenues have increased.`,
  [DILIGENCE_DECLINED_REASONS.MAX_RR_REACHED_BNPL]: `Your business has reached it's current maximum Funding Capacity. Please reapply out when you have repaid 80-100% of your current advance and/or your revenues have increased.`,
  [DILIGENCE_DECLINED_REASONS.PROHIBITED_INDUSTRY]: `Your business is in a category that we can’t fund at this time. To learn more about the businesses we can and can’t fund, <a href="https://help.clear.co/s/article/International-Payments-and-Foreign-Exchange?language=en_US" target="_new">click here.</a>`,
  [DILIGENCE_DECLINED_REASONS.COMPLIANCE_ISSUE]: `Your business is in a category that we can’t fund at this time. To learn more about the businesses we can and can’t fund, <a href="https://help.clear.co/s/article/International-Payments-and-Foreign-Exchange?language=en_US" target="_new">click here.</a>`,
  [DILIGENCE_DECLINED_REASONS.INVOICE_AMOUNT_EXCEEDS_MAX_OFFER]: `The invoice amount submitted exceeds the maximum Funding Capacity available.`,
  [DILIGENCE_DECLINED_REASONS.VENDOR_DENIED_KYB_FAILED]: `The selected vendor does not meet our compliance criteria.`,
  [DILIGENCE_DECLINED_REASONS.VENDOR_DENIED_NOT_FUNDABLE]: `Clearco currently does not send funds directly to customer's bank accounts for invoice funding.`,
  [DILIGENCE_DECLINED_REASONS.VENDOR_DENIED_COUNTRY_OR_CC]: `The selected vendor/invoice falls outside the countries and/or curriencies Clearco is able to fund. <a href="https://help.clear.co/s/article/International-Payments-and-Foreign-Exchange?language=en_US" target="_blank">Learn More.</a>`,
  [DILIGENCE_DECLINED_REASONS.INVOICE_DENIED_FORMAT]: `Unfortunately, our system is unable to read your invoice. Please submit a valid PDF invoice.`,
  [DILIGENCE_DECLINED_REASONS.INVOICE_DENIED_PAST_DUE]: `We do not provide funding where the invoice is more than 60 days past due. Please submit another invoice that is no more than 60 days past due.`,
  [DILIGENCE_DECLINED_REASONS.INVOICE_DENIED_AMOUNT]: `The amount you have entered exceeds the total amount due on your submission. Please resubmit to match the amount on the upload.`,
};

// TODO: i18n
export const messageToFounderTitles = {
  completed: 'Payment complete',
  waiting_on_founder: 'Action required',
  error: 'Error',
};

// List of tracked CTAs for ClearPay
export const CLEARPAY_CTA_SOURCES = {
  paymentsList: 'payments-list',
  onboardingBanner: 'onboarding-banner',
  repaymentsPage: 'repayments-page',
  userCapacity: 'user-capacity',
  joinWaitlistBanner: 'join-waitlist-banner',
};

// ClearPay 1.0 Segment Events
export const CLEARPAY_SEGMENT_EVENTS = {
  VIEWED_DASHBOARD: 'clearpay_1_0_viewed_dashboard',
  CLICKED_ADD_BILL: 'clearpay_1_0_clicked_add_bill',
  CLICKED_ADD_BILL_LIST: 'clearpay_1_0_clicked_add_bill_list',
  CLICKED_ADD_BILL_BLANK: 'clearpay_1_0_clicked_add_bill_blank',
  CLICKED_BILL_DETAILS: 'clearpay_1_0_viewed_bill_detail',
  CLICKED_BNPL_BILL_DETAILS: 'clearpay_1_0_viewed_bnpl_bill_detail',
  CLICKED_PAY_CARD: 'clearpay_1_0_clicked_pay_card',
  CLICKED_PAY_BANK: 'clearpay_1_0_clicked_pay_bank',
  CLICKED_PAY_ADVANCE: 'clearpay_1_0_clicked_pay_advance',
  CLICKED_BNPL_PAY_LATER: 'clearpay_1_0_clicked_bnpl_pay_later',
  CLICKED_ADD_BNPL_BILL: 'clearpay_1_0_clicked_add_bnpl_bill',
  CLICKED_ADD_BNPL_BILL_LIST: 'clearpay_1_0_clicked_add_bnpl_bill_list',
  CLICKED_ADD_BNPL_BILL_BLANK: 'clearpay_1_0_clicked_add_bnpl_bill_blank',
  CLICKED_ADD_BNPL_BILL_CAPACITY: 'clearpay_1_0_clicked_add_bnpl_bill_capacity',
  CLICKED_ADD_BNPL_BILL_REPAYMENTS_PAGE:
    'clearpay_1_0_clicked_add_bnpl_bill_repayments_page',
  CLICKED_BNPL_SCHEDULE_NEXT: 'clearpay_1_0_clicked_bnpl_payment_schedule_next',
  CLICKED_BNPL_REPAYMENTS_TOGGLE: 'clearpay_1_0_clicked_bnpl_repayments_toggle',
  CLICKED_BNPL_CONFIRM_APPLY: 'clearpay_1_0_clicked_bnpl_confirm_apply',
  CLICKED_CREDIT_CARD_CONFIRM_APPLY:
    'clearpay_1_0_clicked_credit_card_confirm_apply',
  CLICKED_BANK_ACCOUNT_CONFIRM_APPLY:
    'clearpay_1_0_clicked_bank_account_confirm_apply',
  CLICKED_ADVANCE_CONFIRM_APPLY: 'clearpay_1_0_clicked_advance_confirm_apply',
  CLICKED_BNPL_REVIEW_AGREEMENT: 'clearpay_1_0_clicked_bnpl_review_agreement',
  SIGNED_BNPL_CONTRACT: 'clearpay_1_0_signed_bnpl_contract',
  CONFIRMED_EMAIL: 'clearpay_1_0_confirmed_email',
  CONNECT_ACCOUNTS: 'clearpay_1_0_clicked_connect_account',
  SELECTED_REVENUE_ABOVE_10K: 'clearpay_1_0_selected_revenue_above_10k',
  SELECTED_REVENUE_BELOW_10K: 'clearpay_1_0_selected_revenue_below_10k',
  SUBMITTED_BASIC_PROFILE: 'clearpay_1_0_submitted_basic_profile',
  SUBMITTED_BUSINESS_PROFILE: 'clearpay_1_0_submitted_business_profile',
  SUBMITTED_REGISTERED_ADDRESS: 'clearpay_1_0_submitted_registered_address',
  STARTED_BILL_UPLOAD: 'clearpay_1_0_started_bill_upload',
  FOUNDER_PROVIDED_PAYMENT_DETAILS:
    'clearpay_1_0_founder_provided_payment_details',
  CLEARPAY_BNPL_WAITLISTED: 'clearpay_1_0_bnpl_waitlisted',
  VIEWED_INVOICE_MODAL_CHOOSE_PLAN:
    'clearpay_1_0_viewed_invoice_modal_choose_plan',
  VIEWED_BILL_DETAILS_FORM: 'clearpay_1_0_viewed_modal_bill_details',
  VIEWED_INVOICE_MODAL_ADD_INVOICE:
    'clearpay_1_0_viewed_invoice_modal_add_invoice',
  VIEWED_INVOICE_MODAL_ADD_RECEIPT:
    'clearpay_1_0_viewed_invoice_modal_add_receipt',
  VIEWED_INVOICE_MODAL_PLAN_DETAILS:
    'clearpay_1_0_viewed_invoice_modal_plan_details',
  VIEWED_INVOICE_MODAL_VENDOR_DETAILS:
    'clearpay_1_0_viewed_invoice_modal_vendor_details',
  FE_DATE_PICKED: 'fe_date_picked',
  VIEWED_SUBMISSION_TYPE_MODAL:
    'clearpay_1_0_viewed_choose_submission_type_modal',
  CLICKED_INVOICE_OR_RECEIPT: 'fe_submission_type_clicked',
  AMOUNT_ERROR_MORE_THAN_100K: 'fe_extension_plan_selection_error_triggered',
  FE_DATE_ERROR: 'date_picker_error',
  CLICKED_CAPACITY_EDUCATION: 'fe_capacity_education_clicked',
  CLICKED_DOWNLOAD_PAYMENT_CONFIRMATION: 'fe_download_confirmation_clicked',
  RECEIPT_VENDOR_SUBMITTED: 'fe_receipt_vendor_submitted',
  BE_STATUS_ERROR: 'be_status_error',
  FE_CHECK_TIMELINE_STATUS: 'fe_check_timeline_status',
};

export const HELP_CENTER_URL = 'https://clear.co/faq/';

export const CANCEL_WINDOW_DAYS = 3;

export const CANCELLATION_NOT_WITHIN_WINDOW =
  'Cancellation request NOT within their cancellation window';

export const BNPL_SCHEDULE_RANGE = [
  { value: 1, label: 'month' },
  { value: 2, label: 'months' },
  { value: 3, label: 'months' },
  { value: 4, label: 'months' },
  { value: 5, label: 'months' },
  { value: 6, label: 'months' },
];

export const PAYMENTS_GETTERS = {
  [PAYMENTS_STATE_MODULE]: {
    experimentName: 'experimentName',
    featureFlags: 'featureFlags',
    firstIncompleteStep: 'firstIncompletePaymentsOnboardingStep',
    steps: 'steps',
    previousStepName: 'previousPaymentsStepName',
  },
};

export const PAYMENTS_STEPS = {
  [PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS_PERSONA]: {
    name: PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS_PERSONA,
    nextRoute: {
      name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
    },
    title: () => i18n.t('data.onboarding.steps.businessDetails.title'),
    subtitle: () =>
      'Provide business details and documents to help us verify your business and generate contracts.',
    complete: false,
    skippable: false,
  },
  [PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS]: {
    name: PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS,
    nextRoute: {
      name: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_REGISTERED_ADDRESS,
    },
    title: () => i18n.t('data.onboarding.steps.businessDetails.title'),
    subtitle: () => i18n.t('data.onboarding.steps.businessDetails.subtitle'),
    complete: false,
    skippable: false,
  },
  [PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_REGISTERED_ADDRESS]: {
    name: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_REGISTERED_ADDRESS,
    nextRoute: {
      name: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_INCORPORATION_DETAILS,
    },
    title: () =>
      i18n.t('data.onboarding.steps.businessRegisteredAddress.title'),
    subtitle: () =>
      i18n.t('data.onboarding.steps.businessRegisteredAddress.subtitle'),
    complete: false,
    skippable: false,
  },
  [PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_INCORPORATION_DETAILS]: {
    name: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_INCORPORATION_DETAILS,
    nextRoute: { name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD },
    title: () => i18n.t('data.onboarding.steps.businessIncorpDocs.title'),
    subtitle: () => i18n.t('data.onboarding.steps.businessIncorpDocs.subtitle'),
    complete: false,
    skippable: false,
  },
  [PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS]: {
    name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS,
    nextRoute: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE },
    complete: true,
    skippable: true,
    firstStepOfForm: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE },
    formCompleteStep: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST },
  },
  [PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE]: {
    name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE,
    title: () => i18n.t('data.onboarding.steps.ownersStepOne.title'),
    subtitle: () => i18n.t('data.onboarding.steps.ownersStepOne.subtitle'),
    subtitle2: () => ({
      title: i18n.t('data.onboarding.steps.ownersStepOne.subtitle2.title'),
      link: i18n.t('data.onboarding.steps.ownersStepOne.subtitle2.link'),
      href: i18n.t('data.onboarding.steps.ownersStepOne.subtitle2.href'),
    }),
    nextRoute: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_TWO },
    complete: false,
    skippable: false,
    firstStepOfForm: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE },
    formCompleteStep: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST },
  },
  [PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_TWO]: {
    name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_TWO,
    title: () => i18n.t('data.onboarding.steps.ownersStepTwo.title'),
    subtitle: () => i18n.t('data.onboarding.steps.ownersStepTwo.subtitle'),
    subtitle2: () => ({
      title: i18n.t('data.onboarding.steps.ownersStepTwo.subtitle2.title'),
      link: i18n.t('data.onboarding.steps.ownersStepTwo.subtitle2.link'),
      href: i18n.t('data.onboarding.steps.ownersStepTwo.subtitle2.href'),
    }),
    nextRoute: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST },
    formCompleteStep: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST },
    complete: true,
    firstStepOfForm: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE },
    skippable: false,
  },
  [PAYMENTS_ROUTE_NAMES.PROFILE_NEW_OWNER]: {
    name: PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS_PERSONA,
    nextRoute: {
      name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
    },
    title: () => i18n.t('data.onboarding.steps.ownersStepNew.title'),
    subtitle: () => i18n.t('data.onboarding.steps.ownersStepNew.subtitle'),
    subtitle2: () => ({
      title: i18n.t('data.onboarding.steps.ownersStepNew.subtitle2.title'),
      link: i18n.t('data.onboarding.steps.ownersStepNew.subtitle2.link'),
      href: i18n.t('data.onboarding.steps.ownersStepNew.subtitle2.href'),
    }),
    complete: false,
    skippable: false,
  },
  [PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST]: {
    name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST,
    title: () => i18n.t('data.onboarding.steps.ownersStepOne.title'),
    subtitle: () => i18n.t('data.onboarding.steps.ownersStepOne.subtitle'),
    subtitle2: () => ({
      title: i18n.t('data.onboarding.steps.ownersStepOne.subtitle2.title'),
      link: i18n.t('data.onboarding.steps.ownersStepOne.subtitle2.link'),
      href: i18n.t('data.onboarding.steps.ownersStepOne.subtitle2.href'),
    }),
    ctaText: i18n.t('common.buttons.next'),
    nextRoute: { name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD },
    redirectRoute: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE },
    complete: false,
    firstStepOfForm: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE },
    formCompleteStep: { name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST },
    skippable: false,
  },
  [PAYMENTS_ROUTE_NAMES.PERSONA_PROFILE_OWNERS]: {
    name: PAYMENTS_ROUTE_NAMES.PERSONA_PROFILE_OWNERS,
    title: () => i18n.t('data.onboarding.steps.personaOwners.title'),
    subtitle: () => i18n.t('data.onboarding.steps.personaOwners.subtitle'),
    nextRoute: { name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD },
    complete: false,
    skippable: false,
  },
};

export const PAYMENTS_CONFIG = {
  [PAYMENTS_STATE_MODULE]: {
    steps: PAYMENTS_STEPS,
  },
};

export const PAYMENTS_ACTIONS = {
  [PAYMENTS_STATE_MODULE]: {
    UPDATE_ALL_PAYMENTS_BIZ_PROFILE_STEPS:
      'UPDATE_ALL_PAYMENTS_BIZ_PROFILE_STEPS',
    UPDATE_ALL_PAYMENTS_ONBOARDING_STEPS:
      'UPDATE_ALL_PAYMENTS_ONBOARDING_STEPS',
    UPDATE_PAYMENTS_ONBOARDING_STEP: 'UPDATE_PAYMENTS_ONBOARDING_STEP',
    UPDATE_PAYMENTS_BIZ_PROFILE_STEP: 'UPDATE_PAYMENTS_BIZ_PROFILE_STEP',
    RESET_SELECTED_BILL: 'RESET_SELECTED_BILL',
    UPDATE_STEP: 'UPDATE_PAYMENTS_STEP',
  },
};

export const PAYMENTS_TRACKING_PARAMS = [
  'utm_campaign',
  'utm_content',
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_id',
  'partner_key',
  'sub_id',
  'campaign_id',
  'gclid',
  'amplitude_id',
  'cb-ex',
];

export const BNPL_TYPES = {
  LOAN: 'Loan',
  CAPPED_IRR_MCA: 'Capped IRR MCA',
};

export const PAYMENT_PROFILE_NOT_FOUND = 'payments profile not found';

export const PAY_OUT_CURRENCY_OPTIONS = {
  USD: {
    symbol: '$',
    name: 'US Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'USD',
    name_plural: 'US dollars',
  },
  CAD: {
    symbol: 'CA$',
    name: 'Canadian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'CAD',
    name_plural: 'Canadian dollars',
  },
  GBP: {
    symbol: '£',
    name: 'British Pound Sterling',
    symbol_native: '£',
    decimal_digits: 2,
    rounding: 0,
    code: 'GBP',
    name_plural: 'British pounds sterling',
  },
  EUR: {
    symbol: '€',
    name: 'Euro',
    symbol_native: '€',
    decimal_digits: 2,
    rounding: 0,
    code: 'EUR',
    name_plural: 'euros',
  },
  AUD: {
    symbol: 'AU$',
    name: 'Australian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'AUD',
    name_plural: 'Australian dollars',
  },
};

export const DEFAULT_CURRENCY = 'USD';
export function getPaymentsRevenueBands(currency = '') {
  return [
    { name: () => `${currency}0-${currency}10K`, value: '0-10k' },
    { name: () => `${currency}10K-${currency}20K`, value: '10k-20k' },
    { name: () => `${currency}20K-${currency}50K`, value: '20k-50k' },
    { name: () => `${currency}50K-${currency}100K`, value: '50k-100k' },
    { name: () => `${currency}100K-${currency}250K`, value: '100k-250k' },
    { name: () => `${currency}250K-${currency}500K`, value: '250k-500k' },
    { name: () => `${currency}500K-${currency}1M`, value: '500k-1m' },
    { name: () => `${currency}1M-${currency}5M`, value: '1m-5m' },
    { name: () => `${currency}5M+`, value: '5m+' },
  ];
}
export const V2_CAPACITY_ERROR_TYPES = {
  CAPACITY_DISQUALIFIED: 'capacity_disqualified',
  DISCONNECTED_ECOM: 'disconnected_ecom',
  CAPACITY_NOT_FOUND: 'capacity_not_found',
};

export const PAY_IN_PAY_OUT_STATUS = {
  UNREAD: 'unread',
  ERROR: 'error',
  CANCELLED: 'cancelled',
  PROCESSING: 'processing',
  IN_DILIGENCE: 'in_diligence',
  DILIGENCE_PASSED: 'diligence_passed',
  DILIGENCE_FAILED: 'diligence_failed',
  CONTRACT_SIGNED: 'contract_signed',
  PAYMENT_PENDING: 'payment_pending',
  PAYMENT_COMPLETED: 'payment_completed',
  AUTO_DEBITING_ACTIVE: 'auto_debiting_active',
  ACCOUNT_PENDING: 'account_pending',
  CAPITAL_RECOUPED: 'capital_recouped',
};

export const PAY_IN_PAY_OUT_STATUS_LOCKED_BY_DILIGENCE = [
  PAY_IN_PAY_OUT_STATUS.IN_DILIGENCE,
  PAY_IN_PAY_OUT_STATUS.DILIGENCE_PASSED,
  PAY_IN_PAY_OUT_STATUS.DILIGENCE_FAILED,
  PAY_IN_PAY_OUT_STATUS.CONTRACT_SIGNED,
  PAY_IN_PAY_OUT_STATUS.PAYMENT_PENDING,
  PAY_IN_PAY_OUT_STATUS.PAYMENT_COMPLETED,
  PAY_IN_PAY_OUT_STATUS.AUTO_DEBITING_ACTIVE,
  PAY_IN_PAY_OUT_STATUS.CAPITAL_RECOUPED,
];

export const BNPL_USER_CATEGORIES = {
  NET_NEW: 'net_new',
  ACTIVE_VALID_ADVANCE: 'active_valid_advance',
  CLOSED_LOST_VALID_ADVANCE: 'closed_lost_valid_advance',
  PAST_VALID_ADVANCE_ONLY: 'past_valid_advance_only',
};

const WAITLIST_BANNER_MESSAGING = {
  [BNPL_USER_CATEGORIES.NET_NEW]: {
    [COUNTRY_CODES.US]: {},
    [COUNTRY_CODES.CA]: {
      title: 'paymentsPage.waitlistBanner.netNew.ca.title',
      paragraph1: 'paymentsPage.waitlistBanner.netNew.ca.paragraph1',
      paragraph2: 'paymentsPage.waitlistBanner.netNew.ca.paragraph2',
    },
    INTERNATIONAL: {
      title: 'paymentsPage.waitlistBanner.netNew.international.title',
      paragraph1: 'paymentsPage.waitlistBanner.netNew.international.paragraph1',
      linkText: 'paymentsPage.waitlistBanner.netNew.international.linkText',
      linkUrl:
        'https://help.clear.co/s/article/Invoice-Funding-Eligibility-Requirements?language=en_US',
    },
  },
  [BNPL_USER_CATEGORIES.ACTIVE_VALID_ADVANCE]: {
    [COUNTRY_CODES.US]: {
      title: 'paymentsPage.waitlistBanner.activeValidAdvance.us.title',
      paragraph1:
        'paymentsPage.waitlistBanner.activeValidAdvance.us.paragraph1',
    },
    [COUNTRY_CODES.CA]: {
      title: 'paymentsPage.waitlistBanner.activeValidAdvance.ca.title',
      paragraph1:
        'paymentsPage.waitlistBanner.activeValidAdvance.ca.paragraph1',
      paragraph2:
        'paymentsPage.waitlistBanner.activeValidAdvance.ca.paragraph2',
      paragraph3:
        'paymentsPage.waitlistBanner.activeValidAdvance.ca.paragraph3',
      p2LinkText:
        'paymentsPage.waitlistBanner.activeValidAdvance.ca.p2LinkText',
      paragraph2NoLink:
        'paymentsPage.waitlistBanner.activeValidAdvance.ca.paragraph2NoLink',
      supportEmail:
        'paymentsPage.waitlistBanner.activeValidAdvance.ca.supportEmail',
    },
    INTERNATIONAL: {},
  },
  [BNPL_USER_CATEGORIES.CLOSED_LOST_VALID_ADVANCE]: {
    [COUNTRY_CODES.US]: {
      title: 'paymentsPage.waitlistBanner.closedLostValidAdvance.us.title',
      paragraph1:
        'paymentsPage.waitlistBanner.closedLostValidAdvance.us.paragraph1',
    },
    [COUNTRY_CODES.CA]: {
      title: 'paymentsPage.waitlistBanner.closedLostValidAdvance.ca.title',
      paragraph1:
        'paymentsPage.waitlistBanner.closedLostValidAdvance.ca.paragraph1',
      paragraph2:
        'paymentsPage.waitlistBanner.closedLostValidAdvance.ca.paragraph2',
    },
    INTERNATIONAL: {
      title: 'paymentsPage.waitlistBanner.netNew.international.title',
      paragraph1: 'paymentsPage.waitlistBanner.netNew.international.paragraph1',
      linkText: 'paymentsPage.waitlistBanner.netNew.international.linkText',
      linkUrl:
        'https://help.clear.co/s/article/Invoice-Funding-Eligibility-Requirements?language=en_US',
    },
  },
  [BNPL_USER_CATEGORIES.PAST_VALID_ADVANCE_ONLY]: {
    [COUNTRY_CODES.US]: {
      title: 'paymentsPage.waitlistBanner.pastValidAdvanceOnly.us.title',
      paragraph1:
        'paymentsPage.waitlistBanner.pastValidAdvanceOnly.us.paragraph1',
    },
    [COUNTRY_CODES.CA]: {
      title: 'paymentsPage.waitlistBanner.pastValidAdvanceOnly.ca.title',
      paragraph1:
        'paymentsPage.waitlistBanner.pastValidAdvanceOnly.ca.paragraph1',
      paragraph2:
        'paymentsPage.waitlistBanner.pastValidAdvanceOnly.ca.paragraph2',
    },
    INTERNATIONAL: {
      title: 'paymentsPage.waitlistBanner.netNew.international.title',
      paragraph1: 'paymentsPage.waitlistBanner.netNew.international.paragraph1',
      linkText: 'paymentsPage.waitlistBanner.netNew.international.linkText',
      linkUrl:
        'https://help.clear.co/s/article/Invoice-Funding-Eligibility-Requirements?language=en_US',
    },
  },
};

export const PAYMENT_SCHEME_TYPES = {
  LOCAL: 'local',
  REGULAR: 'regular', // to deprecate once update in bene service
  PRIORITY: 'priority', // equivalent to wire transfer
};

export function waitlistBannerText(
  userCategory,
  countryCode,
  forceBnplFlag = false,
  hasTakenBnplAdvance = false,
) {
  // No country code --> user is prob logged out --> no banner
  if (!countryCode || forceBnplFlag) return {};
  else if ([COUNTRY_CODES.US, COUNTRY_CODES.CA].includes(countryCode)) {
    if (hasTakenBnplAdvance) return {};
    return WAITLIST_BANNER_MESSAGING[userCategory][countryCode] ?? {};
  }
  if (hasTakenBnplAdvance) return {};
  return WAITLIST_BANNER_MESSAGING[userCategory].INTERNATIONAL;
}
