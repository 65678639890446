// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader_T5g7w{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:10;opacity:75%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "loader_T5g7w"
};
export default ___CSS_LOADER_EXPORT___;
