// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body-container_SO6L1{margin:auto;padding-bottom:30px}@media only screen and (min-width:501px){.body-container_SO6L1{width:90%}}.body-container_SO6L1 .body-paragraph_lIfWx{text-align:left;font-weight:400;color:#000;font-family:Montserrat;margin:0 0 2em;top:0}.body-container_SO6L1 .login-cta_bGeDE{margin-top:50px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body-container": "body-container_SO6L1",
	"body-paragraph": "body-paragraph_lIfWx",
	"login-cta": "login-cta_bGeDE"
};
export default ___CSS_LOADER_EXPORT___;
