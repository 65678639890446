<template>
  <onboardingModal
    ref="amazonadsModal"
    :title="$t('components.connectAmazonAds.connectYourAmazonAdAccount')"
    large
    @open="$emit('modalAction', MARKETING_PLATFORMS.AMAZONADS, 'open')"
    @close="$emit('modalAction', MARKETING_PLATFORMS.AMAZONADS, 'close')"
  >
    <div v-if="!countrySelected">
      <p>
        {{ $t('components.connectAmazonAds.selectYourAmazonMarketplace') }}
      </p>
      <br />
      <br />
      <InputSelection
        external-label="Amazon Marketplace"
        name="market"
        :on-change="countryChanged"
        :list="amazonCountryOptions"
        :placeholder="$t('components.connectAmazonAds.selectARegion')"
        size="small"
        required
        selected-item-value="0"
      />
    </div>

    <LayoutModalAds v-if="countrySelected">
      <ol>
        <li class="m-b-10">
          <i18n-t
            keypath="components.connectAmazonAds.logIntoSellerCentral"
            tag="p"
          >
            <template #accountInformation>
              <a
                target="_blank"
                :href="`https://sellercentral.amazon.com/hz/sc/account-information`"
              >
                {{ $t('components.connectAmazonAds.accountInformation') }}
              </a>
            </template>
          </i18n-t>
        </li>
        <li class="m-b-10">
          <i18n-t
            keypath="components.connectAmazonAds.clickOnMerchantToken"
            tag="p"
          >
            <template #merchantToken>
              <span>
                {{ $t('components.connectAmazonAds.merchantToken') }}
              </span>
            </template>
          </i18n-t>
        </li>
        <li class="m-b-10">
          <i18n-t
            keypath="components.connectAmazonAds.enterYourSellerId"
            tag="p"
          >
            <template #sellerId>
              <span>
                {{ $t('components.connectAmazonAds.sellerId') }}
              </span>
            </template>
          </i18n-t>
          <inputWithEyebrow
            v-model="amazonadsAuth.sellerId"
            type="text"
            autofill="off"
            :placeholder="$t('components.connectAmazonAds.sellerId')"
            :error-msg="$t('common.errors.mandatory')"
            required
            name="amazonSellerId"
            class="m-b-20"
            :validate-on-blur="validateOnBlur"
          />
          <onboardingUiMsg
            v-if="!!errorMessage"
            type="error"
            is-closable
            @close="errorMessage = ''"
          >
            <p>{{ errorMessage }}</p>
            {{ $t('components.connectAmazonAds.ifIssuePersists') }}
            <a class="error" href="mailto:support@clear.co" target="_blank">
              support@clear.co
            </a>
          </onboardingUiMsg>
          <onboarding2Cta
            :text="$t('common.buttons.connect')"
            class="m-b-20"
            theme="modals"
            @click="handleSubmit"
          />
          {{
            $t('components.connectAmazonAds.marketplaceChangeRegion', {
              regionName,
            })
          }}
          <a href="#" @click.prevent="showCountrySection()">
            {{ $t('common.address.region') }}
          </a>
        </li>
      </ol>
    </LayoutModalAds>
  </onboardingModal>
</template>
<script>
import {
  getAmazonCountryMapping,
  amazonCountryOptions,
} from '@/utils/external-accounts';
import { validateAmazonSellerMixin, isMobileMixin } from '@/utils/vue-mixins';
import stateModuleAware from '@/mixins/state-module-aware';
import { MARKETING_PLATFORMS } from '@/data/platforms';
import { COUNTRY_CODES } from '@/data/supported-country-codes';
import { validateChildren } from '@/composables/validation';
import OnboardingUiMsg from '@/components/UiMsg';
import InputWithEyebrow from '@/components/inputs/InputWithEyebrow';
import OnboardingModal from '@/components/modals/ModalOnboarding2';
import Onboarding2Cta from '@/components/buttons/ButtonOnboarding2';
import InputSelection from '@/components/inputs/InputSelection';
import LayoutModalAds from '@/components/layouts/LayoutModalAds';

export default {
  components: {
    InputSelection,
    OnboardingUiMsg,
    InputWithEyebrow,
    OnboardingModal,
    Onboarding2Cta,
    LayoutModalAds,
  },
  mixins: [validateAmazonSellerMixin, isMobileMixin, stateModuleAware],
  setup() {
    const { hasError } = validateChildren();
    return {
      COUNTRY_CODES,
      hasError,
    };
  },
  props: {
    market: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      errorMessage: '',
      hitValidate: false,
      validateOnBlur: false,
      amazonadsAuth: {
        sellerId: '',
        market: '',
      },
    };
  },
  beforeMount() {
    // Set default to North America
    if (this.market === COUNTRY_CODES.GB) {
      this.amazonadsAuth.market = COUNTRY_CODES.EU;
    } else {
      this.amazonadsAuth.market = COUNTRY_CODES.US;
    }
  },
  computed: {
    countrySelected() {
      return !!this.amazonadsAuth.market;
    },
    regionName() {
      return getAmazonCountryMapping(this.amazonadsAuth.market).label();
    },
    MARKETING_PLATFORMS: () => MARKETING_PLATFORMS,
    isInvalid() {
      return this.hitValidate && !!this.errorMessage;
    },
    amazonCountryOptions: () => {
      const countryOptions = [];
      amazonCountryOptions.forEach((element) => {
        countryOptions.push({ label: element.name(), ...element });
      });
      return countryOptions;
    },
  },
  methods: {
    open() {
      this.errorMessage = '';
      this.hitValidate = false;
      this.$refs.amazonadsModal.open();
    },
    countryChanged(country) {
      if (country) this.amazonadsAuth.market = country.value;
    },
    showCountrySection() {
      this.amazonadsAuth.market = '';
    },
    async handleSubmit() {
      this.validateOnBlur = true;

      const error = await this.hasError();
      if (error || this.invalidAmazonSellerID(this.amazonadsAuth.sellerId)) {
        this.$emit('error');
        return;
      }

      this.$refs.amazonadsModal?.close();
      this.$emit('submit', {
        market: this.amazonadsAuth.market,
        sellerId: this.amazonadsAuth.sellerId,
      });
    },
  },
};
</script>
