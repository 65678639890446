<template>
  <div>
    <div :class="$style.modalContext">
      {{ $t('components.bankInfo.title') }}
    </div>
    <custom-fieldset>
      <!-- Bank Name-->
      <UiInput
        name="bankName"
        type="text"
        :label="$t('common.bankInfo.bankName')"
        :model-value="selectedVendor.bankName"
        @input="setVendorProps"
      />
      <!-- Recipient Bank Account Name-->
      <UiInput
        name="nameOnAccount"
        type="text"
        :label="$t('components.bankInfo.recipientBankName')"
        :error-msg="$t('common.errors.mandatory')"
        :model-value="selectedVendor.nameOnAccount"
        required
        @input="setVendorProps"
      />
    </custom-fieldset>
    <custom-fieldset>
      <!-- Bank Account Number -->
      <UiInput
        v-if="requiredVendorFields.includes('acctNumber')"
        name="accountNumber"
        type="text"
        :label="$t('common.bankInfo.bankNumber')"
        :model-value="selectedVendor.accountNumber"
        required
        :error="regexValidator(selectedVendor.accountNumber, 'acctNumber')"
        :error-msg="
          validationMsg(
            selectedVendor.accountNumber,
            $t('common.bankInfo.bankNumberError'),
          )
        "
        @input="setVendorProps"
      />
      <!-- BIC/SWIFT Code-->
      <UiInput
        v-if="requiredVendorFields.includes('bicSwift')"
        name="swiftCode"
        type="text"
        :label="$t('common.bankInfo.swiftCode')"
        :model-value="selectedVendor.swiftCode"
        :error="
          regexValidator(selectedVendor.swiftCode, 'bicSwift') &&
          currencyCloudDependantRequirements('bicSwift')
        "
        :error-msg="
          validationMsg(
            selectedVendor.swiftCode,
            $t('common.bankInfo.swiftCodeError'),
          )
        "
        :required="currencyCloudDependantRequirements('bicSwift')"
        @input="setVendorProps"
      />
      <!-- IBAN -->
      <UiInput
        v-if="requiredVendorFields.includes('iban')"
        name="iban"
        type="text"
        :label="$t('common.bankInfo.iban')"
        :model-value="selectedVendor.iban"
        :error="
          regexValidator(selectedVendor.iban, 'iban') &&
          currencyCloudDependantRequirements('iban')
        "
        :error-msg="
          validationMsg(selectedVendor.iban, $t('common.bankInfo.ibanError'))
        "
        :required="currencyCloudDependantRequirements('iban')"
        @input="setVendorProps"
      />
      <!-- Sort Code -->
      <UiInput
        v-if="requiredVendorFields.includes('sortCode')"
        name="sortCode"
        type="text"
        :label="$t('common.bankInfo.sortCode')"
        :model-value="selectedVendor.sortCode"
        :error="
          regexValidator(selectedVendor.sortCode, 'sortCode') &&
          currencyCloudDependantRequirements('sortCode')
        "
        :error-msg="
          validationMsg(
            selectedVendor.sortCode,
            $t('common.bankInfo.sortCodeError'),
          )
        "
        :required="currencyCloudDependantRequirements('sortCode')"
        @input="setVendorProps"
      />
      <!-- Routing Number -->
      <UiInput
        v-if="requiredVendorFields.includes('aba')"
        name="routingNumber"
        type="text"
        :label="$t('common.bankInfo.ach')"
        :model-value="selectedVendor.routingNumber"
        :error="regexValidator(selectedVendor.routingNumber, 'aba')"
        :error-msg="
          validationMsg(
            selectedVendor.routingNumber,
            $t('common.bankInfo.achError'),
          )
        "
        required
        @input="setVendorProps"
      />
      <!-- Wire Routing Number -->
      <UiInput
        v-if="requiredVendorFields.includes('aba')"
        name="wireRoutingNumber"
        type="text"
        :label="$t('common.bankInfo.wireNumber')"
        :model-value="selectedVendor.wireRoutingNumber"
        :error="
          regexValidator(selectedVendor.wireRoutingNumber, 'aba') &&
          currencyCloudDependantRequirements('aba')
        "
        :error-msg="
          validationMsg(
            selectedVendor.wireRoutingNumber,
            $t('common.bankInfo.wireNumberError'),
          )
        "
        :required="currencyCloudDependantRequirements('aba')"
        @input="setVendorProps"
      />
      <!-- BSB Code -->
      <UiInput
        v-if="requiredVendorFields.includes('bsbCode')"
        name="bsbCode"
        type="text"
        :label="$t('common.bankInfo.bsbCode')"
        :model-value="selectedVendor.bsbCode"
        :error="regexValidator(selectedVendor.bsbCode, 'bsbCode')"
        :error-msg="
          validationMsg(
            selectedVendor.bsbCode,
            $t('common.bankInfo.bsbCodeError'),
          )
        "
        required
        @input="setVendorProps"
      />
      <!-- Bank Code -->
      <UiInput
        v-if="requiredVendorFields.includes('bankCode')"
        name="bankCode"
        type="text"
        :label="$t('common.bankInfo.bankCode')"
        :model-value="selectedVendor.bankCode"
        :error="regexValidator(selectedVendor.bankCode, 'bankCode')"
        :error-msg="
          validationMsg(
            selectedVendor.bankCode,
            $t('common.bankInfo.bankCodeError'),
          )
        "
        required
        @input="setVendorProps"
      />
      <!-- Branch Code -->
      <UiInput
        v-if="requiredVendorFields.includes('branchCode')"
        name="branchCode"
        type="text"
        :label="$t('common.bankInfo.branchCode')"
        :model-value="selectedVendor.branchCode"
        :error="regexValidator(selectedVendor.branchCode, 'branchCode')"
        :error-msg="
          validationMsg(
            selectedVendor.branchCode,
            $t('common.bankInfo.branchCodeError'),
          )
        "
        required
        @input="setVendorProps"
      />
      <!-- CLABE -->
      <UiInput
        v-if="requiredVendorFields.includes('clabe')"
        name="clabe"
        type="text"
        :label="$t('common.bankInfo.clabe')"
        :model-value="selectedVendor.clabe"
        :error="regexValidator(selectedVendor.clabe, 'clabe')"
        :error-msg="
          validationMsg(selectedVendor.clabe, $t('common.bankInfo.clabeError'))
        "
        required
        @input="setVendorProps"
      />
      <!-- CNAPS -->
      <UiInput
        v-if="requiredVendorFields.includes('cnaps')"
        name="cnaps"
        type="text"
        :label="$t('common.bankInfo.cnaps')"
        :model-value="selectedVendor.cnaps"
        :error="regexValidator(selectedVendor.cnaps, 'cnaps')"
        :error-msg="
          validationMsg(selectedVendor.cnaps, $t('common.bankInfo.cnapsError'))
        "
        required
        @input="setVendorProps"
      />
      <!-- IFSC -->
      <UiInput
        v-if="requiredVendorFields.includes('ifsc')"
        name="ifsc"
        type="text"
        :label="$t('common.bankInfo.ifsc')"
        :model-value="selectedVendor.ifsc"
        :error="regexValidator(selectedVendor.ifsc, 'ifsc')"
        :error-msg="
          validationMsg(selectedVendor.ifsc, $t('common.bankInfo.ifscError'))
        "
        required
        @input="setVendorProps"
      />
    </custom-fieldset>
    <div :class="$style.modalContext">
      {{ $t('components.bankInfo.recipientBankAddress') }}
    </div>
    <custom-fieldset>
      <!-- Bank Address Line 1 -->
      <UiInput
        name="bankAddress.line1"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.address.addressLine1')"
        :model-value="selectedVendor.bankAddress.line1"
        :required="requiredVendorFields.includes('bankAddress')"
        @input="setVendorProps"
      />
      <!-- Bank Address Line 2 -->
      <UiInput
        name="bankAddress.line2"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.address.addressLine2')"
        :model-value="selectedVendor.bankAddress.line2"
        :required="requiredVendorFields.includes('bankAddress')"
        @input="setVendorProps"
      />
      <!-- Bank Address Country -->
      <UiInput
        name="bankAddress.country"
        type="text"
        :error-msg="$t('common.errors.mandatory')"
        :label="$t('common.address.country')"
        :model-value="getCountryName(selectedVendor.bankAddress.country)"
        :required="requiredVendorFields.includes('bankAddress')"
        disabled
        @input="setVendorProps"
      />
      <!-- Bank Address State -->
      <UiDropdown
        v-if="stateOptionsForCountry(selectedVendor.bankAddress.country)"
        size="small"
        name="bankAddress.state"
        :external-label="stateLabel(selectedVendor.bankAddress.country)"
        :label="
          $t('common.selectOption', {
            option: `${stateLabel(selectedVendor.bankAddress.country)} -`,
          })
        "
        :options="stateOptionsForCountry(selectedVendor.bankAddress.country)"
        :model-value="selectedVendor.bankAddress.state"
        :required="requiredVendorFields.includes('bankAddress')"
        @input="setVendorProps"
      />
      <!-- Bank Address State for countries without a list of states -->
      <UiInput
        v-else
        name="bankAddress.state"
        :label="$t('common.address.state')"
        :model-value="selectedVendor.bankAddress.state"
        :required="requiredVendorFields.includes('bankAddress')"
        @input="setVendorProps"
      />
      <!-- Bank Address City -->
      <UiInput
        name="bankAddress.city"
        type="text"
        :label="$t('common.address.city')"
        :model-value="selectedVendor.bankAddress.city"
        :required="requiredVendorFields.includes('bankAddress')"
        @input="setVendorProps"
      />
      <!-- Bank Address Postal Code -->
      <UiInput
        v-if="
          selectedVendor.bankAddress.country &&
          !withoutPostalCodeCountries.includes(
            selectedVendor.bankAddress.country,
          )
        "
        name="bankAddress.postalCode"
        type="text"
        :label="$t('common.address.postalCodeLabel.postalCode')"
        :model-value="selectedVendor.bankAddress.postalCode"
        :required="requiredVendorFields.includes('bankAddress')"
        @input="setVendorProps"
      />
    </custom-fieldset>
    <UiButton :text="$t('common.buttons.back')" @click="$emit('prev-tab')" />
    <UiButton
      :text="$t('common.buttons.next')"
      data-cy="next-button"
      @click="$emit('next-tab')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateChildren } from '@/composables/validation';
import { UiDropdown, UiButton, UiInput } from 'ui-components';

import {
  COUNTRY_OPTIONS,
  COUNTRY_CODE_WITH_NAMES,
  WITHOUT_POSTAL_CODE_COUNTRY_CODE,
} from '@/data/country-code-with-names';

import {
  stateOptionsForCountry,
  cityLabel,
  postalCodeLabel,
  stateLabel,
} from '@/utils/local';

import CustomFieldset from '../FieldsetCustom';

export default {
  components: {
    UiButton,
    UiInput,
    UiDropdown,
    CustomFieldset,
  },
  props: {
    requiredVendorFieldsWithValidation: { type: Object, required: true },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  computed: {
    ...mapGetters(['selectedVendor']),
    validationRegex() {
      return Object.keys(this.requiredVendorFieldsWithValidation).reduce(
        (acc, key) => {
          acc[key] = RegExp(this.requiredVendorFieldsWithValidation[key]);
          return acc;
        },
        {},
      );
    },
    countryOptions() {
      return COUNTRY_OPTIONS;
    },
    requiredVendorFields() {
      return Object.keys(this.requiredVendorFieldsWithValidation);
    },
    withoutPostalCodeCountries() {
      return WITHOUT_POSTAL_CODE_COUNTRY_CODE;
    },
  },
  methods: {
    stateOptionsForCountry,
    stateLabel,
    postalCodeLabel,
    cityLabel,
    getCountryName(countryCode) {
      return COUNTRY_CODE_WITH_NAMES[countryCode]();
    },
    setVendorProps(val, name) {
      this.$store.dispatch('UPDATE_SELECTED_VENDOR', { path: name, val });
    },
    // Returns the correct custom message in case of error
    validationMsg(propVal, customMsg) {
      return !propVal ? this.$t('common.errors.mandatory') : customMsg;
    },
    // Does validation on given field based on currency cloud regex requirements
    // which we get from this.validationRegex
    regexValidator(propVal, regexKey) {
      if (typeof propVal === 'undefined') {
        return false;
      }
      if (propVal === '') {
        return true;
      }
      return !this.validationRegex[regexKey].test(propVal);
    },
    // checks whether or not a field is required based on banking info requirements
    // sent over from currency cloud
    currencyCloudDependantRequirements(propName) {
      if (propName === 'bicSwift') {
        if (this.requiredVendorFields.includes('sortCode')) {
          return false;
        }
        if (this.requiredVendorFields.includes('aba')) {
          return !this.selectedVendor.wireRoutingNumber;
        }
        return this.requiredVendorFields.includes('bicSwift');
      }
      // temporary workaround to be re-visited on local rails for Currency Cloud
      // if (propName === 'aba') {
      //   return (
      //     this.requiredVendorFields.includes('aba') &&
      //     !this.selectedVendor.swiftCode
      //   );
      // }
      if (propName === 'iban') {
        return this.requiredVendorFields.includes('sortCode')
          ? false
          : this.requiredVendorFields.includes('iban');
      }
      return true;
    },
  },
};
</script>
<style lang="less" module>
.modalContext {
  color: black;
  padding-bottom: 30px;
  text-align: center;
  font-weight: bold;
}
</style>
