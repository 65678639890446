<template>
  <div>
    <h2 :class="$style.title">{{ title }}</h2>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';

const components = {
  'main-layout': require('@/layout/layout-main').default,
};

export default {
  components,
  setup() {
    useMeta({ title: 'Are you lost? | Clearco' });
  },
  data() {
    return {
      title: 'Nothing to see here folks!',
    };
  },
};
</script>

<style lang="less" module>
.title {
  font-family: @gerstner-font;
  margin: 50px auto;
  padding: 0 20px;
  text-align: center;
}
</style>
