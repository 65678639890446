// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onboarding-wrapper_NxjB_ [class^=content-container]{margin-top:unset}@media only screen and (min-width:961px){.onboarding-wrapper_NxjB_{max-width:460px!important}}.logo_YM63z{width:242px;margin:auto auto 20px}@media screen and (max-width:500px){.logo_YM63z{margin-top:30px}}.text_A49YL{text-align:center}.unverified-warning_lHbsT{margin-top:10px;margin-bottom:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"onboarding-wrapper": "onboarding-wrapper_NxjB_",
	"logo": "logo_YM63z",
	"text": "text_A49YL",
	"unverified-warning": "unverified-warning_lHbsT"
};
export default ___CSS_LOADER_EXPORT___;
