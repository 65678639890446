// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_GS5sq{border:1px solid #ceced0;border-radius:4px;box-sizing:border-box;box-shadow:0 2px 8px rgba(0,0,0,.06);background-color:#fff}.green_Py2c5{color:#2f7e58}.in_transit_zR1vZ,.pending_cgJDo{color:#666}.failed_t6iPZ{color:#973030}.success_PyfSk{color:#2f7e58}.cancelled_XTOau{color:#973030}.settled_do1Et{color:#2f7e58}.scheduled_i84Qw{color:#666}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_GS5sq",
	"green": "green_Py2c5",
	"in_transit": "in_transit_zR1vZ",
	"pending": "pending_cgJDo",
	"failed": "failed_t6iPZ",
	"success": "success_PyfSk",
	"cancelled": "cancelled_XTOau",
	"settled": "settled_do1Et",
	"scheduled": "scheduled_i84Qw"
};
export default ___CSS_LOADER_EXPORT___;
