<template>
  <ProgressSpinner v-if="isLoading" :class="$style['loader']" />
  <Badge
    v-else
    :text="billStatusInfo"
    :theme="getBadgeTheme"
    :class="$style['badge']"
  />
</template>

<script>
import ProgressSpinner from '@clearbanc/clear-components/progressspinner';
import { Badge } from '@clearbanc/design-components/src/components';
import { useContextRoot } from '@/utils/context-root';
import billComposables from '@/composables/bills';
import { COLOR_THEMES } from '@/data/color-themes';
import { mapGetters } from 'vuex';
import { BillPaymentMethods, BillStatus } from '@/data/payments';
import { MILESTONE_STATUSES, MILESTONES } from '@/data/bill-status-timeline';

export default {
  components: {
    Badge,
    ProgressSpinner,
  },
  setup(props, context) {
    const ctxRoot = useContextRoot();
    const { billStatusData } = billComposables(context, ctxRoot);
    return { billStatusData };
  },
  props: {
    bill: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payments: null,
      loadingPayments: false,
      loadingBNPLDetails: false,
    };
  },
  async mounted() {
    await this.getBNPLDetails();

    // if no contract for bill exists in store and bill has been approved
    if (!this.billContract && this.bill?.bnplDetails?.diligenceApprovedAt) {
      await this.$store.dispatchApiAction('GET_BNPL_CONTRACT', {
        billId: this.bill.id,
      });
    }

    if (this.computedBillMilestone === MILESTONES.PAID) {
      this.payments = await this.getPaymentTransactions();
    }
  },
  computed: {
    ...mapGetters(['contracts']),
    isLoading() {
      return this.loadingBNPLDetails || this.loadingPayments;
    },
    billContract() {
      return this.contracts.find(
        (contract) => contract.billId === this.bill.id,
      );
    },
    billMilestoneData() {
      return this.billStatusData(
        this.bill.id,
        this.billContract,
        this.payments,
      );
    },
    computedBillStatus() {
      return this.billMilestoneData?.milestoneInProgress.status;
    },
    computedBillLabel() {
      return this.$t(this.billMilestoneData?.milestoneInProgress.label);
    },
    computedBillMilestone() {
      return this.billMilestoneData?.milestoneInProgress.name;
    },
    mostRecentPayment() {
      return this.billMilestoneData?.mostRecentPayment;
    },
    isLegacyPaymentMethod() {
      return this.bill.paymentMethod !== BillPaymentMethods.BNPL;
    },
    billStatusInfo() {
      return this.computedBillLabel;
    },
    getBadgeTheme() {
      switch (this.computedBillStatus) {
        case MILESTONE_STATUSES.COMPLETE:
          return COLOR_THEMES.SUCCESS;
        case MILESTONE_STATUSES.ACTION_REQUIRED_BIZ_PROFILE:
        case MILESTONE_STATUSES.ACTION_REQUIRED_SIGN_CONTRACT:
        case MILESTONE_STATUSES.ACTION_REQUIRED_VENDOR_DETAILS:
        case MILESTONE_STATUSES.ACTION_REQUIRED_VERIFY_REVENUE:
        case MILESTONE_STATUSES.ACTION_REQUIRED_BANK_DOCUMENTS:
        case MILESTONE_STATUSES.ACTION_REQUIRED_INSUFFICIENT_CAPACITY:
          return COLOR_THEMES.WARNING;
        case MILESTONE_STATUSES.PROCESSING:
          return COLOR_THEMES.INFO;
        case MILESTONE_STATUSES.FAILED:
          return COLOR_THEMES.DANGER;
        default:
          return COLOR_THEMES.NEUTRAL;
      }
    },
  },
  methods: {
    async getBNPLDetails() {
      // NOTE: need to make this call because the GET /bills endpoint does not
      // return `bnplDetails` for the bill. The GET /bill endpoint does return this field
      // and the field is required for determining bill status
      if (
        !this.bill.bnplDetails &&
        !this.isLegacyPaymentMethod &&
        ![
          BillStatus.AUTO_DEBITING_ACTIVE,
          BillStatus.CANCELLED,
          BillStatus.BNPL_DECLINED,
        ].includes(this.bill.status)
      ) {
        this.loadingBNPLDetails = true;
        await this.$store.dispatchApiAction('GET_BILL', { id: this.bill.id });
        this.loadingBNPLDetails = false;
      }
    },
    async getPaymentTransactions() {
      try {
        this.loadingPayments = true;
        const response = await this.$store.dispatchApiAction(
          'GET_BILL_PAYMENT_TRANSACTIONS',
          {
            billId: this.bill.id,
          },
        );
        return response.payments;
      } catch (err) {
        return null;
      } finally {
        this.loadingPayments = false;
      }
    },
  },
  watch: {
    bill: {
      deep: true,
      handler(oldBill, updatedBill) {
        if (oldBill.status !== updatedBill.status) {
          this.getBNPLDetails();
        }
      },
    },
  },
};
</script>

<style lang="less" module>
.loader {
  display: block;
  margin: 0 auto;
  width: 1.5rem;
  height: 1.5rem;
}

.badge {
  display: block;
}
</style>
